import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAllCalendarV1CalendarGet } from 'queries';
import { OutCalendar } from 'queries/model';

const request = async () => await getAllCalendarV1CalendarGet();
const calendarList = atomWithDefault<OutCalendar[]>(request);
export const calendarListAtom = atom(
  (get) => get(calendarList),
  async (_, set) => {
    const res = await getAllCalendarV1CalendarGet();
    set(calendarList, res || []);
  },
);
