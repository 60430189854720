import styled from '@emotion/styled';
import OrganizeToday from './Organize';
import { COLORS } from 'styles/constants';

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
`;

const Ritual = () => {
  return (
    <Container>
      <OrganizeToday />
    </Container>
  );
};

export default Ritual;
