import { SVGProps } from 'react';

export const Globe = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2 8H5.33333M2 8C2 11.3137 4.68629 14 8 14M2 8C2 4.68629 4.68629 2 8 2M5.33333 8H10.6667M5.33333 8C5.33333 11.3137 6.52724 14 8 14M5.33333 8C5.33333 4.68629 6.52724 2 8 2M10.6667 8H14M10.6667 8C10.6667 4.68629 9.47276 2 8 2M10.6667 8C10.6667 11.3137 9.47276 14 8 14M14 8C14 4.68629 11.3137 2 8 2M14 8C14 11.3137 11.3137 14 8 14"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
