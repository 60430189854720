enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  IE = 'Internet Explorer',
  Edge = 'Edge',
  Others = 'Others',
}

export const getBrowserName = () => {
  if (typeof navigator === 'undefined' || !navigator.userAgent) {
    return '';
  }

  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edge') === -1) {
    return Browser.Chrome;
  } else if (userAgent.indexOf('firefox') > -1) {
    return Browser.Firefox;
  } else if (userAgent.indexOf('safari') > -1) {
    return Browser.Safari;
  } else if (userAgent.indexOf('opera') > -1) {
    return Browser.Opera;
  } else if (userAgent.indexOf('msie') > -1 || userAgent.indexOf('trident') > -1) {
    return Browser.IE;
  } else if (userAgent.indexOf('edge') > -1) {
    return Browser.Edge;
  } else {
    return Browser.Others;
  }
};
