import { SVGProps } from 'react';

export const TutorialReplay = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 10.4705C6.08788 9.9439 5.05322 9.66667 4 9.66667C2.94678 9.66667 1.91212 9.9439 1 10.4705V1.80385C1.91212 1.27724 2.94678 1 4 1C5.05322 1 6.08788 1.27724 7 1.80385M7 10.4705C7.91212 9.9439 8.94678 9.66667 10 9.66667C11.0532 9.66667 12.0879 9.9439 13 10.4705V1.80385C12.0879 1.27724 11.0532 1 10 1C8.94678 1 7.91212 1.27724 7 1.80385M7 10.4705V1.80385"
        stroke={props.stroke ? props.stroke : '#1F2023'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
