import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { useEffect, useRef, useState } from 'react';
import { EventProps, Event as RBCEvent } from 'react-big-calendar';
import dayjs from 'lib/dayjs';
import 'moment/locale/ko';
import { truncate } from 'styles/utils';
import { has } from 'lodash';
import { TextField } from '@mui/material';

const Container = styled.div<{ done?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.done ? '#F9FAFE' : 'white')};
  border-radius: 8px;
`;

const EventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 6px 6px 5px 8px;
`;

const AllDayEventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 6px 4px 8px;
  height: 28px;
`;

const TextTruncate = styled.div`
  ${truncate('auto')};
`;

const AllDayEventTitle = styled.div<{ done?: boolean; color?: string }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
  ${truncate('auto')};
`;

const EventTitle = styled.div<{ done?: boolean; color?: string }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const VerticalBar = styled.div<{ done?: boolean; isRecurrence?: boolean }>`
  border-right: ${(props) => `2px solid ${props.isRecurrence ? COLORS.sub4 : COLORS.brand1}`};
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const VerticalBarFocus = styled.div<{ done?: boolean }>`
  border: 1px solid transparent;
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  -webkit-border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image-slice: 1;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const EditableTextField = styled.div`
  width: 100%;
  /* height: 100%; */
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 13px;
  font-weight: bold;
  color: ${COLORS.gray900};
`;

export interface TaskboxInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const TaskboxInput = ({ value, onChange }: TaskboxInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    refInput?.current?.focus();
  }, []);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const content = refInput.current?.textContent || '';
    if (e.key === 'Escape') {
      e.preventDefault();
      onChange?.('');
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      onChange?.(content);
    }
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLInputElement>) => {
    const content = e.currentTarget.textContent || '';
    setTimeout(() => onChange?.(content), 100);
  };

  return (
    <EditableTextField
      ref={refInput}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onKeyDown={handleKeydownInput}
      onBlur={handleBlurTextField}
      dangerouslySetInnerHTML={{ __html: value || '' }}
    />
  );
};

const TaskboxAllDayInput = ({ value, onChange }: TaskboxInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    refInput?.current?.focus();
  }, []);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    if (e.key === 'Escape') {
      e.preventDefault();
      onChange?.('');
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      onChange?.(`${inputValue}`.trim());
    }
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLInputElement>) => {
    const content = e.currentTarget.value || '';
    setTimeout(() => onChange?.(content), 100);
  };

  return (
    <TextField
      ref={refInput}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      autoFocus={true}
      autoComplete="off"
      fullWidth
      variant="standard"
      onBlur={handleBlurTextField}
      onKeyDown={handleKeydownInput}
      InputProps={{ disableUnderline: true, style: { fontSize: 13, fontWeight: 'bold', color: COLORS.gray900 } }}
    />
  );
};

export interface TodayCalendarEventProps extends EventProps<RBCEvent> {
  event: RBCEvent & {
    id?: string;
    type?: string;
    done?: boolean;
    focus?: boolean;
  };
  onInput?: (event: RBCEvent, title: string) => void;
}
const TodayCalendarEvent = (props: TodayCalendarEventProps) => {
  const { event, onInput } = props;
  const [editing, setEditing] = useState(false);
  const range = `${dayjs(event.start).format('a h:mm')} ~ ${dayjs(event.end).format('a h:mm')}`;
  const isGreaterThanMinimum = Math.abs(dayjs(event?.start).diff(event?.end, 'minutes')) > 15;
  const textColor = event.type === 'task' ? (event.done ? COLORS.gray400 : COLORS.gray900) : COLORS.gray600;
  const hasData = has(event, 'data');

  useEffect(() => {
    if (!hasData) {
      setTimeout(() => setEditing(true), 100);
    }
  }, [event]);

  const handleCreate = (value: string) => {
    setEditing(false);
    onInput?.(event, value);
  };

  const handleClickEdit = () => {
    if (event.type === 'task' && !event.done) {
      setEditing(true);
    }
  };

  return (
    <Container done={event.type === 'task' && event?.done}>
      {event.allDay ? (
        <AllDayEventWrapper>
          {event?.type === 'task' && event?.focus ? (
            <VerticalBarFocus done={event?.done} style={{ marginRight: 8, height: '80%' }} />
          ) : (
            <VerticalBar done={event?.done} style={{ marginRight: 8, height: '80%' }} />
          )}
          {editing ? (
            <TaskboxAllDayInput value={event.title as string} onChange={handleCreate} />
          ) : (
            <AllDayEventTitle done={event.done} color={textColor} onClick={handleClickEdit}>
              <span style={{ textDecoration: event.done ? 'line-through' : '' }}>{event.title || ''}</span>
            </AllDayEventTitle>
          )}
        </AllDayEventWrapper>
      ) : (
        <EventWrapper style={isGreaterThanMinimum ? {} : { padding: '4px 5px' }}>
          {event?.type === 'task' &&
            (event?.focus ? <VerticalBarFocus done={event.done} style={{ marginRight: 8 }} /> : <VerticalBar done={event.done} style={{ marginRight: 8 }} />)}

          <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 14px)', overflow: 'hidden' }}>
            {editing ? (
              <TaskboxInput value={event.title as string} onChange={handleCreate} />
            ) : (
              <EventTitle done={event.done} color={textColor} onClick={handleClickEdit} style={{ fontSize: isGreaterThanMinimum ? '13px' : '10px' }}>
                <span style={{ textDecoration: event.done ? 'line-through' : '' }}>{event.title || ''}</span>
              </EventTitle>
            )}
            <TextTruncate style={{ marginTop: 6 }}>{range}</TextTruncate>
          </div>
        </EventWrapper>
      )}
    </Container>
  );
};

export default TodayCalendarEvent;
