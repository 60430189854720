import { SVGProps } from 'react';

export const FocusMode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_19912_70734)">
        <path
          d="M10.6654 2.66927L13.332 2.66927L13.332 5.33594"
          stroke={props.stroke || '#ABB0BF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.33203 6.66406L13.332 2.66406" stroke={props.stroke || '#ABB0BF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M5.33203 13.3359L2.66536 13.3359L2.66536 10.6693"
          stroke={props.stroke || '#ABB0BF'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.66797 13.3359L6.66797 9.33594" stroke={props.stroke || '#ABB0BF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
