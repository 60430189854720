import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, IconButton } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import MUICheckbox from 'components/MuiCheckbox';

const Container = styled.div`
  width: 500px;
  height: 100%;
  padding: 24px 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const IssueTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 0px 8px;
`;

const TaskListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const TaskWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 0px 8px;

  &:nth-last-of-type(1) {
    margin-bottom: 24px;
  }
`;

const TaskContents = styled.div<{ selected?: boolean }>`
  width: 100%;
  min-height: 28px;
  padding: 0px 6px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  background-color: ${(props) => props.selected && COLORS.sub3};
`;

export type TaskSelectionViewTask = {
  id?: string;
  content?: string;
  dueDate?: string;
};

export interface TaskSelectionViewProps {
  issueTitle?: string;
  tasks?: TaskSelectionViewTask[];
  closeIcon?: boolean;
  onClickCancel?: () => void;
  onClickComplete?: (tasks: TaskSelectionViewTask[]) => void;
}

const TaskSelectionView = ({ issueTitle = '', tasks = [], closeIcon, onClickCancel, onClickComplete }: TaskSelectionViewProps) => {
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>((tasks || []).map((item) => item.id!));

  const handleClose = () => {
    onClickCancel && onClickCancel();
  };

  const handleClickComplete = () => {
    onClickComplete && onClickComplete(tasks.filter((item) => selectedTaskIds.includes(item.id!)));
  };

  const handleClickTask = (id: string) => {
    setSelectedTaskIds(selectedTaskIds.includes(id) ? selectedTaskIds.filter((item) => item !== id) : [...selectedTaskIds, id]);
  };

  return (
    <Container>
      <Title>태스크 선택하기</Title>
      {closeIcon && (
        <IconButton aria-label="close" onClick={handleClose} size="small" sx={{ position: 'absolute', right: 16, top: 24 }}>
          <Icons.Close width={24} height={24} />
        </IconButton>
      )}
      <IssueTitleWrapper>
        <Icons.IssueCheck style={{ marginRight: 8 }} />
        <span style={{ fontSize: 16, fontWeight: 'bold' }}>{issueTitle || '제목 없음'}</span>
      </IssueTitleWrapper>
      <TaskListWrapper>
        {tasks.map((v) => (
          <TaskWrapper key={v.id} onClick={() => handleClickTask(v.id!)}>
            <Checkbox
              icon={<Icons.RadioDisableUnchecked />}
              checkedIcon={<Icons.RadioChecked />}
              checked={selectedTaskIds.includes(v.id!)}
              size="small"
              sx={{ padding: 0 }}
              style={{ marginRight: 8 }}
            />
            <TaskContents selected={selectedTaskIds.includes(v.id!)}>
              <MUICheckbox style={{ marginRight: 8 }} disabled />
              <div style={{ overflowWrap: 'anywhere', fontSize: 13 }}>{v.content}</div>
            </TaskContents>
          </TaskWrapper>
        ))}
      </TaskListWrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button disableElevation color="inherit" variant="contained" onClick={handleClose} style={{ background: COLORS.gray200 }}>
          취소하기
        </Button>
        <Button disableElevation color="primary" variant="contained" onClick={handleClickComplete} style={{ marginLeft: 8 }}>
          선택완료 {`(${selectedTaskIds.length})`}
        </Button>
      </div>
    </Container>
  );
};

export default TaskSelectionView;
