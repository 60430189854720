import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { retrieveUserSettingV1UsersSettingGet } from 'queries';
import { UserSettingModel } from 'queries/model';

const request = async () => await retrieveUserSettingV1UsersSettingGet();
const setting = atomWithDefault<UserSettingModel>(request);
export const userSettingAtom = atom(
  (get) => get(setting),
  async (_, set) => {
    const res = await retrieveUserSettingV1UsersSettingGet();
    set(setting, res || []);
  },
);
