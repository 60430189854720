import styled from '@emotion/styled';
import { COLORS } from '../../styles/constants';
import Fonts from '../../components/Fonts';
import { Close } from '../Icons';
import { Button } from '../Button';

interface Props {
  children: React.ReactNode;
  width?: number;
  button?: boolean;
  disabled?: boolean;
  primary?: boolean;
  multiBtn?: boolean;
  title: string;
  label: string;
  handleButton: () => void;
  handleDeleteButton?: () => void;
  handleClose: () => void;
}

export const ModalLayout = ({
  children,
  width = 500,
  button = true,
  multiBtn = false,
  disabled = false,
  primary = true,
  title,
  label,
  handleButton,
  handleDeleteButton = () => {},
  handleClose,
}: Props) => {
  return (
    <Wrapper>
      <Background />
      <Modal width={width}>
        <MainLabel>
          <Fonts.H1 style={{ color: `${COLORS.gray900}` }}>{title}</Fonts.H1>
          <CloseBtn onClick={handleClose}>
            <Close />
          </CloseBtn>
        </MainLabel>
        {children}
        {button ? (
          <Buttonbox role="submit" multiBtn={multiBtn}>
            {multiBtn ? (
              <>
                <div style={{ marginRight: '8px' }}>
                  <div className="delete-btn" role="button" onClick={handleDeleteButton}>
                    <Fonts.H4 className="delete-btn-txt">삭제하기</Fonts.H4>
                  </div>
                </div>
                <Button label={label} onClick={handleButton} disabled={disabled} primary={primary} />
              </>
            ) : (
              <Button label={label} onClick={handleButton} disabled={disabled} primary={primary} />
            )}
          </Buttonbox>
        ) : null}
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  .delete-btn {
    width: fit-content;
    height: 40px;
    background-color: ${COLORS.negative1};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0px 16px;
    color: ${COLORS.white};
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: ${COLORS.negative1};
      opacity: 80%;
    }
  }
`;

const Background = styled.div`
  width: 100vw;
  background-color: ${COLORS.black};
  height: 100vh;
  opacity: 70%;
  height: 100vh;
`;

const Modal = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  border-radius: 16px;
  background-color: ${COLORS.white};
  padding: 24px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MainLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const CloseBtn = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${COLORS.white};
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Buttonbox = styled.div<{ multiBtn: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;
