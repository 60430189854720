import styled from '@emotion/styled';
import { Button, MenuItem, Select } from '@mui/material';
import { Icons } from 'components';
import dayjs from 'lib/dayjs';
import { OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { DATE_FORMAT_7, TIME_FORMAT_2 } from 'utils/datetimeFormat';

interface Props {
  event?: OutTaskboxDetailResponse;
  onClose?: () => void;
  onClick?: (start: string, end: string) => void;
}

export const HighlightTimeSelectionModal = ({ event, onClose = () => {}, onClick = () => {} }: Props) => {
  const [startTime, setStartTime] = useState<string | undefined>(
    dayjs(event?.start?.date).set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15) >=
      dayjs(event?.start?.date).set('hour', 23).set('minute', 45)
      ? dayjs(event?.start?.date).set('hour', 23).set('minute', 45).format(DATE_FORMAT_7)
      : dayjs(event?.start?.date)
          .set('hour', dayjs().toDate().getHours())
          .set('minute', dayjs().toDate().getMinutes())
          .ceil('minutes', 15)
          .format(DATE_FORMAT_7),
  );

  const [endTime, setEndTime] = useState<string | undefined>(
    dayjs(event?.end?.date)
      .set('hour', dayjs().toDate().getHours())
      .set('minute', dayjs().toDate().getMinutes())
      .add(1, 'hour')
      .ceil('minutes', 15)
      .format(DATE_FORMAT_7),
  );

  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs(startTime)
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(DATE_FORMAT_7),
    ),
  ];

  const [endTimeSelection, setEndTimeSelection] = useState<string[]>([
    ...selectableTime.slice(selectableTime.findIndex((v) => v === dayjs(endTime).format(DATE_FORMAT_7))),
  ]);

  useEffect(() => {
    const customTime =
      dayjs(startTime).format('a HH:mm') > dayjs(startTime).set('hour', 22).set('minute', 59).format('a HH:mm')
        ? dayjs(startTime).set('hour', 23).set('minute', 59).format(DATE_FORMAT_7)
        : dayjs(startTime).add(1, 'hour');

    setEndTime(dayjs(customTime).format(DATE_FORMAT_7));
    const index = selectableTime.findIndex((v) => v === dayjs(startTime).add(15, 'minute').format(DATE_FORMAT_7));
    const timeSelection = index === -1 ? [] : selectableTime.slice(index);
    setEndTimeSelection([...timeSelection, dayjs(startTime).set('hour', 23).set('minute', 59).format(DATE_FORMAT_7)]);
  }, [startTime]);

  const handleClick = () => {
    onClick(startTime!, endTime!);
  };

  return (
    <Wrapper>
      <Header>
        <Icons.FocusTime />
        <div style={{ marginLeft: '4px' }}> 하이라이트 시간 설정</div>
      </Header>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '12px' }}>
        <Select
          value={startTime}
          onChange={(e) => {
            setStartTime(e.target.value);
          }}
          style={{ width: 138, height: 40, fontSize: '13px' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
              },
            },
          }}
        >
          {selectableTime.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
            </MenuItem>
          ))}
        </Select>
        <div>-</div>
        <Select
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          style={{ width: 138, height: 40, fontSize: '13px' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
              },
            },
          }}
        >
          {endTimeSelection.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ marginTop: '16px' }}>
        <Button
          variant="contained"
          disableElevation
          onClick={onClose}
          style={{ width: 138, height: 40, color: `${COLORS.black}`, backgroundColor: `${COLORS.gray100}`, marginRight: 16 }}
        >
          취소하기
        </Button>

        <Button variant="contained" disableElevation onClick={handleClick} style={{ width: 138, height: 40 }}>
          적용하기
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 12px;
`;
