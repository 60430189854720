import { SVGProps } from 'react';

export const TaskboxMemo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.66927 2.00058C8.60561 2 8.53413 2 8.45241 2H5.4694C4.72266 2 4.34902 2 4.0638 2.14532C3.81292 2.27316 3.60909 2.47698 3.48126 2.72786C3.33594 3.01308 3.33594 3.38673 3.33594 4.13346V11.8668C3.33594 12.6135 3.33594 12.9867 3.48126 13.2719C3.60909 13.5228 3.81292 13.727 4.0638 13.8548C4.34874 14 4.72194 14 5.46723 14L10.538 14C11.2833 14 11.6559 14 11.9409 13.8548C12.1917 13.727 12.3963 13.5228 12.5241 13.2719C12.6693 12.987 12.6693 12.6143 12.6693 11.8691V6.21712C12.6693 6.13531 12.6693 6.06374 12.6686 6M8.66927 2.00058C8.8597 2.00231 8.98016 2.00923 9.09517 2.03684C9.23121 2.0695 9.36117 2.12351 9.48047 2.19661C9.61498 2.27904 9.73048 2.39455 9.96094 2.625L12.0446 4.70866C12.2752 4.93926 12.3899 5.05424 12.4723 5.1888C12.5454 5.30809 12.5995 5.43817 12.6322 5.57422C12.6598 5.68917 12.6668 5.80967 12.6686 6M8.66927 2.00058V3.86667C8.66927 4.6134 8.66927 4.98651 8.8146 5.27173C8.94243 5.52261 9.14625 5.72699 9.39714 5.85482C9.68207 6 10.0553 6 10.8005 6H12.6686M12.6686 6H12.6694"
        stroke={props.stroke ? props.stroke : '#858994'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 11.3359H8H10" stroke={props.stroke ? props.stroke : '#858994'} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 9.33594H10" stroke={props.stroke ? props.stroke : '#858994'} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
