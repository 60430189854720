import { SVGProps } from 'react';

export const RangeTaskbox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.33268 14H3.99935C3.64573 14 3.30659 13.8595 3.05654 13.6095C2.80649 13.3594 2.66602 13.0203 2.66602 12.6667V4.66667C2.66602 4.31304 2.80649 3.97391 3.05654 3.72386C3.30659 3.47381 3.64573 3.33333 3.99935 3.33333H11.9993C12.353 3.33333 12.6921 3.47381 12.9422 3.72386C13.1922 3.97391 13.3327 4.31304 13.3327 4.66667V8M10.666 2V4.66667M5.33268 2V4.66667"
        stroke="#858994"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.5 10.5V14.5L14.75 12.5L11.5 10.5Z" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
