import { atom } from 'jotai';
import { getUserMeV1UsersMeGet } from 'queries';
import { OutOAuthUser, OutUserDetail, OutWorkspaceWithToken } from 'queries/model';

export const meAtom = atom<OutOAuthUser>({ name: '', email: '', profileImage: '', active: false });
export const userAtom = atom<OutUserDetail | null>(null);
export const updateUserAtom = atom(
  (get) => get(userAtom),
  async (_, set) => {
    const res = await getUserMeV1UsersMeGet();
    set(userAtom, res || {});
  },
);

export const workspacesAtom = atom<OutWorkspaceWithToken[]>([]);
