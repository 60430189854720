import styled from '@emotion/styled';
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { useEffect, useLayoutEffect, useState } from 'react';
import Memo from './tabs/Memo';
import Plan from './tabs/Plan';
import Backlog from './tabs/Backlog';
import { COLORS } from 'styles/constants';
import {
  calendarWidthWithSidePanelAtom,
  foldCalendarViewAtom,
  foldSidePanelViewAtom,
  sidePanelWidthAtom,
  sidePanelWidthWithCalendarAtom,
  taskViewAtom,
} from 'atoms/foldView';
import { useAtom } from 'jotai';
import { css } from '@emotion/react';
import { osName } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { useEventListener, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { Resizable } from 're-resizable';
import { tasksAtom } from 'atoms/tasks';
import { isMouseInsideMemoContainer, removeMemoHandle } from 'components/Remirror/utils';
import { fetchInstancesFromProjectDetailViewAtom, projectFeatureAtom, projectsAtom, selectedInstanceAtom, selectedProjectAtom } from 'atoms/projects';
import {
  deleteProjectV1ProjectProjectIdDelete,
  deleteRoutineV1RoutineRoutineIdDelete,
  getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
} from 'queries';
import ProjectDetailView from 'pages/Desk/components/SidePanel/ProjectDetailView';
import { OutProject, OutRoutine } from 'queries/model';
import TaskboxDetailView from '../components/TaskboxDetailView';
import { fetchTaskboxFromTaskboxDetailViewAtom, taskboxDetailViewAtom } from 'atoms/taskboxDetail';
import RoutineDetailView from 'pages/Desk/components/SidePanel/RoutineDetailView';
import { fetchRoutineFromDetailViewAtom, routinesAtom, selectedRoutineAtom } from 'atoms/routine';
import { languageAtom } from 'atoms/language';
import { sidePanelTabIndexAtom } from 'atoms/sidePanel';

const Container = styled.div<{ bgColor?: string; foldCalendar?: boolean }>`
  max-width: 374px;
  min-width: 258px;
  width: 50%;
  height: 100%;
  padding: 8px 20px 0px 20px;
  position: relative;
  background-color: ${(props) => props.bgColor};
  ${(props) =>
    props.foldCalendar &&
    css`
      max-width: 35%;
      min-width: 35%;
    `}

  .MuiTabs-flexContainer {
    ${(props) =>
      props.foldCalendar &&
      css`
        justify-content: space-around;
      `}
  }
`;

const Wrapper = styled.div`
  .MuiTabs-flexContainer {
    justify-content: space-around;
  }
`;

const TabPanel = styled.div`
  height: 100%;
  width: 100%;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

const ControlWidthLine = styled.div<{ hover?: boolean; leftPosition?: number }>`
  width: 15px;
  height: 100%;
  border-left: 1px solid transparent;
  position: absolute;
  left: ${(props) => `${props.leftPosition}px`};
  bottom: 0;

  ${(props) =>
    props.hover &&
    css`
      cursor: ew-resize;
      border-left: 2px solid ${COLORS.sub2};
    `}
`;

const FoldTapPanel = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 50px;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  padding: 2px;
`;

const SidePanel = () => {
  const [tabIndex, setTabIndex] = useAtom(sidePanelTabIndexAtom);
  const [foldCalendar] = useAtom(foldCalendarViewAtom);
  const [calendarWidthWithSidePanel, setCalendarWidthWithSidePanel] = useAtom(calendarWidthWithSidePanelAtom);
  const [foldSidePanel, setFoldSidePanel] = useAtom(foldSidePanelViewAtom);
  const [sidePanelWidth, setSidePanelWidth] = useAtom(sidePanelWidthAtom);
  const [sidePanelWidthWithCalendar, setSidePanelWidthWithCalendar] = useAtom(sidePanelWidthWithCalendarAtom);
  const [isHoverWidthLine, setIsHoverWidthLine] = useState(false);
  const [mount, setMount] = useState(true);
  const [todoTasks] = useAtom(tasksAtom);
  const [taskView] = useAtom(taskViewAtom);
  const [, fetchProjects] = useAtom(projectsAtom);
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [selectedInstance, setSelectedInstance] = useAtom(selectedInstanceAtom);
  const [, setFetchInstanceFromDetail] = useAtom(fetchInstancesFromProjectDetailViewAtom);
  const [isBlinking, setIsBlinking] = useState(false);
  const [bodyWidth, setBodyWidth] = useState(document.body.getBoundingClientRect().width);
  const [taskboxDetail, setTaskboxDetail] = useAtom(taskboxDetailViewAtom);
  const [projectFeature, fetchProjectFeature] = useAtom(projectFeatureAtom);
  const [render, setRender] = useState(false);
  const [projects] = useAtom(projectsAtom);
  const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);
  const [, fetchRoutines] = useAtom(routinesAtom);
  const [, setFetchRoutineFromDetail] = useAtom(fetchRoutineFromDetailViewAtom);
  const [, setFetchTaskboxFromTaskboxDetail] = useAtom(fetchTaskboxFromTaskboxDetailViewAtom);
  const [language] = useAtom(languageAtom);

  useEffect(() => {
    updateProjectFeature();
  }, []);

  useEffect(() => {
    localStorage.setItem('tabpanel-index', tabIndex.toString());
  }, [tabIndex]);

  useEffect(() => {
    const calendarMinWidth = 284;
    const taskboxViewMinWidth = 702;
    const menuWidth = 80;

    if (taskView === 'today') {
      if (sidePanelWidthWithCalendar >= bodyWidth - calendarMinWidth - taskboxViewMinWidth - menuWidth) {
        setSidePanelWidthWithCalendar(bodyWidth - calendarMinWidth - taskboxViewMinWidth - menuWidth);
        localStorage.setItem('side-panel-width-with-calendar', JSON.stringify(bodyWidth - calendarMinWidth - taskboxViewMinWidth - menuWidth));
        setCalendarWidthWithSidePanel(calendarMinWidth);
        localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(calendarMinWidth));
      } else if (calendarWidthWithSidePanel + taskboxViewMinWidth > bodyWidth - sidePanelWidthWithCalendar) {
        setCalendarWidthWithSidePanel(bodyWidth - sidePanelWidthWithCalendar - taskboxViewMinWidth - menuWidth);
        localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(bodyWidth - sidePanelWidthWithCalendar - taskboxViewMinWidth - menuWidth));
      }
    } else {
      if (sidePanelWidthWithCalendar > (bodyWidth - menuWidth) * 0.5) {
        setSidePanelWidthWithCalendar((bodyWidth - menuWidth) * 0.5);
        localStorage.setItem('side-panel-width-with-calendar', JSON.stringify((bodyWidth - menuWidth) * 0.5));
      }
    }
  }, [taskView]);

  useEffect(() => {
    if (selectedInstance && foldSidePanel) handleClickFoldSidePanelView();
  }, [selectedInstance]);

  useLayoutEffect(() => {
    if (!mount) {
      if (foldCalendar) return;
      if (sidePanelWidth > sidePanelWidthWithCalendar) {
        setSidePanelWidthWithCalendar(document.body.getBoundingClientRect().width - calendarWidthWithSidePanel - 782);
        localStorage.setItem('side-panel-width-with-calendar', JSON.stringify(document.body.getBoundingClientRect().width - calendarWidthWithSidePanel - 782));
      } else if (sidePanelWidth < sidePanelWidthWithCalendar) {
        setSidePanelWidthWithCalendar(sidePanelWidth);
        localStorage.setItem('side-panel-width-with-calendar', JSON.stringify(sidePanelWidth));
      }
    }
    setMount(false);
  }, [foldCalendar]);

  useUpdateEffect(() => {
    if (foldSidePanel) {
      setIsBlinking(true);

      setTimeout(() => {
        setIsBlinking(false);
      }, 2000);
    }
  }, [todoTasks]);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement;
      if (!element) return;
      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
      ) {
        return;
      }
      if (ev.altKey && ev.code === 'Digit1') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(0);
        } else {
          setTabIndex(0);
        }
      }

      if (ev.altKey && ev.code === 'Digit2') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(1);
        } else {
          setTabIndex(1);
        }
      }

      if (projectFeature && ev.altKey && ev.code === 'Digit3') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(2);
        } else {
          setTabIndex(2);
        }
      }

      if ((ev.metaKey || ev.ctrlKey) && ev.altKey && ev.code == 'BracketRight') {
        handleClickFoldSidePanelView();
      }
    },
    [],

    { event: 'keydown', eventOptions: { passive: true } },
  );

  useEventListener(
    window,
    'resize',
    () => {
      if (bodyWidth - document.body.getBoundingClientRect().width > 100 && !foldCalendar && !foldSidePanel) {
        setSidePanelWidthWithCalendar(374);
        localStorage.setItem('side-panel-width-with-calendar', JSON.stringify(374));
      }

      if (bodyWidth - document.body.getBoundingClientRect().width > 100 || bodyWidth - document.body.getBoundingClientRect().width < -100) {
        setSidePanelWidth(Math.round((document.body.getBoundingClientRect().width - 80) * (sidePanelWidth / (bodyWidth - 80))));
        localStorage.setItem(
          'side-panel-width',
          JSON.stringify(Math.round((document.body.getBoundingClientRect().width - 80) * (sidePanelWidth / (bodyWidth - 80)))),
        );
      }

      setBodyWidth(document.body.getBoundingClientRect().width);
    },
    { passive: true },
  );

  useEventListener(
    document.querySelector('.side-panel-resizable'),
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, document.querySelector('.side-panel-resizable') as Element);
      if (!inside) {
        removeMemoHandle();
      }
    },
    { passive: true },
  );

  const handleClickFoldSidePanelView = (index?: number) => {
    if (index !== undefined) setTabIndex(index!);
    setFoldSidePanel(!foldSidePanel);
    localStorage.setItem('fold-side-panel-view', JSON.stringify(!foldSidePanel));
    setIsHoverWidthLine(false);
  };

  const handleResizeStop = (e: MouseEvent | TouchEvent, direction: string, ref: HTMLElement, d: { width: number; height: number }) => {
    if (!foldCalendar && !foldSidePanel) {
      setSidePanelWidthWithCalendar(sidePanelWidthWithCalendar + d.width);
      localStorage.setItem('side-panel-width-with-calendar', JSON.stringify(sidePanelWidthWithCalendar + d.width));
    } else {
      if (!localStorage.getItem('side-panel-width')) {
        const ele = document.querySelector('.side-panel-resizable') as HTMLElement;
        const width = Math.round(ele.getBoundingClientRect().width);
        setSidePanelWidth(width);
        localStorage.setItem('side-panel-width', JSON.stringify(width));
      } else {
        setSidePanelWidth(sidePanelWidth + d.width);
        localStorage.setItem('side-panel-width', JSON.stringify(sidePanelWidth + d.width));
      }
    }
  };

  const handleResizsStart = () => {
    setIsHoverWidthLine(true);
  };

  const handleDeleteProjectTaskWithEmptyText = async (project: OutProject) => {
    // PROJ_SOMEDAY_TASK 타입의 task들 삭제
    const projectSomedayTasks = project.tasks.filter((task) => task.type === 'PROJ_SOMEDAY_TASK');
    for (let i = projectSomedayTasks.length - 1; i >= 0; i--) {
      if (projectSomedayTasks[i].title === '') {
        await removeTaskboxV1TaskboxesTaskboxIdDelete(projectSomedayTasks[i].id!);
      }
    }

    // 다른 타입의 task들 중 하위 tasks 삭제
    const projectInstanceTasks = project.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
    for (const instanceTask of projectInstanceTasks) {
      if (instanceTask.tasks) {
        for (let i = instanceTask.tasks.length - 1; i >= 0; i--) {
          if (instanceTask.tasks[i].content === '') {
            await removeTaskboxV1TaskboxesTaskboxIdDelete(instanceTask.tasks[i].id!);
          }
        }
      }
    }
  };

  const handleCloseProjectDetail = async (project?: OutProject) => {
    if (project) {
      await handleDeleteProjectTaskWithEmptyText(project);
    }

    setTimeout(() => {
      setSelectedProject(null);
      setSelectedInstance(null);
      setFetchInstanceFromDetail(true);
      fetchProjects();
    }, 200);
  };

  const handleFetchProjects = () => {
    setFetchInstanceFromDetail(true);
    fetchProjects();
  };

  const handleDeleteProject = async (projectId: string) => {
    await deleteProjectV1ProjectProjectIdDelete(projectId);
    handleCloseProjectDetail();
  };

  const handleCloseTaskboxDetail = () => {
    setFetchTaskboxFromTaskboxDetail(true);
    setTaskboxDetail(null);
  };

  const handleFetchTaskboxDetail = () => {
    setFetchTaskboxFromTaskboxDetail(true);
  };

  const handleDeleteTaskboxDetail = async (taskboxId: string) => {
    await removeTaskboxV1TaskboxesTaskboxIdDelete(taskboxId);
    setTaskboxDetail(null);
  };

  const updateProjectFeature = async () => {
    if (!projectFeature) return;
    const showProject = projectFeature.data.create;

    if (!showProject && projects.length > 0) {
      try {
        await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(projectFeature.id, {
          data: { create: true },
        });
        fetchProjectFeature();
      } catch (e) {
        console.log(e);
      }
    }
    setRender(true);
  };

  const handleCloseRoutineDetail = async (routine?: OutRoutine) => {
    setFetchRoutineFromDetail(true);
    setTimeout(() => {
      setSelectedRoutine(null);
      fetchRoutines();
    }, 200);
  };

  const handleFetchRoutines = () => {
    setFetchRoutineFromDetail(true);
    fetchRoutines();
  };

  const handleDeleteRoutine = async (routineId: string) => {
    await deleteRoutineV1RoutineRoutineIdDelete(routineId);
    handleCloseRoutineDetail();
  };

  const backlogTabIndex = projectFeature.data.create ? 1 : 0;
  const memoTabIndex = projectFeature.data.create ? 2 : 1;

  return (
    <>
      {render && (
        <>
          {!foldSidePanel && (
            <Resizable
              className="side-panel-resizable"
              size={{
                width:
                  !foldCalendar && !foldCalendar
                    ? sidePanelWidthWithCalendar
                    : foldCalendar && !localStorage.getItem('side-panel-width')
                    ? '35%'
                    : sidePanelWidth,
                height: '100%',
              }}
              minWidth={374}
              maxWidth={
                foldCalendar || taskView === 'week' || taskView === 'month'
                  ? '50%'
                  : !foldCalendar && !foldSidePanel
                  ? bodyWidth - calendarWidthWithSidePanel - 782
                  : 'none'
              }
              enable={{
                top: false,
                right: false,
                bottom: false,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              onResizeStart={() => handleResizsStart()}
              onResize={(e, direction, ref, delta) => {
                setIsHoverWidthLine(true);
              }}
              onResizeStop={(e, direction, ref, d) => {
                handleResizeStop(e, direction, ref, d);
                setIsHoverWidthLine(false);
              }}
              handleComponent={{
                left: (
                  <div
                    onMouseEnter={() => {
                      setIsHoverWidthLine(true);
                      removeMemoHandle();
                    }}
                    onMouseDown={() => setIsHoverWidthLine(true)}
                    onMouseLeave={() => setIsHoverWidthLine(false)}
                    onClick={() => setIsHoverWidthLine(true)}
                    style={{ width: 6, borderRight: isHoverWidthLine ? `2px solid ${COLORS.sub2}` : `2px solid transparent`, height: '100%' }}
                  />
                ),
              }}
              style={{
                padding: (projectFeature.data.create && tabIndex === 2) || (!projectFeature.data.create && tabIndex === 1) ? '8px 20px 0px 20px' : '0px',
                position: 'relative',
                backgroundColor:
                  (projectFeature.data.create && tabIndex === 2) || (!projectFeature.data.create && tabIndex === 1) ? COLORS.white : COLORS.gray100,
              }}
            >
              <Wrapper
                style={{
                  borderBottom: `1px solid ${COLORS.gray300}`,
                  margin: (projectFeature.data.create && tabIndex === 2) || (!projectFeature.data.create && tabIndex === 1) ? '0px 8px' : '8px 28px 0px 28px',
                }}
              >
                <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
                  {projectFeature.data.create && (
                    <Tooltip
                      title={
                        <div style={{ padding: '5px 8px' }}>
                          <span>
                            {language === 'ko' ? '프로젝트 보기' : 'View project'}
                            {/* View project */}
                          </span>
                          <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                          <KeyboardCommandPlus>+</KeyboardCommandPlus>
                          <KeyboardButtonRect>1</KeyboardButtonRect>
                        </div>
                      }
                      disableInteractive
                    >
                      <Tab
                        icon={<Icons.Plan />}
                        iconPosition="start"
                        label={
                          language === 'ko' ? '프로젝트' : 'Project'
                          // 'Project'
                        }
                        style={{ minWidth: 106, padding: '12px 0px', textTransform: 'none' }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      <div style={{ padding: '5px 8px' }}>
                        <span>
                          {language === 'ko' ? '나중에 보기' : 'View later'}
                          {/* View later */}
                        </span>
                        <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                        <KeyboardCommandPlus>+</KeyboardCommandPlus>
                        <KeyboardButtonRect>{projectFeature.data.create ? 2 : 1}</KeyboardButtonRect>
                      </div>
                    }
                    disableInteractive
                  >
                    <Tab
                      icon={<Icons.Later />}
                      iconPosition="start"
                      label={
                        language === 'ko' ? '나중에' : 'Later'
                        // 'Later'
                      }
                      style={{ minWidth: 106, padding: '12px 0px', textTransform: 'none' }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <div style={{ padding: '5px 8px' }}>
                        <span>
                          {language === 'ko' ? '메모 보기' : 'View memo'}
                          {/* View memo */}
                        </span>
                        <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                        <KeyboardCommandPlus>+</KeyboardCommandPlus>
                        <KeyboardButtonRect>{projectFeature.data.create ? 3 : 2}</KeyboardButtonRect>
                      </div>
                    }
                    disableInteractive
                  >
                    <Tab
                      icon={<Icons.MemoTab />}
                      iconPosition="start"
                      label={
                        language === 'ko' ? '메모' : 'Memo'
                        // 'Memo'
                      }
                      style={{ minWidth: 106, padding: '12px 0px', textTransform: 'none' }}
                    />
                  </Tooltip>
                </Tabs>
              </Wrapper>
              <div style={{ marginTop: tabIndex !== backlogTabIndex ? 16 : 0, height: '100%', width: '100%' }}>
                {projectFeature.data.create && (
                  <TabPanel role="tabpanel" hidden={!(tabIndex === 0)}>
                    <Plan active={tabIndex === 0} />
                  </TabPanel>
                )}
                <TabPanel role="tabpanel" hidden={tabIndex !== backlogTabIndex}>
                  <Backlog active={tabIndex === backlogTabIndex} />
                </TabPanel>
                <TabPanel role="tabpanel" hidden={tabIndex !== memoTabIndex}>
                  <Memo active={tabIndex === memoTabIndex} />
                </TabPanel>
              </div>
              <div
                onMouseOver={() => {
                  setIsHoverWidthLine(true);
                  removeMemoHandle();
                }}
                onMouseLeave={() => setIsHoverWidthLine(false)}
                style={{ width: '15px' }}
              >
                {isHoverWidthLine && (
                  <Tooltip
                    title={
                      <div style={{ padding: '5px 8px' }}>
                        <span>
                          {language === 'ko' ? '오른쪽 탭 접기' : 'Collapse right tab'}
                          {/* Collapse right tab */}
                        </span>
                        <KeyboardButtonRect style={{ marginLeft: 8 }}> {osName === 'Windows' ? 'Ctrl' : '⌘'}</KeyboardButtonRect>
                        <KeyboardCommandPlus>+</KeyboardCommandPlus>
                        <KeyboardButtonRect>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                        <KeyboardCommandPlus>+</KeyboardCommandPlus>
                        <KeyboardButtonRect>{`]`}</KeyboardButtonRect>
                      </div>
                    }
                    placement="left"
                    disableInteractive
                    sx={{ padding: '0px' }}
                  >
                    <IconButton
                      onClick={() => handleClickFoldSidePanelView()}
                      sx={{
                        'width': '32px',
                        'height': '32px',
                        'position': 'absolute',
                        'left': -15,
                        'top': 114,
                        'zIndex': 10000,
                        'backgroundColor': COLORS.white,
                        'borderRadius': '8px',
                        'border': `1px solid ${COLORS.gray300}`,
                        'boxShadow': `0px 8px 16px 0px rgba(26, 30, 39, 0.16)`,
                        'padding': '4px',
                        ':hover': {
                          backgroundColor: COLORS.sub3,
                        },
                      }}
                    >
                      <Icons.ArrowRightSmall />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <TaskboxDetailView
                isSide
                taskboxDetail={taskboxDetail || undefined}
                onClose={handleCloseTaskboxDetail}
                onDelete={handleDeleteTaskboxDetail}
                onFetch={handleFetchTaskboxDetail}
              />
              <RoutineDetailView
                isSide
                selectedRoutine={selectedRoutine || undefined}
                onClose={handleCloseRoutineDetail}
                onFetch={handleFetchRoutines}
                onDelete={handleDeleteRoutine}
              />
              <ProjectDetailView
                isSide
                instance={selectedInstance || undefined}
                selectedProject={selectedProject || undefined}
                onClose={handleCloseProjectDetail}
                onDelete={handleDeleteProject}
                onFetch={handleFetchProjects}
              />
            </Resizable>
          )}
          {foldSidePanel &&
            createPortal(
              <FoldTapPanel style={{ zIndex: 100 }}>
                {projectFeature.data.create && (
                  <Tooltip
                    title={
                      <div style={{ padding: '5px 8px' }}>
                        <span>
                          {language === 'ko' ? '프로젝트 보기' : 'View project'}
                          {/* View project */}
                        </span>
                        <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                        <KeyboardCommandPlus>+</KeyboardCommandPlus>
                        <KeyboardButtonRect>1</KeyboardButtonRect>
                      </div>
                    }
                    placement="left"
                    disableInteractive
                    sx={{ padding: '0px' }}
                  >
                    <IconButton
                      onClick={() => handleClickFoldSidePanelView(0)}
                      sx={{
                        'width': '28px',
                        'height': '28px',
                        'backgroundColor': COLORS.white,
                        'borderRadius': '8px',
                        'padding': '4px',
                        ':hover': {
                          backgroundColor: COLORS.sub3,
                        },
                      }}
                    >
                      <Icons.Plan />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={
                    <div style={{ padding: '5px 8px' }}>
                      <span>
                        {language === 'ko' ? '나중에 보기' : 'View later'}
                        {/* View later */}
                      </span>
                      <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect>{projectFeature.data.create ? 2 : 1}</KeyboardButtonRect>
                    </div>
                  }
                  placement="left"
                  disableInteractive
                  sx={{ padding: '0px' }}
                >
                  <IconButton
                    onClick={() => handleClickFoldSidePanelView(projectFeature.data.create ? 1 : 0)}
                    sx={{
                      'width': '28px',
                      'height': '28px',
                      'backgroundColor': isBlinking ? COLORS.positive2 : COLORS.white,
                      'borderRadius': '8px',
                      'padding': '4px',
                      ':hover': {
                        backgroundColor: COLORS.sub3,
                      },
                      // 'transition': 'background-color 0.5s ease',
                    }}
                  >
                    <Icons.Later fill={isBlinking ? COLORS.positive1 : COLORS.gray900} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <div style={{ padding: '5px 8px' }}>
                      <span>
                        {language === 'ko' ? '메모 보기' : 'View memo'}
                        {/* View memo */}
                      </span>
                      <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect>{projectFeature.data.create ? 3 : 2}</KeyboardButtonRect>
                    </div>
                  }
                  placement="left"
                  disableInteractive
                  sx={{ padding: '0px' }}
                >
                  <IconButton
                    onClick={() => handleClickFoldSidePanelView(projectFeature.data.create ? 2 : 1)}
                    sx={{
                      'width': '28px',
                      'height': '28px',
                      'backgroundColor': COLORS.white,
                      'borderRadius': '8px',
                      'padding': '4px',
                      ':hover': {
                        backgroundColor: COLORS.sub3,
                      },
                    }}
                  >
                    <Icons.Memo fill={COLORS.gray900} />
                  </IconButton>
                </Tooltip>
              </FoldTapPanel>,
              document.body,
            )}
        </>
      )}
    </>
  );
};

export default SidePanel;
