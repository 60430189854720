import { CSSProperties, useEffect, useRef, memo } from 'react';
import EditorJS, { EditorConfig, OutputData } from '@editorjs/editorjs';
import { Header, Paragraph, NestedList, CheckList, Quote, Code, Marker, InlineCode, Delimiter } from './tools';
import styled from '@emotion/styled';
import LaterTune from './tunes/block-tune-later';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

const EditorContainer = styled.div`
  font-size: 0.875rem;
  box-sizing: 'borer-box';

  .cdx-block {
    padding: 0px !important;
  }

  .cdx-list__item {
    padding: 2px 0px 2px 3px !important;
  }
`;

const EDITOR_HOLDER_ID = 'editorjs';
export interface EditorProps extends Omit<EditorConfig, 'onChange'> {
  style?: CSSProperties;
  data?: OutputData;
  onChange?: (data: OutputData) => void;
}

const Editor = ({ data, onChange, style, ...config }: EditorProps) => {
  const ref = useRef<EditorJS | null>(null);
  const [language] = useAtom(languageAtom);

  useEffect(() => {
    init();
    return () => destroy();
  }, []);

  const init = async () => {
    const editor = new EditorJS({
      ...config,
      holder: EDITOR_HOLDER_ID,
      data: data,
      placeholder: config.placeholder || language === 'ko' ? '메모를 작성해보세요.' : 'Write a memo.',
      autofocus: false,
      onChange: async (/*api, event*/) => {
        if (!ref.current) return;

        const data = await ref.current.save();
        onChange?.(data);
      },
      tools: {
        blockTuneLater: LaterTune,
        header: {
          class: Header as any,
          config: {
            levels: [1, 2, 3],
            defaultLevel: 3,
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
          tunes: ['blockTuneLater'],
        } as any,
        list: {
          class: NestedList,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered',
            settings: [],
          },
          tunes: ['blockTuneLater'],
        },
        checklist: {
          class: CheckList,
          inlineToolbar: true,
          tunes: ['blockTuneLater'],
        },
        quote: Quote,
        code: Code,
        marker: Marker,
        inlineCode: InlineCode,
        delimiter: Delimiter,
        ...config.tools,
      },
      // https://github.com/codex-team/editor.js/blob/next/example/example-i18n.html
      i18n: {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': '메뉴 선택',
                'or drag to move': '또는 드래그로 옮기기',
              },
            },
            inlineToolbar: {
              converter: {
                'Convert to': '블록 변경',
              },
              bold: {
                bold: '굵게',
              },
            },
            toolbar: {
              toolbox: {
                Add: '블록 추가',
              },
            },
          },
          toolNames: {
            Text: '텍스트',
            Heading: '헤더',
            List: '리스트',
            Checklist: '체크리스트',
            Quote: '인용구',
            Code: '코드',
            Link: '링크',
            Marker: '마커',
            Bold: '볼드',
            Italic: '이탤릭',
            InlineCode: '인라인 코드',
            Delimiter: '구분선',
          },
          tools: {
            list: {
              Unordered: '목차 제거',
              Ordered: '목차 추가',
            },
            link: {
              'Add a link': '링크 추가',
            },
          },
          blockTunes: {
            delete: {
              'Delete': '삭제',
              'Click to delete': '클릭하여 삭제',
            },
            moveUp: {
              'Move up': '위로 옮기기',
            },
            moveDown: {
              'Move down': '아래로 옮기기',
            },
          },
        },
      },
    });

    ref.current = editor;
  };

  const destroy = () => {
    if (ref.current && ref.current?.destroy) {
      ref.current.destroy();
      setTimeout(() => (ref.current = null), 1000);
    }
  };

  return <EditorContainer id={EDITOR_HOLDER_ID} className="editor-holder" style={{ ...style }} />;
};

export default memo(Editor);
