import { SVGProps } from 'react';

export const Preparing = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.77779 3.54247C9.75557 3.49675 9.72274 3.45699 9.68203 3.42653C9.64133 3.39607 9.59393 3.37578 9.54379 3.36735C9.49366 3.35892 9.44223 3.3626 9.3938 3.37808C9.34538 3.39357 9.30135 3.4204 9.2654 3.45635L8.29722 4.42453C8.25773 4.46348 8.20449 4.48532 8.14902 4.48532C8.09354 4.48532 8.0403 4.46348 8.00081 4.42453L7.5753 3.99902C7.53638 3.95947 7.51457 3.90621 7.51457 3.85072C7.51457 3.79523 7.53638 3.74197 7.5753 3.70242L8.53837 2.73916C8.57522 2.70232 8.60248 2.657 8.61776 2.60718C8.63305 2.55736 8.6359 2.50456 8.62605 2.45339C8.61621 2.40221 8.59398 2.35423 8.56131 2.31364C8.52863 2.27305 8.48651 2.24108 8.43862 2.22053C7.68698 1.89875 6.75249 2.07951 6.16723 2.66137C5.65977 3.16505 5.50797 3.94848 5.75081 4.8135C5.76128 4.85063 5.76124 4.88993 5.75068 4.92703C5.74011 4.96413 5.71945 4.99756 5.691 5.02361L2.41832 8.06442C2.3099 8.16196 2.22247 8.28055 2.16136 8.41297C2.10025 8.54539 2.06674 8.68886 2.06288 8.83465C2.05901 8.98044 2.08487 9.12548 2.13887 9.26096C2.19287 9.39643 2.27389 9.51949 2.37698 9.62264C2.48008 9.72579 2.6031 9.80687 2.73855 9.86093C2.87399 9.915 3.01903 9.94093 3.16482 9.93714C3.31061 9.93334 3.4541 9.89991 3.58654 9.83886C3.71899 9.77782 3.83763 9.69045 3.93522 9.58208L7.00329 6.30372C7.02898 6.2759 7.06176 6.25562 7.09811 6.24505C7.13447 6.23448 7.17302 6.23402 7.20961 6.24372C8.06138 6.47086 8.83744 6.31943 9.33771 5.8273C9.62542 5.54337 9.82624 5.14266 9.90347 4.69709C9.97597 4.27689 9.93016 3.85592 9.77779 3.54247Z"
        fill="white"
      />
    </svg>
  );
};
