import { useMemo } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';

const UncheckedIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#0039A7" />
  </svg>
);

const CheckedIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="4" fill="#0039A7" />
    <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const DisabledIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#E7EAF4" stroke="#C8CDDB" />
  </svg>
);

const DisabledCheckedIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="4" fill="#C8CDDB" />
    <path d="M4 8.5L6.66667 11L12 6" stroke="#E7EAF4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export interface MUICheckboxProps extends CheckboxProps {}

const MUICheckbox = (props: MUICheckboxProps) => {
  const { disabled } = props;
  const icon = useMemo(() => (disabled ? DisabledIcon : UncheckedIcon), [disabled]);
  const checkedIcon = useMemo(() => (disabled ? DisabledCheckedIcon : CheckedIcon), [disabled]);

  return <Checkbox icon={icon} checkedIcon={checkedIcon} sx={{ padding: 0, margin: 0, ...props.sx }} {...props} />;
};
export default MUICheckbox;
