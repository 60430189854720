import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import { useClickOutside, useUpdateEffect } from '@react-hookz/web';
import { tasksAtom } from 'atoms/tasks';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { IssueCheckBox } from 'components/Icons';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import TaskBlockInputList, { TaskBlock, TaskBlockHandle } from 'pages/Task/components/TaskBlockInputList';
import { removeTaskboxV1TaskboxesTaskboxIdDelete, updateTaskboxV1TaskboxesTaskboxIdPut } from 'queries';
import { CreateTaskbox, OutAutoComplete, OutCategory, OutTaskboxDetail, OutTaskboxDetailResponse, UpdateTaskForTaskBox } from 'queries/model';
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { DATE_FORMAT_4, DATE_FORMAT_5 } from 'utils/datetimeFormat';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { languageAtom } from 'atoms/language';

/** CreateTaskbox */
export interface CreateTaskboxProps {
  date?: Date;
  doneStatus?: boolean;
  entireTaskboxList?: OutAutoComplete[];
  autoCompleteList?: AutoCompleteWorkList[];
  categoryList?: OutCategory[];
  onCreate?: (taskbox: CreateTaskbox) => void;
  onChange?: (date: Date) => void;
  onFetchEntireTaskboxList?: (title?: string) => void;
  onFetchAutoCompleteList?: (title?: string) => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => void;
}

export interface CreateTaskboxImperativeHandle {
  focus?: () => void;
}

export interface IncompleteWorkList extends OutTaskboxDetailResponse {
  date?: string;
}

export interface AutoCompleteWorkList extends OutAutoComplete {
  date?: string;
}

const CreateTaskboxInput = forwardRef<CreateTaskboxImperativeHandle, CreateTaskboxProps>(
  (
    {
      date,
      doneStatus = false,
      entireTaskboxList = [],
      autoCompleteList = [],
      categoryList = [],
      onCreate = () => {},
      onChange = () => {},
      onFetchEntireTaskboxList = () => {},
      onFetchAutoCompleteList = () => {},
      onClickCategoryActions = () => {},
    }: CreateTaskboxProps,
    ref: any,
  ) => {
    const [language] = useAtom(languageAtom);
    const [isFocus, setIsFocus] = useState(false);
    const refFrequentInput = useRef<HTMLInputElement>(null);
    const refCloseInput = useRef<HTMLInputElement>(null);
    const [incompleteWork, setIncompleteWork] = useState<IncompleteWorkList | null>(null);
    const [foldIncompleteWork, setFoldIncompleteWork] = useState(false);
    const [isAutoComplete, setIsAutoComplete] = useState(false);
    const [incompleteWorkList, setIncompleteWorkList] = useState<IncompleteWorkList[]>([]);
    const [openIncompleteDetailDialog, setOpenIncompleteDetailDialog] = useState(false);
    const [openIncompleteDeleteDialog, setOpenIncompleteDeleteDialog] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [multiSelect, setMultiSelect] = useState(false);
    const [multiSelectList, setMultiSelectList] = useState<string[]>([]);
    const [, fetchTasks] = useAtom(tasksAtom);
    const refScroll = useRef<HTMLDivElement[] | null[]>([]);
    const refScrollContainer = useRef<HTMLDivElement | null>(null);
    const [isHoverCheckbox, setIsHoverCheckbox] = useState(false);
    const [hoverCheckboxId, setHoverCheckboxId] = useState<string | null>(null);
    const [autoCompleteTitle, setAutoCompleteTitle] = useState<string>('');

    useEffect(() => {
      const incompleteWork = entireTaskboxList!
        .filter((item) => item.done === false && dayjs(item.start?.date || item.start?.datetime).isBefore(dayjs(date).subtract(1, 'day')))
        .map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_5) }))
        .sort((a, b) => (a.date > b.date ? -1 : 1));
      setIncompleteWorkList(incompleteWork);
    }, [entireTaskboxList]);

    useImperativeHandle(ref, () => ({ focus: () => refFrequentInput?.current?.focus() }));

    useClickOutside(refCloseInput, () => {
      if (!refFrequentInput.current) return;
      if (refFrequentInput.current.value) return;
      if (openIncompleteDetailDialog) return;
      if (openIncompleteDeleteDialog) return;
      setIsFocus(false);
      setFoldIncompleteWork(false);
    });

    useEffect(() => {
      const el = refScroll.current[focusedIndex];
      if (el) {
        if (isAutoComplete) refScrollContainer.current?.scrollTo({ top: Math.max(el.offsetTop - 309, 0), behavior: 'smooth' });
        else refScrollContainer.current?.scrollTo({ top: Math.max(el.offsetTop - 345, 0), behavior: 'smooth' });
      }
    }, [focusedIndex]);

    useUpdateEffect(() => {
      if (isAutoComplete && autoCompleteList.length > 0) {
        setFoldIncompleteWork(true);
      }
    }, [isAutoComplete]);

    useLayoutEffect(() => {
      handleRefresh();
    }, [foldIncompleteWork]);

    const handleRefresh = () => {
      if (foldIncompleteWork) setFocusedIndex(-1);
      if (multiSelectList.length > 0) setMultiSelectList([]);
      if (multiSelect) setMultiSelect(false);
      // setIncompleteWork(null);
    };

    const handleAutoCompleteTitle = (title: string) => {
      setAutoCompleteTitle(title);
    };

    const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!refFrequentInput.current) return;

      const value = refFrequentInput.current?.value;
      if (e.key === 'Escape') {
        if (value) {
          refFrequentInput.current.blur();
          return;
        }
        e.preventDefault();
        refFrequentInput.current.blur();
        if (quickCategory) setQuickCategory(null);
        if (quickDeadline) setQuickDeadline(null);
        setIsFocus(false);
        setFoldIncompleteWork(false);
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        if (focusedIndex >= 0) {
          const update = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWorkList[focusedIndex].id!, {
            start: { date: dayjs(date).format(DATE_FORMAT_4) },
            end: { date: dayjs(date).format(DATE_FORMAT_4) },
          });

          if (update) {
            onFetchEntireTaskboxList();
            setFoldIncompleteWork(false);
            setFocusedIndex(-1);
            onChange(date!);
          }
        } else {
          refFrequentInput.current.value = '';
          const newTaskbox: CreateTaskbox = {
            title: value,
            done: doneStatus,
            categoryId: quickCategory ? [quickCategory.id] : [],
            deadline: quickDeadline ? quickDeadline : null,
          };
          onCreate?.(newTaskbox);
          if (quickCategory) setQuickCategory(null);
          if (quickDeadline) setQuickDeadline(null);
          setIsAutoComplete(false);
          setFoldIncompleteWork(false);
        }
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (e.nativeEvent.isComposing) return;
        setFoldIncompleteWork(true);
        if (foldIncompleteWork) {
          if (isAutoComplete) setFocusedIndex((prevIndex) => (prevIndex < autoCompleteList.length - 1 ? prevIndex + 1 : prevIndex));
          else setFocusedIndex((prevIndex) => (prevIndex < incompleteWorkList.length - 1 ? prevIndex + 1 : prevIndex));
        } else {
          setFocusedIndex(-1);
        }
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (e.nativeEvent.isComposing) return;
        if (!foldIncompleteWork) return;
        if (focusedIndex === 0 || focusedIndex === -1) setFoldIncompleteWork(false);
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
      }

      if (e.key === '#') {
        setTimeout(() => {
          setCategoryAnchorEl(refFrequentInput.current);
        }, 100);
      }

      if (e.key === '$') {
        setTimeout(() => {
          setDeadlineAnchorEl(refFrequentInput.current);
        }, 100);
      }
    };

    const handleFocus = () => {
      setIsFocus(true);
      refFrequentInput.current?.focus();
    };

    const handleFilterTitle = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value.trim();
      if (!inputValue) {
        setFoldIncompleteWork(false);
        setIsAutoComplete(false);
      } else {
        handleAutoCompleteTitle(inputValue);
        onFetchAutoCompleteList(inputValue);
        await new Promise((resolve) => setTimeout(resolve, 200));
        setIsAutoComplete(true);
      }
    }, 500);

    const handlePullIncompleteWork = async (taskbox: IncompleteWorkList) => {
      const update = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        title: taskbox.title,
        start: { date: dayjs(date).format(DATE_FORMAT_4) },
        end: { date: dayjs(date).format(DATE_FORMAT_4) },
        tasks: taskbox.tasks as UpdateTaskForTaskBox[],
        done: doneStatus,
        beforeId: null,
      });

      if (update) {
        setOpenIncompleteDetailDialog(false);
        setFoldIncompleteWork(false);
        onFetchEntireTaskboxList();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
        onChange(date!);
      }
    };

    const handleClickIncompleteDetailDialog = (incompleteWork: IncompleteWorkList) => {
      setIncompleteWork(incompleteWork);
      setOpenIncompleteDetailDialog(true);
    };

    const handleClickIncompleteLater = async (incompleteWork: IncompleteWorkList) => {
      const result = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, { workSectionType: 'someday' });
      if (result) {
        toast.success(language === 'ko' ? '미완료 업무를 나중에로 옮겼습니다.' : 'Uncompleted tasks moved to Later.');
        setOpenIncompleteDetailDialog(false);
        onFetchEntireTaskboxList && onFetchEntireTaskboxList();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handleClickIncompleteDeleteDialog = (incompleteWork: IncompleteWorkList) => {
      setIncompleteWork(incompleteWork);
      setOpenIncompleteDeleteDialog(true);
    };

    const handleCloseIncompleteDeleteDialog = () => {
      setOpenIncompleteDeleteDialog(false);
    };

    const handleDeleteIncompleteWork = async (incompleteWork: IncompleteWorkList) => {
      const result = await removeTaskboxV1TaskboxesTaskboxIdDelete(incompleteWork.id!);
      if (result) {
        toast.success(language === 'ko' ? '미완료 업무를 삭제하였습니다.' : 'Deleted uncompleted tasks.');
        setOpenIncompleteDetailDialog(false);
        onFetchEntireTaskboxList && onFetchEntireTaskboxList();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handlePutIncompleteWork = async (incompleteWork: IncompleteWorkList) => {
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, {
        ...incompleteWork,
        categoryId: incompleteWork.category ? [incompleteWork.category.id] : [],
        tasks: incompleteWork.tasks!.map((task) => ({ ...task, categoryId: task.category ? [task.category?.id] : [] })) as UpdateTaskForTaskBox[],
      });

      if (incompleteWork.done) {
        await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, {
          done: true,
        });
      }
      // const success2 = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork.id!, { tasks: incompleteWork.tasks });
      if (success) {
        setOpenIncompleteDetailDialog(false);
        onFetchEntireTaskboxList && onFetchEntireTaskboxList();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handleChangeIncompleteWork = (taskbox: IncompleteWorkList) => {
      setIncompleteWork({ ...taskbox });
    };

    const handleChangeIncompleteTaskBlocks = (tasks: { id: string; content?: string; done?: boolean; dueDate?: string }[] = []) => {
      const newTasks = tasks.map((task) => ({ ...task, simple: true }));
      setIncompleteWork({ ...incompleteWork!, done: newTasks.filter((v) => v.done).length === newTasks.length ? true : false, tasks: newTasks });
    };

    const handleClickMultiSelect = () => {
      setMultiSelect(!multiSelect);
      if (multiSelectList.length > 0) {
        setMultiSelectList([]);
      }
    };

    const handleMultiSelectList = (id: string) => {
      if (multiSelectList.includes(id)) {
        setMultiSelectList(multiSelectList.filter((item) => item !== id));
      } else {
        setMultiSelectList([...multiSelectList, id]);
      }
    };

    const handleCancelMultiSelectList = () => {
      setMultiSelectList([]);
    };

    const handleClickSelectAllMultiSelect = () => {
      setMultiSelectList(incompleteWorkList.map((item) => item.id!).slice(0, 10));
    };

    const handleDeleteMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(list.map(async (item) => await removeTaskboxV1TaskboxesTaskboxIdDelete(item.id!)));

      if (success) {
        onFetchEntireTaskboxList();
        toast.success(language === 'ko' ? '선택된 미완료 업무를 삭제했습니다.' : 'Deleted selected uncompleted tasks.');
      } else {
        toast.error(language === 'ko' ? '선택된 미완료 업무를 삭제하는데 실패했습니다.' : 'Failed to delete selected uncompleted tasks.');
      }
    };

    const handleLaterMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(
        list.map(
          async (item) =>
            await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, {
              workSectionType: 'someday',
            }),
        ),
      );

      if (success) {
        onFetchEntireTaskboxList();
        fetchTasks();
        toast.success(language === 'ko' ? '선택된 미완료 업무를 나중에로 보냈습니다.' : 'Moved selected uncompleted tasks to Later.');
      } else {
        toast.error(language === 'ko' ? '선택된 미완료 업무를 나중에로 보내는데 실패했습니다.' : 'Failed to move selected uncompleted tasks to Later.');
      }
    };

    const handlePullMultiSelectList = async () => {
      setFoldIncompleteWork(false);
      const list: OutTaskboxDetail[] = [];
      incompleteWorkList.map((item) => {
        if (multiSelectList.includes(item.id!)) {
          list.push(item);
        }
      });
      if (list.length === 0) return;

      const success = await Promise.all(
        list.map(
          async (item) =>
            await updateTaskboxV1TaskboxesTaskboxIdPut(item.id!, {
              start: { date: dayjs(date).format(DATE_FORMAT_4) },
              end: { date: dayjs(date).format(DATE_FORMAT_4) },
              done: doneStatus,
            }),
        ),
      );

      if (success) {
        onChange(date!);
        onFetchEntireTaskboxList();
        toast.success(language === 'ko' ? '선택된 미완료 업무를 가져왔습니다.' : 'Retrieved selected uncompleted tasks.');
      } else {
        toast.error(language === 'ko' ? '선택된 미완료 업무를 가져오는데 실패했습니다.' : 'Failed to retrieve selected uncompleted tasks.');
      }
    };

    const handleIncompleteWorkCheckbox = async (incompleteWork: IncompleteWorkList) => {
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(incompleteWork!.id!, {
        done: true,
      });
      if (success) {
        if (openIncompleteDetailDialog) setOpenIncompleteDetailDialog(false);
        onFetchEntireTaskboxList && onFetchEntireTaskboxList();
        if (autoCompleteTitle) onFetchAutoCompleteList && onFetchAutoCompleteList(autoCompleteTitle);
      }
    };

    const handleIncompleteWorkDetailCheckbox = async (incompleteWork: IncompleteWorkList) => {
      if (incompleteWork.done) {
        const newTasks = incompleteWork!.tasks!.map((task) => ({ ...task, done: false }));
        setIncompleteWork({ ...incompleteWork, done: false, tasks: newTasks });
      } else {
        const newTasks = incompleteWork!.tasks!.map((task) => ({ ...task, done: true }));
        setIncompleteWork({ ...incompleteWork, done: true, tasks: newTasks });
      }
    };

    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
    const [quickCategory, setQuickCategory] = useState<OutCategory | null>(null);
    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
    const [quickDeadline, setQuickDeadline] = useState<string | null>(null);

    const handleClickQuickCategory = (category: OutCategory | null, action: CategoryActionType) => {
      if (action === 'SELECT') {
        setQuickCategory(category);
        setCategoryAnchorEl(null);

        // 마지막 텍스트가 #이면 #을 지워준다.
        const value = refFrequentInput.current?.value;
        if (value && value.includes('#')) {
          refFrequentInput.current.value = value.replace(/#/g, '');
        }
      } else {
        onClickCategoryActions && onClickCategoryActions(category!, action);
      }
    };

    const handleClickQuickDeadline = (date: Dayjs | null) => {
      setQuickDeadline(date ? date.format(DATE_FORMAT_4) : null);

      setTimeout(() => {
        setDeadlineAnchorEl(null);
      }, 100);

      // 마지막 텍스트가 $이면 $을 지워준다.
      const value = refFrequentInput.current?.value;
      if (value && value.includes('$')) {
        refFrequentInput.current.value = value.replace(/\$/g, '');
      }
    };

    return (
      <Container ref={refCloseInput}>
        <CreateTaskboxWrapper foldIncompleteWork={foldIncompleteWork}>
          <TaskboxInputWrapper>
            {isFocus ? (
              <IssueCheckBox width={18} height={18} />
            ) : (
              <div onClick={handleFocus} style={{ cursor: 'pointer' }}>
                <Icons.Plus width={18} height={18} fill={COLORS.gray400} />
              </div>
            )}
            <TextField
              id={dayjs().isSame(dayjs(date), 'date') ? 'taskboard-input' : ''}
              inputRef={refFrequentInput}
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder={
                doneStatus
                  ? isFocus
                    ? language === 'ko'
                      ? '완료한 일을 입력해주세요'
                      : 'Please enter completed task'
                    : language === 'ko'
                    ? '완료한 일 입력하기'
                    : 'Enter completed task'
                  : isFocus
                  ? language === 'ko'
                    ? '미완료한 일을 입력해주세요'
                    : 'Please enter incompleted task'
                  : language === 'ko'
                  ? '미완료한 일 입력하기'
                  : 'Enter incompleted task'
              }
              onKeyDown={handleKeydownInput}
              onFocus={handleFocus}
              onChange={handleFilterTitle}
              InputProps={{ disableUnderline: true, style: { fontSize: 13, fontWeight: 'bold', color: COLORS.gray800 } }}
              style={{ marginLeft: isFocus ? '8px' : '5.5px', flex: 1 }}
            />
            {quickCategory && (
              <CategoryShowingWrapper textColor={getCategoryTextColor(quickCategory.color)} bgColor={getCategoryBgColor(quickCategory.color)}>
                {`# ${quickCategory.name}`}
              </CategoryShowingWrapper>
            )}
            {quickDeadline && (
              <DeadlineShowingWrapper date={quickDeadline}>
                <Icons.Flag
                  fill={dayjs(quickDeadline).isToday() ? COLORS.brand1 : dayjs(quickDeadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                />
                <span style={{ marginLeft: '2px' }}>
                  {dayjs(quickDeadline).isToday()
                    ? language === 'ko'
                      ? '오늘'
                      : 'Today'
                    : dayjs(quickDeadline).isYesterday()
                    ? language === 'ko'
                      ? '어제'
                      : 'Yesterday'
                    : dayjs(quickDeadline).isTomorrow()
                    ? language === 'ko'
                      ? '내일'
                      : 'Tomorrow'
                    : language === 'ko'
                    ? dayjs(quickDeadline).format('M월 D일 (dd)')
                    : dayjs(quickDeadline).format('dddd, MMM D')}
                </span>
              </DeadlineShowingWrapper>
            )}
            {/* {dayjs().isSame(dayjs(date), 'date') && !isFocus && (
              <TaskboardInputPlaceholder>
                <div style={{ width: '16px', height: '16px', backgroundColor: COLORS.gray100, textAlign: 'center', borderRadius: '2px', marginRight: '4px' }}>
                  /
                </div>
                <div>로 입력 시작하기</div>
              </TaskboardInputPlaceholder>
            )} */}
            {incompleteWorkList.length > 0 && (
              <Tooltip
                title={
                  language === 'ko' ? (
                    <IncompleteWorkKeyDownWrapper>
                      <IncompleteWorkKeyDownIcon style={{ marginRight: '4px', transform: foldIncompleteWork ? 'rotate(90deg)' : 'rotate(270deg)' }}>
                        <Icons.ArrowBack stroke={COLORS.gray500} />
                      </IncompleteWorkKeyDownIcon>
                      <span>{foldIncompleteWork ? '으로 완료되지 않은 업무 접기' : '으로 완료되지 않은 업무 보기'}</span>
                    </IncompleteWorkKeyDownWrapper>
                  ) : (
                    <IncompleteWorkKeyDownWrapper>
                      <span>{foldIncompleteWork ? 'Collapse incompleted tasks with' : 'Expand incompleted tasks with'}</span>
                      <IncompleteWorkKeyDownIcon style={{ marginLeft: '4px', transform: foldIncompleteWork ? 'rotate(90deg)' : 'rotate(270deg)' }}>
                        <Icons.ArrowBack stroke={COLORS.gray500} />
                      </IncompleteWorkKeyDownIcon>
                    </IncompleteWorkKeyDownWrapper>
                  )
                }
                disableInteractive
              >
                <IncompleteWorkFoldWrpapper>
                  {foldIncompleteWork ? (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => {
                        setFoldIncompleteWork(false);
                      }}
                    >
                      <Icons.ArrowUpSmall width={20} height={20} fill={COLORS.brand1} />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                          onFetchEntireTaskboxList();
                          setFoldIncompleteWork(true);
                        }}
                      >
                        <Icons.ArrowDownSmall width={20} height={20} fill={COLORS.brand1} />
                      </IconButton>
                      {incompleteWorkList.length > 0 && <IncompleteWorkRedDot />}
                    </>
                  )}
                </IncompleteWorkFoldWrpapper>
              </Tooltip>
            )}
          </TaskboxInputWrapper>
          {foldIncompleteWork && (
            <>
              {((isAutoComplete && autoCompleteList.length > 0) || (!isAutoComplete && incompleteWorkList.length > 0)) && <Divider style={{ width: '94%' }} />}
              {autoCompleteList.length > 0 && isAutoComplete && (
                <IncompleteWorkContainer>
                  <IncompleteWorkListContentContainer id="incomplete-work-list" ref={refScrollContainer} style={{ maxHeight: '288px' }}>
                    {autoCompleteList.map((item, index) => (
                      <IncompleteWorkListContent
                        key={item.id}
                        focus={focusedIndex === index}
                        data-incomplete-index={index}
                        onClick={() => {
                          handlePullIncompleteWork(item);
                        }}
                        onMouseLeave={() => {
                          setIsHoverCheckbox(false);
                          setHoverCheckboxId(null);
                        }}
                        ref={(elem) => (refScroll ? (refScroll.current[index] = elem) : {})}
                      >
                        <IncompleteWorkListContentInfo>
                          {multiSelect && (
                            <>
                              <Checkbox
                                checked={multiSelectList.includes(item.id!)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMultiSelectList(item.id!);
                                }}
                                sx={{ padding: '0px' }}
                              />
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                            </>
                          )}
                          {isHoverCheckbox && hoverCheckboxId === item.id ? (
                            <div
                              onMouseLeave={() => {
                                setIsHoverCheckbox(false);
                                setHoverCheckboxId(null);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleIncompleteWorkCheckbox(item);
                              }}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.5" cx="8" cy="8" r="8" fill="#2D6ADF" />
                                <circle cx="8" cy="8" r="7.5" stroke="#0039A7" />
                                <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          ) : item.tasks!.filter((v) => v.done).length! > 0 ? (
                            <div
                              onMouseEnter={() => {
                                setIsHoverCheckbox(true);
                                setHoverCheckboxId(item.id!);
                              }}
                            >
                              <Icons.InprogressCheck width={16} height={16} />
                            </div>
                          ) : (
                            item.tasks!.filter((v) => v.done).length! === 0 && (
                              <div
                                onMouseEnter={() => {
                                  setIsHoverCheckbox(true);
                                  setHoverCheckboxId(item.id!);
                                }}
                              >
                                <IssueCheckBox height={16} width={16} />
                              </div>
                            )
                          )}
                          <IncompleteWorkListContentInfoTitle>{item.title}</IncompleteWorkListContentInfoTitle>
                          {item.category && (
                            <CategoryShowingWrapper textColor={getCategoryTextColor(item.category.color)} bgColor={getCategoryBgColor(item.category.color)}>
                              {item.category.name}
                            </CategoryShowingWrapper>
                          )}
                          {item.deadline && (
                            <DeadlineShowingWrapper style={{ color: COLORS.negative1, backgroundColor: COLORS.negative2 }}>
                              <Icons.Flag
                                fill={
                                  dayjs(item.deadline).isToday() ? COLORS.brand1 : dayjs(item.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                }
                              />
                              <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? language === 'ko'
                                    ? '오늘'
                                    : 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? language === 'ko'
                                    ? '어제'
                                    : 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? language === 'ko'
                                    ? '내일'
                                    : 'Tomorrow'
                                  : language === 'ko'
                                  ? dayjs(item.deadline).format('M월 D일 (dd)')
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span>
                            </DeadlineShowingWrapper>
                          )}
                          {item.memo && <Icons.Memo />}
                          {item.links && item.links.length > 0 && <Icons.Link />}
                        </IncompleteWorkListContentInfo>
                        <IncompleteWorkListDueDate className="incomplete-duedate">
                          {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day')
                            ? language === 'ko'
                              ? '어제'
                              : 'Yesterday'
                            : item.date}
                        </IncompleteWorkListDueDate>
                        <IncompleteWorkListOptions
                          className="incomplete-options"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {focusedIndex === index && (
                            <AddIncompleteWorkWithEnter style={{ display: 'flex', alignItems: 'center' }}>
                              {language === 'ko' ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginRight: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div>로 추가하기</div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              ) : (
                                <>
                                  <div>Add with</div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginLeft: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              )}
                            </AddIncompleteWorkWithEnter>
                          )}
                          <IncompleteDetailButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDetailDialog(item);
                            }}
                          >
                            {language === 'ko' ? '상세보기' : 'Details'}
                          </IncompleteDetailButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteLater(item);
                            }}
                            sx={{
                              'margin': '0px 8px',
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDeleteDialog(item);
                            }}
                            sx={{
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Delete fill={COLORS.gray400} />
                          </IconButton>
                        </IncompleteWorkListOptions>
                      </IncompleteWorkListContent>
                    ))}
                  </IncompleteWorkListContentContainer>
                </IncompleteWorkContainer>
              )}

              {incompleteWorkList.length > 0 && !isAutoComplete && (
                <IncompleteWorkContainer>
                  {multiSelect ? (
                    <IncompleteWorkListHeaderContainer>
                      <IncompleteWorkListHeaderTitle>
                        <IconButton
                          onClick={handleClickMultiSelect}
                          sx={{
                            padding: '0px',
                            marginRight: '4px',
                          }}
                        >
                          <Icons.ArrowLeftSmall fill={COLORS.gray400} />
                        </IconButton>
                        <IncompleteWorkHeaderTitleText>{language === 'ko' ? '완료되지 않은 업무' : 'Incomplete taskboxes'}</IncompleteWorkHeaderTitleText>
                      </IncompleteWorkListHeaderTitle>
                      <IncompleteWorkMultiSelectionOptions>
                        {multiSelectList.length > 0 ? (
                          <SelectMultiSelection>
                            {language === 'ko' ? `${multiSelectList.length}개 선택` : `${multiSelectList.length} selected`}
                            <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 4px' }} />
                            <CancelMultiSelect onClick={handleCancelMultiSelectList}>{language === 'ko' ? '취소' : 'Cancel'}</CancelMultiSelect>
                          </SelectMultiSelection>
                        ) : (
                          <SelectAllMultiSelection onClick={handleClickSelectAllMultiSelect}>
                            {language === 'ko' ? '전체 선택' : 'Select all'}
                          </SelectAllMultiSelection>
                        )}
                        <MultiSelectionIcon>
                          <IconButton disabled={multiSelectList.length === 0} onClick={handleLaterMultiSelectList} sx={{ padding: '4px', borderRadius: '8px' }}>
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            disabled={multiSelectList.length === 0}
                            onClick={() => setOpenIncompleteDeleteDialog(true)}
                            sx={{ padding: '4px', borderRadius: '8px' }}
                          >
                            <Icons.Delete width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                        </MultiSelectionIcon>
                        <Button
                          disabled={multiSelectList.length === 0}
                          variant="contained"
                          disableElevation
                          disableRipple
                          onClick={handlePullMultiSelectList}
                          sx={{ height: '28px', padding: '4px 12px', borderRadius: '8px', fontSize: '12px', fontWeight: 700 }}
                        >
                          {language === 'ko' ? '추가하기' : 'Add'}
                        </Button>
                      </IncompleteWorkMultiSelectionOptions>
                    </IncompleteWorkListHeaderContainer>
                  ) : (
                    <IncompleteWorkListHeaderContainer>
                      <IncompleteWorkListHeaderTitle>
                        <IncompleteWorkHeaderTitleText>{language === 'ko' ? '완료되지 않은 업무' : 'Incomplete taskboxes'}</IncompleteWorkHeaderTitleText>
                      </IncompleteWorkListHeaderTitle>
                      <IncompleteWorkMultiSelect onClick={handleClickMultiSelect}>{language === 'ko' ? '다중 선택' : 'Multi select'}</IncompleteWorkMultiSelect>
                    </IncompleteWorkListHeaderContainer>
                  )}

                  <IncompleteWorkListContentContainer id="incomplete-work-list" ref={refScrollContainer} style={{ maxHeight: '288px' }}>
                    {incompleteWorkList.map((item, index) => (
                      <IncompleteWorkListContent
                        key={item.id}
                        focus={focusedIndex === index}
                        data-incomplete-index={index}
                        onClick={() => {
                          handlePullIncompleteWork(item);
                        }}
                        onMouseLeave={() => {
                          setIsHoverCheckbox(false);
                          setHoverCheckboxId(null);
                        }}
                        ref={(elem) => (refScroll ? (refScroll.current[index] = elem) : {})}
                      >
                        <IncompleteWorkListContentInfo>
                          {multiSelect && (
                            <>
                              <Checkbox
                                checked={multiSelectList.includes(item.id!)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMultiSelectList(item.id!);
                                }}
                                sx={{ padding: '0px' }}
                              />
                              <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                            </>
                          )}
                          {isHoverCheckbox && hoverCheckboxId === item.id ? (
                            <div
                              onMouseLeave={() => {
                                setIsHoverCheckbox(false);
                                setHoverCheckboxId(null);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleIncompleteWorkCheckbox(item);
                              }}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle opacity="0.5" cx="8" cy="8" r="8" fill="#2D6ADF" />
                                <circle cx="8" cy="8" r="7.5" stroke="#0039A7" />
                                <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                          ) : item.tasks!.filter((v) => v.done).length! > 0 ? (
                            <div
                              onMouseEnter={() => {
                                setIsHoverCheckbox(true);
                                setHoverCheckboxId(item.id!);
                              }}
                            >
                              <Icons.InprogressCheck width={16} height={16} />
                            </div>
                          ) : (
                            item.tasks!.filter((v) => v.done).length! === 0 && (
                              <div
                                onMouseEnter={() => {
                                  setIsHoverCheckbox(true);
                                  setHoverCheckboxId(item.id!);
                                }}
                              >
                                <IssueCheckBox height={16} width={16} />
                              </div>
                            )
                          )}
                          <IncompleteWorkListContentInfoTitle>{item.title}</IncompleteWorkListContentInfoTitle>
                          {item.category && (
                            <CategoryShowingWrapper textColor={getCategoryTextColor(item.category.color)} bgColor={getCategoryBgColor(item.category.color)}>
                              {item.category.name}
                            </CategoryShowingWrapper>
                          )}
                          {item.deadline && (
                            <DeadlineShowingWrapper date={item.deadline}>
                              <Icons.Flag
                                fill={
                                  dayjs(item.deadline).isToday() ? COLORS.brand1 : dayjs(item.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                }
                              />
                              <span style={{ marginLeft: '2px' }}>
                                {dayjs(item.deadline).isToday()
                                  ? language === 'ko'
                                    ? '오늘'
                                    : 'Today'
                                  : dayjs(item.deadline).isYesterday()
                                  ? language === 'ko'
                                    ? '어제'
                                    : 'Yesterday'
                                  : dayjs(item.deadline).isTomorrow()
                                  ? language === 'ko'
                                    ? '내일'
                                    : 'Tomorrow'
                                  : language === 'ko'
                                  ? dayjs(item.deadline).format('M월 D일 (dd)')
                                  : dayjs(item.deadline).format('dddd, MMM D')}
                              </span>
                            </DeadlineShowingWrapper>
                          )}
                          {item.memo && <Icons.Memo />}
                          {item.links && item.links.length > 0 && <Icons.Link />}
                          {item.tasks!.length > 0 && (
                            <IncompleteWorkListContentInfoSubtask>
                              <Icons.Subtask stroke={COLORS.gray300} />
                              <div style={{ margin: '4px 0px 0px 2px' }}>{item.tasks?.length}</div>
                            </IncompleteWorkListContentInfoSubtask>
                          )}
                        </IncompleteWorkListContentInfo>
                        <IncompleteWorkListDueDate className="incomplete-duedate">
                          {dayjs(item.start?.date || item.start?.datetime).isSame(dayjs(date).subtract(1, 'day'), 'day')
                            ? language === 'ko'
                              ? '어제'
                              : 'Yesterday'
                            : item.date}
                        </IncompleteWorkListDueDate>
                        <IncompleteWorkListOptions
                          className="incomplete-options"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {focusedIndex === index && (
                            <AddIncompleteWorkWithEnter style={{ display: 'flex', alignItems: 'center' }}>
                              {language === 'ko' ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginRight: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div>로 추가하기</div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              ) : (
                                <>
                                  <div>Add with</div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '2px 4px',
                                      backgroundColor: COLORS.white,
                                      border: `0.5px solid ${COLORS.gray400}`,
                                      borderRadius: '2px',
                                      marginLeft: '4px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    Enter
                                  </div>
                                  <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                </>
                              )}
                            </AddIncompleteWorkWithEnter>
                          )}
                          <IncompleteDetailButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDetailDialog(item);
                            }}
                          >
                            {language === 'ko' ? '상세보기' : 'Details'}
                          </IncompleteDetailButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteLater(item);
                            }}
                            sx={{
                              'margin': '0px 8px',
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClickIncompleteDeleteDialog(item);
                            }}
                            sx={{
                              'padding': '0px',
                              '&:hover': {
                                filter:
                                  'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);',
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <Icons.Delete fill={COLORS.gray400} />
                          </IconButton>
                        </IncompleteWorkListOptions>
                      </IncompleteWorkListContent>
                    ))}
                  </IncompleteWorkListContentContainer>
                </IncompleteWorkContainer>
              )}
            </>
          )}
        </CreateTaskboxWrapper>
        {/* 태스크박스 카테고리 */}
        {categoryAnchorEl && (
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setCategoryAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickQuickCategory} />
          </Popover>
        )}
        {/* 태스크박스 기한 */}
        {deadlineAnchorEl && (
          <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setDeadlineAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickQuickDeadline} />
          </Popover>
        )}
        <Dialog
          open={openIncompleteDetailDialog}
          onClose={() => {
            handlePutIncompleteWork(incompleteWork!);
          }}
        >
          <IncompleteWorkDetail
            incompleteWork={incompleteWork!}
            categoryList={categoryList}
            onClickAddTaskbox={handlePullIncompleteWork}
            onClickLater={handleClickIncompleteLater}
            onClickDelete={handleDeleteIncompleteWork}
            onClickCheckbox={handleIncompleteWorkDetailCheckbox}
            onChangeTaskBlocks={handleChangeIncompleteTaskBlocks}
            onChange={handleChangeIncompleteWork}
            onClickCategory={onClickCategoryActions}
          />
        </Dialog>
        <Dialog
          open={openIncompleteDeleteDialog}
          onClose={handleCloseIncompleteDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}`, textAlign: 'center' }}>
            {language === 'ko' ? '미완료 업무를 삭제하시겠어요?' : 'Delete incomplete tasks?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
              {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIncompleteDeleteDialog}>{language === 'ko' ? '취소' : 'Cancel'}</Button>
            <Button
              onClick={() => {
                multiSelectList.length > 0 ? handleDeleteMultiSelectList() : handleDeleteIncompleteWork(incompleteWork!);
                handleCloseIncompleteDeleteDialog();
              }}
            >
              {language === 'ko' ? '삭제' : 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  },
);

export default CreateTaskboxInput;

const Container = styled.div`
  width: 100%;
  height: 36px;
  position: relative;
`;

const TaskboxInputWrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
`;

const TaskboardInputPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
`;

const IncompleteWorkKeyDownWrapper = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  display: flex;
  align-items: center;
`;

const IncompleteWorkKeyDownIcon = styled.div`
  width: 16px;
  height: 16px;
  padding: 4px;
  border-radius: 2px;
  background-color: ${COLORS.gray100};
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IncompleteWorkFoldWrpapper = styled.div`
  position: relative;
`;

const IncompleteWorkRedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${COLORS.negative1};
  position: absolute;
  top: 0px;
  left: 16px;
`;

const CreateTaskboxWrapper = styled.div<{ foldIncompleteWork: boolean }>`
  position: absolute;
  width: 100%;
  background-color: ${COLORS.white};
  z-index: 5;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.foldIncompleteWork &&
    css`
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
      z-index: 6;
    `}
`;

const IncompleteWorkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  .MuiButton-root {
    &:hover {
      border: none;
      border-top: 1px solid ${COLORS.gray200};
    }
  }
`;

const IncompleteWorkListHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 12px;
`;

const IncompleteWorkListHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const IncompleteWorkHeaderTitleText = styled.div`
  font-size: 12px;
  color: ${COLORS.gray500};
`;

const IncompleteWorkMultiSelect = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray400};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const IncompleteWorkMultiSelectionOptions = styled.div`
  display: flex;
  align-items: center;
`;

const SelectMultiSelection = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${COLORS.gray400};
`;

const CancelMultiSelect = styled.div`
  cursor: pointer;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const SelectAllMultiSelection = styled.div`
  font-size: 12px;
  color: ${COLORS.gray400};
  cursor: pointer;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const MultiSelectionIcon = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  margin: 0px 8px;
  padding: 2px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
`;

const IncompleteWorkListContentContainer = styled.div`
  max-height: 180px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const IncompleteWorkListContent = styled.div<{ focus?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  height: 36px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
    .incomplete-options {
      opacity: 1;
    }
    .incomplete-duedate {
      opacity: 0;
    }
  }

  ${(props) =>
    props.focus &&
    css`
      background-color: ${COLORS.gray100};
      .incomplete-options {
        opacity: 1;
      }
      .incomplete-duedate {
        opacity: 0;
      }
    `}
`;

const IncompleteWorkListContentInfo = styled.div`
  display: flex;
  align-items: center;
`;

const IncompleteWorkListContentInfoTitle = styled.div`
  color: ${COLORS.gray900};
  font-size: 16px;
  font-weight: 600;
  margin: 0px 8px;
  max-width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IncompleteWorkListContentInfoSubtask = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${COLORS.gray300};
`;

const IncompleteWorkListDueDate = styled.div`
  color: ${COLORS.negative1};
  font-size: 10px;
`;

const IncompleteWorkListOptions = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  opacity: 0;
`;

const AddIncompleteWorkWithEnter = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray400};
  font-size: 10px;
  font-weight: 700;
`;

const IncompleteDetailButton = styled.div`
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray400};
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const IncompletePropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 24px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;
/** */

/** IncompleteWorkDetail */
interface IncompleteWorkDetailProps {
  incompleteWork?: IncompleteWorkList;
  categoryList?: OutCategory[];
  onClickAddTaskbox?: (incompleteWork: IncompleteWorkList) => void;
  onClickLater?: (incompleteWork: IncompleteWorkList) => void;
  onClickDelete?: (incompleteWork: IncompleteWorkList) => void;
  onClickCheckbox?: (incompleteWork: IncompleteWorkList) => void;
  onChangeTaskBlocks?: (tasks: { id: string; content?: string; dueDate?: string }[]) => void;
  onChange?: (incompleteWork: IncompleteWorkList) => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
}

const IncompleteWorkDetail = ({
  incompleteWork,
  categoryList = [],
  onClickAddTaskbox = () => {},
  onClickLater = () => {},
  onClickDelete = () => {},
  onClickCheckbox = () => {},
  onChangeTaskBlocks = () => {},
  onChange = () => {},
  onClickCategory = () => {},
}: IncompleteWorkDetailProps) => {
  const [language] = useAtom(languageAtom);
  const refInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const [incompleteWorkDetail, setIncompleteWorkDetail] = useState<HTMLElement | null>(null);
  const [openIncompleteDeleteDialog, setDeleteIncomplete] = useState(false);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refTaskBlockList?.current?.add();
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleBlurInput = () => {
    if (!refInput.current) return;
    onChange({ ...incompleteWork!, title: refInput.current.value });
  };

  const handleCloseIncompleteWorkPopover = () => {
    setIncompleteWorkDetail(null);
  };

  const handleIncompleteWorkTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...incompleteWork!, title: e.target.value });
  };

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      onChange({ ...incompleteWork!, category: category });
      setCategoryAnchorEl(null);
    } else {
      if (action === 'DELETE') {
        const updatedTasks = incompleteWork?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: undefined };
          }
          return task;
        });
        onChange({ ...incompleteWork!, category: null, tasks: updatedTasks });
        setCategoryAnchorEl(null);
      } else if (action === 'UPDATE') {
        const updatedTasks = incompleteWork?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: category! };
          }
          return task;
        });
        onChange({
          ...incompleteWork!,
          category: incompleteWork?.category && incompleteWork!.category!.id === category!.id ? category : incompleteWork?.category,
          tasks: updatedTasks,
        });
      }
      onClickCategory(category, action);
    }
  };

  const handleClickDeadline = (date: Dayjs | null) => {
    onChange({ ...incompleteWork!, deadline: date ? date.format('YYYY-MM-DD') : null });
    setDeadlineAnchorEl(null);
  };

  return (
    <IncompleteWorkDialogWrapper>
      <IncompletePropertyWrapper>
        {incompleteWork && incompleteWork.category ? (
          <Tooltip title={language === 'ko' ? '카테고리 설정하기' : 'Set category'} disableInteractive>
            <CategoryShowingWrapper
              textColor={getCategoryTextColor(incompleteWork.category!.color)}
              bgColor={getCategoryBgColor(incompleteWork.category!.color)}
              onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
            >
              {`# ${incompleteWork.category.name}`}
              <IconButton
                className="category-detach-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickCategory(null, 'SELECT');
                }}
                sx={{
                  'width': '12px',
                  'height': '12px',
                  'borderRadius': '4px',
                  'marginLeft': '4px',
                  'marginTop': '1px',
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: hexToRGBA(getCategoryTextColor(incompleteWork.category!.color)!, 0.3),
                  },
                }}
                style={categoryAnchorEl ? { display: 'flex' } : {}}
              >
                <Icons.Close width={8} height={8} stroke={getCategoryTextColor(incompleteWork.category!.color)} />
              </IconButton>
            </CategoryShowingWrapper>
          </Tooltip>
        ) : (
          <Tooltip title={language === 'ko' ? '카테고리 설정하기' : 'Set category'} disableInteractive>
            <IconButton
              onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
              sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
            >
              <Icons.Hashtag />
            </IconButton>
          </Tooltip>
        )}
        {incompleteWork && incompleteWork.deadline ? (
          <Tooltip title={language === 'ko' ? '기한 설정하기' : 'Set deadline'} disableInteractive>
            <DeadlineShowingWrapper date={incompleteWork.deadline} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
              <Icons.Flag
                fill={
                  dayjs(incompleteWork.deadline).isToday()
                    ? COLORS.brand1
                    : dayjs(incompleteWork.deadline).isBefore(dayjs())
                    ? COLORS.negative1
                    : COLORS.gray600
                }
              />
              <span style={{ marginLeft: '2px' }}>
                {dayjs(incompleteWork.deadline).isToday()
                  ? language === 'ko'
                    ? '오늘'
                    : 'Today'
                  : dayjs(incompleteWork.deadline).isYesterday()
                  ? language === 'ko'
                    ? '어제'
                    : 'Yesterday'
                  : dayjs(incompleteWork.deadline).isTomorrow()
                  ? language === 'ko'
                    ? '내일'
                    : 'Tomorrow'
                  : language === 'ko'
                  ? dayjs(incompleteWork.deadline).format('M월 D일 (dd)')
                  : dayjs(incompleteWork.deadline).format('dddd, MMM D')}
              </span>
              <IconButton
                className="deadline-detach-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickDeadline(null);
                }}
                sx={{
                  'width': '12px',
                  'height': '12px',
                  'borderRadius': '4px',
                  'marginLeft': '4px',
                  'marginTop': '1px',
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: hexToRGBA(
                      dayjs(incompleteWork!.deadline).isToday()
                        ? COLORS.brand1
                        : dayjs(incompleteWork!.deadline).isBefore(dayjs())
                        ? COLORS.negative1
                        : COLORS.gray600,
                      0.3,
                    ),
                  },
                }}
                style={deadlineAnchorEl ? { display: 'flex' } : {}}
              >
                <Icons.Close
                  width={8}
                  height={8}
                  stroke={
                    dayjs(incompleteWork!.deadline).isToday()
                      ? COLORS.brand1
                      : dayjs(incompleteWork!.deadline).isBefore(dayjs())
                      ? COLORS.negative1
                      : COLORS.gray600
                  }
                />
              </IconButton>
            </DeadlineShowingWrapper>
          </Tooltip>
        ) : (
          <Tooltip title={language === 'ko' ? '기한 설정하기' : 'Set deadline'} disableInteractive>
            <IconButton
              onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
              sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
            >
              <Icons.Flag />
            </IconButton>
          </Tooltip>
        )}
      </IncompletePropertyWrapper>
      <IncompleteWorkToDo>
        {!incompleteWork!.done && incompleteWork!.tasks!.filter((v) => v.done).length > 0 ? (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.InprogressCheck width={20} height={20} />
          </div>
        ) : incompleteWork!.done ? (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.PriorityIssueCheck width={20} height={20} />
          </div>
        ) : (
          <div onClick={() => onClickCheckbox(incompleteWork!)} style={{ cursor: 'pointer' }}>
            <Icons.IssueCheckBox />
          </div>
        )}
        <TextField
          inputRef={refInput}
          autoComplete="off"
          fullWidth
          variant="standard"
          onKeyDown={handleKeydownInput}
          onBlur={handleBlurInput}
          onChange={(e) => {
            handleIncompleteWorkTitle(e);
          }}
          defaultValue={incompleteWork ? incompleteWork?.title : ''}
          InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
          style={{ marginLeft: 8, width: '99%' }}
        />
        {incompleteWork?.id && (
          <IconButton onClick={(e) => setIncompleteWorkDetail(e.currentTarget)} style={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}>
            <Icons.More width={16} height={16} fill={COLORS.gray300} />
          </IconButton>
        )}
      </IncompleteWorkToDo>
      <div style={{ paddingLeft: '4px' }}>
        <TaskBlockInputList
          ref={refTaskBlockList}
          suppressAddTime
          categoryList={categoryList}
          tasks={(incompleteWork?.tasks as TaskBlock[]) || []}
          onChange={onChangeTaskBlocks}
          onClickCategory={handleClickCategory}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
        <Button
          variant="contained"
          onClick={() => {
            onClickAddTaskbox(incompleteWork!);
          }}
          sx={{ borderRadius: '8px', textTransform: 'none' }}
        >
          {language === 'ko' ? '할 일에 추가하기' : 'Add to To-Do'}
        </Button>
      </div>
      <Popover
        open={Boolean(incompleteWorkDetail)}
        anchorEl={incompleteWorkDetail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseIncompleteWorkPopover}
        sx={{ marginTop: '4px' }}
      >
        <DetailOptionsModal>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              onClickLater(incompleteWork!);
            }}
            style={{ padding: '8px ' }}
          >
            <Icons.Later width={16} height={16} />
            <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '나중에' : 'Later'}</span>
          </Fonts.Blockquote>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              setDeleteIncomplete(true);
            }}
            style={{ color: `${COLORS.negative1}`, padding: '8px' }}
          >
            <Icons.Delete fill={COLORS.negative1} />
            <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '삭제' : 'Delete'}</span>
          </Fonts.Blockquote>
        </DetailOptionsModal>
      </Popover>
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickDeadline} />
        </Popover>
      )}
      <Dialog
        open={openIncompleteDeleteDialog}
        onClose={() => {
          setDeleteIncomplete(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}`, textAlign: 'center' }}>
          {language === 'ko' ? '미완료 업무를 삭제하시겠어요?' : 'Delete incomplete tasks?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteIncomplete(false);
            }}
            sx={{ textTransform: 'none' }}
          >
            {language === 'ko' ? '취소' : 'Cancel'}
          </Button>
          <Button
            onClick={() => {
              setDeleteIncomplete(false);
              setIncompleteWorkDetail(null);
              setTimeout(() => {
                onClickDelete(incompleteWork!);
              }, 200);
            }}
            sx={{ textTransform: 'none' }}
          >
            {language === 'ko' ? '삭제' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </IncompleteWorkDialogWrapper>
  );
};

const IncompleteWorkDialogWrapper = styled.div`
  padding: 24px 22px 24px 0px;
  width: 540px;
`;

const IncompleteWorkToDo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 24px;
`;

const IncompleteWorkDialogBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DetailOptionsModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 8px;
  z-index: 10;

  .detail-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;
