import { SVGProps } from 'react';
import { ProgressPlugin } from 'webpack';

export const ArrowBack = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.6673 8H3.33398M3.33398 8L7.33398 12M3.33398 8L7.33398 4"
        stroke={props.stroke ? props.stroke : '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
