import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface ButtonProps {
  primary?: boolean;
  backgroundColor?: string;
  height?: 64 | 48 | 40 | 32;
  width?: number;
  label: string;
  disabled?: boolean;
  onClick: () => void;
}

export const Button = ({ primary = false, height = 40, width, label, disabled = false, onClick }: ButtonProps) => {
  if (height === 64) {
    return (
      <Wrapper onClick={onClick} height={height} width={width} primary={primary} disable={disabled}>
        <Fonts.H3>{label}</Fonts.H3>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper onClick={onClick} height={height} width={width} primary={primary} disable={disabled}>
        <Fonts.H4>{label}</Fonts.H4>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div<{
  height: number;
  width?: number;
  primary: boolean;
  disable: boolean;
}>`
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  ${(props) =>
    props.height === 64
      ? css`
          padding: 0px 32px;
        `
      : props.height === 32
      ? css`
          padding: 0px 12px;
        `
      : css`
          padding: 0px 16px;
        `}

  ${(props) =>
    props.height === 32 /* size 32일 때 */
      ? props.primary
        ? css`
            /* primary */
            color: ${COLORS.white};
            background-color: ${COLORS.gray800};
            &:hover {
              background-color: ${COLORS.gray700};
            }
            &:active {
              background-color: ${COLORS.gray900};
            }
          `
        : css`
            /* secondary */
            color: ${COLORS.gray900};
            border: 1px solid ${COLORS.gray200};
            background-color: ${COLORS.white};
            &:hover {
              background-color: ${COLORS.sub3};
            }
            &:active {
              background-color: ${COLORS.gray200};
            }
          `
      : props.primary /* size 32아닐 때 */
      ? css`
          /* primary */
          color: ${COLORS.white};
          background-color: ${COLORS.brand1};
          &:hover {
            background-color: ${COLORS.sub2};
          }
          &:active {
            background-color: ${COLORS.sub1};
          }
        `
      : css`
          /* secondary */
          color: ${COLORS.gray900};
          background-color: ${COLORS.gray100};
          &:hover {
            background-color: ${COLORS.sub3};
          }
          &:active {
            background-color: ${COLORS.gray200};
          }
        `}

  /* disabled */
  ${(props) =>
    props.height !== 32 /* size 32아닐 때 */
      ? props.primary
        ? props.disable &&
          css`
            cursor: no-drop;
            background-color: ${COLORS.gray300};
            color: ${COLORS.gray500};
            &:hover {
              background-color: ${COLORS.gray300};
            }
          `
        : props.disable &&
          css`
            cursor: no-drop;
            background-color: ${COLORS.gray200};
            color: ${COLORS.gray400};
            &:hover {
              background-color: ${COLORS.gray200};
            }
          `
      : props.primary /* size 32일 때 */
      ? props.disable &&
        css`
          cursor: no-drop;
          background-color: ${COLORS.gray300};
          color: ${COLORS.gray500};
          &:hover {
            background-color: ${COLORS.gray300};
          }
        `
      : props.disable &&
        css`
          border: 1px solid ${COLORS.gray300};
          cursor: no-drop;
          background-color: ${COLORS.gray200};
          color: ${COLORS.gray400};
          &:hover {
            background-color: ${COLORS.gray200};
          }
        `}
`;
