import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import IssueListView from 'components/Task/IssueListView';
import { useAtom } from 'jotai';
import { covertedToProjectIdAtom, fetchInstancesFromProjectDetailViewAtom, projectsAtom } from 'atoms/projects';
import { createFeatureStorageV1FeatureStoragePost, deleteProjectV1ProjectProjectIdDelete } from 'queries';
import toast from 'react-hot-toast';
import { languageAtom } from 'atoms/language';
import { Popover } from '@mui/material';
import ProjectToInstanceGuide from 'components/Guide/ProjectToInstanceGuide';
import { featureStorageAtom } from 'atoms/featureStorage';
import { OutFeatureStorage } from 'queries/model';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
`;

export interface PlanProps {
  active?: boolean;
}

const Plan = ({ active }: PlanProps) => {
  const [language] = useAtom(languageAtom);
  const [projects, fetchProjects] = useAtom(projectsAtom);
  const [, setFetchInstancesFromProjectDetailViewAtom] = useAtom(fetchInstancesFromProjectDetailViewAtom);
  const [convertedToProjectId, setConvertedToProjectId] = useAtom(covertedToProjectIdAtom);
  const [featureStorage, setFeatureStorage] = useAtom(featureStorageAtom);
  const [guideAnchorEl, setGuideAnchorEl] = useState<HTMLElement | null>(null);
  const [spinBorderId, setSpinBorderId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (active) fetchProjects();
  }, [active]);

  useEffect(() => {
    if (!convertedToProjectId) return;

    const updateGuideAnchor = () => {
      const el = document.querySelector(`[data-sidepanel-project-id="${convertedToProjectId}"]`) as HTMLElement;
      setGuideAnchorEl(el);
      setSpinBorderId(convertedToProjectId);
    };

    const timeoutId = setTimeout(updateGuideAnchor, 1000);
    return () => clearTimeout(timeoutId);
  }, [projects, convertedToProjectId]);

  useEffect(() => {
    if (spinBorderId) {
      const timeoutId = setTimeout(() => {
        setSpinBorderId(undefined);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [spinBorderId]);

  const handleFetchProjects = () => {
    fetchProjects();
  };

  const handleDeleteProject = async (id: string) => {
    try {
      await deleteProjectV1ProjectProjectIdDelete(id);
      language === 'ko' ? toast.success('프로젝트를 삭제하였습니다.') : toast.success('Deleted project.');
      // toast.success('Deleted project.');
      fetchProjects();
      setFetchInstancesFromProjectDetailViewAtom(true);
    } catch (e) {
      language === 'ko' ? toast.error('프로젝트를 삭제할 수 없습니다.') : toast.error('Failed to delete project.');
      // toast.error('Failed to delete project.');
    }
  };

  const handleCloseGuide = async () => {
    setGuideAnchorEl(null);
    setConvertedToProjectId(null);

    const convertGuideStorage = featureStorage.find((feature) => feature.name === 'convert-project-instance-guide');
    if (!convertGuideStorage) {
      await createFeatureStorageV1FeatureStoragePost({ name: 'convert-project-instance-guide', data: { show: true } })
        .then(() => {
          setFeatureStorage((prev: OutFeatureStorage[]) =>
            prev.map((feature) => (feature.name === 'convert-project-instance-guide' ? { ...feature, data: { ...feature.data, show: true } } : feature)),
          );
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <Container>
      <ListWrapper>
        <IssueListView
          items={projects.filter((v) => v.place === 'DESK')}
          draggable={true}
          editable={true}
          dragView="side-project"
          multipleDrag={true}
          canAddItem={false}
          spinBorderId={spinBorderId}
          onFetchProjects={handleFetchProjects}
          onDeleteProject={handleDeleteProject}
        />
      </ListWrapper>
      {guideAnchorEl && (
        <Popover
          open={Boolean(guideAnchorEl)}
          anchorEl={guideAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            'marginTop': '-40px',
            'marginLeft': '44px',
            ' .MuiPaper-root': {
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <div style={{ display: 'flex' }}>
            <ProjectToInstanceGuide onClose={handleCloseGuide}></ProjectToInstanceGuide>
            <div style={{ width: '22px', height: '100%', backgroundColor: 'transparent' }} />
          </div>
        </Popover>
      )}
    </Container>
  );
};

export default Plan;
