import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Popover, TextField, PopoverProps, InputAdornment, IconButton, Button } from '@mui/material';
import { TextDelete } from 'components/Icons';
import toast from 'react-hot-toast';

const LinkInputPopoverWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export interface LinkInputPopoverProps extends PopoverProps {
  onApply?: (title?: string, url?: string) => void;
}

export const LinkInputPopover = ({ open, anchorEl, onApply, onClose, ...props }: LinkInputPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setTitle('');
    setUrl('');
    setIsPopoverOpen(open);
  }, [open]);

  const handleClose = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    setIsPopoverOpen(false);
    onClose && onClose(event, reason);
  };

  const handleClickApply = () => {
    if (!url) {
      toast.error('링크 주소를 입력해주세요');
      return;
    }
    setIsPopoverOpen(false);
    onApply && onApply(title, url);
  };

  return (
    <Popover open={isPopoverOpen} anchorEl={anchorEl} onClose={handleClose} {...props}>
      <LinkInputPopoverWrapper style={{ gap: 6 }}>
        <TextField
          fullWidth
          placeholder="링크 이름 입력"
          size="small"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleClickApply()}
          InputProps={{
            endAdornment: title ? (
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }} onClick={() => setTitle('')}>
                  <TextDelete />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
        <TextField
          fullWidth
          placeholder="링크 주소 입력"
          size="small"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleClickApply()}
          InputProps={{
            endAdornment: url ? (
              <InputAdornment position="end">
                <IconButton style={{ padding: 0 }} onClick={() => setUrl('')}>
                  <TextDelete />
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
        <Button disableElevation color="primary" variant="contained" onClick={handleClickApply}>
          첨부하기
        </Button>
      </LinkInputPopoverWrapper>
    </Popover>
  );
};

export default LinkInputPopover;
