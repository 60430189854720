import { extension, PlainExtension } from '@remirror/core';
import { MemoHandle } from './PlugIns';

export const DragDropExtension = extension({
  defaultOptions: [
    {
      dragHandleWidth: 24,
      dropIndicatorWidth: 3,
      dropIndicatorColor: '#BFE5F4',
      dropIndicatorClass: '',
      isDraggableBlock: () => false,
      allowColumnsInsideNode: () => false,
    },
  ],
})(
  class DragDropExtension extends PlainExtension {
    get name() {
      return 'dragdrop';
    }

    createExternalPlugins() {
      return [
        MemoHandle({
          dragHandleWidth: 18,
          isDraggableBlock: (node) => {
            return node.nodeName === 'P' || node.nodeName === 'BLOCKQUOTE' || node.nodeName === 'UL' || node.nodeName === 'LI';
          },
        }),
      ];
    }
  },
);
