import styled from '@emotion/styled';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CheckboxRound, Icons } from 'components';
import { InnerAlert } from 'components/InnerAlert';
import { AppUserModelOutUser, OutInvitation, UpdateInvitation } from 'queries/model';
import { updateInvitationV1NotesMeetingNoteIdInvitationPut } from 'queries';
import { v4 as uuidv4 } from 'uuid';
import { setCaretToEnd } from 'utils';
import { debounce } from 'lodash';
import { usePrevious } from '@react-hookz/web';
import { BlockAddArea, EditableTextField, EditableTextFieldWrapper, IconRadio, DragHandleIcon } from './styles';
import BlockAddComment from './components/BlockAddComment';
import MemberSearchPopover from './components/MemberSearchPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import CalendarPopover from 'components/CalendarPopover';
import DraggableAttachmentsSection from './components/DraggableAttachmentsSection';
import { COLORS } from 'styles/constants';
import dayjs from 'lib/dayjs';
import { Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const Container = styled.div`
  min-width: 630px;
  border-radius: 8px 8px;
  margin-bottom: 16px;
`;

const TitleWrapper = styled.div<{ isCollapsed: boolean }>`
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gray-100);
  border-radius: ${(props) => (props.isCollapsed ? '8px' : '8px 8px 0px 0px')};
  cursor: pointer;
`;

const MainForm = styled.form`
  padding: 24px;
  background: var(--white);

  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const BorderedEditableField = styled.div`
  background: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  width: 100%;
  cursor: text;
`;

const SubTitle = styled.h3`
  font-weight: bold;
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-md);
`;

const AssigneeAndDueDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalDivider = styled.span`
  display: inline-flex;
  height: 12px;
  width: 1px;
  margin: 2px 8px 0px 6px;
  background: ${COLORS.gray300};
`;

const DraggableAttachmentsSectionWrapper = styled.div`
  border-top: 1px solid ${COLORS.gray100};
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  border-top: 1px solid ${COLORS.gray100};
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
`;

interface EditableBlock {
  id: string;
  content?: string | undefined;
  assignee?: AppUserModelOutUser | undefined;
  dueDate?: string | undefined;
  section: string;
}

const SECTIONS = {
  AGENDA: 'AGENDA',
  DISCUSSION: 'DISCUSSION',
  PREREQUISITE: 'PREREQUISITE',
  ANTICIPATE: 'ANTICIPATE',
} as const;

type SectionType = typeof SECTIONS[keyof typeof SECTIONS];

const generateBlockId = () => uuidv4();

const categorizeBlock = (data: OutInvitation = {}) => [
  ...(data?.agendas || []).map((item) => ({ ...item, section: SECTIONS.AGENDA })),
  ...(data?.discussables || []).map((item) => ({ ...item, section: SECTIONS.DISCUSSION })),
  ...(data?.prerequisites || []).map((item) => ({ ...item, section: SECTIONS.PREREQUISITE })),
  ...(data?.anticipates || []).map((item) => ({ ...item, section: SECTIONS.ANTICIPATE })),
];

interface InvitationFormProps {
  meetingNoteId: string;
  invitationNote?: OutInvitation;
  collapsed?: boolean;
  onOk?: ({ slack, email }: { slack: boolean; email: boolean }) => void;
  onBlur?: () => void;
  onCollapse?: (collapsed: boolean) => void;
}

const InvitationForm = ({ meetingNoteId, invitationNote, collapsed = false, onOk, onBlur, onCollapse }: InvitationFormProps) => {
  const [blocks, setBlocks] = useState<EditableBlock[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const objective = useRef(invitationNote?.objective || '');
  const previousBlocks = usePrevious(blocks);
  const [selectedDueDateId, setSelectedDueDateId] = useState('');
  const [selectedAssigneeId, setSelectedAssigneeId] = useState('');
  const [checkChannelSlack, setCheckChannelSlack] = useState(true);
  const [checkChannelEmail, setCheckChannelEmail] = useState(true);
  const refInputObjective = useRef<HTMLDivElement>(null);
  const [hoverBlockId, setHoverBlockId] = useState('');

  const sections: Record<keyof Pick<OutInvitation, 'agendas' | 'discussables' | 'prerequisites' | 'anticipates'>, EditableBlock[]> = useMemo(
    () => ({
      agendas: blocks.filter(({ section }) => section === SECTIONS.AGENDA),
      discussables: blocks.filter(({ section }) => section === SECTIONS.DISCUSSION),
      prerequisites: blocks.filter(({ section }) => section === SECTIONS.PREREQUISITE),
      anticipates: blocks.filter(({ section }) => section === SECTIONS.ANTICIPATE),
    }),
    [blocks],
  );

  useEffect(() => {
    initInvitationForm();
  }, [meetingNoteId, invitationNote]);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (!invitationNote) return;
    if (!previousBlocks) return;
    if (JSON.stringify(previousBlocks) === JSON.stringify(blocks)) return;

    saveInvitationNote(meetingNoteId, createPayload(blocks));
  }, [previousBlocks, blocks]);

  const initInvitationForm = () => {
    objective.current = invitationNote?.objective || '';
    setBlocks(categorizeBlock(invitationNote) as EditableBlock[]);
  };

  const saveInvitationNote = useRef(
    debounce((id, payload: UpdateInvitation) => {
      updateInvitationV1NotesMeetingNoteIdInvitationPut(id, payload);
    }, 800),
  ).current;

  const createPayload = (blocks: EditableBlock[]): UpdateInvitation => ({
    objective: objective.current,
    agendas: blocks.filter(({ section }) => section === SECTIONS.AGENDA).map((item) => ({ id: item.id, content: item.content })),
    discussables: blocks.filter(({ section }) => section === SECTIONS.DISCUSSION).map((item) => ({ id: item.id, content: item.content })),
    prerequisites: blocks
      .filter(({ section }) => section === SECTIONS.PREREQUISITE)
      .map((item) => ({
        id: item.id,
        content: item.content,
        ...(item.assignee?.id ? { assigneeId: item.assignee?.id } : { assigneeId: undefined }),
        ...(item.dueDate ? { dueDate: item.dueDate } : {}),
      })),
    anticipates: blocks.filter(({ section }) => section === SECTIONS.ANTICIPATE).map((item) => ({ id: item.id, content: item.content })),
  });

  const createBlock = (newBlock: EditableBlock) => {
    setBlocks([...blocks, newBlock]);
  };

  const insertBlock = (index: number, newBlock: EditableBlock) => {
    const newBlocks = blocks.slice(0);
    newBlocks.splice(index, 0, newBlock);
    setBlocks(newBlocks);
  };

  const deleteBlock = (blockId: string) => {
    setBlocks(blocks.filter(({ id }) => id !== blockId));
  };

  const updateBlock = (id: string, content: string | undefined, data?: any) => {
    const blockIndex = blocks.map(({ id }) => id).indexOf(id);
    if (blockIndex === -1) return;
    blocks[blockIndex] = { ...blocks[blockIndex], content: content === undefined ? blocks[blockIndex].content : content, ...data };
    setBlocks(blocks);
  };

  const setFocusElement = (blockId: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-field-id="${blockId}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickCollapse = () => {
    if (onCollapse) {
      onCollapse(!isCollapsed);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  const handleClickAdd = (section: SectionType) => {
    const block = { id: generateBlockId(), content: '', section: section };
    createBlock(block);
    setFocusElement(block.id);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    blockId: string,
    index: number,
    section: SectionType,
    filteredBlocks: EditableBlock[] = [],
    data?: object,
  ) => {
    const text = e.currentTarget.textContent || '';
    if (e.key === 'Backspace') {
      if (text.length !== 0) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const findIndex = index === 0 ? index + 1 : index - 1;
      const block = filteredBlocks[findIndex];
      if (block) setFocusElement(block.id);
      deleteBlock(blockId);
      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const block = { id: generateBlockId(), content: '', section: section, ...data };
      const realIndex = blocks.map(({ id }) => id).indexOf(blockId);
      e.currentTarget.blur();
      insertBlock(realIndex + 1, block);
      setFocusElement(block.id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      const blockIndex = index >= filteredBlocks.length - 1 ? filteredBlocks.length - 1 : index + 1;
      setFocusElement(filteredBlocks[blockIndex].id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      const blockIndex = index <= 0 ? 0 : index - 1;
      setFocusElement(filteredBlocks[blockIndex].id);
      e.preventDefault();
      return;
    }
  };

  const handleChangeBlock = (e: React.FormEvent<HTMLDivElement>, id: string) => {
    // 텍스트 입력시에는 커서가 갱신되지 않도록 update 하고 저장 요청 실행
    updateBlock(id, `${e.currentTarget.textContent || ''}`);
    saveInvitationNote(meetingNoteId, createPayload(blocks));
  };

  const handleChangeObjective = (e: React.FormEvent<HTMLDivElement>) => {
    const content = e.currentTarget.textContent || '';
    objective.current = content;
    saveInvitationNote(meetingNoteId, createPayload(blocks));
  };

  const handleKeydownAddButton = (e: React.KeyboardEvent<HTMLDivElement>, section: SectionType) => {
    if (e.key !== 'Enter') return;
    if (e.nativeEvent.isComposing) return;
    if (e.repeat) {
      e.preventDefault();
      return;
    }
    // if (blocks.filter((item) => item.section === section).length) return;
    const block = { id: generateBlockId(), content: '', section: section };
    createBlock(block);
    setFocusElement(block.id);
    e.preventDefault();
  };

  const handleClickAssigneeItem = (member: AppUserModelOutUser | undefined) => {
    const blockId = selectedAssigneeId;
    setSelectedAssigneeId('');

    if (blockId) {
      setBlocks(blocks.map((item) => (item.id === blockId ? { ...item, assignee: member } : item)));
    }
  };

  const handleClickCalendarItem = (value: Date | null) => {
    const blockId = selectedDueDateId;
    setSelectedDueDateId('');

    const dueDate = value ? dayjs(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null;
    if (blockId) {
      setBlocks(blocks.map((item) => (item.id === blockId ? ({ ...item, dueDate } as EditableBlock) : item)));
    }
  };

  // FocusTrap 사용시 외부에서 Modal을 호출하면 무한 호출되는 문제가 발생해서 마지막 버튼 값으로 탭 포커싱이 넘어가지 않도록 처리 함
  const handleMainFormKeydown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const activeElement = document.activeElement;
    if (e.currentTarget.lastChild?.contains(activeElement)) {
      if (activeElement?.tagName === 'BUTTON' && activeElement?.textContent === '초대하기') {
        refInputObjective.current?.focus();
      }
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const sectionBlocks = blocks.filter((item) => item.section === result.source.droppableId);
    const sourceBlock = blocks.find((item) => item.id === result.draggableId);
    const targetBlock = sectionBlocks[result.destination.index];
    const targetBlockIndex = blocks.findIndex((item) => item.id === targetBlock.id);

    if (targetBlockIndex !== -1) {
      const newBlocks = blocks.filter((item) => item.id !== result.draggableId);
      newBlocks.splice(targetBlockIndex, 0, sourceBlock!);
      setBlocks([...newBlocks]);
    }
  };

  return (
    <Container>
      <TitleWrapper onClick={handleClickCollapse} isCollapsed={isCollapsed}>
        <div style={{ display: 'flex', userSelect: 'none' }}>
          <Icons.Invi />
          <span className="heading-3 ml-4">작성 중인 인비테이션</span>
        </div>
        {isCollapsed ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
      </TitleWrapper>
      {!isCollapsed && (
        <MainForm onBlur={onBlur} style={{ borderRadius: '0 0 8px 8px' }} onKeyDown={handleMainFormKeydown}>
          <div>
            <SubTitle className="pb-2">회의 목표</SubTitle>
            <BorderedEditableField>
              <EditableTextField
                ref={refInputObjective}
                style={{ padding: '14px 16px', lineHeight: '18px' }}
                contentEditable={true}
                suppressContentEditableWarning={true}
                placeholder={'이번 회의의 목표를 여기에 적어주세요. 명확한 회의 목표로 명확한 회의 결과를 얻어보세요.'}
                onInput={handleChangeObjective}
                dangerouslySetInnerHTML={{ __html: objective.current }}
              />
            </BorderedEditableField>
          </div>
          <div>
            <SubTitle className="pb-2">아젠다</SubTitle>
            {sections.agendas.length === 0 && (
              <BlockAddArea tabIndex={0} onKeyDown={(e) => handleKeydownAddButton(e, SECTIONS.AGENDA)} onClick={() => handleClickAdd(SECTIONS.AGENDA)}>
                <BlockAddComment content={'세부 주제를 여기에 적어주세요. 어떤 순서가 효율적인지 고민하여 정리해보세요.'} />
              </BlockAddArea>
            )}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={SECTIONS.AGENDA}>
                {(provided) => (
                  <div className={SECTIONS.AGENDA} {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.agendas.map(({ id, content }, idx) => (
                      <Draggable draggableId={id} index={idx} key={id}>
                        {(provided) => (
                          <EditableTextFieldWrapper
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                            onMouseEnter={() => setHoverBlockId(id)}
                            onMouseLeave={() => setHoverBlockId('')}
                          >
                            <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                            <span style={{ fontSize: 10, marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()}>
                              &#8211;
                            </span>
                            <EditableTextField
                              data-field-id={`${id}`}
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => handleKeyDown(e, id, idx, SECTIONS.AGENDA, sections.agendas)}
                              onInput={(e) => handleChangeBlock(e, id)}
                              onMouseEnter={(e) => e.stopPropagation()}
                              dangerouslySetInnerHTML={{ __html: content! }}
                            />
                          </EditableTextFieldWrapper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div>
            <SubTitle className="pb-2">논의사항</SubTitle>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={SECTIONS.DISCUSSION}>
                {(provided) => (
                  <div className={SECTIONS.DISCUSSION} {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.discussables.map(({ id, content }, idx) => (
                      <Draggable draggableId={id} index={idx} key={id}>
                        {(provided) => (
                          <EditableTextFieldWrapper
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                            onMouseEnter={() => setHoverBlockId(id)}
                            onMouseLeave={() => setHoverBlockId('')}
                          >
                            <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                            <IconRadio style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()} />
                            <EditableTextField
                              data-field-id={`${id}`}
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => handleKeyDown(e, id, idx, SECTIONS.DISCUSSION, sections.discussables)}
                              onInput={(e) => handleChangeBlock(e, id)}
                              onMouseEnter={(e) => e.stopPropagation()}
                              dangerouslySetInnerHTML={{ __html: content! }}
                            />
                          </EditableTextFieldWrapper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <BlockAddArea tabIndex={0} onKeyDown={(e) => handleKeydownAddButton(e, SECTIONS.DISCUSSION)} onClick={() => handleClickAdd(SECTIONS.DISCUSSION)}>
              <BlockAddComment
                icon={<Icons.Add />}
                content={sections.discussables.length === 0 ? '참가자들의 의견이 필요한 사항은 무엇인가요' : '새로운 논의사항 추가하기'}
              />
            </BlockAddArea>
          </div>
          <InnerAlert>
            <div className="flex gap-2">
              <img src={require('assets/images/smile.png')} alt="smile" width={17} height={16} style={{ width: 17, height: 16 }} />
              <blockquote className="blockquote">회의 전/후로 필요한 업무를 미리 정리하면 회의 시간을 보다 알차게 쓸 수 있습니다.</blockquote>
            </div>
          </InnerAlert>
          <div>
            <SubTitle className="pb-2">사전 준비사항</SubTitle>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={SECTIONS.PREREQUISITE}>
                {(provided) => (
                  <div className={SECTIONS.PREREQUISITE} {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.prerequisites.map(({ id, content, assignee, dueDate }, idx) => (
                      <Draggable draggableId={id} index={idx} key={id}>
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                            onMouseEnter={() => setHoverBlockId(id)}
                            onMouseLeave={() => setHoverBlockId('')}
                          >
                            <EditableTextFieldWrapper>
                              <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                              <IconRadio style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()} />
                              <EditableTextField
                                data-field-id={`${id}`}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onKeyDown={(e) => handleKeyDown(e, id, idx, SECTIONS.PREREQUISITE, sections.prerequisites)}
                                onInput={(e) => handleChangeBlock(e, id)}
                                onMouseEnter={(e) => e.stopPropagation()}
                                dangerouslySetInnerHTML={{ __html: content! }}
                              />
                            </EditableTextFieldWrapper>
                            <AssigneeAndDueDateWrapper style={{ marginLeft: 24 }} onMouseEnter={(e) => e.stopPropagation()}>
                              <MemberSearchPopover
                                isOpen={selectedAssigneeId === id}
                                onClickOutside={() => setSelectedAssigneeId('')}
                                onClickItem={handleClickAssigneeItem}
                              >
                                <span onClick={() => setSelectedAssigneeId(selectedAssigneeId ? '' : id)}>
                                  <ArrowToggleButton toggle={false} active={selectedAssigneeId === id}>
                                    {assignee?.name || '담당자'}
                                  </ArrowToggleButton>
                                </span>
                              </MemberSearchPopover>
                              <VerticalDivider />
                              <CalendarPopover
                                isOpen={selectedDueDateId === id}
                                defaultValue={dueDate ? new Date(dueDate) : undefined}
                                onClickOutside={() => setSelectedDueDateId('')}
                                onClickItem={handleClickCalendarItem}
                                nullable
                              >
                                <span onClick={() => setSelectedDueDateId(selectedDueDateId ? '' : id)}>
                                  <ArrowToggleButton toggle={false} active={selectedDueDateId === id}>
                                    {dueDate?.split('T')[0] || '날짜 선택'}
                                  </ArrowToggleButton>
                                </span>
                              </CalendarPopover>
                            </AssigneeAndDueDateWrapper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <BlockAddArea
              tabIndex={0}
              onKeyDown={(e) => handleKeydownAddButton(e, SECTIONS.PREREQUISITE)}
              onClick={() => handleClickAdd(SECTIONS.PREREQUISITE)}
            >
              <BlockAddComment
                icon={<Icons.Add />}
                content={
                  sections.prerequisites.length === 0
                    ? '회의 전에 완료되어야 하는 업무가 있나요? 담당자가 미리 알 수 있게 태그해주세요.'
                    : '사전 준비사항 추가하기'
                }
              />
            </BlockAddArea>
          </div>
          <div>
            <SubTitle className="pb-2">예상 업무</SubTitle>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={SECTIONS.ANTICIPATE}>
                {(provided) => (
                  <div className={SECTIONS.ANTICIPATE} {...provided.droppableProps} ref={provided.innerRef}>
                    {sections.anticipates.map(({ id, content, assignee, dueDate }, idx) => (
                      <Draggable draggableId={id} index={idx} key={id}>
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                            onMouseEnter={() => setHoverBlockId(id)}
                            onMouseLeave={() => setHoverBlockId('')}
                          >
                            <EditableTextFieldWrapper>
                              <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                              <IconRadio style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()} />
                              <EditableTextField
                                data-field-id={`${id}`}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onKeyDown={(e) => handleKeyDown(e, id, idx, SECTIONS.ANTICIPATE, sections.anticipates)}
                                onInput={(e) => handleChangeBlock(e, id)}
                                onMouseEnter={(e) => e.stopPropagation()}
                                dangerouslySetInnerHTML={{ __html: content! }}
                              />
                            </EditableTextFieldWrapper>
                            <AssigneeAndDueDateWrapper style={{ marginLeft: 24 }} onMouseEnter={(e) => e.stopPropagation()}>
                              <MemberSearchPopover
                                isOpen={selectedAssigneeId === id}
                                onClickOutside={() => setSelectedAssigneeId('')}
                                onClickItem={handleClickAssigneeItem}
                              >
                                <span onClick={() => setSelectedAssigneeId(selectedAssigneeId ? '' : id)}>
                                  <ArrowToggleButton toggle={false} active={selectedAssigneeId === id}>
                                    {assignee?.name || '담당자'}
                                  </ArrowToggleButton>
                                </span>
                              </MemberSearchPopover>
                              <VerticalDivider />
                              <CalendarPopover
                                isOpen={selectedDueDateId === id}
                                onClickOutside={() => setSelectedDueDateId('')}
                                onClickItem={handleClickCalendarItem}
                                nullable
                              >
                                <span onClick={() => setSelectedDueDateId(selectedDueDateId ? '' : id)}>
                                  <ArrowToggleButton toggle={false} active={selectedDueDateId === id}>
                                    {dueDate?.split('T')[0] || '날짜 선택'}
                                  </ArrowToggleButton>
                                </span>
                              </CalendarPopover>
                            </AssigneeAndDueDateWrapper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <BlockAddArea tabIndex={0} onKeyDown={(e) => handleKeydownAddButton(e, SECTIONS.ANTICIPATE)} onClick={() => handleClickAdd(SECTIONS.ANTICIPATE)}>
              <BlockAddComment
                icon={<Icons.Add />}
                content={
                  sections.anticipates.length === 0
                    ? '회의를 결과로 어떤 업무가 만들어지나요? 미리 업무를 예상해보면 구체적인 회의를 준비할 수 있습니다.'
                    : '예상업무 추가하기'
                }
              />
            </BlockAddArea>
          </div>
          <DraggableAttachmentsSectionWrapper>
            <DraggableAttachmentsSection meetingNoteId={meetingNoteId} attachedFiles={invitationNote?.attachments} />
          </DraggableAttachmentsSectionWrapper>
          <FooterWrapper>
            <div className="flex items-center pt-1">
              <b>어디로 초대를 보낼까요?</b>
              <span className="flex items-center ml-4 gap-1">
                <CheckboxRound checked={checkChannelSlack} onChange={() => checkChannelEmail && setCheckChannelSlack(!checkChannelSlack)} />
                <Icons.Slack width={16} height={16} />
                <b style={{ fontSize: 13 }}>슬랙</b>
              </span>
              <span className="flex items-center ml-4 gap-1">
                <CheckboxRound checked={checkChannelEmail} onChange={() => checkChannelSlack && setCheckChannelEmail(!checkChannelEmail)} />
                <Icons.Mail width={16} height={16} />
                <b style={{ fontSize: 13 }}>이메일</b>
              </span>
            </div>
            <div className="flex gap-2">
              <Button variant="outlined" color="inherit" style={{ background: COLORS.gray200 }} disableElevation onClick={handleClickCollapse}>
                나중에 보내기
              </Button>
              <Button variant="contained" disableElevation onClick={() => onOk && onOk({ slack: checkChannelSlack, email: checkChannelEmail })}>
                초대하기
              </Button>
            </div>
          </FooterWrapper>
        </MainForm>
      )}
    </Container>
  );
};

export default InvitationForm;
