import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAllFeatureStorageV1FeatureStorageGet } from 'queries';
import { OutFeatureStorage } from 'queries/model';

const request = async () => await getAllFeatureStorageV1FeatureStorageGet();
export const featureStorage = atomWithDefault<OutFeatureStorage[]>(request);
export const featureStorageAtom = atom(
  (get) => get(featureStorage),
  async (_, set) => {
    const res = await getAllFeatureStorageV1FeatureStorageGet();
    set(featureStorage, res || null);
  },
);
