import { PropaneSharp } from '@mui/icons-material';
import { SVGProps } from 'react';

export const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1891_765)">
        <path d="M5 5L27 27" stroke={props.stroke || '#1F2023'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 5L5 27" stroke={props.stroke || '#1F2023'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
