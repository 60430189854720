import { useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { IconButton } from '@mui/material';
import dayjs from 'lib/dayjs';
import TodayCalendarEvent from './TodayCalendarEvent';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';

import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import { useMountEffect, useRafCallback } from '@react-hookz/web';
import { OutTaskboxDetailResponse } from 'queries/model';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';

const CalendarViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #f2f5fc;
  position: relative;
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 112px);
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};

  .rbc-time-header {
    border-bottom: 2px solid ${COLORS.gray300};
    margin-right: 0px !important;
  }

  .rbc-time-header > .rbc-time-header-gutter {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .rbc-time-header-cell {
    /* margin-bottom: 8px; */
    display: none;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-header {
    color: ${COLORS.gray900};
    font-size: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rbc-today {
      background-color: transparent;
      color: ${COLORS.brand1};
    }

    & > button {
      cursor: default;
    }

    /* :nth-of-type(1),
    :nth-last-of-type(1) {
      color: ${COLORS.gray500};
    } */
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-allday-cell {
    height: 100px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    border-top: 1px solid ${COLORS.gray300};
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
    &:nth-of-type(3n) {
      border-top: 1px dashed ${COLORS.gray200};
    }
  }

  .rbc-day-bg.rbc-selected-cell {
    /* background-color: #f2f5fc !important; */
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    & > * + * > * {
      border: none;
    }

    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-event {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-row-content {
    z-index: 0;
  }
`;

const AllDayEventContainer = styled.div<{ height?: number }>`
  width: 100%;
  display: flex;
  position: relative;
`;

const ViewMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 24px;
  cursor: pointer;
`;

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
  isRecurrence?: boolean;
} & Event;

export interface CalendarViewProps {
  newEventId?: string;
  events?: CustomEvent[];
  weekTasks?: { date: Date; tasks: OutTaskboxDetailResponse[] }[];
  currentDate: Date;
  onClickRefresh?: () => void;
  onClickToggleView?: () => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onUpdateEvent?: ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDeleteEvent?: (eventId: string) => void;
  onClickTimeSlot?: ({
    action,
    bounds,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: 'select' | 'click' | 'doubleClick';
    bounds:
      | {
          x: number;
          y: number;
          top: number;
          bottom: number;
          left: number;
          right: number;
        }
      | undefined;
    box:
      | {
          x: number;
          y: number;
          clientX: number;
          clientY: number;
        }
      | undefined;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
}

const CalendarView = ({
  newEventId,
  events = [],
  currentDate,
  onSelectEvent,
  onUpdateEvent,
  onClickTimeSlot,
  onDropFromOutside,
  onUpdateEventTitle,
}: CalendarViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisibleViewMore, setIsVisibleViewMore] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [lang] = useAtom(languageAtom);

  const formats = useMemo(
    () => ({
      dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => `${localizer?.format(date, 'DD (ddd)', culture)}`,
      timeGutterFormat: lang === 'ko' ? 'a hh시' : 'a hh',
      eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
        `${localizer?.format(range.start, 'a h:mm', culture)}~${localizer?.format(range.end, 'a h:mm', culture)}`,
    }),
    [],
  );
  const draggableAccessor = useCallback((event: CustomEvent) => event.type === 'task', [events]);
  const resizableAccessor = useCallback((event: CustomEvent) => event.type === 'task' && !event.allDay, [events]);
  const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
  const eventPropGetter = useCallback(
    (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
      if (event.type === 'meeting') return {};

      if (event.type === 'task' && event.id === newEventId) {
        return {
          style: {
            background: `rgba(0, 57, 167, 0.3)`,
          },
        };
      }

      // 종일 taskbox를 특정 시간대로 이동시 스타일
      if (event.type === 'task' && event.allDay && isSelected === undefined) {
        return {
          style: { maxHeight: '28px' },
        };
      }

      return {
        style: { border: `1px solid ${isSelected ? (event.isRecurrence ? COLORS.sub4 : COLORS.brand1) : COLORS.gray200}` },
      };
    },
    [events],
  );

  useMountEffect(() => {
    scrollToCurrentIndicator();
  });

  const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
    onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
  });

  const scrollToCurrentIndicator = () => {
    setTimeout(() => {
      if (!ref || !ref.current) return;
      const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
      if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
    }, 30);
  };

  const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    // if (event.allDay && isAllDay) return; // 종일 > 종일로 이동 불가
    let endTime = event.allDay && !isAllDay ? dayjs(start).add(30, 'minute') : dayjs(end); // 종일 이벤트를 특정시간으로 이동시 30분으로 조정

    // endTime이 15분 단위가 아닌 경우 15분으로 올림
    if (dayjs(endTime).get('minute') % 15 !== 0) {
      endTime = dayjs(endTime).set('minutes', Math.round(dayjs(endTime).get('minute') / 15) * 15);
    }

    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(endTime).format(DATE_FORMAT_1),
        isAllDay: Boolean(isAllDay),
      });
  };

  const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(end).format(DATE_FORMAT_1),
        isAllDay: Boolean(isAllDay),
      });
  };

  const handleSelectEvent = (event: CustomEvent) => {
    if (event.type === 'meeting') return;

    setSelectedEvent(event);
    onSelectEvent && onSelectEvent(event.id!);
  };

  const handleSelectSlot = (slot: SlotInfo) => {
    const days = dayjs(slot.end).startOf('day').diff(dayjs(slot.start).startOf('day'), 'day');
    if (slot.action === 'select' && days > 1) return;

    const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
    const endTime =
      slot.slots.length === 2
        ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
        : dayjs(slot.end).format(DATE_FORMAT_1);

    onClickTimeSlot &&
      onClickTimeSlot({
        action: slot.action,
        bounds: slot.bounds,
        box: slot.box,
        startTime,
        endTime,
        isAllDay: slot?.slots.length === 1 || days > 1 ? true : false,
      });
  };

  const handleDropFromOutside = (args: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
    onDropFromOutside &&
      onDropFromOutside({
        startTime: dayjs(args.start).format(DATE_FORMAT_1),
        endTime: dayjs(args.end).format(DATE_FORMAT_1),
        isAllDay: args.allDay,
      });
  };

  const handleClickViewMore = () => {
    const timeHeader = ref.current?.querySelector('.rbc-allday-cell') as HTMLDivElement;
    timeHeader.style.height = `${isVisibleViewMore ? 100 : 168}px`;
    setIsVisibleViewMore(!isVisibleViewMore);
  };

  const handleEventInput = (event: CustomEvent, title: string) => {
    updateEventTitle(event, title);
  };

  const EventComponentWrapper = useCallback((props: EventProps<CustomEvent>) => {
    return <TodayCalendarEvent {...props} onInput={(event, value) => handleEventInput(event, value)} />;
  }, []);

  return (
    <CalendarViewWrapper>
      <CalendarViewDaySchedulerWrapper>
        <AllDayEventContainer>
          <ViewMoreButtonWrapper style={{ top: isVisibleViewMore ? 146 : 80 }} onClick={handleClickViewMore}>
            <IconButton size="small" style={{ padding: 2, border: `1px solid ${COLORS.gray200}`, background: COLORS.gray200 }}>
              {isVisibleViewMore ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
            </IconButton>
          </ViewMoreButtonWrapper>
        </AllDayEventContainer>
        <CalendarContainer ref={ref}>
          <DnDCalendar
            style={{ height: '100%' }}
            date={currentDate}
            selected={selectedEvent}
            formats={formats}
            defaultView="day"
            toolbar={false}
            timeslots={4}
            step={15}
            max={dayjs(currentDate).endOf('day').toDate()}
            events={events.filter((v) => v.type === 'task' || v.allDay)}
            backgroundEvents={events.filter((v) => v.type === 'meeting' && !v.allDay)}
            dayLayoutAlgorithm={'overlap'}
            selectable={true}
            draggableAccessor={draggableAccessor}
            resizableAccessor={resizableAccessor}
            allDayAccessor={allDayAccessor}
            components={{ event: EventComponentWrapper }}
            eventPropGetter={eventPropGetter}
            onDropFromOutside={handleDropFromOutside}
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onNavigate={() => ({})}
            showAllEvents={true}
            showMultiDayTimes={true}
            culture={lang}
          />
        </CalendarContainer>
      </CalendarViewDaySchedulerWrapper>
    </CalendarViewWrapper>
  );
};

export default CalendarView;
