import dayjs from 'lib/dayjs';
import { type ManipulateType } from 'dayjs';

import { PluginFunc } from 'dayjs';
declare module 'dayjs' {
  interface Dayjs {
    ceil(unit: UnitType, amount: number): Dayjs;
    isToday(): boolean;
    isYesterday(): boolean;
    isTomorrow(): boolean;
  }
}

const plugin: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.ceil = function (unit, amount) {
    return this.add(amount - (this.get(unit) % amount), unit as ManipulateType).startOf(unit);
  };
  dayjsClass.prototype.isToday = function () {
    const today = dayjs(); 
    return this.isSame(today, 'day'); 
  };
  dayjsClass.prototype.isYesterday = function () {
    const yesterday = dayjs().subtract(1, 'day');
    return this.isSame(yesterday, 'day');
  }
  dayjsClass.prototype.isTomorrow = function () {
    const tomorrow = dayjs().add(1, 'day');
    return this.isSame(tomorrow, 'day');
  }
};

export default plugin;
