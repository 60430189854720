import { SVGProps } from 'react';

export const EmptyTask = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M13.3359 26.6693V56.0026C13.3359 59.7363 13.3359 61.6018 14.0626 63.0279C14.7017 64.2823 15.7208 65.3042 16.9753 65.9434C18.3999 66.6693 20.2659 66.6693 23.9923 66.6693H56.0129C59.7393 66.6693 61.6026 66.6693 63.0273 65.9434C64.2817 65.3042 65.3042 64.2823 65.9434 63.0279C66.6693 61.6032 66.6693 59.7393 66.6693 56.0129V26.6693M13.3359 26.6693H66.6693M13.3359 26.6693L17.8865 18.7056C19.0033 16.7513 19.5615 15.7743 20.3468 15.0628C21.0421 14.4328 21.8646 13.9561 22.7565 13.665C23.7646 13.3359 24.89 13.3359 27.1445 13.3359H52.8596C55.1141 13.3359 56.2436 13.3359 57.2517 13.665C58.1436 13.9561 58.9622 14.4328 59.6576 15.0628C60.4434 15.7749 61.0032 16.7528 62.1217 18.7103L66.6693 26.6693"
        stroke="#C8CDDB"
        strokeWidth="4.27"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3359 53.3332L40.0025 46.6666M40.0025 46.6666L46.6692 40M40.0025 46.6666L33.3359 40M40.0025 46.6666L46.6692 53.3332"
        stroke="#C8CDDB"
        strokeWidth="4.27"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
