export const DATE_FORMAT = {
  TZ: 'YYYY-MM-DDTHH:mm:ssZ',
  YYYY_MM_DD_HH_MM_SSZ: 'YYYY-MM-DDTHH:mm:ssZ',
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_T_HH_MM: 'YYYY-MM-DDTHH:mm',
  YYYY_MM_DD_T_HH_MM_SS: 'YYYY-MM-DDTHH:mm:ss',
  HH_MM: 'HH:mm',
  HH_MM_SS: 'HH:mm:ss',
  HH_MM_SSZ: 'HH:mm:ssZ',
  A_HH_MM: 'a HH:mm',
} as const;

export const DF = DATE_FORMAT;
