import { SVGProps } from 'react';

export const RitualUndoneEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M9.66732 18.8359L3.33398 36.6693L21.1673 30.3526M6.66732 5.0026H6.68398M36.6673 13.3359H36.684M25.0007 3.33594H25.0173M36.6673 33.3359H36.684M36.6673 3.33594L32.934 4.58594C31.8713 4.93992 30.9645 5.652 30.3687 6.60048C29.7729 7.54895 29.5249 8.67492 29.6673 9.78594C29.834 11.2193 28.7173 12.5026 27.2507 12.5026H26.6173C25.184 12.5026 23.9507 13.5026 23.684 14.9026L23.334 16.6693M36.6673 21.6693L35.3007 21.1193C33.8673 20.5526 32.2673 21.4526 32.0007 22.9693C31.8173 24.1359 30.8007 25.0026 29.6173 25.0026H28.334M18.334 3.33594L18.884 4.7026C19.4507 6.13594 18.5507 7.73594 17.034 8.0026C15.8673 8.16927 15.0007 9.2026 15.0007 10.3859V11.6693"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3348 21.6688C21.5515 24.8855 23.0515 28.6188 21.6682 30.0021C20.2848 31.3855 16.5515 29.8855 13.3348 26.6688C10.1182 23.4521 8.61816 19.7188 10.0015 18.3355C11.3848 16.9521 15.1182 18.4521 18.3348 21.6688Z"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
