import { SVGProps } from 'react';

export const Sun = ({ width = 16, height = 16, stroke = '#0039A7', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.5 4.5V2.25M7.19675 6.69675L5.606 5.106M5 12H2.75M12.5 21.75V19.5M19.394 18.894L17.8032 17.304M22.25 12H20M7.19675 17.3032L5.606 18.894M19.394 5.106L17.804 6.69675M17 12C17 13.1935 16.5259 14.3381 15.682 15.182C14.8381 16.0259 13.6935 16.5 12.5 16.5C11.3065 16.5 10.1619 16.0259 9.31802 15.182C8.47411 14.3381 8 13.1935 8 12C8 10.8065 8.47411 9.66193 9.31802 8.81802C10.1619 7.97411 11.3065 7.5 12.5 7.5C13.6935 7.5 14.8381 7.97411 15.682 8.81802C16.5259 9.66193 17 10.8065 17 12Z"
        stroke="#656871"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
