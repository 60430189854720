import { useEffect, useRef, useState } from 'react';
import {
  MenuItem,
  Select,
  Button,
  Dialog,
  Divider,
  TextField,
  InputAdornment,
  Popover,
  IconButton,
  Tooltip,
  Autocomplete,
  Box,
  PopperProps,
  Popper,
} from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, TIME_FORMAT_2, DATE_FORMAT_7, DATE_FORMAT_4, DATE_FORMAT_2 } from 'utils/datetimeFormat';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import {
  WeekDay,
  InCreateTaskBoxInRecurrence,
  InRecurrence,
  InCreateTaskBoxInRecurrenceTarget,
  OutTaskboxDetailResponse,
  UpdateTaskBoxRecurrence,
  InUpdateRoutineRuleOptionalTime,
} from 'queries/model';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import { RecurrenceCustomDialog } from 'components/Recurrence/RecurrenceCustomDialog';
import { Icons } from 'components';
import { css } from '@emotion/react';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { DateType } from 'pages/Task/components/TimelineTaskbox';
import DateRangeCalendar from './DateRangeCalendar';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';

interface Props {
  event?: OutTaskboxDetailResponse;
  suppressMoveDate?: boolean;
  onClose?: () => void;
  onChangeTime?: (
    startTime: string,
    endTime: string,
    timeDiff: number,
    eventId?: string,
    isAllDay?: boolean,
    taskboxRecurrence?: InUpdateRoutineRuleOptionalTime,
  ) => void;
  onChangeDate?: (date: Date | null, isAllDay?: boolean, dateType?: DateType) => void;
  onUnlinkRoutine?: (eventId: string) => void;
}

const TimeDateSelectionModal = ({
  event,
  suppressMoveDate = false,
  onClose = () => {},
  onChangeTime = () => {},
  onChangeDate = () => {},
  onUnlinkRoutine = () => {},
}: Props) => {
  const [language] = useAtom(languageAtom);
  const [startTime, setStartTime] = useState<string | undefined>(event?.start?.datetime ? event?.start?.datetime : undefined);
  const [endTime, setEndTime] = useState<string | undefined>(event?.end?.datetime ? event?.end?.datetime : undefined);
  const [date] = useState(event?.start?.date ? dayjs(event?.start?.date, { utc: true }).toDate() : dayjs(event?.start?.datetime, { utc: true }).toDate());
  const [isAllDay, setIsAllDay] = useState<boolean | undefined>(event?.allDay);
  const [isOpenRecurrenceDialog, setIsOpenRecurrenceDialog] = useState<boolean>(false);
  const [taskboxRecurrence, setTaskboxRecurrence] = useState<InRecurrence | undefined>(undefined);
  const [customRecurrence, setCustomRecurrence] = useState<InRecurrence | undefined>();
  const [recurrenceUnit, setRecurrenceUnit] = useState<string>(language === 'ko' ? '반복 안 함' : 'Does not repeat');
  // const [recurrenceUnit, setRecurrenceUnit] = useState<string>('Does not repeat');
  const [isUpdateRecurrence, setIsUpdateRecurrence] = useState(false);
  const [timeDiff, setTimeDiff] = useState(
    event?.durationMin ? event.durationMin : event?.allDay ? undefined : dayjs(event?.end?.datetime).diff(dayjs(event?.start?.datetime), 'minute'),
  );
  const [inputTimeSelectValue, setInputTimeSelectValue] = useState(dayjs(startTime, { utc: true }).format(TIME_FORMAT_2));
  const [timeError, setTimeError] = useState(false);
  const [openStartTime, setOpenStartTime] = useState(false);
  const [openTimeSelection, setOpenTimeSelection] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [durationLimitError, setDurationLimitError] = useState(false);
  const [openDurationTime, setOpenDurationTime] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [dueDate, setDueDate] = useState(event?.type === 'TERM_TASK' ? true : false);
  const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLDivElement | null>(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLDivElement | null>(null);
  const calendarRef = useRef(null);
  const timeSelectRef = useRef(null);
  const refTimeSelection = useRef<HTMLDivElement>(null);
  const weekNumber = dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1;
  const suffix = weekNumber === 1 ? `${weekNumber}st` : weekNumber === 2 ? `${weekNumber}nd` : weekNumber === 3 ? `${weekNumber}rd` : `${weekNumber}th`;
  const [selectableRecurrence, setSeletableRecurrence] = useState(
    language === 'ko'
      ? [
          '반복 안 함',
          '매일',
          `매주 ${dayjs().format('dddd')}`,
          `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
          `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`,
          '주중 매일(월-금)',
          '사용자 설정',
        ]
      : [
          'Does not repeat',
          'Daily',
          `Weekly on ${dayjs().format('dddd')}`,
          `Monthly on ${suffix} ${dayjs(date).format('dddd')}`,
          `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
          'Every weekday (Mon-Fri)',
          'Custom',
        ],
  );
  // const [selectableRecurrence, setSeletableRecurrence] = useState([
  //   'Does not repeat',
  //   'Daily',
  //   `Weekly on ${dayjs().format('dddd')}`,
  //   `Monthly on ${suffix} ${dayjs(date).format('dddd')}`,
  //   `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
  //   'Every weekday (Mon-Fri)',
  //   'Custom',
  // ]);
  const selectableRecurrenceDays = [
    { kr: '일', en: 'SU' },
    { kr: '월', en: 'MO' },
    { kr: '화', en: 'TU' },
    { kr: '수', en: 'WE' },
    { kr: '목', en: 'TH' },
    { kr: '금', en: 'FR' },
    { kr: '토', en: 'SA' },
  ];
  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs()
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(TIME_FORMAT_2),
    ),
  ];
  const durationToshowingTime = (duration?: number) => {
    if (!duration) return;
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;

    if (hour === 0) {
      return language === 'ko' ? `${minute}분` : `${minute}min`;
      // return `${minute}min`;
    } else if (minute === 0) {
      return language === 'ko' ? `${hour}시간` : `${hour}hr`;
      // return `${hour}hr`;
    } else {
      return language === 'ko' ? `${hour}시간 ${minute}분` : `${hour}hr ${minute}min`;
      // return `${hour}hr ${minute}min`;
    }
  };
  const [inputDurationSelectValue, setInputDurationSelectValue] = useState(() => durationToshowingTime(timeDiff));
  const selectableTimeDuration = [15, 30, 45, 60, 90, 120, 150, 180].map((v) => durationToshowingTime(v));
  const [unlinkRoutine, setUnlinkRoutine] = useState(false);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement as HTMLElement;
      if (!element) return;

      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
      ) {
        return;
      }

      if (ev.code === 'KeyN') {
        onChangeDate?.(dayjs(date).add(1, 'days').toDate(), true, 'tomorrow');
      }

      if (ev.code === 'KeyM') {
        onChangeDate?.(dayjs(date).day(8).toDate(), true, 'nextWeek');
      }

      if (ev.code === 'KeyL') {
        onChangeDate?.(null, true);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  useEffect(() => {
    const index = selectableRecurrence.findIndex((v) => v === recurrenceUnit);

    if (index < 6) {
      setSeletableRecurrence(
        language === 'ko'
          ? [
              '반복 안 함',
              '매일',
              `매주 ${dayjs(date).format('dddd')}`,
              `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
              `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`,
              '주중 매일(월-금)',
              '사용자 설정',
            ]
          : [
              'Does not repeat',
              'Daily',
              `Weekly on ${dayjs().format('dddd')}`,
              `Monthly on the ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}th ${dayjs(date).format('dddd')}`,
              `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
              'Every weekday (Mon-Fri)',
              'Custom',
            ],
      );
      // setSeletableRecurrence([
      //   'Does not repeat',
      //   'Daily',
      //   `Weekly on ${dayjs().format('dddd')}`,
      //   `Monthly on the ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}th ${dayjs(date).format('dddd')}`,
      //   `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
      //   'Every weekday (Mon-Fri)',
      //   'Custom',
      // ]);
    }

    if (index === 0) {
      if (event?.isRecurrence) {
        handleChangeRecurrence({ interval: 0 });
        setCustomRecurrence(undefined);
      } else {
        handleChangeRecurrence(undefined);
        setCustomRecurrence(undefined);
      }
    } else if (index === 1) {
      handleChangeRecurrence({ interval: 1, intervalUnit: 'DAILY' });
      setCustomRecurrence({ interval: 1, intervalUnit: 'DAILY' });
    } else if (index === 2) {
      const weekdays = [dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[];
      handleChangeRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
      setCustomRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
    } else if (index === 3) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
    } else if (index === 4) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
    } else if (index === 5) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
    } else {
      handleChangeRecurrence(customRecurrence);
    }
  }, [recurrenceUnit]);

  useEffect(() => {
    const recurrence = event?.recurrence || event?.routine?.recurrence;
    if (!recurrence) return;
    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);
    if (recurrence.interval === 1) {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('Daily');
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays: string[] = [];
        const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
        // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];
        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        const weekEveryday = newWeekdays.length === targetArray.length && targetArray.every((day) => newWeekdays.includes(day));

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else
              list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else
          //   list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      }
    } else {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
            else list.splice(6, 1, `${recurrence.interval}일마다`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
            else list.splice(6, 1, `Every ${recurrence.interval} days`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else
            //   list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else
              list.splice(6, 1, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  }, [event?.recurrence, event?.routine?.recurrence]);

  useEffect(() => {
    formatDateTime(inputTimeSelectValue);
  }, [inputTimeSelectValue]);

  useEffect(() => {
    showingTimeToDuration(inputDurationSelectValue);
  }, [inputDurationSelectValue]);

  useEffect(() => {
    if (refTimeSelection.current) {
      const rect = refTimeSelection.current.getBoundingClientRect();
      const overflow = rect.bottom - window.innerHeight;

      if (overflow > 0) {
        const el =
          document.querySelectorAll('.MuiPaper-root').length > 1
            ? (document.querySelectorAll('.MuiPaper-root')[1] as HTMLDivElement)
            : (document.querySelectorAll('.MuiPaper-root')[0] as HTMLDivElement);
        if (el) {
          const currentTop = parseInt(el.style.top || '0', 10); // 현재 top 값을 숫자로 가져오기
          el.style.top = `${currentTop - overflow}px`; // 새로운 top 값 설정
        }
      }
    }
  }, [dueDate]);

  useUpdateEffect(() => {
    if (!timeDiff) return;
    if (startTime) setEndTime(dayjs(startTime).add(timeDiff, 'minute').format(DATE_FORMAT_7));
    else setEndTime(undefined);
  }, [startTime]);

  useUpdateEffect(() => {
    if (!timeDiff) return;
    if (startTime) setEndTime(dayjs(startTime).add(timeDiff, 'minute').format(DATE_FORMAT_7));
  }, [timeDiff]);

  useEffect(() => {
    if (openStartTime && !startTime) {
      const currentTime =
        dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15) >=
        dayjs().set('hour', 23).set('minute', 45)
          ? dayjs().set('hour', 23).set('minute', 45).format(DATE_FORMAT_7)
          : dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15).format(DATE_FORMAT_7);

      const index = selectableTime.findIndex((time) => time === dayjs(currentTime).format(TIME_FORMAT_2));

      setTimeout(() => {
        if (index === -1) return;
        const target = document.querySelector(`[data-option-index="${index}"]`);
        if (target) {
          target.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
    }
  }, [openStartTime]);

  useClickOutside(calendarRef, () => {
    if (calendarAnchorEl) return;
    setOpenCalendar(false);
  });

  useClickOutside(timeSelectRef, () => {
    if (timeAnchorEl) return;
    setOpenTimeSelection(false);
  });

  const handleClickSave = async (target?: InCreateTaskBoxInRecurrenceTarget) => {
    const newRecurrence: InUpdateRoutineRuleOptionalTime | undefined = taskboxRecurrence
      ? {
          recurrence: taskboxRecurrence,
          start:
            !startTime && !endTime
              ? { date: dayjs(event?.start?.date || dayjs(event?.start?.datetime, { utc: true })).format(DATE_FORMAT_4) }
              : { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
          end:
            !startTime && !endTime
              ? {
                  date: dayjs(event?.start?.date || dayjs(event?.start?.datetime, { utc: true }))
                    .add(1, 'day')
                    .format(DATE_FORMAT_4),
                }
              : { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
        }
      : undefined;

    if (dateRange[0] && dateRange[0].start && dateRange[0].end && dueDate) {
      onChangeTime(
        dayjs(dateRange[0].start, { utc: true }).format(DATE_FORMAT_4),
        dayjs(dateRange[0].end, { utc: true }).format(DATE_FORMAT_4),
        timeDiff!,
        event!.id!,
        false,
        newRecurrence,
      );
      onClose();
      return;
    }

    if (startTime && !timeDiff) {
      language === 'ko' ? toast.error('지속 시간을 설정해주세요.') : toast.error('Set duration');
      // toast.error('Set duration');
      return;
    }

    /**변화 없으면 시간 모달창 닫기 */
    if (
      (dayjs(event?.start?.datetime).isSame(dayjs(startTime)) && dayjs(event?.end?.datetime).isSame(dayjs(endTime)) && !isAllDay) ||
      (event?.start?.date && event?.end?.date && isAllDay) ||
      event?.durationMin === timeDiff
    ) {
      if (!newRecurrence) {
        onClose();
        return;
      }
    }

    onChangeTime(
      dayjs(startTime, { utc: true }).format(DATE_FORMAT_1),
      dayjs(endTime, { utc: true }).format(DATE_FORMAT_1),
      timeDiff!,
      event!.id!,
      !startTime && !endTime && timeDiff ? true : startTime && endTime ? false : isAllDay,
      newRecurrence,
    );
    onClose();
  };

  const handleChangeRecurrence = (recurrence: InRecurrence | undefined) => {
    setTaskboxRecurrence(recurrence);
  };

  const handleCloseCustomReccurence = () => {
    if (customRecurrence) {
      if (customRecurrence.interval === 1) {
        if (customRecurrence.intervalUnit === 'DAILY') {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('매일');
        } else if (customRecurrence.intervalUnit === 'WEEKLY') {
          const newWeekdays: string[] = [];
          const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
          // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];

          if (customRecurrence.weekdays) {
            for (const weekdayCode of customRecurrence.weekdays) {
              const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
              if (weekday) {
                language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
                // newWeekdays.push(`${weekday.en}`);
              }
            }
          }

          const weekEveryday = targetArray.every((day) => newWeekdays.includes(day));

          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Every ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Every ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (customRecurrence.intervalUnit === 'MONTHLY') {
          if (customRecurrence.monthlyType === 'nth_weekday') {
            const weekNumber = dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1;
            const suffix =
              weekNumber === 1 ? `${weekNumber}st` : weekNumber === 2 ? `${weekNumber}nd` : weekNumber === 3 ? `${weekNumber}rd` : `${weekNumber}th`;

            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Every on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Every on the ${suffix} ${dayjs(date).format('dddd')}`);
          } else {
            setRecurrenceUnit(selectableRecurrence[6]);
          }
        } else if (customRecurrence.intervalUnit === 'YEARLY') {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Every year on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Every year on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      } else {
        setRecurrenceUnit(selectableRecurrence[6]);
      }
    } else {
      setRecurrenceUnit(selectableRecurrence[0]);
    }
  };

  const handleClickCustomRecurrence = (recurrence: InRecurrence) => {
    if (!recurrence) return;
    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);

    if (recurrence.interval === 1) {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('Daily');
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays: string[] = [];
        const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
        // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];
        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        const weekEveryday = newWeekdays.length === targetArray.length && targetArray.every((day) => newWeekdays.includes(day));

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else
              list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else
          //   list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      }
    } else {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
            else list.splice(6, 1, `${recurrence.interval}일마다`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
            else list.splice(6, 1, `Every ${recurrence.interval} days`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else
            //   list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else
              list.splice(6, 1, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  };

  const handleOpenRecurrenceDialog = () => {
    const recurrence = event?.recurrence || event?.routine?.recurrence;
    if (taskboxRecurrence && recurrence?.weekStart) {
      taskboxRecurrence.weekStart = recurrence.weekStart;
    }

    /**변화 없으면 시간 모달창 닫기 */
    if (
      (dayjs(event?.start?.datetime).isSame(dayjs(startTime)) && dayjs(event?.end?.datetime).isSame(dayjs(endTime)) && !isAllDay) ||
      (event?.start?.date && event?.end?.date && isAllDay)
    ) {
      if (!isUpdateRecurrence) {
        onClose();
        return;
      }
    }
    setIsOpenRecurrenceDialog(true);
  };

  const handleCloseRecurrenceDialog = () => {
    setIsOpenRecurrenceDialog(false);
  };

  /**종료일 설정 */
  const handleClickSettingDueDate = () => {
    setDueDate(!dueDate);
  };

  /**Time Select 창 열기 */
  const handleOpenTimeSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTimeAnchorEl(e.currentTarget);
    setOpenTimeSelection(true);
  };

  /**Time Select 창 닫기 */
  const handleCloseTimeSelection = () => {
    if (openStartTime || openDurationTime) return;
    setTimeAnchorEl(null);
    setIsAllDay(false);
  };

  /** 시작일 설정 로직 */
  //유저가 시간 타이핑하면 그 시간으로 바꿔주기(onInputChange)
  const handleInputTimeSelectValue = (value: string) => {
    setInputTimeSelectValue(value);
  };

  //유저가 시간 클릭/입력 시 그 시간으로 바꿔주기(onChange) 오후 01:00 -> DATE_FORMAT_1형태로 변경
  const formatDateTime = (value: string) => {
    const isPM = language === 'ko' ? value.includes('오후') : value.includes('pm');
    const isAM = language === 'ko' ? value.includes('오전') : value.includes('am');
    let timeString = language === 'ko' ? value.replace(/(오전|오후|:|\s)/g, '') : value.replace(/(am|pm|:|\s)/g, '');
    // const isPM = value.includes('pm');
    // const isAM = value.includes('am');
    // let timeString = value.replace(/(am|pm|:|\s)/g, '');

    // 오전 12시를 00시로 변경
    if (isAM && timeString.startsWith('12')) {
      timeString = timeString.replace('12', '00');
    }

    let convertedTime = '';

    if (Number(timeString)) {
      setTimeError(timeString.length > 4);
      convertedTime = convertToTime(Number(timeString));
    } else {
      language === 'ko'
        ? setTimeError(timeString !== '' || value.includes('오후') || value.includes('오전'))
        : setTimeError(timeString !== '' || value.includes('pm') || value.includes('am'));
      // setTimeError(timeString !== '' || value.includes('pm') || value.includes('am'));
    }

    const isAfterNoon = dayjs(convertedTime, 'HH:mm').isAfter(dayjs('12:00', 'HH:mm')) || dayjs(convertedTime, 'HH:mm').isSame(dayjs('12:00', 'HH:mm'));

    const startDateTime = event?.start?.datetime
      ? dayjs(event?.start?.datetime, { utc: true }).format(DATE_FORMAT_1)
      : dayjs(event?.start?.date).format(DATE_FORMAT_1);

    const [datePart, timePart] = startDateTime.split('T');

    // timePart에서 + 또는 -를 추출하고, 이를 timezoneSign에 저장
    const timezoneSign = timePart.includes('-') ? '-' : '+';
    const timezoneOffset = timePart.split(/[+-]/)[1] || '00:00';

    // timezoneSign을 사용해 formattedDateTime 생성
    const formattedDateTime = `${datePart}T${convertedTime}:00${timezoneSign}${timezoneOffset}`;

    return isPM && !isAfterNoon
      ? dayjs(formattedDateTime, { utc: true }).add(12, 'hour').format(DATE_FORMAT_1)
      : dayjs(formattedDateTime, { utc: true }).format(DATE_FORMAT_1);
  };

  // 0100 -> 01:00형태로 변경
  const convertToTime = (time: number): string => {
    const timeStr = time.toString().padStart(4, '0');
    const hours = parseInt(timeStr.slice(0, 2), 10);
    const minutes = parseInt(timeStr.slice(2), 10);
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleStartTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setOpenStartTime(true);
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      if (timeError || inputTimeSelectValue === '') {
        setStartTime(startTime);
        setInputTimeSelectValue(dayjs(startTime, { utc: true }).format(TIME_FORMAT_2));
      } else {
        setStartTime(formatDateTime(inputTimeSelectValue));
      }
      if (openStartTime) setOpenStartTime(false);
    }

    if (e.key === 'Escape') {
      e.stopPropagation();
      setOpenStartTime(false);
      (document.activeElement as HTMLElement).blur();
      if (!openStartTime) setTimeAnchorEl(null);
    }
  };

  /**Duration 시간 설정 */
  const handleInputDurationSelectValue = (value: string) => {
    setInputDurationSelectValue(value);
  };

  // '1시간' -> 60형태로 변환
  const showingTimeToDuration = (showingTime?: string) => {
    if (!showingTime) return;

    const durationString = language === 'ko' ? showingTime.replace(/(시간|시|분|\s)/g, '') : showingTime.replace(/(hr|h|min|m|\s)/g, '');
    // const durationString = showingTime.replace(/(hr|h|min|m|\s)/g, '');

    if (isNaN(Number(durationString)) || Number(durationString) <= 0) {
      setDurationError(true);
      return;
    }

    if (durationError) setDurationError(false);

    const matchHourMinute = language === 'ko' ? showingTime.match(/(\d*)?\s*시간?\s*(\d*)?\s*분?/) : showingTime.match(/(\d+)\s*(hr|h)\s*(\d+)?\s*(min|m)?/);
    const matchMinute = language === 'ko' ? showingTime.match(/\s*(\d*)\s*분?/) : showingTime.match(/\s*(\d*)\s*(min|m)?/);
    // const matchHourMinute = showingTime.match(/(\d+)\s*(hr|h)\s*(\d+)\s*(min|m)?/);
    // const matchMinute = showingTime.match(/\s*(\d*)\s*(min|m)?/);

    let duration = 0;

    if (matchHourMinute) {
      const hours = parseInt(matchHourMinute[1], 10) || 0;
      const minutes = language === 'ko' ? parseInt(matchHourMinute[2], 10) || 0 : parseInt(matchHourMinute[3], 10) || 0;
      // const minutes = parseInt(matchHourMinute[3], 10) || 0;
      duration = hours * 60 + minutes;
    } else if (matchMinute) {
      const minutes = parseInt(matchMinute[1], 10) || 0;
      duration = minutes;
    }

    setDurationLimitError(duration > 540);
    return duration;
  };

  const handleDurationTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      if (durationError || durationLimitError || inputDurationSelectValue === '') {
        setEndTime(endTime);
        setInputDurationSelectValue(durationToshowingTime(timeDiff));
      } else {
        setTimeDiff(showingTimeToDuration(inputDurationSelectValue)!);
      }
      setOpenDurationTime(false);
    }

    if (e.key === 'Escape') {
      e.stopPropagation();
      setOpenDurationTime(false);
      (document.activeElement as HTMLElement).blur();
      if (!openDurationTime) setTimeAnchorEl(null);
    }
  };

  /** 달력 선택 로직 */
  const handleCloseCalendar = () => {
    setCalendarAnchorEl(null);
  };

  const [dateRange, setDateRange] = useState<
    {
      start: Date;
      end: Date | undefined;
    }[]
  >(
    event?.allDay
      ? event.type === 'TERM_TASK'
        ? [
            {
              start: dayjs(event?.start?.date).toDate(),
              end: dayjs(event?.end?.date).subtract(1, 'day').toDate(),
            },
          ]
        : [
            {
              start: dayjs(event?.start?.date).toDate(),
              end: dayjs(event?.start?.date).toDate(),
            },
          ]
      : [
          {
            start: dayjs(event?.start?.datetime).toDate(),
            end: dayjs(event?.end?.datetime).toDate(),
          },
        ],
  );

  const handleChangeDateRange = (range: { start: Date; end: Date }[]) => {
    setDateRange(range);
  };

  const handleRemoveStartTime = () => {
    setStartTime(undefined);
    setIsAllDay(true);
  };

  const handleRemoveDurationTime = () => {
    setStartTime(undefined);
    setEndTime(undefined);
    setTimeDiff(undefined);
    setIsAllDay(true);
  };

  const handleUnlinkRecurrence = () => {
    if (!event) return;
    onUnlinkRoutine(event.id!);
  };

  return (
    <Container ref={refTimeSelection}>
      <Header>
        {language === 'ko' ? '날짜 및 시간 설정' : 'Date and time setting'}
        {/* Date and time setting */}
      </Header>
      {!dueDate && !suppressMoveDate && (
        <>
          <PostponeMenuWrapper>
            <Tooltip
              title={
                <div style={{ padding: '2px 0px' }}>
                  <span>
                    {language === 'ko'
                      ? `다음 날 (${dayjs(date).add(1, 'day').format('M월 D일 dd요일')})`
                      : `Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`}
                    {/* {`Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`} */}
                  </span>
                  <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                    N
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <PostponeMenuItem onClick={() => onChangeDate?.(dayjs(date).add(1, 'days').toDate(), true, 'tomorrow')}>
                <Icons.Tomorrow width={20} height={20} stroke={COLORS.gray600} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                  <div>
                    {language === 'ko' ? '다음 날' : 'Next day'}
                    {/* Next day */}
                  </div>
                  <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                    N
                  </KeyboardButtonRect>
                </div>
              </PostponeMenuItem>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ padding: '2px 0px' }}>
                  <span>
                    {language === 'ko'
                      ? `다음주 월요일 (${dayjs(date).day(8).format('M월 D일 dd요일')})`
                      : `Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`}
                    {/* {`Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`} */}
                  </span>
                  <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                    M
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <PostponeMenuItem onClick={() => onChangeDate?.(dayjs(date).day(8).toDate(), true, 'nextWeek')}>
                <Icons.NextMonday width={20} height={20} stroke={COLORS.gray600} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                  <div>
                    {language === 'ko' ? '다음주 월요일' : 'Next Monday'}
                    {/* Next Monday */}
                  </div>
                  <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                    M
                  </KeyboardButtonRect>
                </div>
              </PostponeMenuItem>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ padding: '2px 0px' }}>
                  <span>
                    {language === 'ko' ? `나중에` : `Later`}
                    {/* Later */}
                  </span>
                  <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                    L
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <PostponeMenuItem onClick={() => onChangeDate?.(null, true)}>
                <Icons.Later width={20} height={20} fill={COLORS.gray600} />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                  <div>
                    {language === 'ko' ? `나중에` : `Later`}
                    {/* Later */}
                  </div>
                  <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                    L
                  </KeyboardButtonRect>
                </div>
              </PostponeMenuItem>
            </Tooltip>
          </PostponeMenuWrapper>
          <Divider sx={{ marginBottom: '16px' }} />
        </>
      )}
      {!dueDate && (
        <SettingDateWrapper>
          <Icons.CalendarWithoutClock width={16} height={16} />
          <DateShowingWrapper
            ref={calendarRef}
            focus={openCalendar}
            onClick={(e) => {
              setCalendarAnchorEl(e.currentTarget);
              setOpenCalendar(true);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: '16px' }}>{dayjs(event?.matchDate, { utc: true }).format(DATE_FORMAT_2)}</div>
            </div>
          </DateShowingWrapper>
          {!dueDate && (
            <Button
              variant="contained"
              onClick={handleClickSettingDueDate}
              sx={{
                'color': COLORS.gray600,
                'borderRadius': '8px',
                'backgroundColor': COLORS.gray100,
                'boxShadow': 'none',
                'fontSize': '12px',
                'padding': '4px 8px',
                'textTransform': 'none',
                '&:hover': {
                  backgroundColor: COLORS.gray200,
                  boxShadow: 'none',
                },
              }}
            >
              {language === 'ko' ? '종료일 설정' : 'Set end date'}
              {/* Set end date */}
            </Button>
          )}
        </SettingDateWrapper>
      )}
      {dueDate && (
        <DateRangeShowingWrapper style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
          <Icons.CalendarWithoutClock width={16} height={16} />
          <span>
            {dayjs(dateRange[0].start).format(DATE_FORMAT_2)} - {dayjs(dateRange[0].end).format(DATE_FORMAT_2)}
          </span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickSettingDueDate();
            }}
            sx={{ padding: '4px', borderRadius: '6px' }}
          >
            <Icons.Close width={16} height={16} stroke={COLORS.gray900} />
          </IconButton>
        </DateRangeShowingWrapper>
      )}
      {dueDate && <DateRangeCalendar dateRange={dateRange} onChange={handleChangeDateRange} />}
      {!dueDate && event!.type !== 'TERM_TASK' && (
        <TimeSelectionWrapper>
          <Icons.Time />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '8px ' }}>
            <StartTimeWrapper timeError={timeError}>
              <Autocomplete
                className="auto-complete"
                freeSolo
                open={openStartTime}
                value={startTime ? dayjs(startTime, { utc: true }).format(TIME_FORMAT_2) : ''}
                inputValue={inputTimeSelectValue}
                placeholder={
                  language === 'ko' ? '시작 시간' : 'Start time'
                  // 'Start time'
                }
                onChange={(e, newValue) => {
                  if (!newValue) return;
                  setStartTime(formatDateTime(newValue!));
                  setOpenStartTime(false);
                  setIsAllDay(false);
                  (document.activeElement as HTMLElement).blur();
                }}
                onInputChange={(e, newValue) => {
                  handleInputTimeSelectValue(newValue);
                }}
                onKeyDown={(e) => {
                  handleStartTimeKeyDown(e);
                }}
                onFocus={() => {
                  setOpenStartTime(true);
                }}
                onBlur={() => {
                  // if (timeError || inputTimeSelectValue === '') {
                  //   setStartTime(startTime);
                  //   setInputTimeSelectValue(dayjs(startTime).format(TIME_FORMAT_2));
                  // } else {
                  //   setStartTime(formatDateTime(inputTimeSelectValue));
                  // }
                  setOpenStartTime(false);
                }}
                options={selectableTime}
                isOptionEqualToValue={(option, value) => option === value}
                size="small"
                PopperComponent={(props: PopperProps) => (
                  <Popper
                    {...props}
                    placement="bottom"
                    sx={{
                      '&.MuiAutocomplete-noOptions': {
                        display: 'none',
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                )}
                ListboxProps={{
                  style: {
                    display: timeError ? 'none' : 'block',
                    maxHeight: '264px',
                    fontSize: '12px',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      language === 'ko' ? '시작 시간' : 'Start time'
                      // 'Start time'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: startTime && (
                        <InputAdornment
                          position={
                            language === 'ko' ? 'end' : 'start'
                            // 'start'
                          }
                          disablePointerEvents
                          disableTypography
                          sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                        >
                          {language === 'ko' ? '부터' : 'From'}
                          {/* from */}
                        </InputAdornment>
                      ),
                      style: {
                        height: '34px',
                        fontSize: '12px',
                        padding: '8px',
                      },
                    }}
                  />
                )}
              />
              {startTime && (
                <IconButton
                  className="start-close-button"
                  onClick={handleRemoveStartTime}
                  sx={{
                    'width': '16px',
                    'height': '16px',
                    'backgroundColor': COLORS.gray600,
                    'opacity': 0,
                    'padding': '0px',
                    'position': 'absolute',
                    'top': '-6px',
                    'left': '112px',
                    ':hover': {
                      backgroundColor: COLORS.gray900,
                    },
                  }}
                >
                  <Icons.Close width={10} height={10} stroke={COLORS.white} />
                </IconButton>
              )}
            </StartTimeWrapper>
            <DurationTimeWrapper durationError={durationError || durationLimitError}>
              <Autocomplete
                className="auto-complete"
                freeSolo
                open={openDurationTime}
                value={timeDiff ? durationToshowingTime(timeDiff) : ''}
                inputValue={inputDurationSelectValue}
                placeholder={
                  language === 'ko' ? '지속 시간' : 'Duration'
                  // 'Duration'
                }
                onChange={(e, newValue) => {
                  if (!newValue) return;
                  setTimeDiff(showingTimeToDuration(newValue)!);
                  setOpenDurationTime(false);
                  setIsAllDay(false);
                  (document.activeElement as HTMLElement).blur();
                }}
                onInputChange={(e, newValue) => {
                  setInputDurationSelectValue(newValue);
                }}
                onKeyDown={(e) => {
                  handleDurationTimeKeyDown(e);
                }}
                onFocus={() => {
                  setOpenDurationTime(true);
                }}
                onBlur={(e) => {
                  // setTimeout(() => {
                  //   console.log(inputDurationSelectValue);
                  //   if (durationError || durationLimitError || inputDurationSelectValue === '') {
                  //     setEndTime(endTime);
                  //     setInputDurationSelectValue(durationToshowingTime(timeDiff));
                  //   } else {
                  //     setTimeDiff(showingTimeToDuration(inputDurationSelectValue)!);
                  //   }
                  // }, 1000);
                  setOpenDurationTime(false);
                }}
                options={selectableTimeDuration}
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      language === 'ko' ? '지속 시간' : 'Duration'
                      // 'Duration'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: timeDiff && (
                        <InputAdornment
                          position={
                            language === 'ko' ? 'end' : 'start'
                            // 'start'
                          }
                          disablePointerEvents
                          disableTypography
                          sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                        >
                          {language === 'ko' ? '동안' : 'for'}
                          {/* for */}
                        </InputAdornment>
                      ),
                      style: {
                        height: '34px',
                        fontSize: '12px',
                        padding: '8px',
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  style: {
                    fontSize: '12px',
                  },
                }}
              />
              {timeDiff && (
                <IconButton
                  className="duration-close-button"
                  onClick={handleRemoveDurationTime}
                  sx={{
                    'width': '16px',
                    'height': '16px',
                    'backgroundColor': COLORS.gray600,
                    'opacity': 0,
                    'padding': '0px',
                    'position': 'absolute',
                    'top': '-6px',
                    'left': '112px',
                    ':hover': {
                      backgroundColor: COLORS.gray900,
                    },
                  }}
                >
                  <Icons.Close width={10} height={10} stroke={COLORS.white} />
                </IconButton>
              )}
            </DurationTimeWrapper>
          </div>
        </TimeSelectionWrapper>
      )}
      {!event?.project && (
        <RecurrenceWrapper className="recurrence">
          <Icons.Recurrence width={16} height={16} stroke={COLORS.gray900} />
          <Select
            value={recurrenceUnit}
            readOnly={!!event?.routine}
            onChange={(e) => {
              setRecurrenceUnit(e.target.value);
              setIsUpdateRecurrence(true);
            }}
            style={{ width: '100%', height: 34, fontSize: '13px', marginLeft: '8px' }}
            renderValue={() => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontSize: '12px' }}>{recurrenceUnit}</div>
              </div>
            )}
            inputProps={{
              sx: {
                padding: '8px',
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 372,
                },
              },
            }}
          >
            {selectableRecurrence.map((v, idx) => (
              <MenuItem key={idx} value={v}>
                <span style={{ fontSize: '12px', width: '100%' }}>{v}</span>
              </MenuItem>
            ))}
          </Select>
          {event?.routine && (
            <Tooltip
              title={
                language === 'ko' ? '루틴과 연결 해제하기' : 'Unlink from routine'
                // 'Unlink from routine'
              }
            >
              <IconButton
                onClick={handleUnlinkRecurrence}
                sx={{
                  position: 'absolute',
                  right: 20,
                }}
              >
                <Icons.Close width={12} height={12} />
              </IconButton>
            </Tooltip>
          )}
        </RecurrenceWrapper>
      )}
      <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          disableElevation
          onClick={onClose}
          style={{ width: '48%', height: 40, color: `${COLORS.black}`, backgroundColor: `${COLORS.gray100}`, textTransform: 'none' }}
        >
          {language === 'ko' ? '취소하기' : 'Cancel'}
          {/* Cancel */}
        </Button>

        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            event?.isRecurrence ? handleOpenRecurrenceDialog() : handleClickSave();
          }}
          style={{ width: '48%', height: 40, textTransform: 'none' }}
        >
          {language === 'ko' ? '저장하기' : 'Save'}
          {/* Save */}
        </Button>
      </div>
      <Popover
        disablePortal
        open={Boolean(calendarAnchorEl)}
        anchorEl={calendarAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleCloseCalendar}
        sx={{ marginTop: '2px' }}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={event?.start?.date || event?.start?.datetime}
          onChange={(newValue) => onChangeDate?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true, 'calendar')}
          renderInput={(params) => <TextField {...params} />}
        />
      </Popover>
      {/* <Popover
        disablePortal
        open={Boolean(timeAnchorEl)}
        anchorEl={timeAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="time-select"
        onClose={handleCloseTimeSelection}
        sx={{ marginTop: '2px' }}
      >
        <TimeSelectPopoverWrapper timeError={timeError} durationError={durationError || durationLimitError}>
          <Autocomplete
            className="auto-complete"
            freeSolo
            open={openStartTime}
            value={dayjs(startTime).format(TIME_FORMAT_2)}
            inputValue={inputTimeSelectValue}
            placeholder="hh:mm"
            onChange={(e, newValue) => {
              if (!newValue) return;
              setStartTime(formatDateTime(newValue!));
              setOpenStartTime(false);
              setIsAllDay(false);
              (document.activeElement as HTMLElement).blur();
            }}
            onInputChange={(e, newValue) => {
              handleInputTimeSelectValue(newValue);
            }}
            onKeyDown={(e) => {
              handleStartTimeKeyDown(e);
            }}
            onFocus={() => {
              setOpenStartTime(true);
            }}
            onBlur={() => {
              setOpenStartTime(false);
            }}
            options={selectableTime}
            isOptionEqualToValue={(option, value) => option === value}
            size="small"
            PopperComponent={(props: PopperProps) => (
              <Popper
                {...props}
                placement="bottom"
                sx={{
                  '&.MuiAutocomplete-noOptions': {
                    display: 'none',
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            ListboxProps={{
              style: {
                display: timeError ? 'none' : 'block',
                maxHeight: '264px',
                fontSize: '12px',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="hh:mm"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      disablePointerEvents
                      disableTypography
                      sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                    >
                      부터
                    </InputAdornment>
                  ),
                  style: {
                    height: '34px',
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
              />
            )}
            sx={{ width: '117px' }}
          />
          <Autocomplete
            className="auto-complete"
            freeSolo
            open={openDurationTime}
            value={durationToshowingTime(timeDiff)}
            inputValue={inputDurationSelectValue}
            onChange={(e, newValue) => {
              if (!newValue) return;
              setEndTime(dayjs(startTime).add(showingTimeToDuration(newValue!)!, 'minute').format(DATE_FORMAT_7));
              setOpenDurationTime(false);
              setIsAllDay(false);
              (document.activeElement as HTMLElement).blur();
            }}
            // inputValue={inputTimeSelectValue}
            onInputChange={(e, newValue) => {
              handleInputDurationSelectValue(newValue);
            }}
            onKeyDown={(e) => {
              handleDurationTimeKeyDown(e);
            }}
            onFocus={() => {
              setOpenDurationTime(true);
            }}
            onBlur={() => {
              setOpenDurationTime(false);
            }}
            options={selectableTimeDuration}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      disablePointerEvents
                      disableTypography
                      sx={{ fontSize: '12px', color: COLORS.gray500, cursor: 'pointer', paddingTop: '2px' }}
                    >
                      동안
                    </InputAdornment>
                  ),
                  style: {
                    height: '34px',
                    fontSize: '12px',
                    padding: '8px',
                  },
                }}
              />
            )}
            ListboxProps={{
              style: {
                fontSize: '12px',
              },
            }}
            sx={{ width: '117px' }}
          />
        </TimeSelectPopoverWrapper>
      </Popover> */}
      <UpdateRecurrenceDialog open={isOpenRecurrenceDialog} onClose={handleCloseRecurrenceDialog} onUpdateRecurrence={handleClickSave} />
      <Dialog
        open={
          language === 'ko' ? recurrenceUnit === '사용자 설정' : recurrenceUnit === 'Custom'
          // recurrenceUnit === 'Custom'
        }
        onClose={handleCloseCustomReccurence}
      >
        <RecurrenceCustomDialog
          date={date}
          defaultCustomRecurrence={customRecurrence}
          onClose={handleCloseCustomReccurence}
          onChange={handleClickCustomRecurrence}
        />
      </Dialog>
      {(timeError || durationError || durationLimitError) && (
        <SelectErrorWrapper timeError={timeError} durationError={durationError} durationLimitError={durationLimitError}>
          {language === 'ko' ? (durationLimitError ? '9시간까지 지정 가능' : '잘못된 시간') : durationLimitError ? 'Can be set up to 9 hours' : 'Invalid time'}
          {/* {durationLimitError ? 'Can be set up to 9 hours' : 'Invalid time'} */}
        </SelectErrorWrapper>
      )}
    </Container>
  );
};

export default TimeDateSelectionModal;

const Container = styled.div`
  background-color: ${COLORS.white};
  z-index: 100;
  box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 16px;

  .MuiMenu-paper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  /* .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
  }

  .css-epd502 {
    width: 200px;
    height: 190px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  } */

  .MuiPickersCalendarHeader-labelContainer {
    max-height: 40px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 100%;
  }

  .css-epd502 {
    width: 100%;
  }

  .MuiCalendarPicker-root {
    width: 100%;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }

  .css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-czqvw5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 0px;
  }

  .auto-complete {
    .MuiInputBase-input {
      padding: 0px !important;
    }
  }

  .time-select {
    .MuiSvgIcon-root {
      display: none;
    }
  }

  .recurrence {
    .MuiSvgIcon-root {
      display: none;
    }
  }
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const RecurrenceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px 12px 0px;
`;

const DateWrapper = styled.div`
  font-size: 13px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 12px;
`;

const PostponeMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px;
`;

const SettingDateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const DateShowingWrapper = styled.div<{ focus?: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  font-size: 12px;
  margin: 0px 8px 0px 8px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    ${(props) => !props.focus && `border: 1px solid ${COLORS.gray900};`}
  }
  ${(props) =>
    props.focus &&
    css`
      border: 2px solid ${COLORS.brand1};
      padding: 7px;
    `}
`;

const PostponeMenuItem = styled.div`
  width: 107.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 8px;
  font-size: 12px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const TimeSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const StartTimeWrapper = styled.div<{ timeError?: boolean }>`
  width: 48%;
  position: relative;
  :hover {
    .start-close-button {
      opacity: 1;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.timeError && `border-color: ${COLORS.negative1};`}
  }
`;

const DurationTimeWrapper = styled.div<{ durationError?: boolean }>`
  width: 48%;
  position: relative;
  :hover {
    .duration-close-button {
      opacity: 1;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.durationError && `border-color: ${COLORS.negative1};`}
  }
`;

const TimeSelectPopoverWrapper = styled.div<{ timeError?: boolean; durationError?: boolean }>`
  width: 274px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.timeError && `border-color: ${COLORS.negative1};`}
    ${(props) => props.durationError && `border-color: ${COLORS.negative1};`}
  }

  .MuiAutocomplete-noOptions {
    display: 'none';
  }
`;

const SelectErrorWrapper = styled.div<{ timeError?: boolean; durationError?: boolean; durationLimitError?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  border-radius: 8px;
  bottom: 100px;
  z-index: 10000;
  padding: 4px 6px;
  border: 1px solid ${COLORS.gray900};
  color: ${COLORS.white};
  background-color: ${COLORS.gray900};
  ${(props) =>
    props.timeError &&
    css`
      left: 57px;
    `}
  ${(props) =>
    props.durationError &&
    css`
      right: 57px;
    `}
    ${(props) =>
    props.durationLimitError &&
    css`
      right: 37.5px;
    `}
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 4px' : '4px'}`};
`;

const DateRangeShowingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  font-size: 12px;
`;
