import styled from '@emotion/styled';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { useState } from 'react';
import { ITimezone, ITimezoneOption, useTimezoneSelect } from 'react-timezone-select';
import { UserSettingModel } from 'queries/model';
import timezones from 'assets/timezones.json';
import { timezoneSetterAtom } from 'atoms/timezone';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

interface LanguageAndTimezoneProps {
  setting?: UserSettingModel;
  onChange?: (calendarData: UserSettingModel) => void;
}

const LanguageAndTimezoneSetting = ({ setting, onChange }: LanguageAndTimezoneProps) => {
  const [language, setLanguage] = useState<string>(setting?.language || 'eng');
  const [timezone, setTimezone] = useState<ITimezone>(setting?.timezone || 'Asia/Seoul');
  const { options } = useTimezoneSelect(
    language === 'ko'
      ? { labelStyle: 'original', timezones }
      : {
          labelStyle: 'original',
          timezones: Object.keys(timezones).reduce((acc: { [key: string]: string }, key) => {
            acc[key] = key;
            return acc;
          }, {} as { [key: string]: string }),
        },
  );
  const [, setGlobalTimezone] = useAtom(timezoneSetterAtom);
  const [, setCommonLanguage] = useAtom(languageAtom);
  const { i18n } = useTranslation();

  const handleChangeTimezone = (e: SelectChangeEvent<string | ITimezoneOption>) => {
    const timezone = e.target.value as string;

    setTimezone(timezone);
    setGlobalTimezone(timezone);
    onChange?.({ timezone });
  };

  const handleChangeLanguage = (e: SelectChangeEvent<string>) => {
    const lang = e.target.value;
    i18n.changeLanguage(lang === 'kor' ? 'ko' : 'en');
    onChange?.({ language: e.target.value });
    setLanguage(lang);
    setCommonLanguage(lang === 'kor' ? 'ko' : 'en');
  };

  const { t } = useTranslation();

  return (
    <Container>
      <div>
        <HeaderTitle>{t('settings.language.header')}</HeaderTitle>
        <ContentWrapper>
          <div style={{ display: 'flex' }}>
            <Icons.Globe stroke={COLORS.gray900} style={{ marginTop: 1 }} />
            <ContentTitleWrapper>
              <ContentTitle>{t('settings.language.title')}</ContentTitle>
              <ContentDescription>{t('settings.language.description')}</ContentDescription>
            </ContentTitleWrapper>
          </div>
          <Select value={language} sx={{ fontSize: '13px', height: '42px', width: '100px' }} onChange={handleChangeLanguage}>
            <MenuItem value={'eng'}>{'English'}</MenuItem>
            <MenuItem value={'kor'}>{'한국어'}</MenuItem>
          </Select>
        </ContentWrapper>
      </div>
      <div>
        <HeaderTitle>{t('settings.timezone.header')}</HeaderTitle>
        <ContentWrapper>
          <div style={{ display: 'flex' }}>
            <Icons.Time style={{ marginTop: 1 }} />
            <ContentTitleWrapper>
              <ContentTitle>{t('settings.timezone.title')}</ContentTitle>
              <ContentDescription>{t('settings.timezone.description')}</ContentDescription>
            </ContentTitleWrapper>
          </div>
          <Select value={timezone} onChange={handleChangeTimezone} sx={{ fontSize: '13px', height: '42px' }}>
            {options.map((option, idx) => (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </ContentWrapper>
      </div>
    </Container>
  );
};

export default LanguageAndTimezoneSetting;

const Container = styled.div`
  width: 630px;

  & > div + div {
    margin-top: 56px;
  }
`;

const HeaderTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 24px;
  background-color: ${COLORS.white};
  border-radius: 8px;
`;

const ContentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  gap: 4px;
`;

const ContentTitle = styled.p`
  font-size: 13px;
  font-weight: 700;
`;

const ContentDescription = styled.p`
  font-size: 12px;
  color: ${COLORS.gray500};
`;
