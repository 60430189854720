import styled from '@emotion/styled';
import { Button, Divider, IconButton, Popover, TextField } from '@mui/material';
import dayjs from 'lib/dayjs';
import { OutRecord } from 'queries/model';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import { osName } from 'react-device-detect';
import { useEffect, useRef, useState } from 'react';
import { useKeyboardEvent } from '@react-hookz/web';
import { Icons } from 'components';
import InboxContextMenu from 'components/InboxContextMenu';
import { setCaretToEnd } from 'utils';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface IReflectionTemplate {
  readOrcreateOrUpdate: 'read' | 'create' | 'update';
  record: OutRecord;
  customHeader?: React.ReactNode;
  padding?: string;
  isTaskView?: boolean;
  isReflectionReadView?: boolean;
  closeDialog?: () => void;
  createOrUpdateRecord?: () => void;
  onChange?: (record: OutRecord) => void;
  onDelete?: (record: OutRecord) => void;
}

export const ReflectionTemplate = ({
  readOrcreateOrUpdate,
  record,
  customHeader,
  padding = '24px',
  isTaskView,
  isReflectionReadView,
  closeDialog,
  createOrUpdateRecord,
  onChange,
  onDelete,
}: IReflectionTemplate) => {
  const [language] = useAtom(languageAtom);
  const [isEmptyRecord, setIsEmptyRecord] = useState(true);
  const memoInput = useRef<HTMLInputElement | null>(null);
  const keepInput = useRef<HTMLInputElement | null>(null);
  const problemInput = useRef<HTMLInputElement | null>(null);
  const tryInput = useRef<HTMLInputElement | null>(null);
  const [detailAnchorEl, setDetailAnchorEl] = useState<HTMLElement | null>(null);
  const [focusInput, setFocusInput] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!record.content.memo && !record.content.keep && !record.content.problem && !record.content.try) {
      setIsEmptyRecord(true);
    } else {
      setIsEmptyRecord(false);
    }
  }, [record]);

  useEffect(() => {
    if (memoInput.current) {
      const input = memoInput.current;
      const value = input.value;
      input.focus();
      input.setSelectionRange(value.length, value.length);
    }
  }, []);

  useKeyboardEvent(
    true,
    (ev) => {
      if ((ev.ctrlKey || ev.metaKey) && ev.code === 'Enter' && !isEmptyRecord) {
        createOrUpdateRecord && createOrUpdateRecord();
      }
    },
    [],
    { event: 'keydown', eventOptions: { passive: true } },
  );

  const handleChangeMemoText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!record) return;
    onChange && onChange({ ...record, id: record.id, date: record?.date, content: { ...record.content, memo: e.target.value } });
  };

  const handleChangeKeepText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!record) return;
    onChange && onChange({ ...record, id: record.id, date: record?.date, content: { ...record.content, keep: e.target.value } });
  };

  const handleChangeProblemText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!record) return;
    onChange && onChange({ ...record, id: record.id, date: record?.date, content: { ...record.content, problem: e.target.value } });
  };

  const handleChangeTryText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!record) return;
    onChange && onChange({ ...record, id: record.id, date: record?.date, content: { ...record.content, try: e.target.value } });
  };

  const handleDeleteRecord = () => {
    onDelete && onDelete(record);
    setDetailAnchorEl(null);
  };

  const editable = readOrcreateOrUpdate !== 'read';

  return (
    <Container taskView={isTaskView} reflectionReadView={isReflectionReadView}>
      {isTaskView && (
        <TaskViewReflectionWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={require('assets/images/reflectionImg2.png')} style={{ width: '150px', height: '150px' }} />
            <div style={{ fontSize: '20px', fontWeight: 700 }}>{language === 'ko' ? '오늘 어떤 하루를 보냈나요?' : 'How was your day?'}</div>
            <div style={{ fontSize: '13px' }}>{language === 'ko' ? '회고로 하루 1% 성장을 완성해봐요.' : 'Complete your 1% daily growth with reflection.'}</div>
          </div>
          <Divider orientation="vertical" sx={{ height: '100%', marginLeft: '32px' }} />
        </TaskViewReflectionWrapper>
      )}
      <div style={!customHeader ? { width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' } : {}}>
        {!customHeader && (
          <WritingHeader padding={padding}>
            <DateBox>
              <div>{dayjs(record.date).format(language === 'ko' ? 'M월 DD일 (dd)' : 'MMMM DD (dd)')}</div>
              {record.createdAt !== '' && (
                <IconButton
                  onClick={(e) => setDetailAnchorEl(e.currentTarget)}
                  sx={{ width: '24px', height: '24px', borderRadius: '6px', marginLeft: '4px', padding: '4px' }}
                >
                  <Icons.More />
                </IconButton>
              )}
            </DateBox>
            <BtnBox>
              {editable ? (
                <>
                  <CancelBtn
                    onClick={() => {
                      closeDialog && closeDialog();
                    }}
                    style={{ marginRight: '8px' }}
                  >
                    {isTaskView ? (language === 'ko' ? '다음에 하기' : 'Do it later') : language === 'ko' ? '취소' : 'Cancel'}
                  </CancelBtn>
                  <Button
                    onClick={() => {
                      createOrUpdateRecord && createOrUpdateRecord();
                    }}
                    style={{
                      borderRadius: '8px',
                      backgroundColor: isEmptyRecord ? COLORS.gray300 : COLORS.brand1,
                      color: isEmptyRecord ? COLORS.gray500 : COLORS.white,
                      fontSize: '13px',
                      fontWeight: 700,
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      textTransform: 'none',
                    }}
                    disabled={isEmptyRecord}
                  >
                    {readOrcreateOrUpdate === 'create'
                      ? language === 'ko'
                        ? '작성 완료'
                        : 'Write complete '
                      : language === 'ko'
                      ? '수정 완료'
                      : 'Edit complete'}
                    <KeyboardButtonRect style={{ marginLeft: 8, backgroundColor: isEmptyRecord ? COLORS.gray400 : COLORS.white }}>
                      {osName === 'Windows' ? 'Ctrl' : '⌘'}
                    </KeyboardButtonRect>
                    <KeyboardCommandPlus color={isEmptyRecord ? COLORS.gray500 : COLORS.white}>+</KeyboardCommandPlus>
                    <KeyboardButtonRect style={{ backgroundColor: isEmptyRecord ? COLORS.gray400 : COLORS.white }}>Enter</KeyboardButtonRect>
                  </Button>
                </>
              ) : (
                <CancelBtn
                  onClick={() => {
                    closeDialog && closeDialog();
                  }}
                >
                  {language === 'ko' ? '닫기' : 'Close'}
                </CancelBtn>
              )}
            </BtnBox>
          </WritingHeader>
        )}
        <WritingWrapper reflectionReadView={isReflectionReadView} style={{ padding: padding }}>
          {customHeader && customHeader}
          <WritingContentWrapper edit={editable} style={{ marginTop: '0px', display: !record.content.memo && !editable ? 'none' : 'flex' }}>
            <OpacityWrapper
              isFocus={
                !editable || focusInput === memoInput.current || (memoInput.current?.textContent && memoInput.current?.textContent.length > 0 ? true : false)
              }
            >
              <WritingContentTitle style={{ margin: '0px 0px 8px 0px' }}>{language === 'ko' ? '메모' : 'Memo'}</WritingContentTitle>
            </OpacityWrapper>
            <TextField
              inputRef={memoInput}
              size="small"
              fullWidth
              multiline
              disabled={!editable}
              placeholder={
                language === 'ko'
                  ? '생각을 정리하거나 보충 설명을 작성하고 싶다면 메모를 활용하세요.'
                  : 'Use notes to organize your thoughts or provide additional explanations.'
              }
              value={record.content.memo || ''}
              onChange={handleChangeMemoText}
              onFocus={() => {
                if (!memoInput) return;
                setFocusInput(memoInput.current);
              }}
              onBlur={() => {
                setFocusInput(null);
              }}
              sx={{ padding: '0px', borderTop: editable ? '1px solid transparent' : `1px solid ${COLORS.gray200}` }}
              inputProps={{
                style: {
                  minHeight: '40px',
                  fontSize: '13px',
                  WebkitTextFillColor: COLORS.gray900,
                },
              }}
            />
          </WritingContentWrapper>
          <WritingContentWrapper edit={editable} style={{ display: !record.content.keep && !editable ? 'none' : 'flex' }}>
            <OpacityWrapper
              isFocus={
                !editable || focusInput === keepInput.current || (keepInput.current?.textContent && keepInput.current?.textContent.length > 0 ? true : false)
              }
            >
              <WritingContentHeader style={{ backgroundColor: COLORS.positive2 }}>😀&nbsp;&nbsp;Keep</WritingContentHeader>
              <WritingContentTitle>
                {language === 'ko' ? '잘한 것, 계속 이어갈 것은 무엇인가요?' : 'What did you do well? What would you like to continue doing?'}
              </WritingContentTitle>
            </OpacityWrapper>
            <TextField
              inputRef={keepInput}
              size="small"
              fullWidth
              multiline
              disabled={!editable}
              placeholder={
                language === 'ko'
                  ? '잘한 것을 계속 이어가는 것도 중요합니다. 스스로를 칭찬해보세요!'
                  : 'It’s important to keep doing what you’re doing well. Praise yourself!'
              }
              value={record.content.keep || ''}
              onChange={handleChangeKeepText}
              onFocus={() => {
                if (!keepInput) return;
                setFocusInput(keepInput.current);
              }}
              onBlur={() => {
                setFocusInput(null);
              }}
              sx={{ padding: '0px', borderTop: editable ? '1px solid transparent' : `1px solid ${COLORS.gray200}` }}
              inputProps={{
                style: {
                  minHeight: '40px',
                  fontSize: '13px',
                  WebkitTextFillColor: COLORS.gray900,
                },
              }}
            />
          </WritingContentWrapper>
          <WritingContentWrapper edit={editable} style={{ display: !record.content.problem && !editable ? 'none' : 'flex' }}>
            <OpacityWrapper
              isFocus={
                !editable ||
                focusInput === problemInput.current ||
                (problemInput.current?.textContent && problemInput.current?.textContent.length > 0 ? true : false)
              }
            >
              <WritingContentHeader style={{ backgroundColor: COLORS.negative2 }}>😡&nbsp;&nbsp;Problem</WritingContentHeader>
              <WritingContentTitle>{language === 'ko' ? '개선할 것은 무엇인가요?' : 'What would you like to improve?'}</WritingContentTitle>
            </OpacityWrapper>
            <TextField
              inputRef={problemInput}
              size="small"
              fullWidth
              multiline
              disabled={!editable}
              placeholder={
                language === 'ko'
                  ? '아쉬운 점이 있었나요? 스스로 피드백을 줄 게 있다면 정리해봐요.'
                  : 'Was there something that could have gone better? Reflect on it and give yourself feedback.'
              }
              value={record.content.problem || ''}
              onChange={handleChangeProblemText}
              onFocus={() => {
                if (!problemInput) return;
                setFocusInput(problemInput.current);
              }}
              onBlur={() => {
                setFocusInput(null);
              }}
              sx={{ padding: '0px', borderTop: editable ? '1px solid transparent' : `1px solid ${COLORS.gray200}` }}
              inputProps={{
                style: {
                  minHeight: '40px',
                  fontSize: '13px',
                  WebkitTextFillColor: COLORS.gray900,
                },
              }}
            />
          </WritingContentWrapper>
          <WritingContentWrapper edit={editable} style={{ display: !record.content.try && !editable ? 'none' : 'flex' }}>
            <OpacityWrapper
              isFocus={
                !editable || focusInput === tryInput.current || (tryInput.current?.textContent && tryInput.current?.textContent.length > 0 ? true : false)
              }
            >
              <WritingContentHeader style={{ backgroundColor: COLORS.sub3 }}>👉&nbsp;&nbsp;Try</WritingContentHeader>
              <WritingContentTitle>{language === 'ko' ? '새롭게 시도할 것은 무엇인가요?' : 'What would you like to try next?'}</WritingContentTitle>
            </OpacityWrapper>
            <TextField
              inputRef={tryInput}
              size="small"
              fullWidth
              multiline
              disabled={!editable}
              placeholder={
                language === 'ko'
                  ? '위 회고를 바탕으로 시도할 게 있나요? 무엇을 해야 할까요?'
                  : 'Based on this reflection, is there something you want to try? What should you do next?'
              }
              value={record.content.try || ''}
              onChange={handleChangeTryText}
              onFocus={() => {
                if (!tryInput) return;
                setFocusInput(tryInput.current);
              }}
              onBlur={() => {
                setFocusInput(null);
              }}
              sx={{ padding: '0px', borderTop: editable ? '1px solid transparent' : `1px solid ${COLORS.gray200}` }}
              inputProps={{
                style: {
                  minHeight: '40px',
                  fontSize: '13px',
                  WebkitTextFillColor: COLORS.gray900,
                },
              }}
            />
          </WritingContentWrapper>
          <Popover
            open={Boolean(detailAnchorEl)}
            anchorEl={detailAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setDetailAnchorEl(null)}
          >
            <InboxContextMenu id={''} type={'reflection'} menus={['DELETE']} onClickMenu={handleDeleteRecord} />
          </Popover>
        </WritingWrapper>
      </div>
    </Container>
  );
};

const Container = styled.div<{ taskView?: boolean; reflectionReadView?: boolean }>`
  display: flex;
  height: 100%;
  ${(props) => props.taskView && `padding: 32px;`}
  ${(props) =>
    props.reflectionReadView &&
    `width: 100%; display: flex; justify-content: center; max-height: calc(100% - 54px); margin-top: 24px; overflow-y: scroll; ${hideScroll()}`}
`;

const TaskViewReflectionWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WritingWrapper = styled.div<{ reflectionReadView?: boolean }>`
  width: 100%;
  background-color: ${COLORS.white};
  border-radius: 12px;
  overflow-y: scroll;
  ${hideScroll()}
  ${(props) => props.reflectionReadView && `width: 630px;`}
`;

const WritingHeader = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 32px 80px 0px 80px;
`;

const DateBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
`;

const BtnBox = styled.div``;

const CancelBtn = styled(Button)`
  border-radius: 8px;
  box-shadow: 0 0 0 1px ${COLORS.gray200} inset;
  background: var(--White, #fff);
  color: ${COLORS.gray900};
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
`;

const WritingContentWrapper = styled.div<{ edit?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (!props.edit ? '30px' : '24px')};
  .MuiInputBase-root {
    ${(props) => (!props.edit ? `padding: 12px 0px` : `padding: 12px 16px;`)}
  }
  .MuiOutlinedInput-notchedOutline {
    ${(props) => !props.edit && `border: none;`}
  }
`;

const OpacityWrapper = styled.div<{ isFocus: boolean }>`
  opacity: ${(props) => (props.isFocus ? 1 : 0.5)};
`;

const WritingContentHeader = styled.div`
  width: fit-content;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 8px;
`;

const WritingContentTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0px 12px 0px;
`;

const KeyboardButtonRect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  height: 16px;
  background: #ffffff;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  color: ${COLORS.gray500};
`;

const KeyboardCommandPlus = styled.div`
  font-size: 10px;
  margin: 0px 4px;
`;
