import styled from '@emotion/styled';
import dayjs from 'lib/dayjs';
import { on } from 'events';
import { m } from 'framer-motion';
import { OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DATE_FORMAT_7 } from 'utils/datetimeFormat';

export const TimePicker = {
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
  MERIDIEM: 'MERIDIEM',
} as const;

export type TimePickerType = typeof TimePicker[keyof typeof TimePicker];

export interface TimePickerProps {
  taskbox?: OutTaskboxDetailResponse;
  suppressMeridiem?: boolean;
  onChange?: (startTime: Date | undefined, duration: number | null | undefined, isAllDay: boolean) => void;
}

export const SwiperTimePicker = ({ taskbox, suppressMeridiem = false, onChange }: TimePickerProps) => {
  const hours = suppressMeridiem
    ? ['00', '01', '02', '03', '04', '05', '06']
    : Array.from({ length: 12 }, (_, i) => (i + 1).toString()).map((v) => (v.length === 1 ? `0${v}` : v));
  const minutes = ['00', '15', '30', '45'];
  const ampm = ['AM', 'PM'];
  const [hour, setHour] = useState<number>(taskbox?.allDay ? dayjs().hour() % 12 || 12 : dayjs(taskbox?.start?.datetime).hour() % 12 || 12);
  const [durationHour, setDurationHour] = useState<number>(
    taskbox?.type === 'TIME_TASK'
      ? Math.floor(dayjs(taskbox?.end?.datetime).diff(dayjs(taskbox?.start?.datetime), 'minute') / 60 || 0)
      : taskbox?.durationMin
      ? Math.floor(taskbox.durationMin / 60)
      : 1,
  );
  const [minute, setMinute] = useState<number>(
    taskbox?.allDay
      ? Math.ceil(dayjs().minute() / 15) * 15 === 60
        ? 0
        : Math.ceil(dayjs().minute() / 15) * 15
      : Math.ceil(dayjs(taskbox?.start?.datetime).minute() / 15) * 15 === 60
      ? 0
      : Math.ceil(dayjs(taskbox?.start?.datetime).minute() / 15) * 15,
  );
  const [isAllDay, setIsAllDay] = useState<boolean>(taskbox?.allDay || false);
  const [durationMinute, setDurationMinute] = useState<number>(
    taskbox?.allDay ? taskbox.durationMin! % 60 || 0 : dayjs(taskbox?.end?.datetime).diff(dayjs(taskbox?.start?.datetime), 'minute') % 60 || 0,
  );
  const [meridiem, setMeridiem] = useState<string>(
    taskbox?.allDay ? (dayjs().hour() < 12 ? 'AM' : 'PM') : dayjs(taskbox?.start?.datetime).hour() < 12 ? 'AM' : 'PM',
  );

  const handleChangeTime = (menu: TimePickerType, index: number) => {
    if (!suppressMeridiem) {
      setIsAllDay(false);
      switch (menu) {
        case TimePicker.HOUR:
          setHour(parseInt(hours[index]));
          break;
        case TimePicker.MINUTE:
          setMinute(parseInt(minutes[index]));
          break;
        case TimePicker.MERIDIEM:
          setMeridiem(ampm[index]);
          break;
      }
    } else {
      switch (menu) {
        case TimePicker.HOUR:
          setDurationHour(parseInt(hours[index]));
          break;
        case TimePicker.MINUTE:
          setDurationMinute(parseInt(minutes[index]));
          break;
      }
    }
  };

  useEffect(() => {
    if (suppressMeridiem) {
      const duration = durationHour * 60 + durationMinute;
      onChange?.(
        !taskbox?.start?.datetime && duration === 0 ? undefined : dayjs(taskbox?.start?.datetime).toDate(),
        duration,
        taskbox?.start?.datetime && duration === 0
          ? true
          : taskbox?.start?.datetime && duration !== 0
          ? false
          : !taskbox?.start?.datetime && duration === 0
          ? true
          : isAllDay,
      );
    } else {
      const startTime =
        meridiem === 'PM'
          ? dayjs()
              .hour(hour + 12)
              .minute(minute)
              .toDate()
          : dayjs().hour(hour).minute(minute).toDate();
      const duration = taskbox?.allDay ? taskbox.durationMin : dayjs(taskbox?.end?.datetime).diff(dayjs(taskbox?.start?.datetime), 'minute');
      onChange?.(startTime, duration, startTime ? false : !startTime ? true : isAllDay);
    }
  }, [hour, durationHour, minute, durationMinute, meridiem]);

  const convertToString = (time: string) => {
    return time.toString().length === 1 ? `0${time.toString()}` : time.toString();
  };

  return (
    <Container onTouchStart={(e) => e.stopPropagation()} onTouchMove={(e) => e.stopPropagation()}>
      <Swiper
        direction="vertical"
        slidesPerView={3}
        slideToClickedSlide
        centeredSlides
        initialSlide={suppressMeridiem ? hours.indexOf(convertToString(durationHour.toString())) : hours.indexOf(convertToString(hour.toString()))}
        onSlideChange={(swiper) => handleChangeTime('HOUR', swiper.activeIndex)}
        style={{ height: '120px' }}
      >
        {hours.map((hour, idx) => (
          <SwiperSlide key={idx}>
            {({ isActive }) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '60px',
                  height: '40px',
                  color: isActive ? COLORS.white : COLORS.gray900,
                  fontWeight: isActive ? 'bold' : 'normal',
                  borderRadius: '8px',
                  backgroundColor: isActive ? COLORS.brand1 : COLORS.white,
                }}
              >
                {hour}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        direction="vertical"
        slidesPerView={3}
        slideToClickedSlide
        centeredSlides
        initialSlide={suppressMeridiem ? minutes.indexOf(convertToString(durationMinute.toString())) : minutes.indexOf(convertToString(minute.toString()))}
        onSlideChange={(swiper) => handleChangeTime('MINUTE', swiper.activeIndex)}
        style={{ height: '120px', marginLeft: '10px' }}
      >
        {minutes.map((minute, idx) => (
          <SwiperSlide key={idx}>
            {({ isActive }) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '60px',
                  height: '40px',
                  color: isActive ? COLORS.white : COLORS.gray900,
                  fontWeight: isActive ? 'bold' : 'normal',
                  borderRadius: '8px',
                  backgroundColor: isActive ? COLORS.brand1 : COLORS.white,
                }}
              >
                {minute}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {!suppressMeridiem && (
        <Swiper
          direction="vertical"
          slidesPerView={2}
          slideToClickedSlide
          centeredSlides
          initialSlide={ampm.indexOf(meridiem)}
          onSlideChange={(swiper) => handleChangeTime('MERIDIEM', swiper.activeIndex)}
          style={{ height: '80px', marginTop: '20px', marginLeft: '10px' }}
        >
          {ampm.map((value, idx) => (
            <SwiperSlide key={idx}>
              {({ isActive }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60px',
                    height: '40px',
                    color: isActive ? COLORS.white : COLORS.gray900,
                    fontWeight: isActive ? 'bold' : 'normal',
                    borderRadius: '8px',
                    backgroundColor: isActive ? COLORS.brand1 : COLORS.white,
                  }}
                >
                  {value}
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 0px;
`;
