import React, { FC, useCallback } from 'react';
import { HorizontalRuleExtension } from '@remirror/extension-horizontal-rule';
import { useCommands, useCurrentSelection } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';
import { useActive } from '@remirror/react';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

export interface InsertHorizontalRuleButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const InsertHorizontalRuleButton: FC<InsertHorizontalRuleButtonProps> = (props) => {
  const [language] = useAtom(languageAtom);
  const { insertHorizontalRule } = useCommands<HorizontalRuleExtension>();
  // Force component update on selection change
  const onClose = props.onClose;
  useCurrentSelection();

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (insertHorizontalRule.enabled()) {
      insertHorizontalRule();
    }
  }, [insertHorizontalRule]);

  const active = useActive<HorizontalRuleExtension>().horizontalRule();
  const enabled = insertHorizontalRule.enabled();

  return <CommandButton {...props} commandName={language === 'ko' ? '디바이더' : 'Divider'} active={active} enabled={enabled} onSelect={handleSelect} />;
};
