import styled from '@emotion/styled';
import { Button, IconButton, Popover } from '@mui/material';
import { Icons } from 'components';
import React, { useState } from 'react';
import { COLORS } from 'styles/constants';
import { osName } from 'react-device-detect';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface CreateEventInfoProps {
  onClose?: () => void;
  onClickNeverShowCreateEventInfo?: () => void;
}

const CreateEventInfo = ({ onClose, onClickNeverShowCreateEventInfo }: CreateEventInfoProps) => {
  const [language] = useAtom(languageAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <Container>
      <TitleWrapper>
        <span>{language === 'ko' ? '일정을 보다 쉽게 생성하려면?' : 'Want to create a schedule more easily?'}</span>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ width: '24px', height: '24px', borderRadius: '8px', padding: '4px' }}>
          <Icons.Close width={16} height={16} />
        </IconButton>
      </TitleWrapper>
      <SubtitleWrapper>
        <IconWrapper>
          <Icons.TutorialReplay />
        </IconWrapper>
        {language === 'ko' ? (
          <>
            <span style={{ marginRight: 4 }}>간편하게 단축키</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '18px',
                height: '18px',
                border: `1.125px solid ${COLORS.gray200}`,
                borderRadius: '2.25px',
                color: COLORS.gray600,
                paddingTop: '1px',
                fontSize: '13px',
              }}
            >
              {osName === 'Windows' && 'Ctrl'}
              {osName === 'Mac OS' && '⌘'}
            </span>
            <span style={{ color: COLORS.gray600, fontSize: '13px', margin: '0px 2.25px' }}>+</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '18px',
                border: `1.125px solid ${COLORS.gray200}`,
                borderRadius: '2.25px',
                color: COLORS.gray600,
                padding: '1px 4px 0px 4px',
                fontSize: '13px',
              }}
            >
              클릭
            </span>
            <span style={{ marginLeft: 4 }}>로도 일정을 생성할 수 있어요</span>
          </>
        ) : (
          <>
            <span style={{ marginRight: 4 }}>Simply create one using shortcuts</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '18px',
                height: '18px',
                border: `1.125px solid ${COLORS.gray200}`,
                borderRadius: '2.25px',
                color: COLORS.gray600,
                paddingTop: '1px',
                fontSize: '13px',
              }}
            >
              {osName === 'Windows' && 'Ctrl'}
              {osName === 'Mac OS' && '⌘'}
            </span>
            <span style={{ color: COLORS.gray600, fontSize: '13px', margin: '0px 2.25px' }}>+</span>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '18px',
                border: `1.125px solid ${COLORS.gray200}`,
                borderRadius: '2.25px',
                color: COLORS.gray600,
                padding: '1px 4px 0px 4px',
                fontSize: '13px',
              }}
            >
              Click
            </span>
          </>
        )}
      </SubtitleWrapper>
      <img
        src={
          osName === 'Windows'
            ? require(language === 'ko' ? 'assets/images/event_creation_info_window.png' : 'assets/images/event_creation_info_window_en.png')
            : osName === 'Mac OS'
            ? require(language === 'ko' ? 'assets/images/event_creation_info_mac.png' : 'assets/images/event_creation_info_mac_en.png')
            : ''
        }
      />
      <ButtonWrapper>
        <Button
          variant="contained"
          sx={{
            'backgroundColor': COLORS.white,
            'borderRadius': '8px',
            'boxShadow': 'none',
            'color': COLORS.brand1,
            'marginRight': '8px',
            'textTransform': 'none',
            '&:hover': {
              backgroundColor: COLORS.white,
            },
          }}
          onClick={onClickNeverShowCreateEventInfo}
        >
          {language === 'ko' ? '다시 보지 않기' : `Don't show again`}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: '8px',
            boxShadow: 'none',
            textTransform: 'none',
          }}
          onClick={onClose}
        >
          {language === 'ko' ? '확인' : 'OK!'}
        </Button>
      </ButtonWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          borderRadius: '8px',
        }}
      >
        <div style={{ padding: 8 }}>
          <Button
            onClick={onClickNeverShowCreateEventInfo}
            sx={{
              color: COLORS.gray900,
              fontSize: '12px',
              padding: '8px',
              textTransform: 'none',
            }}
          >
            {language === 'ko' ? '다시 보지 않기' : `Don't show again`}
          </Button>
        </div>
      </Popover>
    </Container>
  );
};

export default CreateEventInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  background-color: ${COLORS.white};
  border-radius: 16px;
  margin-left: 4px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.gray700};
  margin-bottom: 2px;
  font-size: 24px;
  font-weight: 700;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray700};
  font-size: 16px;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 50%;
  padding: 4px;
  margin-right: 8px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;
