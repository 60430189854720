import { SVGProps } from 'react';

export const StartNow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.33203 11.5519V4.44075C3.33203 3.85437 3.33203 3.56086 3.45543 3.38736C3.56312 3.23594 3.72912 3.13652 3.91341 3.11275C4.12449 3.08553 4.38337 3.2236 4.90039 3.49934L11.5671 7.0549L11.5695 7.05597C12.1409 7.3607 12.4267 7.51314 12.5204 7.71631C12.6022 7.89355 12.6022 8.09815 12.5204 8.27539C12.4265 8.47884 12.14 8.6319 11.5671 8.93749L4.90039 12.493C4.383 12.769 4.12456 12.9065 3.91341 12.8793C3.72912 12.8555 3.56312 12.7561 3.45543 12.6047C3.33203 12.4312 3.33203 12.1382 3.33203 11.5519Z"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
