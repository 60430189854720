import { Button, Icons, Input } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Fonts from 'components/Fonts';
import toast from 'react-hot-toast';
import { createWorkspaceV1AuthWorkspacesPost } from 'queries';

const JoinWorkspace = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const navigate = useNavigate();

  const title = '등록된 이메일 주소와 연관되는 \n워크스페이스가 없습니다';

  const onClickCreateWorkspaceButton = async () => {
    // TODO: 여러번 클릭되지 않도록 수정
    if (workspaceName === '') {
      toast.error('워크스페이스 이름을 입력해주세요.');
      return;
    }
    const createWorkspace = {
      name: workspaceName,
    };
    const success = await createWorkspaceV1AuthWorkspacesPost(createWorkspace);
    if (success) toast.success('새로운 워크스페이스를 생성하였습니다.');
    else toast.error('새로운 워크스페이스 생성에 실패하였습니다.');
    navigate('/task');
  };

  return (
    <Wrapper className="px-8">
      <div className="pb-12">
        <Icons.Logo width={70} />
      </div>
      <Fonts.H1 className="pb-6">{title}</Fonts.H1>
      <div className="input-container">
        <div className="pb-2">
          <Input size={400} label="워크스페이스 이름을 입력해주세요." value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)} />
        </div>
        <Button height={64} width={400} disabled={workspaceName === ''} label={'새로운 워크스페이스 만들기'} onClick={onClickCreateWorkspaceButton} primary />
      </div>
    </Wrapper>
  );
};

export default JoinWorkspace;

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 120px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
  align-items: center;

  .input-container {
    max-width: 480px;
  }
`;
