import dayjs from 'lib/dayjs';
import { atom } from 'jotai';
export const ritualAlarmAtom = atom<boolean>(localStorage.getItem('ritual-alarm') === 'true');
export const ritualEntryAtom = atom(localStorage.getItem('ritual-entry') ? JSON.parse(localStorage.getItem('ritual-entry')!) : []);
export const highlightNotificationAtom = atom<boolean>(localStorage.getItem('highlight-notification') === 'true');
export const shutdownHighlightNotificationAtom = atom(
  localStorage.getItem('shutdown-highlight-notification')
    ? JSON.parse(localStorage.getItem('shutdown-highlight-notification')!)
    : [dayjs().format('YYYY-MM-DD'), false],
);
