import { SVGProps } from 'react';

export const Required = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M12.5418 16.5H11.0381L11.1708 13.0725L8.25184 14.9079L7.5 13.6032L10.5516 12.0111L7.5 10.3968L8.25184 9.09214L11.1708 10.9275L11.0381 7.5H12.5418L12.4091 10.9275L15.328 9.09214L16.0799 10.3968L13.0504 12.0111L16.0799 13.6032L15.328 14.9079L12.4091 13.0725L12.5418 16.5Z"
      fill="#1F2023"
    />
  </svg>
);
