import { Popover, PopoverProps } from '@mui/material';
import InboxContextMenu, { InboxContextMenuName, InboxContextMenuProps } from './InboxContextMenu';
import { OutTaskboxDetailResponse } from 'queries/model';
export { InboxContextMenuName } from './InboxContextMenu';

export type InboxContextMenuType = typeof InboxContextMenuName[keyof typeof InboxContextMenuName];
export interface InboxContextMenuPopoverProps extends PopoverProps {
  id: string;
  type: string;
  data?: any;
  event?: OutTaskboxDetailResponse;
  menus?: InboxContextMenuType[];
  onClickMenu?: InboxContextMenuProps['onClickMenu'];
}

export const InboxContextMenuPopover = ({
  id,
  open,
  anchorEl,
  type,
  data,
  event,
  menus = [],
  onClickMenu,
  onClose,
  ...props
}: InboxContextMenuPopoverProps) => {
  return (
    <Popover open={open} anchorEl={anchorEl} onClose={onClose} {...props}>
      <InboxContextMenu id={id} type={type} data={data} event={event} menus={menus} onClickMenu={onClickMenu} />
    </Popover>
  );
};

export default InboxContextMenuPopover;
