import { timetable } from 'constants/datetime';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { ArrowDownSmall, ArrowUpSmall } from '../Icons';

interface SelectboxProps {
  size?: number;
  optBoxHeight?: number;
  disabled?: boolean;
  focus?: boolean;
  optionDisabled?: boolean;
  contents?: string[];
  label?: string;
  optionLabel?: string;
  handleOptClick?: (content: string) => void;
  secondSelectBox?: boolean;
}

export const Selectbox = ({
  size = 240,
  optBoxHeight = 58,
  disabled = false,
  optionDisabled = false,
  contents,
  label,
  handleOptClick = () => {}, // option클릭 시 실행되는 함수.
  secondSelectBox = false,
}: SelectboxProps) => {
  const [focus, setFocus] = useState(false);
  function onClick() {
    setFocus(!focus);
  }

  const handleTimeModal = (e: any) => {
    if (secondSelectBox) {
      if (focus && !document.querySelectorAll('.time-picker')[1]?.contains(e.target)) {
        setFocus(false);
      }
    } else {
      if (focus && !document.querySelectorAll('.time-picker')[0]?.contains(e.target)) {
        setFocus(false);
      }
    }
  };

  useEffect(() => {
    if (focus) document.addEventListener('mousedown', handleTimeModal);
    return () => {
      document.removeEventListener('mousedown', handleTimeModal);
    };
  });

  /** 초기 시간 설정 */
  useEffect(() => {
    let idx = 0;
    for (let i = 0; i < timetable.length; i++) {
      if (timetable[i] === label) {
        idx = i;
      }
    }
    const optionList = document.querySelector('.option-list');
    optionList?.scrollTo(0, idx * 40 - 80);
  }, [focus]);

  return (
    <Wrapper className="time-picker" disabled={disabled} focus={focus} onClick={onClick}>
      <SelectContainer size={size} onClick={onClick}>
        <Fonts.Body2>{label}</Fonts.Body2>
        {disabled ? (
          <ArrowUpSmall width={16} height={16} fill={COLORS.gray500} onClick={onClick} />
        ) : focus ? (
          <ArrowUpSmall width={16} height={16} onClick={onClick} />
        ) : (
          <ArrowDownSmall width={16} height={16} onClick={onClick} />
        )}
      </SelectContainer>
      {focus && (
        <FocusedUl optBoxHeight={optBoxHeight} className="option-list">
          {contents!.map((content, index) => (
            <OptionLi
              className="time-contents"
              key={`selectbox-option-${index}`}
              role="option"
              size={size}
              optionDisabled={optionDisabled}
              onClick={() => {
                handleOptClick(content);
              }}
            >
              {content}
            </OptionLi>
          ))}
        </FocusedUl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled: boolean; focus: boolean }>`
  width: fit-content;
  height: 48px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  color: ${COLORS.gray900};
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${COLORS.gray200};
      background-color: ${COLORS.gray100};
      color: ${COLORS.gray500};
      pointer-events: none;
      cursor: no-drop;
    `}
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}
`;

const SelectContainer = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
`;

const FocusedUl = styled.ul<{ optBoxHeight: number }>`
  z-index: 1;
  width: fit-content;
  height: ${(props) => props.optBoxHeight}px;
  overflow-y: scroll;
  padding: 8px 0px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.brand1};
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;
  margin: 0px;
  top: 52px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    height: 40%; /* 스크롤바의 길이 */
    background: ${COLORS.sub2}; /* 스크롤바의 색상 */
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #84848447; /*스크롤바 뒷 배경 색상*/
  }
`;

const OptionLi = styled.li<{
  size: number;
  optionDisabled?: boolean;
  optionFocus?: boolean;
}>`
  width: ${(props) => props.size}px;
  height: 40px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray900};
  &:hover {
    background-color: ${COLORS.sub3};
  }
  cursor: pointer;
  ${(props) =>
    props.optionFocus &&
    css`
      color: ${COLORS.brand1};
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.25px;
    `}

  ${(props) =>
    props.optionDisabled &&
    css`
      color: ${COLORS.gray500};
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.25px;
      cursor: no-drop;
      &:hover {
        background-color: ${COLORS.white};
      }
    `}
`;
