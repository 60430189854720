import styled from '@emotion/styled';
import { IconButton, Popover, Tooltip } from '@mui/material';
import { categoryAtom } from 'atoms/category';
import { selectedProjectAtom } from 'atoms/projects';
import { Icons } from 'components';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useAtom } from 'jotai';
import dayjs, { Dayjs } from 'lib/dayjs';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import {
  createCategoryV1CategoryPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  updateProjectV1ProjectProjectIdPatch,
} from 'queries';
import { OutCategory, OutProject, OutTaskboxDetailProjectOnly } from 'queries/model';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { css } from '@emotion/react';
import { languageAtom } from 'atoms/language';

export type ProjectStatus = 'DONE' | 'HOLD' | 'CANCEL' | 'UNLOCK';

interface ProjectViewProps {
  project?: OutProject;
  exceed?: boolean;
  onKeydownProject?: (e: React.KeyboardEvent<HTMLDivElement>, projectId: string) => void;
  onClickProjectCheck?: (projectId: string, status: ProjectStatus) => void;
  onSelectProject?: (project: OutProject) => void;
  onFetch?: () => void;
  onDrop?: (projectId: string) => void;
}

const ProjectView = ({
  project,
  exceed = false,
  onKeydownProject = () => {},
  onClickProjectCheck = () => {},
  onSelectProject = () => {},
  onFetch = () => {},
  onDrop = () => {},
}: ProjectViewProps) => {
  const [language] = useAtom(languageAtom);
  const [projectStatusPopover, setProjectStatusPopover] = useState<HTMLElement | null>();
  const [projectSubtasks, setProjectSubtasks] = useState<OutTaskboxDetailProjectOnly[]>(project?.tasks || []);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedProject] = useAtom(selectedProjectAtom);

  useEffect(() => {
    setProjectSubtasks(project!.tasks!);
  }, [project]);

  const handleClickProjectCheck = async (status: ProjectStatus) => {
    onClickProjectCheck(project!.id, status);
    setProjectStatusPopover(null);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!project) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            await updateProjectV1ProjectProjectIdPatch(project.id!, { categoryIds: category ? [category.id] : [] });
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 선택할 수 없습니다.' : 'Failed to select category.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 생성할 수 없습니다.' : 'Failed to create category.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 삭제할 수 없습니다.' : 'Failed to delete category.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 수정할 수 없습니다.' : 'Failed to update category.');
          }
        }
        break;
    }
  };

  const handleChangeDeadline = async (date: Dayjs | null) => {
    if (!project) return;
    setDeadlineAnchorEl(null);
    try {
      await updateProjectV1ProjectProjectIdPatch(project.id!, { due: date ? date.format('YYYY-MM-DD') : null });
      onFetch && onFetch();
    } catch (e) {
      toast.error(language === 'ko' ? '기한을 설정할 수 없습니다.' : 'Failed to set deadline.');
    }
  };

  const handleDropToProject = async (projectId: string) => {
    onDrop(projectId);
    setIsDragOver(false);
  };

  const isInstance = project && project.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK').length > 0;

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragOver(false);
      }}
      style={{ width: '100%' }}
    >
      <div hidden={isDragOver} className="project-card">
        <ProjectContainer
          key={project!.id}
          isFocus={selectedProject?.id === project?.id}
          done={project?.status === 'DONE'}
          onClick={(e) => {
            e.stopPropagation();
            onSelectProject(project!);
          }}
        >
          {isInstance && <ProjectLine />}
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0px 4px', marginLeft: '8px' }}>
            <div>
              <ProjectHeader>
                {project && project.category && (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(project.category.color)}
                    bgColor={getCategoryBgColor(project.category.color)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryAnchorEl(e.currentTarget);
                    }}
                  >
                    {`# ${project.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(project.category!.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} stroke={getCategoryTextColor(project.category!.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                )}
                {project?.due && (
                  <DeadlineShowingWrapper
                    date={project.due}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeadlineAnchorEl(e.currentTarget);
                    }}
                  >
                    <Icons.Flag
                      fill={dayjs(project.due).isToday() ? COLORS.brand1 : dayjs(project.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                    />
                    <span style={{ marginLeft: '2px' }}>
                      {dayjs(project.due).isToday()
                        ? language === 'ko'
                          ? '오늘'
                          : 'Today'
                        : dayjs(project.due).isYesterday()
                        ? language === 'ko'
                          ? '어제'
                          : 'Yesterday'
                        : dayjs(project.due).isTomorrow()
                        ? language === 'ko'
                          ? '내일'
                          : 'Tomorrow'
                        : language === 'ko'
                        ? dayjs(project.due).format('M월 D일 (dd)')
                        : dayjs(project.due).format('dddd, MMM D')}
                    </span>
                    {/* <span style={{ marginLeft: '2px' }}>
                      {dayjs(project.due).isToday()
                        ? 'Today'
                        : dayjs(project.due).isYesterday()
                        ? 'Yesterday'
                        : dayjs(project.due).isTomorrow()
                        ? 'Tomorrow'
                        : dayjs(project.due).format('dddd, MMM D')}
                    </span> */}
                    <IconButton
                      className="deadline-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDeadline(null);
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(
                            dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
                            0.3,
                          ),
                        },
                      }}
                      style={deadlineAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close
                        width={8}
                        height={8}
                        stroke={dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                      />
                    </IconButton>
                  </DeadlineShowingWrapper>
                )}
              </ProjectHeader>
              <ProjectContent>
                {project?.place === 'DESK' && (
                  <PriorityIssueCheckbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setProjectStatusPopover(e.currentTarget);
                    }}
                    checked={project.done}
                    bordercolor={COLORS.issue2}
                    markercolor={COLORS.issue4}
                    width={20}
                    height={20}
                  />
                )}
                {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
                  <Tooltip
                    title={exceed ? (language === 'ko' ? '데스크가 가득 찼습니다' : 'Desk is full') : language === 'ko' ? '홀드 해제' : 'Unhold'}
                    disableInteractive
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        exceed ? toast.error(language === 'ko' ? '데스크가 가득 찼습니다' : 'Desk is full') : handleClickProjectCheck('UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueHold width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {project?.place === 'ARCHIVE' && project?.done && (
                  <Tooltip title={language === 'ko' ? '완료 해제' : 'Undo completion'} disableInteractive>
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickProjectCheck('UNLOCK');
                      }}
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                    >
                      <Icons.DeskIssueCheck />
                    </IconButton>
                  </Tooltip>
                )}
                {project?.place === 'ARCHIVE' && !project?.done && (
                  <Tooltip
                    title={
                      language === 'ko' ? '취소 해제' : 'Undo cancel'
                      // 'Undo cancel'
                    }
                    disableInteractive
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickProjectCheck('UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueCancel width={12} height={12} />
                    </IconButton>
                  </Tooltip>
                )}
                <ProjectContentTitle contentEditable={false} suppressContentEditableWarning={true} onKeyDown={(e) => onKeydownProject(e, project!.id)}>
                  {project!.title}
                </ProjectContentTitle>
              </ProjectContent>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {project && project.memo && (
                <div>
                  <Icons.TaskboxMemo width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {project && project.links && project.links.length > 0 && (
                <div style={{ marginLeft: 4 }}>
                  <Icons.Link2 width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {projectSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length > 0 && (
                <ProjectSubtask>
                  <Icons.Subtask width={18} height={18} stroke={COLORS.gray500} />
                  <div style={{ color: COLORS.gray500, marginLeft: '4px', marginTop: 2 }}>
                    {projectSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length}
                  </div>
                </ProjectSubtask>
              )}
            </div>
          </div>
          {projectStatusPopover && (
            <Popover
              open={Boolean(projectStatusPopover)}
              anchorEl={projectStatusPopover}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ marginTop: 0.5 }}
              onClose={() => setProjectStatusPopover(null)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ProjectStatus>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('DONE');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCheck />
                  </IconButton>
                  {language === 'ko' ? '완료' : 'Complete'}
                </ProjectStatusDetail>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('HOLD');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueHold />
                  </IconButton>
                  {language === 'ko' ? '홀드' : 'Hold'}
                </ProjectStatusDetail>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('CANCEL');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCancel />
                  </IconButton>
                  {language === 'ko' ? '취소' : 'Cancel'}
                </ProjectStatusDetail>
              </ProjectStatus>
            </Popover>
          )}
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setCategoryAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
          <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setDeadlineAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={project && project.due ? dayjs(project!.due).toDate() : dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
          </Popover>
        </ProjectContainer>
      </div>
      <ProjectDropContainer hidden={!isDragOver} onDrop={() => handleDropToProject(project!.id)} />
    </div>
  );
};

const ProjectDropContainer = styled.div`
  width: 582px;
  height: 102px;
  background-color: ${COLORS.sub5};
  border-radius: 8px;
  border: 1px dashed ${COLORS.sub4};
`;

const ProjectContainer = styled.div<{ done?: boolean; isFocus?: boolean }>`
  width: 582px;
  height: 102px;
  display: flex;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid ${COLORS.gray200};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 20px 1px ${COLORS.gray200};

    .project-detail {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isFocus &&
    css`
      border: 1px solid ${COLORS.issue2};
    `}

  ${(props) =>
    props.done &&
    css`
      opacity: 0.6;
    `}
`;

const ProjectLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.issue2};
  border-radius: 100px;
`;

const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;
`;

const ProjectContentTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-left: 8px;
`;

const ProjectSubtask = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4px;
`;

const CabinetContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CabinetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ProjectStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0px;
`;

const ProjectStatusDetail = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

export default ProjectView;
