import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

interface Props {
  icon: React.ReactNode;
  size?: number;
  pointer?: boolean;
}

export const IconCircle = ({ icon, size = 32, pointer = false }: Props) => {
  return (
    <Wrapper pointer={pointer} size={size}>
      {icon}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size: number; pointer: boolean }>`
  background: ${COLORS.white};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;
