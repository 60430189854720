import { SVGProps } from 'react';

export const ShortcutKey = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.00065 6.66667V6.67333M6.66732 6.66667V6.67333M9.33398 6.66667V6.67333M12.0007 6.66667V6.67333M4.00065 9.33333V9.34M12.0007 9.33333V9.34M6.66732 9.33333L9.33398 9.34M1.33398 5.33333C1.33398 4.97971 1.47446 4.64057 1.72451 4.39052C1.97456 4.14048 2.3137 4 2.66732 4H13.334C13.6876 4 14.0267 4.14048 14.2768 4.39052C14.5268 4.64057 14.6673 4.97971 14.6673 5.33333V10.6667C14.6673 11.0203 14.5268 11.3594 14.2768 11.6095C14.0267 11.8595 13.6876 12 13.334 12H2.66732C2.3137 12 1.97456 11.8595 1.72451 11.6095C1.47446 11.3594 1.33398 11.0203 1.33398 10.6667V5.33333Z"
        stroke={props.stroke ? props.stroke : '#1F2023'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
