import { SVGProps } from 'react';

export const LogOut = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.99935 9.9974L9.99935 7.9974M9.99935 7.9974L7.99935 5.9974M9.99935 7.9974H2.66602M5.99935 4.82979V4.79753C5.99935 4.05079 5.99935 3.67714 6.14467 3.39193C6.2725 3.14104 6.47633 2.93722 6.72721 2.80939C7.01243 2.66406 7.38608 2.66406 8.13281 2.66406H11.1995C11.9462 2.66406 12.3191 2.66406 12.6043 2.80939C12.8552 2.93722 13.0597 3.14104 13.1875 3.39193C13.3327 3.67686 13.3327 4.05006 13.3327 4.79534V11.1998C13.3327 11.9451 13.3327 12.3177 13.1875 12.6027C13.0597 12.8535 12.8552 13.0577 12.6043 13.1855C12.3193 13.3307 11.9467 13.3307 11.2014 13.3307H8.13062C7.38534 13.3307 7.01215 13.3307 6.72721 13.1855C6.47633 13.0577 6.2725 12.8533 6.14467 12.6025C5.99935 12.3172 5.99935 11.9441 5.99935 11.1974V11.1641"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
