import { SVGProps } from 'react';

export const CalendarTransparency = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.33333 5.33073H4.13346C3.38673 5.33073 3.01308 5.33073 2.72786 5.47605C2.47698 5.60389 2.27316 5.80771 2.14532 6.05859C2 6.34381 2 6.71746 2 7.46419V11.1975C2 11.9443 2 12.3174 2.14532 12.6027C2.27316 12.8535 2.47698 13.0577 2.72786 13.1855C3.0128 13.3307 3.386 13.3307 4.13127 13.3307H11.8687C12.614 13.3307 12.9867 13.3307 13.2716 13.1855C13.5225 13.0577 13.727 12.8535 13.8548 12.6027C14 12.3177 14 11.9451 14 11.1998V7.462C14 6.71673 14 6.34353 13.8548 6.05859C13.727 5.80771 13.5225 5.60389 13.2716 5.47605C12.9864 5.33073 12.6135 5.33073 11.8668 5.33073H10.6667M5.33333 5.33073H10.6667M5.33333 5.33073C5.33333 3.85797 6.52724 2.66406 8 2.66406C9.47276 2.66406 10.6667 3.85797 10.6667 5.33073"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
