import { SVGProps } from 'react';

export const SubTaskCollapse = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66602 3.91406C2.2518 3.91406 1.91602 4.24985 1.91602 4.66406C1.91602 5.07828 2.2518 5.41406 2.66602 5.41406H10.666C11.0802 5.41406 11.416 5.07828 11.416 4.66406C11.416 4.24985 11.0802 3.91406 10.666 3.91406H2.66602ZM2.66602 7.2474C2.2518 7.2474 1.91602 7.58318 1.91602 7.9974C1.91602 8.41161 2.2518 8.7474 2.66602 8.7474H8.66602C9.08023 8.7474 9.41602 8.41161 9.41602 7.9974C9.41602 7.58318 9.08023 7.2474 8.66602 7.2474H2.66602ZM2.66602 10.5807C2.2518 10.5807 1.91602 10.9165 1.91602 11.3307C1.91602 11.7449 2.2518 12.0807 2.66602 12.0807H6.66602C7.08023 12.0807 7.41602 11.7449 7.41602 11.3307C7.41602 10.9165 7.08023 10.5807 6.66602 10.5807H2.66602ZM11.916 9.1409V12.6636C11.916 13.0778 12.2518 13.4136 12.666 13.4136C13.0802 13.4136 13.416 13.0778 13.416 12.6636V9.1409L14.1357 9.86057C14.4286 10.1535 14.9035 10.1535 15.1963 9.86057C15.4892 9.56768 15.4892 9.0928 15.1963 8.79991L13.1963 6.79991C12.9035 6.50702 12.4286 6.50702 12.1357 6.79991L10.1357 8.79991C9.84279 9.0928 9.84279 9.56768 10.1357 9.86057C10.4286 10.1535 10.9035 10.1535 11.1963 9.86057L11.916 9.1409Z"
        fill={props.fill ? props.fill : '#1F2023'}
      />
    </svg>
  );
};
