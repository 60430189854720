import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import weekend from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjsPlugin from './dayjsPlugin';

import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import { has } from 'lodash';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(weekend);
dayjs.extend(weekOfYear);
dayjs.extend(dayjsPlugin);

dayjs.locale('ko');
dayjs.updateLocale('ko', { formats: { LL: 'YYYY-MM-DD', LLL: 'YYYY-MM-DD HH:mm' } });
dayjs.tz.setDefault('Asia/Seoul');

const setDefaultTimeZone = (zone: string) => {
  dayjs.tz.setDefault(zone);
};

const setDefaultLocale = (locale: string) => {
  dayjs.locale(locale);
};

const tzDayjs = (...args: any) => {
  const offset = dayjs().utcOffset();
  if (offset === 0) {
    return dayjs(...args);
  }

  if (args[1] && has(args[1], 'utc')) {
    return dayjs.tz(dayjs(...args, { utc: args[1]?.utc }));
  }

  if (args[1]) {
    return dayjs.tz(dayjs(...args));
  }

  return dayjs.tz(...args) as dayjs.Dayjs;
};

tzDayjs.isDayjs = dayjs.isDayjs;

export { setDefaultLocale, setDefaultTimeZone };
export type { Dayjs } from 'dayjs';
export default tzDayjs;
