import styled from '@emotion/styled';
import { Button, Dialog } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { userAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { getUserMeV1UsersMeGet } from 'queries';
import { OutUserDetail } from 'queries/model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';

interface ConnectCalendarDialogProps {
  open: boolean;
  onClose?: () => void;
}

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const handleLoginGoogle = useGoogleLogin(options);
  return (
    <Button
      variant="contained"
      onClick={() => handleLoginGoogle()}
      sx={{
        width: '200px',
        height: '48px',
        borderRadius: '8px',
        boxShadow: 'none',
        color: COLORS.white,
        fontSize: '13px',
        fontWeight: 700,
        padding: '14px 16px',
        marginLeft: '16px',
        textTransform: 'none',
      }}
    >
      <Icons.GoogleCalendar style={{ marginRight: '4px' }} />
      {label}
    </Button>
  );
};

const ConnectCalendarDialog = ({ open, onClose }: ConnectCalendarDialogProps) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState<OutUserDetail>();
  const [user] = useAtom(userAtom);
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google/calendar`,
    state: userData?.id,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setUserData(user ? user : await getUserMeV1UsersMeGet());
    };

    fetchUserData();
  }, []);

  const handleClickLater = () => {
    onClose && onClose();
  };

  return (
    <Dialog open={open}>
      <Container>
        <ConnectContentWrapper>
          <ConnectTitleWrapper>
            <ConnectTitle>{t('calendar.connect.header')}</ConnectTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: COLORS.sub3,
                  borderRadius: '8px',
                  marginRight: '8px',
                  padding: '4px',
                }}
              >
                <Icons.TutorialReplay />
              </div>
              <ConnectSubTitle>{t('calendar.connect.description')}</ConnectSubTitle>
            </div>
          </ConnectTitleWrapper>
          <img
            src={require(`assets/images/connectCalendar.png`)}
            alt="google-calendar"
            style={{
              width: '427px',
              height: '236px',
              margin: '20px 0px ',
            }}
          />
          <ButtonWrapper>
            <Button
              variant="contained"
              onClick={handleClickLater}
              sx={{
                'width': '200px',
                'height': '48px',
                'backgroundColor': COLORS.gray100,
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': COLORS.gray900,
                'fontSize': '13px',
                'fontWeight': 700,
                'padding': '14px 16px',
                'textTransform': 'none',
                '&:hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              {t('calendar.connect.later')}
            </Button>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogInButton options={googleLoginOptions} label={t('calendar.connect.complete')} />
            </GoogleOAuthProvider>
          </ButtonWrapper>
        </ConnectContentWrapper>
      </Container>
    </Dialog>
  );
};

export default ConnectCalendarDialog;

const Container = styled.div``;

const ConnectContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 24px;
`;

const ConnectTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConnectTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.25px;
  margin-bottom: 2px;
`;

const ConnectSubTitle = styled.div`
  color: ${COLORS.gray700};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
