import { SVGProps } from 'react';

export const TaskGroupCheckbox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19782_144692)">
        <path d="M3 15H12C13.6569 15 15 13.6569 15 12V3" stroke="#87C8F5" strokeWidth="2" strokeLinecap="round" />
        <path d="M0 2C0 0.895431 0.895431 0 2 0H11C12.1046 0 13 0.895431 13 2V11C13 12.1046 12.1046 13 11 13H2C0.895431 13 0 12.1046 0 11V2Z" fill="#87C8F5" />
        <path d="M2.78516 6.78237L5.26135 9.10379L10.2137 4.46094" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_19782_144692">
          <rect width="16" height="16" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
