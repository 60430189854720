import styled from '@emotion/styled';
import { removeTokens } from 'utils/token';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface ProfileListProps {
  workspace: string;
  name: string;
}

export const ProfileList = ({ workspace, name }: ProfileListProps) => {
  return (
    <Wrapper>
      <Container>
        <Profile />
        <Fonts.H3>{workspace}</Fonts.H3>
      </Container>
      <Container style={{ borderBottom: `1px solid ${COLORS.gray200} ` }}>
        <Fonts.Body1>워크스페이스 설정</Fonts.Body1>
      </Container>
      <Container>
        <Profile />
        <Fonts.H3>{name}</Fonts.H3>
      </Container>
      <Container style={{ borderBottom: `1px solid ${COLORS.gray200} ` }}>
        <Fonts.Body1>개인 정보 설정</Fonts.Body1>
      </Container>
      <Container>
        <Fonts.Body1>사용자 관리</Fonts.Body1>
      </Container>
      <Container
        onClick={() => {
          removeTokens();
          window.location.href = '/signIn';
        }}
      >
        <Fonts.Body1>로그아웃</Fonts.Body1>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 10;
  width: 300px;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  box-shadow: 0px 8px 16px 0px #1a1e2729;
  background-color: ${COLORS.white};
  box-sizing: border-box;
`;

const Container = styled.div`
  width: 300px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
  color: ${COLORS.gray900};
  cursor: pointer;
`;

const Profile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${COLORS.gray400};
  margin-right: 8px;
`;
