import { useEffect, useRef, useState } from 'react';

import { Icons } from '../../components';
import Fonts from '../../components/Fonts';
import { OutTaskboxDetailResponse, OutWork } from '../../queries/model';
import { COLORS } from '../../styles/constants';
import { useSwipeable } from 'react-swipeable';
import dayjs from 'lib/dayjs';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import { DATE_FORMAT_4, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { groupBy } from 'lodash';
import { readTaskboxesV1TaskboxesGet } from 'queries';
import { osName } from 'react-device-detect';
import { isMobile } from 'react-device-detect';

interface Props {
  hidden?: boolean;
  taskboxes?: OutTaskboxDetailResponse[];
  events?: CustomEvent[];
  selectId?: string;
  multiSelectedIds?: string[];
  currentDate: Date;
  onChangeCurrentDate?: (date: Date) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onChangeDone?: (id: string, done: boolean) => void;
  onClickMultiSelect?: (id: string) => void;
  onRemoveMultiSelect?: () => void;
}

export const Inbox = ({
  hidden,
  selectId,
  currentDate,
  taskboxes = [],
  events = [],
  multiSelectedIds = [],
  onClickTaskbox = () => {},
  onChangeCurrentDate,
  onChangeDone,
  onClickMultiSelect,
  onRemoveMultiSelect,
}: Props) => {
  const [weekTasks, setWeekTasks] = useState<{ date: Date; taskboxes: OutTaskboxDetailResponse[] }[]>([]);
  const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    fetchWeekTasks(currentDate);
  }, [currentDate]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleClickCalendarPopoverItem = (value: Date | null) => {
    if (!value) return;
    setIsVisibleCalendarPopover(false);
    onChangeCurrentDate && onChangeCurrentDate(value);
  };

  const handleChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
  };

  const fetchWeekTasks = async (date: Date) => {
    const start = dayjs(date).startOf('week').format(DATE_FORMAT_4);
    const end = dayjs(date).endOf('week').add(1, 'day').format(DATE_FORMAT_4);
    const data = await readTaskboxesV1TaskboxesGet({ start_date: start, end_date: end });
    const taskboxes = data.map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_4) }));
    const groups = groupBy(taskboxes, 'date');

    setWeekTasks(
      [...Array(7).keys()].map((idx) => ({
        date: dayjs(start).add(idx, 'day').toDate(),
        taskboxes: groups[dayjs(start).add(idx, 'day').format(DATE_FORMAT_4)] || [],
      })),
    );
  };

  const showingTime = (event?: OutTaskboxDetailResponse, duration?: number) => {
    // 시간과 기간이 모두 제공되지 않으면 기본 메시지를 반환
    if (!event && duration === undefined) {
      return '시간 설정';
    }

    // 이벤트 객체가 제공된 경우
    if (event?.start?.datetime && event.end?.datetime) {
      const start = dayjs(event.start.datetime);
      const end = dayjs(event.end.datetime);
      duration = end.diff(start, 'minutes'); // 기간 계산
    }

    // 기간이 제공된 경우
    if (duration !== undefined) {
      const hour = Math.floor(duration / 60);
      const minute = duration % 60;

      const durationTime = hour === 0 ? `${minute}분` : minute === 0 ? `${hour}시간 동안` : `${hour}시간 ${minute}분 동안`;

      // 이벤트 객체가 제공된 경우 시간 포맷과 결합하여 반환
      if (event?.start?.datetime) {
        const timeboxTime = `${dayjs(event.start.datetime).format(TIME_FORMAT_2)}, ${durationTime}`;
        return timeboxTime;
      }

      // 이벤트 객체가 제공되지 않은 경우 기간만 반환
      return durationTime;
    }

    return '시간 설정';
  };

  const handleChangeDone = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const done = e.currentTarget.checked;
    onChangeDone && onChangeDone(id, done);
  };

  const handleClickTaskbox = (taskbox: OutTaskboxDetailResponse) => {
    if (finishMultiSelect) setFinishMultiSelect(false);
    if (multiSelectedIds.length > 0 || finishMultiSelect) return;
    onClickTaskbox && onClickTaskbox(taskbox);
  };

  const handleRemoveMultiSelectIds = () => {
    onRemoveMultiSelect && onRemoveMultiSelect();
  };

  const [finishMultiSelect, setFinishMultiSelect] = useState(false);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>, id: string) => {
    multiSelectedIds.length === 1 && multiSelectedIds.includes(id) && setFinishMultiSelect(true);
    if (multiSelectedIds.length > 0) {
      onClickMultiSelect && onClickMultiSelect(id);
    } else {
      timeoutRef.current = setTimeout(() => {
        onClickMultiSelect && onClickMultiSelect(id);
      }, 800);
    }
  };

  const handleTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  return (
    <Container hidden={hidden}>
      <CalendarViewControlWrapper>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {multiSelectedIds.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: 600 }}>
              <IconButton onClick={handleRemoveMultiSelectIds} sx={{ marginRight: '8px', padding: '0px' }}>
                <Icons.ArrowBack width={24} height={24} stroke={COLORS.gray600} />
              </IconButton>
              <div>{`${multiSelectedIds.length} 선택`}</div>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CalendarPopover
                isOpen={isVisibleCalendarPopover}
                defaultValue={currentDate}
                onClickOutside={() => setIsVisibleCalendarPopover(false)}
                onClickItem={handleClickCalendarPopoverItem}
              >
                <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
                  <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                    <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                      {dayjs(currentDate).format('M월')}
                    </Typography>
                  </ArrowToggleButton>
                </span>
              </CalendarPopover>
            </div>
          )}

          <div style={{ marginLeft: 12, position: 'relative' }}>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>이전 일지 보기</span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    ←
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton
                aria-label="previous day"
                onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(1, 'day').toDate())}
                sx={{ padding: '4px' }}
              >
                <Icons.ArrowLeftSmall />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>오늘로 이동</span>
                  <KeyboardButtonRect small style={{ padding: '0px 3px', marginLeft: 8 }}>
                    Shift
                  </KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect small style={{ padding: '0px 3px' }}>
                    T
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <Button
                sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
                size="small"
                style={{ minWidth: '48px', marginLeft: 2, marginRight: 2 }}
                onClick={() => handleChangeCurrentDate(new Date())}
              >
                <b>오늘</b>
              </Button>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>다음 일지 보기</span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    →
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton aria-label="next day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(1, 'day').toDate())} sx={{ padding: '4px' }}>
                <Icons.ArrowRightSmall />
              </IconButton>
            </Tooltip>
          </div>
        </Stack>
        <div style={{ marginTop: 4 }}>
          <WeekTaskStatus currentDate={currentDate} weekTasks={weekTasks} onClick={(date) => handleChangeCurrentDate(date)} />
        </div>
      </CalendarViewControlWrapper>
      <TaskboxViewWrapper osName={osName} isMultiSelect={multiSelectedIds.length > 0} isMobile={isMobile}>
        {taskboxes!.map((taskbox) => (
          <TaskboxWrapper
            key={taskbox.id}
            select={selectId === taskbox.id}
            isRecurrence={taskbox.isRecurrence}
            isHighlight={taskbox.focus}
            isInstance={!!taskbox.project}
            multiSelect={multiSelectedIds.includes(taskbox.id!)}
            onClick={() => handleClickTaskbox(taskbox)}
            onTouchStart={(e) => handleTouchStart(e, taskbox.id!)}
            onTouchEnd={handleTouchEnd}
          >
            <div className="task-content" style={{ display: 'flex', minHeight: 24 }}>
              <Checkbox
                icon={
                  taskbox?.focus ? (
                    <Icons.FocusUncheck width={24} height={24} />
                  ) : (
                    <Icons.TaskboxUncheck
                      circle={taskbox?.project ? COLORS.issue2 : taskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                      marker={taskbox?.project ? '#F3DAFE' : taskbox?.isRecurrence ? '#E9F9FA' : '#E2ECFF'}
                    />
                  )
                }
                checkedIcon={
                  taskbox?.focus ? (
                    <Icons.FocusCheck width={24} height={24} opacity={0.5} />
                  ) : (
                    <Icons.TaskboxCheck fill={taskbox?.project ? COLORS.issue2 : taskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1} opacity={0.5} />
                  )
                }
                checked={!!taskbox?.done}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleChangeDone(e, taskbox.id!)}
                sx={{ width: 24, height: 24, padding: 0, marginRight: '8px' }}
              />
              <div className="task-content">
                <Fonts.Body1 className="task-content" style={{ marginTop: 1 }}>
                  {taskbox.title}
                </Fonts.Body1>
                {(taskbox?.start?.datetime || taskbox?.durationMin) && (
                  <div
                    style={{
                      color: COLORS.gray500,
                      fontSize: 12,
                      marginTop: 6,
                    }}
                  >
                    {taskbox.durationMin ? showingTime(undefined, taskbox.durationMin) : showingTime(taskbox)}
                  </div>
                )}
              </div>
              {taskbox.deadline && selectId === taskbox.id && (
                <TaskDueDate>
                  <Icons.Flag />
                  <Fonts.Blockquote className="task-duedate">{dayjs(taskbox.deadline).format('MM월 DD일')}</Fonts.Blockquote>
                </TaskDueDate>
              )}
            </div>
          </TaskboxWrapper>
        ))}
      </TaskboxViewWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 20px 20px 0px 20px;
`;

const TaskHeader = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

const TaskboxViewWrapper = styled.div<{ osName?: string; isMultiSelect?: boolean; isMobile?: boolean }>`
  height: calc(100vh - 240px);
  overflow-y: scroll;
  margin-top: 20px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  ${(props) =>
    props.osName === 'Android' &&
    props.isMobile &&
    css`
      height: calc(100vh - 280px);
    `}

  ${(props) =>
    props.osName === 'Android' &&
    props.isMobile &&
    props.isMultiSelect &&
    css`
      height: calc(100vh - 324px);
    `}

  ${(props) =>
    props.osName === 'iOS' &&
    props.isMobile &&
    css`
      height: calc(100vh - 330px);
    `}

    ${(props) =>
    props.osName === 'iOS' &&
    props.isMobile &&
    props.isMultiSelect &&
    css`
      height: calc(100vh - 374px);
    `}
`;

const TaskboxWrapper = styled.div<{ select?: boolean; multiSelect?: boolean; isRecurrence?: boolean; isInstance?: boolean; isHighlight?: boolean }>`
  display: flex;
  border: 1px solid ${COLORS.gray200};
  border-radius: 9px;
  padding: 8px 0px 8px 16px;
  min-height: 44px;
  /* &:not(:last-child) {
    margin-bottom: 12px;
  } */
  margin-bottom: 12px;
  ${(props) =>
    props.select
      ? props.isHighlight
        ? css`
            border: 1px solid transparent;
            background-image: linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
            border-radius: 8px;
            background-origin: border-box;
            background-clip: padding-box, border-box;
          `
        : props.isRecurrence
        ? css`
            border: 1px solid ${COLORS.sub4};
          `
        : props.isInstance
        ? css`
            border: 1px solid ${COLORS.issue2};
          `
        : css`
            border: 1px solid ${COLORS.brand1};
          `
      : ''}

  ${(props) =>
    props.multiSelect &&
    css`
      background-color: ${COLORS.gray100};
    `}

  .task-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      outline: none;
    }
  }

  .task-duedate {
    color: ${COLORS.gray600};
    margin-left: 8px;
  }
`;

const TaskDueDate = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${COLORS.gray100};
  border-radius: 9px;
  padding: 4px 9px;
  margin-top: 5px;
`;

const CalendarViewControlWrapper = styled.div`
  height: 100px;

  .MuiSelect-icon {
    right: 1px;
  }

  .MuiSelect-select {
    font-size: 12px;
    padding: 0px 0px 0px 9px !important;
  }

  .MuiSelect-select span:nth-of-type(2) {
    display: none !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.gray900};
    font-weight: 700;
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: fit-content;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

const WeekTaskStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DayWrapper = styled.div<{ bgColor?: string; borderColor?: string }>`
  display: flex;
  width: 42px;
  height: 42px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${(props) => props.bgColor || 'inherit'};
  color: ${(props) => (props.bgColor === COLORS.brand1 ? 'white' : COLORS.gray900)};
  border-radius: 50%;
  cursor: pointer;

  ${(props) =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};
`;

const DayTaskStatusDot = styled.div<{ bgColor?: string }>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || 'inherit'};
`;

interface WeekTaskStatusProps {
  currentDate: Date;
  weekTasks?: { date: Date; taskboxes: OutTaskboxDetailResponse[] }[];
  onClick?: (date: Date) => void;
}

const WeekTaskStatus = ({ currentDate, weekTasks = [], onClick }: WeekTaskStatusProps) => {
  return (
    <WeekTaskStatusContainer>
      {weekTasks.map((item, idx) => {
        const { date, taskboxes } = item;
        const isDone = taskboxes.length > 0 && taskboxes.every((task) => task.done);
        const dayColor = dayjs(date).isToday() ? COLORS.brand1 : dayjs(date).isSame(currentDate, 'day') ? COLORS.sub3 : taskboxes?.length ? 'white' : '';
        const statusColor = isDone ? COLORS.sub4 : dayjs().isAfter(date, 'day') ? COLORS.negative1 : COLORS.brand1;

        return (
          <div key={idx} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: 14, color: COLORS.gray500, marginBottom: 2 }}>{dayjs(date).format('ddd')}</div>
            <DayWrapper
              bgColor={dayColor}
              borderColor={dayjs(date).isSame(currentDate, 'day') ? COLORS.brand1 : taskboxes?.length ? COLORS.gray200 : ''}
              onClick={() => onClick?.(dayjs(date).toDate())}
            >
              {dayjs(date).format('D')}
            </DayWrapper>
            <DayTaskStatusDot bgColor={taskboxes.length ? statusColor : 'transparent'} style={{ marginTop: 4 }} />
          </div>
        );
      })}
    </WeekTaskStatusContainer>
  );
};
