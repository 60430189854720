import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import {
  Button,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Popover,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  linearProgressClasses,
} from '@mui/material';
import dayjs from 'lib/dayjs';
import WeekCalendarEvent, { WeekCalendarEventProps } from './WeekCalendarEvent';
import { DATE_FORMAT_1, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import { useEventListener, useMountEffect, useRafCallback, useUpdateEffect } from '@react-hookz/web';
import { OutCategory, OutFeatureStorage, OutProject, OutTaskboxDetailResponse, OutTaskboxDetailResponseCategory } from 'queries/model';
import { useNavigate } from 'react-router-dom';
import { CalendarEventsFilter } from '../Month';
import { GAEventTrigger } from 'lib/gtag';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ritualAlarmAtom, ritualEntryAtom } from 'atoms/notification';
import { OnDragStartArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { dragContextAtom } from 'atoms/calendar';
import { CategoryActionType } from '../components/CategoryPopover';
import { DATE_FORMAT } from 'constants/dateFormat';
import { totalTimeAtom } from 'atoms/timer';
import { languageAtom } from 'atoms/language';
import { ritualAlarmTimeAtom } from 'atoms/timer';
import { createEventInfoCloseAtom } from 'atoms/createEventInfoClose';
import CreateEventOptionInfo from 'components/CreateEventInfo';
import { isOpenConnectCalendarDialogAtom } from 'atoms/dialog';
import { calendarListAtom } from 'atoms/calendarList';

const CalendarViewWrapper = styled.div<{ routineView?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 24px 28px 0px 28px;
  background-color: #f2f5fc;
  position: relative;
  ${(props) => props.routineView && 'padding: 26px 25px 0px 80px;'}
`;

const CalendarViewControlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .MuiSelect-icon {
    right: 1px;
  }

  .MuiSelect-select {
    font-size: 12px;
    padding: 0px 0px 0px 9px !important;
  }

  .MuiSelect-select span:nth-of-type(2) {
    display: none !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.gray900};
    font-weight: 700;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.gray900};
    font-weight: 700;
  }
`;

const CalendarReadonlyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 12px;
  border-radius: 8px;
  background-color: ${COLORS.gray800};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  color: ${COLORS.white};
  font-size: 12px;
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};

  .rbc-time-header {
    border-bottom: 2px solid ${COLORS.gray300};
    margin-right: 0px !important;
  }

  .rbc-time-header > .rbc-time-header-gutter {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .rbc-time-header-cell {
    /* margin-bottom: 8px; */
    display: none;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-header {
    color: ${COLORS.gray900};
    font-size: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rbc-today {
      background-color: transparent;
      color: ${COLORS.brand1};
    }

    & > button {
      cursor: default;
    }

    /* :nth-of-type(1),
    :nth-last-of-type(1) {
      color: ${COLORS.gray500};
    } */
  }

  .rbc-today {
    background-color: rgba(226, 236, 255, 0.45);
  }

  .rbc-allday-cell {
    height: 100px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    border-top: 1px solid ${COLORS.gray300};
  }

  .rbc-day-slot {
    border-right: 1px solid ${COLORS.gray200};
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray200};
    }
    &:nth-of-type(3n) {
      border-top: 1px dashed ${COLORS.gray200};
    }
  }

  .rbc-day-bg.rbc-selected-cell {
    /* background-color: #f2f5fc !important; */
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    & > * + * > * {
      border: none;
    }

    & > :nth-of-type(2) {
      border-left: 1px solid ${COLORS.gray200};
    }

    & > :last-child {
      border-right: none;
    }

    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-event {
    background: white !important;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-row-segment {
    padding: 0px 10px 1px 1px !important;
  }

  .rbc-addons-dnd-drag-row {
    .rbc-event-content {
      height: 28px;
    }
  }

  .rbc-event-continues-later {
    .rbc-event-content {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
`;

const AllDayEventContainer = styled.div<{ height?: number }>`
  width: 100%;
  display: flex;
  position: relative;
`;

const ViewMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 24px;
  cursor: pointer;
`;

const CalendarHeaderWrapper = styled.div`
  display: flex;
  height: 88px;
  width: 100%;
  border-right: 1px solid #f2f5fc;
  border-left: 1px solid #f2f5fc;
`;

const CalendarHeaderTimeGutter = styled.div`
  width: 50px;
  min-width: 50px;
`;

const CalendarHeaderDayWrapper = styled.div`
  display: flex;
  width: 100%;
  border-left: 1px solid ${COLORS.gray200};

  > div:last-child {
    border-right: 1px solid transparent;
  }
`;

const WeekTaskDayWrapper = styled.div<{ focused?: boolean }>`
  display: flex;
  flex-basis: 14.2857%;
  max-width: 14.2857%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${COLORS.gray200};
  background-color: ${(props) => (props.focused ? 'rgba(226, 236, 255, 0.45)' : 'inherit')};
`;

const WeekTaskDayTextWrapper = styled.div<{ bgColor?: string }>`
  display: flex;
  width: 38px;
  height: 38px;
  /* padding: 4px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  background-color: ${(props) => props.bgColor || 'inherit'};
  color: ${(props) => (props.bgColor === COLORS.brand1 ? 'white' : COLORS.gray900)};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.bgColor === COLORS.brand1 ? COLORS.brand1 : COLORS.gray200)};
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: fit-content;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

const RitualNotification = styled.div`
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 80px;
  right: -54px;
  z-index: 100;
  background-color: ${COLORS.white};
  border-radius: 8px;
  box-shadow: 0px 10.566px 21.132px 0px rgba(26, 30, 39, 0.16);
  padding: 12px;

  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 12px 0px 12px;
    border-color: ${COLORS.white} transparent;
    display: block;
    width: 0;
    z-index: 100;
    bottom: -8px;
    left: 50%;
    @media screen and (max-width: 650px) {
      left: 8px;
    }
  }
`;

const SnoozeWrapper = styled.div`
  padding: 8px 0px;

  .MuiList-root {
    padding: 0px 8px;
  }

  .MuiMenuItem-root {
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 8px;
    :hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

interface CustomLinearProgressProps {
  value?: number;
  color?: string;
  barColor?: string;
}
const CustomLinearProgress = ({ value = 0, color = COLORS.gray200, barColor = 'transparent' }: CustomLinearProgressProps) => {
  return (
    <LinearProgress
      value={value}
      variant="determinate"
      sx={{
        height: '12px',
        borderRadius: '8px',
        border: '2px solid white',
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: color,
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: barColor,
        },
      }}
    />
  );
};

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
  isRecurrence?: boolean;
  isProject?: boolean;
  focus?: boolean;
  category?: OutTaskboxDetailResponseCategory;
  done?: boolean;
  lockedIn?: boolean;
  durationMin?: number | null;
} & Event;

export interface CalendarViewProps {
  newEventId?: string;
  events?: CustomEvent[];
  selectedEvent?: CustomEvent | null;
  weekTasks?: { date: Date; tasks: OutTaskboxDetailResponse[] }[];
  currentDate: Date;
  calendarFilter?: CalendarEventsFilter;
  meetingFilter?: OutFeatureStorage;
  routineView?: boolean;
  onClickRefresh?: (isRefresh?: boolean) => void;
  onClickToggleView?: () => void;
  onClickCalendarFilter?: (filter: CalendarEventsFilter) => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onUpdateEvent?: ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDeleteEvent?: (eventId: string) => void;
  onClickTimeSlot?: ({
    action,
    bounds,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: 'select' | 'click' | 'doubleClick';
    bounds:
      | {
          x: number;
          y: number;
          top: number;
          bottom: number;
          left: number;
          right: number;
        }
      | undefined;
    box:
      | {
          x: number;
          y: number;
          clientX: number;
          clientY: number;
        }
      | undefined;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onContextMenuEvent?: (eventId: string) => void;
  onClickMeetingFilter?: () => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType) => void;
  onClickAddButton?: () => void;
  onScrollToCurrentTime?: (ele: HTMLElement) => void;
  onChangeOngoingTaskbox?: (eventId: string) => void;
}

const WeekCalendarView = ({
  newEventId,
  events = [],
  selectedEvent,
  weekTasks = [],
  currentDate,
  calendarFilter,
  meetingFilter,
  routineView = false,
  onClickRefresh,
  onClickToggleView,
  onClickCalendarFilter,
  onSelectEvent,
  onUpdateEvent,
  onClickTimeSlot,
  onChangeCurrentDate,
  onDropFromOutside,
  onUpdateEventTitle,
  onContextMenuEvent,
  onClickMeetingFilter,
  onClickCategoryActions,
  onClickAddButton,
  onScrollToCurrentTime,
  onChangeOngoingTaskbox,
}: CalendarViewProps) => {
  const navigate = useNavigate();

  //useRef
  const ref = useRef<HTMLDivElement>(null);
  const refReadonly = useRef<HTMLButtonElement>(null);

  //useState
  const [isVisibleViewMore, setIsVisibleViewMore] = useState(false);
  const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);
  const [ritualSettingEl, setRitualSettingEl] = useState<HTMLElement | null>(null);
  const [snoozeAnchorEl, setSnoozeAnchorEl] = useState<HTMLElement | null>(null);
  const [meetingInfoPopoverAnchor, setMeetingInfoPopover] = useState<HTMLElement | null>(null);
  const [snoozeTime] = useState(JSON.parse(localStorage.getItem('snooze-time') || '[]'));
  const [readonlyAnchorEl, setReadonlyAnchorEl] = useState<HTMLElement | null>(null);
  const [createEventPopoverAnchorEl, setCreateEventPopoverAnchorEl] = useState<HTMLElement | null>(null);

  //useAtom
  const [language] = useAtom(languageAtom);
  const [ritualAlarm, setRitualAlarm] = useAtom(ritualAlarmAtom);
  const [, setRitualEntry] = useAtom(ritualEntryAtom);
  const [, setDragContext] = useAtom(dragContextAtom);
  const [, setRitualAlarmTime] = useAtom(totalTimeAtom);
  const [createEventInfoClose] = useAtom(createEventInfoCloseAtom);
  const calendarList = useAtomValue(calendarListAtom);
  const setOpenConnectCalendarDialog = useSetAtom(isOpenConnectCalendarDialogAtom);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const currentTimeIndicator = ref.current.querySelector('.rbc-current-time-indicator') as HTMLElement;

    if (!currentTimeIndicator) return;
    let rbcEvents: HTMLElement[] = [];
    const updateEvents = () => {
      if (ref.current) {
        rbcEvents = Array.from(ref.current.querySelectorAll('.rbc-event-content')) as HTMLElement[];
      }
    };

    const findAndHandleEvents = () => {
      if (!ref.current) return;

      const validEvents = events.filter((event) => event.type === 'task' && !event.allDay && !event.done);
      const indicatorRect = currentTimeIndicator.getBoundingClientRect();

      const overlappingEvents = rbcEvents.filter((event) => {
        const eventRect = event.getBoundingClientRect();
        return (
          eventRect.left < indicatorRect.right &&
          eventRect.right > indicatorRect.left &&
          eventRect.top < indicatorRect.bottom &&
          eventRect.bottom > indicatorRect.top
        );
      });

      const calendarEvents: (CustomEvent | undefined)[] = overlappingEvents
        .map((v) => {
          const eventId = (v.childNodes[0] as HTMLElement).dataset.id;
          return validEvents.find((event) => event.id === eventId);
        })
        .filter(Boolean) as CustomEvent[];

      return calendarEvents;
    };

    //events가 변경될 때마다 updateEvents, findAndHandleEvents 실행
    updateEvents();
    const calendarEvents = findAndHandleEvents();
    if (calendarEvents) handleSelectOngoingTaskbox(calendarEvents);

    //current time indicator가 변할 때마다 findAndHandleEvents 실행
    const observer = new MutationObserver(() => {
      const calendarEvents = findAndHandleEvents();
      if (calendarEvents) handleSelectOngoingTaskbox(calendarEvents);
    });

    observer.observe(currentTimeIndicator, { attributes: true, attributeFilter: ['style'] });

    return () => observer.disconnect();
  }, [events]);

  const formats = useMemo(
    () => ({
      dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => `${localizer?.format(date, 'DD (ddd)', culture)}`,
      timeGutterFormat: language === 'ko' ? 'a hh시' : 'a hh',
      eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
        `${localizer?.format(range.start, 'a h:mm', culture)}~${localizer?.format(range.end, 'a h:mm', culture)}`,
    }),
    [],
  );
  const draggableAccessor = useCallback((event: CustomEvent) => event.type === 'task', [events]);
  const resizableAccessor = useCallback(
    (event: CustomEvent) => {
      const isTask = event.type === 'task';

      if (!isTask) {
        return !routineView;
      }

      if (event.allDay) {
        return !event.isProject && !event.isRecurrence && !routineView;
      }

      return !routineView;
    },
    [events],
  );

  const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
  const eventPropGetter = useCallback(
    (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
      if (event.type === 'meeting') {
        if (dayjs(start).isSame(dayjs(), 'day') && !event.allDay) {
          return {
            style: {
              border: '2px solid rgba(226, 236, 255, 0.7)',
            },
          };
        }

        if (!event.allDay) {
          return {
            style: {
              border: `2px solid ${COLORS.gray100}`,
            },
          };
        }
      }

      if (event.type === 'task' && event.id === newEventId) {
        return {
          style: {
            background: `rgba(0, 57, 167, 0.3)`,
          },
        };
      }

      // 종일 taskbox를 특정 시간대로 이동시 스타일
      if (event.allDay && isSelected === undefined) {
        return {
          style: { maxHeight: '28px' },
        };
      }

      if (isSelected && event.focus) {
        return {
          style: {
            border: '1px solid transparent',
            backgroundImage: 'linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
          },
        };
      }

      return {
        style: { border: `1px solid ${isSelected ? (event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1) : COLORS.gray200}` },
      };
    },
    [events],
  );

  useMountEffect(() => {
    scrollToCurrentIndicator();
  });

  useEventListener(window, 'mouseup', () => {
    setDragContext(null);
  });

  const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
    onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
  });

  const [handleContextMenu] = useRafCallback((event: CustomEvent) => {
    onContextMenuEvent && onContextMenuEvent(event.id!);
  });

  const [updateCategory] = useRafCallback((category: OutCategory | null, action: CategoryActionType) => {
    onClickCategoryActions && onClickCategoryActions(category, action);
  });

  const scrollToCurrentIndicator = () => {
    setTimeout(() => {
      if (!ref || !ref.current) return;
      const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
      if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
    }, 30);
  };

  const handleClickCalendarPopoverItem = (value: Date | null) => {
    if (!value) return;

    setIsVisibleCalendarPopover(false);
    onChangeCurrentDate && onChangeCurrentDate(value);
  };

  const handleChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
  };

  const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    // if (event.allDay && isAllDay) return; // 종일 > 종일로 이동 불가
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    let endTime = event.allDay && !isAllDay ? dayjs(start).add(event.durationMin ? event.durationMin : 30, 'minute') : dayjs(end); // 종일 이벤트를 특정시간으로 이동시 30분으로 조정

    // endTime이 15분 단위가 아닌 경우 15분으로 올림
    if (dayjs(endTime).get('minute') % 15 !== 0) {
      endTime = dayjs(endTime).set('minutes', Math.round(dayjs(endTime).get('minute') / 15) * 15);
    }

    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(endTime).format(DATE_FORMAT_1),
        isAllDay: Boolean(isAllDay),
      });
  };

  const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(end).format(DATE_FORMAT_1),
        isAllDay: Boolean(isAllDay),
      });
  };

  const handleSelectEvent = (event: CustomEvent) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    onSelectEvent && onSelectEvent(event.id!);
  };

  const handleSelectSlot = (slot: SlotInfo) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    if (calendarFilter === 'MEETING') onClickCalendarFilter && onClickCalendarFilter('ALL');

    const days = dayjs(slot.end).startOf('day').diff(dayjs(slot.start).startOf('day'), 'day');
    if (slot.action === 'select' && days > 1) return;

    const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
    const endTime =
      slot.slots.length === 2
        ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
        : dayjs(slot.end).format(DATE_FORMAT_1);

    onClickTimeSlot &&
      onClickTimeSlot({
        action: slot.action,
        bounds: slot.bounds,
        box: slot.box,
        startTime,
        endTime,
        isAllDay: slot?.slots.length === 1 || days > 1 ? true : false,
      });
  };

  const handleDropFromOutside = (args: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    onDropFromOutside &&
      onDropFromOutside({
        startTime: dayjs(args.start).format(DATE_FORMAT_1),
        endTime: dayjs(args.end).format(DATE_FORMAT_1),
        isAllDay: args.allDay,
      });
  };

  const handleClickViewMore = () => {
    const timeHeader = ref.current?.querySelector('.rbc-allday-cell') as HTMLDivElement;
    timeHeader.style.height = `${isVisibleViewMore ? 100 : 188}px`;
    setIsVisibleViewMore(!isVisibleViewMore);
  };

  const handleEventInput = (event: CustomEvent, title: string) => {
    updateEventTitle(event, title);
  };

  const handleEventCategory = (category: OutCategory | null, action: CategoryActionType) => {
    updateCategory(category, action);
  };

  const handleClickWeekTaskDate = (date: Date) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }
    onChangeCurrentDate && onChangeCurrentDate(date);
    onClickToggleView?.();
  };

  const EventComponentWrapper = useCallback((props: WeekCalendarEventProps) => {
    return (
      <WeekCalendarEvent
        {...props}
        onInput={(event, value) => handleEventInput(event, value)}
        onChangeCategory={(category, action) => handleEventCategory(category, action)}
        onContextMenu={handleContextMenu}
      />
    );
  }, []);

  const handleClickRitual = () => {
    navigate('/ritual');
    setRitualAlarm(false);
    localStorage.setItem('ritual-alarm', 'false');
    setRitualEntry([dayjs().format(DATE_FORMAT_4), true]);
    localStorage.setItem('ritual-entry', JSON.stringify([dayjs().format(DATE_FORMAT_4), true]));
  };

  const handleClickSnoozeMenu = (time: number) => {
    setSnoozeAnchorEl(null);
    setRitualAlarm(false);
    localStorage.setItem('ritual-alarm', 'false');

    if (time === 0) {
      setRitualEntry([dayjs().format(DATE_FORMAT_4), true]);
      localStorage.setItem('ritual-entry', JSON.stringify([dayjs().format(DATE_FORMAT_4), true]));
    } else {
      setRitualAlarmTime(time);
    }
  };

  const handleDragStart = (args: OnDragStartArgs<CustomEvent>) => {
    if (routineView) {
      setReadonlyAnchorEl(refReadonly.current);
      return;
    }

    const { event } = args;
    if (!event) return;

    setDragContext({
      id: event.id!,
      title: `${event.title}`,
      isRecurrence: event.isRecurrence,
      isProject: event.isProject,
      done: event.done,
      lockedIn: event.lockedIn,
      focus: event.focus,
    });
  };

  const scrollToCurrentTime = () => {
    const scrollEle = ref.current?.querySelector('.rbc-time-content') as HTMLElement;
    if (scrollEle) onScrollToCurrentTime && onScrollToCurrentTime(scrollEle);
  };

  const handleClickRefreshButton = () => {
    if (calendarList.length === 0) {
      setOpenConnectCalendarDialog(true);
      return;
    }
    onClickRefresh && onClickRefresh(true);
  };

  const handleClickAddButton = (e: any) => {
    if (calendarList.length === 0) {
      setOpenConnectCalendarDialog(true);
      return;
    }

    scrollToCurrentTime();
    setTimeout(() => {
      onClickAddButton && onClickAddButton();
    }, 300);
  };

  const handleSelectOngoingTaskbox = (events: (CustomEvent | undefined)[]) => {
    if (events.length === 0) {
      onChangeOngoingTaskbox && onChangeOngoingTaskbox('');
      return;
    }

    if (events.length > 1) {
      // 여러 이벤트가 겹칠 경우 시작 시간이 현재 시간과 가장 가까운 이벤트 선택. 시작 시간이 같은 경우 종료 시간이 현재 시간과 가장 가까운 이벤트 선택. 시작 시간과 종료 시간이 모두 같을 경우 첫번째 이벤트 선택
      const currentTime = dayjs();
      const closestEvent = events.reduce((prev, current) => {
        const prevStartDiff = Math.abs(dayjs(prev?.start).diff(currentTime, 'minute'));
        const currentStartDiff = Math.abs(dayjs(current?.start).diff(currentTime, 'minute'));

        const prevEndDiff = Math.abs(dayjs(prev?.end).diff(currentTime, 'minute'));
        const currentEndDiff = Math.abs(dayjs(current?.end).diff(currentTime, 'minute'));

        // 시작 시간이 동일하면 종료 시간 기준으로 가까운 이벤트 선택
        if (prevStartDiff === currentStartDiff) {
          if (prevEndDiff === currentEndDiff) {
            return prev; // 시작/종료 시간이 모두 같으면 기존(prev) 유지
          }
          return prevEndDiff < currentEndDiff ? prev : current; // 종료 시간이 더 가까운 이벤트
        }

        // 시작 시간이 더 가까운 이벤트 선택
        return prevStartDiff < currentStartDiff ? prev : current;
      }, events[0]); // 초기값으로 첫 번째 이벤트 설정

      if (closestEvent) {
        onChangeOngoingTaskbox && onChangeOngoingTaskbox(closestEvent.id!);
      }
    } else {
      if (events[0]) {
        onChangeOngoingTaskbox && onChangeOngoingTaskbox(events[0].id!);
      }
    }
  };

  return (
    <CalendarViewWrapper routineView={routineView}>
      <CalendarViewControlWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarPopover
            isOpen={isVisibleCalendarPopover}
            defaultValue={currentDate}
            onClickOutside={() => setIsVisibleCalendarPopover(false)}
            onClickItem={handleClickCalendarPopoverItem}
          >
            <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
              <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                  {language === 'ko' ? dayjs(currentDate).format('M월') : dayjs(currentDate).format('MMM')}
                  {/* {dayjs(currentDate).format('MMM')} */}
                </Typography>
              </ArrowToggleButton>
            </span>
          </CalendarPopover>
          {!routineView && (
            <Select
              value={
                language === 'ko' ? '주간' : 'Weekly'
                // 'Weekly'
              }
              sx={{ width: language === 'ko' ? '53px' : '68px', height: '30px', fontSize: '12px' }}
              MenuProps={{
                MenuListProps: {
                  style: {
                    padding: '8px',
                  },
                },
                style: { marginTop: '2px', left: language === 'ko' ? '23px' : '14px' },
              }}
            >
              <MenuItem
                value={
                  language === 'ko' ? '일간' : 'Daily'
                  // 'Daily'
                }
                onClick={() => navigate('/task/today')}
                sx={{
                  width: '82px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '6px',
                  fontSize: '12px',
                  padding: '8px',
                }}
              >
                <span>
                  {language === 'ko' ? '일간' : 'Daily'}
                  {/* Daily */}
                </span>
                <KeyboardButtonRect style={{ width: '16px' }}>D</KeyboardButtonRect>
              </MenuItem>
              <MenuItem
                value={
                  language === 'ko' ? '주간' : 'Weekly'
                  // 'Weekly'
                }
                sx={{
                  width: '82px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '6px',
                  fontSize: '12px',
                  padding: '8px',
                }}
              >
                <span>
                  {language === 'ko' ? '주간' : 'Weekly'}
                  {/* Weekly */}
                </span>
                <KeyboardButtonRect style={{ width: '16px' }}>W</KeyboardButtonRect>
              </MenuItem>
              <MenuItem
                value={
                  language === 'ko' ? '월간' : 'Monthly'
                  // 'Monthly'
                }
                onClick={() => navigate('/task/month')}
                sx={{
                  width: '82px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '6px',
                  fontSize: '12px',
                  padding: '8px',
                }}
              >
                <span>
                  {language === 'ko' ? '월간' : 'Monthly'}
                  {/* Monthly */}
                </span>
                <KeyboardButtonRect style={{ width: '16px' }}>M</KeyboardButtonRect>
              </MenuItem>
            </Select>
          )}
          <div style={{ marginLeft: 12 }}>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>
                    {language === 'ko' ? '지난주로 이동' : 'Move to last week'}
                    {/* Move to last week */}
                  </span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    ←
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton
                aria-label="previous day"
                onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(7, 'day').toDate())}
                sx={{ padding: '4px' }}
              >
                <Icons.ArrowLeftSmall />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>
                    {language === 'ko' ? '오늘로 이동' : 'Move to today'}
                    {/* Move to today */}
                  </span>
                  <KeyboardButtonRect small style={{ padding: '0px 3px', marginLeft: 8 }}>
                    Shift
                  </KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect small style={{ padding: '0px 3px' }}>
                    T
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <Button
                sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
                size="small"
                style={{ minWidth: '48px', marginLeft: 2, marginRight: 2, textTransform: 'none' }}
                onClick={() => handleChangeCurrentDate(new Date())}
              >
                <b>
                  {language === 'ko' ? '오늘' : 'Today'}
                  {/* Today */}
                </b>
              </Button>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>
                    {language === 'ko' ? '다음주로 이동' : 'Move to next week'}
                    {/* Move to next week */}
                  </span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    →
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton aria-label="next day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(7, 'day').toDate())} sx={{ padding: '4px' }}>
                <Icons.ArrowRightSmall />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/**루틴 주간 달력*/}
        {routineView && (
          <>
            <Button
              ref={refReadonly}
              onClick={(e) => setReadonlyAnchorEl(e.currentTarget)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: COLORS.white,
                border: `1px solid ${COLORS.gray200}`,
                borderRadius: '8px',
                color: COLORS.gray900,
                padding: '6px 8px',
                textTransform: 'none',
              }}
            >
              <Icons.CalendarInvisibility stroke={COLORS.gray900} />
              <span style={{ fontSize: 13, fontWeight: 700, marginLeft: 4 }}>
                {language === 'ko' ? '보기 전용 모드' : 'Readonly mode'}
                {/* Readonly mode */}
              </span>
            </Button>
            <Popover
              open={Boolean(readonlyAnchorEl)}
              anchorEl={readonlyAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{
                marginTop: '4px',
              }}
            >
              <CalendarReadonlyWrapper>
                {language === 'ko' ? (
                  <span>
                    💡 일정 참고용으로 <span style={{ color: COLORS.alert1 }}>보기</span>만 가능해요
                  </span>
                ) : (
                  <span>
                    💡 This view is <span style={{ color: COLORS.alert1 }}>read-only</span> for reference
                  </span>
                )}
                {/* <span>
                  💡 This view is <span style={{ color: COLORS.alert1 }}>read-only</span> for reference
                </span> */}
                <IconButton
                  onClick={() => setReadonlyAnchorEl(null)}
                  sx={{
                    padding: '0px',
                    marginLeft: '6px',
                  }}
                >
                  <Icons.Close width={14} height={14} stroke={COLORS.white} />
                </IconButton>
              </CalendarReadonlyWrapper>
            </Popover>
            <div style={{ width: '140px' }} />
          </>
        )}
        {!routineView && (
          <ToggleButtonGroup
            color="primary"
            value={calendarFilter}
            exclusive
            onChange={(e, newFilter) => onClickCalendarFilter?.(newFilter)}
            sx={{ height: 32, padding: '3px', backgroundColor: COLORS.gray200, borderRadius: '8px' }}
          >
            <ToggleButton value="ALL" style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px', textTransform: 'none' }}>
              <span>
                {language === 'ko' ? '모두' : 'All'}
                {/* All */}
              </span>
            </ToggleButton>
            <ToggleButton
              value="TASK"
              style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px', textTransform: 'none' }}
            >
              <span>
                {language === 'ko' ? '할 일' : 'Tasks'}
                {/* Tasks */}
              </span>
            </ToggleButton>
            <ToggleButton
              value="MEETING"
              onClick={(e) => setMeetingInfoPopover(e.currentTarget)}
              style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px', textTransform: 'none' }}
            >
              <span>
                {language === 'ko' ? '일정' : 'Events'}
                {/* Events */}
              </span>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </CalendarViewControlWrapper>
      <CalendarViewDaySchedulerWrapper>
        <AllDayEventContainer>
          <ViewMoreButtonWrapper style={{ top: isVisibleViewMore ? 256 : 164 }} onClick={handleClickViewMore}>
            <IconButton size="small" style={{ padding: 2, border: `1px solid ${COLORS.gray200}`, background: COLORS.gray200 }}>
              {isVisibleViewMore ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
            </IconButton>
          </ViewMoreButtonWrapper>
        </AllDayEventContainer>
        <CalendarContainer ref={ref}>
          <CalendarHeaderWrapper>
            <CalendarHeaderTimeGutter />
            <CalendarHeaderDayWrapper>
              {weekTasks.map((item, idx) => {
                const { date, tasks } = item;
                const isDone = tasks.length > 0 && tasks.every((task) => task.done);
                const taskDoneCount = tasks.filter((task) => task.done).length;
                const isToday = dayjs(date).isSame(dayjs(), 'date');
                const statusColor = isDone ? COLORS.positive1 : COLORS.brand1;
                const progress = isDone ? 100 : (tasks.filter((task) => task.done).length / tasks.length) * 100;

                return (
                  <WeekTaskDayWrapper key={idx} focused={isToday}>
                    <div style={{ fontSize: 13, color: COLORS.gray500, marginBottom: 4, marginTop: 2 }}>{dayjs(date).format('ddd')}</div>
                    <WeekTaskDayTextWrapper bgColor={isToday ? COLORS.brand1 : ''} style={{ marginBottom: 4 }} onClick={() => handleClickWeekTaskDate(date)}>
                      {dayjs(date).format('DD')}
                    </WeekTaskDayTextWrapper>
                    {tasks.length > 0 && (
                      <div style={{ width: '80%' }}>
                        <CustomLinearProgress
                          value={progress}
                          color={dayjs().isAfter(date, 'day') ? 'rgba(252, 223, 230, 0.6)' : COLORS.gray200}
                          barColor={taskDoneCount ? statusColor : 'transparent'}
                        />
                      </div>
                    )}
                  </WeekTaskDayWrapper>
                );
              })}
            </CalendarHeaderDayWrapper>
          </CalendarHeaderWrapper>
          <DnDCalendar
            style={{ height: '100%' }}
            date={currentDate}
            getNow={() => dayjs().toDate()}
            selected={selectedEvent}
            formats={formats}
            view="week"
            defaultView="week"
            toolbar={false}
            timeslots={4}
            step={15}
            max={dayjs(currentDate).endOf('day').toDate()}
            events={events}
            // events={events.filter((v) => v.type === 'task' || v.allDay)}
            // backgroundEvents={events.filter((v) => v.type === 'meeting' && !v.allDay)}
            dayLayoutAlgorithm={'overlap'}
            selectable={true}
            // draggableAccessor={draggableAccessor}
            resizableAccessor={resizableAccessor}
            allDayAccessor={allDayAccessor}
            components={{ event: EventComponentWrapper }}
            eventPropGetter={eventPropGetter}
            onDropFromOutside={handleDropFromOutside}
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onDragOver={(event) => scrollToCurrentIndicator()}
            onDragStart={handleDragStart}
            // onDoubleClickEvent={handleDoubleClickEvent}
            onView={() => 'week'}
            onNavigate={() => ({})}
            showAllEvents={true}
            showMultiDayTimes={true}
            culture={language}
          />
        </CalendarContainer>
      </CalendarViewDaySchedulerWrapper>
      <div style={{ position: 'absolute', bottom: 32, right: routineView ? 20 : 116 }}>
        <IconButton
          aria-label="sync"
          sx={{ boxShadow: '0px 8px 16px 0px rgba(26, 30, 39, 0.16)' }}
          style={{ width: '32px', height: '32px', marginLeft: 8, background: 'white' }}
          onClick={handleClickRefreshButton}
        >
          <Icons.Reload width={16} height={16} strokeWidth={1.2} />
        </IconButton>
      </div>
      {!routineView && (
        <div style={{ position: 'absolute', bottom: 24, right: 62 }}>
          <IconButton
            aria-label="sync"
            sx={{ width: '48px', height: '48px', boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
            style={{ background: COLORS.brand1, zIndex: 30 }}
            onClick={(e) => handleClickAddButton(e)}
          >
            <Icons.Plus stroke={COLORS.white} strokeWidth={2.5} />
          </IconButton>
        </div>
      )}
      {!routineView && (
        <div style={{ position: 'absolute', bottom: 24, right: 8 }}>
          {ritualAlarm && (
            <RitualNotification>
              <Icons.OrganizeToday />
              <div>
                <div style={{ fontSize: 12, fontWeight: 700 }}>
                  {
                    language === 'ko' ? '오늘 정리하기' : 'Wrap up today'
                    // 'Wrap up today'
                  }
                </div>
                <div style={{ color: COLORS.gray500, fontSize: 10, fontWeight: 700 }}>
                  {
                    language === 'ko' ? '오늘 하루를 정리해보세요' : 'Wrap up your day'
                    // 'Wrap up your day'
                  }
                </div>
              </div>
              <div>
                <IconButton sx={{ width: 20, height: 20, padding: '2.5px 2px', borderRadius: '6px' }} onClick={(e) => setRitualSettingEl(e.currentTarget)}>
                  <Icons.More />
                </IconButton>
                <IconButton sx={{ width: 20, height: 20, padding: '2.5px 2px', borderRadius: '6px' }} onClick={(e) => setSnoozeAnchorEl(e.currentTarget)}>
                  <Icons.Close width={14} height={14} />
                </IconButton>
              </div>
            </RitualNotification>
          )}
          <Tooltip
            title={
              language === 'ko' ? '오늘 정리하기' : 'Wrap up today'
              // 'Wrap up today'
            }
            disableInteractive
          >
            <IconButton
              onClick={() => {
                handleClickRitual();
                GAEventTrigger({ action: 'ritual_shutdown_init', category: 'ritual_shutdown_init' });
              }}
              sx={{
                'zIndex': 10,
                'width': 48,
                'height': 48,
                'backgroundColor': ritualAlarm ? COLORS.brand1 : COLORS.white,
                'boxShadow': '0px 8px 16px 0px rgba(26, 30, 39, 0.16)',
                ':hover': ritualAlarm ? { backgroundColor: COLORS.sub2 } : { backgroundColor: COLORS.white },
              }}
            >
              <Icons.Ritual stroke={ritualAlarm ? COLORS.white : COLORS.brand1} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {ritualSettingEl && (
        <Popover
          open={Boolean(ritualSettingEl)}
          anchorEl={ritualSettingEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setRitualSettingEl(null)}
        >
          <MenuList sx={{ padding: '12px' }}>
            <MenuItem
              onClick={() => navigate('/settings?section=notification')}
              sx={{
                'borderRadius': '8px',
                'fontSize': 12,
                'padding': '8px',
                ':hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              {language === 'ko' ? '하루 정리하기 설정' : 'Wrap up day settings'}
              {/* Wrap up day settings */}
            </MenuItem>
          </MenuList>
        </Popover>
      )}
      {snoozeAnchorEl && (
        <Popover
          open={Boolean(snoozeAnchorEl)}
          anchorEl={snoozeAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setSnoozeAnchorEl(null)}
        >
          <SnoozeWrapper>
            <div style={{ fontSize: '13px', fontWeight: 700, padding: '2px 16px' }}>
              {language === 'ko' ? '스누즈' : 'Snooze'}
              {/* Snooze */}
            </div>
            <div style={{ fontSize: '10px', padding: '5px 16px' }}>
              {language === 'ko' ? '나중에 알림을 받을 수 있어요' : 'You can get reminders later'}
              {/* You can get reminders later */}
            </div>
            <MenuList>
              <MenuItem onClick={() => handleClickSnoozeMenu(0.5)}>
                {language === 'ko' ? '30분 후' : '30 minutes later'}
                {/* 30 minutes later */}
              </MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(1)}>
                {language === 'ko' ? '1시간 후' : '1 hour later'}
                {/* 1 hour later */}
              </MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(2)}>
                {language === 'ko' ? '2시간 후' : '2 hours later'}
                {/* 2 hours later */}
              </MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(0)}>
                {language === 'ko' ? '오늘은 알리지 않기' : 'Don`t remind me today'}
                {/* {`Don't remind me today`} */}
              </MenuItem>
            </MenuList>
          </SnoozeWrapper>
        </Popover>
      )}
      {meetingInfoPopoverAnchor && meetingFilter?.data.click !== true && (
        <Popover
          open={Boolean(meetingInfoPopoverAnchor)}
          anchorEl={meetingInfoPopoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => {
            setMeetingInfoPopover(null);
            onClickMeetingFilter && onClickMeetingFilter();
          }}
          sx={{ top: '10px' }}
        >
          <div style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center', padding: '12px' }}>
            {language === 'ko' ? '일정 생성·수정 기능은 준비중입니다.' : 'The event creation and modification features are under preparation.'}
            {/* 🛠️ The event creation and modification features are under preparation. */}
            <br />
            {language === 'ko' ? '조금만 기다려주세요!' : 'Please wait a moment!'}
            {/* Please wait a moment! */}
          </div>
        </Popover>
      )}
    </CalendarViewWrapper>
  );
};

export default WeekCalendarView;
