import { SVGProps } from 'react';

interface IssueCheckBoxProps extends SVGProps<SVGSVGElement> {
  circleColor?: string;
  pathColor?: string;
}

export const IssueCheckBox = ({ circleColor = '#0039A7', pathColor = '#E2ECFF', ...props }: IssueCheckBoxProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="9.25" stroke={circleColor} strokeWidth="1.5" />
      <path d="M5 10.625L8.33333 13.75L15 7.5" stroke={pathColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
