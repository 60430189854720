import styled from '@emotion/styled';
import { Button, Dialog, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Fonts from 'components/Fonts';
import { InCreateTaskBoxInRecurrenceTarget, RecurrenceActionType, RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';

export type RecurringType = 'TASK' | 'MEETING';

interface Props {
  open: boolean;
  type?: RecurringType;
  onClose?: () => void;
  onUpdateRecurrence?: (target: InCreateTaskBoxInRecurrenceTarget) => void;
}

export const UpdateRecurrenceDialog = ({ open = false, type = 'TASK', onClose = () => {}, onUpdateRecurrence = () => {} }: Props) => {
  const [value, setValue] = useState<RecurrenceActionType>('this');
  useEffect(() => {
    setValue('this');
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as RecurrenceActionType);
  };

  const handleClose = () => {
    onClose();
  };

  const handleClickUpdate = () => {
    onUpdateRecurrence(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <RemoveRecurrenceWrapper>
        <Fonts.H3>{type === 'TASK' ? '반복 업무 수정' : '반복 일정 수정'}</Fonts.H3>
        <Divider sx={{ margin: '12px 0px', backgroundColor: COLORS.gray200 }} />
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{ marginBottom: '24px' }}
        >
          <FormControlLabel value="this" control={<Radio />} label={type === 'TASK' ? '이 업무' : '이 일정'} />
          <FormControlLabel
            value="this_and_following"
            control={<Radio />}
            label={type === 'TASK' ? '이 업무 및 향후 업무' : '이 일정 및 향후 일정'}
            sx={{ marginTop: '8px', marginBottom: '8px' }}
          />
          <FormControlLabel value="all" control={<Radio />} label={type === 'TASK' ? '모든 업무' : '모든 일정'} />
        </RadioGroup>

        <Button
          disableRipple
          onClick={() => {
            onClose();
          }}
          sx={{
            color: `${COLORS.gray900}`,
            backgroundColor: COLORS.gray100,
            borderRadius: '8px',
            padding: '10px 16px',
            border: 'none',
            width: '130px',
            marginRight: '8px',
          }}
        >
          취소하기
        </Button>
        <Button
          variant="contained"
          onClick={handleClickUpdate}
          sx={{ color: `${COLORS.white}`, backgroundColor: COLORS.brand1, borderRadius: '8px', padding: '10px 16px', width: '130px' }}
        >
          적용하기
        </Button>
      </RemoveRecurrenceWrapper>
    </Dialog>
  );
};

const RemoveRecurrenceWrapper = styled.div`
  padding: 16px;
  .MuiRadio-root {
    padding: 0px;
    margin-right: 8px;
  }
  .MuiFormControlLabel-root {
    font-size: 13px;
    margin-left: 0px;
  }
`;
