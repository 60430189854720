import { ArrowDownSmall, ArrowUpSmall } from 'components/Icons';
import React, { ButtonHTMLAttributes, MouseEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS, FontSize } from 'styles/constants';

const ArrowToggleButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const Button = styled.button<{ color?: string }>`
  display: inline-flex;
  align-items: center;
  font-size: ${FontSize.h5};
  letter-spacing: -0.25px;
  margin-right: 4px;
  color: ${(props) => props.color};
`;

const ArrowIconWrapper = styled.span`
  margin-left: 4px;
`;

export interface ArrowToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  toggle?: boolean;
  isToggle?: boolean;
  active?: boolean;
  children?: ReactNode;
}

const ArrowToggleButton = ({ toggle = true, isToggle = false, active, children, ...props }: ArrowToggleButtonProps) => {
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    setIsToggled(isToggle);
  }, [isToggle]);

  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    setIsToggled(!isToggled);
    props.onClick && props.onClick(e);
    e.preventDefault();
  };

  const activeColor = useMemo(() => (active ? COLORS.brand1 : COLORS.gray900), [active]);

  return (
    <ArrowToggleButtonContainer>
      <Button {...props} onClick={handleClickButton} color={activeColor}>
        {children}
        <ArrowIconWrapper>
          {toggle ? isToggled ? <ArrowUpSmall fill={activeColor} /> : <ArrowDownSmall fill={activeColor} /> : <ArrowDownSmall fill={activeColor} />}
        </ArrowIconWrapper>
      </Button>
    </ArrowToggleButtonContainer>
  );
};

export default React.memo(ArrowToggleButton);
