import React, { FC, useCallback } from 'react';
import { OrderedListExtension } from '@remirror/extension-list';
import { useActive, useCommands } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';
export interface ToggleOrderedListButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const ToggleOrderedListButton: FC<ToggleOrderedListButtonProps> = (props) => {
  const [language] = useAtom(languageAtom);
  const { toggleOrderedList } = useCommands<OrderedListExtension>();
  const onClose = props.onClose;

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (toggleOrderedList.enabled()) {
      toggleOrderedList();
    }
  }, [toggleOrderedList]);

  const active = useActive<OrderedListExtension>().orderedList();
  const enabled = toggleOrderedList.enabled();

  return (
    <CommandButton {...props} commandName={language === 'ko' ? '숫자 리스트' : 'Numbered List'} active={active} enabled={enabled} onSelect={handleSelect} />
  );
};
