import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React from 'react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface InputProps {
  size?: number;
  complete?: boolean;
  disabled?: boolean;
  error?: boolean;
  label: string;
  message?: string;
  value?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = ({ size = 240, complete = false, disabled = false, error = false, label, message, value, onChange, onKeyPress }: InputProps) => {
  return (
    <Wrapper>
      <InputText
        size={size}
        name="input"
        type="text"
        complete={complete}
        disabled={disabled}
        error={error}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={label}
        value={value}
      />
      {error ? <Fonts.Body2 style={{ color: `${COLORS.negative1}`, marginTop: '4px' }}>{message}</Fonts.Body2> : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  border: none;
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
`;

const InputText = styled.input<{
  size: number;
  complete: boolean;
  disabled: boolean;
  error: boolean;
}>`
  width: 100%;
  height: 46px;

  background-color: ${COLORS.white};
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  outline: none !important;
  padding-left: 16px;
  &:focus {
    border: 1px solid ${COLORS.brand1};
    color: ${COLORS.gray900};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.gray100};
      cursor: no-drop;
      color: ${COLORS.gray500};
    `}

  ${(props) =>
    props.complete &&
    css`
      color: ${COLORS.gray900};
    `}
  ${(props) =>
    props.error &&
    css`
      color: ${COLORS.gray900};
      &::placeholder {
        color: ${COLORS.gray900};
      }
      border: 1px solid ${COLORS.negative1};
      &:focus {
        border: 1px solid ${COLORS.negative1};
      }
    `}
`;
