import { SVGProps } from 'react';

export const MoveToToday = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8578 8.00065C10.862 7.93554 10.8641 7.86987 10.8641 7.8037C10.8641 6.11373 9.49331 4.74544 7.80451 4.74544C6.11571 4.74544 4.74495 6.11373 4.74495 7.8037C4.74495 9.49368 6.11571 10.862 7.80451 10.862C7.86891 10.862 7.93284 10.86 7.99626 10.8561V9.51811C7.9333 9.52506 7.86932 9.52863 7.80451 9.52863C6.85114 9.52863 6.07829 8.75635 6.07829 7.8037C6.07829 6.85105 6.85114 6.07878 7.80451 6.07878C8.75787 6.07878 9.53073 6.85105 9.53073 7.8037C9.53073 7.8703 9.52695 7.93602 9.5196 8.00065H10.8578Z"
        fill="#1F2023"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68766 1.33398C8.05585 1.33398 8.35433 1.63246 8.35433 2.00065V3.31315C8.35433 3.68134 8.05585 3.97982 7.68766 3.97982C7.31947 3.97982 7.021 3.68134 7.021 3.31315V2.00065C7.021 1.63246 7.31947 1.33398 7.68766 1.33398ZM3.19476 3.19525C3.45511 2.9349 3.87722 2.9349 4.13757 3.19525L5.0655 4.12318C5.32585 4.38353 5.32585 4.80564 5.0655 5.06599C4.80516 5.32634 4.38305 5.32634 4.1227 5.06599L3.19476 4.13806C2.93441 3.87771 2.93441 3.4556 3.19476 3.19525ZM12.1805 3.19514C12.4409 3.45542 12.441 3.87753 12.1807 4.13794L11.2532 5.06588C10.9929 5.32629 10.5708 5.32639 10.3104 5.0661C10.05 4.80582 10.0499 4.38371 10.3101 4.1233L11.2376 3.19536C11.4979 2.93495 11.92 2.93485 12.1805 3.19514ZM1.3335 7.68815C1.3335 7.31996 1.63197 7.02148 2.00016 7.02148H3.31266C3.68085 7.02148 3.97933 7.31996 3.97933 7.68815C3.97933 8.05634 3.68085 8.35482 3.31266 8.35482H2.00016C1.63197 8.35482 1.3335 8.05634 1.3335 7.68815ZM11.396 7.68815C11.396 7.31996 11.6945 7.02148 12.0627 7.02148H13.3752C13.7434 7.02148 14.0418 7.31996 14.0418 7.68815C14.0418 8.05634 13.7434 8.35482 13.3752 8.35482H12.0627C11.6945 8.35482 11.396 8.05634 11.396 7.68815ZM5.0655 10.3103C5.32585 10.5707 5.32585 10.9928 5.0655 11.2531L4.13757 12.1811C3.87722 12.4414 3.45511 12.4414 3.19476 12.1811C2.93441 11.9207 2.93441 11.4986 3.19476 11.2382L4.1227 10.3103C4.38305 10.05 4.80516 10.05 5.0655 10.3103ZM7.68766 11.3965C8.05585 11.3965 8.35433 11.695 8.35433 12.0632V13.3757C8.35433 13.7438 8.05585 14.0423 7.68766 14.0423C7.31947 14.0423 7.021 13.7438 7.021 13.3757V12.0632C7.021 11.695 7.31947 11.3965 7.68766 11.3965Z"
        fill="#1F2023"
      />
      <path
        d="M15 11.75C15 13.5449 13.5449 15 11.75 15C9.95507 15 8.5 13.5449 8.5 11.75C8.5 9.95507 9.95507 8.5 11.75 8.5C13.5449 8.5 15 9.95507 15 11.75Z"
        fill="#1F2023"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7813 9.91735C11.9375 10.0736 11.9375 10.3268 11.7813 10.483L10.8641 11.4002H13.8984C14.1194 11.4002 14.2984 11.5793 14.2984 11.8002C14.2984 12.0211 14.1194 12.2002 13.8984 12.2002H10.8641L11.7813 13.1174C11.9375 13.2736 11.9375 13.5268 11.7813 13.683C11.6251 13.8392 11.3718 13.8392 11.2156 13.683L9.61577 12.0832C9.45956 11.927 9.45938 11.6736 9.61559 11.5174L11.2156 9.91735C11.3718 9.76114 11.6251 9.76114 11.7813 9.91735Z"
        fill="white"
      />
    </svg>
  );
};
