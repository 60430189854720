import styled from '@emotion/styled';
import React, { useState } from 'react';
import { COLORS } from '../../../styles/constants';
import Fonts from '../../../components/Fonts';
import { ModalLayout } from '../ModaILayout';
import { Selectbox } from '../../TextField';

interface Props {
  title: string;
  label: string;
  modal: boolean;
  name: string;
  date: string;
  handleButton: () => void;
  handleClose: () => void;
}

export const ShareSlack = ({ modal, title, label, name, date, handleButton, handleClose }: Props) => {
  const [focus, setFocus] = useState(false);

  function handleSelectbox() {
    setFocus(true);
    if (focus === true) {
      setFocus(false);
    }
  }

  return (
    <ModalLayout title={title} label={label} multiBtn={true} handleButton={handleButton} handleClose={handleClose}>
      <Meeting>
        <Fonts.H3 style={{ color: `${COLORS.gray700}`, marginBottom: '8px' }}>{name}</Fonts.H3>
        <Fonts.Body2 style={{ color: `${COLORS.gray700}` }}>{date}</Fonts.Body2>
      </Meeting>
      <Channel>
        <Fonts.H3 style={{ color: `${COLORS.gray900}`, marginBottom: '8px' }}>채널로 보내기</Fonts.H3>
        <Selectbox
          contents={[]}
          focus={focus}
          size={452}
          label={'수신자 또는 채널을 선택해주세요'}
          optionLabel={''}
          handleOptClick={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      </Channel>
      <Message>
        <Fonts.H3 style={{ color: `${COLORS.gray900}` }}>추가 메시지</Fonts.H3>
        <TextArea placeholder="Slack에 공유될 때 함께 게시될 메시지를 작성해주세요(선택)" />
      </Message>
    </ModalLayout>
  );
};

const Meeting = styled.div`
  margin-bottom: 24px;
`;
const Channel = styled.div`
  margin-bottom: 24px;
`;

const Message = styled.div``;

const TextArea = styled.textarea`
  width: 452px;
  height: 88px;
  margin-top: 8px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-top: 14px;
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  outline-color: ${COLORS.brand1};
`;
