import { SVGProps } from 'react';

export const ExternalLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.6655 3.33268H5.46549C4.71876 3.33268 4.34511 3.33268 4.0599 3.47801C3.80901 3.60584 3.60519 3.80966 3.47736 4.06055C3.33203 4.34576 3.33203 4.71941 3.33203 5.46615V10.5328C3.33203 11.2795 3.33203 11.6527 3.47736 11.9379C3.60519 12.1888 3.80901 12.393 4.0599 12.5208C4.34483 12.666 4.71803 12.666 5.4633 12.666H10.5341C11.2794 12.666 11.652 12.666 11.937 12.5208C12.1878 12.393 12.3924 12.1886 12.5202 11.9377C12.6654 11.6528 12.6654 11.28 12.6654 10.5347V9.33268M13.332 5.99935V2.66602M13.332 2.66602H9.9987M13.332 2.66602L8.66536 7.33268"
        stroke="#858994"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
