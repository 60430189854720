import { SVGProps } from 'react';

export const Plus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 4V20" stroke={props.stroke || '#1F2023'} strokeWidth={props.strokeWidth || 1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12L4 12" stroke={props.stroke || '#1F2023'} strokeWidth={props.strokeWidth || 1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
