import { SVGProps } from 'react';

export const TaskUngroupCheckbox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_19347_243442)">
        <path d="M3 15H12C13.6569 15 15 13.6569 15 12V3" stroke="#57B2F1" strokeWidth="2" strokeLinecap="round" />
        <path d="M0 2C0 0.895431 0.895431 0 2 0H11C12.1046 0 13 0.895431 13 2V11C13 12.1046 12.1046 13 11 13H2C0.895431 13 0 12.1046 0 11V2Z" fill="#57B2F1" />
        <g clipPath="url(#clip1_19347_243442)">
          <path d="M4.09375 4.09375L8.90625 8.90625" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.90625 4.09375L4.09375 8.90625" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19347_243442">
          <rect width="16" height="16" rx="1" fill="white" />
        </clipPath>
        <clipPath id="clip1_19347_243442">
          <rect width="7" height="7" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};
