import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { StaticDatePicker } from 'components/StaticDatePicker';
import dayjs, { Dayjs } from 'lib/dayjs';
import { OutRecord } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';

export interface CalendarReflection {
  date: string;
  done: boolean;
  color: string;
  backgroundColor: string;
}

export interface ReflectionCalendarProps {
  recordDate?: string;
  records?: OutRecord[];
  monthFontSize?: number;
  monthMarginBottom?: number;
  dateWidth?: number;
  dateFontSize?: number;
  dateMarginBottom?: number;
  dayLabelFontSize?: number;
  dayLabelHeight?: number;
  dayLabelMarginBottom?: number;
  padding?: number;
}

export const ReflectionCalendarReadOnly = ({
  recordDate,
  records = [],
  monthFontSize = 17,
  monthMarginBottom = 24,
  dateWidth = 36,
  dateFontSize = 13,
  dateMarginBottom = 12,
  dayLabelFontSize = 10,
  dayLabelHeight = 18,
  dayLabelMarginBottom = 4,
  padding = 16,
}: ReflectionCalendarProps) => {
  const [calendarReflection, setCalendarReflection] = useState<CalendarReflection[]>([]);
  const color = ['#B7E9EC', '#6FD4DA', '#10B8C2', '#0C939B', '#085C61'];
  const continuous: CalendarReflection[] = [];

  useEffect(() => {
    init();
  }, [records]);

  const init = () => {
    if (!records) return;
    records!.forEach((v, idx) => {
      continuous.push({
        date: v.date,
        done: v ? true : false,
        color: v.comboHit === 5 ? COLORS.white : COLORS.gray900,
        backgroundColor: records.length > 1 ? (v.comboHit === -1 ? COLORS.gray200 : color[v.comboHit - 1]) : color[0],
      });
    });

    setCalendarReflection(continuous);
  };

  return (
    <Container
      dateWidth={dateWidth}
      dateFontSize={dateFontSize}
      dateMarginBottom={dateMarginBottom}
      dayLabelFontSize={dayLabelFontSize}
      dayLabelHeight={dayLabelHeight}
      dayLabelMarginBottom={dayLabelMarginBottom}
      padding={padding}
    >
      <div>
        <DatePickerHeader style={{ width: dateWidth * 7 + 28 + 2 * padding, padding: `${padding}px 0px ${monthMarginBottom}px 0px`, fontSize: monthFontSize }}>
          {dayjs(recordDate).format('M월')}
        </DatePickerHeader>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={dayjs(recordDate)}
          onChange={(value) => {}}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(date, selectedDates, pickersDayProps) => {
            return renderWeekPickerDay(date, pickersDayProps, calendarReflection);
          }}
        />
      </div>
    </Container>
  );
};

const renderWeekPickerDay = (date: Dayjs, pickersDayProps: PickersDayProps<Dayjs>, reflection: CalendarReflection[]) => {
  if (!reflection) return <PickersDay {...pickersDayProps} />;
  const isCalendar = reflection.map((v) => v.date).includes(dayjs(date).format('YYYY-MM-DD'));

  return (
    <PickersDay
      {...pickersDayProps}
      selected={false}
      showDaysOutsideCurrentMonth={true}
      today={false}
      onDaySelect={(value) => {}}
      disabled={pickersDayProps.outsideCurrentMonth}
      sx={
        pickersDayProps.outsideCurrentMonth
          ? {
              opacity: 0,
            }
          : isCalendar
          ? {
              pointerEvents: 'none',
              backgroundColor: reflection.find((v) => v.date === dayjs(date).format('YYYY-MM-DD'))?.backgroundColor,
              color: reflection.find((v) => v.date === dayjs(date).format('YYYY-MM-DD'))?.color,
            }
          : {
              pointerEvents: 'none',
            }
      }
    />
  );
};

const Container = styled.div<{
  dateWidth: number;
  dateFontSize: number;
  dateMarginBottom: number;
  dayLabelHeight: number;
  dayLabelFontSize: number;
  dayLabelMarginBottom: number;
  padding: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .PrivatePickersSlideTransition-root {
    min-height: 0px;
  }

  .MuiDayPicker-monthContainer {
    position: relative;
  }

  .MuiPickerStaticWrapper-content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid ${COLORS.gray200};
    border-right: 1px solid ${COLORS.gray200};
    border-bottom: 1px solid ${COLORS.gray200};
  }

  .MuiCalendarPicker-viewTransitionContainer {
    padding: ${({ padding }) => padding}px;
    padding-top: 0px;
  }

  .MuiPickersCalendarHeader-root {
    display: none;
  }

  .css-1u20033-MuiButtonBase-root-MuiPickersDay-root {
    color: ${COLORS.gray300};
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
  }

  .MuiCalendarPicker-root {
    width: 100%;
  }

  .css-epd502 {
    width: 100%;
  }

  .MuiButtonBase-root {
    width: ${({ dateWidth }) => dateWidth}px;
    height: ${({ dateWidth }) => dateWidth}px;
  }

  .MuiDayPicker-header {
    margin-bottom: 4px;
  }

  .MuiDayPicker-weekDayLabel {
    width: ${({ dateWidth }) => dateWidth}px;
    font-size: 10px;
    height: 18px;
  }

  .MuiDayPicker-weekContainer:not(:last-child) {
    margin: 0px 0px 12px 0px;
  }
`;

const DatePickerHeader = styled.div`
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: end;
  font-weight: 700;
  background-color: ${COLORS.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-left: 1px solid ${COLORS.gray200};
  border-right: 1px solid ${COLORS.gray200};
  border-top: 1px solid ${COLORS.gray200};
`;
