import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

export const Line = ({ ...props }) => {
  return <Wrapper {...props} />;
};

const Wrapper = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.gray200};
`;
