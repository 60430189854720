import React, { SVGProps, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import { setCaretToEnd, setCaretPosition, getCaretPosition } from 'utils';
import { Icons } from 'components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { isEqual } from 'lodash';
import TaskTag from 'components/TaskTag';
import dayjs, { Dayjs } from 'lib/dayjs';
import { Checkbox, CheckboxProps, IconButton, Popover, TextField, Tooltip } from '@mui/material';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { removeTaskV1WorksWorkIdDelete, updateWorkV2V2WorksWorkIdPut } from 'queries';
import { CreateTaskboxTask, OutCategory, UpdateTaskboxTask, UpdateWorkV2 } from 'queries/model';
import { DeadlinePopover } from './DeadlinePopover';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { categoryColors } from 'constants/categoryColor';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TaskGroupBlockInputListContainer = styled.div`
  width: 100%;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const BlockAddArea = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-top: 4px;
`;

const EditableTextFieldWrapper = styled.div`
  height: 28px;
  position: relative;
  word-break: break-all;

  cursor: 'text';

  .task-more-btn {
    opacity: 0;
  }

  :hover {
    .task-more-btn {
      opacity: 1;
    }
    .drag-handle {
      opacity: 0.4;
    }
    .detail {
      opacity: 1;
    }
  }
`;

const EditableTextFieldStyle = styled.div<{ select?: boolean }>`
  height: 28px;
  display: flex;
  align-items: center;
  cursor: text;
  padding: 4px 6px;
  border-radius: 8px;
  border: 1px solid transparent;

  :hover {
    background-color: ${COLORS.gray100};
  }

  ${(props) => props.select && `border: 1px solid ${COLORS.gray200}`}
`;

const DragHandleIcon = styled.span<{ isHover?: boolean }>`
  position: absolute;
  opacity: ${(props) => (props.isHover ? 0.4 : 0)};
  transition: opacity 0.3s ease-in-out;
  left: -16px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=drag_indicator');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const EditableTextField = styled.div`
  width: 100%;
  flex: 1;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
  overflow-wrap: anywhere;
`;

const TruncateTaskTag = styled(TaskTag)`
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CalendarInitButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: 12px;
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

const TaskButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TaskIconWrapper = styled.div`
  cursor: pointer;
  padding: 2px;
  border-radius: 4px;
  opacity: 0;

  &:hover {
    background-color: ${COLORS.gray200};

    .check {
      filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
    }
    .delete {
      filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
    }
  }
`;

const TaskMoreButtonWrapper = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

export type TaskBlock = {
  id: string;
  content?: string;
  dueDate?: string;
  done?: boolean;
  doneAt?: string;
  meetingNoteId?: string;
  meetingNoteTitle?: string;
  category?: OutCategory;
  deadline?: string | null;
};

export interface TaskBlockHandle {
  add: () => void;
}

export interface TaskGroupBlockInputListProps {
  tasks?: TaskBlock[];
  category?: OutCategory[];
  date?: Date | null;
  draggable?: boolean;
  suppressKeyEvent?: boolean;
  suppressModification?: boolean;
  suppressCheckboxToggle?: boolean;
  suppressVisibleTag?: boolean;
  done?: boolean;
  isRecurrence?: boolean;
  isFocus?: boolean;
  onChange?: (tasks: TaskBlock[]) => void;
  onClickCheckbox?: (workId: string) => void;
  onCreateWorkInWorkbox?: (work: CreateTaskboxTask) => void;
  onUpdateWorkInWorkbox?: (workId: string, work: UpdateTaskboxTask) => void;
  onDeleteWorkInWorkbox?: (workId: string) => void;
  onClickCategory?: (category: OutCategory, action: CategoryActionType) => void;
}

const TaskGroupBlockInputList = (props: TaskGroupBlockInputListProps, ref: any) => {
  const {
    tasks = [],
    category = [],
    date,
    draggable = true,
    suppressKeyEvent = false,
    suppressModification = false,
    suppressCheckboxToggle = false,
    suppressVisibleTag = false,
    done = false,
    isRecurrence = false,
    isFocus = false,
    onChange,
    onClickCheckbox,
    onCreateWorkInWorkbox,
    onUpdateWorkInWorkbox,
    onDeleteWorkInWorkbox,
    onClickCategory,
  } = props;
  const [language] = useAtom(languageAtom);
  const [blocks, setBlocks] = useState<TaskBlock[]>([]);
  const [clickedTask, setClickedTask] = useState<TaskBlock | undefined>();
  const [taskCalendarPopover, setTaskCalendarPopover] = useState<HTMLElement | null>(null);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();

  useImperativeHandle(ref, () => ({
    add: () => {
      const block = createBlock();
      insertBlock(block);
      setFocusElement(block.id);
      // onCreateWorkInWorkbox && onCreateWorkInWorkbox({ ...block });
    },
  }));

  useEffect(() => {
    const taskList = tasks.map((item) => ({ ...item, id: item.id }));
    if (isEqual(tasks, blocks)) {
      taskList.forEach((task) => {
        const index = blocks.findIndex((block) => block.id === task.id);
        if (index !== -1) blocks[index] = { ...task, id: task.id, content: task.content || '' };
      });
    } else {
      setBlocks(tasks.map((item) => ({ ...item, id: item.id, content: item.content || '' })));
    }
  }, [tasks]);

  const updateBlocks = (blocks: TaskBlock[] = []) => {
    setBlocks(blocks);
    onChange && onChange(blocks);
  };

  const createBlock = (content?: any): TaskBlock => ({
    id: uuidv4(),
    content: content || '',
    done: false,
  });

  const insertBlock = (block: TaskBlock, index = -1) => {
    if (suppressModification) return;

    if (index === -1) {
      updateBlocks([...blocks, block]);
    } else {
      const newBlocks = blocks.slice(0);
      newBlocks.splice(index, 0, block);
      updateBlocks(newBlocks);
    }
  };

  const deleteBlock = (blockId: string) => {
    if (suppressModification) return;

    const newBlocks = blocks.filter(({ id }) => id !== blockId);
    updateBlocks(newBlocks);
  };

  const swapBlock = (srcBlock: TaskBlock, dstBlock: TaskBlock) => {
    if (!srcBlock) return;
    if (!dstBlock) return;

    const dstBlockIndex = blocks.findIndex((item) => item.id === dstBlock.id);
    if (dstBlockIndex === -1) return;

    const newBlocks = blocks.filter((item) => item.id !== srcBlock.id);
    newBlocks.splice(dstBlockIndex, 0, srcBlock);

    updateBlocks([...newBlocks]);
  };

  const updateBlockData = (id: string, data: { content?: string; dueDate?: string; done?: boolean; category?: OutCategory; deadline?: string | null }) => {
    const blockIndex = blocks.map(({ id }) => id).indexOf(id);
    if (blockIndex === -1) return;
    blocks[blockIndex] = { ...blocks[blockIndex], ...data };
  };

  const setFocusElement = (blockId: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-field-id="${blockId}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickAdd = () => {
    const block = createBlock();
    insertBlock(block);
    setFocusElement(block.id);
    // onCreateWorkInWorkbox && onCreateWorkInWorkbox({ ...block, beforeId: tasks[tasks.length - 1]?.id });
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, blockId: string, index: number) => {
    if (suppressKeyEvent) {
      if (e.key === 'Enter') e.preventDefault();
      return;
    }

    const text = e.currentTarget.textContent || '';

    if (e.key === 'Backspace') {
      const caretPosition = window.getSelection()?.focusOffset;
      if (text && index >= 0 && caretPosition === 0) {
        const prevBlock = blocks[index - 1];
        const block = blocks.find(({ id }) => id === blockId);

        if (!block) return;
        updateBlockData(prevBlock.id, { content: `${prevBlock.content}${block?.content}` });
        deleteBlock(block.id);
        // onUpdateWorkInWorkbox &&
        //   onUpdateWorkInWorkbox(prevBlock.id, {
        //     content: `${prevBlock.content}${block?.content}`,
        //     id: prevBlock.id,
        //   });
        // onDeleteWorkInWorkbox && onDeleteWorkInWorkbox(blockId);
        setTimeout(() => {
          const prevEl = document.querySelector(`[data-field-id="${prevBlock.id}"]`) as HTMLDivElement;
          prevEl && setCaretPosition(prevEl, prevBlock?.content?.length || 0);
          setClickedTask(prevBlock);
        }, 100);

        e.preventDefault();
      } else {
        if (!text) {
          const nextBlock = blocks[index === 0 ? index + 1 : index - 1];
          if (nextBlock) {
            setFocusElement(nextBlock.id);
            setClickedTask(nextBlock);
          }

          const dstBlock = blocks.find(({ id }) => id === blockId);
          if (dstBlock) deleteBlock(dstBlock.id);
          // onDeleteWorkInWorkbox && onDeleteWorkInWorkbox(blockId);
          e.preventDefault();
        }
      }

      return;
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      // const caret = getCaretPosition(e.target);

      // const handleAfterEnter = (e: React.KeyboardEvent<HTMLDivElement>, block: TaskBlock, beforeId: string) => {
      //   e.currentTarget.blur();
      //   setFocusElement(block.id);
      //   setClickedTask(block);
      //   // insertBlock(block);
      //   // onCreateWorkInWorkbox && onCreateWorkInWorkbox({ ...block, beforeId });
      // };

      // if (caret === 0 && text) {
      //   const block = createBlock('');
      //   insertBlock(block, index);
      //   handleAfterEnter(e, block, tasks[index - 1]?.id);
      // } else if (caret === text.length) {
      //   const block = createBlock('');
      //   insertBlock(block, index + 1);
      //   handleAfterEnter(e, block, blockId);
      // } else {
      //   const block = createBlock(text.slice(caret, text.length));
      //   updateBlockData(blockId, { content: text.slice(0, caret) });
      //   insertBlock(block, index + 1);
      //   handleAfterEnter(e, block, blockId);
      // }
      const caret = getCaretPosition(e.target);
      const blockText = text || '';

      const insertNewBlock = (startIndex: number, content: string) => {
        e.currentTarget.blur();
        const block = createBlock(content);
        insertBlock(block, startIndex);
        setFocusElement(block.id);
        setClickedTask(block);
      };

      if (caret === 0 && blockText.length !== 0) {
        insertNewBlock(index, '');
      } else if (caret === blockText.length) {
        insertNewBlock(index + 1, '');
      } else {
        updateBlockData(blockId, { content: blockText.slice(0, caret) });
        insertNewBlock(index + 1, blockText.slice(caret, blockText.length));
      }
      return;
    }

    if (e.key === 'ArrowDown') {
      const blockIndex = index >= blocks.length - 1 ? blocks.length - 1 : index + 1;
      setFocusElement(blocks[blockIndex].id);
      setTimeout(() => {
        setClickedTask(blocks[blockIndex]);
      }, 100);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      const blockIndex = index <= 0 ? 0 : index - 1;
      setFocusElement(blocks[blockIndex].id);
      setTimeout(() => {
        setClickedTask(blocks[blockIndex]);
      }, 100);
      e.preventDefault();
      return;
    }
  };

  const handleChangeBlock = (e: React.FormEvent<HTMLDivElement>, id: string) => {
    updateBlockData(id, { content: `${e.currentTarget.innerText || ''}` });
  };

  const handleKeydownAddButton = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (suppressKeyEvent) return;
    if (e.key !== 'Enter') return;
    if (e.nativeEvent.isComposing) return;
    if (e.repeat) {
      e.preventDefault();
      return;
    }

    const block = createBlock();
    insertBlock(block);
    setFocusElement(block.id);
    // onCreateWorkInWorkbox && onCreateWorkInWorkbox({ ...block });
    e.preventDefault();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const srcBlock = blocks.find((item) => item.id === result.draggableId);
    if (!srcBlock) return;

    const dstBlock = blocks[result.destination.index];
    if (!dstBlock) return;

    swapBlock(srcBlock, dstBlock);
  };

  /** focus out 시 변경된 하위업무 저장 */
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, blockId: string, index: number) => {
    // const text = e.currentTarget.textContent!;
    // const caret = getCaretPosition(e.target);

    // if (text !== blocks[index].content) {
    //   updateBlockData(blockId, { content: text.slice(0, caret) });
    //   onUpdateWorkInWorkbox && onUpdateWorkInWorkbox(blockId, { id: blockId, content: text.slice(0, caret) });
    // }
    onChange && onChange(blocks);
    setClickedTask(undefined);
  };

  const handleCloseTaskCalendarPopover = () => {
    setClickedTask(undefined);
    setTaskCalendarPopover(null);
  };

  const handleClickDueDate = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setTaskCalendarPopover(e.currentTarget);
  };

  const handleChangeDueDate = (value: Date | null) => {
    if (!clickedTask) return;
    const task = clickedTask;
    const dueDate = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null;

    if (task.dueDate !== dueDate) {
      updateBlockData(task.id, { dueDate: dueDate as any });
    }

    setClickedTask(undefined);
    setTaskCalendarPopover(null);
    onChange && onChange(blocks);
  };

  const handleChangeDone = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newBlocks = tasks.map((item) => (item.id === id ? { ...item, done: e.currentTarget.checked } : item));
    onChange && onChange(newBlocks);
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setContextMenuPopover(e.currentTarget);
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    switch (menu) {
      case 'DELETE':
        deleteBlock(id);
        // onDeleteWorkInWorkbox && onDeleteWorkInWorkbox(id);
        // toast.success('태스크를 삭제하였습니다.');
        break;
      case 'COMPLETED_AND_MOVE_TO_TODAY': {
        const block = blocks.find((item) => item.id === id);
        if (!block) return;

        block.done = true;
        await updateWorkV2V2WorksWorkIdPut(id, { done: true });

        // deleteBlock(id);
        // toast.success('완료하고 오늘로 이동하였습니다.');
        break;
      }
    }
    setContextMenuPopover(null);
  };

  const handleClickCheckbox = (e: React.MouseEvent<HTMLDivElement>, blockId: string, index: number) => {
    const text = e.currentTarget.textContent || '';
    const caretPosition = window.getSelection()?.focusOffset;
    if (text && index >= 0 && caretPosition === 0) {
      const prevBlock = blocks[index - 1];
      const block = blocks.find(({ id }) => id === blockId);

      if (!block) return;
      updateBlockData(prevBlock.id, { content: `${prevBlock.content}${block?.content}` });
      deleteBlock(block.id);

      e.preventDefault();
    } else {
      if (!text) {
        const nextBlock = blocks[index === 0 ? index + 1 : index - 1];

        const dstBlock = blocks.find(({ id }) => id === blockId);
        if (dstBlock) deleteBlock(dstBlock.id);

        e.preventDefault();
      }
    }
    onClickCheckbox && onClickCheckbox(blockId);
    return;
  };

  const [hoverdTaskId, setHoverdTaskId] = useState<string | undefined>(undefined);

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.stopPropagation;
    setHoverdTaskId(id);
  };

  const handleMouseLeave = () => {
    setHoverdTaskId(undefined);
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickCategory = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setCategoryAnchorEl(e.currentTarget);
  };

  const handleChangeCategory = (category: OutCategory | undefined, action: CategoryActionType, detachTaskId?: string) => {
    if (action === 'SELECT') {
      if (!category && detachTaskId) {
        updateBlockData(detachTaskId, { category: undefined });
        onChange && onChange(blocks);
      } else {
        if (!clickedTask) return;
        const task = clickedTask;
        const newCategory = category;

        updateBlockData(task.id, { category: newCategory });
        setClickedTask(undefined);
        setCategoryAnchorEl(null);
        onChange && onChange(blocks);
      }
    } else {
      if (action === 'UPDATE') {
        updateBlockData(clickedTask!.id, { category: clickedTask?.category && clickedTask?.category.id === category?.id ? category : clickedTask?.category });
        onChange && onChange(blocks);
      } else if (action === 'DELETE') {
        updateBlockData(clickedTask!.id, { category: undefined });
        setClickedTask(undefined);
        setCategoryAnchorEl(null);
        onChange && onChange(blocks);
      }
      onClickCategory && onClickCategory(category!, action);
    }
  };

  const handleClickDeadline = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setDeadlineAnchorEl(e.currentTarget);
  };

  const handleChangeDeadline = (value: Dayjs | null, detachTaskId?: string) => {
    if (!value && detachTaskId) {
      updateBlockData(detachTaskId, { deadline: null });
      onChange && onChange(blocks);
    } else {
      if (!clickedTask) return;
      const task = clickedTask;
      const deadline = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null;

      if (task.deadline !== deadline) {
        updateBlockData(task.id, { deadline: deadline as any });
      }

      setClickedTask(undefined);
      setDeadlineAnchorEl(null);
      onChange && onChange(blocks);
    }
  };

  return (
    <Container>
      <TaskGroupBlockInputListContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={'task-list'}>
            {(provided) => (
              <div className={'task-list'} {...provided.droppableProps} ref={provided.innerRef}>
                {blocks.map(({ id, content, done: taskDone, dueDate, meetingNoteTitle, category, deadline }, idx) => (
                  <Draggable draggableId={id} index={idx} key={id} isDragDisabled={!draggable}>
                    {(provided) => (
                      <EditableTextFieldWrapper
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style, display: 'flex', flexDirection: 'column' }}
                      >
                        <EditableTextFieldStyle select={clickedTask?.id === id}>
                          {draggable && <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />}
                          <div
                            onMouseEnter={(e) => handleMouseEnter(e, id)}
                            onMouseLeave={handleMouseLeave}
                            onClick={(e) => handleClickCheckbox(e, id, idx)}
                            style={{ cursor: 'pointer' }}
                          >
                            {hoverdTaskId === id ? (
                              <Tooltip
                                title={
                                  language === 'ko' ? '그룹에서 빼기' : 'Subtract'
                                  // 'Subtract'
                                }
                                disableInteractive
                              >
                                <div>
                                  <Icons.RemoveFromGroup />
                                </div>
                              </Tooltip>
                            ) : (
                              <Icons.TaskCheckbox />
                            )}
                          </div>
                          <EditableTextField
                            style={{
                              marginLeft: 8,
                              color: taskDone ? COLORS.gray500 : COLORS.gray900,
                              fontSize: '13px',
                              textDecoration: taskDone ? 'line-through' : 'none',
                            }}
                            tabIndex={0}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            data-field-id={`${id}`}
                            onKeyDown={(e) => handleKeyDown(e, id, idx)}
                            onInput={(e) => handleChangeBlock(e, id)}
                            onBlur={(e) => handleBlur(e, id, idx)}
                            onClick={() => setClickedTask(blocks.find((item) => item.id === id))}
                            dangerouslySetInnerHTML={{ __html: content || '' }}
                          />
                          <TaskButtonWrapper>
                            {category ? (
                              <CategoryShowingWrapper
                                className="task-more-btn"
                                textColor={getCategoryTextColor(category!.color)}
                                bgColor={getCategoryBgColor(category!.color)}
                                onClick={(e) => handleClickCategory(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                style={categoryAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                              >
                                {`# ${category.name}`}
                                <IconButton
                                  className="category-detach-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeCategory(undefined, 'SELECT', id);
                                  }}
                                  sx={{
                                    'width': '12px',
                                    'height': '12px',
                                    'borderRadius': '4px',
                                    'marginLeft': '4px',
                                    'padding': '0px',
                                    ':hover': {
                                      backgroundColor: hexToRGBA(getCategoryTextColor(category!.color)!, 0.3),
                                    },
                                  }}
                                  style={categoryAnchorEl && clickedTask!.id === id ? { display: 'flex' } : {}}
                                >
                                  <Icons.Close width={8} height={8} stroke={getCategoryTextColor(category!.color)} />
                                </IconButton>
                              </CategoryShowingWrapper>
                            ) : (
                              <IconButton
                                className="task-more-btn"
                                onClick={(e) => handleClickCategory(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                sx={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '4px',
                                  padding: '0px',
                                  marginRight: '4px',
                                }}
                                style={categoryAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                              >
                                <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                              </IconButton>
                            )}
                            {deadline ? (
                              <DeadlineShowingWrapper
                                date={deadline}
                                className="task-more-btn"
                                onClick={(e) => handleClickDeadline(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                style={deadlineAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                              >
                                <Icons.Flag
                                  fill={dayjs(deadline).isToday() ? COLORS.brand1 : dayjs(deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                                />
                                <span style={{ marginLeft: '2px' }}>
                                  {dayjs(deadline).isToday()
                                    ? language === 'ko'
                                      ? '오늘'
                                      : 'Today'
                                    : dayjs(deadline).isYesterday()
                                    ? language === 'ko'
                                      ? '어제'
                                      : 'Yesterday'
                                    : dayjs(deadline).isTomorrow()
                                    ? language === 'ko'
                                      ? '내일'
                                      : 'Tomorrow'
                                    : language === 'ko'
                                    ? dayjs(deadline).format('M월 D일 (dd)')
                                    : dayjs(deadline).format('dddd, MM D')}
                                  {/* {dayjs(deadline).isToday()
                                    ? 'Today'
                                    : dayjs(deadline).isYesterday()
                                    ? 'Yesterday'
                                    : dayjs(deadline).isTomorrow()
                                    ? 'Tomorrow'
                                    : dayjs(deadline).format('dddd, MM D')} */}
                                </span>
                                <IconButton
                                  className="deadline-detach-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeDeadline(null, id);
                                  }}
                                  sx={{
                                    'width': '12px',
                                    'height': '12px',
                                    'borderRadius': '4px',
                                    'marginLeft': '4px',
                                    'padding': '0px',
                                    ':hover': {
                                      backgroundColor: hexToRGBA(
                                        dayjs(deadline).isToday() ? COLORS.brand1 : dayjs(deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
                                        0.3,
                                      ),
                                    },
                                  }}
                                  style={deadlineAnchorEl && clickedTask!.id === id ? { display: 'flex' } : {}}
                                >
                                  <Icons.Close
                                    width={8}
                                    height={8}
                                    stroke={dayjs(deadline).isToday() ? COLORS.brand1 : dayjs(deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                                  />
                                </IconButton>
                              </DeadlineShowingWrapper>
                            ) : (
                              <IconButton
                                className="task-more-btn"
                                onClick={(e) => handleClickDeadline(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                sx={{
                                  width: '20px',
                                  height: '20px',
                                  borderRadius: '4px',
                                  padding: '0px',
                                  marginRight: '4px',
                                }}
                                style={deadlineAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                              >
                                <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                              </IconButton>
                            )}
                            <TaskIconWrapper
                              className="detail"
                              aria-label="delete"
                              onClick={() => handleClickContextMenu(id, 'task', 'DELETE')}
                              data-task-id={id}
                            >
                              <div className="delete">
                                <Icons.Delete fill={COLORS.gray400} />
                              </div>
                            </TaskIconWrapper>
                          </TaskButtonWrapper>
                        </EditableTextFieldStyle>
                        {!suppressVisibleTag && (
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2, marginLeft: 24 }}>
                            {meetingNoteTitle && <TruncateTaskTag style={{ marginRight: 8 }}>{meetingNoteTitle}</TruncateTaskTag>}
                            {dueDate && (
                              <TaskTag onClick={(e) => handleClickDueDate(e, blocks[idx])}>
                                <Icons.Flag />
                                {dayjs(dueDate).format('MM월 DD일')}
                              </TaskTag>
                            )}
                          </div>
                        )}
                      </EditableTextFieldWrapper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TaskGroupBlockInputListContainer>
      {!suppressModification && (
        <BlockAddArea onKeyDown={(e) => handleKeydownAddButton(e)}>
          <div onClick={() => handleClickAdd()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <span style={{ minWidth: 16, minHeight: 16, marginRight: 8 }}>
              <Icons.Plus width={16} height={16} fill={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
            </span>
            <span style={{ fontSize: 13, color: done ? COLORS.gray400 : COLORS.gray500 }}>
              {language === 'ko' ? '하위 업무 추가' : 'Add subtask'}
              {/* Add subtask */}
            </span>
          </div>
        </BlockAddArea>
      )}
      {taskCalendarPopover && (
        <Popover
          open={Boolean(taskCalendarPopover)}
          anchorEl={taskCalendarPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskCalendarPopover}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={clickedTask?.dueDate || ''}
            onChange={handleChangeDueDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <CalendarInitButton onClick={() => handleChangeDueDate(null)}>
            {language === 'ko' ? '지정 안함' : 'No selection'}
            {/* No selection */}
          </CalendarInitButton>
        </Popover>
      )}
      {contextMenuPopover && (
        <InboxContextMenuPopover
          id={contextMenuPopover.getAttribute('data-task-id') || ''}
          type={'task'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          menus={['MOVE_TO_AFTER', 'DELETE']}
          onClose={() => setContextMenuPopover(null)}
          onClickMenu={handleClickContextMenu}
        />
      )}{' '}
      {/* 하위업무 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={category} onClickCategoryAction={handleChangeCategory} />
        </Popover>
      )}
      {/* 하위업무 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={date!} onChangeDeadline={handleChangeDeadline} />
        </Popover>
      )}
    </Container>
  );
};

export default forwardRef<TaskBlockHandle, TaskGroupBlockInputListProps>(TaskGroupBlockInputList);
