import { Icons } from '../../components';
import Fonts from '../../components/Fonts';
import { OutTaskboxDetailResponse, OutWork } from '../../queries/model';
import { COLORS } from '../../styles/constants';
import dayjs from 'lib/dayjs';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSwipeable } from 'react-swipeable';
import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile, osName } from 'react-device-detect';

interface Props {
  hidden?: boolean;
  tasks?: OutTaskboxDetailResponse[];
  selectId?: string;
  multiSelectedIds?: string[];
  onClickTask?: (taskbox: OutTaskboxDetailResponse) => void;
  onClickMultiSelect?: (id: string) => void;
  onRemoveMultiSelect?: () => void;
}

export const Later = ({ hidden, tasks, selectId, multiSelectedIds = [], onClickTask = () => {}, onClickMultiSelect, onRemoveMultiSelect }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const [finishMultiSelect, setFinishMultiSelect] = useState(false);

  const handleClickTask = (task: OutTaskboxDetailResponse) => {
    if (finishMultiSelect) setFinishMultiSelect(false);
    if (multiSelectedIds.length > 0 || finishMultiSelect) return;
    onClickTask && onClickTask(task);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>, id: string) => {
    multiSelectedIds.length === 1 && multiSelectedIds.includes(id) && setFinishMultiSelect(true);
    if (multiSelectedIds.length > 0) {
      onClickMultiSelect && onClickMultiSelect(id);
    } else {
      timeoutRef.current = setTimeout(() => {
        e.preventDefault();
        onClickMultiSelect && onClickMultiSelect(id);
      }, 800);
    }
  };

  const handleTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  const handleRemoveMultiSelectIds = () => {
    onRemoveMultiSelect && onRemoveMultiSelect();
  };

  return (
    <Container hidden={hidden}>
      {multiSelectedIds.length > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', fontWeight: 600, marginBottom: '12px' }}>
          <IconButton onClick={handleRemoveMultiSelectIds} sx={{ marginRight: '8px', padding: '0px' }}>
            <Icons.ArrowBack width={24} height={24} stroke={COLORS.gray600} />
          </IconButton>
          <div>{`${multiSelectedIds.length} 선택`}</div>
        </div>
      ) : (
        <TaskHeader>
          <Fonts.H2>태스크</Fonts.H2>
          <Fonts.H2 style={{ color: COLORS.gray400, marginLeft: 4 }}>({tasks!.length})</Fonts.H2>
        </TaskHeader>
      )}
      <TaskViewWrapper osName={osName} isMultiSelect={multiSelectedIds.length > 0} isMobile={isMobile}>
        {tasks!.map((v) => (
          <TaskWrapper
            key={v.id}
            select={selectId === v.id}
            multiSelect={multiSelectedIds.includes(v.id!)}
            onClick={() => handleClickTask(v)}
            onTouchStart={(e) => handleTouchStart(e, v.id!)}
            onTouchEnd={handleTouchEnd}
          >
            {v.tasks && v.tasks.length > 0 ? <Icons.TaskGroupCheckbox width={20} height={20} /> : <Icons.TaskCheckbox width={20} height={20} />}
            <Fonts.Body1
              className="task-content"
              style={{ marginLeft: 8, flex: 1 }}
              // contentEditable={true}
              // suppressContentEditableWarning={true}
            >
              {v.title}
            </Fonts.Body1>
            {v.deadline && selectId === v.id && (
              <TaskDueDate>
                <Icons.Flag />
                <Fonts.Blockquote className="task-duedate">{dayjs(v.deadline).format('MM월 DD일')}</Fonts.Blockquote>
              </TaskDueDate>
            )}
          </TaskWrapper>
        ))}
      </TaskViewWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 20px 20px 0px 20px;
`;

const TaskHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const TaskViewWrapper = styled.div<{ osName?: string; isMultiSelect?: boolean; isMobile?: boolean }>`
  height: calc(100vh - 160px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  ${(props) =>
    props.osName === 'Android' &&
    props.isMobile &&
    css`
      height: calc(100vh - 200px);
    `}

  ${(props) =>
    props.osName === 'Android' &&
    props.isMultiSelect &&
    props.isMobile &&
    css`
      height: calc(100vh - 244px);
    `}

  ${(props) =>
    props.osName === 'iOS' &&
    props.isMobile &&
    css`
      height: calc(100vh - 250px);
    `}

  ${(props) =>
    props.osName === 'iOS' &&
    props.isMobile &&
    props.isMultiSelect &&
    css`
      height: calc(100vh - 294px);
    `}
`;

const TaskWrapper = styled.div<{ select?: boolean; multiSelect?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.gray200};
  border-radius: 9px;
  padding: 8px 0px 8px 16px;
  min-height: 44px;
  /* &:not(:last-child) {
    margin-bottom: 12px;
  } */
  margin-bottom: 12px;
  ${(props) =>
    props.select &&
    css`
      border: 1px solid ${COLORS.gray300};
    `}

  ${(props) =>
    props.multiSelect &&
    css`
      background-color: ${COLORS.gray100};
    `}

  .task-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      outline: none;
    }
  }

  .task-duedate {
    color: ${COLORS.gray600};
    margin-left: 8px;
  }
`;

const TaskDueDate = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${COLORS.gray100};
  border-radius: 9px;
  padding: 4px 9px;
  margin-top: 5px;
`;
