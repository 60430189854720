import { SVGProps } from 'react';

export const DeskIssueCancel = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.5 2.5L7.49996 7.49996" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.5 2.5L2.50004 7.49996" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
