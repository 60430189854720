import styled from '@emotion/styled';
import { Dialog, Radio, RadioGroup, FormControlLabel, TextField, Button } from '@mui/material';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useState } from 'react';
import { COLORS } from 'styles/constants';
import { css } from '@emotion/react';
import { CreateVote } from 'queries/model';
import Fonts from 'components/Fonts';

interface CompleteVoteProps {
  open?: boolean;
  onClose?: () => void;
}

export const CompleteVote = ({ open = true, onClose = () => {} }: CompleteVoteProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <CompleteVoteContainer>
        <div
          style={{
            width: '60px',
            height: '60px',
            fontSize: '32px',
            backgroundColor: COLORS.sub3,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span>👍</span>
        </div>
        <div style={{ margin: '40px 0px 8px 0px', fontSize: '24px', fontWeight: 700 }}>전달되었어요!</div>
        <div style={{ fontSize: '16px' }}>빨리 오픈할 수 있도록 노력할게요🔥</div>
      </CompleteVoteContainer>
    </Dialog>
  );
};

const CompleteVoteContainer = styled.div`
  width: 456px;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
