import { SVGProps } from 'react';

export const StartAfter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M2.75391 2.58594L2.75391 7.2526C2.75391 7.95985 3.03486 8.63813 3.53495 9.13822C4.03505 9.63832 4.71333 9.91927 5.42057 9.91927L8.5 9.91927"
        stroke="#101828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 5.70312L15 9.70313L9 13.7031L9 5.70312Z" stroke="#101828" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
