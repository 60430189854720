import { SVGProps } from 'react';

export const TimerAdd = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 24V19.5M18 19.5V15M18 19.5H13.5M18 19.5H22.5M31.5 9L28.5 6M15 3H21M18 31.5C11.3726 31.5 6 26.1274 6 19.5C6 12.8726 11.3726 7.5 18 7.5C24.6274 7.5 30 12.8726 30 19.5C30 26.1274 24.6274 31.5 18 31.5Z"
        stroke={props.stroke ? props.stroke : '#C8CDDB'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
