import styled from '@emotion/styled';
import { IconButton, ToggleButton, Tooltip } from '@mui/material';
import { useActive, useCommands } from '@remirror/react';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';
import { useActionData } from 'react-router-dom';

const HighlightButtons = () => {
  const [language] = useAtom(languageAtom);
  const commands = useCommands();
  const active = useActive();

  return (
    <Tooltip title={language === 'ko' ? '마커' : 'Marker'} disableInteractive>
      <ToggleButton
        value="highlight"
        onMouseDown={(event) => event.preventDefault()}
        onChange={() => {
          active.textHighlight() ? commands.removeTextHighlight() : commands.setTextHighlight('rgba(245, 235, 111, 0.29)');
        }}
        sx={{
          'height': '30px',
          'backgroundColor': active.textHighlight() ? '#0039A7' : 'transparent',
          'color': active.textHighlight() ? 'white' : 'black',
          'border': '1px solid rgba(0, 0, 0, 0.12)',
          'borderRadius': '4px',
          'padding': '6px 12px',
          'fontSize': '10px',
          ':hover': {
            backgroundColor: active.textHighlight() ? '#2D6ADF' : 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <svg className="highlight-button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M6.66667 12.6693H8M8 12.6693H9.33333M8 12.6693V3.33594M8 3.33594H4V4.0026M8 3.33594H12V4.0026"
            stroke={active.textHighlight() ? '#FFFFFF' : '#7B7B7B'}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect x="12" y="10" width="3.33333" height="3.33333" fill={active.textHighlight() ? '#FFFFFF' : '#7B7B7B'} />
        </svg>
      </ToggleButton>
    </Tooltip>
  );
};

export default HighlightButtons;
