import { SVGProps } from 'react';

export const Ritual = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M9 9H11.6667C13.0812 9 14.4377 8.4381 15.4379 7.4379C16.4381 6.43771 17 5.08115 17 3.66667V2.77778H14.3333C12.9188 2.77778 11.5623 3.33968 10.5621 4.33988C9.5619 5.34007 9 6.69662 9 8.11111H6.33333C4.91885 8.11111 3.56229 7.54921 2.5621 6.54901C1.5619 5.54882 1 4.19227 1 2.77778V1H3.66667C5.08115 1 6.43771 1.5619 7.4379 2.5621C8.4381 3.56229 9 4.91885 9 6.33333V11.6667M4.55556 11.6667H13.4444V15.2222C13.4444 15.6937 13.2571 16.1459 12.9237 16.4793C12.5903 16.8127 12.1382 17 11.6667 17H6.33333C5.86184 17 5.40965 16.8127 5.07625 16.4793C4.74286 16.1459 4.55556 15.6937 4.55556 15.2222V11.6667Z"
      stroke={props.stroke ? props.stroke : '#0039A7'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
