import { SVGProps } from 'react';

export const QuestionInfo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <circle cx="6" cy="6" r="4.5" fill="#ABB0BF" stroke="#ABB0BF" strokeWidth="1.5" />
      <path
        d="M6.375 8.625C6.375 8.83125 6.20625 9 6 9C5.79375 9 5.625 8.83125 5.625 8.625C5.625 8.41875 5.79375 8.25 6 8.25C6.20625 8.25 6.375 8.41875 6.375 8.625Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M4.90039 3.85267C5.21862 3.20879 6.33241 3.04791 6.96886 3.53077C7.39319 3.8527 7.60531 4.49652 7.12797 5.14037C6.65063 5.78422 6.01418 5.94519 6.01418 6.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
