import { atom } from 'jotai';

type DragContext = {
  id: string;
  title?: string | undefined;
  isRecurrence?: boolean;
  isProject?: boolean;
  done?: boolean;
  lockedIn?: boolean;
  focus?: boolean;
};

export const dragContextAtom = atom<DragContext | null>(null);
