import { SVGProps } from 'react';

export const DoubleCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.66634 8.00163L7.96617 11.3015L15.0378 4.23047M1.36621 8.03516L4.66604 11.335M11.737 4.264L8.2015 7.79953"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
