import { SVGProps } from 'react';

export const InprogressCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={props.fill ? props.fill : '#0039A7'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5459 6.98705C15.8292 7.28923 15.8138 7.76386 15.5117 8.04716L8.84499 14.2972C8.54281 14.5805 8.06818 14.5651 7.78488 14.263C7.50158 13.9608 7.51689 13.4861 7.81908 13.2028L14.4857 6.95285C14.7879 6.66955 15.2626 6.68486 15.5459 6.98705Z"
        fill="#E2ECFF"
      />
      <mask id="mask0_12445_210152" maskUnits="userSpaceOnUse" x="3" y="10" width="14" height="7">
        <path d="M17 17L17 10L3 10L3 17L17 17Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12445_210152)">
        <path
          d="M10.002 17C13.8679 17 17.002 13.866 17.002 10C17.002 6.13401 13.8679 3 10.002 3C6.13596 3 3.00195 6.13401 3.00195 10C3.00195 13.866 6.13596 17 10.002 17Z"
          fill={props.fill ? props.fill : '#0039A7'}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4297 10L8.52598 14.5972C8.23749 14.8677 7.78856 14.8677 7.50007 14.5972L4.16673 11.4722C3.86455 11.1889 3.84924 10.7143 4.13254 10.4121C4.41583 10.1099 4.89046 10.0946 5.19265 10.3779L8.01302 13.022L11.2365 10H13.4297Z"
        fill="white"
      />
    </svg>
  );
};
