import { SVGProps } from 'react';

export const ConvertMenu = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4 5.33594V11.7359C4 12.296 4 12.5758 4.10496 12.7897C4.19728 12.9779 4.34448 13.1312 4.52567 13.2271C4.73146 13.3359 5.00099 13.3359 5.53924 13.3359H10M4 5.33594L2 7.33594M4 5.33594L6 7.33594"
        stroke="#1F2023"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11.3359L12 4.93594C12 4.37589 12 4.09605 11.895 3.88214C11.8027 3.69398 11.6555 3.5407 11.4743 3.44482C11.2685 3.33594 10.999 3.33594 10.4608 3.33594L6 3.33594M12 11.3359L14 9.33594M12 11.3359L10 9.33594"
        stroke="#1F2023"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
