import { SVGProps } from 'react';

export const Question = ({
  width = 32,
  height = 32,
  fill = '#1F2023',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2098_8377)">
        <path
          d="M13.9532 20.4668C13.8785 21.2587 14.5347 21.9054 15.3301 21.9054C16.1255 21.9054 16.7519 21.2562 16.8703 20.4697C17.1393 18.6828 17.9763 17.2772 19.6842 16.2297C21.7608 14.9459 23 13.1047 23 10.4527C23 6.71959 20.2703 4 16.067 4C12.757 4 10.021 5.77428 9.2262 8.89927C9.01035 9.74801 9.72948 10.4865 10.6052 10.4865C11.4169 10.4865 12.0547 9.84004 12.3281 9.07578C12.9611 7.30598 14.5195 6.55388 16.067 6.56757C18.2775 6.55068 20.0526 8.02027 20.0526 10.3176C20.0526 12.2095 18.9976 13.5608 17.6077 14.4054C15.1899 15.8898 14.1976 17.8759 13.9532 20.4668ZM13.2871 25.8041C13.2536 27.0034 14.2584 27.9831 15.4306 28C16.6196 27.9831 17.5909 27.0034 17.5742 25.8041C17.5909 24.6216 16.6196 23.6419 15.4306 23.6419C14.2584 23.6419 13.2536 24.6216 13.2871 25.8041Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2098_8377">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
