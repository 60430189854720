import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { createVoteV1VotesPost, getListVotesV1VotesGet } from 'queries';
import { CreateVote } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { Vote } from './Vote';
import { v4 as uuidv4 } from 'uuid';
import { CompleteVote } from './CompleteVote';

export const ProductionGoal = () => {
  const [openVote, setOpenVote] = useState(false);
  const [openCompleteVote, setOpenCompleteVote] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isImgRendered, setIsImgRendered] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    init();

    const img = new Image();
    img.src = require('assets/images/ProductionGoal.png');
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, []);

  const init = async () => {
    const voteList = await getListVotesV1VotesGet();
    voteList.find((item) => item.voteType === 'goal') ? setComplete(true) : setComplete(false);
  };

  const handleOpenVote = () => {
    setOpenVote(true);
  };
  const handleCloseVote = () => {
    setOpenVote(false);
  };

  const handleOpenCompleteVote = () => {
    setOpenCompleteVote(true);
  };

  const handleCloseCompleteVote = () => {
    setOpenCompleteVote(false);
  };

  const handleClickVote = async (score: number, description: string) => {
    const newVote: CreateVote = {
      id: uuidv4(),
      voteType: 'goal',
      score,
      description,
    };

    const success = await createVoteV1VotesPost(newVote);

    if (success) {
      setOpenVote(false);
      setTimeout(() => {
        handleOpenCompleteVote();
      }, 300);
      init();
    }
  };

  return (
    <Container>
      {isImgLoaded && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: isImgRendered ? 1 : 0 }}>
          <MainContent>
            <MainContentHeader>
              <div
                style={{
                  width: '14px',
                  height: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: COLORS.brand1,
                  borderRadius: '50%',
                  marginRight: '4px',
                }}
              >
                <Icons.Preparing />
              </div>
              <Fonts.H4>준비중</Fonts.H4>
            </MainContentHeader>
            <MainContentMain>
              <Fonts.H1>{`나를 나답게 만들어주는 '목표',\n어디에 적어두고 있나요?`}</Fonts.H1>
            </MainContentMain>
            <MainContentFooter>
              <Fonts.Body1>{`SLASH에 목표를 적어두고, 잊지 않도록 리마인드를 받아보세요.`}</Fonts.Body1>
            </MainContentFooter>
          </MainContent>
          {complete === false && (
            <Button variant="contained" sx={{ height: '40px', padding: '10px 16px', borderRadius: '8px', margin: '32px 0px' }} onClick={handleOpenVote}>
              👍 기대돼요
            </Button>
          )}
          {complete && (
            <div
              style={{
                padding: '10px 16px',
                color: COLORS.brand1,
                fontSize: '13px',
                fontWeight: 'bold',
                borderRadius: '8px',
                border: `1px solid ${COLORS.brand1}`,
                backgroundColor: COLORS.white,
                margin: '32px 0px',
              }}
            >
              👍 기대돼요 전달완료!
            </div>
          )}
          <img src={require('assets/images/ProductionGoal.png')} alt="goal-img" style={{ width: 827 }} onLoad={() => setIsImgRendered(true)} />
        </div>
      )}
      <Vote open={openVote} onClose={handleCloseVote} onClick={handleClickVote} />
      <CompleteVote open={openCompleteVote} onClose={handleCloseCompleteVote} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContentHeader = styled.div`
  color: ${COLORS.brand1};
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
`;

const MainContentMain = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 12px;
  text-align: center;
  white-space: pre-line;
`;

const MainContentFooter = styled.div`
  color: ${COLORS.gray500};
  white-space: pre-line;
  text-align: center;
`;

export default ProductionGoal;
