import moment from 'moment-timezone';
import 'moment/locale/ko';

moment.tz.setDefault('Asia/Seoul');

export const setMomentTimeZone = (zone: string) => {
  moment.tz.setDefault(zone);
  console.log(zone);
};

export default moment;
