import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Popover, Divider, TextField, Tooltip } from '@mui/material';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';
import { StaticDatePicker } from 'components/StaticDatePicker';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';
import { DateType } from './TimelineTaskbox';
import { useState } from 'react';
import { useKeyboardEvent } from '@react-hookz/web';

interface Props {
  event?: OutTaskboxDetailResponse;
  onChangeDate?: (date: Date | null, isAllDay?: boolean, dateType?: DateType) => void;
}

export const TaskboxPostponeDetail = ({ event, onChangeDate = () => {} }: Props) => {
  const [language] = useAtom(languageAtom);
  const [date] = useState(event?.start?.date ? dayjs(event?.start?.date, { utc: true }).toDate() : dayjs(event?.start?.datetime, { utc: true }).toDate());

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement as HTMLElement;
      if (!element) return;

      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
      ) {
        return;
      }

      if (ev.code === 'KeyN') {
        onChangeDate?.(dayjs(date).add(1, 'days').toDate(), true, 'tomorrow');
      }

      if (ev.code === 'KeyM') {
        onChangeDate?.(dayjs(date).day(8).toDate(), true, 'nextWeek');
      }

      if (ev.code === 'KeyL') {
        onChangeDate?.(null, true);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  return (
    <Container>
      <MenuList>
        <Tooltip
          title={
            <div style={{ padding: '2px 0px' }}>
              <span>
                {language === 'ko'
                  ? `다음 날 (${dayjs(date).add(1, 'day').format('M월 D일 dd요일')})`
                  : `Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`}
                {/* {`Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`} */}
              </span>
              <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                N
              </KeyboardButtonRect>
            </div>
          }
          disableInteractive
        >
          <MenuItem onClick={() => onChangeDate?.(dayjs(date).add(1, 'days').toDate(), true, 'tomorrow')}>
            <Icons.Tomorrow width={20} height={20} stroke={COLORS.gray600} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
              <div>
                {language === 'ko' ? '다음 날' : 'Next day'}
                {/* Next day */}
              </div>
              <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                N
              </KeyboardButtonRect>
            </div>
          </MenuItem>
        </Tooltip>
        <Tooltip
          title={
            <div style={{ padding: '2px 0px' }}>
              <span>
                {language === 'ko'
                  ? `다음주 월요일 (${dayjs(date).day(8).format('M월 D일 dd요일')})`
                  : `Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`}
                {/* {`Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`} */}
              </span>
              <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                M
              </KeyboardButtonRect>
            </div>
          }
          disableInteractive
        >
          <MenuItem
            onClick={() => onChangeDate?.(dayjs(date).day(8).toDate(), true, 'nextWeek')}
            style={{ width: language === 'ko' ? '33.3%' : '36%', padding: '10px 0px' }}
          >
            <Icons.NextMonday width={20} height={20} stroke={COLORS.gray600} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
              <div>
                {language === 'ko' ? '다음주 월요일' : 'Next Monday'}
                {/* Next Monday */}
              </div>
              <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                M
              </KeyboardButtonRect>
            </div>
          </MenuItem>
        </Tooltip>
        <Tooltip
          title={
            <div style={{ padding: '2px 0px' }}>
              <span>
                {language === 'ko' ? `나중에` : `Later`}
                {/* Later */}
              </span>
              <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                L
              </KeyboardButtonRect>
            </div>
          }
          disableInteractive
        >
          <MenuItem onClick={() => onChangeDate?.(null, true)}>
            <Icons.Later width={20} height={20} fill={COLORS.gray600} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
              <div>
                {language === 'ko' ? `나중에` : `Later`}
                {/* Later */}
              </div>
              <KeyboardButtonRect small style={{ marginLeft: '4px' }}>
                L
              </KeyboardButtonRect>
            </div>
          </MenuItem>
        </Tooltip>
      </MenuList>
      <Divider style={{ margin: '16px 0px' }} />
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={event?.start?.date || event?.start?.datetime}
        onChange={(newValue) => onChangeDate?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true, 'calendar')}
        renderInput={(params) => <TextField {...params} />}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 306px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 16px;
  z-index: 10;

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  .css-epd502 {
    width: auto;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
  }

  .MuiCalendarPicker-root {
    width: 274px;
    margin: 0px;
  }

  .PrivatePickersSlideTransition-root {
    min-height: 192px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 8px;
  }

  .MuiPickersCalendarHeader-labelContainer {
    max-height: 48px;
  }
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItem = styled.div`
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 8px;
  font-size: 12px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 4px' : '4px'}`};
`;
