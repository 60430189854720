import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Popover, Divider, TextField, Tooltip } from '@mui/material';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';
import { StaticDatePicker } from 'components/StaticDatePicker';
import dayjs from 'lib/dayjs';
import { ContinueDateType } from './TimelineTaskbox';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface Props {
  event?: OutTaskboxDetailResponse;
  anchorEl?: HTMLElement | null;
  onCloseContinue?: () => void;
  onChangeDate?: (date: Date | null, isAllDay: boolean, dateType: ContinueDateType) => void;
}

export const TaskboxContinueDetail = ({ event, anchorEl, onCloseContinue = () => {}, onChangeDate = () => {} }: Props) => {
  const [language] = useAtom(languageAtom);
  const [date] = useState(event?.start?.date ? dayjs(event?.start?.date).toDate() : dayjs(event?.start?.datetime).toDate());
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onCloseContinue}
      sx={{ marginTop: '4px' }}
    >
      <Container lang={language}>
        <MenuList>
          <Tooltip
            title={
              language === 'ko' ? `오늘 (${dayjs(date).format('M월 D일 dd요일')})` : `Today (${dayjs(date).format('dddd, MMM D')})`
              // `Today (${dayjs(date).format('dddd, MMM D')})`
            }
            disableInteractive
          >
            <MenuItem
              className="item-text"
              onClick={() => {
                onChangeDate(date, event!.allDay!, 'today');
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Today width={16} height={16} />
              </span>
              <span>
                {language === 'ko' ? '오늘 중' : 'Today'}
                {/* Today */}
              </span>
            </MenuItem>
          </Tooltip>
          <Tooltip
            title={
              language === 'ko'
                ? `다음 날 (${dayjs(date).add(1, 'day').format('M월 D일 dd요일')})`
                : `Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`
              // `Next day (${dayjs(date).add(1, 'day').format('dddd, MMM D')})`
            }
            disableInteractive
          >
            <MenuItem
              className="item-text"
              onClick={() => {
                onChangeDate(dayjs(date).add(1, 'day').toDate(), true, 'tomorrow');
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Tomorrow />
              </span>
              <span>
                {language === 'ko' ? '다음 날' : 'Next day'}
                {/* Next day */}
              </span>
            </MenuItem>
          </Tooltip>
          <Tooltip
            title={
              language === 'ko' ? `다음주 월요일 (${dayjs(date).day(8).format('M월 D일 dd요일')})` : `Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`
              // `Next Monday (${dayjs(date).day(8).format('dddd, MMM D')})`
            }
            disableInteractive
          >
            <MenuItem
              className="item-text"
              onClick={() => {
                onChangeDate(dayjs(date).day(8).toDate(), true, 'nextWeek');
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.NextMonday />
              </span>
              <span>
                {language === 'ko' ? '다음주 월요일' : 'Next Monday'}
                {/* Next Monday */}
              </span>
            </MenuItem>
          </Tooltip>
        </MenuList>
        <Divider style={{ margin: '16px 0px' }} />
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={event?.start?.date || event?.start?.datetime}
          onChange={(newValue) => onChangeDate(dayjs(newValue!).toDate(), false, 'calendar')}
          renderInput={(params) => <TextField {...params} />}
        />
      </Container>
    </Popover>
  );
};

const Container = styled.div<{ lang?: string }>`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiPickersCalendarHeader-label {
    font-size: 12px;
    /* font-size: ${({ lang }) => (lang === 'ko' ? '16px' : '12px')}; */
  }

  .MuiCalendarPicker-root {
    width: 200px;
  }

  .css-epd502 {
    width: 200px;
    height: 190px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  font-size: 12px;
`;
