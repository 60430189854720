import { SVGProps } from 'react';

export const Flag = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <mask id="mask0_19846_146286" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
        <rect width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_19846_146286)">
        <path
          d="M3 10.5C2.85833 10.5 2.7395 10.452 2.6435 10.356C2.54783 10.2603 2.5 10.1417 2.5 10V2.5C2.5 2.35833 2.54783 2.2395 2.6435 2.1435C2.7395 2.04783 2.85833 2 3 2H6.5875C6.70417 2 6.80833 2.0375 6.9 2.1125C6.99167 2.1875 7.05 2.28333 7.075 2.4L7.2 3H9.5C9.64167 3 9.76033 3.04783 9.856 3.1435C9.952 3.2395 10 3.35833 10 3.5V7.5C10 7.64167 9.952 7.76033 9.856 7.856C9.76033 7.952 9.64167 8 9.5 8H6.9125C6.79583 8 6.69167 7.9625 6.6 7.8875C6.50833 7.8125 6.45 7.71667 6.425 7.6L6.3 7H3.5V10C3.5 10.1417 3.45217 10.2603 3.3565 10.356C3.2605 10.452 3.14167 10.5 3 10.5ZM7.325 7H9V4H6.375L6.175 3H3.5V6H7.125L7.325 7Z"
          fill={props.fill || '#656871'}
        />
      </g>
    </svg>
  );
};
