/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Custom title
 * 커스텀 OpenAPI 스키마
 * OpenAPI spec version: 3.0.2
 */

/**
 * An enumeration.
 */
export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachmentType = {
  file: 'file',
  link: 'link',
} as const;
