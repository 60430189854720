import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { listWorksV2V2WorkboxesGet } from 'queries';
import { OutTaskboxDetailResponse } from 'queries/model';

const request = async () => await listWorksV2V2WorkboxesGet();
const tasks = atomWithDefault<OutTaskboxDetailResponse[]>(request);
export const tasksAtom = atom(
  (get) => get(tasks),
  async (_, set) => {
    const res = await listWorksV2V2WorkboxesGet();
    set(tasks, res || []);
  },
);
