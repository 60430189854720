import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from '../../styles/constants';

interface ToggleProps {
  toggle: boolean;
  onClick: () => void;
}

export const Toggle = ({ toggle = false, onClick }: ToggleProps) => {
  return (
    <Wrapper toggle={toggle}>
      <Controller toggle={toggle} onClick={onClick} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ toggle: boolean }>`
  width: 48px;
  height: 24px;
  border-radius: 100px;
  background-color: ${COLORS.gray300};
  position: relative;

  ${(props) =>
    props.toggle &&
    css`
      background-color: ${COLORS.brand1};
    `}
`;

const Controller = styled.div<{ toggle: boolean }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${COLORS.white};
  position: absolute;
  top: 4px;
  left: 4px;
  ${(props) =>
    props.toggle &&
    css`
      top: 4px;
      left: 28px;
    `}
`;
