import { SVGProps } from 'react';

export const Today = ({ width = 16, height = 16, stroke = '#1F2023', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.9998 3.33325H3.99984C3.26346 3.33325 2.6665 3.93021 2.6665 4.66659V12.6666C2.6665 13.403 3.26346 13.9999 3.99984 13.9999H11.9998C12.7362 13.9999 13.3332 13.403 13.3332 12.6666V4.66659C13.3332 3.93021 12.7362 3.33325 11.9998 3.33325Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.6665 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.3335 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 8.875L7 10.75L11 7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
