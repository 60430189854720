import styled from '@emotion/styled';
import { Button, IconButton, Modal, TextField } from '@mui/material';
import { Icons } from 'components';
import { useState } from 'react';
import { COLORS } from 'styles/constants';
import { css } from '@emotion/react';
import { PriorityIssueCheck, PriorityIssueUncheck } from 'components/Icons';
import { RecordUserSurveyV1SurveysSurveyIdPostBody } from 'queries/model';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiInputBase-input {
    font-size: 13px;
  }
  .MuiInputBase-root {
    padding: 14px 16px;
  }
`;

const SurveyWrapper = styled.div`
  width: 480px;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border-radius: 16px;
  padding: 24px;
`;

const Section = styled.div`
  width: 100%;
`;

const SurveyHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SurveyHeaderTitle = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: bold;
`;

const SurveyBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SurveyBodyTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
const SurveyBodySubTitle = styled.div`
  font-size: 13px;
  margin-top: 8px;
`;

const SurveyFooter = styled.div``;

const SurveyFooterLater = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

const RequiredIcon = styled.div`
  width: 16px;
  height: 16px;
  font-size: 14px;
  background-color: ${COLORS.gray100};
  border-radius: 50%;
  text-align: center;
  margin-left: 4px;
`;

const RecommendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Gray_200, #e7eaf4);
  background: #f2f5fc;
`;

const ScoreWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  background-color: ${COLORS.white};
`;

const ScoreNumber = styled.div<{ select?: boolean }>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  color: ${COLORS.gray500};
  justify-content: center;
  padding: 4px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.sub3};
  }
  ${(props) =>
    props.select &&
    css`
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.sub2};
      color: ${COLORS.sub2};
    `}
`;

const ScoreText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
`;

const DisappointmentWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  > div:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const DisappointmentContent = styled.div<{ select?: boolean }>`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  font-size: 13px;
  padding: 12px;

  &:hover {
    background-color: ${COLORS.sub3};
  }

  ${(props) =>
    props.select &&
    css`
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.sub2};
    `}
`;

const DisappointmentImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const DisappointmentText = styled.div`
  text-align: center;
`;

interface PMFSurveyProps {
  open?: boolean;
  onClose?: () => void;
  onClickLater?: () => void;
  onClickSubmit?: (survey?: RecordUserSurveyV1SurveysSurveyIdPostBody) => void;
}

const PMFSurvey = ({ open = false, onClose, onClickLater, onClickSubmit }: PMFSurveyProps) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const handleClickNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handelClickPrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const scoreList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [selectedScore, setSelectedScore] = useState(0);
  const handleClickScore = (score: number) => {
    setSelectedScore(score);
  };

  const disappointmentList = [
    { image: require('assets/images/VeryDisappointment.png'), text: t('pmf.common.disappointment1') },
    { image: require('assets/images/Disappointment.png'), text: t('pmf.common.disappointment2') },
    { image: require('assets/images/NotDisappointment.png'), text: t('pmf.common.disappointment3') },
  ];
  const [selectedDisappointment, setSelectedDisappointment] = useState(0);
  const handleClickDisappointment = (disappointment: number) => {
    setSelectedDisappointment(disappointment);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleClickLater = () => {
    onClickLater && onClickLater();
  };

  const handleClickSubmit = () => {
    const survey = [
      { score: selectedScore },
      { disappointment: selectedDisappointment === 1 ? 'very disappointed' : selectedDisappointment === 2 ? 'disappointed' : 'not disappointed' },
      ...(isAhamomentSurvey ? [{ ahamoment: userAhamoment }] : []), // Conditionally add ahamoment
      { satisfaction: userSatisfaction },
      { improvement: userImprovement },
    ];

    onClickSubmit && onClickSubmit(survey);
    handleClickNext();
  };

  const [userAhamoment, setUserAhamoment] = useState('');
  const handleChangeUserAhamoment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserAhamoment(e.target.value);
  };

  const [userSatisfaction, setUserSatisfaction] = useState('');
  const handleChangeUserSatisfaction = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSatisfaction(e.target.value);
  };

  const [userImprovement, setUserImprovement] = useState('');
  const handleChangeUserImprovement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserImprovement(e.target.value);
  };

  const isAhamomentSurvey = selectedScore === 10 || selectedDisappointment === 1;

  // {activeStep === 3 && (
  //   <SurveyWrapper>
  //     <Section>
  //       <SurveyHeader>
  //         <div style={{ width: '20px', height: '20px' }} />
  //         <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
  //         <div style={{ width: '20px', height: '20px' }} />
  //       </SurveyHeader>
  //       <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
  //         <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('pmf.step3.question')}</SurveyBodyTitle>
  //         <TextField
  //           multiline
  //           minRows={5.2}
  //           maxRows={5.2}
  //           value={userSatisfaction}
  //           placeholder={t('pmf.step3.placeholder')}
  //           onChange={handleChangeUserSatisfaction}
  //           sx={{ width: '100%' }}
  //         />
  //       </SurveyBody>
  //     </Section>
  //     <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
  //       <Button
  //         variant="contained"
  //         onClick={handelClickPrev}
  //         sx={{
  //           'width': '204px',
  //           'height': '48px',
  //           'borderRadius': '8px',
  //           'padding': '14px 16px',
  //           'fontWeight': 'bold',
  //           'boxShadow': 'none',
  //           'color': COLORS.gray900,
  //           'backgroundColor': COLORS.gray100,
  //           '&:hover': { backgroundColor: COLORS.gray200 },
  //         }}
  //       >
  //         {t('pmf.common.prev')}
  //       </Button>
  //       <Button
  //         variant="contained"
  //         onClick={handleClickNext}
  //         sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
  //       >
  //         {t('pmf.common.next')}
  //       </Button>
  //     </SurveyFooter>
  //   </SurveyWrapper>
  // )}
  // {activeStep === 4 && (
  //   <SurveyWrapper>
  //     <Section>
  //       <SurveyHeader>
  //         <div style={{ width: '20px', height: '20px' }} />
  //         <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
  //         <div style={{ width: '20px', height: '20px' }} />
  //       </SurveyHeader>
  //       <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
  //         <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('pmf.step4.question')}</SurveyBodyTitle>
  //         <TextField
  //           multiline
  //           minRows={5.2}
  //           maxRows={5.2}
  //           value={userImprovement}
  //           placeholder={t('pmf.step4.placeholder')}
  //           onChange={handleChangeUserImprovement}
  //           sx={{ width: '100%' }}
  //         />
  //       </SurveyBody>
  //     </Section>

  //     <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
  //       <Button
  //         variant="contained"
  //         onClick={handelClickPrev}
  //         sx={{
  //           'width': '204px',
  //           'height': '48px',
  //           'borderRadius': '8px',
  //           'padding': '14px 16px',
  //           'fontWeight': 'bold',
  //           'boxShadow': 'none',
  //           'color': COLORS.gray900,
  //           'backgroundColor': COLORS.gray100,
  //           '&:hover': { backgroundColor: COLORS.gray200 },
  //         }}
  //       >
  //         {t('pmf.common.next')}
  //       </Button>
  //       <Button
  //         variant="contained"
  //         onClick={handleClickSubmit}
  //         sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
  //       >
  //         {t('pmf.common.complete')}
  //       </Button>
  //     </SurveyFooter>
  //   </SurveyWrapper>
  // )}
  // {activeStep === 5 && (
  //   <SurveyWrapper style={{ justifyContent: 'center' }}>
  //     <SurveyBody>
  //       <div
  //         style={{
  //           width: '60px',
  //           height: '60px',
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           fontSize: '32px',
  //           backgroundColor: COLORS.sub3,
  //           borderRadius: '50%',
  //         }}
  //       >
  //         ☺️
  //       </div>
  //       <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>{t('pmf.appreciation.title')}</SurveyBodyTitle>
  //       <div>{t('pmf.appreciation.description')}</div>
  //     </SurveyBody>
  //   </SurveyWrapper>

  return (
    <Modal open={open} onClose={handleClose}>
      <Container>
        {activeStep === 0 && (
          <SurveyWrapper>
            <SurveyHeader>
              <div style={{ width: '20px', height: '20px' }} />
              <SurveyHeaderTitle>💖&nbsp;&nbsp;&nbsp;{t('pmf.intro.header')}</SurveyHeaderTitle>
              <div style={{ width: '20px', height: '20px' }} />
            </SurveyHeader>
            <SurveyBody>
              <SurveyBodyTitle>{t('pmf.intro.title')}</SurveyBodyTitle>
              <SurveyBodySubTitle>{t('pmf.intro.description')}.</SurveyBodySubTitle>
            </SurveyBody>
            <SurveyFooter>
              <Button
                variant="contained"
                onClick={handleClickNext}
                sx={{ width: '192px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                🙌&nbsp;&nbsp;&nbsp;{t('pmf.intro.join')}
              </Button>
              <SurveyFooterLater>
                <div style={{ fontSize: '12px', marginRight: '9px' }}>{t('pmf.intro.busy')}</div>
                <Button
                  sx={{ 'fontSize': '12px', 'padding': '0px', 'minWidth': '0px', '&:hover': { backgroundColor: COLORS.white } }}
                  onClick={handleClickLater}
                >
                  {t('pmf.intro.later')}
                </Button>
              </SurveyFooterLater>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 1 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <SurveyBodyTitle style={{ fontSize: '13px' }}>{t('pmf.step1.question')}</SurveyBodyTitle>
                  <RequiredIcon>*</RequiredIcon>
                </div>
                <RecommendWrapper>
                  <ScoreWrapper>
                    {scoreList.map((score) => (
                      <ScoreNumber key={score} onClick={() => handleClickScore(score)} select={score === selectedScore}>
                        {score}
                      </ScoreNumber>
                    ))}
                  </ScoreWrapper>
                  <ScoreText>
                    <div>{t('pmf.common.score1')}</div>
                    <div>{t('pmf.common.score2')}</div>
                  </ScoreText>
                </RecommendWrapper>
              </SurveyBody>
            </Section>
            <SurveyFooter>
              <Button
                variant="contained"
                disabled={selectedScore === 0}
                onClick={handleClickNext}
                sx={{ width: '192px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                {t('pmf.common.next')}
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 2 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <SurveyBodyTitle style={{ fontSize: '13px' }}>{t('pmf.step2.question')}</SurveyBodyTitle>
                  <RequiredIcon>*</RequiredIcon>
                </div>
                <DisappointmentWrapper>
                  {disappointmentList.map((disappointment, index) => (
                    <DisappointmentContent key={index} onClick={() => handleClickDisappointment(index + 1)} select={index + 1 === selectedDisappointment}>
                      {index + 1 === selectedDisappointment ? <PriorityIssueCheck /> : <PriorityIssueUncheck />}
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <DisappointmentImage src={disappointment.image} />
                        <DisappointmentText>{disappointment.text}</DisappointmentText>
                      </div>
                    </DisappointmentContent>
                  ))}
                </DisappointmentWrapper>
              </SurveyBody>
            </Section>
            <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handelClickPrev}
                sx={{
                  'width': '204px',
                  'height': '48px',
                  'borderRadius': '8px',
                  'padding': '14px 16px',
                  'fontWeight': 'bold',
                  'boxShadow': 'none',
                  'color': COLORS.gray900,
                  'backgroundColor': COLORS.gray100,
                  '&:hover': { backgroundColor: COLORS.gray200 },
                }}
              >
                {t('pmf.common.prev')}
              </Button>
              <Button
                variant="contained"
                disabled={selectedDisappointment === 0}
                onClick={handleClickNext}
                sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                {t('pmf.common.next')}
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {isAhamomentSurvey ? (
          <>
            {activeStep === 3 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <SurveyBodyTitle style={{ fontSize: '13px' }}>{t('pmf.aha1.question')}</SurveyBodyTitle>
                      <RequiredIcon>*</RequiredIcon>
                    </div>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userAhamoment}
                      placeholder={t('pmf.aha1.placeholder')}
                      onChange={handleChangeUserAhamoment}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {t('pmf.common.prev')}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!userAhamoment}
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    {t('pmf.common.next')}
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 4 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <SurveyBodyTitle style={{ fontSize: '13px' }}>{t('pmf.aha2.question')}</SurveyBodyTitle>
                      <RequiredIcon>*</RequiredIcon>
                    </div>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userSatisfaction}
                      placeholder={t('pmf.aha2.placeholder')}
                      onChange={handleChangeUserSatisfaction}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {t('pmf.common.prev')}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!userSatisfaction}
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    {t('pmf.common.next')}
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 5 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{t('pmf.common.additional_question')}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('pmf.step4.question')}</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userImprovement}
                      placeholder={t('pmf.step4.placeholder')}
                      onChange={handleChangeUserImprovement}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {t('pmf.common.prev')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickSubmit}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    {t('pmf.common.complete')}
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 6 && (
              <SurveyWrapper style={{ justifyContent: 'center' }}>
                <SurveyBody>
                  <div
                    style={{
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '32px',
                      backgroundColor: COLORS.sub3,
                      borderRadius: '50%',
                    }}
                  >
                    ☺️
                  </div>
                  <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>{t('pmf.appreciation.title')}</SurveyBodyTitle>
                  <div>{t('pmf.appreciation.description')}</div>
                </SurveyBody>
              </SurveyWrapper>
            )}
          </>
        ) : (
          <>
            {activeStep === 3 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('pmf.step3.question')}</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userSatisfaction}
                      placeholder={t('pmf.step3.placeholder')}
                      onChange={handleChangeUserSatisfaction}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {t('pmf.common.prev')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    {t('pmf.common.next')}
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 4 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('pmf.common.question')} ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('pmf.step4.question')}</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userImprovement}
                      placeholder={t('pmf.step4.placeholder')}
                      onChange={handleChangeUserImprovement}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {t('pmf.common.prev')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickSubmit}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    {t('pmf.common.complete')}
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 5 && (
              <SurveyWrapper style={{ justifyContent: 'center' }}>
                <SurveyBody>
                  <div
                    style={{
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '32px',
                      backgroundColor: COLORS.sub3,
                      borderRadius: '50%',
                    }}
                  >
                    ☺️
                  </div>
                  <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>{t('pmf.appreciation.title')}</SurveyBodyTitle>
                  <div>{t('pmf.appreciation.description')}🔥</div>
                </SurveyBody>
              </SurveyWrapper>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default PMFSurvey;
