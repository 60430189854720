import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useAtom } from 'jotai';
import { updateUserAtom } from 'atoms/user';
import { useEffect, useState } from 'react';
import {
  createTaskboxV1TaskboxesPost,
  createWorkboxV2WorkboxesPost,
  getSignupSurveyV1SurveysSignupGet,
  listWorksV2V2WorkboxesGet,
  mergeWorkOrWorkBoxesV2V2WorksMergePost,
  readTaskboxesV1TaskboxesGet,
  removeTaskV1WorksWorkIdDelete,
  removeTaskboxesV1TaskboxesDelete,
  updateBulkTaskboxesV1TaskboxesBulkPut,
  updateTaskboxV1TaskboxesTaskboxIdPut,
  updateWorkboxesV2V2WorkboxesWorkboxIdPut,
} from 'queries';
import { Inbox } from './Inbox';
import { Later } from './Later';
import Profile from './Profile';
import toast from 'react-hot-toast';
import { OutTaskboxDetailResponse, UpdateBulkTaskbox, UpdateTaskbox, UpdateWork } from 'queries/model';
import dayjs from 'lib/dayjs';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  SwipeableDrawer,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Fonts from 'components/Fonts';
import { v4 as uuidv4 } from 'uuid';
import { DATE_FORMAT_1, DATE_FORMAT_4, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { SwiperTimePicker } from './components/SwiperTimePicker';
import { ReactComponent as Work } from 'assets/images/icons/menus/work.svg';
import { useSwipeable } from 'react-swipeable';
import { languageAtom } from 'atoms/language';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiTabs-root {
    width: 100%;
  }
  .MuiTab-root {
    width: 33.3%;
  }
`;

const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MobileMain = styled.div`
  width: 100%;
  height: 100%;
`;

const MobileFooter = styled.div<{ active?: boolean }>`
  width: 100%;
  display: flex;
  border-top: 1px solid ${COLORS.gray300};

  .icon-today {
    filter: ${(props) =>
      props.active && 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);'};
  }

  .MuiTabs-flexContainer {
    background-color: ${COLORS.white};
  }
`;

const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px 20px 20px;
`;

const DrawerBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
`;

const LogoDescription = styled.h3`
  color: ${COLORS.gray500};
  font-size: 13px;
  margin-top: 8px;
`;

const InfoWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
  z-index: 2;
`;

const GuideMessage = styled.h1`
  color: ${COLORS.gray900};
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  white-space: pre-line;
`;

const GuideDescription = styled.h3`
  color: ${COLORS.gray800};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
  white-space: pre-line;
  margin-top: 24px;
`;

const LinkText = styled.h6`
  color: ${COLORS.gray600};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.25px;
`;

const SwiperWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 360px;

  .swiper-button-prev {
    left: 5%;
    color: ${COLORS.gray300};
  }

  .swiper-button-prev:after {
    font-size: 15px;
    font-weight: bold;
  }

  .swiper-button-next {
    right: 5%;
    color: ${COLORS.gray300};
  }

  .swiper-button-next:after {
    font-size: 15px;
    font-weight: bold;
  }
`;

const SlideWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  height: 234px;
  max-width: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 32px 20px;
  max-height: 360px;
  box-sizing: border-box;
  box-shadow: 0px 10.5px 35px 0px rgba(45, 106, 223, 0.24);
  background-color: ${COLORS.white};
  /* margin: 20px 20px; */
`;

const SlideText = styled.div<{ isMobile?: boolean }>`
  color: ${COLORS.gray700};
  white-space: pre-wrap;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 20px 0px;
`;

const UserEmail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--White, #fff);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12);
  margin-top: 32px;
  padding: 32px;
`;

const Puller = styled.div`
  width: 30px;
  height: 6px;
  background-color: ${COLORS.gray200};
  border-radius: 100px;
`;

export type MultiSelectActions = 'MOVE_TO_TODAY' | 'MOVE_TO_LATER' | 'MOVE_TO_TOMORROW' | 'MERGE_TO_TASKGROUP' | 'DELETE';

const Mobile = () => {
  const navigate = useNavigate();
  const [user, getUser] = useAtom(updateUserAtom);

  useEffect(() => {
    (async () => {
      const survey = await getSignupSurveyV1SurveysSignupGet();

      if (survey.isTarget) {
        navigate('/m/survey');
        return;
      }
      getUser();
    })();
  }, []);

  const slide: {
    logo: string;
    text: string;
    name: string;
    company: string;
  }[] = [
    {
      logo: require('../../assets/images/enactus.png'),
      text: '하루를 계획하는 문제를 해결해줬어요.\nSLASH로 빠르게 계획하면 하루를\n자신감 있게 시작할 수 있어요.',
      name: '이고은',
      company: 'Enactus Korea, 사무국장',
    },
    {
      logo: require('../../assets/images/onespirits.png'),
      text: '무엇보다도 배우거나 세팅할 필요 없이\n바로 쓸 수 있어 좋아요. SLASH 덕분에\n업무 정리로 고민하는 시간이 줄었어요.',
      name: '차미지',
      company: '원스피리츠, 경영관리본부 리드',
    },
    {
      logo: require('../../assets/images/genieworks.png'),
      text: '지금 집중할 일과 나중에 할 일을 빠르게\n구분할 수 있어 현재에 집중할 수 있어요.\n업무가 쏟아지듯 많은 사람에게 추천해요.',
      name: '홍지은',
      company: '지니웍스, PM',
    },
    {
      logo: require('../../assets/images/millie.png'),
      text: '일을 바쁘게 했는데 뭘 했는지 모를 때가\n있잖아요.SLASH를 쓰면 성취를\n시각적으로 확인할 수 있어서 하루를\n채워가는 느낌이 들어요.',
      name: '조윤성',
      company: '밀리의 서재, PM',
    },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [taskboxes, setTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [backlogTasks, setBacklogTasks] = useState<OutTaskboxDetailResponse[]>([]);
  const [selectedTaskbox, setSelectedTaskbox] = useState<OutTaskboxDetailResponse | undefined>(undefined);
  const [multiSelectedIds, setMultiSelectedIds] = useState<string[]>([]);
  const [openStart, setOpenStart] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);
  const [isOpenTaskDrawer, setIsOpenTaskDrawer] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [language] = useAtom(languageAtom);

  useEffect(() => {
    updateTaskboxes(currentDate);
    updateBacklogTasks();
  }, []);

  const setFocusElement = (id: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
      const element = el.querySelector('input') as HTMLElement;
      element && element.focus();
    }, 100);
  };

  const handleClickAddTask = () => {
    setSelectedTaskbox({
      id: '',
      title: '',
    });
    setIsOpenTaskDrawer(true);
  };

  const handleClickMoveToToday = () => {
    handleChangeCurrentDate(new Date());
  };

  const handleCloseDrawer = async () => {
    if (!selectedTaskbox) return;

    const isFakeTaskbox = selectedTaskbox.id === '' && selectedTaskbox.title === '';
    const isTaskboxTab = tabIndex === 0;
    const isNewTaskbox = !selectedTaskbox.id;

    if (isFakeTaskbox) {
      setIsOpenTaskDrawer(false);
      setTimeout(() => {
        setSelectedTaskbox(undefined);
      }, 200);

      return;
    }

    try {
      if (isTaskboxTab) {
        if (isNewTaskbox) {
          await createWorkboxV2WorkboxesPost({
            title: selectedTaskbox.title!,
            start: selectedTaskbox.start?.datetime ? { datetime: selectedTaskbox.start.datetime } : { date: dayjs(currentDate).format(DATE_FORMAT_4) },
            end: selectedTaskbox.end?.datetime ? { datetime: selectedTaskbox.end.datetime } : { date: dayjs(currentDate).add(1, 'day').format(DATE_FORMAT_4) },
          });
          toast.success('새로운 태스크박스를 생성하였습니다.');
        } else {
          await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, selectedTaskbox as UpdateTaskbox);
          toast.success('태스크박스를 수정하였습니다.');
        }
        updateTaskboxes(currentDate);
      } else {
        if (isNewTaskbox) {
          await createTaskboxV1TaskboxesPost({
            title: selectedTaskbox.title!,
          });
          toast.success('새로운 태스크를 생성하였습니다.');
        } else {
          await updateWorkboxesV2V2WorkboxesWorkboxIdPut(selectedTaskbox.id!, selectedTaskbox as UpdateTaskbox);
          toast.success('태스크를 수정하였습니다.');
        }
        updateBacklogTasks();
      }
    } catch (error) {
      toast.error('작업을 완료할 수 없습니다. 다시 시도해주세요.');
    } finally {
      setIsOpenTaskDrawer(false);
      setTimeout(() => {
        setSelectedTaskbox(undefined);
      }, 200);
    }
  };

  const handleClickTask = async (taskbox: OutTaskboxDetailResponse) => {
    if (taskbox) {
      setIsOpenTaskDrawer(true);
      setSelectedTaskbox(taskbox);
    }
  };

  const handleClickDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => [setDeleteDialog(false)];

  const handleClickDelete = async () => {
    if (!selectedTaskbox) return;
    const success = await removeTaskV1WorksWorkIdDelete(selectedTaskbox.id!);

    if (success) {
      toast.success('태스크박스를 삭제하였습니다.');
      tabIndex === 0 ? updateTaskboxes(currentDate) : updateBacklogTasks();
      setDeleteDialog(false);
      setIsOpenTaskDrawer(false);
      setTimeout(() => {
        setSelectedTaskbox(undefined);
      }, 200);
    }
  };

  const handleClickLater = async () => {
    if (!selectedTaskbox) return;
    const updateWork: UpdateWork = { workSectionType: 'someday' };
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, updateWork);
    if (success) {
      toast.success('태스크박스를 나중에로 옮겼습니다.');
      updateTaskboxes(currentDate);
      setIsOpenTaskDrawer(false);
      setTimeout(() => {
        setSelectedTaskbox(undefined);
      }, 200);
    }
  };

  const handleChangeCurrentDate = async (date: Date) => {
    updateTaskboxes(date);
    setCurrentDate(date);
  };

  const updateTaskboxes = async (date: Date) => {
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const taskboxes = await readTaskboxesV1TaskboxesGet({
      start_date: formattedDate,
      end_date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4),
    });
    const multiDayTaskboxes = taskboxes.filter((item) => item.type === 'TERM_TASK');
    const allDayTaskboxes = taskboxes.filter((item) => item.type !== 'TERM_TASK' && item.allDay);
    const timeTaskboxes = taskboxes.filter((item) => item.type !== 'TERM_TASK' && !item.allDay);
    const sortedTaskboxes = [...multiDayTaskboxes, ...allDayTaskboxes, ...timeTaskboxes];
    setTaskboxes(sortedTaskboxes);
  };

  const updateBacklogTasks = async () => {
    const tasks = await listWorksV2V2WorkboxesGet();
    setBacklogTasks(tasks);
  };

  const handleClickHighlight = () => {
    if (!selectedTaskbox) return;
    setSelectedTaskbox({
      ...selectedTaskbox,
      focus: !selectedTaskbox.focus,
    });
  };

  const handleClickCheckbox = async (id: string, done: boolean) => {
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(id, { done: done });
    if (success) {
      updateTaskboxes(currentDate);
    }
  };

  const handleChangeTaskboxDone = (done: boolean) => {
    if (!selectedTaskbox) return;
    setSelectedTaskbox({
      ...selectedTaskbox,
      done: done,
    });
  };

  const handleClickStartTime = () => {
    setOpenStart(true);
  };

  const handleCloseStartTime = (remove?: boolean) => {
    if (remove) {
      setSelectedTaskbox({
        ...selectedTaskbox!,
        start: undefined,
        durationMin: dayjs(selectedTaskbox?.end?.datetime).diff(dayjs(selectedTaskbox?.start?.datetime), 'minute'),
        allDay: true,
      });
    }
    setOpenStart(false);
    setOpenDuration(true);
  };

  const handleChangeTime = (startTime: Date | undefined, duration: number | null | undefined, isAllDay: boolean) => {
    setSelectedTaskbox({
      ...selectedTaskbox!,
      start: isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
      end: isAllDay
        ? { date: dayjs(startTime).add(1, 'day').format(DATE_FORMAT_4) }
        : { datetime: dayjs(startTime).add(duration!, 'minute').format(DATE_FORMAT_1) },
      durationMin: duration === 0 ? undefined : isAllDay ? duration : undefined,
      allDay: isAllDay,
    });
  };

  const handleClickDuration = () => {
    setOpenDuration(true);
  };

  const handleCloseDuration = (remove?: boolean) => {
    if (remove) {
      setSelectedTaskbox({
        ...selectedTaskbox!,
        durationMin: undefined,
        start: { date: dayjs(currentDate).format(DATE_FORMAT_4) },
        end: { date: dayjs(currentDate).add(1, 'day').format(DATE_FORMAT_4) },
      });
    }
    setOpenDuration(false);
  };

  const showingDurationTime = (duration: number) => {
    const hour = Math.floor(duration / 60);
    const minute = duration % 60;

    if (hour === 0) {
      return `${minute}분 동안`;
    }

    return minute === 0 ? `${hour}시간 동안` : `${hour}시간 ${minute}분 동안`;
  };

  const handleClickMultiSelect = (id: string) => {
    if (multiSelectedIds.includes(id)) {
      setMultiSelectedIds(multiSelectedIds.filter((item) => item !== id));
    } else {
      setMultiSelectedIds([...multiSelectedIds, id]);
    }
  };

  const handleRemoveMultiSelect = () => {
    setMultiSelectedIds([]);
  };

  const handleClickMultiSelectActions = async (type: MultiSelectActions) => {
    if (!multiSelectedIds.length) return;
    const multiSelectedTaskboxes =
      tabIndex === 0 ? taskboxes.filter((item) => multiSelectedIds.includes(item.id!)) : backlogTasks.filter((item) => multiSelectedIds.includes(item.id!));

    try {
      switch (type) {
        case 'MOVE_TO_TODAY': {
          const updateBultTaskboxes: UpdateBulkTaskbox[] = multiSelectedTaskboxes.map((item) => ({
            ...item,
            start: { date: dayjs().format(DATE_FORMAT_4) },
            end: { date: dayjs().add(1, 'day').format(DATE_FORMAT_4) },
          })) as UpdateBulkTaskbox[];

          await updateBulkTaskboxesV1TaskboxesBulkPut(updateBultTaskboxes);
          toast.success('선택된 태스크박스를 오늘로 이동하였습니다.');
          break;
        }
        case 'MOVE_TO_LATER': {
          const updateBultTaskboxes: UpdateBulkTaskbox[] = multiSelectedTaskboxes.map((item) => ({
            ...item,
            workSectionType: 'someday',
          })) as UpdateBulkTaskbox[];

          await updateBulkTaskboxesV1TaskboxesBulkPut(updateBultTaskboxes);
          toast.success('선택된 태스크박스를 나중에로 이동하였습니다.');
          break;
        }
        case 'MOVE_TO_TOMORROW': {
          const updateBultTaskboxes: UpdateBulkTaskbox[] = multiSelectedTaskboxes.map((item) => ({
            ...item,
            start: { date: dayjs(currentDate).add(1, 'day').format(DATE_FORMAT_4) },
            end: { date: dayjs(currentDate).add(2, 'day').format(DATE_FORMAT_4) },
          })) as UpdateBulkTaskbox[];

          await updateBulkTaskboxesV1TaskboxesBulkPut(updateBultTaskboxes);
          toast.success('선택된 태스크박스를 내일로 이동하였습니다.');
          break;
        }
        case 'MERGE_TO_TASKGROUP': {
          await mergeWorkOrWorkBoxesV2V2WorksMergePost({ taskboxIds: multiSelectedIds });
          toast.success('태스크그룹으로 합쳤습니다.');
          break;
        }
        case 'DELETE': {
          const options = {
            paramsSerializer: (params: any) => {
              if (Array.isArray(params.id)) {
                return params.id.map((id: any) => `id=${id}`).join('&');
              }
            },
          };
          await removeTaskboxesV1TaskboxesDelete({ id: multiSelectedIds }, options);
          toast.success('선택된 태스크박스를 삭제하였습니다.');
          break;
        }
      }
      handleRemoveMultiSelect();
      updateTaskboxes(currentDate);
      updateBacklogTasks();
    } catch (error) {
      toast.error('작업을 완료할 수 없습니다.');
    }
  };

  const handleClickCompleteAndMoveToday = async () => {
    if (!selectedTaskbox) return;
    const success = await updateWorkboxesV2V2WorkboxesWorkboxIdPut(selectedTaskbox.id!, {
      ...(selectedTaskbox as UpdateTaskbox),
      start: {
        date: dayjs().format(DATE_FORMAT_4),
      },
      end: {
        date: dayjs().add(1, 'day').format(DATE_FORMAT_4),
      },
      beforeId: null,
      done: true,
    });

    if (success) {
      toast.success('태스크를 완료하고 오늘로 보냈습니다.');
      updateTaskboxes(currentDate);
      updateBacklogTasks();
      setIsOpenTaskDrawer(false);
      setTimeout(() => {
        setSelectedTaskbox(undefined);
      }, 200);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabIndex === 0) handleChangeCurrentDate(dayjs(currentDate).add(1, 'day').toDate());
    },
    onSwipedRight: () => {
      if (tabIndex === 0) handleChangeCurrentDate(dayjs(currentDate).subtract(1, 'day').toDate());
    },
  });

  return (
    <Container {...handlers}>
      {user && !user.tutorialStatus ? (
        <>
          <LogoWrapper>
            <Icons.SlashBeta width={143} height={40} />
            <LogoDescription>Live as you think</LogoDescription>
          </LogoWrapper>
          <InfoWrapper>
            <GuideMessage>{language === 'ko' ? 'PC에서 SLASH를\n시작해보세요' : 'Start using SLASH\non your PC'}</GuideMessage>
            <GuideDescription>
              {language === 'ko'
                ? 'SLASH는 PC에 최적화되어 있어요\nPC에서 바로 이용하실 수 있도록\n가입하신 아래 메일로 링크를 보내드렸어요'
                : `SLASH is optimized for PC\nWe've emailed you a link\nto get started on your PC right away`}
            </GuideDescription>
            <UserEmail>
              <div style={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '40px' }}>✉️</div>
              <div style={{ color: COLORS.sub2, fontSize: '16px', fontWeight: 700, marginTop: '24px' }}>{user.email}</div>
            </UserEmail>
          </InfoWrapper>
        </>
      ) : (
        user && (
          <MobileWrapper>
            <MobileMain>
              <Inbox
                hidden={!(tabIndex === 0)}
                currentDate={currentDate}
                taskboxes={taskboxes}
                selectId={selectedTaskbox?.id}
                multiSelectedIds={multiSelectedIds}
                onClickTaskbox={handleClickTask}
                onChangeCurrentDate={handleChangeCurrentDate}
                onChangeDone={handleClickCheckbox}
                onClickMultiSelect={handleClickMultiSelect}
                onRemoveMultiSelect={handleRemoveMultiSelect}
              />
              <Later
                hidden={!(tabIndex === 1)}
                tasks={backlogTasks}
                selectId={selectedTaskbox?.id}
                multiSelectedIds={multiSelectedIds}
                onClickTask={handleClickTask}
                onClickMultiSelect={handleClickMultiSelect}
                onRemoveMultiSelect={handleRemoveMultiSelect}
              />
              <Profile user={user!} hidden={!(tabIndex === 2)} />
            </MobileMain>
            {tabIndex === 0 && !dayjs().isSame(dayjs(currentDate), 'date') && multiSelectedIds.length === 0 && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 90,
                  zIndex: 10,
                  backgroundColor: COLORS.white,
                }}
              >
                <Button
                  onClick={handleClickMoveToToday}
                  disableRipple
                  sx={{
                    backgroundColor: COLORS.white,
                    borderRadius: '50px',
                    border: `1px solid ${COLORS.gray200}`,
                    boxShadow: `0px 8px 16px 0px rgba(26, 30, 39, 0.16);`,
                    color: COLORS.brand1,
                    fontWeight: 700,
                    padding: '8px 14px',
                  }}
                >
                  오늘로 이동하기
                </Button>
              </div>
            )}
            {!(tabIndex === 2) && multiSelectedIds.length === 0 && (
              <div
                style={{
                  position: 'absolute',
                  bottom: 90,
                  right: 14,
                  zIndex: 10,
                }}
              >
                <IconButton
                  onClick={handleClickAddTask}
                  disableRipple
                  sx={{
                    backgroundColor: COLORS.brand1,
                    borderRadius: '50%',
                    width: 50,
                    height: 50,
                    boxShadow: `0px 8px 16px 0px rgba(26, 30, 39, 0.16)`,
                  }}
                >
                  <Icons.Plus strokeWidth={2.34377} fill={COLORS.white} />
                </IconButton>
              </div>
            )}
            <div style={{ width: '100%' }}>
              <SwipeableDrawer
                anchor="bottom"
                open={isOpenTaskDrawer}
                onClose={handleCloseDrawer}
                onOpen={() => setSelectedTaskbox(selectedTaskbox)} // TransitionComponent={Transition}
                swipeAreaWidth={0}
                ModalProps={{
                  keepMounted: false,
                }}
                PaperProps={{
                  style: {
                    borderRadius: '16px 16px 0px 0px',
                  },
                }}
              >
                <DrawerContainer>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Puller />
                  </div>
                  <TaskContainer>
                    {selectedTaskbox?.id !== '' && tabIndex === 0 && (
                      <Checkbox
                        icon={
                          selectedTaskbox?.focus ? (
                            <Icons.FocusUncheck width={24} height={24} />
                          ) : (
                            <Icons.TaskboxUncheck
                              circle={selectedTaskbox?.project ? COLORS.issue2 : selectedTaskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                              marker={selectedTaskbox?.project ? '#F3DAFE' : selectedTaskbox?.isRecurrence ? '#E9F9FA' : '#E2ECFF'}
                            />
                          )
                        }
                        checkedIcon={
                          selectedTaskbox?.focus ? (
                            <Icons.FocusCheck width={24} height={24} opacity={0.5} />
                          ) : (
                            <Icons.TaskboxCheck
                              fill={selectedTaskbox?.project ? COLORS.issue2 : selectedTaskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                              opacity={0.5}
                            />
                          )
                        }
                        checked={!!selectedTaskbox?.done}
                        onChange={(e) => handleChangeTaskboxDone(e.target.checked)}
                        sx={{ width: 24, height: 24, padding: 0, marginRight: '8px' }}
                      />
                    )}
                    {selectedTaskbox?.id !== '' && tabIndex === 1 && (
                      <div style={{ marginRight: 8 }}>
                        <Icons.TaskCheckbox width={24} height={24} />
                      </div>
                    )}
                    <TextField
                      type="text"
                      data-id="task-input"
                      fullWidth
                      autoFocus
                      autoComplete="new-password"
                      variant="standard"
                      contentEditable
                      suppressContentEditableWarning
                      placeholder={tabIndex === 0 ? '할 일을 입력해주세요' : '태스크를 입력해주세요'}
                      value={selectedTaskbox?.title}
                      onChange={(e) => {
                        setSelectedTaskbox({ ...selectedTaskbox!, title: e.target.value });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleCloseDrawer();
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: 16, color: COLORS.gray600 },
                        inputProps: {
                          autoComplete: 'off',
                          form: {
                            autoComplete: 'off',
                          },
                        },
                      }}
                    />
                  </TaskContainer>
                  <DrawerBtn>
                    <div>
                      {tabIndex === 0 && (
                        <>
                          <Button
                            variant="contained"
                            onClick={handleClickStartTime}
                            sx={{
                              backgroundColor: selectedTaskbox?.start?.datetime ? COLORS.white : COLORS.gray200,
                              border: `1px solid ${COLORS.gray200}`,
                              boxShadow: 'none',
                              borderRadius: '8px',
                              color: COLORS.gray700,
                              fontSize: '13px',
                              padding: '8px',
                            }}
                          >
                            {selectedTaskbox?.start?.datetime ? dayjs(selectedTaskbox?.start?.datetime).format(TIME_FORMAT_2) : '시작 시간'}
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleClickDuration}
                            sx={{
                              backgroundColor: selectedTaskbox?.durationMin || selectedTaskbox?.allDay === false ? COLORS.white : COLORS.gray200,
                              border: `1px solid ${COLORS.gray200}`,
                              boxShadow: 'none',
                              borderRadius: '8px',
                              color: COLORS.gray700,
                              fontSize: '13px',
                              marginLeft: '6px',
                              padding: '8px',
                            }}
                          >
                            {selectedTaskbox?.durationMin
                              ? showingDurationTime(selectedTaskbox?.durationMin)
                              : selectedTaskbox?.end?.datetime
                              ? showingDurationTime(dayjs(selectedTaskbox?.end?.datetime).diff(dayjs(selectedTaskbox?.start?.datetime), 'minute'))
                              : '지속 시간'}
                          </Button>
                        </>
                      )}

                      {tabIndex === 1 && selectedTaskbox?.id && (
                        <Button
                          onClick={handleClickCompleteAndMoveToday}
                          sx={{ border: `1px solid ${COLORS.gray200}`, borderRadius: '8px', fontSize: '14px', padding: '8px' }}
                        >
                          완료하고 오늘로 보내기
                        </Button>
                      )}
                    </div>
                    <div style={{ display: 'flex' }}>
                      {tabIndex === 0 && selectedTaskbox?.id && (
                        <IconButton
                          onClick={handleClickHighlight}
                          style={{
                            width: 36,
                            height: 36,
                            backgroundColor: COLORS.white,
                            border: `1px solid ${COLORS.gray200}`,
                            borderRadius: 8,
                          }}
                        >
                          {selectedTaskbox?.focus ? <Icons.UnfocusTime width={20} height={20} /> : <Icons.FocusTime width={20} height={20} />}
                        </IconButton>
                      )}
                      {tabIndex === 0 && selectedTaskbox?.id && (
                        <IconButton
                          onClick={handleClickLater}
                          style={{
                            width: 36,
                            height: 36,
                            backgroundColor: COLORS.white,
                            border: `1px solid ${COLORS.gray200}`,
                            borderRadius: 8,
                            marginLeft: 8,
                          }}
                        >
                          <Icons.Later width={20} height={20} fill={COLORS.gray500} />
                        </IconButton>
                      )}
                      {selectedTaskbox?.id && (
                        <IconButton
                          onClick={handleClickDeleteDialog}
                          style={{
                            width: 36,
                            height: 36,
                            backgroundColor: COLORS.gray100,
                            borderRadius: 8,
                            marginLeft: 8,
                          }}
                        >
                          <Icons.Delete width={20} height={20} fill={COLORS.negative1} />
                        </IconButton>
                      )}
                      {selectedTaskbox?.id === '' && (
                        <IconButton
                          onClick={handleCloseDrawer}
                          style={{ width: 36, height: 36, backgroundColor: selectedTaskbox.title === '' ? COLORS.gray300 : COLORS.brand1, marginLeft: 8 }}
                        >
                          <Icons.Send />
                        </IconButton>
                      )}
                    </div>
                  </DrawerBtn>
                </DrawerContainer>
              </SwipeableDrawer>
              <SwipeableDrawer
                anchor="bottom"
                open={openStart}
                onClose={() => handleCloseStartTime()}
                onOpen={handleClickStartTime} // TransitionComponent={Transition}
                swipeAreaWidth={0}
                ModalProps={{
                  keepMounted: false,
                }}
                PaperProps={{
                  style: {
                    borderRadius: '16px 16px 0px 0px',
                  },
                }}
              >
                <DrawerContainer style={{ alignItems: 'center' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Puller />
                  </div>
                  <div style={{ fontSize: '16px', fontWeight: 700, marginTop: '8px', padding: '12px 0px' }}>시작 시간</div>
                  <Divider sx={{ width: '100%' }} />
                  <SwiperTimePicker taskbox={selectedTaskbox} onChange={handleChangeTime} />
                  <Divider sx={{ width: '100%' }} />
                  <Button
                    onClick={() => handleCloseStartTime(!!selectedTaskbox?.start?.datetime)}
                    style={{ color: selectedTaskbox?.start?.datetime ? COLORS.negative1 : COLORS.gray500, margin: '24px 0px' }}
                  >
                    {selectedTaskbox?.start?.datetime ? '시간 삭제' : '지정 안함'}
                  </Button>
                </DrawerContainer>
              </SwipeableDrawer>
              <SwipeableDrawer
                anchor="bottom"
                open={openDuration}
                onClose={() => handleCloseDuration()}
                onOpen={handleClickDuration} // TransitionComponent={Transition}
                swipeAreaWidth={0}
                ModalProps={{
                  keepMounted: false,
                }}
                PaperProps={{
                  style: {
                    borderRadius: '16px 16px 0px 0px',
                  },
                }}
              >
                <DrawerContainer style={{ alignItems: 'center' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Puller />
                  </div>
                  <div style={{ fontSize: '16px', fontWeight: 700, marginTop: '8px', padding: '12px 0px' }}>지속 시간</div>
                  <Divider sx={{ width: '100%' }} />
                  <SwiperTimePicker suppressMeridiem taskbox={selectedTaskbox} onChange={handleChangeTime} />
                  <Divider sx={{ width: '100%' }} />
                  <Button onClick={() => handleCloseDuration(true)} style={{ color: COLORS.gray500, margin: '24px 0px' }}>
                    지정 안함
                  </Button>
                </DrawerContainer>
              </SwipeableDrawer>
              <Drawer anchor="bottom" variant="persistent" open={multiSelectedIds.length > 0}>
                <DrawerContainer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px' }}>
                  {tabIndex === 0 && (
                    <Button
                      onClick={() => handleClickMultiSelectActions('MOVE_TO_TOMORROW')}
                      sx={{ width: '30%', height: '80px', display: 'flex', flexDirection: 'column', backgroundColor: COLORS.gray100, borderRadius: '8px' }}
                    >
                      <Icons.Tomorrow width={24} height={24} stroke={COLORS.gray600} />
                      <div style={{ color: COLORS.gray600, fontSize: '12px', marginTop: '4px' }}>다음 날</div>
                    </Button>
                  )}
                  {tabIndex === 0 && (
                    <Button
                      onClick={() => handleClickMultiSelectActions('MOVE_TO_LATER')}
                      sx={{ width: '30%', height: '80px', display: 'flex', flexDirection: 'column', backgroundColor: COLORS.gray100, borderRadius: '8px' }}
                    >
                      <Icons.Later width={24} height={24} stroke={COLORS.gray600} />
                      <div style={{ color: COLORS.gray600, fontSize: '12px', marginTop: '4px' }}>나중에</div>
                    </Button>
                  )}
                  {tabIndex === 1 && (
                    <Button
                      onClick={() => handleClickMultiSelectActions('MOVE_TO_TODAY')}
                      sx={{ width: '30%', height: '80px', display: 'flex', flexDirection: 'column', backgroundColor: COLORS.gray100, borderRadius: '8px' }}
                    >
                      <Icons.Sun width={24} height={24} stroke={COLORS.gray600} />
                      <div style={{ color: COLORS.gray600, fontSize: '12px', marginTop: '4px' }}>오늘로 보내기</div>
                    </Button>
                  )}
                  {tabIndex === 1 && (
                    <Button
                      onClick={() => handleClickMultiSelectActions('MERGE_TO_TASKGROUP')}
                      sx={{ width: '30%', height: '80px', display: 'flex', flexDirection: 'column', backgroundColor: COLORS.gray100, borderRadius: '8px' }}
                    >
                      <Icons.Merge width={21} height={21} stroke={COLORS.gray600} />
                      <div style={{ color: COLORS.gray600, fontSize: '12px', lineHeight: '18px', marginTop: '4px' }}>태스크 그룹으로 합치기</div>
                    </Button>
                  )}
                  <Button
                    onClick={() => handleClickMultiSelectActions('DELETE')}
                    sx={{ width: '30%', height: '80px', display: 'flex', flexDirection: 'column', backgroundColor: COLORS.negative2, borderRadius: '8px' }}
                  >
                    <Icons.Delete width={24} height={24} fill={COLORS.negative1} />
                    <div style={{ color: COLORS.negative1, fontSize: '12px', marginTop: '4px' }}>삭제</div>
                  </Button>
                </DrawerContainer>
              </Drawer>
              <MobileFooter active={tabIndex === 0}>
                <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
                  <Tab
                    className="icon-today"
                    icon={<Work />}
                    label="오늘"
                    style={{
                      minWidth: 106,
                      color: tabIndex === 0 ? COLORS.brand1 : COLORS.gray400,
                    }}
                  />
                  <Tab
                    icon={<Icons.Later width={30} height={30} stroke={tabIndex === 1 ? '#0039A7' : '#ABB0BF'} />}
                    label="나중에"
                    style={{
                      minWidth: 106,
                      color: tabIndex === 1 ? COLORS.brand1 : COLORS.gray400,
                    }}
                  />
                  <Tab
                    icon={<Avatar src={user.profileImage} sx={{ width: '30px', height: '30px' }} />}
                    label="프로필"
                    style={{
                      minWidth: 106,
                      color: tabIndex === 2 ? COLORS.brand1 : COLORS.gray400,
                    }}
                  />
                </Tabs>
              </MobileFooter>
            </div>
            {/* 태스크박스 삭제 창 */}
            <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}>
              <DialogTitle id="alert-dialog-title">
                <Fonts.Body1 style={{ color: COLORS.negative1 }}>
                  {tabIndex === 0 ? `태스크박스를 삭제하시겠습니까?` : `태스크를 삭제하시겠습니까?`}
                </Fonts.Body1>
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} style={{ color: COLORS.brand1 }}>
                  취소
                </Button>
                <Button onClick={handleClickDelete} style={{ color: COLORS.brand1 }}>
                  삭제
                </Button>
              </DialogActions>
            </Dialog>
          </MobileWrapper>
        )
      )}
    </Container>
  );
};

export default Mobile;
