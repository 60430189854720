import { SVGProps } from 'react';

export const FoldDeskIssueDetail = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_13505_26864)">
        <path d="M19 5V19" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M11 6L17 12L11 18" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 12L17 12" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_13505_26864">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
