import styled from '@emotion/styled';
import { Button, CircularProgress, Dialog } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { languageAtom } from 'atoms/language';
import { userAtom } from 'atoms/user';
import { Icons, Login } from 'components';
import Fonts from 'components/Fonts';
import SyncCalendarDialog from 'components/SyncCalendarDialog';
import { use } from 'i18next';
import { useAtom } from 'jotai';
import { set } from 'lodash';
import {
  addGoogleCalendarAccountV1AuthGoogleCalendarGet,
  getUserMeV1UsersMeGet,
  syncGoogleCalendarAndRelatedEventV1EventsSyncingGoogleCalendarGet,
  syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet,
} from 'queries';
import { OutUserDetail } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const handleLoginGoogle = useGoogleLogin(options);
  return (
    <Button
      variant="contained"
      onClick={handleLoginGoogle}
      sx={{
        width: '288px',
        height: '48px',
        borderRadius: '8px',
        boxShadow: 'none',
        color: COLORS.white,
        fontSize: '13px',
        fontWeight: 700,
        padding: '14px 16px',
        marginLeft: '16px',
        textTransform: 'none',
      }}
    >
      <Icons.GoogleCalendar style={{ marginRight: '4px' }} />
      {label}
    </Button>
  );
};

const ConnectGoogleCalendar = () => {
  const response = location.search;
  const state = response.split('state=')[1];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);
  const handleClickLater = () => {
    if (!user?.tutorialStatus && !localStorage.getItem('onboarding')) {
      navigate('/welcome');
    } else {
      navigate(`/task`);
    }
  };
  const [userData, setUserData] = useState<OutUserDetail | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [authFail, setAuthFail] = useState<string | null>(null);

  useEffect(() => {
    if (new URLSearchParams(location.search).get('state') === 'authFail') {
      setAuthFail('authFail');
    }
  }, [authFail]);

  useEffect(() => {
    const syncGoogleCalendarList = async () => {
      setIsSyncing(true);
      await syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet();
      setIsSyncing(false);
      navigate('/calendar');
    };
    if (state === 'success') {
      syncGoogleCalendarList();
    }
  }, [state]);

  useEffect(() => {
    const fetchUserData = async () => {
      setUserData(user ? user : await getUserMeV1UsersMeGet());
    };

    fetchUserData();
  }, []);

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google/calendar`,
    state: userData?.id,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight = headerRef.current?.clientHeight || 0;

  return (
    userData && (
      <Container style={{ opacity: isImageLoaded ? 1 : 0 }}>
        <Header ref={headerRef}>{t('calendar.connect.header')}</Header>
        <ConnectScrollWrapper headerHeight={headerHeight}>
          <ConnectContentWrapper>
            <ConnectTitle>{t('calendar.connect.title')}</ConnectTitle>
            <ConnectSubTitle>{t('calendar.connect.subtitle')}</ConnectSubTitle>
            <img
              src={require(`assets/images/connectCalendar.png`)}
              alt="google-calendar"
              style={{
                width: '60%',
                maxWidth: '827px',
                height: '458px',
                margin: '32px 0px',
              }}
              onLoad={() => setIsImageLoaded(true)} // 이미지 로드 완료 시 상태 변경
            />
          </ConnectContentWrapper>
          <ButtonWrapper>
            <Button
              variant="contained"
              onClick={handleClickLater}
              sx={{
                'width': '288px',
                'height': '48px',
                'backgroundColor': COLORS.white,
                'border': `1px solid ${COLORS.gray200}`,
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': COLORS.gray600,
                'fontSize': '13px',
                'fontWeight': 700,
                'padding': '14px 16px',
                'textTransform': 'none',
                '&:hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              {t('calendar.connect.later')}
            </Button>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogInButton options={googleLoginOptions} label={t('calendar.connect.complete')} />
            </GoogleOAuthProvider>
          </ButtonWrapper>
          {authFail && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
              <ConnectErrorWrapper>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    borderRadius: '50%',
                    backgroundColor: COLORS.negative1,
                    color: COLORS.white,
                    fontWeight: 'bold',
                  }}
                >
                  !
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Fonts.H3>{t('signin.access_denied')}</Fonts.H3>
                  <Fonts.Body1 style={{ textAlign: 'left', marginTop: '4px' }}> {t('calendar.connect.error')}</Fonts.Body1>
                </div>
              </ConnectErrorWrapper>
            </div>
          )}
        </ConnectScrollWrapper>
        <SyncCalendarDialog open={isSyncing} />
      </Container>
    )
  );
};

export default ConnectGoogleCalendar;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  width: 100%;
  background-color: ${COLORS.gray100};
  border-bottom: 1px solid ${COLORS.gray300};
  font-size: 20px;
  font-weight: 700;
  padding: 20px 24px;
`;

const ConnectScrollWrapper = styled.div<{ headerHeight: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px);
  overflow-y: scroll;
  padding: 40px 0px;
  ${hideScroll()}
`;

const ConnectContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConnectTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.25px;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 12px;
`;

const ConnectSubTitle = styled.div`
  font-size: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SyncingWrapper = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  border-radius: 8px;
`;

const SyncingText = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 100px;
`;

const ConnectErrorWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: ${COLORS.negative2};
`;
