import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import Fonts from 'components/Fonts';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  active: boolean;
}
export const ButtonSmall2 = ({ children, onClick, active }: Props) => (
  <Wrapper onClick={onClick} active={active}>
    <Fonts.H6>{children}</Fonts.H6>
  </Wrapper>
);

const Wrapper = styled.div<{ active: boolean }>`
  cursor: pointer;

  border-radius: 2px;
  padding: 4px 8px;
  background-color: ${(props) => (props.active ? COLORS.brand1 : COLORS.gray100)};
  border: 1px solid ${(props) => (props.active ? COLORS.brand1 : COLORS.gray300)};
  color: ${(props) => (props.active ? COLORS.white : COLORS.gray900)};
  cursor: pointer;
`;
