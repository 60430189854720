import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface TooltipProps {
  text: string;
}

export const Tooltip = ({ text }: TooltipProps) => {
  return (
    <Wrapper>
      <Fonts.Body2>{text}</Fonts.Body2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 272px;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  background-color: ${COLORS.white};
  color: ${COLORS.gray500};
  padding: 16px;
`;
