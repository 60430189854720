import { SVGProps } from 'react';

export const Stop = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8Z"
        stroke={props.stroke || '#D8204C'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8.93307V7.06641C10 6.69304 9.99975 6.50654 9.92708 6.36393C9.86317 6.23849 9.76157 6.13658 9.63613 6.07266C9.49352 6 9.30644 6 8.93307 6H7.06641C6.69304 6 6.50654 6 6.36393 6.07266C6.23849 6.13658 6.13658 6.23849 6.07266 6.36393C6 6.50654 6 6.69304 6 7.06641V8.93307C6 9.30644 6 9.49352 6.07266 9.63613C6.13658 9.76157 6.23849 9.86317 6.36393 9.92708C6.50654 9.99975 6.69304 10 7.06641 10H8.93307C9.30644 10 9.49352 9.99975 9.63613 9.92708C9.76157 9.86317 9.86317 9.76157 9.92708 9.63613C9.99975 9.49352 10 9.30644 10 8.93307Z"
        stroke={props.stroke || '#D8204C'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
