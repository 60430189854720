import styled from '@emotion/styled';
import { IconButton, TextField } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { osName } from 'react-device-detect';
import { useState } from 'react';
import { hideScroll } from 'styles/utils';
import { useTranslation } from 'react-i18next';

interface ShortcutProps {
  onClose?: () => void;
}

const Shortcut = ({ onClose }: ShortcutProps) => {
  const { t } = useTranslation();
  const os = osName;
  const shortcutList = [
    [
      { title: t('shortcut.key.basic_selection'), shortcut: [t('shortcut.common.click')] },
      { title: t('shortcut.key.quick_taskbox_selection'), shortcut: ['1', '~', '9'] },
      { title: t('shortcut.key.taskbox_selection_move'), shortcut: ['↑', ',', '↓'] },
      { title: t('shortcut.key.view_right_plan_tab'), shortcut: os === 'Windows' ? ['Alt', '+', '1'] : ['Option', '+', '1'] },
      { title: t('shortcut.key.view_right_note_tab'), shortcut: os === 'Windows' ? ['Alt', '+', '2'] : ['Option', '+', '2'] },
      { title: t('shortcut.key.view_right_later_tab'), shortcut: os === 'Windows' ? ['Alt', '+', '3'] : ['Option', '+', '3'] },
      { title: t('shortcut.key.toggle_view_daily_weekly'), shortcut: ['D', '/', 'W'] },
      { title: t('shortcut.key.view_today_journal'), shortcut: ['Shift', '+', 'T'] },
      { title: t('shortcut.key.toggle_subtask'), shortcut: os === 'Windows' ? ['Alt', '+', 'F'] : ['Option', '+', 'F'] },
    ],
    [
      {
        title: t('shortcut.key.multi_select_task'),
        shortcut: os === 'Windows' ? ['Crtl', '+', t('shortcut.common.click')] : ['Cmd', '+', t('shortcut.common.click')],
      },
      { title: t('shortcut.key.continuous_select_task'), shortcut: [t('shortcut.common.group'), '+', 'Shift', '+', '↑', ',', '↓'] },
      { title: t('shortcut.key.merge_group'), shortcut: [t('shortcut.common.group'), '+', 'G'] },
      {
        title: t('shortcut.common.delete'),
        shortcut: os === 'Windows' ? ['Delete'] : ['Cmd', '+', '←'],
        shortcutWithEdit: os === 'Windows' ? ['Shift', '+', 'Delete'] : ['Shift', '+', 'Cmd', '+', '←'],
      },
    ],
    [
      { title: t('shortcut.key.input_today_task'), shortcut: ['/'] },
      {
        title: t('shortcut.key.complete_task'),
        shortcut: [t('shortcut.common.select'), '+', 'V'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'V'] : ['Option', '+', 'Shift', '+', 'V'],
      },
      { title: t('shortcut.key.toggle_journal'), shortcut: os === 'Windows' ? ['Alt', '+', 'F'] : ['Option', '+', 'F'] },
      {
        title: t('shortcut.key.taskbox_focus_mode'),
        shortcut: [t('shortcut.common.select'), '+', 'F'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'F'] : ['Option', '+', 'Shift', '+', 'F'],
      },
      {
        title: t('shortcut.key.add_modify_time_date_taskbox'),
        shortcut: [t('shortcut.common.select'), '+', 'T'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'T'] : ['Option', '+', 'Shift', '+', 'T'],
      },
      {
        title: t('shortcut.key.send_taskbox_next_day'),
        shortcut: ['T', '그리고', 'N'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'T', '그리고', 'N'] : ['Option', '+', 'Shift', '+', 'T', '그리고', 'N'],
      },
      {
        title: t('shortcut.key.send_taskbox_next_monday'),
        shortcut: ['T', '그리고', 'M'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'T', '그리고', 'M'] : ['Option', '+', 'Shift', '+', 'T', '그리고', 'M'],
      },
      {
        title: t('shortcut.key.send_taskbox_later'),
        shortcut: ['T', '그리고', 'L'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'T', '그리고', 'L'] : ['Option', '+', 'Shift', '+', 'T', '그리고', 'L'],
      },
      {
        title: t('shortcut.key.send_subtask_later'),
        shortcut: ['L'],
        shortcutWithEdit: os === 'Windows' ? ['Alt', '+', 'Shift', '+', 'L'] : ['Option', '+', 'Shift', '+', 'L'],
      },
    ],
    [{ title: t('shortcut.key.input_task_later'), shortcut: os === 'Windows' ? ['Ctrl', '+', '/'] : ['Cmd', '+', '/'] }],
  ];

  const [filteredShortcutList, setFilteredShortcutList] = useState<({ title: string; shortcut: string[] } | { title: string; shortcut: string[][] })[][]>();

  const handleFilterShortcut = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      setFilteredShortcutList([]);
    } else {
      const filteredShortcutList = shortcutList.map((shortcut) => {
        return shortcut.filter((item) => item.title.toLowerCase().startsWith(value));
      });

      setFilteredShortcutList(filteredShortcutList);
    }
  };

  return (
    <Container>
      <Header>
        <div>{t('shortcut.common.shortcut')}</div>
        <IconButton onClick={onClose} sx={{ 'padding': '0px', ':hover': { backgroundColor: COLORS.white } }}>
          <Icons.Close width={20} height={20} />
        </IconButton>
      </Header>
      <SearchWrapper>
        <TextField
          fullWidth
          placeholder={t('shortcut.input.search')}
          onChange={handleFilterShortcut}
          InputProps={{
            sx: { height: '36px', fontSize: '13px', borderRadius: '8px' },
            startAdornment: (
              <div style={{ marginRight: '4px' }}>
                <Icons.Search width={16} height={16} />
              </div>
            ),
          }}
        />
        <SearchTextWrapper>
          <Icons.ShortcutWithText />
          <div style={{ margin: '0px 4px' }}>
            <Icons.ArrowRightSmall fill={COLORS.gray500} />
          </div>
          <div style={{ fontSize: '12px' }}>{t('shortcut.input.guide')}</div>
        </SearchTextWrapper>
      </SearchWrapper>
      <ShortcutWrapper>
        {filteredShortcutList?.map((v) => v.length > 0).some((v) => v)
          ? filteredShortcutList!.map((shortcut, index) => (
              <>
                {shortcut.length > 0 && (
                  <div style={{ marginTop: '24px' }} key={index}>
                    <ShortcutTitleWrapper>
                      <div style={{ fontSize: '16px', fontWeight: 700, marginRight: '8px' }}>
                        {index === 0 ? t('shortcut.common.basic') : t('shortcut.common.task')}
                      </div>
                      <div style={{ fontSize: '13px', color: COLORS.gray500 }}>
                        {index === 1 ? t('shortcut.title.common') : index === 2 ? t('shortcut.title.task') : index === 3 ? t('shortcut.title.later') : ''}
                      </div>
                    </ShortcutTitleWrapper>
                    <ShortcutContentWrapper>
                      {shortcut.map((item, index) => (
                        <div style={{ padding: '8px', borderBottom: index === shortcut.length - 1 ? 'none' : `1px solid ${COLORS.gray100}` }}>
                          <div>{item.title}</div>
                        </div>
                      ))}
                    </ShortcutContentWrapper>
                  </div>
                )}
              </>
            ))
          : shortcutList.map((shortcut, index) => (
              <div style={{ marginTop: '24px' }} key={index}>
                <ShortcutTitleWrapper>
                  <div style={{ fontSize: '16px', fontWeight: 700, marginRight: '8px' }}>
                    {index === 0 ? t('shortcut.common.basic') : t('shortcut.common.task')}
                  </div>
                  <div style={{ fontSize: '13px', color: COLORS.gray500 }}>
                    {index === 1 ? t('shortcut.title.common') : index === 2 ? t('shortcut.title.task') : index === 3 ? t('shortcut.title.later') : ''}
                  </div>
                </ShortcutTitleWrapper>
                <ShortcutContentWrapper>
                  {shortcut.map((item, index) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        padding: '8px',
                        borderBottom: index === shortcut.length - 1 ? 'none' : `1px solid ${COLORS.gray100}`,
                      }}
                      key={index}
                    >
                      <ShortcutTitle>{item.title}</ShortcutTitle>
                      <ShortcutDescription>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.shortcut.map((v) => (
                            <>
                              {(shortcutList.findIndex((v) => v === shortcut) === 0 && v === '/') ||
                              v === ',' ||
                              v === 'or' ||
                              v === '+' ||
                              v === t('shortcut.common.and') ||
                              v === '~' ? (
                                <div style={{ marginLeft: '4px' }}>{v}</div>
                              ) : (
                                <KeyboardButtonRect>{v}</KeyboardButtonRect>
                              )}
                            </>
                          ))}
                        </div>

                        {item.shortcutWithEdit && (
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                            <Icons.ShortcutWithText />
                            {item.shortcutWithEdit &&
                              item.shortcutWithEdit.map((v) => (
                                <>
                                  {v === ',' || v === 'or' || v === '+' || v === t('shortcut.common.and') ? (
                                    <div style={{ marginLeft: '4px' }}>{v}</div>
                                  ) : (
                                    <KeyboardButtonRect>{v}</KeyboardButtonRect>
                                  )}
                                </>
                              ))}
                          </div>
                        )}
                      </ShortcutDescription>
                    </div>
                  ))}
                </ShortcutContentWrapper>
              </div>
            ))}
      </ShortcutWrapper>
    </Container>
  );
};

export default Shortcut;

const Container = styled.div`
  width: 462px;
  padding: 24px;
  overflow-y: scroll;
  ${hideScroll()}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
`;

const SearchWrapper = styled.div`
  margin-top: 8px;
`;

const SearchTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: 8px;
`;

const ShortcutWrapper = styled.div``;

const ShortcutTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const ShortcutContentWrapper = styled.div`
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  font-size: 12px;
  padding: 4px 8px;
`;

const ShortcutTitle = styled.div`
  margin-top: 3px;
`;

const ShortcutDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.gray500};
`;

const KeyboardButtonRect = styled.div`
  font-size: 10px;
  border-radius: 4px;
  background-color: ${COLORS.gray100};
  padding: 4px 6px;
  margin-left: 4px;
`;
