import ReactGA from 'react-ga4';

interface GAEventTriggerProps {
  action: string;
  category: string;
  label?: string;
  value?: number;
}

export const GAEventTrigger = ({ action, category, label, value }: GAEventTriggerProps) => {
  ReactGA.event({
    action: action,
    category: category,
    label: label,
    value: value,
  });
};
