import styled from '@emotion/styled';
import { Button, CircularProgress, Dialog, Divider, Skeleton, Stack } from '@mui/material';
import { COLORS } from 'styles/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Await, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { createRecordV1RecordPost, deleteRecordV1RecordRecordIdDelete, getAllRecordV1RecordGet, getARandomRecordV1RecordRandomGet } from 'queries';
import { InCreateRecord, OutRecord } from 'queries/model';
import dayjs from 'lib/dayjs';
import { Icons } from 'components';
import { ReflectionWriting } from './Writing';
import { ReflectionTemplate } from 'components/ReflectionTemplate/ReflectionTemplate';
import toast from 'react-hot-toast';
import { hideScroll } from 'styles/utils';
import { useEventListener } from '@react-hookz/web';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

const ScrollContainer = styled.div<{ suppressScroll: boolean }>`
  width: 100%;
  overflow-y: scroll;
  ${hideScroll}
  display: flex;
  flex-direction: column;

  ${({ suppressScroll }) =>
    suppressScroll &&
    `
    justify-content: center;
    align-items: center;
  `};
`;

const Container = styled.div`
  width: 100%;
`;

const IntroWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
`;

const TitleWrapper = styled.p`
  font-size: 32px;
  font-weight: 700;
`;

const SubTitleWrapper = styled.p`
  font-size: 20px;
`;

const InfoWrapper = styled.div`
  color: ${COLORS.gray600};
  font-size: 13px;
  line-height: 20px;
  display: flex;
`;

const ImageWrapper = styled.div`
  width: 824px;
  background-color: ${COLORS.gray200};
  border-radius: 16px;
`;

const ReflectionIntro = () => {
  const [language] = useAtom(languageAtom);
  const [render, setRender] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [date, setDate] = useState(new Date());
  const [randomRecord, setRandomRecord] = useState<OutRecord | null>(null);
  const [records, setRecords] = useState<OutRecord[]>([]);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isRenderImg, setIsRenderImg] = useState(false);
  const [isReflectionDialogOpen, setIsReflectionDialogOpen] = useState(false);
  const refScrollContainer = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [suppressScroll, setSuppressScroll] = useState(false);
  const [record, setRecord] = useState<OutRecord>({
    date: dayjs(date).format('YYYY-MM-DD'),
    id: '',
    status: 'ACTIVE',
    createdAt: '',
    updatedAt: '',
    comboHit: 0,
    content: {},
  });

  useEffect(() => {
    const handleLoad = async () => {
      const datas = await fetchRecords(dayjs().toDate());
      await fetchRandomRecord();

      if (datas) {
        setDate(dayjs().toDate());
        setIsLoaded(true);
        setRender(true);
      }
    };

    handleLoad();
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = require('assets/images/reflection_main.png');
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, []);

  useEffect(() => {
    handleSuppresScroll();
  }, [randomRecord]);

  useEventListener(
    window,
    'resize',
    () => {
      handleSuppresScroll();
    },
    { passive: true },
  );

  const handleCreateRecord = async () => {
    if (!record) return;
    const inCreateRecord: InCreateRecord = {
      content: record.content,
      date: record.date,
    };
    const newRecord = await createRecordV1RecordPost(inCreateRecord);
    setRecords([...records, { ...newRecord }]);
    if (!randomRecord) fetchRandomRecord();
    localStorage.setItem('reflection-data', JSON.stringify(true));
    setIsReflectionDialogOpen(false);
  };

  const fetchRecords = async (date: Date) => {
    const recordDatas = await getAllRecordV1RecordGet({
      startDate: dayjs(date).startOf('month').format('YYYY-MM-DD'),
      endDate: dayjs(date).endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    });

    if (recordDatas) {
      const filterderRecords = records.filter((record) => !dayjs(record.date).startOf('month').isSame(dayjs(date).startOf('month')));
      const sortedRecords = [...filterderRecords, ...recordDatas].sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
      setRecords(sortedRecords);
    }

    return recordDatas;
  };

  const fetchRandomRecord = async () => {
    const randomRecord = await getARandomRecordV1RecordRandomGet();
    setRandomRecord(randomRecord.id ? randomRecord : null);
    if (randomRecord.id) localStorage.setItem('reflection-data', JSON.stringify(true));
  };

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };

  const handleMonthChange = (date: Date) => {
    fetchRecords(date);
  };

  const handleChangeRecord = (record: OutRecord) => {
    setRecord(record);
  };

  const handleDeleteRecord = async (record: OutRecord) => {
    try {
      await deleteRecordV1RecordRecordIdDelete(record.id);
      toast.success(language === 'ko' ? '회고가 삭제되었습니다.' : 'Reflection deleted.');
      const records = await fetchRecords(dayjs(record.date).toDate());
      if (records.length === 0) {
        fetchRandomRecord();
        localStorage.setItem('reflection-data', JSON.stringify(false));
      }
      setRecord({
        date: dayjs(record.date).format('YYYY-MM-DD'),
        id: '',
        status: 'ACTIVE',
        createdAt: '',
        updatedAt: '',
        comboHit: 0,
        content: {},
      });
      setDate(dayjs(record.date).toDate());
    } catch (e) {
      toast.error(language === 'ko' ? '삭제에 실패했습니다.' : 'Failed to delete.');
    }
  };

  const handleSuppresScroll = () => {
    const scrollContainer = refScrollContainer.current;
    const container = refContainer.current;

    if (!scrollContainer || !container) return;
    const scrollContainerHeight = scrollContainer.clientHeight;
    const containerHeight = container.clientHeight;

    if (scrollContainerHeight - containerHeight > 40) {
      setSuppressScroll(true);
    } else {
      setSuppressScroll(false);
    }
  };

  const reflectionData = localStorage.getItem('reflection-data');
  const showIntro = !randomRecord;

  return (
    <ScrollContainer ref={refScrollContainer} suppressScroll={suppressScroll}>
      <Container ref={refContainer} style={reflectionData === 'true' ? { height: '100%' } : {}}>
        {render ? (
          showIntro ? (
            isImgLoaded && (
              <IntroWrapper style={{ height: '100%', justifyContent: 'center', opacity: isRenderImg ? 1 : 0 }}>
                <TitleWrapper>{language === 'ko' ? `성공하는 사람들의 습관, '회고'` : `The habits of successful people, 'Reflection'`}</TitleWrapper>
                <SubTitleWrapper style={{ marginTop: 8 }}>
                  {language === 'ko' ? 'SLASH와 함께 매일 1%씩의 성장을 경험해보세요' : 'Experience 1% growth every day with SLASH'}
                </SubTitleWrapper>
                <div style={{ marginTop: 24 }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ paddingLeft: 4, paddingRight: 4, borderRadius: '8px' }}
                    style={{ fontSize: 16, fontWeight: 700, textTransform: 'none' }}
                    onClick={() => setIsReflectionDialogOpen(true)}
                  >
                    <Icons.Edit width={20} height={20} stroke={COLORS.white} style={{ marginRight: '4px' }} />
                    {language === 'ko' ? '회고 작성하기' : 'Write a reflection'}
                  </Button>
                </div>
                <InfoWrapper style={{ marginTop: 24 }}>
                  <InfoOutlinedIcon style={{ width: 16, height: 16, marginTop: 1 }} />
                  <div style={{ marginLeft: 4 }}>
                    {language === 'ko' ? (
                      <>
                        &apos;Keep, Problem, Try&apos; 템플릿을 먼저 지원합니다.
                        <br />
                        이후 더 많은 템플릿을 만나볼 수 있어요.
                      </>
                    ) : (
                      <>
                        We currently support the &apos;Keep, Problem, Try&apos; template.
                        <br />
                        More templates will be available soon.
                      </>
                    )}
                  </div>
                </InfoWrapper>
                <ImageWrapper style={{ marginTop: 32 }}>
                  <img
                    src={require(language === 'ko' ? 'assets/images/reflection_main.png' : 'assets/images/reflection_main_en.png')}
                    alt="reflection_main"
                    onLoad={() => setIsRenderImg(true)}
                  />
                </ImageWrapper>
                <Dialog
                  open={isReflectionDialogOpen}
                  onClose={() => {
                    if (record.content && (record.content.memo || record.content.keep || record.content.problem || record.content.try)) {
                      handleCreateRecord();
                    } else {
                      setIsReflectionDialogOpen(false);
                    }
                  }}
                  maxWidth="md"
                >
                  <div style={{ height: '726px', width: '790px' }}>
                    <ReflectionTemplate
                      readOrcreateOrUpdate={'create'}
                      record={record}
                      padding="0px 80px 32px 80px"
                      onChange={handleChangeRecord}
                      closeDialog={() => {
                        setIsReflectionDialogOpen(false);
                      }}
                      createOrUpdateRecord={handleCreateRecord}
                    />
                  </div>
                </Dialog>
              </IntroWrapper>
            )
          ) : (
            <ReflectionWriting
              date={date}
              records={records}
              onChangeDate={handleChangeDate}
              onDelete={handleDeleteRecord}
              onFetchRecords={fetchRecords}
              onMonthChange={handleMonthChange}
            />
          )
        ) : reflectionData === 'false' && showIntro ? (
          <Stack spacing={1} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Skeleton variant="rounded" animation="wave" width={320} height={32} style={{ marginTop: '20px' }} />
            <Skeleton variant="rounded" animation="wave" width={260} height={32} />
            <Skeleton variant="rounded" animation="wave" width={450} height={24} style={{ marginTop: '12px' }} />
            <Skeleton variant="rounded" animation="wave" width={98} height={40} style={{ marginTop: '32px' }} />
            <Skeleton variant="rounded" animation="wave" width={830} height={460} style={{ marginTop: '32px' }} />
          </Stack>
        ) : (
          <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '28px 24px' }}>
              <Skeleton variant="rounded" animation="wave" width={131} height={24} style={{ marginBottom: 28 }} />
              <Skeleton variant="rounded" animation="wave" width={320} height={340} />
            </div>
            <Divider orientation="vertical" sx={{ height: '100%' }} />
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
              <div style={{ width: 630, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24 }}>
                <Skeleton variant="rounded" animation="wave" width={148} height={24} style={{ marginRight: 12 }} />
                <div style={{ display: 'flex' }}>
                  <Skeleton variant="rounded" animation="wave" width={32} height={32} />
                  <Skeleton variant="rounded" animation="wave" width={47} height={32} style={{ margin: '0px 4px' }} />
                  <Skeleton variant="rounded" animation="wave" width={32} height={32} />
                </div>
              </div>
              <Skeleton variant="rounded" animation="wave" width={630} height={378} />
            </div>
          </div>
        )}
      </Container>
    </ScrollContainer>
  );
};

export default ReflectionIntro;
