import { SVGProps } from 'react';

export const UserAlarm = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M14.999 17.002V18.002C14.999 19.6588 13.6559 21.002 11.999 21.002C10.3422 21.002 8.99902 19.6588 8.99902 18.002V17.002M14.999 17.002H8.99902M14.999 17.002H18.5895C18.972 17.002 19.1642 17.002 19.3191 16.9497C19.615 16.8499 19.8465 16.6176 19.9463 16.3217C19.9987 16.1662 19.9987 15.9734 19.9987 15.5879C19.9987 15.4192 19.9986 15.3348 19.9854 15.2544C19.9604 15.1024 19.9014 14.9583 19.8116 14.8332C19.7641 14.7671 19.7038 14.7067 19.5848 14.5878L19.1953 14.1982C19.0696 14.0726 18.999 13.9021 18.999 13.7244V10.002C18.999 6.13595 15.865 3.00194 11.999 3.00195C8.13304 3.00196 4.99902 6.13597 4.99902 10.002V13.7244C4.99902 13.9021 4.92827 14.0726 4.80259 14.1982L4.41309 14.5877C4.29379 14.707 4.23406 14.767 4.18652 14.8332C4.09668 14.9583 4.03717 15.1024 4.01222 15.2544C3.99902 15.3348 3.99902 15.4192 3.99902 15.5879C3.99902 15.9735 3.99902 16.1662 4.05148 16.3217C4.15127 16.6176 4.38382 16.8499 4.67969 16.9497C4.83458 17.002 5.02604 17.002 5.40858 17.002H8.99902M18.0176 2.01562C19.3968 3.05494 20.4833 4.43372 21.1714 6.01769M5.98099 2.01562C4.60177 3.05494 3.51522 4.43372 2.82715 6.01769"
        stroke="#1F2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
