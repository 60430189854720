import styled from '@emotion/styled';
import { DATE_FORMAT_4, convertDatetimeFormat, getToday } from 'utils/datetimeFormat';
import defaultProfile from 'assets/images/default-profile.png';
import { COLORS } from '../../styles/constants';
import Fonts from '../../components/Fonts';

export interface UserType {
  id?: string;
  name: string;
  email: string;
  // profile: string | null;
  profileImage: string | null;
  permission?: string;
}

interface ListProps {
  title: string;
  message: string;
  createdTime: string;
  sender: UserType;
  required: boolean;
  onClick: () => void;
}

export const AlarmListItem = ({ title, message, createdTime, required = false, sender, onClick }: ListProps) => {
  const isToday = convertDatetimeFormat(createdTime, DATE_FORMAT_4) === convertDatetimeFormat(getToday(), DATE_FORMAT_4);

  return (
    <Wrapper onClick={onClick} required={required}>
      <>
        {required ||
          (isToday && (
            <AlarmTop>
              {required && <AlarmTopBox>필수</AlarmTopBox>}
              {isToday && <AlarmTopBox>오늘 진행</AlarmTopBox>}
            </AlarmTop>
          ))}
        <AlarmMid>
          <div>
            <Fonts.Body2 className="notification-sender">{sender.name}</Fonts.Body2>
            <Fonts.H4 className="pb-4">{title}</Fonts.H4>
          </div>

          {sender.profileImage ? (
            <img
              className="profile-img"
              src={sender.profileImage}
              onError={(e: any) => {
                e.target.src = defaultProfile;
              }}
            />
          ) : (
            <div className="no-profile-img" />
          )}
        </AlarmMid>
        <AlarmBot>
          <Fonts.Blockquote
            className="text-gray700"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {message}
          </Fonts.Blockquote>
          <Fonts.Badge className="text-gray400">{createdTime}</Fonts.Badge>
        </AlarmBot>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ required: boolean }>`
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  cursor: pointer;

  .notification-sender {
    color: ${COLORS.gray500};
  }

  .notification-title {
    padding-bottom: 16p;
  }

  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .no-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${COLORS.gray400};
  }
`;

const AlarmTop = styled.div`
  color: ${COLORS.white};
  display: flex;
  margin-bottom: 16px;
  gap: 4px;
`;

const AlarmTopBox = styled.div`
  box-sizing: border-box;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  background-color: ${COLORS.sub4};
  font-size: 10px;
  width: fit-content;
  padding: 0px 8px;

  display: flex;
  align-items: center;
`;

const AlarmMid = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AlarmBot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
