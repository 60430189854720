import React, { PropsWithChildren, ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { meAtom, workspacesAtom } from 'atoms/user';
import { COLORS, FontSize } from 'styles/constants';
import { useAuthAction } from 'hooks/useAuthAction';
import { Avatar, Divider, Menu, Popover } from '@mui/material';
import { Icons } from 'components';
import { Link } from 'react-router-dom';
import { languageAtom } from 'atoms/language';

const Container = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

const EmptyProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${COLORS.brand1};
`;

const PopoverContent = styled.div`
  position: absolute;
  left: 16px;
  top: 72px;
  width: 300px;
  background: var(--white);
  border: 1px solid var(--gray-200);
  box-shadow: 0px 8px 16px var(--shadow-100);
  border-radius: 8px;
  padding: 8px 0px;
`;

const PopoverContentMenuWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  cursor: pointer;
`;

const PopoverContentIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-right: 8px;
`;

const PopoverContentTextWrapper = styled.span`
  color: var(--gray-900);
  font-size: ${FontSize.h3};
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--gray-200);
`;

const MenuWrapper = styled.div``;

const MenuContentWrapper = styled.div`
  width: 124px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: var(--gray-100);
  }
`;

const MenuContentText = styled.div`
  margin-left: 8px;
  font-size: 12px;
`;

const PopoverContentMenu = ({ icon, text, children }: { icon?: ReactNode; text?: string } & PropsWithChildren) => {
  return (
    <PopoverContentMenuWrapper>
      {icon && <PopoverContentIconWrapper>{icon}</PopoverContentIconWrapper>}
      {text ? <PopoverContentTextWrapper>{text}</PopoverContentTextWrapper> : <>{children}</>}
    </PopoverContentMenuWrapper>
  );
};

const Profile = () => {
  const [language] = useAtom(languageAtom);
  const [me] = useAtom(meAtom);
  const { logout } = useAuthAction();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  return (
    <>
      <Container>
        <div onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ProfileImage>
            {me?.name ? (
              me.profileImage ? (
                <img src={me?.profileImage} />
              ) : (
                <Avatar
                  {...stringAvatar(me.name)}
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
              )
            ) : (
              <EmptyProfileImage />
            )}
          </ProfileImage>
        </div>
        {/* <Popover
          isOpen={isPopoverOpen}
          align="end"
          padding={100}
          reposition={true}
          positions={['bottom']}
          containerStyle={{ zIndex: '1000' }}
          content={
            <PopoverContent>
              <PopoverContentMenu icon={<img src={me?.profileImage} />}>
                <b>{workspaceList[0] && workspaceList[0].name}</b>
              </PopoverContentMenu>
              <PopoverContentMenu>워크스페이스 설정</PopoverContentMenu>
              <HorizontalDivider />
              <PopoverContentMenu icon={<img src={me?.profileImage} />}>
                <b>{me.name}</b>
              </PopoverContentMenu>
              <PopoverContentMenu>개인정보 설정</PopoverContentMenu>
              <HorizontalDivider />
              <PopoverContentMenu>사용자 관리</PopoverContentMenu>
              <div onClick={() => logout('/signIn')}>
                <PopoverContentMenu>로그 아웃</PopoverContentMenu>
              </div>
            </PopoverContent>
          }
          clickOutsideCapture={true}
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <></>
        </Popover> */}
        <Popover
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          sx={{ marginLeft: '8px' }}
        >
          <MenuWrapper>
            <div style={{ padding: '8px' }}>
              <Link to={'/settings'}>
                <MenuContentWrapper>
                  <Icons.AccountSetting />
                  <MenuContentText>{language === 'ko' ? '설정' : 'Settings'}</MenuContentText>
                </MenuContentWrapper>
              </Link>
              <Link to={'/membership'}>
                <MenuContentWrapper>
                  <Icons.Membership />
                  <MenuContentText>{language === 'ko' ? '멤버십' : 'Membership'}</MenuContentText>
                </MenuContentWrapper>
              </Link>
              <MenuContentWrapper onClick={() => logout('/signIn')}>
                <Icons.LogOut />
                <MenuContentText>{language === 'ko' ? '로그아웃' : 'Log out'}</MenuContentText>
              </MenuContentWrapper>
            </div>
            <Divider />
            {language === 'ko' && (
              <div style={{ padding: '8px' }}>
                <Link to={'/m/home'}>
                  <MenuContentWrapper>
                    <Icons.Mobile />
                    <MenuContentText>모바일로 보기</MenuContentText>
                  </MenuContentWrapper>
                </Link>
              </div>
            )}
          </MenuWrapper>
        </Popover>
      </Container>
    </>
  );
};

export default Profile;
