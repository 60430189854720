import { SVGProps } from 'react';

export const Bulb = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 19H10M7 1C3.68629 1 1 3.68629 1 7C1 8.2145 1.36084 9.34472 1.98117 10.2893C2.93507 11.7418 3.41161 12.4676 3.47352 12.5761C4.02428 13.541 3.92287 13.2007 3.99219 14.3096C3.99998 14.4342 4 14.6229 4 15C4 15.5523 4.44772 16 5 16L9 16C9.55229 16 10 15.5523 10 15C10 14.6229 10 14.4342 10.0078 14.3096C10.0771 13.2007 9.97511 13.541 10.5259 12.5761C10.5878 12.4676 11.0651 11.7418 12.019 10.2893C12.6394 9.34472 13.0002 8.2145 13.0002 7C13.0002 3.68629 10.3137 1 7 1Z"
        stroke="#3E4046"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
