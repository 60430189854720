import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Icons } from 'components';
import { AppMemoModelOutMemo, OutCategory, OutLink, OutTaskboxDetailResponseMemo, WorkSectionType } from 'queries/model';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import InboxContextMenuPopover, { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { css } from '@emotion/react';
import { useClickOutside, useEventListener } from '@react-hookz/web';
import { setCaretToEnd } from 'utils/caret';
import dayjs from 'lib/dayjs';
import { categoryColors } from 'constants/categoryColor';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 32px;

  :hover {
    .drag-handle {
      opacity: 0.4;
    }
  }
`;

const TaskWrapper = styled.div<{ groupSelected?: boolean; selected?: boolean; highlight?: boolean; editing?: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 20px;
  padding: 8px 16px;
  align-items: flex-start;
  background-color: ${(props) => (props.groupSelected ? COLORS.sub3 : COLORS.white)};
  border: 1px solid ${(props) => (props.groupSelected || props.selected ? COLORS.gray300 : COLORS.gray200)};
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  :focus-within {
    border: 1px solid ${COLORS.gray300};
  }

  :hover {
    cursor: ${(props) => (props.editing ? 'text' : 'pointer')};
    box-shadow: 0px 4px 6px 0px rgba(151, 153, 158, 0.16);
  }

  ${(props) =>
    props.highlight &&
    css`
      border: 1px solid ${COLORS.gray600};
    `}
`;

const TaskItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  :hover .task-more-btn {
    opacity: 1;
  }
`;

const TaskItemCheckboxWrapper = styled.div`
  flex: 0 0 24px;
  display: flex;
`;

const TaskItemContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 13px;
`;

const TaskItemButtonWrapper = styled.div`
  flex: 0 0 20px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const TaskItemCollapseButtonWrapper = styled.div`
  flex: 0 0 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
`;

const SubTaskItemWrapper = styled.div<{ groupSelected?: boolean }>`
  width: 100%;
  align-items: center;
  min-height: 28px;
  padding: 0px 8px;
  border-radius: 8px;
  background-color: ${(props) => (props.groupSelected ? COLORS.sub3 : COLORS.white)};
  display: flex;
  border: 1px solid transparent;

  :hover > div {
    opacity: 1;
  }
`;

const SubTaskContentWrapper = styled.div<{ groupSelected?: boolean }>`
  flex: 1;
  overflow: hidden;
  font-size: 13px;
  display: flex;
  align-items: center;
  /* min-height: 20px; */
`;

const SubTaskItemButtonWrapper = styled.div`
  flex: 0 0 24px;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const EditableTextField = styled.div<{ expanded?: boolean }>`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;

  ${(props) =>
    !props.expanded &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

const TaskboxPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;
`;

const DragHandleIcon = styled.div<{ taskHeight?: number }>`
  position: absolute;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskHeight! / 2 - 10}px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

export type Task = {
  id: string;
  type?: string;
  title?: string;
  tasks?: any[];
  deadline?: string | null;
  category?: OutCategory | null;
  links?: OutLink[];
  memo?: OutTaskboxDetailResponseMemo;
};

export interface TaskItemProps {
  value: Task;
  selected?: boolean;
  groupSelected?: boolean;
  draggable?: boolean;
  editable?: boolean;
  hidden?: boolean;
  highlight?: boolean;
  popoverMenus?: InboxContextMenuType[];
  isGrouping: boolean;
  dragIndex?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onClickContext?: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onChangeTask?: (
    id: string,
    type: string,
    params: {
      content?: string;
      done?: boolean;
      workSectionType?: WorkSectionType;
      dueDate?: string;
    },
  ) => void;
  onChangeTitle?: (id: string, type: string, title: string) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseCalendar?: () => void;
}

const TaskItem = (props: TaskItemProps) => {
  const {
    value,
    selected,
    groupSelected,
    draggable,
    editable,
    hidden,
    highlight,
    popoverMenus,
    isGrouping,
    dragIndex,
    onClick,
    onClickContext,
    onDragStart,
    onDrag,
    onDragEnd,
    onChangeTask,
    onChangeTitle,
    onClickContextMenu,
  } = props;
  const [editing, setEditing] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [contextMenuOwnerId, setContextMenuOwnerId] = useState<string | undefined>();
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();
  const [isMetaKeyDown, setMetaKeyDown] = useState(false);
  const [isHover, setHover] = useState(false);
  const refTaskItem = useRef<HTMLDivElement>(null);
  const [taskHeight, setTaskHeight] = useState<number | undefined>();

  useEffect(() => {
    setCollapse(selected ? true : false);
  }, [selected]);

  useEffect(() => {
    setTaskHeight(refTaskItem.current?.getBoundingClientRect().height);
  }, [value]);

  useEventListener(
    window,
    'keydown',
    (event: React.MouseEvent) => {
      setMetaKeyDown(event.metaKey || event.ctrlKey);
    },
    { passive: true },
  );

  useEventListener(
    window,
    'keyup',
    (event: React.MouseEvent) => {
      setMetaKeyDown(event.metaKey || event.ctrlKey);
    },
    { passive: true },
  );

  useClickOutside(refTaskItem, () => {
    if (editing) setEditing(false);
  });

  const handleClickTask = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e, value.id);
  };

  const handleClickContext = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClickContext?.(e, value.id);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    onDragStart?.(e, value.id);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDrag?.(e);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    onDragEnd?.(e);
  };

  const handleClickTextField = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) return;
    if (!editable) return;

    // e.stopPropagation();
  };

  const handleClickSubTaskTextField = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) return;
    if (!editable) return;

    // e.stopPropagation();
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    if (e.ctrlKey || e.metaKey) return;
    if (!editable) return;

    e.stopPropagation();
    setEditing(true);

    if (value.type === 'taskbox' && value.tasks && value.tasks?.length > 0) setCollapse(true);

    const targetInput = refTaskItem.current?.querySelector(`[data-id="${id}"]`) as HTMLElement;
    setTimeout(() => targetInput && setCaretToEnd(targetInput), 50);
  };

  const handleKeydownTextField = async (e: React.KeyboardEvent<HTMLElement>, id: string) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const content = e.currentTarget.textContent || '';
      onChangeTask?.(id, value.type!, { content: `${content}`.trim() });
      setEditing(false);
    }
  };

  const handleKeydownTitleTextField = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const content = e.currentTarget.textContent || '';
      onChangeTitle?.(value.id, value.type!, `${content}`.trim());
      setEditing(false);
    }
  };

  const handleBlurTitleTextField = (e: React.FocusEvent<HTMLElement>) => {
    if (!editable) return;

    const content = e.currentTarget.textContent || '';
    onChangeTitle?.(value.id, value.type!, `${content}`.trim());
    window?.getSelection()?.removeAllRanges();
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLElement>, id: string) => {
    if (!editable) return;

    const content = e.currentTarget.textContent || '';
    onChangeTask?.(id, value.type!, { content: `${content}`.trim() });
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    setContextMenuOwnerId(id);
    setContextMenuPopover(e.currentTarget);
  };

  const handleClickContextMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickContextMenu?.(id, type, menu, data);
    setContextMenuPopover(null);
    setContextMenuOwnerId(undefined);
  };

  const handleCloseContextMenu = () => {
    setContextMenuPopover(null);
    setContextMenuOwnerId(undefined);
  };

  const handleToggleCollapse = () => {
    setCollapse(!collapse);
    setEditing(false);
  };

  const handleClickTaskItem = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) return;
    if (editing) return;

    setCollapse(!collapse);
    if (!collapse) setEditing(false);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    if (contextMenuPopover) return;
    setHover(false);
  };

  const isTaskGroup = value.tasks!.length > 0;
  const isEditable = editable && !isMetaKeyDown && !isGrouping;

  return (
    <>
      <Draggable draggableId={value.id} index={dragIndex!}>
        {(provided) => {
          const style = {
            width: '100%',
            marginBottom: '8px',
            ...provided.draggableProps.style,
          };
          return (
            <div ref={provided.innerRef} {...provided.draggableProps} style={style}>
              <Container
                ref={refTaskItem}
                draggable={draggable}
                hidden={hidden}
                onClickCapture={handleClickTask}
                onContextMenu={handleClickContext}
                onDragStart={handleDragStart}
                onDrag={handleDrag}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <DragHandleIcon className="drag-handle" taskHeight={taskHeight} {...provided.dragHandleProps} />
                <TaskWrapper groupSelected={groupSelected} highlight={highlight} selected={selected} editing={editing}>
                  {(value.category || value.deadline) && (
                    <TaskboxPropertyWrapper>
                      {value.category && (
                        <CategoryShowingWrapper
                          textColor={categoryColors.find((v) => v.color === value.category!.color)?.textColor}
                          bgColor={categoryColors.find((v) => v.color === value.category!.color)?.bgColor}
                        >{`# ${value.category.name}`}</CategoryShowingWrapper>
                      )}
                      {value.deadline && (
                        <DeadlineShowingWrapper date={value.deadline}>
                          <Icons.Flag
                            fill={dayjs(value.deadline).isToday() ? COLORS.brand1 : dayjs(value.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                          />
                          <span style={{ marginLeft: '2px' }}>
                            {dayjs(value.deadline).isToday()
                              ? '오늘'
                              : dayjs(value.deadline).isYesterday()
                              ? '어제'
                              : dayjs(value.deadline).isTomorrow()
                              ? '내일'
                              : dayjs(value.deadline).format('M월 D일 (dd)')}
                          </span>
                        </DeadlineShowingWrapper>
                      )}
                    </TaskboxPropertyWrapper>
                  )}

                  <TaskItemWrapper onClick={handleClickTaskItem}>
                    <TaskItemCheckboxWrapper>{isTaskGroup ? <Icons.TaskGroupCheckbox /> : <Icons.TaskCheckbox />}</TaskItemCheckboxWrapper>
                    <TaskItemContentWrapper>
                      <EditableTextField
                        data-id={`${value.id}`}
                        contentEditable={editing && isEditable}
                        suppressContentEditableWarning={true}
                        onKeyDown={handleKeydownTitleTextField}
                        onBlur={handleBlurTitleTextField}
                        onClick={handleClickTextField}
                        dangerouslySetInnerHTML={{ __html: value.title || '' }}
                        expanded={editing || collapse}
                      />
                    </TaskItemContentWrapper>
                    {/* {isHover && (
              <TaskItemButtonWrapper className="task-more-btn" style={contextMenuOwnerId === value.id ? { opacity: 1 } : {}}>
                <IconButton
                  aria-label="edit"
                  sx={{ background: 'transparent', padding: '4px', borderRadius: '4px' }}
                  size="small"
                  onClick={(e) => handleClickEdit(e, value.id)}
                >
                  <Icons.Edit width={16} height={16} stroke={'#C8CDDB'} />
                </IconButton>
                <IconButton
                  aria-label="more"
                  sx={{ background: 'transparent', padding: '4px', borderRadius: '4px' }}
                  size="small"
                  onClick={(e) => handleClickMenu(e, value.id)}
                >
                  <Icons.TaskMore />
                </IconButton>
              </TaskItemButtonWrapper>
            )} */}
                    {value.memo && <Icons.TaskboxMemo />}
                    {value.links!.length > 0 && (
                      <div style={{ marginLeft: '4px' }}>
                        <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                      </div>
                    )}
                    {Number(value?.tasks?.length) > 0 && (
                      <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '4px' }}>
                        <Icons.Subtask />
                        <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{value?.tasks?.length}</span>
                      </span>
                    )}
                    {/* {isTaskGroup && (
              <TaskItemCollapseButtonWrapper onClick={handleToggleCollapse}>
                <IconButton aria-label="arrow btn" sx={{ background: 'transparent', padding: '4px', borderRadius: '4px' }} size="small">
                  {collapse ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
                </IconButton>
              </TaskItemCollapseButtonWrapper>
            )} */}
                  </TaskItemWrapper>
                  {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginTop: collapse && value?.tasks?.length ? '4px' : '0px', width: '100%' }}>
            {collapse &&
              value?.tasks?.map((v: any) => (
                <SubTaskItemWrapper key={v.id} groupSelected={groupSelected}>
                  <TaskItemCheckboxWrapper>
                    <Icons.TaskCheckbox />
                  </TaskItemCheckboxWrapper>
                  <SubTaskContentWrapper>
                    <EditableTextField
                      data-id={`${v.id}`}
                      contentEditable={editing && isEditable}
                      suppressContentEditableWarning={true}
                      onKeyDown={(e) => handleKeydownTextField(e, v.id)}
                      onBlur={(e) => handleBlurTextField(e, v.id)}
                      onClick={handleClickSubTaskTextField}
                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                      expanded={true}
                    />
                  </SubTaskContentWrapper>
                  <SubTaskItemButtonWrapper style={v.id === contextMenuOwnerId ? { opacity: 1 } : {}}>
                    <IconButton
                      aria-label="more"
                      sx={{ background: 'transparent', padding: '4px', borderRadius: '4px' }}
                      size="small"
                      onClick={(e) => handleClickMenu(e, v.id)}
                    >
                      <Icons.TaskMore />
                    </IconButton>
                  </SubTaskItemButtonWrapper>
                </SubTaskItemWrapper>
              ))}
          </div> */}
                </TaskWrapper>
              </Container>
            </div>
          );
        }}
      </Draggable>
      {contextMenuPopover && contextMenuOwnerId && (
        <InboxContextMenuPopover
          id={contextMenuOwnerId}
          type={value.type!}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 0.5 }}
          menus={popoverMenus}
          onClose={handleCloseContextMenu}
          onClickMenu={handleClickContextMenu}
        />
      )}
    </>
  );
};

export default TaskItem;
