import { SVGProps } from 'react';

export const AccountSetting = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.15299 6H4.79948C4.05274 6 3.6791 6 3.39388 6.14532C3.143 6.27316 2.93917 6.47698 2.81134 6.72786C2.66602 7.01308 2.66602 7.38673 2.66602 8.13346V11.8668C2.66602 12.6135 2.66602 12.9867 2.81134 13.2719C2.93917 13.5228 3.143 13.727 3.39388 13.8548C3.67882 14 4.05203 14 4.79731 14H11.2014C11.9467 14 12.3193 14 12.6043 13.8548C12.8552 13.727 13.0597 13.5228 13.1875 13.2719C13.3327 12.987 13.3327 12.6143 13.3327 11.8691V8.13127C13.3327 7.386 13.3327 7.0128 13.1875 6.72786C13.0597 6.47698 12.8552 6.27316 12.6043 6.14532C12.3191 6 11.9462 6 11.1995 6H9.8453M6.15299 6H9.8453M6.15299 6C6.06803 6 5.99935 5.93112 5.99935 5.84615V4C5.99935 2.89543 6.89478 2 7.99935 2C9.10392 2 9.99935 2.89543 9.99935 4V5.84615C9.99935 5.93112 9.93027 6 9.8453 6"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
