import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet } from 'queries';
import { OutFeatureStorage } from 'queries/model';
export const quickTaskPopupVisibleAtom = atom(false);

const request = async () => await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('start-now');
const startNowFeature = atomWithDefault<OutFeatureStorage>(request);
export const startNowPopupVisibleAtom = atom(
  (get) => get(startNowFeature),
  async (_, set) => {
    const res = await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('start-now');
    set(startNowFeature, res || []);
  },
);
