import styled from '@emotion/styled';
import { Button, Checkbox, LinearProgress, Tooltip, Typography, keyframes } from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { calendarListAtom } from 'atoms/calendarList';
import { languageAtom } from 'atoms/language';
import { userAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom, useSetAtom } from 'jotai';
import { set } from 'lodash';
import {
  getAllCalendarV1CalendarGet,
  getUserMeV1UsersMeGet,
  syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost,
  updateCalendarV1CalendarCalendarIdPatch,
  updateMyProfileV1UsersMePatch,
} from 'queries';
import { InUpdateCalendar, InUpdateCalendarShown, OutCalendar, OutUserDetail } from 'queries/model';
import { SVGProps, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';

const TaskCheckIcon = ({ stroke = '#ABB0BF' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke={stroke} />
  </svg>
);

const TaskCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="4" fill={props?.fill || COLORS.brand1} />
    <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CalendarSetting = () => {
  const [language] = useAtom(languageAtom);
  const [isAnimation, setIsAnimation] = useState<boolean>(false);
  const [passOnboarding, setPassOnboarding] = useState<boolean>(false);
  const navigate = useNavigate();
  const [googleCalendarList, setGoogleCalendarList] = useState<OutCalendar[]>([]);
  const setGoogleCalendarListAtom = useSetAtom(calendarListAtom);

  useEffect(() => {
    (async () => {
      await getEntireGoogleCalendar();
      const userData = await getUserMeV1UsersMeGet();
      // if (userData?.isCalendarPicked) {
      //   if (userData?.tutorialStatus || localStorage.getItem('onboarding')) {
      //     navigate('/task');
      //   } else {
      //     navigate('/welcome');
      //   }
      //   return;
      // }

      if (userData?.tutorialStatus || localStorage.getItem('onboarding')) {
        setPassOnboarding(true);
      }
    })();
  }, []);

  useUpdateEffect(() => {
    if (isAnimation) {
      setTimeout(() => {
        if (passOnboarding) {
          navigate('/task');
        } else {
          navigate('/welcome');
        }
      }, 4000);
    }
  }, [isAnimation]);

  const getEntireGoogleCalendar = async () => {
    const calendarList = await getAllCalendarV1CalendarGet();
    if (calendarList.length === 0) {
      navigate('/google/calendar');
      return;
    }
    setGoogleCalendarList(calendarList);
    setGoogleCalendarListAtom(calendarList);
  };

  const handleChangeGoogleCalendarShown = async (e: React.ChangeEvent<HTMLInputElement>, calendar: OutCalendar) => {
    if (calendar.default) return;

    const updateCalendar: InUpdateCalendar = {
      shown: e.target.checked,
      default: calendar.default,
    };

    try {
      await updateCalendarV1CalendarCalendarIdPatch(calendar.id, updateCalendar);
    } catch (error) {
      toast.error(language === 'ko' ? '캘린더 설정을 변경하는 중 오류가 발생했습니다.' : 'An error occurred while changing the calendar settings.');
    }
    getEntireGoogleCalendar();
  };

  const handleChangeDefaultGoogleCalendar = async (calendar: OutCalendar) => {
    if (calendar.accessRole !== 'owner') return;

    const updateCalendar: InUpdateCalendar = {
      shown: !calendar.default ? true : calendar.shown,
      default: !calendar.default,
    };

    try {
      await updateCalendarV1CalendarCalendarIdPatch(calendar.id, updateCalendar);
    } catch (error) {
      toast.error(language === 'ko' ? '캘린더 설정을 변경하는 중 오류가 발생했습니다.' : 'An error occurred while changing the calendar settings.');
    }
    getEntireGoogleCalendar();
  };

  const handleClickComplete = async () => {
    const updateCalendars: InUpdateCalendarShown = {
      ids: googleCalendarList.filter((calendar) => calendar.shown).map((calendar) => calendar.id),
    };

    try {
      await updateMyProfileV1UsersMePatch({ isCalendarPicked: true });
      syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost(updateCalendars.ids);
    } catch (error) {
      toast.error(language === 'ko' ? '캘린더 설정을 변경하는 중 오류가 발생했습니다.' : 'An error occurred while changing the calendar settings.');
    }

    setIsAnimation(true);
  };

  return (
    <>
      {isAnimation ? (
        <ImageContainer>
          <div style={{ width: '100%' }}>
            <LinearProgress />
          </div>
          <ImageWrapper>
            <img src={require('assets/images/illust.png')} width={276} height={320} style={{ width: 276, height: 320 }} />
            <div style={{ display: 'flex', marginLeft: 12, marginTop: 16 }}>
              <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
                “
              </Typography>
              <Typography variant="subtitle2" color={COLORS.gray700} fontSize={40}>
                {language === 'ko' ? '오늘 하루를' : 'Make it a'}&nbsp;
              </Typography>
              <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'}>
                {language === 'ko' ? '성공적으로' : 'successful'}&nbsp;
              </Typography>
              <Typography variant="subtitle2" color={COLORS.gray700} fontSize={40}>
                {language === 'ko' ? '보내봐요' : 'day'}
              </Typography>
              <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
                ”
              </Typography>
            </div>
          </ImageWrapper>
        </ImageContainer>
      ) : (
        <>
          {googleCalendarList.length > 0 && (
            <Container>
              <GoogleCalendarSettingHeader>
                <div style={{ fontSize: '20px', fontWeight: 700 }}>{language === 'ko' ? '캘린더 설정하기' : 'Set up calendar'}</div>
              </GoogleCalendarSettingHeader>
              <GoogleCalendarShowingWrapper>
                <div style={{ width: '100%', height: '94%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <GoogleCalendarShowingTitle>
                    {language === 'ko' ? '어떤 캘린더를 사용하실건가요?' : 'Which calendar would you like to use?'}
                  </GoogleCalendarShowingTitle>
                  <GoogleCalendarShowingSubTitle>
                    {language === 'ko'
                      ? '구글 계정에 연결된 캘린더 중 SLASH에서 사용할 캘린더를 선택해주세요.'
                      : 'Please select the calendar from your Google account that you want to use in SLASH.'}
                  </GoogleCalendarShowingSubTitle>
                  <GoogleCalendarShowingList>
                    {googleCalendarList.map((calendar) => (
                      <GoogleCalendarContentWrapper key={calendar.id}>
                        <Checkbox
                          checked={calendar.shown}
                          icon={<TaskCheckIcon stroke={calendar.backgroundColor} />}
                          checkedIcon={<TaskCheckedIcon fill={calendar.backgroundColor} />}
                          onChange={(e) => handleChangeGoogleCalendarShown(e, calendar)}
                          sx={{
                            padding: '0px',
                            marginRight: '8px',
                          }}
                        />
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div key={calendar.id}>{calendar.summary}</div>
                          {calendar.default ? (
                            <div
                              style={{
                                height: '20px',
                                backgroundColor: COLORS.sub3,
                                borderRadius: '6px',
                                color: COLORS.brand1,
                                fontSize: '10px',
                                padding: '4px 10px',
                              }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '4px', height: '4px', borderRadius: '50%', backgroundColor: COLORS.brand1, marginRight: '4px' }} />
                                <span style={{ lineHeight: '11px' }}>{language === 'ko' ? '기본 캘린더' : 'Default Calendar'}</span>
                              </div>
                            </div>
                          ) : (
                            <Tooltip
                              title={
                                <div style={{ width: 'auto', maxWidth: 'none' }}>
                                  {calendar.accessRole === 'owner'
                                    ? language === 'ko'
                                      ? `할 일을 '구글 캘린더'에 표시할 때 사용할 캘린더를 선택합니다`
                                      : `Select the calendar to use when displaying tasks in 'Google Calendar'`
                                    : language === 'ko'
                                    ? '쓰기 권한이 없어 기본 캘린더로 만들 수 없어요'
                                    : `Can't make it the default calendar due to lack of write permissions`}
                                </div>
                              }
                              disableInteractive
                              sx={{ minWidth: '600px' }}
                            >
                              <DefaultCalendarButtonWrapper className="calendar-button-wrapper">
                                <Button
                                  onClick={() => handleChangeDefaultGoogleCalendar(calendar)}
                                  sx={{
                                    'height': '20px',
                                    'borderRadius': '6px',
                                    'color': COLORS.gray500,
                                    'fontSize': '10px',
                                    'padding': '4px 10px',
                                    'textTransform': 'none',
                                    ':hover': {
                                      'backgroundColor': calendar.accessRole === 'owner' ? COLORS.gray200 : 'transparent',
                                      'color': calendar.accessRole === 'owner' ? COLORS.brand1 : COLORS.gray400,
                                      '& .calendar-button-dot': {
                                        backgroundColor: calendar.accessRole === 'owner' ? COLORS.brand1 : 'transparent',
                                        border: calendar.accessRole === 'owner' ? 'none' : `1px solid ${COLORS.gray400}`,
                                      },
                                    },
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="calendar-button-dot" style={{ width: '4px', height: '4px', borderRadius: '50%', marginRight: '4px' }} />
                                    <span>{language === 'ko' ? '기본 캘린더로 만들기' : 'Make as Default Calendar'}</span>
                                  </div>
                                </Button>
                              </DefaultCalendarButtonWrapper>
                            </Tooltip>
                          )}
                        </div>
                      </GoogleCalendarContentWrapper>
                    ))}
                  </GoogleCalendarShowingList>
                  <GoogleCalendarInfoWrapper>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                      <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray600} />
                      <span style={{ display: 'flex', alignItems: 'center', color: COLORS.gray700, fontSize: 13, marginLeft: 4 }}>
                        {language === 'ko' ? '이후 캘린더 설정에서 설정할 수 있습니다.' : 'You can adjust this later in calendar settings.'}
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', color: COLORS.brand1, fontSize: 13, fontWeight: 700, paddingLeft: 20 }}>
                      <span style={{ marginRight: '2px' }}>{language === 'ko' ? '좌측 프로필 사진' : 'Profile picture on the left'}</span>
                      <Icons.ArrowRightSmall width={14} height={14} fill={COLORS.brand1} />
                      <span style={{ marginLeft: '2px' }}>{language === 'ko' ? '설정' : 'Settings'}</span>
                      <Icons.ArrowRightSmall width={14} height={14} fill={COLORS.brand1} />
                      <span style={{ marginLeft: '2px' }}>{language === 'ko' ? '캘린더 설정' : 'Calendar settings'}</span>
                    </div>
                  </GoogleCalendarInfoWrapper>
                </div>
                <Button
                  variant="contained"
                  onClick={handleClickComplete}
                  sx={{ width: '288px', borderRadius: '8px', fontSize: '13px', fontWeight: 700, padding: '14px 16px', textTransform: 'none' }}
                >
                  <Icons.DoubleCheck />
                  <span style={{ marginLeft: 4 }}>{language === 'ko' ? '설정 완료하기' : 'Complete setup'}</span>
                </Button>
              </GoogleCalendarShowingWrapper>
            </Container>
          )}
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.gray100};
`;

const GoogleCalendarSettingHeader = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  border-bottom: 1px solid ${COLORS.gray300};
  padding: 20px 24px;
`;

const GoogleCalendarShowingWrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
`;

const GoogleCalendarShowingList = styled.div`
  width: 630px;
  max-height: calc(100% - 210px);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(45, 106, 223, 0.08), 0px 12px 40px 0px rgba(45, 106, 223, 0.12);
  padding: 24px;
  overflow: scroll;
  ${hideScroll}
`;

const GoogleCalendarShowingTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

const GoogleCalendarShowingSubTitle = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
  font-size: 20px;
`;

const GoogleCalendarContentWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 6px;

  .calendar-button-wrapper {
    opacity: 0;
  }

  :hover {
    background-color: #f2f5fc;

    .calendar-button-wrapper {
      opacity: 1;
    }
  }
`;

const DefaultCalendarButtonWrapper = styled.div`
  .calendar-button-dot {
    background-color: ${COLORS.gray500};
  }
`;

const GoogleCalendarInfoWrapper = styled.div`
  width: 630px;
  padding-left: 24px;
  margin-top: 10px;
`;

const fadeInOutAnimation = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: ${fadeInOutAnimation} ease-in-out 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
