import { SVGProps } from 'react';

export const Edit = ({ width = 24, height = 24, stroke = '#1F2023', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_16878_103304)">
        <path
          d="M2.66602 13.3361H5.33268L12.3327 6.33614C12.6863 5.98252 12.885 5.5029 12.885 5.00281C12.885 4.50271 12.6863 4.02309 12.3327 3.66947C11.9791 3.31585 11.4994 3.11719 10.9993 3.11719C10.4993 3.11719 10.0196 3.31585 9.66602 3.66947L2.66602 10.6695V13.3361Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 4.33594L11.6667 7.0026" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_16878_103304">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
