import { SVGProps } from 'react';

export const GoogleCalendar = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path d="M12.2104 3.80469H3.78943V12.2563H12.2104V3.80469Z" fill="white" />
      <path d="M12.2106 16.0611L16 12.2578H12.2106V16.0611Z" fill="#EA4335" />
      <path d="M16 3.80469H12.2106V12.2563H16V3.80469Z" fill="#FBBC04" />
      <path d="M12.2104 12.2578H3.78943V16.0611H12.2104V12.2578Z" fill="#34A853" />
      <path d="M0 12.2578V14.7933C0 15.4937 0.565261 16.0611 1.26315 16.0611H3.78946V12.2578H0Z" fill="#188038" />
      <path d="M16 3.80324V1.26775C16 0.567317 15.4348 0 14.7369 0H12.2106V3.80324H16Z" fill="#1967D2" />
      <path d="M12.2105 0H1.26315C0.565261 0 0 0.567317 0 1.26775V12.2549H3.78946V3.80324H12.2105V0Z" fill="#4285F4" />
      <path
        d="M5.51681 10.3628C5.20207 10.1494 4.98418 9.83778 4.86523 9.42577L5.59576 9.12362C5.66207 9.37717 5.77786 9.57367 5.94312 9.71312C6.10733 9.85258 6.30733 9.92124 6.54102 9.92124C6.77996 9.92124 6.98522 9.84835 7.1568 9.70256C7.32838 9.55677 7.4147 9.37083 7.4147 9.14581C7.4147 8.9155 7.32417 8.72745 7.14312 8.58166C6.96207 8.43587 6.7347 8.36297 6.46312 8.36297H6.04102V7.63719H6.41996C6.65365 7.63719 6.85049 7.5738 7.01049 7.44702C7.17049 7.32025 7.25049 7.14699 7.25049 6.92619C7.25049 6.72969 7.17891 6.57333 7.03575 6.45607C6.89259 6.3388 6.71154 6.27964 6.49154 6.27964C6.27681 6.27964 6.10628 6.33669 5.97997 6.45184C5.85373 6.5673 5.75883 6.71308 5.70418 6.87548L4.98102 6.57333C5.07681 6.30077 5.2526 6.0599 5.51049 5.85177C5.76839 5.64365 6.09786 5.53906 6.49786 5.53906C6.79365 5.53906 7.05996 5.59611 7.29575 5.71126C7.53154 5.82642 7.7168 5.98594 7.85048 6.18878C7.98417 6.39268 8.05048 6.62087 8.05048 6.87442C8.05048 7.13326 7.98838 7.35194 7.86417 7.53154C7.73996 7.71114 7.58733 7.84848 7.40628 7.94461V7.98793C7.64006 8.08466 7.84313 8.24355 7.99364 8.44749C8.14627 8.6535 8.22311 8.89965 8.22311 9.18701C8.22311 9.47436 8.15048 9.73108 8.00522 9.95611C7.85996 10.1811 7.65891 10.3586 7.40417 10.4875C7.14838 10.6164 6.86102 10.6819 6.54207 10.6819C6.1726 10.683 5.83155 10.5762 5.51681 10.3628ZM10.0042 6.72441L9.20206 7.30651L8.80101 6.69588L10.2399 5.65422H10.7915V10.5678H10.0042V6.72441Z"
        fill="#4285F4"
      />
    </svg>
  );
};
