import { SVGProps } from 'react';

export const ConvertToProject = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.3333 8.66927L14 6.0026M14 6.0026L11.3333 3.33594M14 6.0026H5.33333C3.49238 6.0026 2 7.49499 2 9.33594C2 11.1769 3.49238 12.6693 5.33333 12.6693H8.66667"
        stroke={props.stroke || '#1F2023'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
