import { SVGProps } from 'react';

export const BoardSetting = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16927 3.33594C4.62951 3.33594 5.0026 3.70903 5.0026 4.16927V5.78476C5.0041 5.80876 5.00458 5.83302 5.00399 5.85751C4.993 6.31762 4.6111 6.68169 4.15099 6.6707L4.14936 6.67066C3.69701 6.65985 3.33594 6.29005 3.33594 5.83756V4.16927C3.33594 3.70903 3.70903 3.33594 4.16927 3.33594ZM6.66927 5.0026C6.66927 4.54237 7.04237 4.16927 7.5026 4.16927H15.8359C16.2962 4.16927 16.6693 4.54237 16.6693 5.0026C16.6693 5.46284 16.2962 5.83594 15.8359 5.83594H7.5026C7.04237 5.83594 6.66927 5.46284 6.66927 5.0026ZM4.16927 8.33594C4.62951 8.33594 5.0026 8.70903 5.0026 9.16927V10.7848C5.0041 10.8087 5.00458 10.833 5.004 10.8574C4.99304 11.3175 4.61117 11.6817 4.15107 11.6707L4.14944 11.6707C3.69706 11.6599 3.33594 11.2901 3.33594 10.8376V9.16927C3.33594 8.70903 3.70903 8.33594 4.16927 8.33594ZM6.66927 10.0026C6.66927 9.54237 7.04237 9.16927 7.5026 9.16927H15.8359C16.2962 9.16927 16.6693 9.54237 16.6693 10.0026C16.6693 10.4628 16.2962 10.8359 15.8359 10.8359H7.5026C7.04237 10.8359 6.66927 10.4628 6.66927 10.0026ZM4.16927 13.3359C4.62951 13.3359 5.0026 13.709 5.0026 14.1693V15.8376C5.0026 16.2978 4.62951 16.6709 4.16927 16.6709C3.70903 16.6709 3.33594 16.2978 3.33594 15.8376V14.1693C3.33594 13.709 3.70903 13.3359 4.16927 13.3359ZM6.66927 15.0026C6.66927 14.5424 7.04237 14.1693 7.5026 14.1693H8.33594C8.79617 14.1693 9.16927 14.5424 9.16927 15.0026C9.16927 15.4628 8.79617 15.8359 8.33594 15.8359H7.5026C7.04237 15.8359 6.66927 15.4628 6.66927 15.0026Z"
        fill="#1F2023"
      />
      <path
        d="M10.6626 16.5771C11.404 17.3142 12.6971 18.3359 14.1682 18.3359C15.6393 18.3359 16.9321 17.3142 17.6735 16.5771C17.869 16.3827 17.9671 16.2852 18.0294 16.0943C18.0738 15.9581 18.0738 15.7137 18.0294 15.5776C17.9671 15.3867 17.8691 15.2891 17.6735 15.0947C16.9321 14.3576 15.6393 13.3359 14.1682 13.3359C12.6971 13.3359 11.404 14.3576 10.6626 15.0947C10.4669 15.2893 10.369 15.3866 10.3068 15.5776C10.2623 15.7137 10.2623 15.9581 10.3068 16.0943C10.369 16.2853 10.4669 16.3826 10.6626 16.5771Z"
        stroke="#1F2023"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3348 15.8359C13.3348 16.2962 13.7079 16.6693 14.1681 16.6693C14.6283 16.6693 15.0014 16.2962 15.0014 15.8359C15.0014 15.3757 14.6283 15.0026 14.1681 15.0026C13.7079 15.0026 13.3348 15.3757 13.3348 15.8359Z"
        stroke="#1F2023"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
