import { isElementDomNode } from 'remirror';

/*
 * nodeAtCoords : Returns the DOM node at given coords
 */
export function nodeAtCoords(
  coords: { x: number; y: number },
  isDraggableBlock: (elem: Element) => boolean = () => false,
  targetType: 'drop' | 'drag' = 'drop',
): Element | null {
  const elements = document.elementsFromPoint(coords.x, coords.y);
  for (const elem of elements) {
    // Root nodes are all draggable
    if (elem && elem.parentNode instanceof HTMLElement) {
      if (elem.parentNode.classList.contains('ProseMirror') || elem.parentNode.classList.contains('column')) {
        return elem;
      }
    }

    // Nested nodes
    if ((elem.previousElementSibling || targetType === 'drop') && isDraggableBlock(elem)) {
      return elem;
    }
  }

  return null;
}

/*
 * nearestBlockFromCoords : When coords are inside the editor but
 * not directly over its content (e.g. if the cursor is inside the
 * padding area of the editor), returns the nearest node and the closest side.
 */
export function nearestBlockFromCoords(coords: { x: number; y: number }) {
  const editorWrapper = document.querySelector('.ProseMirror');

  if (!editorWrapper) {
    return { nearestBlock: null };
  }

  const editorRect = editorWrapper.getBoundingClientRect();
  const targetX = Math.min(Math.max(coords.x, editorRect.left), editorRect.right - 1);
  const targetY = Math.min(Math.max(coords.y, editorRect.top), editorRect.bottom - 1);

  const nearestBlock = nodeAtCoords({ x: targetX, y: targetY });

  let side: 'top' | 'bottom' | 'left' | 'right' = 'top';

  if (coords.x > editorRect.right) {
    side = 'right';
  } else if (coords.x < editorRect.left) {
    side = 'left';
  } else if (coords.y > editorRect.bottom) {
    side = 'bottom';
  }

  return { nearestBlock, side };
}

/*
 * nodePosAtDOM : Returns the node position of a given DOM node
 */
export function nodePosAtDOM(node: any, view: any) {
  const pos = view.posAtDOM(node);
  const resolvedPos = view.state.doc.resolve(pos);
  const nodePos = resolvedPos.path[3 * resolvedPos.depth - 1];

  return nodePos;
}

/*
 * getClosestSide : Returns the DOM node side (top, right, left, bottom)
 * which is the closest to the given coords.
 */
export function getClosestSide(node: Element, coords: { x: number; y: number }): string {
  const nodeRect = node.getBoundingClientRect();
  const distances = [
    { origin: 'left', distance: Math.abs(coords.x - nodeRect.left) },
    { origin: 'right', distance: Math.abs(coords.x - nodeRect.right) },
    { origin: 'top', distance: Math.abs(coords.y - nodeRect.top) },
    { origin: 'bottom', distance: Math.abs(coords.y - nodeRect.bottom) },
  ];

  // 가장 짧은 거리를 가진 측면을 찾습니다.
  const shortestDistance = distances.reduce((min, current) => (current.distance < min.distance ? current : min));

  return shortestDistance.origin;
}

/*
 * isNodeSlice : Returns true if the given slice represents a node,
 * otherwise returns false.
 */
export function isNodeSlice(slice: { openStart: number; openEnd: number; content: { childCount: number } }) {
  return slice.openStart === 0 && slice.openEnd === 0 && slice.content.childCount === 1;
}

/*
 * createColumnsNodeWith : Creates a new Columns node with the given content
 */
export function createColumnsNodeWith(
  content: any[],
  nodeTypes: { column: { createChecked: (arg0: { scale: number }, arg1: any) => any }; columns: { create: (arg0: null, arg1: any[]) => any } },
) {
  const columns: any[] = [];
  content.forEach((node) => {
    columns.push(nodeTypes.column.createChecked({ scale: 1 / content.length }, node.type.name === 'column' ? [...node.content.content] : node));
  });

  return nodeTypes.columns.create(null, columns);
}

export const isMouseInsideMemoContainer = (event: any, element: Element) => {
  const rect = element!.getBoundingClientRect();
  const mouseX = event.clientX;
  const mouseY = event.clientY;
  const isInside = mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom;
  return isInside;
};

export const removeMemoHandle = () => {
  const dragElement =
    document.querySelectorAll('.memo-drag-handle').length > 1
      ? document.querySelectorAll('.memo-drag-handle')[1]
      : (document.querySelector('.memo-drag-handle') as HTMLDivElement);
  const menuElement =
    document.querySelectorAll('.memo-menu-handle').length > 1
      ? document.querySelectorAll('.memo-menu-handle')[1]
      : (document.querySelector('.memo-menu-handle') as HTMLDivElement);

  if (dragElement && menuElement) {
    dragElement.classList.add('hidden');
    menuElement.classList.add('hidden');
  }
};
