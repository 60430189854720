import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { COLORS } from 'styles/constants';

interface ProjectToInstanceGuideProps {
  onClose?: () => void;
}

const ProjectToInstanceGuide = ({ onClose }: ProjectToInstanceGuideProps) => {
  const [language] = useAtom(languageAtom);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Container>
      <Header>
        {language === 'ko' ? (
          <div>
            <div>프로젝트를 Drag & Drop해서</div>
            <div>인스턴스로 만들어 보세요</div>
          </div>
        ) : (
          <div>
            <div>Drag & Drop projects to create</div>
            <div>instances</div>
          </div>
        )}
        <IconButton onClick={handleClose} sx={{ width: '24px', height: '24px', padding: '4px' }}>
          <Icons.Close width={19} height={19} stroke={COLORS.gray400} />
        </IconButton>
      </Header>
      <img
        src={require(language === 'ko' ? 'assets/images/project_instance_guide.png' : 'assets/images/project_instance_guide_en.png')}
        alt="guide1"
        style={{ width: '286px', marginBottom: 16 }}
      />
      <Footer>
        <div style={{ display: 'flex', alignItems: 'center', color: COLORS.gray700, fontSize: '10px', fontWeight: 700, marginBottom: 4 }}>
          <Icons.ExclamationInfo width={12.25} height={12.25} stroke={COLORS.gray700} style={{ marginRight: 4 }} />
          <span>{language === 'ko' ? '인스턴스' : 'Instance'}</span>
        </div>
        <div style={{ color: COLORS.gray700, fontSize: 10 }}>
          <div>
            {language === 'ko' ? '프로젝트, 루틴을 구체적으로 실행하는 단위입니다' : 'Instances are actionable units of projects and routines—think of'}
          </div>
          <div> {language === 'ko' ? '프로젝트로 만든 태스크박스로 생각하면 돼요!' : 'them as taskboxes derived from your projects!'}</div>
        </div>
      </Footer>
    </Container>
  );
};

export default ProjectToInstanceGuide;

const Container = styled.div`
  background-color: ${COLORS.white};
  border: 1px solid #e7eaf4;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  padding: 24px;

  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 12px 0px 12px 10px;
    border-color: transparent ${COLORS.white};
    display: block;
    width: 0;
    z-index: 0;
    top: 50px;
    right: 4%;
    @media screen and (max-width: 650px) {
      left: 8px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Footer = styled.div``;
