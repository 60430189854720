import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { Graph } from '../Icons';

interface MenuProps {
  label?: string;
  focus: boolean;
  onClick: () => void;
}

export const GraphMenu = ({ focus = false, onClick, label }: MenuProps) => {
  if (focus) {
    return (
      <svg width="80px" height="80px" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H80V80H8C3.58172 80 0 76.4183 0 72V8Z" fill="#F2F5FC" />
        <path
          d="M8 0C3.58172 0 0 3.58172 0 8V72C0 76.4183 3.58172 80 8 80H80C80 75.5817 76.4183 72 72 72H16C11.5817 72 8 68.4183 8 64V16C8 11.5817 11.5817 8 16 8H72C76.4183 8 80 4.41828 80 0H8Z"
          fill="white"
        />
        <g clipPath="url(#clip0_1858_1511)">
          <path d="M40 17V29H52" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M40 41C46.6274 41 52 35.6274 52 29C52 22.3726 46.6274 17 40 17C33.3726 17 28 22.3726 28 29C28 35.6274 33.3726 41 40 41Z"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M39.543 57.9102H35.418V57.2539H38.5V56.1055H32.5117V55.4961H38.1602V54.418H32.5117V53.8203H38.4062V52.6719H31.0117V57.2539H33.918V57.9102H29.793V59.082H39.543V57.9102ZM30.9297 61.3203C30.9297 62.4688 32.2773 63.084 34.668 63.0781C37.0176 63.084 38.3711 62.4688 38.3828 61.3203C38.3711 60.1953 37.0176 59.5625 34.668 59.5742C32.2773 59.5625 30.9297 60.1953 30.9297 61.3203ZM32.4297 61.3203C32.418 60.875 33.1328 60.6699 34.668 60.6758C36.168 60.6699 36.877 60.875 36.8828 61.3203C36.877 61.7773 36.168 61.9766 34.668 61.9766C33.1328 61.9766 32.418 61.7773 32.4297 61.3203ZM49.1836 52.4141H47.7773V63.0781H49.1836V52.4141ZM39.7734 59.8086L40.6523 60.8633C42.6445 59.6797 43.7344 58.1973 44.2324 56.4336H45.5391V57.8867H44.0273V59.0938H45.5391V62.6094H46.957V52.6484H45.5391V55.2266H44.4785C44.543 54.7285 44.5781 54.2129 44.5781 53.6797H40.3945V54.8867H43.0898C42.8848 56.9785 41.9355 58.5312 39.7734 59.8086Z"
          fill="#0039A7"
        />
        <defs>
          <clipPath id="clip0_1858_1511">
            <rect width="32" height="32" fill="white" transform="translate(24 13)" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <Wrapper onClick={onClick}>
        <Container>
          <Graph fill={COLORS.gray700} />
        </Container>
        <Fonts.H5>통계</Fonts.H5>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1px;
  background-color: ${COLORS.white};
  color: ${COLORS.gray700};
`;

const Container = styled.div`
  margin-bottom: 2px;
`;
