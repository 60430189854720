import { SVGProps } from 'react';
import { ProgressPlugin } from 'webpack';

export const AppleLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M22.9927 15.4223C23.0265 19.0543 26.1789 20.2629 26.2138 20.2783C26.1872 20.3635 25.7101 22.0007 24.553 23.6918C23.5526 25.1538 22.5145 26.6105 20.879 26.6406C19.272 26.6702 18.7553 25.6877 16.918 25.6877C15.0813 25.6877 14.5072 26.6105 12.986 26.6703C11.4074 26.7301 10.2052 25.0893 9.19664 23.6326C7.13564 20.653 5.56061 15.2128 7.67548 11.5406C8.7261 9.71698 10.6036 8.56218 12.6415 8.53257C14.1917 8.503 15.6548 9.57547 16.6025 9.57547C17.5496 9.57547 19.3276 8.28572 21.1969 8.47514C21.9794 8.50771 24.176 8.79124 25.5864 10.8558C25.4728 10.9262 22.9655 12.3859 22.9927 15.4223ZM19.9726 6.50384C20.8107 5.48934 21.3748 4.07707 21.2209 2.67188C20.0128 2.72043 18.552 3.4769 17.6855 4.49083C16.9089 5.38872 16.2288 6.82584 16.4123 8.20323C17.7588 8.30741 19.1344 7.51897 19.9726 6.50384Z"
        fill="black"
      />
    </svg>
  );
};
