import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import dayjs from 'lib/dayjs';
import { OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';

const GraphTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 20px;
`;
const GraphTimeHeader = styled.div`
  font-size: 16px;
  font-weight: 700;

  .control-wrapper {
    &:hover {
      .control-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .pace-wrapper {
    &:hover {
      .pace-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .focus-wrapper {
    &:hover {
      .focus-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .invest-wrapper {
    &:hover {
      .invest-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }
`;
const GraphTimeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const GraphTimeFooter = styled.div`
  font-size: 13px;
  border-radius: 8px;
  background-color: ${COLORS.gray100};
  padding: 12px 16px;
`;

const TimeboxingTimeWrapper = styled.div`
  width: 142px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimeboxingTimeHeader = styled.div`
  color: ${COLORS.gray500};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;
const TimeboxingTimeContent = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const InvestedTaskboxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const InvestedTaskboxPrioity = styled.div`
  width: 48px;
  border-radius: 8px;
  background-color: ${COLORS.alert2};
  color: ${COLORS.alert1};
  padding: 4px 0px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

const InvestedTaskboxContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  margin-left: 12px;
  padding: 12px 16px;
  font-size: 16px;
`;

const TooltipWrapper = styled.div`
  width: 265px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${COLORS.gray900};
  color: ${COLORS.white};
  position: absolute;
  z-index: -1;
  opacity: 0;
  font-size: 12px;
  font-weight: 400;
`;

interface StatViewProps {
  taskboxes?: OutTaskboxDetailResponse[];
}

export interface ControlTime {
  totalTimeboxing: number;
  doneTimeboxing: number;
}

const StatView = ({ taskboxes = [] }: StatViewProps) => {
  const { t } = useTranslation();
  const [language] = useAtom(languageAtom);
  const [controlTimeData, setControlTimeData] = useState<ControlTime>({
    totalTimeboxing: 0,
    doneTimeboxing: 0,
  });

  useEffect(() => {
    handleTimeboxingTime(taskboxes);
  }, [taskboxes]);

  const handleTimeboxingTime = (taskboxes: OutTaskboxDetailResponse[]) => {
    let totalTimeboxingTime = 0;
    let totalDoneTime = 0;

    taskboxes.forEach((taskbox) => {
      totalTimeboxingTime += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      if (taskbox.done) {
        totalDoneTime += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      }
    });

    const control: ControlTime = {
      totalTimeboxing: totalTimeboxingTime / 60,
      doneTimeboxing: totalDoneTime / 60,
    };
    setControlTimeData(control);
  };

  return (
    <GraphTimeWrapper>
      <GraphTimeHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '4px' }}>{language === 'ko' ? '내가 통제한 시간' : 'Time under control'}</div>
          <div className="control-wrapper" style={{ cursor: 'pointer', position: 'relative' }}>
            <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray500} />
            <TooltipWrapper className="control-tooltip" style={{ left: '-122px', top: '22px' }}>
              <div style={{ marginBottom: '8px', fontWeight: 700 }}>{t('home.tooltip1.title')}</div>
              <div>
                <div>{t('home.common.question1')}</div>
                <div style={{ display: 'flex', marginLeft: 6 }}>
                  <div style={{ marginRight: 4 }}>•</div>
                  <div>{t('home.tooltip1.answer1')}</div>
                </div>
              </div>
              <div>
                <div>{t('home.common.question2')}</div>
                <div style={{ display: 'flex', marginLeft: 6 }}>
                  <div style={{ marginRight: 4 }}>•</div>
                  <div>{t('home.tooltip1.answer2')}</div>
                </div>
              </div>
            </TooltipWrapper>
          </div>
        </div>
        <Divider style={{ marginTop: '12px' }} />
      </GraphTimeHeader>

      <GraphTimeContent style={{ margin: '32px 0px', padding: '0px 24px' }}>
        <TimeboxingTimeWrapper style={{ marginBottom: '32px' }}>
          <TimeboxingTimeHeader>{language === 'ko' ? '오늘 타임박싱한 시간' : 'Time-boxed today'}</TimeboxingTimeHeader>
          <TimeboxingTimeContent>
            {`${parseFloat(controlTimeData!.totalTimeboxing.toFixed(1)!) === 0 ? '-' : parseFloat(controlTimeData!.totalTimeboxing.toFixed(1)!)}${
              language === 'ko' ? ' 시간' : parseFloat(controlTimeData!.totalTimeboxing.toFixed(1)!) === 1 ? ' hour' : ' hours'
            }`}
          </TimeboxingTimeContent>
        </TimeboxingTimeWrapper>
        <TimeboxingTimeWrapper>
          <TimeboxingTimeHeader>{language === 'ko' ? '그 중 완료한 시간' : 'Time-boxed completed'}</TimeboxingTimeHeader>
          <TimeboxingTimeContent>
            {`${parseFloat(controlTimeData!.doneTimeboxing.toFixed(1)!) === 0 ? '-' : parseFloat(controlTimeData!.doneTimeboxing.toFixed(1)!)}${
              language === 'ko' ? ' 시간' : parseFloat(controlTimeData!.totalTimeboxing.toFixed(1)!) === 1 ? ' hour' : ' hours'
            }`}
          </TimeboxingTimeContent>
        </TimeboxingTimeWrapper>
      </GraphTimeContent>
      <GraphTimeFooter
        style={{
          color: controlTimeData?.totalTimeboxing === 0 && controlTimeData?.totalTimeboxing === 0 ? COLORS.gray400 : COLORS.gray900,
        }}
      >
        {language === 'ko'
          ? `👊 오늘 통제한 시간, ${parseFloat(controlTimeData!.doneTimeboxing.toFixed(1))}시간 달성중!`
          : `👊 Today's controlled time, ${parseFloat(controlTimeData!.doneTimeboxing.toFixed(1))}${
              parseFloat(controlTimeData!.doneTimeboxing.toFixed(1)) === 0 || parseFloat(controlTimeData!.doneTimeboxing.toFixed(1)) === 1 ? 'hr' : 'hrs'
            } achieved!`}
      </GraphTimeFooter>
    </GraphTimeWrapper>
  );
};

export default StatView;
