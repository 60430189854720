import { SVGProps } from 'react';

export const MoreVertical = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.33203 12.0026C7.33203 12.3708 7.63051 12.6693 7.9987 12.6693C8.36689 12.6693 8.66536 12.3708 8.66536 12.0026C8.66536 11.6344 8.36689 11.3359 7.9987 11.3359C7.63051 11.3359 7.33203 11.6344 7.33203 12.0026Z"
        stroke={props.stroke || '#656871'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33203 8.0026C7.33203 8.37079 7.63051 8.66927 7.9987 8.66927C8.36689 8.66927 8.66536 8.37079 8.66536 8.0026C8.66536 7.63441 8.36689 7.33594 7.9987 7.33594C7.63051 7.33594 7.33203 7.63441 7.33203 8.0026Z"
        stroke={props.stroke || '#656871'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33203 4.0026C7.33203 4.37079 7.63051 4.66927 7.9987 4.66927C8.36689 4.66927 8.66536 4.37079 8.66536 4.0026C8.66536 3.63441 8.36689 3.33594 7.9987 3.33594C7.63051 3.33594 7.33203 3.63441 7.33203 4.0026Z"
        stroke={props.stroke || '#656871'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
