import { SVGProps } from 'react';

export const ContinueTaskbox = ({ fill = '#0039A7', ...props }: { fill?: string; props?: SVGProps<SVGSVGElement> }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8011 4.34286C11.0821 4.64722 11.0631 5.12172 10.7587 5.40267L6.42538 9.40267C6.13808 9.66787 5.69526 9.66787 5.40796 9.40267L3.24129 7.40267C2.93693 7.12172 2.91795 6.64722 3.1989 6.34286C3.47985 6.03849 3.95435 6.01951 4.25871 6.30046L5.91667 7.83089L9.74129 4.30046C10.0457 4.01951 10.5202 4.03849 10.8011 4.34286Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3936 8.28218C11.1007 7.98928 10.6259 7.98927 10.333 8.28216C10.0401 8.57505 10.0401 9.04992 10.3329 9.34282L10.6854 9.69531H8.51544C8.38974 9.69531 8.26866 9.74265 8.1763 9.82791L7.44291 10.5049C7.16952 10.7572 6.84918 10.9296 6.51168 11.0218C6.39183 11.0546 6.37586 11.1953 6.5001 11.1953H10.6926L10.3329 11.555C10.0401 11.8479 10.0401 12.3228 10.333 12.6157C10.6259 12.9086 11.1007 12.9086 11.3936 12.6157L13.03 10.9792C13.3229 10.6864 13.3229 10.2115 13.03 9.9186L11.3936 8.28218Z"
        fill="white"
      />
    </svg>
  );
};
