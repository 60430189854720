import styled from '@emotion/styled';
import { IconButton, TextField, Typography, Tooltip, Button } from '@mui/material';
import { Icons } from 'components';
import React, { PropsWithChildren, ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import { useClickOutside, useKeyboardEvent } from '@react-hookz/web';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDraggableType, WorkDragView } from 'atoms/works';
import { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import IssueItem, { IssueItemProps, Issue as IssueType } from 'components/Task/IssueItem';
import { osName } from 'react-device-detect';
import { OutProject } from 'queries/model';
import { useNavigate } from 'react-router-dom';
import { getAProjectV1ProjectProjectIdGet, orderProjectV1ProjectProjectIdOrderPatch } from 'queries';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { changedProjectIdAtom, fetchInstancesFromProjectDetailViewAtom, projects, selectedProjectAtom } from 'atoms/projects';
import { languageAtom } from 'atoms/language';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ListTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const ListTitleCount = styled.h3`
  font-weight: bold;
  color: ${COLORS.gray400};
`;

const ListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* padding: 0 12px; */
`;

const NewInputWrapper = styled.div`
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
`;

const IssueListViewItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  ${hideScroll()}
`;

const IssueListDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const EmptyListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(180deg, #e7eaf4 0%, #f2f5fc 100%);
`;

const IssueListDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid #cd6cfc;
  background: #cd6cfc;
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

export interface IssueListViewProps extends PropsWithChildren {
  title?: ReactNode;
  items?: OutProject[];
  editable?: boolean;
  draggable?: boolean;
  dragView?: WorkDragView;
  multipleDrag?: boolean;
  issuePopoverMenus?: InboxContextMenuType[];
  canAddItem?: boolean;
  spinBorderId?: string;
  onCreate?: (value: string) => void;
  onChangeItem?: IssueItemProps['onChangeTask'];
  onChangeTitle?: IssueItemProps['onChangeTitle'];
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onFetchProjects?: () => void;
  onDeleteProject?: (id: string) => void;
}

export type IssueListDragContext = Partial<{ dragging: boolean; draggingId: string }>;

const IssueListView = (props: IssueListViewProps) => {
  const {
    title,
    items = [],
    editable,
    draggable,
    dragView,
    multipleDrag,
    issuePopoverMenus = ['DELETE', 'COMPLETED_AND_MOVE_TO_TODAY'],
    canAddItem = false,
    spinBorderId,
    onCreate,
    onClickContextMenu,
    onChangeItem,
    onChangeTitle,
    onFetchProjects,
    onDeleteProject,
  } = props;
  const [language] = useAtom(languageAtom);
  const [deskProjects, setDeskProjects] = useState<OutProject[]>(items);
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const refListView = useRef<HTMLDivElement>(null);
  const refIssueListView = useRef<HTMLDivElement>(null);
  const refPopperMenu = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [selectedItemIds, setSelectedItemIds] = useState<Map<string, string>>(new Map());
  const [, setOutsideDragContext] = useAtom(dragContextAtom);
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [, setFetchInstancesFromProjectDetailView] = useAtom(fetchInstancesFromProjectDetailViewAtom);
  const [, setChangedProjectId] = useAtom(changedProjectIdAtom);
  const [dragContext, setDragContext] = useReducer((prev: IssueListDragContext, next: IssueListDragContext) => ({ ...prev, ...next }), {
    dragging: false,
    draggingId: '',
  });
  const [currentSelectedId, setCurrentSelectedId] = useState<string | null>(null);
  const { dragging, draggingId } = dragContext;
  const os = osName;

  useEffect(() => {
    setDeskProjects(items);
    if (selectedProject) {
      const project = items.find((item) => item.id === selectedProject.id);
      if (project) setSelectedProject(project);
    }
  }, [items]);

  useClickOutside(refIssueListView, () => {
    setSelectedItemIds(new Map());
    setCurrentSelectedId(null);
  });

  useClickOutside(refPopperMenu, (e: Event) => {
    if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
    if (refIssueListView.current !== e.target && refIssueListView.current?.contains(e.target as Node)) return;

    setSelectedItemIds(new Map());
  });

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === 'Escape' && refPopperMenu) {
        setSelectedItemIds(new Map());
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const navigate = useNavigate();

  const handleClickCreate = () => {
    setIsVisibleInput(true);
    setTimeout(() => refInput.current?.focus(), 100);
  };

  const handleKeydownNewItemInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;
    if (e.key === 'Escape') {
      if (value) return;

      e.preventDefault();
      setIsVisibleInput(false);
    }

    if (e.key === 'Enter') {
      if (!value) return;
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refInput.current.value = '';
      onCreate?.(value);
    }
  };

  const handleClickIssueItem = async (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    const project = await getAProjectV1ProjectProjectIdGet(id);
    if (project) setSelectedProject(project);

    // if (e.ctrlKey || e.metaKey) {
    //   if (selectedItemIds.has(id)) {
    //     setSelectedItemIds((prev) => {
    //       const newState = new Map(prev);
    //       newState.delete(id);
    //       return newState;
    //     });
    //   } else {
    //     const type = items.find((item) => item.id === id)?.type as string;
    //     setSelectedItemIds((prev) => new Map(prev).set(id, type));
    //   }
    //   setCurrentSelectedId(null);
    // } else {
    //   if (selectedItemIds.size) setSelectedItemIds(new Map());
    //   setCurrentSelectedId(id);
    // }
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
    setDragContext({ dragging: true, draggingId: id });

    const el = refListView?.current?.querySelector('#grouping-issue-drag-image');
    e.dataTransfer.setDragImage(el!, 24, 24);

    const selectedItems = selectedItemIds.size ? deskProjects.filter((item) => selectedItemIds.has(item.id)) : deskProjects.filter((item) => item.id === id);
    setOutsideDragContext({ id, view: dragView, title: selectedItems[0].title, type: 'project', data: selectedItems });
  };

  const handleDrag = (/*e: React.DragEvent<HTMLDivElement>*/) => {
    setDragContext({ dragging: true });
  };

  const handleDragEnd = () => {
    setDragContext({ dragging: false, draggingId: '' });
    setOutsideDragContext(null);
    setSelectedItemIds(new Map());
  };

  const selectedItems = Array.from(selectedItemIds).map((v) => ({ id: v[0], type: v[1] }));

  const handleFoldProjectDetail = () => {
    // if (window.location.pathname === '/task' || window.location.pathname === '/task/today') setFetchInstances(true);
    if (selectedProject) setChangedProjectId(selectedProject.id);
    setFetchInstancesFromProjectDetailView(true);
    setSelectedProject(null);
    onFetchProjects?.();
  };

  const handleDeleteProject = () => {
    if (!selectedProject) return;
    onDeleteProject?.(selectedProject.id);
    setSelectedProject(null);
    onFetchProjects?.();
  };

  const handleBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    const newProject = deskProjects.filter((item) => item.id !== result.draggableId);
    newProject.splice(result.destination.index, 0, deskProjects[result.source.index]);
    setDeskProjects([...newProject]);
    if (result.source.index < result.destination.index) {
      try {
        await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
          beforeId: deskProjects[result.destination.index].id,
          place: 'DESK',
        });
      } catch (error) {
        language === 'ko' ? toast.error('프로젝트 순서 변경에 실패하였습니다.') : toast.error('Failed to reorder project.');
        // toast.error('Failed to reorder project.');
      }
    } else {
      try {
        await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
          beforeId: result.destination.index - 1 < 0 ? undefined : deskProjects[result.destination.index - 1].id,
          place: 'DESK',
        });
      } catch (error) {
        language === 'ko' ? toast.error('프로젝트 순서 변경에 실패하였습니다.') : toast.error('Failed to reorder project.');
        // toast.error('Failed to reorder project.');
      }
    }
  };

  return (
    <Container ref={refListView} onDragEnd={handleDragEnd}>
      <ListHeaderWrapper>
        {title ? (
          <>{title}</>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '32px' }}>
            <ListTitle>
              {language === 'ko' ? '데스크의 프로젝트' : 'Projects in Desk'}
              {/* Projects in Desk */}
            </ListTitle>
            <ListTitleCount
              style={{ marginLeft: 4, color: deskProjects.length >= 7 ? COLORS.negative1 : 'inherit' }}
            >{`(${deskProjects.length}/7)`}</ListTitleCount>
            <IconButton onClick={() => navigate('/plan')} sx={{ marginLeft: '4px', padding: '4px' }}>
              <Icons.ArrowRightSmall />
            </IconButton>
          </div>
        )}
        {canAddItem && (
          <Tooltip
            placement="bottom-start"
            title={
              <div style={{ margin: '2px 4px' }}>
                <span>이슈 추가</span>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  {os === 'Windows' ? 'Ctrl' : '⌘'}
                </KeyboardButtonRect>
                + <KeyboardButtonRect small>/</KeyboardButtonRect>
              </div>
            }
          >
            <IconButton size={'small'} color="inherit" style={{ padding: 0 }} onClick={handleClickCreate}>
              <Icons.Plus width={16} height={16} strokeWidth={2} />
            </IconButton>
          </Tooltip>
        )}
      </ListHeaderWrapper>
      {isVisibleInput && (
        <div style={{ marginBottom: '8px' }}>
          <NewInputWrapper>
            <Icons.TaskCheckbox style={{ marginRight: 8, marginLeft: 16 }} />
            <TextField
              inputRef={refInput}
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder="새로운 이슈 만들기"
              onKeyDown={handleKeydownNewItemInput}
              onBlur={() => !refInput?.current?.value && setIsVisibleInput(false)}
              InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray800 } }}
            />
          </NewInputWrapper>
        </div>
      )}
      {deskProjects.length > 0 && (
        <DragDropContext onDragEnd={handleBeautifulDragEnd}>
          <Droppable droppableId="project">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={{ height: '100%' }}>
                <IssueListViewItemWrapper ref={refIssueListView}>
                  {deskProjects.map((v, index) => {
                    const isGroupSelecting = selectedItemIds.has(v.id);
                    const selectionCount = selectedItemIds.size;
                    const isHidden = (isGroupSelecting && dragging) || (selectionCount === 0 && v.id === draggingId && dragging);
                    return (
                      <IssueItem
                        key={v.id}
                        value={v}
                        editable={editable}
                        draggable={draggable}
                        selected={currentSelectedId === v.id}
                        groupSelected={multipleDrag && isGroupSelecting}
                        highlight={!!(selectedItems.length >= 2 && selectedItems[0].id === v.id)}
                        isGrouping={selectionCount > 0}
                        dragIndex={index}
                        hidden={isHidden}
                        popoverMenus={issuePopoverMenus}
                        spinBorderId={spinBorderId}
                        onDragStart={handleDragStart}
                        onDrag={handleDrag}
                        onClick={(e, id) => handleClickIssueItem(e, id)}
                        onChangeTask={onChangeItem}
                        onChangeTitle={onChangeTitle}
                        onClickContextMenu={onClickContextMenu}
                      />
                    );
                  })}
                </IssueListViewItemWrapper>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!isVisibleInput && deskProjects.length === 0 && (
        <div style={{ width: '100%', height: '100%', padding: '0px 32px' }}>
          <EmptyListWrapper>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 12, paddingTop: 12 }}>
              <Icons.Desk width={100} height={100} />
              <Typography variant={'subtitle2'} fontWeight={'bold'} fontSize={12} color={COLORS.gray700} style={{ marginLeft: 4, marginTop: 12 }}>
                {language === 'ko' ? '현재 집중할 프로젝트는 무엇인가요?' : 'What is your current focus project?'}
                {/* What is your current focus project? */}
              </Typography>
              <Button
                onClick={() => navigate('/plan')}
                sx={{
                  backgroundColor: COLORS.white,
                  border: `1px solid ${COLORS.gray200}`,
                  borderRadius: '8px',
                  color: COLORS.gray800,
                  fontSize: '13px',
                  fontWeight: 700,
                  marginTop: '20px',
                  padding: '6px 12px',
                }}
              >
                <span style={{ marginRight: 4 }}>
                  {language === 'ko' ? '데스크로 바로가기' : 'Go to desk'}
                  {/* Go to project */}
                </span>
                <Icons.ArrowRightSmall />
              </Button>
            </div>
          </EmptyListWrapper>
        </div>
      )}
      <IssueListDragImage id="grouping-issue-drag-image">
        <IssueListDragImageCount>{selectedItemIds.size || 1}</IssueListDragImageCount>
        <span style={{ fontSize: 13, marginLeft: 8 }}>선택됨</span>
      </IssueListDragImage>
    </Container>
  );
};

export default IssueListView;
