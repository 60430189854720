import { Icons } from 'components';
import { useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import Fonts from 'components/Fonts';

export const NotificationBar = () => {
  const [visibleUnreadAlert, setVisibleUnreadAlert] = useState(false);

  return (
    <Wrapper>
      <div className="flex gap-1 pb-6 pb-4">
        <Icons.Notice />
        <Fonts.H1>받은 알림</Fonts.H1>
      </div>
      {visibleUnreadAlert && (
        <article className="unread-import-alert p-4 mb-2 flex justify-between items-center">
          <div className="flex gap-1 items-center">
            <Icons.Alert fill={COLORS.negative1} height={14} width={14} />
            <Fonts.Blockquote>확인하지 않은 필수 알림이 있습니다.</Fonts.Blockquote>
          </div>
          <div className="cursor-pointer" onClick={() => setVisibleUnreadAlert(false)}>
            <Icons.Close width={12} height={12} />
          </div>
        </article>
      )}

      <section>
        <div className="flex items-center gap-2 pb-1 text-gray500">
          <div className="dot-green" />
          <Fonts.H5>새로운 알림</Fonts.H5>
        </div>
        <Fonts.Body2 className="gray900 pb-4">새로운 알림이 없습니다.</Fonts.Body2>
      </section>

      <section>
        <div className="flex items-center gap-2 py-1 text-gray500">
          <div className="dot-red" />
          <Fonts.H5>확인하지 않은 필수 일림</Fonts.H5>
        </div>

        <Fonts.Body2 className="gray900 pb-2">확인하지 않은 알림이 없습니다.</Fonts.Body2>
      </section>

      <section>
        <div className="flex items-center gap-2 pb-1 text-gray500">
          <Fonts.H5>기타 알림</Fonts.H5>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 32px;
  min-width: 372px;
  overflow-y: auto;
  background: ${COLORS.gray100};

  .dot-green {
    background-color: ${COLORS.positive1};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .dot-red {
    background-color: ${COLORS.negative1};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .unread-import-alert {
    background: #fcdfe6;
    border-radius: 8px;
  }
`;
