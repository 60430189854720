import { SVGProps } from 'react';

export const Link2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5629_38513)">
        <path
          d="M6.6665 9.33342C6.88376 9.55515 7.14307 9.73131 7.42926 9.85156C7.71544 9.97182 8.02275 10.0338 8.33317 10.0338C8.6436 10.0338 8.9509 9.97182 9.23709 9.85156C9.52327 9.73131 9.78258 9.55515 9.99984 9.33342L12.6665 6.66676C13.1085 6.22473 13.3569 5.62521 13.3569 5.00009C13.3569 4.37497 13.1085 3.77545 12.6665 3.33342C12.2245 2.89139 11.625 2.64307 10.9998 2.64307C10.3747 2.64307 9.7752 2.89139 9.33317 3.33342L8.99984 3.66676"
          stroke={props.stroke ? props.stroke : '#1F2023'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33342 6.66665C9.11617 6.44492 8.85686 6.26876 8.57067 6.14851C8.28449 6.02825 7.97718 5.96631 7.66676 5.96631C7.35633 5.96631 7.04903 6.02825 6.76284 6.14851C6.47665 6.26876 6.21734 6.44492 6.00009 6.66665L3.33342 9.33332C2.89139 9.77534 2.64307 10.3749 2.64307 11C2.64307 11.6251 2.89139 12.2246 3.33342 12.6666C3.77545 13.1087 4.37497 13.357 5.00009 13.357C5.62521 13.357 6.22473 13.1087 6.66676 12.6666L7.00009 12.3333"
          stroke={props.stroke ? props.stroke : '#1F2023'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5629_38513">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
