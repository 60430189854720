import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Popover, Divider } from '@mui/material';
import { Icons } from 'components';
import { SubtaskContextMenu } from './TimelineTaskbox';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface Props {
  taskId?: string | null;
  detail?: HTMLElement | null;
  menus?: SubtaskContextMenu[];
  onCloseDetail?: () => void;
  onClickSubtaskContextMenu?: (taskId: string, type: SubtaskContextMenu) => void;
}

export const SubtaskDetail = ({ taskId, detail, menus = [], onCloseDetail = () => {}, onClickSubtaskContextMenu = () => {} }: Props) => {
  const [language] = useAtom(languageAtom);
  return (
    <Popover
      open={Boolean(detail)}
      anchorEl={detail}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onCloseDetail}
      sx={{ marginTop: '4px' }}
    >
      <DetailModal>
        <MenuList>
          {menus.includes('LATER') && (
            <MenuItem className="item-text" onClick={() => onClickSubtaskContextMenu(taskId!, 'LATER')}>
              <span style={{ marginRight: 8 }}>
                <Icons.Later width={16} height={16} fill="#1C1B1F" />
              </span>
              <span>
                {language === 'ko' ? '나중에' : 'Later'}
                {/* Later */}
              </span>
            </MenuItem>
          )}
          {menus.includes('CONVERT_TO_TASKBOX') && (
            <MenuItem className="item-text" onClick={() => onClickSubtaskContextMenu(taskId!, 'CONVERT_TO_TASKBOX')}>
              <span style={{ marginRight: 8 }}>
                <Icons.ConvertToTaskbox width={16} height={16} fill="#1C1B1F" />
              </span>
              <span>
                {language === 'ko' ? '태스크박스로 전환하기' : 'Convert to taskbox'}
                {/* Convert to taskbox */}
              </span>
            </MenuItem>
          )}
          {menus.includes('DELETE') && (
            <MenuItem className="item-text" onClick={() => onClickSubtaskContextMenu(taskId!, 'DELETE')}>
              <Icons.Delete fill={COLORS.negative1} />
              <span style={{ marginLeft: '8px', color: `${COLORS.negative1}` }}>
                {language === 'ko' ? '삭제' : 'Delete'}
                {/* Delete */}
              </span>
            </MenuItem>
          )}
        </MenuList>
      </DetailModal>
    </Popover>
  );
};

const DetailModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 8px;
  z-index: 10;

  .item-text {
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
`;
