import styled from '@emotion/styled';
import { IconButton, Popover } from '@mui/material';
import { timerTypeAtom, pauseAtom, totalTimeAtom } from 'atoms/timer';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { TimerType } from 'atoms/timer';
import { TimerActionType } from 'pages/Task';
import React, { useState } from 'react';
import { COLORS } from 'styles/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TimerSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 700;
  padding: 8px;
`;

const TimerSelectMenu = styled.div`
  cursor: pointer;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

interface TimerProps {
  size?: 'small' | 'medium' | 'large';
  onChange?: (type: TimerActionType) => void;
}

const Timer = ({ size = 'large', onChange }: TimerProps) => {
  const [totalTime] = useAtom(totalTimeAtom);
  const [timerPause] = useAtom(pauseAtom);
  const [timerType, setTimerType] = useAtom(timerTypeAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const formatTime = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };

  const secondsToTimeString = (totalSeconds: number) => {
    const savedHours = Math.floor(totalSeconds / 3600);
    const savedMinutes = Math.floor((totalSeconds % 3600) / 60);
    const savedSeconds = totalSeconds % 60;

    return `${formatTime(savedHours)}:${formatTime(savedMinutes)}:${formatTime(savedSeconds)}`;
  };

  const handleClickStart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange && onChange('START');
  };

  const handleClickPause = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange && onChange('PAUSE');
  };

  const handleClickReset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange && onChange('RESET');
  };

  const handleClickTimerSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: TimerType) => {
    e.stopPropagation();
    setTimerType(type);
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <div
        style={{
          fontSize: size === 'small' ? '10px' : size === 'medium' ? '12px' : '14px',
          marginRight: '6px',
          color: timerPause ? COLORS.gray900 : COLORS.sub2,
          cursor: 'pointer',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {secondsToTimeString(totalTime)}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {timerPause && (
          <IconButton
            onClick={(e) => handleClickStart(e)}
            sx={{ 'width': '16px', 'height': '16px', 'padding': '2px', 'boxSizing': 'border-box', ':hover': { backgroundColor: COLORS.sub3 } }}
          >
            <Icons.TimerStart />
          </IconButton>
        )}
        {!timerPause && (
          <IconButton
            onClick={(e) => handleClickPause(e)}
            sx={{ 'width': '16px', 'height': '16px', 'padding': '2px', ':hover': { backgroundColor: COLORS.sub3 } }}
          >
            <Icons.TimerPause />
          </IconButton>
        )}
        {
          <IconButton
            onClick={(e) => handleClickReset(e)}
            sx={{ 'width': '16px', 'height': '16px', 'padding': '2px', ':hover': { backgroundColor: COLORS.sub3 } }}
          >
            <Icons.TimerStop />
          </IconButton>
        }
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{ zIndex: 10000 }}
      >
        <TimerSelectWrapper>
          <TimerSelectMenu onClick={(e) => handleClickTimerSelect(e, 'COUNTUP')}>COUNT UP</TimerSelectMenu>
          <TimerSelectMenu onClick={(e) => handleClickTimerSelect(e, 'COUNTDOWN')}>COUNT DOWN</TimerSelectMenu>
        </TimerSelectWrapper>
      </Popover>
    </Wrapper>
  );
};

export default Timer;
