import { SVGProps } from 'react';

export const LinkToProject = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.11453 9.88618L9.88577 6.11494M4.70057 7.529L3.75776 8.47181C2.71636 9.51321 2.71606 11.2018 3.75746 12.2432C4.79886 13.2846 6.4878 13.2843 7.5292 12.2429L8.471 11.3002M7.5286 4.70033L8.47141 3.75752C9.5128 2.71612 11.201 2.71631 12.2424 3.7577C13.2838 4.7991 13.2838 6.48756 12.2424 7.52896L11.3001 8.47173"
        stroke={props.stroke || '#1F2023'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
