import { Swiper, SwiperSlide } from 'swiper/react'; // basic
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from '@emotion/styled';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Container = styled.div`
  width: 100%;
  height: 100%;

  .swiper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    min-width: 640px;

    .swiper-idx {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #ffffff;
      opacity: 0.5;
      padding-bottom: 16px;
    }
    .swiper-title {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      letter-spacing: -0.25px;
      color: #ffffff;
      white-space: pre-line;
      padding-bottom: 24px;
    }
    .swiper-img {
      margin: 0 auto;
    }
    .swiper-icon {
      width: 4px;
      height: 4px;
      background-color: #ffffff;
      border-radius: 100px;
      margin-right: 4px;
      margin-bottom: 26px;
      opacity: 30%;
    }
  }
`;

export interface SlideProps {
  pages: Array<{
    title: string;
    image: string;
  }>;
}

const Slide = ({ pages = [] }: SlideProps) => {
  return (
    <Container>
      <Swiper spaceBetween={50} slidesPerView={1} autoplay={{ delay: 3000 }}>
        {pages?.map((v, idx) => (
          <SwiperSlide key={idx}>
            <div className="swiper">
              <div className="swiper-idx">{`0${idx + 1}`}</div>
              <div className="swiper-title">{v.title}</div>
              <div className="flex">
                <div className="swiper-icon" style={idx === 0 ? { width: '32px', opacity: '100%' } : {}} />
                <div className="swiper-icon" style={idx === 1 ? { width: '32px', opacity: '100%' } : {}} />
                <div className="swiper-icon" style={idx === 2 ? { width: '32px', opacity: '100%' } : {}} />
              </div>
              <div className="swiper-img">
                <img src={v.image} style={{ width: idx === 1 ? '618px' : '500px' }} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default Slide;
