import { SVGProps } from 'react';

export const ChevronLeftDuo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M11.3307 10.6693L8.66406 8.0026L11.3307 5.33594M7.33073 10.6693L4.66406 8.0026L7.33073 5.33594"
        stroke={props.stroke || '#0039A7'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
