import { SVGProps } from 'react';

export const InstanceLater = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0_22270_225419)">
        <circle cx="8" cy="8" r="8" fill="#C8CDDB" />
        <path d="M4 8L12 8M12 8L8.57143 5M12 8L8.57143 11" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_22270_225419">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};
