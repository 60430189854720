import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';

interface ConvertGuideProps {
  onClickNext?: (index: number) => void;
  onClose?: () => void;
}

const ConvertGuide = ({ onClickNext, onClose }: ConvertGuideProps) => {
  const [language] = useAtom(languageAtom);
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState<number>(0);
  const refContainer = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const guideList = [
    {
      title: (
        <>
          {language === 'ko' ? (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                중요한 업무는 <Icons.Issue style={{ margin: '0px 4px 0px 8px' }} />
                &apos;프로젝트&apos;로,
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                반복되는 업무는 <Icons.Routine width={16} height={16} style={{ margin: '0px 4px 0px 8px' }} />
                &apos;루틴&apos;으로 설정해보세요!
              </div>
            </div>
          ) : (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Set important tasks <Icons.Issue style={{ margin: '0px 4px 0px 8px' }} />
                as &apos;Projects&apos;
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                and recurring tasks <Icons.Routine width={16} height={16} style={{ margin: '0px 4px 0px 8px' }} />
                as &apos;Routines&apos;!
              </div>
            </div>
          )}
        </>
      ),
      content: (
        <>
          {language === 'ko' ? (
            <>
              <div>매번 새로운 태스크를 만드느라 인지력을 낭비하지 마세요</div>
              <div>한 번 설정해서 매일 5분씩 계획 시간을 아끼세요</div>
            </>
          ) : (
            <>
              <div>Save mental energy—set tasks once</div>
              <div>and reclaim five minutes of planning daily</div>
            </>
          )}
        </>
      ),
      src: require(language === 'ko' ? 'assets/images/convert_guide1.png' : 'assets/images/convert_guide1_en.png'),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icons.Issue />
          <span style={{ marginLeft: 8 }}>{language === 'ko' ? '프로젝트' : 'Project'}</span>
        </div>
      ),
      content: (
        <>
          {language === 'ko' ? (
            <>
              <div>기한이 있는 중요한 업무를 프로젝트로 설정해</div>
              <div>꾸준히 관리하세요</div>
            </>
          ) : (
            <>
              <div>Set important, time-sensitive tasks as projects</div>
              <div> for consistent management</div>
            </>
          )}
        </>
      ),
      src: require(language === 'ko' ? 'assets/images/convert_guide2.png' : 'assets/images/convert_guide2_en.png'),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icons.Routine width={16} height={16} />
          <span style={{ marginLeft: 8 }}>{language === 'ko' ? '루틴' : 'Routine'}</span>
        </div>
      ),
      content: <>{language === 'ko' ? <span>정기적으로 반복되는 일을 루틴으로 체계화하세요</span> : <span>Systematize recurring tasks into routines</span>}</>,
      src: require(language === 'ko' ? 'assets/images/convert_guide3.png' : 'assets/images/convert_guide3_en.png'),
    },
  ];

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (refContainer.current) {
        adjustPopoverPosition(refContainer.current);
      }
    });

    const papers = document.querySelectorAll('.MuiPaper-root');
    papers.forEach((paper) => observer.observe(paper, { attributes: true, childList: true, subtree: true }));

    return () => observer.disconnect();
  }, []);

  const adjustPopoverPosition = (container: HTMLDivElement) => {
    const rect = container.getBoundingClientRect();
    const overflow = rect.bottom - window.innerHeight;

    if (overflow > 0) {
      const papers = document.querySelector<HTMLDivElement>('.convert-guide-popover');
      const el = papers?.querySelector('.MuiPaper-root') as HTMLDivElement;

      if (el) {
        const currentTop = parseInt(el.style.top || '0', 10); // 현재 top 값을 숫자로 가져오기
        el.style.top = `${currentTop - overflow - 10}px`; // 새로운 top 값 설정
        setOverflow(true);
      }
    }
    setTimeout(() => setVisible(true), 500);
  };

  const handleClickNext = () => {
    onClickNext?.(index);
    setIndex(index + 1);
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Container ref={refContainer} visible={visible} isOverflow={overflow}>
      <Header>
        {guideList[index].title}
        <IconButton onClick={handleClose} sx={{ width: '24px', height: '24px', padding: '4px' }}>
          <Icons.Close width={19} height={19} stroke={COLORS.gray400} />
        </IconButton>
      </Header>
      <Main>{guideList[index].content}</Main>
      <img src={guideList[index].src} alt="guide1" style={{ width: index === 0 ? '325px' : '272px', marginBottom: 16 }} />
      <Footer>
        <div style={{ fontSize: '13px', fontWeight: 700, color: COLORS.brand1 }}>{`${index + 1} / ${guideList.length}`}</div>
        <Button
          variant="contained"
          onClick={index === guideList.length - 1 ? handleClose : handleClickNext}
          sx={{
            width: '55px',
            minWidth: '55px',
            height: '40px',
            boxShadow: 'none',
            borderRadius: '8px',
            fontSize: '13px',
            fontWeight: 700,
            padding: ' 10px 16px',
          }}
        >
          {index === guideList.length - 1 ? (language === 'ko' ? '완료' : 'OK!') : language === 'ko' ? '다음' : 'Next'}
        </Button>
      </Footer>
    </Container>
  );
};

export default ConvertGuide;

const Container = styled.div<{ visible?: boolean; isOverflow?: boolean }>`
  background-color: ${COLORS.white};
  border: 1px solid #e7eaf4;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  padding: 24px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 12px 10px 12px 0px;
    border-color: transparent ${COLORS.white};
    display: block;
    width: 0;
    z-index: 0;
    top: ${(props) => (props.isOverflow ? '250px' : '50px')};
    left: 4%;
    @media screen and (max-width: 650px) {
      left: 8px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Main = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
