import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { Work } from '../Icons';

interface MenuProps {
  label?: string;
  focus: boolean;
  onClick: () => void;
}

export const WorkMenu = ({ focus = false, onClick, label }: MenuProps) => {
  if (focus) {
    return (
      <svg width="80px" height="80px" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H80V80H8C3.58172 80 0 76.4183 0 72V8Z" fill="#F2F5FC" />
        <path
          d="M8 0C3.58172 0 0 3.58172 0 8V72C0 76.4183 3.58172 80 8 80H80C80 75.5817 76.4183 72 72 72H16C11.5817 72 8 68.4183 8 64V16C8 11.5817 11.5817 8 16 8H72C76.4183 8 80 4.41828 80 0H8Z"
          fill="white"
        />
        <g clipPath="url(#clip0_1858_1512)">
          <path
            d="M35.9998 19.6667H33.3332C32.6259 19.6667 31.9476 19.9477 31.4476 20.4478C30.9475 20.9479 30.6665 21.6262 30.6665 22.3334V38.3334C30.6665 39.0407 30.9475 39.7189 31.4476 40.219C31.9476 40.7191 32.6259 41.0001 33.3332 41.0001H46.6665C47.3737 41.0001 48.052 40.7191 48.5521 40.219C49.0522 39.7189 49.3332 39.0407 49.3332 38.3334V22.3334C49.3332 21.6262 49.0522 20.9479 48.5521 20.4478C48.052 19.9477 47.3737 19.6667 46.6665 19.6667H43.9998"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.3333 17H38.6667C37.1939 17 36 18.1939 36 19.6667C36 21.1394 37.1939 22.3333 38.6667 22.3333H41.3333C42.8061 22.3333 44 21.1394 44 19.6667C44 18.1939 42.8061 17 41.3333 17Z"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M36 29H36.0133" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M41.3335 29H44.0002" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M36 34.3333H36.0133" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M41.3335 34.3333H44.0002" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <path
          d="M32.793 52.8711C31.1523 52.8594 29.9453 53.9316 29.957 55.3906C29.9453 56.8789 31.1523 57.9453 32.793 57.9453C34.1641 57.9453 35.2598 57.1484 35.5352 55.9766H37.3164V58.1445H38.8047V52.4141H37.3164V54.7812H35.5293C35.2363 53.6387 34.1523 52.8594 32.793 52.8711ZM31.375 55.3906C31.3691 54.5938 31.9551 54.1074 32.793 54.1133C33.6016 54.1074 34.1816 54.5938 34.1875 55.3906C34.1816 56.1875 33.6016 56.6914 32.793 56.6914C31.9551 56.6914 31.3691 56.1875 31.375 55.3906ZM31.6914 62.9609H38.8047V58.6133H37.3164V59.6445H33.168V58.6133H31.6914V62.9609ZM33.168 61.7539V60.793H37.3164V61.7539H33.168ZM49.6875 58.3789H39.9258V59.5742H44.0391V63.0664H45.5156V59.5742H49.6875V58.3789ZM41.0742 57.2305H48.5156V52.9297H41.0742V57.2305ZM42.5508 56.0586V54.1133H47.0273V56.0586H42.5508Z"
          fill="#0039A7"
        />
        <defs>
          <clipPath id="clip0_1858_1512">
            <rect width="32" height="32" fill="white" transform="translate(24 13)" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <Wrapper onClick={onClick}>
        <Container>
          <Work fill={COLORS.gray700} />
        </Container>
        <Fonts.H5>업무</Fonts.H5>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1px;
  background-color: ${COLORS.white};
  color: ${COLORS.gray700};
`;

const Container = styled.div`
  margin-bottom: 2px;
`;
