import { SVGProps } from 'react';

export const Flame = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.17683 5.29405C5.1102 6.00482 5.06344 7.26269 5.48312 7.79811C5.48312 7.79811 5.28555 6.41632 7.05663 4.68265C7.76973 3.98474 7.93457 3.03549 7.68556 2.32355C7.54411 1.92024 7.28576 1.58707 7.0613 1.35443C6.93037 1.21766 7.03091 0.992033 7.22146 1.00022C8.37412 1.05165 10.2422 1.37197 11.036 3.36399C11.3844 4.23842 11.4101 5.14208 11.2441 6.06093C11.1389 6.64778 10.7648 7.95242 11.6182 8.11257C12.2272 8.22714 12.5218 7.74316 12.6539 7.39479C12.7089 7.24983 12.8994 7.21359 13.0023 7.32933C14.031 8.49952 14.1187 9.8778 13.906 11.0644C13.4945 13.358 11.1716 15.0274 8.86394 15.0274C5.98112 15.0274 3.68633 13.3779 3.09129 10.3922C2.85164 9.18691 2.97322 6.80209 4.83197 5.1187C4.96992 4.99244 5.19554 5.10467 5.17683 5.29405Z"
        fill="url(#paint0_radial_26274_98006)"
      />
      <path
        d="M9.92622 9.58128C8.86358 8.21352 9.33937 6.65287 9.60006 6.03095C9.63513 5.94912 9.54161 5.87196 9.46796 5.92223C9.01087 6.23319 8.07448 6.965 7.63844 7.99492C7.04808 9.38723 7.09016 10.0688 7.4397 10.9011C7.65013 11.4026 7.4058 11.509 7.28305 11.5277C7.16381 11.5464 7.05392 11.4669 6.96625 11.3839C6.71401 11.1418 6.53427 10.8341 6.4472 10.4955C6.42849 10.423 6.3338 10.4031 6.29055 10.4627C5.96322 10.9151 5.79371 11.6411 5.78553 12.1543C5.75981 13.7407 7.07029 15.0266 8.65549 15.0266C10.6534 15.0266 12.1088 12.8172 10.9608 10.9701C10.6276 10.4323 10.3143 10.0805 9.92622 9.58128Z"
        fill="url(#paint1_radial_26274_98006)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_26274_98006"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.29296 15.0637) rotate(-179.751) scale(8.25188 13.5397)"
        >
          <stop offset="0.314" stopColor="#FF9800" />
          <stop offset="0.662" stopColor="#FF6D00" />
          <stop offset="0.972" stopColor="#F44336" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_26274_98006"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.76537 6.85025) rotate(90.5787) scale(8.63401 6.49774)"
        >
          <stop offset="0.214" stopColor="#FFF176" />
          <stop offset="0.328" stopColor="#FFF27D" />
          <stop offset="0.487" stopColor="#FFF48F" />
          <stop offset="0.672" stopColor="#FFF7AD" />
          <stop offset="0.793" stopColor="#FFF9C4" />
          <stop offset="0.822" stopColor="#FFF8BD" stopOpacity="0.804" />
          <stop offset="0.863" stopColor="#FFF6AB" stopOpacity="0.529" />
          <stop offset="0.91" stopColor="#FFF38D" stopOpacity="0.209" />
          <stop offset="0.941" stopColor="#FFF176" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
