import { SVGProps } from 'react';

export const DropZone = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_16487_234330)">
        <path
          d="M2.66602 9.33594V12.3359C2.66602 12.8882 3.11373 13.3359 3.66602 13.3359H12.3327C12.885 13.3359 13.3327 12.8882 13.3327 12.3359V9.33594"
          stroke={props.stroke ? props.stroke : '#0039A7'}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M11.334 7.0625L8.00065 10.3958L4.66732 7.0625"
          stroke={props.stroke ? props.stroke : '#0039A7'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 2.0625L8 10.0625" stroke={props.stroke ? props.stroke : '#0039A7'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_16487_234330">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
