import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@mui/material';
import { useKeyboardEvent } from '@react-hookz/web';
import { focusModeTaskboxAtom, hideFocusModeTaskboxAtom } from 'atoms/focus';
import { userStateAtom } from 'atoms/userState';
import { Icons } from 'components';
import dayjs from 'lib/dayjs';
import { useAtom } from 'jotai';
import { updateStateStorageV1StateStoragePatch, updateTaskboxV1TaskboxesTaskboxIdPut } from 'queries';
import { OutTaskboxDetail, OutTaskboxDetailResponse, UpdateTaskbox } from 'queries/model';
import { COLORS } from 'styles/constants';
import { ongoingTaskboxAtom } from 'atoms/ongoingTaskbox';
import { languageAtom } from 'atoms/language';
import { set } from 'lodash';
import toast from 'react-hot-toast';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';
import { useState } from 'react';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.gray900};
  padding: 0px 30px;
`;

const UniversalIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UniversalFocusModeTaskboxWrapper = styled.div<{ taskbox?: OutTaskboxDetailResponse }>`
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(270deg, #4760de -0.16%, #2a92f3 99.84%);
  border-radius: 8px;
  color: ${COLORS.white};
  cursor: pointer;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 700;
  ${(props) =>
    props.taskbox?.isRecurrence
      ? `background: linear-gradient(63deg, #02CB8F 17.47%, #0CB0BB 83.26%);`
      : props.taskbox?.project
      ? `background: linear-gradient(63deg, #CD6CFC 17.47%, #9C17DB 83.26%);`
      : props.taskbox?.focus
      ? `background: linear-gradient(243deg, #C471ED 16.99%, #F9787F 82.79%);`
      : `background: linear-gradient(270deg, #4760de -0.16%, #2a92f3 99.84%);`}
`;

const UniversalFocusModeTaskboxTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UniversalFocusModeTaskboxTitle = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UniversalTaskViewControlWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

export const Universal = () => {
  const [, setFocusModeTaskbox] = useAtom(focusModeTaskboxAtom);
  const [userState, setUserStat] = useAtom(userStateAtom);
  const [language] = useAtom(languageAtom);
  const [ongoingTaskbox, setOngoingTaskbox] = useAtom(ongoingTaskboxAtom);
  const [isHoverOngoingTaskbox, setIsHoverOngoingTaskbox] = useState(false);
  const [isHoverCompleteNow, setIsHoverCompleteNow] = useState(false);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement;
      if (ev.shiftKey && ev.code === 'KeyF') {
        if (!element) return;
        if (
          element.tagName === 'TEXTAREA' ||
          (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
          (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        ) {
          return;
        }
        handleClickShowFocusModeTaskbox();
      }
    },
    [],
    { event: 'keydown', eventOptions: { passive: false } },
  );

  const handleClickListView = async () => {
    const success = await updateStateStorageV1StateStoragePatch({
      data: { ...userState.data, taskViewMode: userState.data.taskViewMode === 'MERGED' ? 'SPLIT' : 'MERGED' },
    });

    if (success) {
      setUserStat({
        ...userState,
        data: {
          ...userState.data,
          taskViewMode: userState.data.taskViewMode === 'MERGED' ? 'SPLIT' : 'MERGED',
        },
      });
    }
  };

  const handleClickShowSubtask = async () => {
    const success = await updateStateStorageV1StateStoragePatch({
      data: { ...userState.data, isShowingSubtask: !userState.data.isShowingSubtask },
    });

    if (success) {
      setUserStat({
        ...userState,
        data: {
          ...userState.data,
          isShowingSubtask: !userState.data.isShowingSubtask,
        },
      });
    }
  };

  const handleClickShowFocusModeTaskbox = () => {
    setFocusModeTaskbox(ongoingTaskbox);
  };

  const handleCompleteNowOngoingTaskbox = async () => {
    const taskbox = ongoingTaskbox;
    if (!taskbox) return;

    const newTaskbox: OutTaskboxDetailResponse = { ...taskbox, end: { datetime: dayjs().format(DATE_FORMAT_1) }, done: true };
    setOngoingTaskbox(newTaskbox);

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, newTaskbox as UpdateTaskbox);
    } catch (e) {
      toast.error(language === 'ko' ? '지금 완료하기에 실패하였습니다.' : 'Failed to complete now.');
      setOngoingTaskbox(taskbox);
    }
  };

  return (
    <Container>
      <UniversalIconsWrapper>
        <Icons.Slash style={{ marginRight: 4 }} />
        <Icons.Beta />
      </UniversalIconsWrapper>
      {ongoingTaskbox && (
        <Tooltip
          disableInteractive
          open={isHoverOngoingTaskbox && !isHoverCompleteNow}
          title={
            <div style={{ margin: '2px 4px' }}>
              <span>{language === 'ko' ? '포커스모드로 보기' : 'View in Focus Mode'}</span>
              <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                Shift
              </KeyboardButtonRect>
              + <KeyboardButtonRect small>F</KeyboardButtonRect>
            </div>
          }
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              },
            ],
          }}
        >
          <UniversalFocusModeTaskboxWrapper
            taskbox={ongoingTaskbox || undefined}
            onClick={handleClickShowFocusModeTaskbox}
            onMouseEnter={() => setIsHoverOngoingTaskbox(true)}
            onMouseLeave={() => setIsHoverOngoingTaskbox(false)}
          >
            <UniversalFocusModeTaskboxTitleWrapper>
              <Icons.Flame style={{ marginRight: 8 }} />
              <UniversalFocusModeTaskboxTitle style={{ marginRight: 8 }}>{ongoingTaskbox?.title}</UniversalFocusModeTaskboxTitle>
              {!ongoingTaskbox?.allDay && (
                <div style={{ marginRight: 8 }}>{`${dayjs(ongoingTaskbox?.start?.datetime, { utc: true }).format('a h:mm')}-${dayjs(
                  ongoingTaskbox?.end?.datetime,
                  { utc: true },
                ).format('a h:mm')}`}</div>
              )}
            </UniversalFocusModeTaskboxTitleWrapper>
            <Tooltip disableInteractive title={language === 'ko' ? '지금 완료하기' : 'Complete Now'}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleCompleteNowOngoingTaskbox();
                }}
                onMouseEnter={() => setIsHoverCompleteNow(true)}
                onMouseLeave={() => setIsHoverCompleteNow(false)}
                sx={{
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Icons.DoubleCheck width={14} height={14} stroke={COLORS.white} />
              </IconButton>
            </Tooltip>
          </UniversalFocusModeTaskboxWrapper>
        </Tooltip>
      )}
      <UniversalTaskViewControlWrapper>
        {/* <IconButton
          onClick={handleClickListView}
          sx={{
            borderRadius: '6px',
            backgroundColor: userState.data.taskViewMode === 'MERGED' ? COLORS.sub2 : 'transparent',
            padding: '4px',
            marginRight: '10px',
          }}
        >
          <Icons.ListView />
        </IconButton> */}
        <IconButton
          onClick={handleClickShowSubtask}
          sx={{
            'borderRadius': '6px',
            'backgroundColor': userState.data.isShowingSubtask ? 'transparent' : COLORS.sub2,
            'padding': '4px',
            ':hover': {
              backgroundColor: userState.data.isShowingSubtask ? COLORS.gray800 : '#003DB2',
            },
          }}
        >
          <Icons.SubTaskCollapse fill={COLORS.white} />
        </IconButton>
      </UniversalTaskViewControlWrapper>
    </Container>
  );
};
