import { SVGProps } from 'react';

export const PriorityIssueCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="9.4" fill={props.fill ? props.fill : '#0039A7'} stroke={props.stroke ? props.stroke : '#0039A7'} strokeWidth="1.2" />
      <path d="M5 10.625L8.33333 13.75L15 7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
