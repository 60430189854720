import { SVGProps } from 'react';

export const ConvertToTaskbox = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99958 2.5C4.96225 2.5 2.5 4.96225 2.5 7.99958C2.5 11.0369 4.96225 13.4992 7.99958 13.4992C8.09657 13.4992 8.19298 13.4966 8.28874 13.4917C8.50363 14.0242 8.82981 14.4999 9.23847 14.8899C8.83636 14.9617 8.42235 14.9992 7.99958 14.9992C4.13382 14.9992 1 11.8653 1 7.99958C1 4.13382 4.13382 1 7.99958 1C11.8653 1 14.9992 4.13382 14.9992 7.99958C14.9992 8.41998 14.9621 8.83173 14.8911 9.23173C14.5007 8.82346 14.0247 8.49774 13.492 8.28337C13.4967 8.18938 13.4992 8.09476 13.4992 7.99958C13.4992 4.96225 11.0369 2.5 7.99958 2.5ZM11.1698 6.98728C11.472 6.70398 11.4873 6.22936 11.204 5.92717C10.9207 5.62499 10.4461 5.60968 10.1439 5.89297L7.32377 8.53687L6.17017 7.45537C5.86799 7.17207 5.39336 7.18738 5.11006 7.48957C4.82677 7.79175 4.84208 8.26638 5.14426 8.54967L6.81082 10.1121C7.09931 10.3825 7.54823 10.3825 7.83673 10.1121L11.1698 6.98728Z"
        fill="#1F2023"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9393 9.56171C13.1693 9.3396 13.5369 9.34475 13.7604 9.5732L14.8357 10.6721C15.0548 10.896 15.0548 11.2525 14.8357 11.4764L13.7604 12.5753C13.5369 12.8038 13.1693 12.8089 12.9393 12.5868C12.7094 12.3647 12.7042 11.9995 12.9278 11.771L13.045 11.6512H10.9247C10.5184 11.6512 10.1613 11.9925 10.1613 12.4479C10.1613 12.9033 10.5184 13.2446 10.9247 13.2446H12.2688C12.5895 13.2446 12.8495 13.5029 12.8495 13.8215C12.8495 14.1401 12.5895 14.3984 12.2688 14.3984H10.9247C9.84642 14.3984 9 13.5097 9 12.4479C9 11.3861 9.84642 10.4973 10.9247 10.4973H13.045L12.9278 10.3775C12.7042 10.1491 12.7094 9.78382 12.9393 9.56171Z"
        fill="#1F2023"
      />
    </svg>
  );
};
