import styled from '@emotion/styled';
import { Divider, IconButton, Popover, TextField } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { categoryColors } from 'constants/categoryColor';
import { useAtom } from 'jotai';
import { OutCategory } from 'queries/model';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';

export const CategoryAction = {
  CREATE: 'CREATE',
  SELECT: 'SELECT',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  DETACH: 'DETACH',
} as const;

export type CategoryActionType = typeof CategoryAction[keyof typeof CategoryAction];

interface CategoryPopoverProps {
  categoryList?: OutCategory[];
  onClickCategoryAction?: (category: OutCategory, action: CategoryActionType) => void;
}

const CategoryPopover = ({ categoryList = [], onClickCategoryAction }: CategoryPopoverProps) => {
  const [language] = useAtom(languageAtom);
  const [category, setCategory] = useState<OutCategory[]>(categoryList);
  const [filteredCategory, setFilteredCategory] = useState<OutCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<OutCategory | null>(null);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [updateCategoryAnchorEl, setUpdateCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [hoverId, setHoverId] = useState<string | null>(null);

  useEffect(() => {
    setCategory(categoryList);
    if (newCategoryName) setFilteredCategory(categoryList.filter((c) => c.name.includes(newCategoryName)));
  }, [categoryList]);

  const handleFilterCategory = (value: string) => {
    if (value === '') {
      setFilteredCategory([]);
      setNewCategoryName('');
      return;
    }

    setNewCategoryName(value);
    const filtered = category.filter((c) => c.name.includes(value));
    setFilteredCategory(filtered);
  };

  const handleKeyDownTextField = (e: React.KeyboardEvent) => {
    if (e.nativeEvent.isComposing === false && e.key === 'Enter' && newCategoryName !== '') {
      e.preventDefault();

      if (categoryList.map((v) => v.name).includes(newCategoryName)) {
        language === 'ko' ? toast.error('이미 존재하는 카테고리입니다.') : toast.error('This category already exists.');
        // toast.error('This category already exists.');
        return;
      }

      if (category.length === 8) {
        language === 'ko' ? toast.error('최대 8개까지 생성할 수 있습니다.') : toast.error('Create up to 8 categories.');
        // toast.error('Create up to 8 categories.');
        return;
      }

      const filteredColors = categoryColors.filter((c) => !category.map((v) => v.color).includes(c.color));
      const newCategory = {
        id: uuidv4(),
        name: newCategoryName,
        color: filteredColors[0].color,
      };

      onClickCategoryAction && onClickCategoryAction(newCategory as OutCategory, 'CREATE');
      setNewCategoryName('');
      setFilteredCategory([]);

      setTimeout(() => {
        onClickCategoryAction && onClickCategoryAction(newCategory as OutCategory, 'SELECT');
      }, 500);
      return;
    }

    if (e.key === 'ArrowDown') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredCategory.length > 0 ? setHoverId(filteredCategory[0].id) : setHoverId(category[0].id);
      const el =
        filteredCategory.length > 0
          ? (document.querySelector(`[data-category-id="${filteredCategory[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-category-id="${category[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredCategory.length > 0 ? setHoverId(filteredCategory[filteredCategory.length - 1].id) : setHoverId(category[category.length - 1].id);
      const el =
        filteredCategory.length > 0
          ? (document.querySelector(`[data-category-id="${filteredCategory[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-category-id="${category[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }
  };

  const handleKeyDownCategory = (e: React.KeyboardEvent, category: OutCategory) => {
    if (e.key === 'Enter') {
      onClickCategoryAction && onClickCategoryAction(category, 'SELECT');
      return;
    }

    if (e.key === 'ArrowDown') {
      const index = filteredCategory.length > 0 ? filteredCategory.findIndex((c) => c.id === hoverId) : categoryList.findIndex((c) => c.id === hoverId);
      if (index === filteredCategory.length - 1 || index === categoryList.length - 1) return;

      const nextCategory = filteredCategory.length > 0 ? filteredCategory[index + 1] : categoryList[index + 1];
      setHoverId(nextCategory.id);
      const el = document.querySelector(`[data-category-id="${nextCategory.id}"]`) as HTMLElement;
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const index = filteredCategory.length > 0 ? filteredCategory.findIndex((c) => c.id === hoverId) : categoryList.findIndex((c) => c.id === hoverId);
      if (index === 0) return;

      const nextCategory = filteredCategory.length > 0 ? filteredCategory[index - 1] : categoryList[index - 1];
      setHoverId(nextCategory.id);
      const el = document.querySelector(`[data-category-id="${nextCategory.id}"]`) as HTMLElement;
      el.focus();
      return;
    }
  };

  const handleClickCreateNewCategory = () => {
    if (newCategoryName === '') return;
    if (filteredCategory.length > 0) {
      language === 'ko' ? toast.error('이미 존재하는 카테고리입니다.') : toast.error('This category already exists.');
      // toast.error('This category already exists.');
      return;
    }

    if (category.length === 8) {
      language === 'ko' ? toast.error('최대 8개까지 생성할 수 있습니다.') : toast.error('Create up to 8 categories.');
      // toast.error('Create up to 8 categories.');
      return;
    }

    const filteredColors = categoryColors.filter((c) => !category.map((v) => v.color).includes(c.color));
    const newCategory = {
      id: uuidv4(),
      name: newCategoryName,
      color: filteredColors[0].color,
    };
    onClickCategoryAction && onClickCategoryAction(newCategory as OutCategory, 'CREATE');
    setNewCategoryName('');
    setFilteredCategory([]);
  };

  const handleClickUpdateButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, category: OutCategory) => {
    e.stopPropagation();
    setUpdateCategoryAnchorEl(e.currentTarget);
    setSelectedCategory(category);
  };

  const handleClickDeleteButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, category: OutCategory) => {
    e.stopPropagation();
    onClickCategoryAction && onClickCategoryAction(category, 'DELETE');
  };

  return (
    <Container className="category-popover">
      <TextFieldWrapper>
        <TextField
          autoFocus
          autoComplete="off"
          fullWidth
          placeholder={
            language === 'ko' ? '카테고리를 선택하거나 생성하세요' : 'Select or create a category'
            // 'Select or create a category'
          }
          value={newCategoryName}
          inputProps={{
            style: {
              padding: '0px',
              fontSize: '12px',
            },
          }}
          onChange={(e) => handleFilterCategory(e.target.value)}
          onKeyDown={(e) => handleKeyDownTextField(e)}
        />
      </TextFieldWrapper>

      {category.length === 0 && newCategoryName === '' && (
        <>
          <Divider />
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px', padding: '12px 16px' }}>
            <Icons.ExclamationInfo width={12} height={12} />
            <span style={{ marginLeft: '4px' }}>
              {language === 'ko' ? '최대 8개까지 생성해서 관리할 수 있어요' : 'You can create and manage up to 8 categories'}
              {/* You can create and manage up to 8 categories */}
            </span>
          </div>
        </>
      )}
      {filteredCategory.length === 0 && newCategoryName !== '' && (
        <>
          <Divider />
          <div style={{ padding: '8px' }}>
            <CreateNewCategoryWrapper onClick={handleClickCreateNewCategory}>
              <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
              <span style={{ fontSize: '12px', color: COLORS.gray500, margin: '0px 4px' }}>
                {language === 'ko' ? '새 카테고리 추가:' : 'Add new category:'}
                {/* Add new category: */}
              </span>
              <div
                style={{ backgroundColor: COLORS.gray200, borderRadius: '4px', color: COLORS.gray600, fontSize: '10px', padding: '2px 6px' }}
              >{`# ${newCategoryName}`}</div>
            </CreateNewCategoryWrapper>
          </div>
        </>
      )}
      {(filteredCategory.length > 0 || category.length > 0) && (
        <>
          <Divider />
          <div style={{ padding: '8px' }}>
            {filteredCategory.length > 0
              ? filteredCategory.map((v) => (
                  <CategoryShowingWrapper
                    key={v.id}
                    data-category-id={v.id}
                    tabIndex={0}
                    isHover={hoverId === v.id}
                    onClick={() => onClickCategoryAction && onClickCategoryAction(v, 'SELECT')}
                    onKeyDown={(e) => handleKeyDownCategory(e, v)}
                  >
                    <CategoryShowingName
                      textColor={categoryColors.find((c) => c.color === v.color)?.textColor}
                      bgColor={categoryColors.find((c) => c.color === v.color)?.bgColor}
                    >
                      {`# ${v.name}`}
                    </CategoryShowingName>
                    <IconButtonWrapper>
                      <IconButton
                        onClick={(e) => handleClickUpdateButton(e, v)}
                        sx={{
                          borderRadius: '6px',
                          padding: '4px',
                        }}
                      >
                        <Icons.Edit width={16} height={16} stroke={COLORS.gray300} />
                      </IconButton>
                      <div className="delete-btn">
                        <IconButton className="delete" onClick={(e) => handleClickDeleteButton(e, v)} sx={{ borderRadius: '6px', padding: '4px' }}>
                          <Icons.Delete width={16} height={16} fill={COLORS.gray300} />
                        </IconButton>
                      </div>
                    </IconButtonWrapper>
                  </CategoryShowingWrapper>
                ))
              : category.map((v) => (
                  <CategoryShowingWrapper
                    key={v.id}
                    tabIndex={0}
                    data-category-id={v.id}
                    isHover={hoverId === v.id}
                    onClick={() => onClickCategoryAction && onClickCategoryAction(v, 'SELECT')}
                    onKeyDown={(e) => handleKeyDownCategory(e, v)}
                  >
                    <CategoryShowingName
                      textColor={categoryColors.find((c) => c.color === v.color)?.textColor}
                      bgColor={categoryColors.find((c) => c.color === v.color)?.bgColor}
                    >
                      {`# ${v.name}`}
                    </CategoryShowingName>
                    <IconButtonWrapper>
                      <IconButton
                        onClick={(e) => handleClickUpdateButton(e, v)}
                        sx={{
                          borderRadius: '6px',
                          padding: '4px',
                        }}
                      >
                        <Icons.Edit width={16} height={16} stroke={COLORS.gray300} />
                      </IconButton>
                      <div className="delete-btn">
                        <IconButton
                          className="delete"
                          onClick={(e) => handleClickDeleteButton(e, v)}
                          sx={{
                            borderRadius: '6px',
                            padding: '4px',
                          }}
                        >
                          <Icons.Delete width={16} height={16} fill={COLORS.gray300} />
                        </IconButton>
                      </div>
                    </IconButtonWrapper>
                  </CategoryShowingWrapper>
                ))}
          </div>
        </>
      )}
      <Popover
        open={Boolean(updateCategoryAnchorEl)}
        anchorEl={updateCategoryAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => {
          onClickCategoryAction && onClickCategoryAction(selectedCategory as OutCategory, 'UPDATE');
          setUpdateCategoryAnchorEl(null);
        }}
      >
        <UpdateCategoryPopoverWrapper>
          <TextField
            autoFocus
            value={selectedCategory?.name}
            onChange={(e) => setSelectedCategory({ ...(selectedCategory as OutCategory), name: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onClickCategoryAction && onClickCategoryAction(selectedCategory as OutCategory, 'UPDATE');
                setUpdateCategoryAnchorEl(null);
              }
            }}
            inputProps={{
              style: {
                fontSize: '12px',
                padding: '8px',
              },
            }}
          />
        </UpdateCategoryPopoverWrapper>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  min-width: 200px;
`;

const TextFieldWrapper = styled.div`
  height: 50px;
  padding: 16px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const CreateNewCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingWrapper = styled.div<{ isHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
  }

  ${(props) =>
    props.isHover &&
    `
    background-color: ${COLORS.gray100};
  `}
`;

const CategoryShowingName = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  font-size: 10px;
  padding: 2px 6px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  .delete-btn {
    :hover {
      .delete {
        filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
      }
    }
  }
`;

const EditButton = styled.div`
  border-radius: 6px;
  padding: 4px;
  :hover {
    background-color: ${COLORS.gray200};
  }
`;

const UpdateCategoryPopoverWrapper = styled.div`
  padding: 8px;
`;

export default CategoryPopover;
