import { SVGProps } from 'react';

export const Cabinet = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.1331 2.68752C14.9367 2.09863 14.5599 1.58648 14.0562 1.22365C13.5525 0.860831 12.9474 0.665735 12.3266 0.666016H4.6761C4.05579 0.666299 3.45127 0.861656 2.9481 1.22444C2.44493 1.58722 2.06859 2.09906 1.87235 2.68752L0.436866 6.95901C0.255648 7.5116 0.164874 8.08983 0.168049 8.67137C0.167973 8.92769 0.185939 9.1837 0.221813 9.4375L0.974499 14.795C1.07841 15.5006 1.43271 16.1452 1.97266 16.6111C2.51261 17.0771 3.20217 17.3332 3.91535 17.3326H13.0874C13.8006 17.3332 14.4901 17.0771 15.0301 16.6111C15.57 16.1452 15.9243 15.5006 16.0282 14.795L16.7809 9.4375C16.8168 9.1837 16.8348 8.92769 16.8347 8.67137C16.8379 8.08983 16.7471 7.51159 16.5659 6.95901L15.1331 2.68752ZM3.39385 3.19827C3.48339 2.92943 3.6556 2.69576 3.88588 2.53065C4.11617 2.36554 4.39275 2.27743 4.6761 2.27892H12.3266C12.61 2.27743 12.8866 2.36554 13.1168 2.53065C13.3471 2.69576 13.5193 2.92943 13.6089 3.19827L14.91 7.11761H2.09278L3.39385 3.19827ZM15.1841 9.20901L14.4153 14.5665C14.3691 14.889 14.2073 15.1837 13.9601 15.3958C13.7128 15.6079 13.397 15.723 13.0712 15.7197H3.91535C3.58961 15.723 3.27375 15.6079 3.02652 15.3958C2.77929 15.1837 2.61751 14.889 2.57127 14.5665L1.81858 9.20901C1.7961 9.05045 1.78353 8.89064 1.78095 8.73051H15.2218C15.2192 8.89064 15.2066 9.05045 15.1841 9.20901Z"
        fill="#1F2023"
      />
      <path
        d="M9.84618 11.1504H7.15801C6.71262 11.1504 6.35156 11.5115 6.35156 11.9568C6.35156 12.4022 6.71262 12.7633 7.15801 12.7633H9.84618C10.2916 12.7633 10.6526 12.4022 10.6526 11.9568C10.6526 11.5115 10.2916 11.1504 9.84618 11.1504Z"
        fill="#1F2023"
      />
    </svg>
  );
};
