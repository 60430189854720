import styled from '@emotion/styled';
import { Avatar, Button, Dialog, Divider, Tooltip } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import PaymentComplete from './Components/PaymentComplete';
import { css } from '@emotion/react';
import { ExternalLink } from 'components/Icons';
import { OutBelieverPlan, OutUserDetail } from 'queries/model';
import dayjs from 'dayjs';

interface BelieverPageProps {
  user?: OutUserDetail;
  believer?: OutBelieverPlan;
  onClickJoinBeliever?: () => void;
}

const BelieverPage = ({ user, believer, onClickJoinBeliever }: BelieverPageProps) => {
  const { t } = useTranslation();
  const [language] = useAtom(languageAtom);
  const benefits = [
    {
      title: 'Invest in your Growth',
      content:
        language === 'ko'
          ? ['하루 150원으로 5년간, 자신을 성장시키기 위한 강력한 도구에 투자하세요']
          : [`Invest in a powerful tool to grow yourself over 5 years for just 10 cent per day`],
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5007 16.6665C14.8018 16.6665 16.6673 18.532 16.6673 20.8332V53.6048L25.889 45.92C25.9022 45.909 25.9155 45.8981 25.9289 45.8872L26.0048 45.8239C27.3695 44.6866 28.5542 43.6993 29.5967 42.9465C30.7001 42.1498 31.8738 41.4418 33.2655 41.0074C36.0627 40.1342 39.0748 40.2728 41.7787 41.3945C43.1265 41.9536 44.2322 42.7655 45.2606 43.6604C46.2322 44.5058 47.3248 45.5986 48.5847 46.8585L48.7062 46.98C50.1089 48.3827 51.0119 49.2823 51.7515 49.9248C52.4588 50.5391 52.7926 50.7147 52.9733 50.7884C53.8874 51.1607 54.9112 51.195 55.8584 50.8808C56.047 50.8182 56.3949 50.6635 57.1458 50.0946C57.9307 49.4999 58.898 48.6572 60.3998 47.3431L84.7568 26.0308C86.4886 24.5154 89.1209 24.6909 90.6363 26.4227C92.1516 28.1546 91.9761 30.7869 90.2443 32.3022L65.802 53.6893C64.423 54.896 63.2297 55.9401 62.1783 56.7367C61.0711 57.5756 59.8908 58.323 58.4821 58.7903C55.6613 59.726 52.5919 59.6313 49.8294 58.5059C48.4566 57.9466 47.3311 57.1232 46.2868 56.2161C45.2994 55.3585 44.1891 54.2481 42.9095 52.9684L42.7867 52.8456C41.4053 51.4642 40.5174 50.5796 39.7903 49.9469C39.0958 49.3426 38.7667 49.167 38.5855 49.0918C37.6824 48.7172 36.6792 48.6717 35.7487 48.9622C35.5626 49.0202 35.2199 49.1649 34.4752 49.7026C33.6956 50.2656 32.7337 51.0641 31.2372 52.3112C31.224 52.3222 31.2107 52.3331 31.1973 52.344L16.6673 64.4523V69.9998C16.6673 72.4022 16.6706 73.9528 16.767 75.1329C16.8595 76.2641 17.0164 76.6836 17.1215 76.8898C17.5228 77.6775 18.1611 78.3156 18.9414 78.7133C19.1465 78.8177 19.5654 78.9745 20.6959 79.0669C21.8748 79.1633 23.4234 79.1665 25.8211 79.1665H87.5015C89.8027 79.1665 91.6681 81.032 91.6681 83.3332C91.6681 85.6344 89.8027 87.4998 87.5015 87.4998H25.6606C23.4686 87.4999 21.5763 87.5 20.0172 87.3726C18.3717 87.2381 16.734 86.9412 15.1582 86.1383C12.8025 84.938 10.893 83.0215 9.69641 80.6731C8.89322 79.0967 8.59599 77.4589 8.46137 75.8117C8.33385 74.2514 8.33391 72.3569 8.33399 70.1605L8.33399 20.8332C8.33399 18.532 10.1995 16.6665 12.5007 16.6665Z"
            fill="url(#paint0_linear_4078_8348)"
            fillOpacity="0.45"
          />
          <defs>
            <linearGradient id="paint0_linear_4078_8348" x1="50.0011" y1="16.6665" x2="50.0011" y2="87.4998" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: 'Early Access',
      content: language === 'ko' ? ['새롭게 개발된 기능들을 가장 먼저 경험해보세요'] : [`Be the first to experience newly developed features`],
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M55.5205 44.4803L22.6698 77.331M39.4855 31.5026L25.0581 31.6281C23.836 31.6374 22.6698 32.1299 21.8102 32.9895L9.0137 45.7859C7.73126 47.073 8.35389 49.2708 10.1242 49.689L26.215 53.4898L31.9101 40.0002M57.6579 14.9936C68.396 22.3165 77.6843 31.6048 85.0072 42.3429M46.5063 73.7858L50.3072 89.8766C50.7253 91.6469 52.9231 92.2696 54.2102 90.9871L67.0067 78.1907C67.8663 77.3311 68.3588 76.1648 68.3681 74.9427L68.4936 60.5154L57.5213 69.6364M31.9101 40.0002C33.212 38.0881 34.6962 36.2919 36.3491 34.639L51.1714 19.8213C55.0094 15.9786 59.8882 13.3441 65.2085 12.2382L83.2741 8.48383C88.1901 7.4616 92.5392 11.8107 91.517 16.7267L87.7626 34.7923C86.6567 40.1126 84.0222 44.9914 80.1795 48.8294L65.3618 63.6471C63.0118 65.9972 60.372 68.0069 57.5213 69.6364M31.9101 40.0002C28.7581 44.6298 26.6747 49.9389 25.8526 55.539L25.8015 55.8782C25.0674 60.9289 26.7587 66.0261 30.3644 69.6364C33.9701 73.2421 39.0719 74.9335 44.1227 74.1947L44.4619 74.1436C49.0863 73.4674 53.5124 71.9279 57.5213 69.6364"
            stroke="url(#paint0_linear_4078_8355)"
            strokeOpacity="0.45"
            strokeWidth="8.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4078_8355" x1="50.0007" y1="8.3335" x2="50.0007" y2="91.6668" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: 'Contributions',
      content:
        language === 'ko'
          ? ['최고의 도구를 함께 만들어가요! SLASH의 로드맵을 미리 확인하고, 기능 제안과 투표를 통해 발전에 기여할 수 있어요']
          : [`Help build the best tool! Get a sneak peek at SLASH’s roadmap, make feature suggestions, and vote to shape its development`],
      paymentContent: language === 'ko' ? [`SLASH 로드맵을 미리 확인할 \n수 있어요`] : [`Get a sneak peek at SLASH’s\nroadmap`],
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M31.7297 87.9188H20.4919C18.5049 87.9188 16.5993 87.1295 15.1943 85.7245C13.7893 84.3195 13 82.4139 13 80.4269V54.2053C13 52.2184 13.7893 50.3128 15.1943 48.9078C16.5993 47.5028 18.5049 46.7135 20.4919 46.7135H31.7297M57.9513 39.2216V24.2378C57.9513 21.2574 56.7673 18.399 54.6598 16.2915C52.5523 14.184 49.6939 13 46.7135 13L31.7297 46.7135V87.9188H73.9839C75.7907 87.9392 77.5439 87.306 78.9207 86.1357C80.2974 84.9655 81.2049 83.3371 81.4758 81.5507L86.6452 47.8372C86.8081 46.7635 86.7357 45.6671 86.4329 44.6242C86.1301 43.5812 85.6042 42.6165 84.8915 41.797C84.1789 40.9774 83.2966 40.3226 82.3058 39.8779C81.3149 39.4332 80.2393 39.2093 79.1533 39.2216H57.9513Z"
            stroke="url(#paint0_linear_4078_8366)"
            strokeOpacity="0.45"
            strokeWidth="8.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4078_8366" x1="49.865" y1="13" x2="49.865" y2="87.9193" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
      paymentIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
          <path
            d="M15.2324 42.2012H9.83829C8.88454 42.2012 7.96986 41.8224 7.29546 41.148C6.62106 40.4736 6.24219 39.5589 6.24219 38.6051V26.0188C6.24219 25.065 6.62106 24.1504 7.29546 23.476C7.96986 22.8016 8.88454 22.4227 9.83829 22.4227H15.2324M27.8188 18.8266V11.6344C27.8188 10.2038 27.2505 8.83174 26.2389 7.82015C25.2273 6.80855 23.8553 6.24023 22.4246 6.24023L15.2324 22.4227V42.2012H35.5145C36.3817 42.2111 37.2233 41.9071 37.8841 41.3454C38.545 40.7837 38.9805 40.0021 39.1106 39.1446L41.5919 22.9621C41.6701 22.4467 41.6353 21.9205 41.49 21.4198C41.3446 20.9192 41.0922 20.4562 40.7501 20.0628C40.4081 19.6694 39.9846 19.3551 39.509 19.1416C39.0334 18.9282 38.517 18.8207 37.9958 18.8266H27.8188Z"
            stroke="url(#paint0_linear_4122_8425)"
            strokeOpacity="0.45"
            strokeWidth="3.9984"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4122_8425" x1="6.15354" y1="6.15029" x2="42.3315" y2="44.411" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: '100',
      content:
        language === 'ko'
          ? [
              'SLASH의 첫 100명 Believer 중 한 명이 되어보세요',
              '멤버십 결제 순서에 따라 1~100의 고유 번호를 부여 받아요(내 Believer 번호는 프로필에서 확인할 수 있어요)',
            ]
          : [`Become one of SLASH’s first 100 Believers`, `Receive a unique number from 1 to 100 based on your membership order`],
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M25.002 74.9998C25.2672 75 25.5405 75 25.8224 75H50.002M25.002 74.9998C20.8842 74.9967 18.7242 74.9451 17.0511 74.0926C15.4831 73.2937 14.2092 72.0155 13.4102 70.4475C12.502 68.6649 12.502 66.3346 12.502 61.6675V38.3341C12.502 33.667 12.502 31.3318 13.4102 29.5492C14.2092 27.9811 15.4831 26.7072 17.0511 25.9083C18.8337 25 21.169 25 25.8361 25H74.1694C78.8365 25 81.1668 25 82.9494 25.9083C84.5175 26.7072 85.7956 27.9811 86.5946 29.5492C87.502 31.33 87.502 33.6625 87.502 38.3205V61.6795C87.502 66.3375 87.502 68.6666 86.5946 70.4475C85.7956 72.0155 84.5175 73.2937 82.9494 74.0926C81.1686 75 78.8395 75 74.1815 75H50.002M25.002 74.9998C25.0021 70.3975 30.5985 66.6667 37.502 66.6667C44.4055 66.6667 50.002 70.3976 50.002 75M25.002 74.9998C25.002 74.9999 25.002 74.9997 25.002 74.9998ZM75.002 58.3333H58.3353M75.002 45.8333H62.502M37.502 54.1667C32.8996 54.1667 29.1686 50.4357 29.1686 45.8333C29.1686 41.231 32.8996 37.5 37.502 37.5C42.1043 37.5 45.8353 41.231 45.8353 45.8333C45.8353 50.4357 42.1043 54.1667 37.502 54.1667Z"
            stroke="url(#paint0_linear_4078_8375)"
            strokeOpacity="0.45"
            strokeWidth="8.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4078_8375" x1="50.002" y1="25" x2="50.002" y2="75" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      title: 'Personalized Onboarding',
      content:
        language === 'ko'
          ? ['SLASH를 더욱 효과적으로 활용하고 싶다면 Believer 전용 1:1 온보딩을 신청해보세요']
          : [`For a more effective SLASH experience, apply for 1:1 onboarding exclusively for Believers (Your Believer number is available in your profile)`],
      paymentContent: language === 'ko' ? ['Believer 전용 1:1 온보딩을\n신청해보세요'] : [`Apply for 1:1 onboarding exclusively \nfor Believers`],
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M62.5 79.1667C62.5 69.9619 51.3071 62.5 37.5 62.5C23.6929 62.5 12.5 69.9619 12.5 79.1667M70.1172 21.5495C71.6648 23.0971 72.8925 24.9344 73.7301 26.9565C74.5676 28.9786 74.9995 31.1472 74.9995 33.3359C74.9995 35.5246 74.568 37.6874 73.7305 39.7095C72.8929 41.7316 71.6648 43.571 70.1172 45.1187M79.1667 12.5C81.9025 15.2359 84.0727 18.4838 85.5534 22.0584C87.034 25.633 87.7994 29.4636 87.7994 33.3327C87.7994 37.2018 87.0357 41.034 85.555 44.6086C84.0744 48.1831 81.9025 51.4314 79.1667 54.1673M37.5 50C28.2953 50 20.8333 42.5381 20.8333 33.3333C20.8333 24.1286 28.2953 16.6667 37.5 16.6667C46.7047 16.6667 54.1667 24.1286 54.1667 33.3333C54.1667 42.5381 46.7047 50 37.5 50Z"
            stroke="url(#paint0_linear_4078_8381)"
            strokeOpacity="0.45"
            strokeWidth="8.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4078_8381" x1="50.1497" y1="12.5" x2="50.1497" y2="79.1667" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
      paymentIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
          <path
            d="M30 38C30 33.5817 24.6274 30 18 30C11.3726 30 6 33.5817 6 38M33.6562 10.3437C34.3991 11.0866 34.9884 11.9685 35.3904 12.9391C35.7925 13.9097 35.9998 14.9507 35.9998 16.0012C35.9998 17.0518 35.7927 18.0899 35.3906 19.0605C34.9886 20.0312 34.3991 20.9141 33.6562 21.657M38 6C39.3132 7.31322 40.3549 8.87223 41.0656 10.588C41.7763 12.3038 42.1437 14.1425 42.1437 15.9997C42.1437 17.8569 41.7771 19.6963 41.0664 21.4121C40.3557 23.1279 39.3132 24.6871 38 26.0003M18 24C13.5817 24 10 20.4183 10 16C10 11.5817 13.5817 8 18 8C22.4183 8 26 11.5817 26 16C26 20.4183 22.4183 24 18 24Z"
            stroke="url(#paint0_linear_4122_9025)"
            strokeOpacity="0.45"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient id="paint0_linear_4122_9025" x1="5.90946" y1="5.91996" x2="37.557" y2="44.3331" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2D6ADF" />
              <stop offset="1" stopColor="#E7EAF4" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
  ];

  return (
    <Container>
      {!believer ? (
        <>
          <BelieverHeader>{t(`membership.tab2`)}</BelieverHeader>
          <BelieverContentWrapper>
            <BelieverContentCardWrapper style={{ marginRight: '20px' }}>
              {benefits.map((benefit, idx) =>
                benefit.title === '100' || benefit.title === 'Personalized Onboarding' ? null : (
                  <BelieverContentCard key={idx}>
                    {benefit.title === 'Contributions' ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BelieverContentCardTitle>{benefit.title}</BelieverContentCardTitle>
                        <div style={{ backgroundColor: COLORS.gray100, borderRadius: '6px', marginLeft: '8px', padding: '2px 6px', fontSize: '12px' }}>
                          {language === 'ko' ? '준비중' : 'Coming Soon'}
                        </div>
                      </div>
                    ) : (
                      <BelieverContentCardTitle>{benefit.title}</BelieverContentCardTitle>
                    )}
                    <BelieverContentCardSubtitle>{benefit.content}</BelieverContentCardSubtitle>
                    <BelieverContentCardIcon>{benefit.icon}</BelieverContentCardIcon>
                  </BelieverContentCard>
                ),
              )}
            </BelieverContentCardWrapper>
            <BelieverContentCardWrapper>
              {benefits.map((benefit, idx) =>
                benefit.title === 'Invest in your Growth' || benefit.title === 'Early Access' || benefit.title === 'Contiributions' ? null : (
                  <BelieverContentCard key={idx}>
                    <BelieverContentCardTitle>{benefit.title}</BelieverContentCardTitle>
                    {benefit.content.map((content, idx) => (
                      <BelieverContentCardSubtitle key={idx}>{content}</BelieverContentCardSubtitle>
                    ))}
                    <BelieverContentCardIcon>{benefit.icon}</BelieverContentCardIcon>
                  </BelieverContentCard>
                ),
              )}
            </BelieverContentCardWrapper>
          </BelieverContentWrapper>
          <ButtonWrapper>
            <Button
              variant="contained"
              onClick={onClickJoinBeliever}
              sx={{ width: '257px', height: '40px', borderRadius: '8px', fontSize: '13px', fontWeight: 700, marginBottom: '12px', textTransform: 'none' }}
            >
              {language === 'ko' ? '빌리버로 참여하기' : 'Join as a Believer'}
            </Button>
            <Button
              href="https://doslash.io/believer"
              target="_blank"
              variant="text"
              sx={{ width: '257px', height: '40px', borderRadius: '8px', fontSize: '13px', fontWeight: 700, textTransform: 'none' }}
            >
              {language === 'ko' ? '더 알아보기' : 'Learn More'}
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <BelieverHeaderAfterPayment>
            <Avatar
              alt={user?.name}
              src={`${user?.profileImage}`}
              sx={{
                width: '120px',
                height: '120px',
                marginBottom: '12px',
              }}
            />
            <div
              style={{
                fontSize: '24px',
                fontWeight: 700,
                marginBottom: '12px',
              }}
            >
              {language === 'ko' ? (
                <>
                  <span>{`${user?.name}님은`}</span>
                  &nbsp;
                  <span style={{ color: COLORS.sub2 }}>{`${believer.sequence}번째 빌리버`}</span>
                  <span>예요 ✌️</span>
                </>
              ) : (
                <>
                  <span>{`${user?.name} is the`}</span>
                  &nbsp;
                  <span style={{ color: COLORS.sub2 }}>{`${believer.sequence}th Believer`}</span>
                  <span>✌️</span>
                </>
              )}
            </div>
            <div
              style={{
                color: COLORS.gray600,
                fontSize: '16px',
              }}
            >
              {language === 'ko'
                ? `만료일:  ${dayjs(believer.expireAt).format('YYYY년 M월 D일')}`
                : `
              Expiration Date: ${dayjs(believer.expireAt).format('MMMM D, YYYY')}
              `}
            </div>
          </BelieverHeaderAfterPayment>
          <BelieverContentWrapper>
            <BelieverContentCardWrapper believer={!!believer}>
              {benefits.map((benefit, idx) =>
                benefit.title === 'Contributions' || benefit.title === 'Personalized Onboarding' ? (
                  <BelieverContentCard key={idx} believer={!!believer}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BelieverContentCardIcon believer={!!believer}>{benefit.paymentIcon}</BelieverContentCardIcon>
                      <div>
                        <BelieverContentCardTitle believer={!!believer}>{benefit.title}</BelieverContentCardTitle>
                        <BelieverContentCardSubtitle believer={!!believer}>{benefit.paymentContent}</BelieverContentCardSubtitle>
                      </div>
                    </div>
                    <ButtonWrapper believer={!!believer}>
                      {benefit.title === 'Contributions' ? (
                        <Button
                          variant="text"
                          href="https://slash.upvotyfeedback.com"
                          target="_blank"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            color: COLORS.gray600,
                            fontSize: '13px',
                            textTransform: 'none',
                          }}
                          endIcon={<ExternalLink style={{ marginLeft: -4 }} />}
                        >
                          {language === 'ko' ? '확인하러 가기' : 'Check it out'}
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          href="https://calendly.com/jaybang/30min"
                          target="_blank"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            color: COLORS.gray600,
                            fontSize: '13px',
                            textTransform: 'none',
                          }}
                          endIcon={<ExternalLink style={{ marginLeft: -4 }} />}
                        >
                          {language === 'ko' ? '신청하러 가기' : 'Apply'}
                        </Button>
                      )}
                    </ButtonWrapper>
                  </BelieverContentCard>
                ) : null,
              )}
            </BelieverContentCardWrapper>
          </BelieverContentWrapper>
          <Button
            href={
              process.env.REACT_APP_MODE === 'development'
                ? 'https://sandbox-customer-portal.paddle.com/login/cpl_01jc05xdz48wv6m0a8b4tbdy81'
                : 'https://customer-portal.paddle.com/login/cpl_01jbm9xzm58s3kd8d16tv5cmwg'
            }
            target="_blank"
            sx={{ borderRadius: '8px', color: COLORS.sub2, padding: '10px 16px', textTransform: 'none' }}
          >
            {language === 'ko' ? '결제 정보 보기' : 'View Payment Information'}
          </Button>
        </>
      )}
    </Container>
  );
};

export default BelieverPage;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 108px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  overflow-y: scroll;
  ${hideScroll}
`;

const BelieverHeader = styled.div`
  width: 630px;
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 700;
`;

const BelieverContent = styled.div`
  display: flex;
  margin-top: 20px;
`;

const BelieverContentPrice = styled.div`
  text-align: right;
  font-size: 32px;
  font-weight: 700;
  margin-top: 8px;
`;

const BelieverContentSubPrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 8px;
`;

const FeatureWrapper = styled.div``;

const FeatureDescription = styled.div`
  color: ${COLORS.gray600};
  font-size: 13px;
  margin-bottom: 12px;
`;

const FeatureListWrapper = styled.div``;

const FeatureListTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const FeatureListContent = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const BelieverContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0px 24px 0px;
  position: relative;
`;

const BelieverContentCardWrapper = styled.div<{ believer?: boolean }>`
  width: 305px;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.believer &&
    css`
      width: fit-content;
      flex-direction: row;
      justify-content: center;
    `}
`;

const BelieverContentCard = styled.div<{ believer?: boolean }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--Gray_100, #f2f5fc);
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  :not(:first-child) {
    margin-top: 24px;
  }

  ${(props) =>
    props.believer &&
    css`
      :not(:first-child) {
        margin-top: 0px;
      }

      :nth-child(1) {
        margin-right: 24px;
      }
    `}
`;

const BelieverContentCardTitle = styled.div<{ believer?: boolean }>`
  font-size: 24px;
  font-weight: 700;

  ${(props) =>
    props.believer &&
    css`
      font-size: 16px;
    `}
`;

const BelieverContentCardSubtitle = styled.div<{ believer?: boolean }>`
  font-size: 16px;
  margin-top: 8px;

  ${(props) =>
    props.believer &&
    css`
      white-space: pre-line;
    `}
`;

const BelieverContentCardIcon = styled.div<{ believer?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  ${(props) =>
    props.believer &&
    css`
      width: fit-content;
      margin-top: 0px;
      margin-right: 12px;
    `}
`;

const ButtonWrapper = styled.div<{ believer?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  ${(props) =>
    props.believer &&
    css`
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 32px;
    `}
`;

const BelieverHeaderAfterPayment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
