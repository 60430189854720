import { SVGProps } from 'react';

export const LockOut = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.666 2V4.66667M5.3327 2V4.66667M3.99935 3.33203H11.9993C12.7357 3.33203 13.3327 3.92898 13.3327 4.66536V12.6654C13.3327 13.4017 12.7357 13.9987 11.9993 13.9987H3.99935C3.26297 13.9987 2.66602 13.4017 2.66602 12.6654V4.66536C2.66602 3.92898 3.26297 3.33203 3.99935 3.33203Z"
        stroke={props.stroke ? props.stroke : '#C8CDDB'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.63411 9H6.30078" stroke={props.stroke ? props.stroke : '#C8CDDB'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
