import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Select,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import {
  AppKernelComponentLinkModelJsonLink,
  InRecurrence,
  InUpdateRoutineTask,
  OutCategory,
  OutLink,
  OutProject,
  OutRoutine,
  OutTaskboxDetailProjectOnly,
  OutTaskboxDetailResponse,
  OutTaskboxDetailRoutineOnly,
  RoutineStatusEnum,
  WeekDay,
} from 'queries/model';
import dayjs, { Dayjs } from 'lib/dayjs';
import {
  absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost,
  createCategoryV1CategoryPost,
  createTaskboxV1TaskboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  removeTaskboxesV1TaskboxesDelete,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch,
  updateRoutineStatusV1RoutineRoutineIdStatusPatch,
  updateRoutineTaskV1RoutineRoutineIdTaskPost,
  updateRoutineV1RoutineRoutineIdPatch,
  updateTaskboxV1TaskboxesTaskboxIdPut,
} from 'queries';
import toast from 'react-hot-toast';
import TaskBlockInputList, { TaskBlock, ProjectTaskBlockAction } from 'pages/Task/components/TaskBlockInputList';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useClickOutside, useEventListener, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { useAtom } from 'jotai';
import { categoryAtom } from 'atoms/category';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { v4 as uuidv4 } from 'uuid';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType } from 'remirror';
import { isMouseInsideMemoContainer } from 'components/Remirror/utils';
import { hideScroll } from 'styles/utils';
import ConfirmDialog, { DialogElement } from 'components/ConfirmDialog';
import InboxContextMenu, { InboxContextMenuType } from 'components/InboxContextMenu';
import { dragContextAtom } from 'atoms/works';
import { loadSubtaskFromProjectAtom, moveToInstanceAtom, syncInstanceAtom } from 'atoms/projects';
import { planSidePanelWidthAtom, sidePanelWidthWithCalendarAtom } from 'atoms/foldView';
import { MultiSelectSubtaskIds } from 'pages/Task/Today';
import { DATE_FORMAT_1, DATE_FORMAT_2, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { isEqual } from 'lodash';
import { t } from 'i18next';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { RecurrenceCustomDialog } from 'components/Recurrence/RecurrenceCustomDialog';
import { selectedRoutineDateAtom } from 'atoms/routine';
import { languageAtom } from 'atoms/language';

const RoutineContainer = styled.div<{ left?: number; isAnimation?: boolean; planView?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(${(props) => props.left! + 5000}px);
  transition: transform 0.4s ease-in-out;
  z-index: 103;

  ${(props) => props.planView && `top: -8px;`}

  ${(props) =>
    props.isAnimation &&
    `
      transform: translateX(0);
  `}

  .recurrence {
    .MuiSvgIcon-root {
      display: none;
    }

    .MuiSelect-select {
      padding-right: 0px;
    }
  }
`;

const RoutineWrapper = styled.div`
  height: 100%;
  padding: 32px;
`;

const Container = styled.div`
  width: 460px;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
`;

const ContentHeaderWrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  max-height: calc(100% - 252px);
  min-height: 392px;
  overflow-y: scroll;
  ${hideScroll};
`;

const MemoLinkWrapper = styled.div``;

const ContentHeader = styled.div`
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const RoutineTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TaskListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const TaskListTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const TaskListBorderWrapper = styled.div<{ border?: boolean }>`
  border: ${(props) => (props.border ? `1px solid ${COLORS.gray200}` : `1px solid transparent`)};
  border-radius: 8px;
  padding: 8px 12px 8px 0px;
`;

const TaskListButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const TaskListHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray700};
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;

  .close-btn {
    opacity: 0;
  }

  &:hover {
    .close-btn {
      opacity: 1;
    }
  }

  .delete-instance {
    margin-left: 8px;
    opacity: 0;
  }

  :hover {
    .delete-instance {
      margin-left: 8px;
      opacity: 1;
    }
  }
`;

const TaskListDate = styled.div`
  :hover {
    color: ${COLORS.issue2};
  }
`;

const CategoryWrapper = styled.div`
  margin-top: 20px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const ScheduleWrapper = styled.div``;

const ScheduleTitleWrapper = styled.div`
  font-size: 13px;
  font-weight: 700;
`;

const ScheduleBorderWrapper = styled.div<{ border?: boolean }>`
  border: ${(props) => (props.border ? `1px solid ${COLORS.gray200}` : `1px solid transparent`)};
  border-radius: 8px;
  padding: 8px 12px;
`;

const ScheduleReflectionNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray600};
  font-size: 12px;
  margin-top: 8px;
`;

const RecurrenceActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const RecurrenceScheduleButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
`;

const TimeSelectWrapper = styled.div<{ focus?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f5fc;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 8px 0px 8px;
  padding: 2px 6px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    ${(props) => !props.focus && `border: 1px solid ${COLORS.gray900};`}
  }
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
      padding: 2px 6px;
    `}
`;

const RecurrenceWrapper = styled.div``;

const MemoWrapper = styled.div``;

const LinkWrapper = styled.div``;

const LinkContentWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  position: relative;

  .link-icon {
    opacity: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :hover {
    background-color: ${COLORS.gray100};
    .link-icon {
      opacity: 1;
    }
  }
`;

const CreateLinkButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const LinkPopoverContainer = styled.div`
  width: 365px;
`;

const LinkPopoverWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
`;

const LinkURLWrapper = styled.div``;

const LinkTitleWrapper = styled.div`
  margin-top: 12px;
`;

const LinkDeleteWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const RoutineStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0px;
`;

const RoutineStatusDetail = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const UnallocateTaskWrapper = styled.div<{ multiSelect?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  font-size: 13px;
  padding: 4px 8px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  background-color: ${(props) => (props.multiSelect ? COLORS.sub3 : COLORS.white)};
`;

const NoticeUndoneSubtaskWrapper = styled.div`
  width: 500px;
  margin-top: 24px;
`;

const NoticeUndoneSubtaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoticeUndoneSubtaskContent = styled.div`
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 4px 8px;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const SubtaskMultiSelectPopoverWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray_100, #f2f5fc);
  background: var(--White, #fff);

  /* Shadow_100 */
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
`;

const ProjectDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -10000px;
  right: -10000px;
  z-index: 10000;
`;

const ProjectDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid ${COLORS.issue3};
  background: ${COLORS.issue3};
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const InprogressProject = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const InprogressProjectContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

export type ProjectFilter = 'ALL' | 'SUBTASK';

export interface ProjectDetailViewProps {
  instance?: OutTaskboxDetailResponse;
  selectedRoutine?: OutRoutine;
  isSide?: boolean;
  planView?: boolean;
  deskProjects?: OutProject[];
  onClose?: () => void;
  onDelete?: (id: string) => void;
  onFetch?: () => void;
}

const ProjectDetailView = ({
  instance,
  isSide = false,
  planView = false,
  selectedRoutine,
  deskProjects = [],
  onClose,
  onDelete,
  onFetch,
}: ProjectDetailViewProps) => {
  const [language] = useAtom(languageAtom);
  const [routine, setRoutine] = useState<OutRoutine | null>(selectedRoutine!);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedLink, setSelectedLink] = useState<(AppKernelComponentLinkModelJsonLink & { index: number }) | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState<HTMLElement | null>(null);
  const [routineStatusPopover, setRoutineStatusPopover] = useState<HTMLElement | null>();
  const [routineMemoEle, setRoutineMemoEle] = useState<Element | null>(null);
  const [instances, setInstances] = useState<OutTaskboxDetailRoutineOnly[]>(selectedRoutine?.tasks?.filter((v) => v.type !== 'PROJ_SOMEDAY_TASK') || []);
  const [unallocate, setUnallocate] = useState<OutTaskboxDetailRoutineOnly[]>(selectedRoutine?.tasks?.filter((v) => v.type === 'PROJ_SOMEDAY_TASK') || []);
  const [projectFilter, setProjectFilter] = useState<ProjectFilter>('ALL');
  const [multiSelect, setMultiSelect] = useState<OutTaskboxDetailProjectOnly[]>([]);
  const [multiSelectAnchorEl, setMultiSelectAnchorEl] = useState<HTMLElement | null>(null);
  const [isAnimation, setIsAnimation] = useState<boolean>(!!selectedRoutine);
  const [, setDragContext] = useAtom(dragContextAtom);
  const [isLoadSubtaskFromProject, setIsLoadSubTaskFromProject] = useAtom(loadSubtaskFromProjectAtom);
  const [taskViewSidePanel] = useAtom(sidePanelWidthWithCalendarAtom);
  const [planViewSidePanel] = useAtom(planSidePanelWidthAtom);
  const refPopperMenu = useRef<HTMLDivElement>(null);
  const refRoutineDetail = useRef<HTMLDivElement>(null);
  const refCreateLinkInput = useRef<HTMLInputElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refConfirm = useRef<DialogElement>(null);
  const [routineMemo, setRoutineMemo] = useState(routine?.memo);
  const [isMemoReady, setIsMemoReady] = useState(false);
  const [, setMoveToInstance] = useAtom(moveToInstanceAtom);
  const [inprogressPopover, setInprogressPopover] = useState<HTMLElement | null>(null);
  const [selectedInprogressInstance, setSelectedInprogressInstance] = useState<OutTaskboxDetailProjectOnly | null>(null);
  const [taskViewDate] = useAtom(selectedRoutineDateAtom);
  const [syncInstance] = useAtom(syncInstanceAtom);
  const [originRoutine, setOriginRoutine] = useState<OutRoutine | null>(selectedRoutine!);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const [updateSubtasks, setUpdateSubtasks] = useState(false);
  const [updateSchedule, setUpdateSchedule] = useState<boolean>(false);
  const [taskboxRecurrence, setTaskboxRecurrence] = useState<InRecurrence | undefined>(undefined);
  const [customRecurrence, setCustomRecurrence] = useState<InRecurrence | undefined>();
  const [recurrenceUnit, setRecurrenceUnit] = useState<string>(language === 'ko' ? '매일' : 'Daily');
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  // const [recurrenceUnit, setRecurrenceUnit] = useState<string>('Daily');
  const weekNumber = dayjs().diff(dayjs().startOf('month'), 'week') + 1;
  const suffix = weekNumber === 1 ? `${weekNumber}st` : weekNumber === 2 ? `${weekNumber}nd` : weekNumber === 3 ? `${weekNumber}rd` : `${weekNumber}th`;
  const [selectableRecurrence, setSeletableRecurrence] = useState(
    language === 'ko'
      ? [
          '매일',
          `매주 ${dayjs().format('dddd')}`,
          `매월 ${dayjs().diff(dayjs().startOf('month'), 'week') + 1}번째 ${dayjs().format('dddd')}`,
          `매년 ${dayjs().format('M')}월 ${dayjs().format('DD')}일`,
          '주중 매일(월-금)',
          '사용자 설정',
        ]
      : [
          'Daily',
          `Weekly on ${dayjs().format('dddd')}`,
          `Monthly on the ${suffix} ${dayjs().format('dddd')}`,
          `Annually on ${dayjs().format('MMMM')} ${dayjs().format('D')}`,
          'Every weekday (Mon-Fri)',
          'Custom',
        ],
  );
  // const [selectableRecurrence, setSeletableRecurrence] = useState([
  //   'Daily',
  //   `Weekly on ${dayjs().format('dddd')}`,
  //   `Monthly on the ${suffix} ${dayjs().format('dddd')}`,
  //   `Annually on ${dayjs().format('MMMM')} ${dayjs().format('D')}`,
  //   'Every weekday (Mon-Fri)',
  //   'Custom',
  // ]);

  const selectableRecurrenceDays = [
    { kr: '일', en: 'SU' },
    { kr: '월', en: 'MO' },
    { kr: '화', en: 'TU' },
    { kr: '수', en: 'WE' },
    { kr: '목', en: 'TH' },
    { kr: '금', en: 'FR' },
    { kr: '토', en: 'SA' },
  ];
  const [date, setDate] = useState(
    routine?.lastMasterStart?.date
      ? dayjs(routine?.lastMasterStart?.date, { utc: true }).toDate()
      : dayjs(routine?.lastMasterStart?.datetime, { utc: true }).toDate(),
  );

  useEffect(() => {
    setProjectFilter('ALL');
  }, []);

  useEffect(() => {
    setProjectFilter(isLoadSubtaskFromProject ? 'SUBTASK' : 'ALL');
  }, [isLoadSubtaskFromProject]);

  useEffect(() => {
    const target = instances.find((v) => v.id === syncInstance?.id);
    if (syncInstance?.syncType === 'taskbox' && target) {
      setInstances(instances.map((v) => (v.id === syncInstance?.id ? { ...v, tasks: syncInstance.tasks } : v)));
    }
  }, [syncInstance]);

  useEffect(() => {
    setUpdateSubtasks(!isEqual(routine?.tasks, originRoutine?.tasks));
  }, [routine?.tasks]);

  useEffect(() => {
    setUpdateSchedule(
      !isEqual({ ...routine?.recurrence, weekStart: 'SU' }, originRoutine?.recurrence) ||
        !isEqual(routine?.lastMasterStart, originRoutine?.lastMasterStart) ||
        !isEqual(routine?.lastMasterEnd, originRoutine?.lastMasterEnd),
    );

    const recurrence = routine?.recurrence;
    if (!recurrence) return;
    const newDate = routine?.lastMasterStart?.date
      ? dayjs(routine?.lastMasterStart?.date, { utc: true }).toDate()
      : dayjs(routine?.lastMasterStart?.datetime, { utc: true }).toDate();

    if (!dayjs(newDate).isSame(date)) {
      const recurrenceIndex = selectableRecurrence.findIndex((recurrence) => recurrence === recurrenceUnit);
      const weekNumber = dayjs(newDate).diff(dayjs(newDate).startOf('month'), 'week') + 1;
      const suffix = weekNumber === 1 ? `${weekNumber}st` : weekNumber === 2 ? `${weekNumber}nd` : weekNumber === 3 ? `${weekNumber}rd` : `${weekNumber}th`;
      const newSelectableRecurrence =
        language === 'ko'
          ? [
              '매일',
              `매주 ${dayjs(newDate).format('dddd')}`,
              `매월 ${dayjs(newDate).diff(dayjs(newDate).startOf('month'), 'week') + 1}번째 ${dayjs(newDate).format('dddd')}`,
              `매년 ${dayjs(newDate).format('M')}월 ${dayjs(newDate).format('DD')}일`,
              '주중 매일(월-금)',
              '사용자 설정',
            ]
          : [
              'Daily',
              `Weekly on ${dayjs(newDate).format('dddd')}`,
              `Monthly on the ${suffix} ${dayjs(newDate).format('dddd')}`,
              `Annually on ${dayjs(newDate).format('MMMM')} ${dayjs(newDate).format('D')}`,
              'Every weekday (Mon-Fri)',
              'Custom',
            ];
      // const newSelectableRecurrence = [
      //   'Daily',
      //   `Weekly on ${dayjs(newDate).format('dddd')}`,
      //   `Monthly on the ${suffix} ${dayjs(date).format('dddd')}`,
      //   `Annually on ${dayjs(newDate).format('MMMM')} ${dayjs(newDate).format('D')}`,
      //   'Every weekday (Mon-Fri)',
      //   'Custom',
      // ];
      setDate(newDate);
      setRecurrenceUnit(newSelectableRecurrence[recurrenceIndex]);
      setSeletableRecurrence(newSelectableRecurrence);
    }

    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);

    if (recurrence.interval === 1) {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('Daily');
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays: string[] = [];
        const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
        // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];
        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        const weekEveryday = newWeekdays.length === targetArray.length && targetArray.every((day) => newWeekdays.includes(day));

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else
              list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else
          //   list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      }
    } else {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
            else list.splice(6, 1, `${recurrence.interval}일마다`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
            else list.splice(6, 1, `Every ${recurrence.interval} days`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else
            //   list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          let suffix;
          if (recurrence.monthlyTypeOrder === 1) {
            suffix = `${recurrence.monthlyTypeOrder}st`;
          } else if (recurrence.monthlyTypeOrder === 2) {
            suffix = `${recurrence.monthlyTypeOrder}nd`;
          } else if (recurrence.monthlyTypeOrder === 3) {
            suffix = `${recurrence.monthlyTypeOrder}rd`;
          } else {
            suffix = `${recurrence.monthlyTypeOrder}th`;
          }

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else
              list.splice(6, 1, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  }, [routine]);

  useUpdateEffect(() => {
    setIsAnimation(!!selectedRoutine);
    removeMemoHandle();
    if (selectedRoutine) {
      updateProjectDetails();
      resetProjectMemo();
    } else {
      if (isSide) handleSaveRoutine();
      clearProjectMemo();
    }
  }, [selectedRoutine]);

  useEventListener(
    routineMemoEle,
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, routineMemoEle as Element);
      if (!inside) {
        hideMemoHandle();
      }
    },
    { passive: true },
  );

  useClickOutside(
    refPopperMenu,
    (e: Event) => {
      if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
      setMultiSelect([]);
    },
    ['mouseup'],
  );

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.code === 'Escape') {
        if (multiSelect.length > 0) {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
          setMultiSelect([]);
        } else {
          handleSaveRoutine();
        }
      }
    },
    [],
    { eventOptions: { passive: false } },
  );

  const hideMemoHandle = () => {
    const element = document.querySelector('.empty-routine-memo') || document.querySelector('.routine-memo');
    if (element) {
      element.querySelector('.memo-drag-handle')?.classList.add('hidden');
      element.querySelector('.memo-menu-handle')?.classList.add('hidden');
    }
  };

  const removeMemoHandle = () => {
    const element = document.querySelector('.empty-routine-memo') || document.querySelector('.routine-memo');
    if (element) {
      element.querySelector('.memo-drag-handle')?.remove();
      element.querySelector('.hidden')?.remove();
      element.querySelector('.memo-menu-handle')?.remove();
      element.querySelector('.hidden')?.remove();
    }
  };

  const updateProjectDetails = () => {
    if (!selectedRoutine) return;
    setRoutine({ ...selectedRoutine, categoryId: selectedRoutine.category?.id || null });
    setOriginRoutine({ ...selectedRoutine, categoryId: selectedRoutine.category?.id || null });
    // setInstances(
    //   selectedRoutine?.tasks?.filter(
    //     (v) => v.type === 'PROJ_DAY_TASK' || v.type === 'PROJ_TIME_TASK' || v.type === 'PROJ_LINK_DAY_TASK' || v.type === 'PROJ_LINK_TIME_TASK',
    //   ),
    // );
    // setUnallocate(selectedRoutine?.tasks?.filter((v) => v.type === 'PROJ_SOMEDAY_TASK'));
    const routineMemo = document.querySelector('.routine-memo');
    const remirror = routineMemo?.firstChild as HTMLDivElement;
    setRoutineMemoEle(routineMemo);
    if (remirror) remirror.classList.add('routine-memo');
  };

  const resetProjectMemo = () => {
    setIsMemoReady(false);
    setRoutineMemo(undefined);

    setTimeout(() => {
      setRoutineMemo(selectedRoutine?.memo);
      setIsMemoReady(true);
    }, 500);
  };

  const clearProjectMemo = () => {
    setTimeout(() => {
      setRoutineMemo(undefined);
      setIsMemoReady(false);
    }, 500);
  };

  const handleChangeUnallocateState = (tasks: { id: string; title?: string; category?: OutCategory; deadline?: string | null }[] = []) => {
    if (!routine) return;
    // setUnallocate(tasks.map((task) => ({ ...task, type: 'PROJ_SOMEDAY_TASK' })));
    // setRoutine({ ...routine, tasks: [...instances, ...tasks.map((task) => ({ ...task, type: 'PROJ_SOMEDAY_TASK' }))] as OutTaskboxDetailProjectOnly[] });
  };

  const handleOpenDeleteDialog = () => {
    if (!routine) return;
    refConfirm.current?.open();
  };

  const handleSaveRoutine = async () => {
    onClose && onClose();
    setIsAnimation(false);
    setRoutine(null);
    setProjectFilter('ALL');
    setIsLoadSubTaskFromProject(false);
  };

  const handleUpdateRoutineTitle = async () => {
    if (!routine) return;
    try {
      await updateRoutineV1RoutineRoutineIdPatch(routine.id!, { ...routine, title: routine.title });
      onFetch && onFetch();
    } catch (e) {
      language === 'ko' ? toast.error('루틴 이름을 수정할 수 없습니다.') : toast.error('Failed to update routine title.');
      // toast.error('Failed to update routine title.');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      const active = document.activeElement as HTMLElement;
      active.blur();
      e.preventDefault();
      handleUpdateRoutineTitle();
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current!);
      }, 100);
    }
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!routine) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            if (routine.title?.includes('#')) {
              setRoutine({
                ...routine,
                title: routine.title.replace(/#/g, ''),
                category: category ? category : undefined,
                categoryId: category ? category.id : null,
              });
              await updateRoutineV1RoutineRoutineIdPatch(routine.id!, {
                ...routine,
                title: routine.title.includes('#') ? routine.title.replace(/#/g, '') : routine.title,
                categoryId: category ? category.id : null,
              });
            } else {
              setRoutine({ ...routine, category: category ? category : undefined, categoryId: category ? category.id : null });
              await updateRoutineV1RoutineRoutineIdPatch(routine.id!, {
                ...routine,
                categoryId: category ? category.id : null,
              });
            }
            onFetch && onFetch();
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 선택할 수 없습니다.') : toast.error('Failed to select category.');
            // toast.error('Failed to select category.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 생성할 수 없습니다.') : toast.error('Failed to create category.');
            // toast.error('Failed to create category.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 삭제할 수 없습니다.') : toast.error('Failed to delete category.');
            // toast.error('Failed to delete category.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 수정할 수 없습니다.') : toast.error('Failed to update category.');
            // toast.error('Failed to update category.');
          }
        }
        break;
    }
  };

  const saveMemo = async (data: any) => {
    if (!data) return;
    await updateRoutineV1RoutineRoutineIdPatch(routine!.id!, { ...routine, memo: data });
  };

  const handleChangeMemo = (data: any) => {
    if (!data) return;
    if (!routine) return;
    setRoutine({ ...routine, memo: data });
    saveMemo(data);
  };

  const handleCreateLink = () => {
    if (!routine) return;
    const links = routine?.links || [];
    if (links[links.length - 1]?.title === '' && links[links.length - 1]?.url === '') {
      setSelectedLink({ ...links[links.length - 1], index: links.length - 1 });
      setTimeout(() => {
        if (refCreateLinkInput.current) refCreateLinkInput.current?.focus();
      }, 100);
    } else {
      const newLink = {
        title: '',
        url: '',
      };
      setSelectedLink({ ...newLink, index: links.length });
      setRoutine({ ...routine, links: [...links, newLink] as OutLink[] });
    }
  };

  const handleSaveLink = async () => {
    if (!routine) return;
    if (!selectedLink || selectedLink?.url === '') {
      clearLinkState();
      return;
    }

    const links = routine?.links || [];
    const targetLink = links[selectedLink.index];

    if (targetLink) {
      targetLink.url = selectedLink.url;
      targetLink.title = selectedLink.title;
    }

    try {
      await updateRoutineV1RoutineRoutineIdPatch(routine.id!, { ...routine, links });
      onFetch && onFetch();
    } catch (e) {
      language === 'ko' ? toast.error('링크를 수정할 수 없습니다.') : toast.error('Failed to update link.');
      // toast.error('Failed to update link.');
    }
    clearLinkState();
  };

  const clearLinkState = () => {
    setSelectedLink(null);
    setLinkAnchorEl(null);
  };

  const handleClickLinkUpdate = (link: AppKernelComponentLinkModelJsonLink, index: number) => {
    setSelectedLink({
      ...link,
      index: index,
    });
    setTimeout(() => {
      const el = document.querySelector(`[data-link-idx="${index}"]`) as HTMLDivElement;
      setLinkAnchorEl(el);
    }, 100);
  };

  const handleCopyClipBoard = async (text: string) => {
    if (text === '') return language === 'ko' ? toast.error('링크가 없습니다.') : toast.error('No link available.');
    // if (text === '') return toast.error('No link available.');

    try {
      await navigator.clipboard.writeText(text);
      language === 'ko' ? toast.success('클립보드에 링크가 복사되었습니다.') : toast.success('Link copied to clipboard.');
      // toast.success('Link copied to clipboard.');
    } catch (e) {
      language === 'ko' ? toast.error('복사에 실패하였습니다') : toast.error('Failed to copy.');
      // toast.error('Failed to copy.');
    }
  };

  const handleDeleteLink = async () => {
    if (!routine || !selectedLink) return;
    const links = routine?.links?.filter((_, idx) => idx !== selectedLink.index);
    setRoutine({ ...routine, links });
    clearLinkState();
    try {
      await updateRoutineV1RoutineRoutineIdPatch(routine.id!, { ...routine, links });
      onFetch && onFetch();
    } catch (e) {
      language === 'ko' ? toast.error('링크를 삭제할 수 없습니다.') : toast.error('Failed to delete link.');
      // toast.error('Failed to delete link.');
    }
  };

  const handleChangeUnallocateSubtask = async (block: TaskBlock, action: ProjectTaskBlockAction) => {
    if (!routine) return;
    switch (action) {
      case 'CREATE':
        {
          try {
            await createTaskboxV1TaskboxesPost({ id: block.id, title: block.title!, projectId: [routine.id!] });
          } catch (e) {
            language === 'ko' ? toast.error('하위업무를 생성할 수 없습니다.') : toast.error('Failed to create subtask.');
            // toast.error('Failed to create subtask.');
          }
        }
        break;
      case 'UPDATE':
        {
          try {
            await updateTaskboxV1TaskboxesTaskboxIdPut(block.id, { title: block.title });
          } catch (e) {
            language === 'ko' ? toast.error('하위업무를 수정할 수 없습니다.') : toast.error('Failed to update subtask.');
            // toast.error('Failed to update subtask.');
          }
        }
        break;
      case 'DELETE':
        {
          try {
            await removeTaskboxV1TaskboxesTaskboxIdDelete(block.id);
          } catch (e) {
            language === 'ko' ? toast.error('하위업무를 삭제할 수 없습니다.') : toast.error('Failed to delete subtask.');
            // toast.error('Failed to delete subtask.');
          }
        }
        break;
    }
  };

  // const handleChangeInstanceSubtask = async (instanceId: string, block: TaskBlock, action: ProjectTaskBlockAction, index?: number, blocks?: TaskBlock[]) => {
  //   if (!routine) return;
  //   switch (action) {
  //     case 'CREATE':
  //       {
  //         try {
  //           await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(instanceId, {
  //             id: block.id,
  //             content: block.content,
  //             beforeId: blocks && index && blocks[index - 1] ? blocks[index - 1].id : null,
  //           } as CreateTaskboxTask);
  //         } catch (e) {
  //           toast.error('하위업무를 생성할 수 없습니다.');
  //         }
  //       }
  //       break;
  //     case 'UPDATE':
  //       {
  //         try {
  //           await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instanceId, block.id, block);
  //         } catch (e) {
  //           toast.error('하위업무를 수정할 수 없습니다.');
  //         }
  //       }
  //       break;
  //     case 'DELETE':
  //       {
  //         try {
  //           await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(instanceId, block.id);
  //         } catch (e) {
  //           toast.error('하위업무를 삭제할 수 없습니다.');
  //         }
  //       }
  //       break;
  //     case 'TAB':
  //       {
  //         try {
  //           if (!blocks || !index) return;
  //           await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instanceId, blocks[index].id, { ...block, content: block.title });
  //           await removeTaskboxV1TaskboxesTaskboxIdDelete(block.id);
  //           await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(instanceId, {
  //             id: blocks[index + 1].id,
  //             content: '',
  //             beforeId: blocks[index].id,
  //           });
  //         } catch (e) {
  //           toast.error('하위업무를 생성할 수 없습니다.');
  //         }
  //         onFetch && onFetch();
  //         if (blocks && index) setFocusElement(`[data-field-id="${blocks[index + 1].id}"]`);
  //       }
  //       break;
  //     case 'MOVE_TO_AFTER':
  //       {
  //         const instances = routine.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
  //         const index = instances.findIndex((instance) => instance.id === instanceId);
  //         const nextInstance = instances[index + 1];

  //         const assignTaskToNextInstance = async () => {
  //           if (nextInstance) {
  //             const nextInstanceTasks = nextInstance.tasks;
  //             await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
  //               beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
  //               tasks: [block.id],
  //             });
  //             toast.success('프로젝트의 다음 인스턴스로 이동했습니다.');
  //           } else {
  //             await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(routine.id!, {
  //               taskIds: [block.id],
  //             });
  //             toast.success('프로젝트의 미할당으로 이동했습니다.');
  //           }
  //         };

  //         try {
  //           await assignTaskToNextInstance();
  //           onFetch && onFetch();
  //         } catch (error) {
  //           toast.error('일부 완료에 실패했습니다.');
  //         }
  //       }
  //       break;
  //   }
  // };

  const handleClickRoutineCheck = async (routineId: string, status: RoutineStatusEnum) => {
    if (isProgress) return;
    setIsProgress(true);
    if (routineStatusPopover) setRoutineStatusPopover(null);
    switch (status) {
      case 'ACTIVE':
        {
          try {
            await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
              status: 'ACTIVE',
              startDate: dayjs(taskViewDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
            });
            language === 'ko' ? toast.success('루틴을 데스크로 옮겼습니다.') : toast.success('Moved the routine to the desk.');
          } catch (error) {
            language === 'ko' ? toast.error('루틴을 데스크로 옮기는데 실패하였습니다.') : toast.error('Failed to move the routine to the desk.');
          }
        }
        break;

      case 'HOLD':
        {
          try {
            await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
              status: 'HOLD',
              startDate: dayjs(taskViewDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
            });
            language === 'ko' ? toast.success('홀드된 루틴은 서랍으로 이동되었습니다.') : toast.success('Routine on hold has been moved to the drawer.');
          } catch (error) {
            language === 'ko' ? toast.error('루틴 홀드에 실패하였습니다.') : toast.error('Failed to hold routine.');
          }
        }
        break;
      case 'ARCHIVED': {
        try {
          await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
            status: 'ARCHIVED',
            startDate: dayjs(taskViewDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
          });
          language === 'ko' ? toast.success('루틴을 아카이브로 옮겼습니다.') : toast.success('Archived the routine.');
        } catch (error) {
          language === 'ko' ? toast.error('루틴 아카이브에 실패하였습니다.') : toast.error('Failed to archive routine.');
        }
        break;
      }
    }
    setIsProgress(false);
    if (routineStatusPopover) setRoutineStatusPopover(null);
    onClose && onClose();
  };

  const handleDelete = (data: any) => {
    onDelete && onDelete(data.id);
  };

  const handleMultiSelectIds = (task: any) => {
    if (multiSelect.includes(task)) {
      setMultiSelect(multiSelect.filter((v) => v !== task));
    } else {
      setMultiSelect([...multiSelect, task]);
    }
  };

  const handleClickInboxContextMenu = async (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    switch (menu) {
      case 'INSTANCE':
        {
          try {
            if (!instance) return;
            if (!instance.id) return;

            const success = await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(instance.id, {
              beforeId: null,
              taskIds: multiSelect.map((task) => task.id!),
            });

            if (success) {
              toast.success('인스턴스로 이동하였습니다.');
              onFetch && onFetch();
            }
          } catch (error) {
            toast.error('해당 인스턴스로 가져오기에 실패했습니다.');
          }
        }
        break;
      case 'MOVE_TO_TODAY':
        {
          try {
            const create = await createTaskboxV1TaskboxesPost({
              id: uuidv4(),
              title: '',
              start: { date: dayjs().format('YYYY-MM-DD') },
              end: { date: dayjs().add(1, 'day').format('YYYY-MM-DD') },
              projectId: [routine!.id!],
              beforeId: null,
            });

            if (create && create.id) {
              await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(create.id, {
                beforeId: null,
                taskIds: data.map((task: any) => task.id),
              });
              onFetch && onFetch();
            }
          } catch (error) {
            toast.error('오늘로 이동에 실패했습니다.');
          }
        }
        break;
      case 'DELETE': {
        try {
          const options = {
            paramsSerializer: (params: any) => {
              if (Array.isArray(params.id)) {
                return params.id.map((id: any) => `id=${id}`).join('&');
              }
            },
          };
          await removeTaskboxesV1TaskboxesDelete(
            {
              id: multiSelect.map((task) => task.id!),
            },
            options,
          );
          toast.success('선택된 하위 업무가 삭제되었습니다.');
          onFetch && onFetch();
        } catch (error) {
          toast.error('삭제에 실패했습니다.');
        }
      }
    }
    setMultiSelect([]);
    setMultiSelectAnchorEl(null);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, subtask: OutTaskboxDetailProjectOnly) => {
    if (multiSelectAnchorEl) setMultiSelectAnchorEl(null);
    if (multiSelectSubtaskAnchorEl) setMultiSelectSubtaskAnchorEl(null);

    const el = refRoutineDetail.current?.querySelector('#drag-image');
    e.dataTransfer.setDragImage(el!, 80, 2);

    // setDragContext({
    //   view: 'routine-detail',
    //   type: multiSelectSubtaskIds.length > 0 ? 'routine-multi-subtask' : 'routine-subtask',
    //   projectId: routine?.id,
    //   data: multiSelect.length > 0 ? multiSelect : multiSelectSubtaskIds.length > 0 ? multiSelectSubtaskIds[0].subtaskIds : [subtask],
    // });
  };

  const handleDragEnd = () => {
    setDragContext(null);
    setMultiSelect([]);
    setMultiSelectSubtaskIds([]);
  };

  // const unallocateSubtasksBeforeToday = instances
  //   .filter((instance) => dayjs(instance.start?.date || instance.start?.datetime).isBefore(dayjs(), 'date'))
  //   .flatMap((v) =>
  //     v.tasks
  //       ?.filter((v) => !v.done)
  //       .map((t) => ({
  //         date: v.start?.date || v.start?.datetime,
  //         title: t?.content,
  //         ...t,
  //       })),
  //   );

  // const handleClickInstanceDone = async (instance: OutTaskboxDetailProjectOnly) => {
  //   setInprogressPopover(null);
  //   setSelectedInprogressInstance(null);

  //   const instanceIndex = instances.findIndex((inst) => inst.id === instance.id);

  //   if (instanceIndex === -1) return;

  //   const updatedInstance = {
  //     ...instances[instanceIndex],
  //     done: !instances[instanceIndex].done,
  //     tasks: instances[instanceIndex].tasks?.map((task) => ({
  //       ...task,
  //       done: !instances[instanceIndex].done,
  //     })),
  //   };

  //   const updatedInstances = [...instances];
  //   updatedInstances[instanceIndex] = updatedInstance;
  //   setInstances(updatedInstances);

  //   await updateTaskboxV1TaskboxesTaskboxIdPut(instance.id!, { done: updatedInstance.done });
  //   // onFetch && onFetch();
  // };

  // const handleClickInstanceContinue = async () => {
  //   if (!selectedInprogressInstance) return;
  //   setInprogressPopover(null);
  //   setSelectedInprogressInstance(null);

  //   const index = instances.findIndex((inst) => inst.id === selectedInprogressInstance?.id);
  //   const nextInstance = instances[index + 1];
  //   const assignTasks = selectedInprogressInstance.tasks ? selectedInprogressInstance.tasks.filter((task) => !task.done).map((task) => task.id!) : [];

  //   try {
  //     if (nextInstance) {
  //       const nextInstanceTasks = nextInstance.tasks;
  //       await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
  //         beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
  //         tasks: assignTasks,
  //       });
  //       toast.success('미완료 업무가 프로젝트의 다음 인스턴스로 이동했습니다.');
  //     } else {
  //       await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(routine!.id!, {
  //         taskIds: assignTasks,
  //       });
  //       toast.success('미완료 업무가 프로젝트의 미할당으로 이동했습니다.');
  //     }
  //     onFetch && onFetch();
  //   } catch (error) {
  //     toast.error('일부 완료에 실패했습니다.');
  //   }
  // };

  const handleClickDateText = (instance: OutTaskboxDetailProjectOnly) => {
    setMoveToInstance(instance);
  };

  const [multiSelectSubtaskIds, setMultiSelectSubtaskIds] = useState<MultiSelectSubtaskIds[]>([]);
  const [multiSelectSubtaskAnchorEl, setMultiSelectSubtaskAnchorEl] = useState<HTMLElement | null>(null);

  const handleChangeMultiSelectSubtask = (multiSubtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => {
    setMultiSelectSubtaskIds(multiSubtaskList);

    if (e && (e.ctrlKey || e.metaKey)) {
      setMultiSelectSubtaskAnchorEl(e.currentTarget);
    }
  };

  const handleCloseMultiSelectSubtask = () => {
    setMultiSelectSubtaskAnchorEl(null);
    setMultiSelectSubtaskIds([]);
  };

  const handleMultiSelectSubtaskAnchorEl = () => {
    setMultiSelectSubtaskAnchorEl(null);
  };

  // const handleClickMultiSelectSubtaskAction = async (type: InboxContextMenuType, data: any) => {
  //   switch (type) {
  //     case 'MOVE_TO_AFTER':
  //       {
  //         const taskbox = await readTaskboxV1TaskboxesTaskboxIdGet(multiSelectSubtaskIds[0].taskboxId);
  //         if (!taskbox) return;
  //         if (taskbox.routine) {
  //           const targetProject = projects.find((routine) => routine.id === taskbox.routine?.id);
  //           if (!targetProject) return;

  //           const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
  //           const index = instances.findIndex((instance) => instance.id === taskbox.id);
  //           const nextInstance = instances[index + 1];

  //           try {
  //             if (nextInstance) {
  //               const nextInstanceTasks = nextInstance.tasks;
  //               await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
  //                 beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
  //                 tasks: multiSelectSubtaskIds[0].subtaskIds,
  //               });
  //               toast.success('프로젝트의 다음 인스턴스로 이동했습니다.');
  //             } else {
  //               await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
  //                 taskIds: multiSelectSubtaskIds[0].subtaskIds,
  //               });
  //               toast.success('프로젝트의 미할당으로 이동했습니다.');
  //             }
  //           } catch (error) {
  //             toast.error('일부 완료에 실패했습니다.');
  //           }
  //         }
  //       }
  //       break;
  //     case 'DELETE':
  //       {
  //         if (multiSelectSubtaskIds[0].taskboxId !== 'unallocate-false' && multiSelectSubtaskIds[0].taskboxId !== 'unallocate-true') {
  //           const taskbox = await readTaskboxV1TaskboxesTaskboxIdGet(multiSelectSubtaskIds[0].taskboxId);
  //           if (!taskbox) return;
  //           try {
  //             await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
  //               ...taskbox,
  //               tasks: taskbox?.tasks?.filter((task) => !data.includes(task.id!)) as UpdateTaskForTaskBox[],
  //             });
  //           } catch (e) {
  //             toast.error('삭제할 수 없습니다.');
  //           }
  //         } else {
  //           const options = {
  //             paramsSerializer: (params: any) => {
  //               if (Array.isArray(params.id)) {
  //                 return params.id.map((id: any) => `id=${id}`).join('&');
  //               }
  //             },
  //           };
  //           try {
  //             await removeTaskboxesV1TaskboxesDelete(
  //               {
  //                 id: data,
  //               },
  //               options,
  //             );
  //           } catch (e) {
  //             toast.error('삭제할 수 없습니다.');
  //           }
  //         }
  //       }
  //       break;
  //     case 'MOVE_TO_TODAY':
  //       {
  //         try {
  //           const create = await createTaskboxV1TaskboxesPost({
  //             id: uuidv4(),
  //             title: '',
  //             start: { date: dayjs().format('YYYY-MM-DD') },
  //             end: { date: dayjs().add(1, 'day').format('YYYY-MM-DD') },
  //             projectId: [routine!.id!],
  //             beforeId: null,
  //           });

  //           if (create && create.id) {
  //             await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(create.id, {
  //               beforeId: null,
  //               taskIds: multiSelectSubtaskIds[0].subtaskIds,
  //             });
  //           }
  //         } catch (error) {
  //           toast.error('오늘로 이동에 실패했습니다.');
  //         }
  //       }
  //       break;
  //     case 'INSTANCE':
  //       {
  //         try {
  //           if (!instance) return;
  //           if (!instance.id) return;

  //           const success = await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(instance.id, {
  //             beforeId: null,
  //             taskIds: multiSelectSubtaskIds[0].subtaskIds,
  //           });

  //           if (success) {
  //             toast.success('인스턴스로 이동하였습니다.');
  //           }
  //         } catch (error) {
  //           toast.error('해당 인스턴스로 가져오기에 실패했습니다.');
  //         }
  //       }
  //       break;
  //   }
  //   setMultiSelectSubtaskIds([]);
  //   setMultiSelectSubtaskAnchorEl(null);
  //   onFetch && onFetch();
  // };

  // const [date] = useState(routine?.start?.date ? dayjs(routine?.start?.date, { utc: true }).toDate() : dayjs(routine?.start?.datetime, { utc: true }).toDate());

  const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLDivElement | null>(null);

  const timeFormat = (routine: OutRoutine) => {
    if (!routine) return;
    if (!routine.lastMasterStart || !routine.lastMasterEnd) return '';
    const time =
      language === 'ko'
        ? dayjs(routine.lastMasterStart.datetime, { utc: true }).format('A h시')
        : dayjs(routine.lastMasterStart.datetime, { utc: true }).format('A h') + 'hr';
    // const time = dayjs(routine.lastMasterStart.datetime, { utc: true }).format('a h');
    const minutes = dayjs(routine.lastMasterStart.datetime, { utc: true }).format('mm');
    const formattedTime = language === 'ko' ? (minutes === '00' ? time : `${time} ${minutes}분`) : minutes === '00' ? time : `${time} ${minutes}min`;
    // const formattedTime = minutes === '00' ? time : `${time}hr ${minutes}min`;
    return formattedTime;
  };

  const duringTimeFormat = (routine: OutRoutine) => {
    if (!routine) return;
    if (!routine.lastMasterStart || !routine.lastMasterEnd) return '';
    const timeDiff = dayjs(routine.lastMasterEnd.datetime).diff(dayjs(routine.lastMasterStart.datetime), 'minute');
    const hour = Math.floor(timeDiff / 60);
    const minute = timeDiff % 60;
    const formattedTime =
      language === 'ko'
        ? hour === 0
          ? `${minute}분`
          : minute === 0
          ? `${hour}시간`
          : `${hour}시간 ${minute}분`
        : hour === 0
        ? `${minute}min`
        : minute === 0
        ? `${hour}hr`
        : `${hour}hr ${minute}min`;
    // const formattedTime = hour === 0 ? `${minute}min` : minute === 0 ? `${hour}hr` : `${hour}hr ${minute}min`;
    return formattedTime;
  };

  const handleCloseCustomReccurence = () => {
    if (customRecurrence) {
      if (customRecurrence.interval === 1) {
        if (customRecurrence.intervalUnit === 'DAILY') {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('매일');
        } else if (customRecurrence.intervalUnit === 'WEEKLY') {
          const newWeekdays: string[] = [];
          const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
          // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];

          if (customRecurrence.weekdays) {
            for (const weekdayCode of customRecurrence.weekdays) {
              const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
              if (weekday) {
                language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
                // newWeekdays.push(`${weekday.en}`);
              }
            }
          }

          const weekEveryday = targetArray.every((day) => newWeekdays.includes(day));

          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Every ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Every ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (customRecurrence.intervalUnit === 'MONTHLY') {
          if (customRecurrence.monthlyType === 'nth_weekday') {
            const weekNumber = dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1;
            let suffix;
            if (weekNumber === 1) {
              suffix = `${weekNumber}st`;
            } else if (weekNumber === 2) {
              suffix = `${weekNumber}nd`;
            } else if (weekNumber === 3) {
              suffix = `${weekNumber}rd`;
            } else {
              suffix = `${weekNumber}th`;
            }
            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Every on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Every on the ${suffix} ${dayjs(date).format('dddd')}`);
          } else {
            setRecurrenceUnit(selectableRecurrence[6]);
          }
        } else if (customRecurrence.intervalUnit === 'YEARLY') {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Every year on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Every year on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      } else {
        setRecurrenceUnit(selectableRecurrence[6]);
      }
    } else {
      setRecurrenceUnit(selectableRecurrence[0]);
    }
  };

  const handleChangeRecurrence = (recurrence: InRecurrence | undefined) => {
    if (!routine) return;
    if (!recurrence) return;
    setTaskboxRecurrence(recurrence);
    if (!isEqual(routine.recurrence, recurrence)) setRoutine({ ...routine, recurrence });
  };

  const handleClickCustomRecurrence = (recurrence: InRecurrence) => {
    if (!recurrence) return;
    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);

    if (recurrence.interval === 1) {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Daily, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Daily, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          language === 'ko' ? setRecurrenceUnit('매일') : setRecurrenceUnit('Daily');
          // setRecurrenceUnit('Daily');
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays: string[] = [];
        const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
        // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];
        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        const weekEveryday = newWeekdays.length === targetArray.length && targetArray.every((day) => newWeekdays.includes(day));

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            language === 'ko' ? setRecurrenceUnit('주중 매일(월-금)') : setRecurrenceUnit('Every weekday (Mon-Fri)');
            // setRecurrenceUnit('Every weekday (Mon-Fri)');
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
              else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Weekly on ${newWeekdays.join(', ')}`);
            // else list.splice(6, 1, `Weekly on ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `매월 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on day ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Monthly on day ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            if (language === 'ko') {
              setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // setRecurrenceUnit(`Monthly on the ${suffix} ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Monthly on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Monthly on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else
              list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else
          //   list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (language === 'ko') {
            setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // setRecurrenceUnit(`Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
        }
      }
    } else {
      if (recurrence.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
            else list.splice(6, 1, `${recurrence.interval}일마다`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
            else list.splice(6, 1, `Every ${recurrence.interval} days`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} days`);
          // else list.splice(6, 1, `Every ${recurrence.interval} days`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          }
          // if (list.length === 7)
          //   list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          // else list.splice(6, 1, `${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            // else
            //   list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('D')}일`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on ${dayjs(date).format('D')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(
                    DATE_FORMAT_2,
                  )}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`,
                );
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the ${suffix} ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            } else {
              if (list.length === 7)
                list.splice(
                  6,
                  0,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
              else
                list.splice(
                  6,
                  1,
                  `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
                );
            }
            // if (list.length === 7)
            //   list.splice(
            //     6,
            //     0,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            // else
            //   list.splice(
            //     6,
            //     1,
            //     `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
            //   );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            } else {
              if (list.length === 7)
                list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            }
            // if (list.length === 7)
            //   list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}, ${recurrence.occurrences} times`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (language === 'ko') {
              if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            } else {
              if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
              else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            }
            // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            // else list.splice(6, 1, `Every ${recurrence.interval} months on the last ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          } else {
            if (list.length === 7)
              list.splice(
                6,
                0,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, until ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일, ${recurrence.occurrences}회`);
          } else {
            if (list.length === 7)
              list.splice(6, 0, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
            else
              list.splice(6, 1, `Every ${recurrence.interval} years ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`);
          }
          // if (list.length === 7)
          //   list.splice(
          //     6,
          //     0,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          // else
          //   list.splice(
          //     6,
          //     1,
          //     `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}, ${recurrence.occurrences} times`,
          //   );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (language === 'ko') {
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
            else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('D')}일`);
          } else {
            if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
            else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          }
          // if (list.length === 7) list.splice(6, 0, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          // else list.splice(6, 1, `Every ${recurrence.interval} years on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  };

  useEffect(() => {
    const index = selectableRecurrence.findIndex((v) => v === recurrenceUnit);
    const weekNumber = dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1;
    const suffix = weekNumber === 1 ? `${weekNumber}st` : weekNumber === 2 ? `${weekNumber}nd` : weekNumber === 3 ? `${weekNumber}rd` : `${weekNumber}th`;

    if (index < 5) {
      setSeletableRecurrence(
        language === 'ko'
          ? [
              '매일',
              `매주 ${dayjs(date).format('dddd')}`,
              `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
              `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`,
              '주중 매일(월-금)',
              '사용자 설정',
            ]
          : [
              'Daily',
              `Weekly on ${dayjs(date).format('dddd')}`,
              `Monthly on the ${suffix} ${dayjs(date).format('dddd')}`,
              `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
              'Every weekday (Mon-Fri)',
              'Custom',
            ],
      );
      // setSeletableRecurrence([
      //   'Daily',
      //   `Weekly on ${dayjs(date).format('dddd')}`,
      //   `Monthly on the ${suffix} ${dayjs(date).format('dddd')}`,
      //   `Annually on ${dayjs(date).format('MMMM')} ${dayjs(date).format('D')}`,
      //   'Every weekday (Mon-Fri)',
      //   'Custom',
      // ]);
    }

    if (index === 0) {
      handleChangeRecurrence({ interval: 1, intervalUnit: 'DAILY' });
      setCustomRecurrence({ interval: 1, intervalUnit: 'DAILY' });
    } else if (index === 1) {
      const weekdays = [dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[];
      handleChangeRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
      setCustomRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
    } else if (index === 2) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
    } else if (index === 3) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
    } else if (index === 4) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
    } else {
      handleChangeRecurrence(customRecurrence);
    }
  }, [recurrenceUnit]);

  const handleChangeSubtask = (blocks: TaskBlock[]) => {
    if (!routine) return;
    setRoutine({
      ...routine,
      tasks: blocks.map((block) => {
        return { ...block, title: block.title } as OutTaskboxDetailRoutineOnly;
      }),
    });
  };

  const handleRollbackRoutineSubtasks = () => {
    if (!routine) return;
    if (!originRoutine) return;
    setRoutine({ ...routine, tasks: originRoutine.tasks });
  };

  const handleUpdateRoutineSubtasks = async () => {
    if (!routine) return;
    if (routine.status === 'HOLD') {
      language === 'ko' ? toast.error('홀드 상태의 하위 업무는 수정할 수 없습니다.') : toast.error('Unable to modify subtasks in HOLD status.');
      handleRollbackRoutineSubtasks();
      return;
    }

    setIsProgress(true);
    try {
      setUpdateSubtasks(false);
      await updateRoutineTaskV1RoutineRoutineIdTaskPost(routine!.id, {
        tasks: routine.tasks!.map((task) => {
          return { id: task.id, title: task.title } as InUpdateRoutineTask;
        }),
        start: { date: dayjs(taskViewDate).format(DATE_FORMAT_4) },
      });
      setIsProgress(false);
      onFetch && onFetch();
    } catch (e) {
      language === 'ko' ? toast.error('반복 하위 업무 수정에 실패했습니다.') : toast.error('Failed to update subtasks of routine.');
      // toast.error('Failed to update subtasks of routine.');
      setIsProgress(false);
    }
  };

  const handleChangeTime = (startTime: string, endTime: string, timeDiff: number, eventId?: string, isAllDay?: boolean) => {
    if (!routine) return;
    const datetime = isAllDay
      ? {
          lastMasterStart: { date: dayjs(routine?.lastMasterStart?.date || routine?.lastMasterStart?.datetime, { utc: true }).format(DATE_FORMAT_4) },
          lastMasterEnd: {
            date: dayjs(routine?.lastMasterStart?.date || routine?.lastMasterStart?.datetime, { utc: true })
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          durationMin: timeDiff,
          beforeId: null,
        }
      : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
      ? {
          lastMasterStart: { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) },
          lastMasterEnd: { date: dayjs(endTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) },
          beforeId: null,
        }
      : {
          lastMasterStart: { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
          lastMasterEnd: { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
        };

    setRoutine({
      ...routine,
      ...datetime,
    });

    setTimeAnchorEl(null);
  };

  const handleRollbackRoutineSchedule = () => {
    if (!routine) return;
    if (!originRoutine) return;
    setUpdateSchedule(false);
    setRoutine({
      ...routine,
      lastMasterStart: originRoutine?.lastMasterStart,
      lastMasterEnd: originRoutine?.lastMasterEnd,
      recurrence: originRoutine?.recurrence,
    });
  };

  const handleUpdateRoutineSchedule = async () => {
    if (!routine) return;
    if (!taskboxRecurrence) return;
    setIsProgress(true);
    setUpdateSchedule(false);

    const start = routine.lastMasterStart.date
      ? { date: dayjs(taskViewDate).format(DATE_FORMAT_4) }
      : { datetime: `${dayjs(taskViewDate).format(DATE_FORMAT_4)}T${routine.lastMasterStart.datetime?.split('T')[1]}` };
    const end = routine.lastMasterEnd.date
      ? { date: dayjs(taskViewDate).add(1, 'day').format(DATE_FORMAT_4) }
      : { datetime: `${dayjs(taskViewDate).format(DATE_FORMAT_4)}T${routine.lastMasterEnd.datetime?.split('T')[1]}` };

    try {
      await updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch(routine!.id, {
        start,
        end,
        recurrence: taskboxRecurrence,
      });
      onFetch && onFetch();
      setIsProgress(false);
    } catch (e) {
      language === 'ko' ? toast.error('반복 일정 수정에 실패했습니다.') : toast.error('Failed to update schedule of routine.');
      // toast.error('Failed to update schedule of routine.');
    }
  };

  const handleClickMoreButton = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };

  return isSide ? (
    <RoutineContainer
      className="routine-detail"
      ref={refRoutineDetail}
      left={planViewSidePanel || taskViewSidePanel}
      planView={planView}
      isAnimation={isAnimation}
      onClick={(e) => e.stopPropagation()}
    >
      {isProgress && <LinearProgress sx={{ width: '100%' }} />}
      <RoutineWrapper>
        <ContentHeaderWrapper>
          <ContentHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.Issue width={20} height={20} stroke={COLORS.sub4} fill={COLORS.sub4} />
              <Button
                onClick={() => setProjectFilter('ALL')}
                sx={{
                  backgroundColor: projectFilter === 'ALL' ? COLORS.gray200 : COLORS.white,
                  border: `1px solid ${COLORS.gray200}`,
                  borderRadius: '6px',
                  fontSize: '12px',
                  fontWeight: projectFilter === 'ALL' ? 'bold' : 'normal',
                  color: projectFilter === 'ALL' ? COLORS.gray900 : COLORS.gray500,
                  padding: '2px 8px',
                  marginLeft: '8px',
                  textTransform: 'none',
                }}
              >
                {language === 'ko' ? '상세 보기' : 'View details'}
              </Button>
              <Tooltip title={language === 'ko' ? '준비중' : 'preparing'} disableInteractive>
                <Button
                  onClick={() => {
                    return;
                    // setProjectFilter('SUBTASK');
                  }}
                  sx={{
                    backgroundColor: projectFilter === 'SUBTASK' ? COLORS.gray200 : COLORS.white,
                    border: `1px solid ${COLORS.gray200}`,
                    borderRadius: '6px',
                    fontSize: '12px',
                    fontWeight: projectFilter === 'SUBTASK' ? 'bold' : 'normal',
                    color: projectFilter === 'SUBTASK' ? COLORS.gray900 : COLORS.gray500,
                    padding: '2px 8px',
                    marginLeft: '8px',
                    textTransform: 'none',
                  }}
                >
                  {language === 'ko' ? '인스턴스 보기' : 'View instances'}
                </Button>
              </Tooltip>
            </div>
            <div>
              <IconButton onClick={handleClickMoreButton} sx={{ borderRadius: '8px', padding: '4px' }}>
                <Icons.More width={16} height={16} fill={COLORS.gray500} />
              </IconButton>
              <Tooltip
                title={
                  <div style={{ padding: '4px 2px' }}>
                    <span>{language === 'ko' ? '나가기' : 'Exit'}</span>
                    <span
                      style={{
                        height: '16px',
                        padding: '4px 5px',
                        backgroundColor: COLORS.gray200,
                        borderRadius: '2px',
                        color: COLORS.gray500,
                        marginLeft: 10,
                      }}
                    >
                      Esc
                    </span>
                  </div>
                }
              >
                <IconButton onClick={handleSaveRoutine} sx={{ borderRadius: '8px', padding: '4px' }}>
                  <Icons.FoldDeskIssueDetail width={16} height={16} />
                </IconButton>
              </Tooltip>
            </div>
          </ContentHeader>
          <RoutineTitleWrapper>
            {routine?.status === 'ACTIVE' && (
              <PriorityIssueCheckbox
                onClick={(e) => {
                  e.stopPropagation();
                  setRoutineStatusPopover(e.currentTarget);
                }}
                checked={false}
                bordercolor={COLORS.sub4}
                markercolor={COLORS.sub5}
                width={20}
                height={20}
              />
            )}
            {routine?.status === 'HOLD' && (
              <Tooltip title={language === 'ko' ? '홀드 해제' : 'Unhold'} disableInteractive>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'ACTIVE');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.sub4 }}
                >
                  <Icons.DeskIssueHold width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
            {routine?.status === 'ARCHIVED' && (
              <Tooltip title={language === 'ko' ? '아카이브 해제' : 'Unarchive'} disableInteractive>
                <IconButton
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'ACTIVE');
                  }}
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.sub4 }}
                >
                  <Icons.DeskIssueCheck />
                </IconButton>
              </Tooltip>
            )}

            {/* {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
              <Tooltip
                title={
                  language === 'ko' ? (deskProjects.length >= 7 ? '데스크가 가득 찼습니다' : '홀드 해제') : deskProjects.length >= 7 ? 'Desk is full' : 'Unhold'
                  // deskProjects.length >= 7 ? 'Desk is full' : 'Unhold'
                }
                disableInteractive
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deskProjects.length >= 7
                      ? toast.error(language === 'ko' ? '데스크가 가득 찼습니다.' : 'Desk is full.')
                      : handleClickRoutineCheck(project!.id, 'UNLOCK');
                    // deskProjects.length >= 7 ? toast.error('Desk is full.') : handleClickRoutineCheck(project!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueHold width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
            {project?.place === 'ARCHIVE' && project?.done && (
              <Tooltip
                title={
                  language === 'ko' ? '완료 해제' : 'Undo completion'
                  // 'Undo completion'
                }
                disableInteractive
              >
                <IconButton
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(project!.id, 'UNLOCK');
                  }}
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                >
                  <Icons.DeskIssueCheck />
                </IconButton>
              </Tooltip>
            )}
            {project?.place === 'ARCHIVE' && !project?.done && (
              <Tooltip
                title={
                  language === 'ko' ? '취소 해제' : 'Undo cancel'
                  // 'Undo cancel'
                }
                disableInteractive
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(project!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueCancel width={12} height={12} />
                </IconButton>
              </Tooltip>
            )} */}
            <TextField
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder={
                language === 'ko' ? '루틴 이름을 입력해주세요.' : 'Please enter the routine name.'
                // '루틴 이름을 입력해주세요.'
              }
              ref={refInput}
              value={routine?.title || ''}
              onChange={routine ? (e) => setRoutine({ ...routine, title: e.currentTarget.value }) : undefined}
              onBlur={handleUpdateRoutineTitle}
              onKeyDown={(e) => handleKeyDown(e)}
              InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
              style={{ marginLeft: 8 }}
            />
            {/* <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {routine?.place === 'DESK' && (
              <PriorityIssueCheckbox
                onClick={(e) => {
                  e.stopPropagation();
                  setRoutineStatusPopover(e.currentTarget);
                }}
                checked={routine.done}
                bordercolor={COLORS.issue2}
                width={20}
                height={20}
              />
            )}
            {(routine?.place === 'DRAWER_CREATED' || routine?.place === 'DRAWER_MOVED') && (
              <Tooltip title={deskProjects.length >= 7 ? '데스크가 가득 찼습니다' : '홀드 해제'} disableInteractive>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deskProjects.length >= 7 ? toast.error('데스크가 가득 찼습니다.') : handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueHold width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
            {routine?.place === 'ARCHIVE' && routine?.done && (
              <Tooltip title={'완료 해제'} disableInteractive>
                <IconButton
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                >
                  <Icons.DeskIssueCheck />
                </IconButton>
              </Tooltip>
            )}
            {routine?.place === 'ARCHIVE' && !routine?.done && (
              <Tooltip title={'취소 해제'} disableInteractive>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueCancel width={12} height={12} />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder="프로젝트 이름을 입력해주세요."
              ref={refInput}
              value={routine?.title || ''}
              onChange={routine ? (e) => setRoutine({ ...routine, title: e.currentTarget.value }) : undefined}
              onBlur={handleUpdateRoutineTitle}
              onKeyDown={(e) => handleKeyDown(e)}
              InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
              style={{ marginLeft: 8 }}
            />
          </div> */}
            {/* <div>
              <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={(e) => setContextMenuPopover(e.currentTarget)}>
                <Icons.ColorMore width={24} height={24} />
              </IconButton>
            </div> */}
          </RoutineTitleWrapper>
          {projectFilter === 'ALL' && (
            <CategoryWrapper>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>
                  {language === 'ko' ? '카테고리' : 'Category'}
                  {/* Category */}
                </div>
                {routine?.category ? (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(routine.category!.color)}
                    bgColor={getCategoryBgColor(routine.category!.color)}
                    onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                  >
                    {`# ${routine.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(routine.category!.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} stroke={getCategoryTextColor(routine.category!.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                ) : (
                  <Button
                    onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '6px',
                      color: COLORS.gray500,
                      fontSize: '12px',
                      padding: '2px 6px',
                      textTransform: 'none',
                    }}
                  >
                    <Icons.Category width={16} height={16} fill={COLORS.gray500} />
                    <span style={{ margin: '1px 0px 0px 8px' }}>
                      {language === 'ko' ? '카테고리 추가하기' : 'Add Category'}
                      {/* Add category */}
                    </span>
                  </Button>
                )}
              </div>
            </CategoryWrapper>
          )}
          <ScheduleWrapper>
            <ScheduleTitleWrapper>
              {language === 'ko' ? '스케줄' : 'Schedule'}
              {/* Schedule */}
            </ScheduleTitleWrapper>
            <Divider style={{ margin: '8px 0px' }} />
            <ScheduleBorderWrapper border={updateSchedule}>
              <RecurrenceActionsWrapper>
                <div style={{ width: 60 }}>
                  {language === 'ko' ? '시간' : 'Time'}
                  {/* Time */}
                </div>
                <TimeSelectWrapper
                  focus={Boolean(timeAnchorEl)}
                  onClick={(e) => {
                    routine?.status === 'HOLD'
                      ? toast.error(language === 'ko' ? '홀드된 루틴은 시간을 변경할 수 없습니다.' : 'Unable to change the time in HOLD status.')
                      : setTimeAnchorEl(e.currentTarget);
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icons.Time width={12} height={12} />
                    <div style={{ height: '16px', marginLeft: 6 }}>
                      {language === 'ko'
                        ? routine?.lastMasterStart.date
                          ? '종일'
                          : `${timeFormat(routine!)}부터, ${duringTimeFormat(routine!)}동안`
                        : routine?.lastMasterStart.date
                        ? 'All day'
                        : `From ${timeFormat(routine!)}, for ${duringTimeFormat(routine!)}`}
                      {/* {routine?.lastMasterStart.date ? 'All day' : `From ${timeFormat(routine!)}, for ${duringTimeFormat(routine!)}`} */}
                    </div>
                  </div>
                </TimeSelectWrapper>
              </RecurrenceActionsWrapper>
              <RecurrenceActionsWrapper style={{ marginBottom: 0, paddingTop: '1px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                <div style={{ width: 60 }}>
                  {language === 'ko' ? '주기' : 'Recurrence'}
                  {/* Recurrence */}
                </div>
                <RecurrenceWrapper className="recurrence">
                  <Select
                    value={recurrenceUnit}
                    readOnly={routine?.status === 'HOLD'}
                    onClick={() =>
                      routine?.status === 'HOLD' &&
                      toast.error(language === 'ko' ? '홀드된 루틴은 반복 주기를 변경할 수 없습니다.' : 'Unable to change the recurrence in HOLD status.')
                    }
                    onChange={(e) => {
                      setRecurrenceUnit(e.target.value);
                    }}
                    style={{ width: '100%', height: 22, marginLeft: '8px', borderRadius: 6, backgroundColor: COLORS.gray100 }}
                    renderValue={() => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icons.Recurrence width={12} height={12} stroke={COLORS.gray600} style={{ marginRight: 6 }} />
                        <div style={{ color: COLORS.gray600, fontSize: '12px' }}>{recurrenceUnit}</div>
                      </div>
                    )}
                    inputProps={{
                      sx: {
                        padding: '8px',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 372,
                        },
                      },
                    }}
                  >
                    {selectableRecurrence.map((v, idx) => (
                      <MenuItem key={idx} value={v}>
                        <span style={{ fontSize: '12px', width: '100%' }}>{v}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </RecurrenceWrapper>
              </RecurrenceActionsWrapper>
              {updateSchedule && (
                <RecurrenceScheduleButton>
                  <Button
                    variant="contained"
                    onClick={handleRollbackRoutineSchedule}
                    sx={{
                      'backgroundColor': COLORS.white,
                      'border': `1px solid ${COLORS.gray200}`,
                      'borderRadius': '6px',
                      'boxShadow': 'none',
                      'color': COLORS.gray600,
                      'fontSize': '12px',
                      'fontWeight': 'bold',
                      'padding': '5px 8px',
                      'textTransform': 'none',
                      ':hover': { backgroundColor: COLORS.gray100 },
                    }}
                  >
                    {language === 'ko' ? '취소하기' : 'Cancel'}
                    {/* Cancel */}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleUpdateRoutineSchedule}
                    sx={{
                      borderRadius: '6px',
                      boxShadow: 'none',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      padding: '5px 8px',
                      marginLeft: '8px',
                      textTransform: 'none',
                    }}
                  >
                    {language === 'ko' ? '저장하기' : 'Save'}
                    {/* Apply */}
                  </Button>
                </RecurrenceScheduleButton>
              )}
            </ScheduleBorderWrapper>
            {updateSchedule && (
              <ScheduleReflectionNoticeWrapper>
                <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray600} style={{ marginRight: 4 }} />
                <div>
                  {language === 'ko'
                    ? `변경사항은 ${dayjs(taskViewDate).format('M월 D일 ddd요일')}의 인스턴스부터 반영돼요`
                    : `Changes take effect from the ${dayjs(taskViewDate).format('ddd, MMM D')} instance.`}
                </div>
              </ScheduleReflectionNoticeWrapper>
            )}
          </ScheduleWrapper>
        </ContentHeaderWrapper>
        <ContentWrapper>
          {projectFilter === 'ALL' && (
            <div style={{ width: '100%' }}>
              <MemoLinkWrapper>
                <Divider sx={{ width: '100%', margin: '16px 0px' }} />
                <MemoWrapper className={routine ? 'routine-memo' : 'empty-routine-memo'}>
                  {isMemoReady ? (
                    <RemirrorEditor id={routine?.id || ''} data={routineMemo as RemirrorContentType} onChangeData={handleChangeMemo} />
                  ) : (
                    <div style={{ height: '128px' }}>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </div>
                  )}
                </MemoWrapper>
                <Divider sx={{ width: '100%', margin: '16px 0px' }} />
                <LinkWrapper>
                  {routine?.links?.map((link, idx) => (
                    <LinkContentWrapper data-link-idx={idx} key={idx}>
                      <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                        <Icons.Link2 />
                        {link.url === '' ? (
                          <TextField
                            data-link-input-id={idx}
                            inputRef={refCreateLinkInput}
                            fullWidth
                            autoFocus
                            placeholder={
                              language === 'ko' ? 'URL 주소(https://…)를 붙여넣고 Enter로 입력하기' : 'Paste a URL (https://...) and press Enter to submit'
                              // 'Paste a URL (https://...) and press Enter to submit'
                            }
                            onChange={(e) => {
                              setSelectedLink({ ...link, url: e.target.value, title: e.target.value, index: idx });
                            }}
                            onBlur={handleSaveLink}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                if (e.nativeEvent.isComposing) return;
                                e.preventDefault();
                                handleSaveLink();
                              }
                            }}
                            inputProps={{
                              style: { padding: '0px', fontSize: '13px', border: 'none' },
                            }}
                            sx={{ border: 'none', fontSize: '13px', marginLeft: '8px' }}
                          />
                        ) : (
                          <Tooltip title={link.url} disableInteractive>
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                width: '100%',
                                fontSize: '13px',
                                marginLeft: '8px',
                                textDecoration: 'underline',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {link.title || link.url}
                            </a>
                          </Tooltip>
                        )}
                      </div>
                      <div
                        className="link-icon"
                        style={{
                          backgroundColor: COLORS.white,
                          border: `1px solid ${COLORS.gray200}`,
                          borderRadius: '8px',
                          padding: '2px',
                          position: 'absolute',
                          right: '8px',
                        }}
                      >
                        <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                          <Icons.Duplicate stroke={COLORS.gray400} />
                        </IconButton>
                        <IconButton
                          sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}
                          onClick={() => handleClickLinkUpdate(link, idx)}
                        >
                          <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
                        </IconButton>
                      </div>
                    </LinkContentWrapper>
                  ))}
                  <CreateLinkButton onClick={handleCreateLink}>
                    <Icons.AddLink />
                    <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>
                      {language === 'ko' ? '링크 추가하기' : 'Add link'}
                      {/* Add link */}
                    </div>
                  </CreateLinkButton>
                </LinkWrapper>
                <Divider sx={{ width: '100%', margin: '16px 0px' }} />
              </MemoLinkWrapper>
              <TaskListWrapper>
                <TaskListTitle>
                  {language === 'ko' ? '반복 하위 업무' : 'Routine Subtasks'}
                  {/* Routine Subtasks */}
                </TaskListTitle>
                <TaskListBorderWrapper border={updateSubtasks}>
                  <div style={{ marginLeft: '-10px' }}>
                    <TaskBlockInputList
                      draggable={false}
                      isRoutine
                      suppressTemplate
                      suppressCheckboxToggle
                      suppressMoveToAfter
                      suppressCategory
                      suppressDeadline
                      suppressTaskMore
                      suppressAddTime
                      tasks={routine?.tasks as TaskBlock[]}
                      onChange={handleChangeSubtask}
                    />
                  </div>
                  {updateSubtasks && (
                    <TaskListButtonWrapper>
                      <Button
                        variant="contained"
                        onClick={handleRollbackRoutineSubtasks}
                        sx={{
                          'backgroundColor': COLORS.white,
                          'border': `1px solid ${COLORS.gray200}`,
                          'borderRadius': '6px',
                          'boxShadow': 'none',
                          'color': COLORS.gray600,
                          'fontSize': '12px',
                          'fontWeight': 'bold',
                          'padding': '5px 8px',
                          'textTransform': 'none',
                          ':hover': {
                            backgroundColor: COLORS.gray100,
                          },
                        }}
                      >
                        {language === 'ko' ? '취소하기' : 'Cancel'}
                        {/* Cancel */}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleUpdateRoutineSubtasks}
                        sx={{
                          borderRadius: '6px',
                          boxShadow: 'none',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          padding: '5px 8px',
                          marginLeft: '8px',
                          textTransform: 'none',
                        }}
                      >
                        {language === 'ko' ? '저장하기' : 'Save'}
                        {/* Apply */}
                      </Button>
                    </TaskListButtonWrapper>
                  )}
                </TaskListBorderWrapper>
                {updateSubtasks && (
                  <ScheduleReflectionNoticeWrapper>
                    <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray600} style={{ marginRight: 4 }} />
                    <div>
                      {language === 'ko'
                        ? `변경사항은 ${dayjs(taskViewDate).format('M월 D일 ddd요일')}의 인스턴스부터 반영돼요`
                        : `Changes take effect from the ${dayjs(taskViewDate).format('ddd, MMM D')} instance.`}
                    </div>
                  </ScheduleReflectionNoticeWrapper>
                )}
              </TaskListWrapper>
            </div>
          )}
          {/* {projectFilter === 'SUBTASK' &&
          unallocateSubtasksBeforeToday.length > 0 &&
          !dayjs(instance?.start?.date || instance?.start?.datetime).isBefore(dayjs(), 'date') && (
            <NoticeUndoneSubtaskWrapper>
              <NoticeUndoneSubtaskHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Alert width={16} height={16} fill={COLORS.negative1} />
                  <span
                    style={{ marginLeft: 4, fontWeight: 700, fontSize: 13 }}
                  >{`오늘 이전 일자에 ${unallocateSubtasksBeforeToday.length}개의 미완료 하위 업무가 있어요.`}</span>
                </div>
                <IconButton onClick={() => setIsFoldUndoneSubtask(!isFoldUndoneSubtask)} sx={{ padding: '4px' }}>
                  {isFoldUndoneSubtask ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                </IconButton>
              </NoticeUndoneSubtaskHeader>
              <Divider sx={{ margin: '8px 0px' }} />
              {!isFoldUndoneSubtask &&
                unallocateSubtasksBeforeToday.map((task) => (
                  <UnallocateTaskWrapper
                    key={task!.id}
                    draggable
                    multiSelect={multiSelect.map((v) => v.id).includes(task!.id)}
                    onDragStart={(e) => {
                      handleDragStart(e, task! as OutTaskboxDetailProjectOnly);
                    }}
                    onDragEnd={handleDragEnd}
                    onClick={(e) => {
                      if (e.ctrlKey || e.metaKey) {
                        setMultiSelectAnchorEl(e.currentTarget);
                        handleMultiSelectIds(task! as OutTaskboxDetailProjectOnly);
                      }
                    }}
                    style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <TaskListHeader style={{ marginBottom: 8 }}>
                      <span>
                        {task!.start?.datetime
                          ? dayjs(task!.start?.datetime, { utc: true }).format('M월 D일 dddd A h:mm')
                          : dayjs(task!.start?.date, { utc: true }).format('M월 D일 dddd')}
                      </span>
                      <Tooltip title="일시 지정 해제하기">
                        <IconButton
                          className="close-btn"
                          onClick={() => handleMoveToUnallocate(task!)}
                          sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px' }}
                        >
                          <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                        </IconButton>
                      </Tooltip>
                    </TaskListHeader>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icons.TaskCheckbox fill={'#D18DF2'} />
                      <div style={{ marginLeft: 8 }}>{task!.content}</div>
                    </div>
                  </UnallocateTaskWrapper>
                ))}
            </NoticeUndoneSubtaskWrapper>
          )} */}
        </ContentWrapper>
        <ProjectDragImage id="drag-image">
          <ProjectDragImageCount>
            {multiSelect.length || (multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].subtaskIds.length) || 1}
          </ProjectDragImageCount>
          <span style={{ fontSize: 13, marginLeft: 8 }}>
            {language === 'ko' ? '개 선택됨' : 'selected'}
            {/* selected */}
          </span>
        </ProjectDragImage>
        {routineStatusPopover && (
          <Popover
            className="routine-status"
            open={Boolean(routineStatusPopover)}
            anchorEl={routineStatusPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{ marginTop: 0.5 }}
            onClose={() => setRoutineStatusPopover(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RoutineStatusWrapper>
              <RoutineStatusDetail
                onClick={() => {
                  handleClickRoutineCheck(routine!.id, 'HOLD');
                }}
              >
                <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                  <Icons.DeskIssueHold />
                </IconButton>
                {language === 'ko' ? '홀드' : 'Hold'}
              </RoutineStatusDetail>
              <RoutineStatusDetail
                onClick={() => {
                  handleClickRoutineCheck(routine!.id, 'ARCHIVED');
                }}
              >
                <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                  <Icons.DeskIssueCheck />
                </IconButton>
                {language === 'ko' ? '아카이브' : 'Archive'}
              </RoutineStatusDetail>
            </RoutineStatusWrapper>
          </Popover>
        )}
        {linkAnchorEl && (
          <Popover
            open={Boolean(linkAnchorEl)}
            anchorEl={linkAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleSaveLink}
            sx={{ marginLeft: '30px' }}
          >
            <LinkPopoverContainer className="routine-link">
              <LinkPopoverWrapper>
                <LinkURLWrapper>
                  <div>
                    {language === 'ko' ? '링크 URL' : 'Link URL'}
                    {/* Link URL */}
                  </div>
                  <TextField
                    autoFocus
                    fullWidth
                    value={selectedLink?.url}
                    placeholder={
                      language === 'ko' ? '링크 URL 주소(https://…)를 입력해주세요.' : 'Please enter the link URL (https://...).'
                      // 'Please enter the link URL (https://...).'
                    }
                    onChange={(e) => {
                      if (!selectedLink) return;
                      setSelectedLink({ ...selectedLink, url: e.target.value });
                    }}
                    sx={{ marginTop: '4px' }}
                    inputProps={{
                      style: { padding: '8px 12px', fontSize: '12px' },
                    }}
                  />
                </LinkURLWrapper>
                <LinkTitleWrapper>
                  <div>
                    {language === 'ko' ? '링크 제목' : 'Link title'}
                    {/* Link title */}
                  </div>
                  <TextField
                    fullWidth
                    value={selectedLink?.title}
                    placeholder={
                      language === 'ko' ? '링크 제목을 입력해주세요.' : 'Please enter the link title.'
                      // 'Please enter the link title.'
                    }
                    onChange={(e) => {
                      if (!selectedLink) return;
                      setSelectedLink({ ...selectedLink, title: e.target.value });
                    }}
                    sx={{ marginTop: '4px' }}
                    inputProps={{
                      style: { padding: '8px 12px', fontSize: '12px' },
                    }}
                  />
                </LinkTitleWrapper>
              </LinkPopoverWrapper>
              <Divider />
              <LinkDeleteWrapper>
                <Button
                  onClick={handleDeleteLink}
                  sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
                >
                  <Icons.Delete fill={COLORS.negative1} />
                  <span style={{ marginLeft: '8px' }}>
                    {language === 'ko' ? '링크 삭제' : 'Delete link'}
                    {/* Delete link */}
                  </span>
                </Button>
              </LinkDeleteWrapper>
            </LinkPopoverContainer>
          </Popover>
        )}
        {categoryAnchorEl && (
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setCategoryAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <div className="routine-category">
              <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
            </div>
          </Popover>
        )}
        {timeAnchorEl && (
          <Popover
            className="routin-time-select"
            open={Boolean(timeAnchorEl)}
            anchorEl={timeAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => setTimeAnchorEl(null)}
            sx={{ marginTop: '2px', left: '-15px' }}
          >
            <SimpleTimeSelectionModal
              event={{
                type: 'ROUTINE_TASK',
                start: routine?.lastMasterStart.date ? { date: routine?.lastMasterStart.date } : { datetime: routine?.lastMasterStart.datetime },
                end: routine?.lastMasterEnd.date ? { date: routine?.lastMasterEnd.date } : { datetime: routine?.lastMasterEnd.datetime },
              }}
              onClose={() => setTimeAnchorEl(null)}
              onChangeTime={handleChangeTime}
            />
          </Popover>
        )}
        {inprogressPopover && (
          <Popover
            open={Boolean(inprogressPopover)}
            anchorEl={inprogressPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setInprogressPopover(null)}
            sx={{ marginTop: '4px' }}
          >
            <InprogressProject className="routine-inprogress">
              <InprogressProjectContent
                onClick={() => {
                  if (!selectedInprogressInstance) return;
                  // handleClickInstanceDone(selectedInprogressInstance);
                }}
              >
                <Icons.TaskboxCheck width={16} height={16} fill={COLORS.issue2} />
                <span style={{ marginLeft: '8px' }}>
                  {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                  {/* Complete all */}
                </span>
              </InprogressProjectContent>
              <Tooltip
                title={
                  <span>
                    {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                    <br />
                    {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                  </span>
                  // <span>
                  //   Complete the instance and send any incomplete
                  //   <br />
                  //   subtasks to the next instance or project.
                  // </span>
                }
                placement="right"
              >
                <InprogressProjectContent onClick={() => {}}>
                  <Icons.ContinueTaskbox fill={COLORS.issue2} />
                  <span style={{ marginLeft: '8px' }}>
                    {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                    {/* Complete some */}
                  </span>
                  <span style={{ marginLeft: '4px' }}>
                    <Icons.QuestionInfo />
                  </span>
                </InprogressProjectContent>
              </Tooltip>
            </InprogressProject>
          </Popover>
        )}
        {menuAnchorEl && (
          <Popover
            open={Boolean(menuAnchorEl)}
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => setMenuAnchorEl(null)}
            sx={{ marginTop: '4px' }}
          >
            <MenuList sx={{ padding: '8px' }}>
              <MenuItem
                onClick={() => {
                  setMenuAnchorEl(null);
                  handleOpenDeleteDialog();
                }}
                sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px', color: COLORS.negative1, fontSize: '12px', padding: '8px' }}
              >
                <Icons.Delete fill={COLORS.negative1} />
                <div style={{ marginLeft: 8 }}>{language === 'ko' ? '루틴 삭제' : 'Delete routine'}</div>
              </MenuItem>
            </MenuList>
          </Popover>
        )}
        {multiSelect.length > 0 && (
          <Popper
            open={Boolean(multiSelectAnchorEl)}
            anchorEl={multiSelectAnchorEl}
            placement={'left-start'}
            ref={refPopperMenu}
            sx={{ zIndex: 1201, right: '4px' }}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ]}
          >
            <SubtaskMultiSelectPopoverWrapper className="routine-detail-menu">
              <div style={{ padding: '16px 16px 0px 16px', fontSize: 12, fontWeight: 'bold' }}>{multiSelect.length}개의 하위 업무가 선택되었습니다.</div>
              <InboxContextMenu
                id=""
                type="routine"
                menus={instance?.id ? ['INSTANCE', 'DELETE'] : ['MOVE_TO_TODAY', 'DELETE']}
                data={multiSelect}
                onClickMenu={handleClickInboxContextMenu}
              />
            </SubtaskMultiSelectPopoverWrapper>
          </Popper>
        )}
        <Dialog
          open={
            language === 'ko' ? recurrenceUnit === '사용자 설정' : recurrenceUnit === 'Custom'
            // recurrenceUnit === 'Custom'
          }
          onClose={handleCloseCustomReccurence}
        >
          <RecurrenceCustomDialog
            date={dayjs().toDate()}
            defaultCustomRecurrence={customRecurrence}
            onClose={handleCloseCustomReccurence}
            onChange={handleClickCustomRecurrence}
          />
        </Dialog>
        <ConfirmDialog
          className="routine-delete"
          ref={refConfirm}
          data={routine}
          type={'routine'}
          title={
            language === 'ko' ? '루틴 삭제' : 'Delete routine'
            // 'Delete routine'
          }
          onConfirm={handleDelete}
        >
          <div style={{ color: COLORS.negative1, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
            {language === 'ko' ? `루틴을 삭제하시겠어요?` : `Delete routine?`}
            {/* Delete routine? */}
          </div>
          <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>
            {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
            {/* All related data will be deleted. */}
          </div>
        </ConfirmDialog>
      </RoutineWrapper>
    </RoutineContainer>
  ) : (
    <Drawer
      className="routine-drawer"
      anchor="right"
      open={!!routine}
      onClose={handleSaveRoutine}
      SlideProps={{
        style: { visibility: 'visible' },
      }}
    >
      <Container ref={refRoutineDetail}>
        {isProgress && <LinearProgress sx={{ width: '100%', marginTop: 1 }} />}
        <RoutineWrapper>
          <ContentHeaderWrapper>
            <ContentHeader>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Issue width={20} height={20} stroke={COLORS.sub4} fill={COLORS.sub4} />
                {/* {unallocate.filter((task) => !task.done).length > 0 && (
              <>
                <Button
                  onClick={() => setProjectFilter('ALL')}
                  sx={{
                    backgroundColor: projectFilter === 'ALL' ? COLORS.gray200 : COLORS.white,
                    border: `1px solid ${COLORS.gray200}`,
                    borderRadius: '6px',
                    fontSize: '12px',
                    fontWeight: projectFilter === 'ALL' ? 'bold' : 'normal',
                    color: projectFilter === 'ALL' ? COLORS.gray900 : COLORS.gray500,
                    padding: '2px 8px',
                    marginLeft: '8px',
                  }}
                >
                  모두 보기
                </Button>
                <Button
                  onClick={() => setProjectFilter('SUBTASK')}
                  sx={{
                    backgroundColor: projectFilter === 'SUBTASK' ? COLORS.gray200 : COLORS.white,
                    border: `1px solid ${COLORS.gray200}`,
                    borderRadius: '6px',
                    fontSize: '12px',
                    fontWeight: projectFilter === 'SUBTASK' ? 'bold' : 'normal',
                    color: projectFilter === 'SUBTASK' ? COLORS.gray900 : COLORS.gray500,
                    padding: '2px 8px',
                    marginLeft: '8px',
                  }}
                >{`타임블록할 하위 업무 ${unallocate.filter((task) => !task.done).length}`}</Button>
              </>
            )} */}
              </div>
              <div>
                <IconButton onClick={handleSaveRoutine} sx={{ borderRadius: '8px', padding: '4px' }}>
                  <Icons.FoldDeskIssueDetail width={16} height={16} />
                </IconButton>
                <IconButton onClick={handleOpenDeleteDialog} sx={{ borderRadius: '8px', padding: '4px' }}>
                  <Icons.Delete width={16} height={16} fill={COLORS.gray500} />
                </IconButton>
              </div>
            </ContentHeader>
            <RoutineTitleWrapper>
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder={
                  language === 'ko' ? '루틴 이름을 입력해주세요.' : 'Please enter the routine name.'
                  // '루틴 이름을 입력해주세요.'
                }
                ref={refInput}
                value={routine?.title || ''}
                onChange={routine ? (e) => setRoutine({ ...routine, title: e.currentTarget.value }) : undefined}
                onBlur={handleUpdateRoutineTitle}
                onKeyDown={(e) => handleKeyDown(e)}
                InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
                style={{ marginLeft: 8 }}
              />
              {/* <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {routine?.place === 'DESK' && (
              <PriorityIssueCheckbox
                onClick={(e) => {
                  e.stopPropagation();
                  setRoutineStatusPopover(e.currentTarget);
                }}
                checked={routine.done}
                bordercolor={COLORS.issue2}
                width={20}
                height={20}
              />
            )}
            {(routine?.place === 'DRAWER_CREATED' || routine?.place === 'DRAWER_MOVED') && (
              <Tooltip title={deskProjects.length >= 7 ? '데스크가 가득 찼습니다' : '홀드 해제'} disableInteractive>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deskProjects.length >= 7 ? toast.error('데스크가 가득 찼습니다.') : handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueHold width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}
            {routine?.place === 'ARCHIVE' && routine?.done && (
              <Tooltip title={'완료 해제'} disableInteractive>
                <IconButton
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                >
                  <Icons.DeskIssueCheck />
                </IconButton>
              </Tooltip>
            )}
            {routine?.place === 'ARCHIVE' && !routine?.done && (
              <Tooltip title={'취소 해제'} disableInteractive>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRoutineCheck(routine!.id, 'UNLOCK');
                  }}
                  disableRipple
                  sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                >
                  <Icons.DeskIssueCancel width={12} height={12} />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder="프로젝트 이름을 입력해주세요."
              ref={refInput}
              value={routine?.title || ''}
              onChange={routine ? (e) => setRoutine({ ...routine, title: e.currentTarget.value }) : undefined}
              onBlur={handleUpdateRoutineTitle}
              onKeyDown={(e) => handleKeyDown(e)}
              InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
              style={{ marginLeft: 8 }}
            />
          </div> */}
              {/* <div>
              <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={(e) => setContextMenuPopover(e.currentTarget)}>
                <Icons.ColorMore width={24} height={24} />
              </IconButton>
            </div> */}
            </RoutineTitleWrapper>
            {projectFilter === 'ALL' && (
              <CategoryWrapper>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                  <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>
                    {language === 'ko' ? '카테고리' : 'Category'}
                    {/* Category */}
                  </div>
                  {routine?.category ? (
                    <CategoryShowingWrapper
                      textColor={getCategoryTextColor(routine.category!.color)}
                      bgColor={getCategoryBgColor(routine.category!.color)}
                      onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                    >
                      {`# ${routine.category.name}`}
                      <IconButton
                        className="category-detach-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickCategoryActions(null, 'SELECT');
                        }}
                        sx={{
                          'width': '12px',
                          'height': '12px',
                          'borderRadius': '4px',
                          'marginLeft': '4px',
                          'marginTop': '1px',
                          'padding': '0px',
                          ':hover': {
                            backgroundColor: hexToRGBA(getCategoryTextColor(routine.category!.color)!, 0.3),
                          },
                        }}
                        style={categoryAnchorEl ? { display: 'flex' } : {}}
                      >
                        <Icons.Close width={8} height={8} stroke={getCategoryTextColor(routine.category!.color)} />
                      </IconButton>
                    </CategoryShowingWrapper>
                  ) : (
                    <Button
                      onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '6px',
                        color: COLORS.gray500,
                        fontSize: '12px',
                        padding: '2px 6px',
                        textTransform: 'none',
                      }}
                    >
                      <Icons.Category width={16} height={16} fill={COLORS.gray500} />
                      <span style={{ margin: '1px 0px 0px 8px' }}>
                        {language === 'ko' ? '카테고리 추가하기' : 'Add Category'}
                        {/* Add category */}
                      </span>
                    </Button>
                  )}
                </div>
              </CategoryWrapper>
            )}
            <ScheduleWrapper>
              <ScheduleTitleWrapper>
                {language === 'ko' ? '스케줄' : 'Schedule'}
                {/* Schedule */}
              </ScheduleTitleWrapper>
              <Divider style={{ margin: '8px 0px' }} />
              <ScheduleBorderWrapper border={updateSchedule}>
                <RecurrenceActionsWrapper>
                  <div style={{ width: 60 }}>
                    {language === 'ko' ? '시간' : 'Time'}
                    {/* Time */}
                  </div>
                  <TimeSelectWrapper
                    focus={Boolean(timeAnchorEl)}
                    onClick={(e) => {
                      routine?.status === 'HOLD'
                        ? toast.error(language === 'ko' ? '홀드된 루틴은 시간을 변경할 수 없습니다.' : 'Unable to change the time in Hold status.')
                        : setTimeAnchorEl(e.currentTarget);
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icons.Time width={12} height={12} />
                      <div style={{ height: '16px', marginLeft: 6 }}>
                        {language === 'ko'
                          ? routine?.lastMasterStart.date
                            ? '종일'
                            : `${timeFormat(routine!)}`
                          : routine?.lastMasterStart.date
                          ? 'All day'
                          : `From ${timeFormat(routine!)}, for ${duringTimeFormat(routine!)}`}
                        {/* {routine?.lastMasterStart.date ? 'All day' : `From ${timeFormat(routine!)}, for ${duringTimeFormat(routine!)}`} */}
                      </div>
                    </div>
                  </TimeSelectWrapper>
                </RecurrenceActionsWrapper>
                <RecurrenceActionsWrapper style={{ marginBottom: 0 }}>
                  <div style={{ width: 60 }}>
                    {language === 'ko' ? '주기' : 'Recurrence'}
                    {/* Recurrence */}
                  </div>
                  <RecurrenceWrapper className="recurrence">
                    <Select
                      value={recurrenceUnit}
                      readOnly={routine?.status === 'HOLD'}
                      onClick={() =>
                        routine?.status === 'HOLD' &&
                        toast.error(language === 'ko' ? '홀드된 루틴은 반복 주기를 변경할 수 없습니다.' : 'Unable to change the recurrence in Hold status.')
                      }
                      onChange={(e) => {
                        setRecurrenceUnit(e.target.value);
                      }}
                      style={{ width: '100%', height: 22, marginLeft: '8px', borderRadius: 6, backgroundColor: COLORS.gray100 }}
                      renderValue={() => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icons.Recurrence width={12} height={12} stroke={COLORS.gray600} style={{ marginRight: 6 }} />
                          <div style={{ color: COLORS.gray600, fontSize: '12px' }}>{recurrenceUnit}</div>
                        </div>
                      )}
                      inputProps={{
                        sx: {
                          padding: '8px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 372,
                          },
                        },
                      }}
                    >
                      {selectableRecurrence.map((v, idx) => (
                        <MenuItem key={idx} value={v}>
                          <span style={{ fontSize: '12px', width: '100%' }}>{v}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </RecurrenceWrapper>
                </RecurrenceActionsWrapper>
                {updateSchedule && (
                  <RecurrenceScheduleButton>
                    <Button
                      variant="contained"
                      onClick={handleRollbackRoutineSchedule}
                      sx={{
                        'backgroundColor': COLORS.white,
                        'border': `1px solid ${COLORS.gray200}`,
                        'borderRadius': '6px',
                        'boxShadow': 'none',
                        'color': COLORS.gray600,
                        'fontSize': '12px',
                        'fontWeight': 'bold',
                        'padding': '5px 8px',
                        'textTransform': 'none',
                        ':hover': { backgroundColor: COLORS.gray100 },
                      }}
                    >
                      {language === 'ko' ? '취소하기' : 'Cancel'}
                      {/* Cancel */}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleUpdateRoutineSchedule}
                      sx={{
                        borderRadius: '6px',
                        boxShadow: 'none',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        padding: '5px 8px',
                        marginLeft: '8px',
                        textTransform: 'none',
                      }}
                    >
                      {language === 'ko' ? '저장하기' : 'Save'}
                      {/* Apply */}
                    </Button>
                  </RecurrenceScheduleButton>
                )}
              </ScheduleBorderWrapper>
              {updateSchedule && (
                <ScheduleReflectionNoticeWrapper>
                  <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray600} style={{ marginRight: 4 }} />
                  <div>
                    {language === 'ko'
                      ? `변경사항은 ${dayjs(taskViewDate).format('M월 D일 ddd요일')}의 인스턴스부터 반영돼요`
                      : `Changes take effect from the ${dayjs(taskViewDate).format('ddd, MMM D')} instance.`}
                  </div>
                </ScheduleReflectionNoticeWrapper>
              )}
            </ScheduleWrapper>
          </ContentHeaderWrapper>
          <ContentWrapper>
            {projectFilter === 'ALL' && (
              <div style={{ width: '100%' }}>
                <MemoLinkWrapper>
                  <Divider sx={{ width: '100%', margin: '16px 0px' }} />
                  <MemoWrapper className={routine ? 'routine-memo' : 'empty-routine-memo'}>
                    {isMemoReady ? (
                      <RemirrorEditor id={routine?.id || ''} data={routineMemo as RemirrorContentType} onChangeData={handleChangeMemo} />
                    ) : (
                      <div style={{ height: '128px' }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                      </div>
                    )}
                  </MemoWrapper>
                  <Divider sx={{ width: '100%', margin: '16px 0px' }} />
                  <LinkWrapper>
                    {routine?.links?.map((link, idx) => (
                      <LinkContentWrapper data-link-idx={idx} key={idx}>
                        <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                          <Icons.Link2 />
                          {link.url === '' ? (
                            <TextField
                              data-link-input-id={idx}
                              inputRef={refCreateLinkInput}
                              fullWidth
                              autoFocus
                              placeholder={
                                language === 'ko' ? 'URL 주소(https://…)를 붙여넣고 Enter로 입력하기' : 'Paste a URL (https://...) and press Enter to submit'
                                // 'Paste a URL (https://...) and press Enter to submit'
                              }
                              onChange={(e) => {
                                setSelectedLink({ ...link, url: e.target.value, title: e.target.value, index: idx });
                              }}
                              onBlur={handleSaveLink}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  if (e.nativeEvent.isComposing) return;
                                  e.preventDefault();
                                  handleSaveLink();
                                }
                              }}
                              inputProps={{
                                style: { padding: '0px', fontSize: '13px', border: 'none' },
                              }}
                              sx={{ border: 'none', fontSize: '13px', marginLeft: '8px' }}
                            />
                          ) : (
                            <Tooltip title={link.url} disableInteractive>
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  width: '100%',
                                  fontSize: '13px',
                                  marginLeft: '8px',
                                  textDecoration: 'underline',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {link.title || link.url}
                              </a>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className="link-icon"
                          style={{
                            backgroundColor: COLORS.white,
                            border: `1px solid ${COLORS.gray200}`,
                            borderRadius: '8px',
                            padding: '2px',
                            position: 'absolute',
                            right: '8px',
                          }}
                        >
                          <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                            <Icons.Duplicate stroke={COLORS.gray400} />
                          </IconButton>
                          <IconButton
                            sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}
                            onClick={() => handleClickLinkUpdate(link, idx)}
                          >
                            <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
                          </IconButton>
                        </div>
                      </LinkContentWrapper>
                    ))}
                    <CreateLinkButton onClick={handleCreateLink}>
                      <Icons.AddLink />
                      <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>
                        {language === 'ko' ? '링크 추가하기' : 'Add link'}
                        {/* Add link */}
                      </div>
                    </CreateLinkButton>
                  </LinkWrapper>
                  <Divider sx={{ width: '100%', margin: '16px 0px' }} />
                </MemoLinkWrapper>
                <TaskListWrapper>
                  <TaskListTitle>
                    {language === 'ko' ? '반복 하위 업무' : 'Routine Subtasks'}
                    {/* Routine Subtasks */}
                  </TaskListTitle>
                  <TaskListBorderWrapper border={updateSubtasks}>
                    <TaskBlockInputList
                      draggable={false}
                      isRoutine
                      suppressTemplate
                      suppressCheckboxToggle
                      suppressMoveToAfter
                      suppressCategory
                      suppressDeadline
                      suppressTaskMore
                      suppressAddTime
                      tasks={routine?.tasks as TaskBlock[]}
                      onChange={handleChangeSubtask}
                    />
                    {updateSubtasks && (
                      <TaskListButtonWrapper>
                        <Button
                          variant="contained"
                          onClick={handleRollbackRoutineSubtasks}
                          sx={{
                            'minWidth': '0px',
                            'backgroundColor': COLORS.white,
                            'border': `1px solid ${COLORS.gray200}`,
                            'borderRadius': '6px',
                            'boxShadow': 'none',
                            'color': COLORS.gray600,
                            'fontSize': '12px',
                            'fontWeight': 'bold',
                            'padding': '5px 8px',
                            'textTransform': 'none',
                            ':hover': {
                              backgroundColor: COLORS.gray100,
                            },
                          }}
                        >
                          {language === 'ko' ? '취소하기' : 'Cancel'}
                          {/* Cancel */}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleUpdateRoutineSubtasks}
                          sx={{
                            borderRadius: '6px',
                            boxShadow: 'none',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            padding: '5px 8px',
                            marginLeft: '8px',
                            textTransform: 'none',
                          }}
                        >
                          {language === 'ko' ? '저장하기' : 'Save'}
                          {/* Apply */}
                        </Button>
                      </TaskListButtonWrapper>
                    )}
                  </TaskListBorderWrapper>
                  {updateSubtasks && (
                    <ScheduleReflectionNoticeWrapper>
                      <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray600} style={{ marginRight: 4 }} />
                      <div>
                        {language === 'ko'
                          ? `변경사항은 ${dayjs(taskViewDate).format('M월 D일 ddd요일')}의 인스턴스부터 반영돼요`
                          : `Changes take effect from the ${dayjs(taskViewDate).format('ddd, MMM D')} instance.`}
                      </div>
                    </ScheduleReflectionNoticeWrapper>
                  )}
                </TaskListWrapper>
              </div>
            )}
            {/* {projectFilter === 'SUBTASK' &&
          unallocateSubtasksBeforeToday.length > 0 &&
          !dayjs(instance?.start?.date || instance?.start?.datetime).isBefore(dayjs(), 'date') && (
            <NoticeUndoneSubtaskWrapper>
              <NoticeUndoneSubtaskHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Alert width={16} height={16} fill={COLORS.negative1} />
                  <span
                    style={{ marginLeft: 4, fontWeight: 700, fontSize: 13 }}
                  >{`오늘 이전 일자에 ${unallocateSubtasksBeforeToday.length}개의 미완료 하위 업무가 있어요.`}</span>
                </div>
                <IconButton onClick={() => setIsFoldUndoneSubtask(!isFoldUndoneSubtask)} sx={{ padding: '4px' }}>
                  {isFoldUndoneSubtask ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                </IconButton>
              </NoticeUndoneSubtaskHeader>
              <Divider sx={{ margin: '8px 0px' }} />
              {!isFoldUndoneSubtask &&
                unallocateSubtasksBeforeToday.map((task) => (
                  <UnallocateTaskWrapper
                    key={task!.id}
                    draggable
                    multiSelect={multiSelect.map((v) => v.id).includes(task!.id)}
                    onDragStart={(e) => {
                      handleDragStart(e, task! as OutTaskboxDetailProjectOnly);
                    }}
                    onDragEnd={handleDragEnd}
                    onClick={(e) => {
                      if (e.ctrlKey || e.metaKey) {
                        setMultiSelectAnchorEl(e.currentTarget);
                        handleMultiSelectIds(task! as OutTaskboxDetailProjectOnly);
                      }
                    }}
                    style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <TaskListHeader style={{ marginBottom: 8 }}>
                      <span>
                        {task!.start?.datetime
                          ? dayjs(task!.start?.datetime, { utc: true }).format('M월 D일 dddd A h:mm')
                          : dayjs(task!.start?.date, { utc: true }).format('M월 D일 dddd')}
                      </span>
                      <Tooltip title="일시 지정 해제하기">
                        <IconButton
                          className="close-btn"
                          onClick={() => handleMoveToUnallocate(task!)}
                          sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px' }}
                        >
                          <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                        </IconButton>
                      </Tooltip>
                    </TaskListHeader>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icons.TaskCheckbox fill={'#D18DF2'} />
                      <div style={{ marginLeft: 8 }}>{task!.content}</div>
                    </div>
                  </UnallocateTaskWrapper>
                ))}
            </NoticeUndoneSubtaskWrapper>
          )} */}
          </ContentWrapper>
          <ProjectDragImage id="drag-image">
            <ProjectDragImageCount>
              {multiSelect.length || (multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].subtaskIds.length) || 1}
            </ProjectDragImageCount>
            <span style={{ fontSize: 13, marginLeft: 8 }}>
              {language === 'ko' ? '개 선택됨' : 'selected'}
              {/* selected */}
            </span>
          </ProjectDragImage>
          {routineStatusPopover && (
            <Popover
              className="routine-status"
              open={Boolean(routineStatusPopover)}
              anchorEl={routineStatusPopover}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ marginTop: 0.5 }}
              onClose={() => setRoutineStatusPopover(null)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RoutineStatusWrapper>
                <RoutineStatusDetail
                  onClick={() => {
                    handleClickRoutineCheck(routine!.id, 'HOLD');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                    <Icons.DeskIssueHold />
                  </IconButton>
                  {language === 'ko' ? '홀드' : 'Hold'}
                </RoutineStatusDetail>
                <RoutineStatusDetail
                  onClick={() => {
                    handleClickRoutineCheck(routine!.id, 'ARCHIVED');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                    <Icons.DeskIssueCancel />
                  </IconButton>
                  {language === 'ko' ? '아카이브' : 'Archive'}
                </RoutineStatusDetail>
              </RoutineStatusWrapper>
            </Popover>
          )}
          {linkAnchorEl && (
            <Popover
              open={Boolean(linkAnchorEl)}
              anchorEl={linkAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handleSaveLink}
              sx={{ marginLeft: '30px' }}
            >
              <LinkPopoverContainer className="routine-link">
                <LinkPopoverWrapper>
                  <LinkURLWrapper>
                    <div>
                      {language === 'ko' ? '링크 URL' : 'Link URL'}
                      {/* Link URL */}
                    </div>
                    <TextField
                      autoFocus
                      fullWidth
                      value={selectedLink?.url}
                      placeholder={
                        language === 'ko' ? '링크 URL 주소(https://…)를 입력해주세요.' : 'Please enter the link URL (https://...).'
                        // 'Please enter the link URL (https://...).'
                      }
                      onChange={(e) => {
                        if (!selectedLink) return;
                        setSelectedLink({ ...selectedLink, url: e.target.value });
                      }}
                      sx={{ marginTop: '4px' }}
                      inputProps={{
                        style: { padding: '8px 12px', fontSize: '12px' },
                      }}
                    />
                  </LinkURLWrapper>
                  <LinkTitleWrapper>
                    <div>
                      {language === 'ko' ? '링크 제목' : 'Link title'}
                      {/* Link title */}
                    </div>
                    <TextField
                      fullWidth
                      value={selectedLink?.title}
                      placeholder={
                        language === 'ko' ? '링크 제목을 입력해주세요.' : 'Please enter the link title.'
                        // 'Please enter the link title.'
                      }
                      onChange={(e) => {
                        if (!selectedLink) return;
                        setSelectedLink({ ...selectedLink, title: e.target.value });
                      }}
                      sx={{ marginTop: '4px' }}
                      inputProps={{
                        style: { padding: '8px 12px', fontSize: '12px' },
                      }}
                    />
                  </LinkTitleWrapper>
                </LinkPopoverWrapper>
                <Divider />
                <LinkDeleteWrapper>
                  <Button
                    onClick={handleDeleteLink}
                    sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
                  >
                    <Icons.Delete fill={COLORS.negative1} />
                    <span style={{ marginLeft: '8px' }}>
                      {language === 'ko' ? '링크 삭제' : 'Delete link'}
                      {/* Delete link */}
                    </span>
                  </Button>
                </LinkDeleteWrapper>
              </LinkPopoverContainer>
            </Popover>
          )}
          {categoryAnchorEl && (
            <Popover
              open={Boolean(categoryAnchorEl)}
              anchorEl={categoryAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setCategoryAnchorEl(null);
              }}
              sx={{ marginTop: '4px' }}
            >
              <div className="routine-category">
                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
              </div>
            </Popover>
          )}
          {timeAnchorEl && (
            <Popover
              className="routin-time-select"
              open={Boolean(timeAnchorEl)}
              anchorEl={timeAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={() => setTimeAnchorEl(null)}
              sx={{ marginTop: '2px', left: '-15px' }}
            >
              <SimpleTimeSelectionModal
                event={{
                  type: 'ROUTINE_TASK',
                  start: routine?.lastMasterStart.date ? { date: routine?.lastMasterStart.date } : { datetime: routine?.lastMasterStart.datetime },
                  end: routine?.lastMasterEnd.date ? { date: routine?.lastMasterEnd.date } : { datetime: routine?.lastMasterEnd.datetime },
                }}
                onClose={() => setTimeAnchorEl(null)}
                onChangeTime={handleChangeTime}
              />
            </Popover>
          )}
          {inprogressPopover && (
            <Popover
              open={Boolean(inprogressPopover)}
              anchorEl={inprogressPopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => setInprogressPopover(null)}
              sx={{ marginTop: '4px' }}
            >
              <InprogressProject className="routine-inprogress">
                <InprogressProjectContent
                  onClick={() => {
                    if (!selectedInprogressInstance) return;
                    // handleClickInstanceDone(selectedInprogressInstance);
                  }}
                >
                  <Icons.TaskboxCheck width={16} height={16} fill={COLORS.issue2} />
                  <span style={{ marginLeft: '8px' }}>
                    {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                    {/* Complete all */}
                  </span>
                </InprogressProjectContent>
                <Tooltip
                  title={
                    <span>
                      {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                      <br />
                      {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                    </span>
                    // <span>
                    //   Complete the instance and send any incomplete
                    //   <br />
                    //   subtasks to the next instance or project.
                    // </span>
                  }
                  placement="right"
                >
                  <InprogressProjectContent onClick={() => {}}>
                    <Icons.ContinueTaskbox fill={COLORS.issue2} />
                    <span style={{ marginLeft: '8px' }}>
                      {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                      {/* Complete some */}
                    </span>
                    <span style={{ marginLeft: '4px' }}>
                      <Icons.QuestionInfo />
                    </span>
                  </InprogressProjectContent>
                </Tooltip>
              </InprogressProject>
            </Popover>
          )}
          {menuAnchorEl && (
            <Popover
              open={Boolean(menuAnchorEl)}
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setMenuAnchorEl(null)}
              sx={{ marginTop: '4px' }}
            >
              <MenuList sx={{ padding: '8px' }}>
                <MenuItem
                  onClick={() => {
                    setMenuAnchorEl(null);
                    handleOpenDeleteDialog();
                  }}
                  sx={{ display: 'flex', alignItems: 'center', borderRadius: '8px', color: COLORS.negative1, fontSize: '12px', padding: '8px' }}
                >
                  <Icons.Delete fill={COLORS.negative1} />
                  <div style={{ marginLeft: 8 }}>{language === 'ko' ? '루틴 삭제' : 'Delete routine'}</div>
                </MenuItem>
              </MenuList>
            </Popover>
          )}
          {multiSelect.length > 0 && (
            <Popper
              open={Boolean(multiSelectAnchorEl)}
              anchorEl={multiSelectAnchorEl}
              placement={'left-start'}
              ref={refPopperMenu}
              sx={{ zIndex: 1201, right: '4px' }}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ]}
            >
              <SubtaskMultiSelectPopoverWrapper className="routine-detail-menu">
                <div style={{ padding: '16px 16px 0px 16px', fontSize: 12, fontWeight: 'bold' }}>{multiSelect.length}개의 하위 업무가 선택되었습니다.</div>
                <InboxContextMenu
                  id=""
                  type="routine"
                  menus={instance?.id ? ['INSTANCE', 'DELETE'] : ['MOVE_TO_TODAY', 'DELETE']}
                  data={multiSelect}
                  onClickMenu={handleClickInboxContextMenu}
                />
              </SubtaskMultiSelectPopoverWrapper>
            </Popper>
          )}
          <Dialog
            open={
              language === 'ko' ? recurrenceUnit === '사용자 설정' : recurrenceUnit === 'Custom'
              // recurrenceUnit === 'Custom'
            }
            onClose={handleCloseCustomReccurence}
          >
            <RecurrenceCustomDialog
              date={dayjs().toDate()}
              defaultCustomRecurrence={customRecurrence}
              onClose={handleCloseCustomReccurence}
              onChange={handleClickCustomRecurrence}
            />
          </Dialog>
          <ConfirmDialog
            className="routine-delete"
            ref={refConfirm}
            data={routine}
            type={'routine'}
            title={
              language === 'ko' ? '루틴 삭제' : 'Delete routine'
              // 'Delete routine'
            }
            onConfirm={handleDelete}
          >
            <div style={{ color: COLORS.negative1, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
              {language === 'ko' ? `루틴을 삭제하시겠어요?` : `Delete routine?`}
              {/* Delete routine? */}
            </div>
            <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>
              {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
              {/* All related data will be deleted. */}
            </div>
          </ConfirmDialog>
        </RoutineWrapper>
      </Container>
    </Drawer>
  );
};

export default ProjectDetailView;
