import styled from '@emotion/styled';
import { CircularProgress, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';

interface SyncCalendarDialogProps {
  open: boolean;
}

const SyncCalendarDialog = ({ open }: SyncCalendarDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <SyncingWrapper>
        <SyncingText>{t('calendar.connect.sync')}</SyncingText>
        <CircularProgress />
      </SyncingWrapper>
    </Dialog>
  );
};

export default SyncCalendarDialog;

const SyncingWrapper = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  border-radius: 8px;
`;

const SyncingText = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 100px;
`;
