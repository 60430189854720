import styled from '@emotion/styled';
import { Button, IconButton, Modal, TextField } from '@mui/material';
import { Icons } from 'components';
import { useState } from 'react';
import { COLORS } from 'styles/constants';
import { css } from '@emotion/react';
import { PriorityIssueCheck, PriorityIssueUncheck } from 'components/Icons';
import { RecordUserSurveyV1SurveysSurveyIdPostBody } from 'queries/model';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiInputBase-input {
    font-size: 13px;
  }
  .MuiInputBase-root {
    padding: 14px 16px;
  }
`;

const SurveyWrapper = styled.div`
  width: 480px;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border-radius: 16px;
  padding: 24px;
`;

const Section = styled.div`
  width: 100%;
`;

const SurveyHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SurveyHeaderTitle = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: bold;
`;

const SurveyBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SurveyBodyTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
const SurveyBodySubTitle = styled.div`
  font-size: 13px;
  margin-top: 8px;
`;

const SurveyFooter = styled.div``;

interface ComebackSurveyProps {
  open?: boolean;
  onClose?: () => void;
  onClickSubmit?: (survey?: RecordUserSurveyV1SurveysSurveyIdPostBody) => void;
}

const ComebackSurvey = ({ open = false, onClose, onClickSubmit }: ComebackSurveyProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const handleClickNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handelClickPrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleClickSubmit = () => {
    const survey = [{ exit: userExit }, { comeback: userComeback }];
    onClickSubmit && onClickSubmit(survey);
    handleClickNext();
  };

  const [userExit, setUserExit] = useState('');
  const handleChangeUserExit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserExit(e.target.value);
  };

  const [userComeback, setUserComeback] = useState('');
  const handleChangeUserImprovement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserComeback(e.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Container>
        {activeStep === 0 && (
          <SurveyWrapper>
            <SurveyHeader>
              <div style={{ width: '20px', height: '20px' }} />
              <SurveyHeaderTitle>💖&nbsp;&nbsp;&nbsp;{t('comeback.intro.header')}</SurveyHeaderTitle>
              <div style={{ width: '20px', height: '20px' }} />
            </SurveyHeader>
            <SurveyBody>
              <SurveyBodyTitle>{t('comeback.intro.title')}</SurveyBodyTitle>
              <SurveyBodySubTitle>{t('comeback.intro.description')}</SurveyBodySubTitle>
            </SurveyBody>
            <SurveyFooter>
              <Button
                variant="contained"
                onClick={handleClickNext}
                sx={{ width: '192px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                🙌&nbsp;&nbsp;&nbsp;{t('comeback.intro.good')}
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 1 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('comeback.common.question')} ${activeStep}/2`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>{t('comeback.step1.question')}</SurveyBodyTitle>
                <TextField
                  multiline
                  minRows={5.2}
                  maxRows={5.2}
                  value={userExit}
                  placeholder={t('comeback.step1.placeholder')}
                  onChange={handleChangeUserExit}
                  sx={{ width: '100%' }}
                />
              </SurveyBody>
            </Section>
            <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handelClickPrev}
                sx={{
                  'width': '204px',
                  'height': '48px',
                  'borderRadius': '8px',
                  'padding': '14px 16px',
                  'fontWeight': 'bold',
                  'boxShadow': 'none',
                  'color': COLORS.gray900,
                  'backgroundColor': COLORS.gray100,
                  '&:hover': { backgroundColor: COLORS.gray200 },
                }}
              >
                {t('comeback.common.complete')}
              </Button>
              <Button
                disabled={!userExit}
                variant="contained"
                onClick={handleClickNext}
                sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                {t('comeback.common.next')}
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 2 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`${t('comeback.common.question')} ${activeStep}/2`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}> {t('comeback.step2.question')}</SurveyBodyTitle>
                <TextField
                  multiline
                  minRows={5.2}
                  maxRows={5.2}
                  value={userComeback}
                  placeholder={t('comeback.step2.placeholder')}
                  onChange={handleChangeUserImprovement}
                  sx={{ width: '100%' }}
                />
              </SurveyBody>
            </Section>
            <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handelClickPrev}
                sx={{
                  'width': '204px',
                  'height': '48px',
                  'borderRadius': '8px',
                  'padding': '14px 16px',
                  'fontWeight': 'bold',
                  'boxShadow': 'none',
                  'color': COLORS.gray900,
                  'backgroundColor': COLORS.gray100,
                  '&:hover': { backgroundColor: COLORS.gray200 },
                }}
              >
                {t('comeback.common.prev')}
              </Button>
              <Button
                disabled={!userComeback}
                variant="contained"
                onClick={handleClickSubmit}
                sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                {t('comeback.common.complete')}
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 3 && (
          <SurveyWrapper style={{ justifyContent: 'center' }}>
            <SurveyBody>
              <div
                style={{
                  width: '60px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '32px',
                  backgroundColor: COLORS.sub3,
                  borderRadius: '50%',
                }}
              >
                ☺️
              </div>
              <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>{t('comeback.appreciation.title')}</SurveyBodyTitle>
              <div>{t('comeback.appreciation.description')}🔥</div>
            </SurveyBody>
          </SurveyWrapper>
        )}
      </Container>
    </Modal>
  );
};

export default ComebackSurvey;
