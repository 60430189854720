import { SVGProps } from 'react';

export const Later = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1672 1.66406C16.6274 1.66406 17.0005 2.03716 17.0005 2.4974V6.66406C17.0005 7.1243 16.6274 7.4974 16.1672 7.4974H12.0005C11.5403 7.4974 11.1672 7.1243 11.1672 6.66406C11.1672 6.20382 11.5403 5.83073 12.0005 5.83073H14.4165C13.7803 5.20733 13.0061 4.73457 12.151 4.45423C10.9656 4.06564 9.68662 4.06749 8.50208 4.45924C7.31757 4.85098 6.28989 5.61196 5.56992 6.63069C4.84998 7.64938 4.4758 8.87212 4.50193 10.1195C4.52806 11.3668 4.95329 12.5729 5.7153 13.5606C6.47733 14.5484 7.53572 15.2656 8.73554 15.6074C9.93537 15.9491 11.2133 15.8973 12.3814 15.4594C13.5495 15.0215 14.5465 14.2206 15.226 13.1743C15.4767 12.7883 15.9928 12.6786 16.3787 12.9293C16.7647 13.1799 16.8744 13.6961 16.6238 14.082C15.7502 15.4272 14.4683 16.457 12.9664 17.02C11.4645 17.583 9.82154 17.6497 8.27894 17.2103C6.73635 16.7708 5.37548 15.8487 4.3957 14.5787C3.4159 13.3087 2.86922 11.758 2.83563 10.1544C2.80204 8.55091 3.28304 6.97876 4.20885 5.66878C5.13462 4.35885 6.45597 3.38048 7.97876 2.87686C9.50152 2.37326 11.1459 2.37081 12.6701 2.87049C13.6603 3.19509 14.566 3.72034 15.3338 4.40704V2.4974C15.3338 2.03716 15.7069 1.66406 16.1672 1.66406Z"
        fill={props.fill || '#656871'}
      />
    </svg>
  );
};
