import { SVGProps } from 'react';

export const Uncheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M1.36719 8.03678L4.66702 11.3366L11.738 4.26562" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="12" cy="11" r="3.625" fill="#1F2023" stroke="white" strokeWidth="0.75" />
      <path d="M14 11L10 11" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};
