import { SVGProps } from 'react';

export const ListAdd = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.5 25.5H15M22.5 24H27M27 24H31.5M27 24V28.5M27 24V19.5M4.5 18H21M4.5 10.5H21"
        stroke="#C8CDDB"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
