import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';
import Fonts from 'components/Fonts';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';

export const SkeletonMeetingNote = () => {
  return (
    <Container>
      {/* 회의 목표 */}
      <div className="px-6">
        <div className="py-6 ">
          <Fonts.H2 className="pb-2">회의 목표</Fonts.H2>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>
        {/* 아젠다 */}
        <div className="py-6">
          <Fonts.H2 className="pb-2">아젠다</Fonts.H2>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>
      </div>
      {/* 공지사항 */}
      <div className="px-6">
        <div className="py-6">
          <div className="flex pb-2 gap-4 items-center">
            <Icons.Speaker />
            <Fonts.H2 className="">공지사항</Fonts.H2>
          </div>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>

        {/* 논의사항 */}
        <div className="py-6">
          <Fonts.H2 className="pb-8">논의사항</Fonts.H2>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>

        {/* 업무 */}
        <div className="py-6">
          <Fonts.H2 className="pb-8">업무</Fonts.H2>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>

        {/* 주요 내용 */}
        <div className="py-6">
          <Fonts.H2 className="pb-8">주요 내용</Fonts.H2>
          <Skeleton style={{ width: 166, height: 12, borderRadius: '4px' }} animated />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`;
