import '../Styles/DragHandle.css';
import '../Styles/MenuHandle.css';
import { isElementDomNode } from 'remirror';
import { NodeSelection, Plugin } from '@remirror/pm/state';
// import { __serializeForClipboard } from '@remirror/pm/extension/clipboard';
import { EditorView } from '@remirror/pm/view';
import { nodeAtCoords, nodePosAtDOM } from 'components/Remirror/utils';

/*
 * Tiptap GlobalDragHandle extension :
 * https://github.com/ueberdosis/tiptap/blob/main/demos/src/Experiments/GlobalDragHandle/Vue/DragHandle.js
 */

export function MemoHandle(
  options = {
    isDraggableBlock: (node: Node) => false,
    dragHandleWidth: 20,
  },
) {
  let dragHandleElement: HTMLDivElement | null = null;
  let menuHandleElement: HTMLDivElement | null = null;

  const handleDragStart = (event: DragEvent, view: EditorView) => {
    // view.composing = true;
    if (!event.dataTransfer) {
      return;
    }

    const coords = { x: event.clientX + 50, y: event.clientY };
    const node = nodeAtCoords(coords, options.isDraggableBlock, 'drag');
    const pos = nodePosAtDOM(node, view);

    if (pos !== null) {
      view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos)));

      const slice = view.state.selection.content();

      // const { dom, text } = __serializeForClipboard(view, slice);

      event.dataTransfer.clearData();
      // event.dataTransfer.setData('text/html', view.dom.innerHTML);
      // event.dataTransfer.setData('text/plain', slice.content.content[0].text);

      const draggedElement = view.nodeDOM(pos) as Element;
      event.dataTransfer.setDragImage(draggedElement, 0, 0);

      view.dom.classList.add('dragging');
      view.dragging = { slice, move: true };
    }
  };

  return new Plugin({
    props: {
      handleDOMEvents: {
        mousemove: (view, event) => {
          if (!view.editable) {
            return;
          }

          const coords = {
            x: event.clientX + 50 + options.dragHandleWidth,
            y: event.clientY,
          };
          const node = nodeAtCoords(coords, options.isDraggableBlock, 'drag');

          if (!node || !isElementDomNode(node)) {
            if (dragHandleElement) {
              dragHandleElement.style.opacity = '0';
            }
            if (menuHandleElement) {
              menuHandleElement.style.opacity = '0';
            }
            return;
          }

          const compStyle = window.getComputedStyle(node);
          const lineHeight = parseInt(compStyle.lineHeight, 10);
          const boundingRect = node.getBoundingClientRect();
          let nestedNodeLeftOffset = 0;
          const parentNode = node.parentNode as Element;
          if (parentNode && !parentNode.classList.contains('ProseMirror')) {
            const parentCompStyle = window.getComputedStyle(parentNode);
            nestedNodeLeftOffset += parseFloat(parentCompStyle.paddingLeft) || 0;
          }
          const nodePaddingTop = parseInt(compStyle.paddingTop, 10);
          const taskItemMemo = document.querySelector('.taskitem-container');
          const focusModeMemo = document.querySelector('.focus-mode-dialog');
          const topPos = boundingRect.top + (lineHeight - 24) / 2 + nodePaddingTop;
          const leftPos = boundingRect.left - options.dragHandleWidth - nestedNodeLeftOffset;

          if (dragHandleElement) {
            dragHandleElement.style.top = taskItemMemo ? `${topPos - taskItemMemo.getBoundingClientRect().top}px` : `${topPos - 40}px`;
            dragHandleElement.style.left = focusModeMemo ? `${leftPos}px` : '24px';
            dragHandleElement.style.opacity = '1';
          }

          if (menuHandleElement) {
            menuHandleElement.style.top = taskItemMemo ? `${topPos - taskItemMemo.getBoundingClientRect().top}px` : `${topPos - 40}px`;
            menuHandleElement.style.left = focusModeMemo ? `${leftPos - 20}px` : '4px';
            menuHandleElement.style.opacity = '1';
          }
        },
        keydown: () => {
          if (dragHandleElement) dragHandleElement.style.opacity = '0';
          if (menuHandleElement) menuHandleElement.style.opacity = '0';
        },
      },
    },
    view: (editorView) => {
      dragHandleElement = document.createElement('div');
      dragHandleElement.draggable = true;
      dragHandleElement.classList.add('memo-drag-handle');
      dragHandleElement.style.opacity = '0';
      dragHandleElement.addEventListener('dragstart', (e) => {
        handleDragStart(e, editorView);
      });
      dragHandleElement.addEventListener('dragend', () => {
        editorView.dom.classList.remove('dragging');
      });

      menuHandleElement = document.createElement('div');
      menuHandleElement.classList.add('memo-menu-handle');
      menuHandleElement.style.opacity = '0';

      const appendElementsToMemoContainer = () => {
        const memoContainer = document.querySelector('.project-memo')
          ? document.querySelector('.project-memo')
          : document.querySelector('.taskbox-detail-memo')
          ? document.querySelector('.taskbox-detail-memo')
          : document.querySelector('.focus-mode-memo') || document.querySelector('.task-item-memo') || document.querySelector('.memo-container');

        if (memoContainer) {
          if (dragHandleElement) memoContainer.appendChild(dragHandleElement);
          if (menuHandleElement) memoContainer.appendChild(menuHandleElement);
        }
      };

      setTimeout(appendElementsToMemoContainer, 100);

      return {
        destroy: () => {
          dragHandleElement && dragHandleElement.remove();
          dragHandleElement = null;
          menuHandleElement && menuHandleElement.remove();
          menuHandleElement = null;
        },
      };
    },
  });
}
