import { Popper, PopperProps } from '@mui/material';
import React, { CSSProperties, FC, RefCallback, useCallback, useMemo, useState } from 'react';
import type { PositionerParam } from '@remirror/extension-positioner';
import { getPositioner } from '@remirror/extension-positioner';
import { usePositioner } from '@remirror/react-hooks';
import MenuButtons from './MenuButtons';
import styled from '@emotion/styled';

const DEFAULT_MODIFIERS = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
];

export interface FloatingToolbarProps extends Omit<PopperProps, 'open' | 'anchorEl'> {
  open?: boolean;
  positioner?: PositionerParam;
  onClose?: () => void;
}

export type MemoActionType = 'CLICK' | 'PRESS';

const MenubarContainer = styled.div``;

const Menubar: FC<FloatingToolbarProps> = ({ open = false, positioner = 'always', onClose, children, ...rest }) => {
  const { ref, x, y, width, height, active } = usePositioner(() => getPositioner(positioner), [positioner]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const inlineStyle: CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      pointerEvents: 'none',
      left: x,
      top: y,
      width,
      height,
    }),
    [x, y, width, height],
  );

  const combinedSelectionRefs: RefCallback<HTMLDivElement> = useCallback(
    (elem) => {
      setAnchorEl(elem);
      ref?.(elem);
    },
    [ref],
  );

  const handleChangeUserAction: (actionType: MemoActionType) => Element | null = (actionType: string) => {
    // 커서가 있는 dom 가져오기 ==> 조금 더 좋은 방법이 있을 듯.
    const elements = document.elementsFromPoint(
      document.body.getBoundingClientRect().width - document.querySelector('.side-panel-resizable')!.getBoundingClientRect().width + x,
      y,
    );

    let element: Element | null = null;

    for (const elem of elements) {
      if (elem && elem.parentNode instanceof HTMLElement) {
        if (elem.parentNode.classList.contains('ProseMirror') || elem.parentNode.classList.contains('column')) {
          const targetElement = actionType === 'CLICK' ? elem : elem.previousElementSibling;
          element = targetElement ? targetElement : elem;
          break;
        }
      }
    }

    return element;
  };

  return (
    <MenubarContainer>
      <div ref={combinedSelectionRefs} style={inlineStyle} />
      <Popper autoFocus placement="right-start" modifiers={DEFAULT_MODIFIERS} {...rest} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }}>
        <MenuButtons onClose={onClose} onChangeUserAction={handleChangeUserAction} />
      </Popper>
    </MenubarContainer>
  );
};

export default Menubar;
