import { CategoryColorTheme } from 'queries/model';

export const categoryColors: {
  color: CategoryColorTheme;
  textColor: string;
  bgColor: string;
  statColor: string;
}[] = [
  { color: 'RED', textColor: '#B42E04', bgColor: '#F6D9C9', statColor: '#FA8C51' },
  { color: 'YELLOW', textColor: '#A07014', bgColor: '#F6EEBD', statColor: '#FFDA83' },
  { color: 'GREEN', textColor: '#1DAC10', bgColor: '#DEF5D5', statColor: '#69C493' },
  { color: 'DEEP_GREEN', textColor: '#139999', bgColor: '#D4F6EC', statColor: '#85E0E3' },
  { color: 'BLUE', textColor: '#2463AE', bgColor: '#DFF0FA', statColor: '#7CC6EE' },
  { color: 'DEEP_PURPLE', textColor: '#5328CF', bgColor: '#DADDF9', statColor: '#7179DE' },
  { color: 'PURPLE', textColor: '#972DCB', bgColor: '#EDDBFF', statColor: '#CC7CFE' },
  { color: 'PINK', textColor: '#CB40CE', bgColor: '#FCDDF8', statColor: '#FE96E6' },
];
