import { SVGProps } from 'react';

export const Wand = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M13.75 8V11" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 9.5H15.25" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 2.25V4.75" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.75 3.5H6.25" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.25 11.75V13.75" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.25 12.75H11.25" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.6515 2.35241L2.35746 11.6464C2.1622 11.8417 2.1622 12.1583 2.35746 12.3536L3.65014 13.6462C3.8454 13.8415 4.16198 13.8415 4.35725 13.6462L13.6513 4.3522C13.8465 4.15693 13.8465 3.84035 13.6513 3.64509L12.3586 2.35241C12.1633 2.15715 11.8468 2.15715 11.6515 2.35241Z"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 5L11 7" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
