import { SVGProps } from 'react';

export const Mobile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.66602 4.13346V11.8668C4.66602 12.6135 4.66602 12.9867 4.81134 13.2719C4.93917 13.5228 5.143 13.727 5.39388 13.8548C5.67882 14 6.05201 14 6.79729 14H9.20141C9.94669 14 10.3193 14 10.6043 13.8548C10.8552 13.727 11.0597 13.5228 11.1875 13.2719C11.3327 12.987 11.3327 12.6143 11.3327 11.8691V4.13127C11.3327 3.386 11.3327 3.0128 11.1875 2.72786C11.0597 2.47698 10.8552 2.27316 10.6043 2.14532C10.3191 2 9.94622 2 9.19948 2H6.79948C6.05274 2 5.6791 2 5.39388 2.14532C5.143 2.27316 4.93917 2.47698 4.81134 2.72786C4.66602 3.01308 4.66602 3.38673 4.66602 4.13346Z"
        stroke="#1F2023"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.66536 12H7.33203" stroke="#1F2023" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 4H8.00133" stroke="#1F2023" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
