import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { Calendar } from '../Icons';

interface MenuProps {
  label?: string;
  focus: boolean;
  onClick: () => void;
}

export const MeetingMenu = ({ focus = false, onClick, label }: MenuProps) => {
  if (focus) {
    return (
      <svg width="80px" height="80px" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H80V80H8C3.58172 80 0 76.4183 0 72V8Z" fill="#F2F5FC" />
        <path
          d="M8 0C3.58172 0 0 3.58172 0 8V72C0 76.4183 3.58172 80 8 80H80C80 75.5817 76.4183 72 72 72H16C11.5817 72 8 68.4183 8 64V16C8 11.5817 11.5817 8 16 8H72C76.4183 8 80 4.41828 80 0H8Z"
          fill="white"
        />
        <g clipPath="url(#clip0_1858_1513)">
          <path
            d="M39.7267 41.0001H30.6667C29.9594 41.0001 29.2811 40.7191 28.781 40.219C28.281 39.7189 28 39.0407 28 38.3334V22.3334C28 21.6262 28.281 20.9479 28.781 20.4478C29.2811 19.9477 29.9594 19.6667 30.6667 19.6667H46.6667C47.3739 19.6667 48.0522 19.9477 48.5523 20.4478C49.0524 20.9479 49.3333 21.6262 49.3333 22.3334V27.6667"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.9998 42.3334C50.9454 42.3334 53.3332 39.9456 53.3332 37.0001C53.3332 34.0546 50.9454 31.6667 47.9998 31.6667C45.0543 31.6667 42.6665 34.0546 42.6665 37.0001C42.6665 39.9456 45.0543 42.3334 47.9998 42.3334Z"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M44 17V22.3333" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M33.3335 17V22.3333" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M28 27.6667H49.3333" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M48 34.9946V37L49.3333 38.3333" stroke="#0039A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <path
          d="M38.7812 52.4141H37.293V63.0781H38.7812V52.4141ZM29.8633 60.0664L30.0625 61.2852C31.9609 61.291 34.4746 61.2676 36.8125 60.8633L36.707 59.7617C35.8516 59.873 34.9551 59.9434 34.0586 59.9844V59.1348C35.2305 58.918 35.9922 58.1914 35.9922 57.1953C35.9922 56.0059 34.8906 55.1914 33.3086 55.1914C31.7383 55.1914 30.6543 56.0059 30.6602 57.1953C30.6543 58.1973 31.416 58.9238 32.582 59.1348V60.0371C31.6094 60.0605 30.6777 60.0605 29.8633 60.0664ZM30.0742 54.7695H36.5547V53.5977H34.0703V52.4609H32.582V53.5977H30.0742V54.7695ZM32.0781 57.1953C32.0723 56.6445 32.582 56.334 33.3086 56.3281C34.0586 56.334 34.5625 56.6445 34.5625 57.1953C34.5625 57.7578 34.0586 58.0625 33.3086 58.0625C32.582 58.0625 32.0723 57.7578 32.0781 57.1953ZM43.3945 53.1289C41.7129 53.123 40.4238 54.2129 40.4297 55.7422C40.4238 57.2773 41.7129 58.3613 43.3945 58.3672C45.0879 58.3613 46.3418 57.2773 46.3477 55.7422C46.3418 54.2129 45.0879 53.123 43.3945 53.1289ZM39.9844 59.7031L40.1602 60.9219C42.0234 60.9219 44.5547 60.9043 46.9219 60.4414L46.8047 59.3633C44.5254 59.6855 41.8887 59.709 39.9844 59.7031ZM41.918 55.7422C41.9121 54.9102 42.5508 54.418 43.3945 54.418C44.2441 54.418 44.8887 54.9102 44.8945 55.7422C44.8887 56.5859 44.2441 57.0723 43.3945 57.0664C42.5508 57.0723 41.9121 56.5859 41.918 55.7422ZM47.4141 63.1016H48.9023V52.4141H47.4141V63.1016Z"
          fill="#0039A7"
        />
        <defs>
          <clipPath id="clip0_1858_1513">
            <rect width="32" height="32" fill="white" transform="translate(24 13)" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <Wrapper onClick={onClick}>
        <Container>
          <Calendar fill={COLORS.gray700} />
        </Container>
        <Fonts.H5>회의</Fonts.H5>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1px;
  background-color: ${COLORS.white};
  color: ${COLORS.gray700};
`;

const Container = styled.div`
  margin-bottom: 2px;
`;
