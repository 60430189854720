import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { COLORS } from 'styles/constants';

interface SuggestionConfirmDialogProps {
  onCloseConfirmDialog?: () => void;
  onCloseSuggestionDialog?: () => void;
}

const SuggestionConfirmDialog = ({ onCloseConfirmDialog, onCloseSuggestionDialog }: SuggestionConfirmDialogProps) => {
  const handleCloseConfirmDialog = () => {
    onCloseConfirmDialog && onCloseConfirmDialog();
  };

  const handleCloseSuggestionDialog = () => {
    onCloseConfirmDialog && onCloseConfirmDialog();
    onCloseSuggestionDialog && onCloseSuggestionDialog();
  };

  return (
    <Container>
      <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 4 }}>작성을 종료하시겠습니까?</div>
      <div style={{ fontSize: 13, marginBottom: 32 }}>작성 중인 제안하기 내용은 저장되지 않습니다.</div>
      <Button
        fullWidth
        variant="contained"
        onClick={handleCloseConfirmDialog}
        sx={{ height: '40px', boxShadow: 'none', borderRadius: '8px', fontSize: '13px', marginBottom: '12px' }}
      >
        계속 작성
      </Button>
      <Button
        fullWidth
        variant="contained"
        onClick={handleCloseSuggestionDialog}
        sx={{
          'height': '40px',
          'backgroundColor': COLORS.gray100,
          'boxShadow': 'none',
          'borderRadius': '8px',
          'color': COLORS.gray900,
          'fontSize': '13px',
          ':hover': {
            backgroundColor: COLORS.gray200,
          },
        }}
      >
        작성 취소
      </Button>
    </Container>
  );
};

export default SuggestionConfirmDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;
