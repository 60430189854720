import { SVGProps } from 'react';

export const ArrowUpLong = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M8 12.6693V3.33594" stroke={props.stroke || '#1F2023'} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 7L8 3L12 7" stroke={props.stroke || '#1F2023'} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
