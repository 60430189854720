import { SVGProps } from 'react';

export const LoadToInstance = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60016 13.334C3.35263 13.334 3.11523 13.2357 2.9402 13.0606C2.76516 12.8856 2.66683 12.6482 2.66683 12.4007L2.66683 3.60065C2.66683 3.35312 2.76516 3.11572 2.9402 2.94068C3.11523 2.76565 3.35263 2.66732 3.60016 2.66732L12.4002 2.66732C12.6477 2.66732 12.8851 2.76565 13.0601 2.94068C13.2352 3.11572 13.3335 3.35312 13.3335 3.60065V6.06732C13.3335 6.43551 13.632 6.73398 14.0002 6.73398C14.3684 6.73398 14.6668 6.43551 14.6668 6.06732V3.60065C14.6668 2.99949 14.428 2.42296 14.0029 1.99788C13.5779 1.57279 13.0013 1.33398 12.4002 1.33398L3.60016 1.33399C2.99901 1.33399 2.42247 1.57279 1.99739 1.99788C1.5723 2.42296 1.3335 2.99949 1.3335 3.60065L1.3335 12.4007C1.3335 13.0018 1.57231 13.5783 1.99739 14.0034C2.42247 14.4285 2.99901 14.6673 3.60016 14.6673L6.06683 14.6673C6.43502 14.6673 6.7335 14.3688 6.7335 14.0007C6.7335 13.6325 6.43502 13.334 6.06683 13.334L3.60016 13.334Z"
        fill={props.fill || '#1F2023'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 10.8561C7.93675 10.86 7.87281 10.862 7.80842 10.862C6.11961 10.862 4.74886 9.49368 4.74886 7.8037C4.74886 6.11373 6.11961 4.74544 7.80842 4.74544C9.49722 4.74544 10.868 6.11373 10.868 7.8037C10.868 7.86987 10.8659 7.93554 10.8617 8.00065H9.52351C9.53086 7.93602 9.53464 7.8703 9.53464 7.8037C9.53464 6.85105 8.76178 6.07878 7.80842 6.07878C6.85505 6.07878 6.0822 6.85105 6.0822 7.8037C6.0822 8.75635 6.85505 9.52863 7.80842 9.52863C7.87323 9.52863 7.93721 9.52506 8.00016 9.51811V10.8561ZM7.80881 8.37993C8.1266 8.37993 8.38422 8.12251 8.38422 7.80496C8.38422 7.48741 8.1266 7.22998 7.80881 7.22998C7.49102 7.22998 7.2334 7.48741 7.2334 7.80496C7.2334 8.12251 7.49102 8.37993 7.80881 8.37993Z"
        fill={props.fill || '#1F2023'}
      />
      <path
        d="M15 11.75C15 13.5449 13.5449 15 11.75 15C9.95507 15 8.5 13.5449 8.5 11.75C8.5 9.95507 9.95507 8.5 11.75 8.5C13.5449 8.5 15 9.95507 15 11.75Z"
        fill={props.fill || '#1F2023'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7832 9.91735C11.9394 10.0736 11.9394 10.3268 11.7832 10.483L10.8661 11.4002H13.9004C14.1213 11.4002 14.3004 11.5793 14.3004 11.8002C14.3004 12.0211 14.1213 12.2002 13.9004 12.2002H10.8661L11.7832 13.1174C11.9394 13.2736 11.9394 13.5268 11.7832 13.683C11.627 13.8392 11.3738 13.8392 11.2175 13.683L9.61773 12.0832C9.46152 11.927 9.46134 11.6736 9.61755 11.5174L11.2175 9.91735C11.3738 9.76114 11.627 9.76114 11.7832 9.91735Z"
        fill="white"
      />
    </svg>
  );
};
