import { atom } from 'jotai';

export const taskViewAtom = atom<string>(localStorage.getItem('task-view') || 'today');
export const foldCalendarViewAtom = atom<boolean>(Boolean(JSON.parse(localStorage.getItem('fold-calendar-view') || 'false')));
export const calendarWidthAtom = atom<number>(Number(JSON.parse(localStorage.getItem('calendar-width') || '300')));
export const calendarWidthWithSidePanelAtom = atom<number>(Number(JSON.parse(localStorage.getItem('calendar-width-with-side-panel') || '300')));
export const foldSidePanelViewAtom = atom<boolean>(Boolean(JSON.parse(localStorage.getItem('fold-side-panel-view') || 'false')));
export const sidePanelWidthAtom = atom<number>(Number(JSON.parse(localStorage.getItem('side-panel-width') || '374')));
export const sidePanelWidthWithCalendarAtom = atom<number>(Number(JSON.parse(localStorage.getItem('side-panel-width-with-calendar') || '374')));
export const foldPlanSidePanelViewAtom = atom<boolean>(Boolean(JSON.parse(localStorage.getItem('plan-fold-side-panel-view') || 'false')));
export const planSidePanelWidthAtom = atom<number>(Number(JSON.parse(localStorage.getItem('plan-side-panel-width') || '374')));
