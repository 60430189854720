import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { Home } from '../Icons';

interface MenuProps {
  label?: string;
  focus: boolean;
  onClick: () => void;
}

export const HomeMenu = ({ focus = false, onClick, label }: MenuProps) => {
  if (focus) {
    return (
      <svg width="80px" height="80px" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8C0 3.58172 3.58172 0 8 0H80V80H8C3.58172 80 0 76.4183 0 72V8Z" fill="#F2F5FC" />
        <path
          d="M8 0C3.58172 0 0 3.58172 0 8V72C0 76.4183 3.58172 80 8 80H80C80 75.5817 76.4183 72 72 72H16C11.5817 72 8 68.4183 8 64V16C8 11.5817 11.5817 8 16 8H72C76.4183 8 80 4.41828 80 0H8Z"
          fill="white"
        />
        <g clipPath="url(#clip0_1858_1514)">
          <path
            d="M50.455 24.0581L42.4555 17.8419C41.7535 17.2962 40.8894 17 40 17C39.1106 17 38.2465 17.2962 37.5445 17.8419L29.5435 24.0581C29.0627 24.4317 28.6736 24.9101 28.4061 25.4569C28.1386 26.0036 27.9997 26.6042 28 27.2127V38.0028C28 38.7977 28.3161 39.56 28.8787 40.1221C29.4413 40.6842 30.2044 41 31 41H49C49.7957 41 50.5587 40.6842 51.1213 40.1221C51.6839 39.56 52 38.7977 52 38.0028V27.2127C52 25.9794 51.43 24.8149 50.455 24.0581Z"
            stroke="#0039A7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M43.3828 59.832H35.9414V62.9609H43.3828V59.832ZM34.8164 59.293H44.543V58.1211H40.4297V57.5996C42.2637 57.4941 43.2598 56.9844 43.2656 56.0938C43.2598 55.0859 41.9883 54.5762 39.6797 54.5703C37.3828 54.5762 36.082 55.0859 36.0938 56.0938C36.082 56.9844 37.1016 57.4941 38.9297 57.5996V58.1211H34.8164V59.293ZM35.2852 54.3008H44.0391V53.1758H40.4297V52.3203H38.9297V53.1758H35.2852V54.3008ZM37.418 61.8477V60.9453H41.918V61.8477H37.418ZM37.6875 56.0938C37.6875 55.7188 38.332 55.584 39.6797 55.5781C41.0391 55.584 41.6602 55.7188 41.6602 56.0938C41.6602 56.4512 41.0391 56.6094 39.6797 56.6094C38.332 56.6094 37.6875 56.4512 37.6875 56.0938Z"
          fill="#0039A7"
        />
        <defs>
          <clipPath id="clip0_1858_1514">
            <rect width="32" height="32" fill="white" transform="translate(24 13)" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <Wrapper onClick={onClick}>
        <Container>
          <Home fill={COLORS.gray700} />
        </Container>
        <Fonts.H5>홈</Fonts.H5>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1px;
  background-color: ${COLORS.white};
  color: ${COLORS.gray700};
`;

const Container = styled.div`
  margin-bottom: 2px;
`;
