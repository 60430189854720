import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ko } from 'date-fns/locale';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import dayjs from 'lib/dayjs';

interface DateRangeCalendarProps {
  dateRange?: { start: Date; end?: Date }[];
  onChange?: (range: { start: Date; end: Date }[]) => void;
}

const DateRangeCalendar = ({ dateRange, onChange }: DateRangeCalendarProps) => {
  const [range, setRange] = useState<
    {
      startDate: Date;
      endDate: Date | undefined;
      key: string;
    }[]
  >([
    {
      startDate: dateRange && dateRange[0].start ? dateRange[0].start : new Date(),
      endDate: dateRange && dateRange[0].end ? dateRange[0].end : new Date(),
      key: 'selection',
    },
  ]);

  const handleChange = (item: any) => {
    setRange([item.selection]);
    if (onChange) {
      onChange([{ start: item.selection.startDate!, end: item.selection.endDate! }]);
    }
  };

  return (
    <Container>
      <DateRangePicker
        locale={ko}
        onChange={(item) => {
          handleChange(item);
        }}
        months={1}
        ranges={range}
        focusedRange={dayjs(range[0].startDate).isSame(range[0].endDate, 'date') ? [0, 1] : [0, 0]}
        direction="horizontal"
      />
    </Container>
  );
};

export default DateRangeCalendar;

const Container = styled.div`
  .rdrDateRangePickerWrapper {
    width: 100%;
  }

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    width: 100%;
  }

  .rdrMonth {
    width: 100%;
  }

  .rdrDay {
    height: 36px;
    margin-top: 1px;

    .rdrDayNumber span {
      color: ${COLORS.gray900} !important;
    }
  }

  .rdrInRange {
    height: 36px;
    top: 0;
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .rdrDayStartOfWeek .rdrEndEdge {
    border-radius: 50%;
    left: 0;
  }

  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .rdrStartEdge {
    height: 36px;
    top: 0;
    left: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${COLORS.sub3};
  }

  .rdrEndEdge {
    height: 36px;
    top: 0;
    right: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${COLORS.sub3};
  }

  .rdrDayInPreview {
    height: 36px;
    bottom: 0;
    top: 0;
    border: 0px dashed ${COLORS.gray300};
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  .rdrDayStartPreview {
    height: 36px;
    bottom: 0;
    top: 0;
    border: 0px dashed ${COLORS.gray300};
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    z-index: 100;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rdrDayEndPreview {
    height: 36px;
    bottom: 0;
    top: 0;
    border: 0px dashed ${COLORS.gray300};
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rdrDayPassive {
    .rdrDayNumber span {
      color: ${COLORS.gray300} !important;
    }
  }

  .rdrDayToday {
    background-color: ${COLORS.brand1} !important;
    border-radius: 50%;
    color: ${COLORS.white};

    .rdrDayNumber span {
      color: ${COLORS.white} !important;

      ::after {
        display: none;
      }
    }

    .rdrStartEdge {
      border-radius: 50%;
      background-color: ${COLORS.brand1} !important;
    }

    .rdrEndEdge {
      border-radius: 50%;
      background-color: ${COLORS.brand1} !important;
    }

    .rdrInRange {
      border-radius: 50%;
      background-color: ${COLORS.brand1} !important;
    }
  }
`;
