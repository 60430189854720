import { SVGProps } from 'react';

export const ArrowDownLong = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M8 3.33073L8 12.6641" stroke={props.stroke || '#1F2023'} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 9L8 13L4 9" stroke={props.stroke || '#1F2023'} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
