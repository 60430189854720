import { SVGProps } from 'react';

export const Recurrence = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.9643 10.4199C13.2443 13.5389 10.2423 15.5765 7.07766 15.0939C3.91332 14.6115 1.65466 11.7722 1.89666 8.58054C2.13866 5.38821 4.79899 2.92188 7.99999 2.92188"
        stroke={props.stroke ? props.stroke : '#858994'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.06 0.835938L10.3093 2.94094M10.3093 2.94094L8 5.18927M10.3093 2.94094L8 2.9266"
        stroke={props.stroke ? props.stroke : '#858994'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7.42188L8.33333 10.5469L15 4.29688"
        stroke={props.stroke ? props.stroke : '#858994'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
