import { SVGProps } from 'react';

export const Tomorrow = ({ width = 16, height = 16, stroke = '#1F2023', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 9H11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 7L11 9L9 11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.9998 3.33203H3.99984C3.26346 3.33203 2.6665 3.92898 2.6665 4.66536V12.6654C2.6665 13.4017 3.26346 13.9987 3.99984 13.9987H11.9998C12.7362 13.9987 13.3332 13.4017 13.3332 12.6654V4.66536C13.3332 3.92898 12.7362 3.33203 11.9998 3.33203Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.6665 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.3335 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
