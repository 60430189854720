import React, { FC, useCallback } from 'react';
import { useCommands, useCurrentSelection } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';
import { CodeBlockExtension } from 'remirror/extensions';
import { useActive, useRemirrorContext } from '@remirror/react';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

export interface InsertCodeBlockButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const InsertCodeBlockButton: FC<InsertCodeBlockButtonProps> = (props) => {
  const [language] = useAtom(languageAtom);
  const { commands } = useRemirrorContext<CodeMirrorExtension>({ autoUpdate: true });
  const { createCodeMirror } = commands;
  const onClose = props.onClose;

  const handleSelect = useCallback(() => {
    if (createCodeMirror.enabled({ language: 'JavaScript' })) createCodeMirror({ language: 'JavaScript' });
    onClose && onClose();
  }, [commands]);

  const active = useActive<CodeMirrorExtension>().codeBlock();
  const enabled = createCodeMirror.enabled({ language: 'JavaScript' });

  return <CommandButton {...props} commandName={language === 'ko' ? '코드블록' : 'Code Block'} active={active} enabled={enabled} onSelect={handleSelect} />;
};
