import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAllCategoryV1CategoryGet } from 'queries';
import { OutCategory } from 'queries/model';

const request = async () => await getAllCategoryV1CategoryGet();
const categoryList = atomWithDefault<OutCategory[]>(request);
export const categoryAtom = atom(
  (get) => get(categoryList),
  async (_, set) => {
    const res = await getAllCategoryV1CategoryGet();
    set(categoryList, res || []);
  },
);
