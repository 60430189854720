import { SVGProps } from 'react';

export const Desk = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="124" height="120" viewBox="0 0 124 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.8994 5.64481L81.198 4.60663L88.5896 0.3391C89.7987 -0.358968 91.3448 0.0552966 92.0428 1.26439L93.0464 3.00262L93.8061 2.56403C95.0151 1.86596 96.5612 2.28023 97.2593 3.48931C97.9573 4.6984 97.5431 6.24446 96.334 6.94253L95.5743 7.38111L96.574 9.11263C97.2721 10.3217 96.8578 11.8678 95.6487 12.5658L94.8488 13.0277L101.884 19.8357C102.622 20.5501 103.039 21.5333 103.039 22.5606V44.7107H119.641C121.735 44.7107 123.433 46.4084 123.433 48.5026V57.3027C123.433 59.3969 121.735 61.0946 119.641 61.0946H110L110 116.206C110 118.301 108.675 120 107.04 120H97.96C96.3252 120 95 118.301 95 116.206V61.0946H29V116.206C29 118.301 27.6748 120 26.04 120H16.96C15.3252 120 14 118.301 14 116.206V61.0946H4.3583C2.2641 61.0946 0.566406 59.3969 0.566406 57.3027V48.5026C0.566406 46.4084 2.26409 44.7107 4.3583 44.7107H97.9828V23.0963L90.2952 15.6567L88.2675 16.8274L82.7113 24.3717C82.2054 25.0586 81.3872 25.444 80.5354 25.3966C79.6836 25.3492 78.9132 24.8754 78.4866 24.1366L69.9906 9.4211C69.5641 8.68228 69.5389 7.77815 69.9238 7.01677C70.3086 6.25539 71.0515 5.73948 71.8994 5.64481ZM85.4624 7.98258L87.4657 11.4523L90.9316 9.45131L88.9283 5.98156L85.4624 7.98258ZM83.5229 14.7439L80.6431 9.75587L76.3018 10.2406L80.9325 18.2612L83.5229 14.7439ZM100.511 49.7666L100.495 49.7666H5.62226V56.0387H16.5356C16.6742 56.0132 16.8159 56 16.96 56H26.04C26.1841 56 26.3258 56.0132 26.4644 56.0387H97.5356C97.6742 56.0132 97.8159 56 97.96 56H107.04C107.184 56 107.326 56.0132 107.464 56.0387H118.377V49.7666H100.526L100.511 49.7666ZM98.9466 61.0946V114.941H106.053L106.053 61.0946H98.9466ZM25.0534 61.0946H17.9466V114.941H25.0534V61.0946Z"
          fill="url(#paint0_linear_13505_22769)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_13505_22769" x1="62.1699" y1="2.11769" x2="62.1374" y2="117.547" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C471ED" />
          <stop offset="1" stopColor="#F64F59" />
        </linearGradient>
      </defs>
    </svg>
  );
};
