import styled from '@emotion/styled';
import { Dialog, Tab, Tabs } from '@mui/material';
import { Icons } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import MembershipPage from './Membership';
import BelieverPage from './Believer';
import { initializePaddle, Paddle, PaddleEventData } from '@paddle/paddle-js';
import toast from 'react-hot-toast';
import { useAtom } from 'jotai';
import { updateUserAtom } from 'atoms/user';
import { createPaymentPlanBelieverV1PaymentPlanBelieverPost, getBelieverPlanV1BelieverMeGet } from 'queries';
import { OutBelieverPlan } from 'queries/model';
import { languageAtom } from 'atoms/language';
import PaymentComplete from './Components/PaymentComplete';

const Membership = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(1);
  const [paddle, setPaddle] = useState<Paddle>();
  const [believer, setBeliever] = useState<OutBelieverPlan | undefined>(undefined);
  const [render, setRender] = useState(false);
  const [openPaymentCompleteDialog, setOpenPaymentCompleteDialog] = useState(false);
  const [language] = useAtom(languageAtom);
  const [user, setUser] = useAtom(updateUserAtom);
  const environment = process.env.REACT_APP_MODE === 'production' ? 'production' : 'sandbox';
  const token = process.env.REACT_APP_PADDLE_TOKEN!;
  const priceId = process.env.REACT_APP_PADDLE_PRICE_ID!;

  useEffect(() => {
    const fetchUser = async () => {
      await setUser();
    };
    fetchUser();
  }, [setUser]);

  useEffect(() => {
    if (!user) return;
    const fetchBeliever = async () => {
      if (user.membership === 'believer') {
        await getBelieverData();
      }
      setRender(true);
    };

    fetchBeliever();
  }, [user]);

  useEffect(() => {
    initializePaddle({
      environment,
      token,
      eventCallback: function (data) {
        if (data.name == 'checkout.completed') {
          handlePayment(data);
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getBelieverData = async () => {
    const believer = await getBelieverPlanV1BelieverMeGet();
    setBeliever(believer);
  };

  const handlePayment = async (data: PaddleEventData) => {
    if (data.name == 'checkout.completed') {
      try {
        await createPaymentPlanBelieverV1PaymentPlanBelieverPost({
          transactionId: data.data?.transaction_id || '',
        });

        await getBelieverData();
        await setUser();
        setTabValue(1);
        setOpenPaymentCompleteDialog(true);
      } catch (error) {
        toast.error(language == 'ko' ? '결제 실패' : 'Payment failed');
      }
    }
  };

  const handleClickJoinBeliever = () => {
    paddle?.Checkout.open({
      items: [{ priceId: priceId, quantity: 1 }],
      customData: { userId: user?.id || '' },
    });
  };

  const handleClosePaymentComplete = () => {
    setOpenPaymentCompleteDialog(false);
  };

  return (
    <Container>
      {render && (
        <>
          <MembershipHeader>
            <Link to={'/task'}>
              <HeaderButton>
                <Icons.ArrowBack width={24} height={24} stroke={COLORS.gray900} />
                <HeaderText>{t('settings.workspace.back')}</HeaderText>
              </HeaderButton>
            </Link>
          </MembershipHeader>
          <MembershipMain>
            <Tabs
              orientation="vertical"
              value={tabValue}
              onChange={handleChangeTabs}
              sx={{ minWidth: '310px', height: '100%', borderRight: `1px solid ${COLORS.gray300}`, padding: '28px' }}
            >
              <TabHeader>{t(`membership.tab1`)}</TabHeader>
              {!believer && (
                <Tab
                  icon={<Icons.Membership width={24} height={24} />}
                  iconPosition="start"
                  label={`${t('membership.tab1')}`}
                  sx={{ height: '48px', borderRadius: '8px', minHeight: '0px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                />
              )}
              <Tab
                icon={<Icons.Believer width={24} height={24} />}
                iconPosition="start"
                label={`${t('membership.tab2')}`}
                sx={{
                  height: '48px',
                  borderRadius: '8px',
                  minHeight: '0px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: '12px',
                }}
              />
            </Tabs>
            <TabPanel hidden={tabValue !== 1 || Boolean(believer)}>
              <MembershipPage onClickJoinBeliever={handleClickJoinBeliever} />
            </TabPanel>
            <TabPanel hidden={believer ? tabValue !== 1 : tabValue !== 2}>
              <BelieverPage user={user!} believer={believer} onClickJoinBeliever={handleClickJoinBeliever} />
            </TabPanel>
          </MembershipMain>
        </>
      )}
      <Dialog open={openPaymentCompleteDialog}>
        <PaymentComplete onClose={handleClosePaymentComplete} />
      </Dialog>
    </Container>
  );
};

export default Membership;

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiTabs-indicator {
    width: 0px;
  }

  .Mui-selected {
    background-color: ${COLORS.gray200};
  }
`;

const MembershipHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
  padding: 20px 24px;
`;

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 6px;
`;

const MembershipMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const TabHeader = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 12px 16px;
`;

const TabPanel = styled.div`
  width: 100%;
  display: ${(props: { hidden: boolean }) => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
`;
