import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';

interface HighlightInfoDialogProps {
  open?: boolean;
  onClose?: () => void;
}

const HighlightInfoDialog = ({ open = false, onClose }: HighlightInfoDialogProps) => {
  const navigate = useNavigate();
  const [language] = useAtom(languageAtom);

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: 13, fontWeight: 700, display: 'flex', justifyContent: 'center', padding: '24px 24px 32px 24px' }}>
        <div style={{ width: 'fit-content', border: `1px solid ${COLORS.gray200}`, borderRadius: '100px', padding: '6px 12px 4px 12px' }}>
          <span style={{ marginRight: 8 }}>⏰</span>
          <span
            style={{
              background: 'var(--Gradation, linear-gradient(180deg, #C471ED 1.78%, #F64F59 97.94%))',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {language === 'ko' ? '하이라이트' : 'Highlight'}
          </span>
        </div>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 700, textAlign: 'center', marginBottom: '16px', whiteSpace: 'pre-line' }}>
          {language === 'ko'
            ? `'이 일을 끝내면 오늘 하루는 성공이다'\n싶은 일을 하이라이트 해보세요.`
            : `
         Highlight the task that would make\nyour day feel successful once completed.
          `}
        </Typography>
        <img
          src={require(language === 'ko' ? 'assets/images/highlightGuide.png' : 'assets/images/highlightGuide_en.png')}
          alt="highlight-guide"
          style={{ width: '408px' }}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px' }}>
        <Button
          onClick={() => navigate('/settings?section=notification')}
          sx={{
            width: 192,
            boxShadow: 'none',
            borderRadius: '8px',
            color: COLORS.gray900,
            backgroundColor: COLORS.gray100,
            padding: '14px 16px',
            fontSize: '13px',
            fontWeight: 700,
            textTransform: 'none',
          }}
        >
          {language === 'ko' ? '알림 끄기' : 'Turn off notifications'}
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ width: 192, boxShadow: 'none', borderRadius: '8px', padding: '14px 16px', fontSize: '13px', fontWeight: 700, textTransform: 'none' }}
        >
          {language === 'ko' ? '알았어요!' : 'Got it!'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HighlightInfoDialog;
