import styled from '@emotion/styled';
import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { Icons, Login } from 'components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { COLORS } from 'styles/constants';
import Slide from 'components/Slide';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const LoginSectionWrapper = styled.div`
  min-width: 640px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SlideSectionWrapper = styled.div`
  width: calc(100% - 640px);
  height: 100%;
  background-color: #2d6adf;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 32px;
`;

const TitleWrapper = styled.p`
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
`;

const SubTitleWrapper = styled.p`
  text-align: center;
  color: ${COLORS.gray800};
  font-size: 16px;
  line-height: 24px;
`;

const TermsWrapper = styled.div`
  display: flex;
  font-size: 12px;
`;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const onHandleLoginGoogle = useGoogleLogin(options);
  return <Login onClick={onHandleLoginGoogle} label={label} />;
};

const SlidePages = [
  {
    title: '내 성장을 돕는 단 세 가지 질문으로\n회고해보세요',
    image: require('assets/images/swipe_review_1.png'),
  },
  {
    title: '원하는 회고 시간에\n리마인더를 받아보세요',
    image: require('assets/images/swipe_review_2.png'),
  },
  {
    title: '습관이 쌓이는 것을 시각적으로 확인하세요',
    image: require('assets/images/swipe_review_3.png'),
  },
];

const ReflectionSignIn = () => {
  const params = new URLSearchParams({ from: 'reflection' });

  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/auth${params ? `?${params}` : ''}`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  return (
    <Container>
      <LoginSectionWrapper>
        <LogoWrapper>
          <Icons.Logo width={70} />
        </LogoWrapper>
        <TitleWrapper style={{ marginTop: 160 }}>
          {`SLASH와 함께`}
          <br />
          {`'생각대로' 살아보세요`}
        </TitleWrapper>
        <SubTitleWrapper style={{ marginTop: 24 }}>
          SLASH는 회고의 습관화에 도움을 드리기 위해
          <br />
          구글 계정을 통해 구글 캘린더와 연동합니다.
        </SubTitleWrapper>
        <div style={{ marginTop: 24 }}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogInButton options={googleLoginOptions} label={'구글 계정으로 로그인'} />
          </GoogleOAuthProvider>
        </div>
        <TermsWrapper style={{ marginTop: 220 }}>
          <a href="javascript:void(0)" style={{ color: COLORS.gray600 }}>
            이용약관
          </a>
          <div style={{ margin: '0px 8px' }}>|</div>
          <a href="http://policy.doslash.io/" target="_blank" rel="noreferrer">
            <p style={{ color: COLORS.gray600 }}>개인 정보 취급 방침</p>
          </a>
        </TermsWrapper>
      </LoginSectionWrapper>
      <SlideSectionWrapper>
        <Slide pages={SlidePages} />
      </SlideSectionWrapper>
    </Container>
  );
};

export default ReflectionSignIn;
