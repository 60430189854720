import styled from '@emotion/styled';
import { Divider, Popover } from '@mui/material';
import { featureStorageAtom } from 'atoms/featureStorage';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import ConvertGuide from 'components/Guide/ConvertGuide';
import { useAtom } from 'jotai';
import { createFeatureStorageV1FeatureStoragePost, updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch } from 'queries';
import { OutFeatureStorage } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';

export type ConvertType = 'PROJECT' | 'ROUTINE';
export type ConvertMenuType = 'LINK_TO_PROJECT' | 'CONVERT_TO_PROJECT' | 'CONVERT_TO_ROUTINE';

interface TaskboxConvertMenuProps {
  types: ConvertType[];
  onClick?: (type: ConvertMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const TaskboxConvertMenu = ({ types = [], onClick }: TaskboxConvertMenuProps) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refProject = useRef<HTMLSpanElement>(null);
  const refRoutine = useRef<HTMLSpanElement>(null);
  const [language] = useAtom(languageAtom);
  const [featureStorage, setFeatureStorage] = useAtom(featureStorageAtom);
  const [convertGuideAnchorEl, setConvertGuideAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const isFirstShowConvertGuide = featureStorage.find((feature) => feature.name === 'convert-guide')?.data.show || false;

    if (!isFirstShowConvertGuide) {
      const el = refContainer.current as HTMLElement;
      setConvertGuideAnchorEl(el);
    }
  }, []);

  const handleClickMenu = (type: ConvertMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick && onClick(type, e);
  };

  const handleClickConvertGuideNext = (index: number) => {
    if (index === 0) {
      const el = refProject.current as HTMLElement;
      setConvertGuideAnchorEl(el);
    } else if (index === 1) {
      const el = refRoutine.current as HTMLElement;
      setConvertGuideAnchorEl(el);
    }
  };

  const handleCloseConvertGuide = async () => {
    setConvertGuideAnchorEl(null);

    const convertGuideStorage = featureStorage.find((feature) => feature.name === 'convert-guide');
    if (!convertGuideStorage) {
      await createFeatureStorageV1FeatureStoragePost({ name: 'convert-guide', data: { show: true } })
        .then(() => {
          setFeatureStorage((prev: OutFeatureStorage[]) =>
            prev.map((feature) => (feature.name === 'convert-guide' ? { ...feature, data: { ...feature.data, show: true } } : feature)),
          );
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <Container ref={refContainer}>
      {types.includes('PROJECT') && (
        <ConvertTypeWrapper>
          <TitleWrapper>
            <Icons.Issue width={12} height={12} style={{ marginRight: 6 }} />
            <span ref={refProject}>{language === 'ko' ? '프로젝트' : 'Project'}</span>
          </TitleWrapper>
          <MenuWrapper>
            <MenuItem onClick={() => handleClickMenu('CONVERT_TO_PROJECT')}>
              <Icons.ConvertToProject style={{ marginRight: 8 }} />
              <span>{language === 'ko' ? '프로젝트로 전환하기' : 'Convert to new project'}</span>
            </MenuItem>
            <MenuItem onClick={(e) => handleClickMenu('LINK_TO_PROJECT', e)}>
              <Icons.LinkToProject style={{ marginRight: 8 }} />
              <span>{language === 'ko' ? '기존 프로젝트에 연결하기' : 'Link to existing project'}</span>
            </MenuItem>
          </MenuWrapper>
        </ConvertTypeWrapper>
      )}
      <Divider sx={{ margin: '4px 0px 8px 0px' }} />
      {types.includes('ROUTINE') && (
        <ConvertTypeWrapper>
          <TitleWrapper>
            <Icons.Routine style={{ marginRight: 6 }} />
            <span ref={refRoutine}>{language === 'ko' ? '루틴' : 'Routine'}</span>
          </TitleWrapper>
          <MenuWrapper>
            <MenuItem onClick={() => handleClickMenu('CONVERT_TO_ROUTINE')}>
              <Icons.Reload style={{ marginRight: 8 }} />
              <span>{language === 'ko' ? '루틴으로 전환하기' : 'Convert to new routine'}</span>
            </MenuItem>
          </MenuWrapper>
        </ConvertTypeWrapper>
      )}
      {/** 프로젝트, 루틴 전환 가이드 */}
      {convertGuideAnchorEl && (
        <Popover
          className="convert-guide-popover"
          open={Boolean(convertGuideAnchorEl)}
          anchorEl={convertGuideAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{ paper: { className: 'convert-guide-popover' } }}
          sx={{
            'marginTop': '-56px',
            'marginLeft': convertGuideAnchorEl?.tagName === 'DIV' ? '-10px' : '-8px',
            ' .MuiPaper-root': {
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: '22px', height: '100%', backgroundColor: 'transparent' }} />
            <ConvertGuide onClickNext={handleClickConvertGuideNext} onClose={handleCloseConvertGuide} />
          </div>
        </Popover>
      )}
    </Container>
  );
};

export default TaskboxConvertMenu;

const Container = styled.div`
  width: 230px;
  padding: 8px;
`;

const ConvertTypeWrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  padding-left: 8px;
`;

const MenuWrapper = styled.div`
  margin-top: 8px;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  padding: 8px;

  :hover {
    background-color: ${COLORS.gray100};
  }
`;
