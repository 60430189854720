import { SVGProps } from 'react';

export const DonutLarge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.34961 13.6339C4.6385 13.4672 3.21072 12.7505 2.06628 11.4839C0.921832 10.2172 0.349609 8.72274 0.349609 7.00052C0.349609 5.2783 0.921832 3.78385 2.06628 2.51719C3.21072 1.25052 4.6385 0.533854 6.34961 0.367188V2.36719C5.19405 2.52274 4.2385 3.03941 3.48294 3.91719C2.72739 4.79497 2.34961 5.82274 2.34961 7.00052C2.34961 8.1783 2.72739 9.20608 3.48294 10.0839C4.2385 10.9616 5.19405 11.4783 6.34961 11.6339V13.6339ZM7.68294 13.6339V11.6339C8.72739 11.5005 9.61072 11.0672 10.3329 10.3339C11.0552 9.60052 11.4941 8.71163 11.6496 7.66719H13.6496C13.4941 9.25608 12.8579 10.6089 11.7413 11.7255C10.6246 12.8422 9.27183 13.4783 7.68294 13.6339ZM11.6496 6.33385C11.4941 5.28941 11.0552 4.40052 10.3329 3.66719C9.61072 2.93385 8.72739 2.50052 7.68294 2.36719V0.367188C9.27183 0.522743 10.6246 1.15885 11.7413 2.27552C12.8579 3.39219 13.4941 4.74497 13.6496 6.33385H11.6496Z"
        fill="#1C1B1F"
      />
    </svg>
  );
};
