import { atom } from 'jotai';
import { getSignupSurveyV1SurveysSignupGet } from 'queries';
import { WelcomeSurveyOut } from 'queries/model';

export const surveyAtom = atom<WelcomeSurveyOut | null>(null);
export const updateSurveyAtom = atom(
  (get) => get(surveyAtom),
  async (_, set) => {
    const res = await getSignupSurveyV1SurveysSignupGet();
    set(surveyAtom, res || {});
  },
);
