import { SVGProps } from 'react';

export const Merge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M4 7L6 9L10 5" stroke={props.stroke ? props.stroke : '#1F2023'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="1.75" y="1.75" width="10.5" height="10.5" stroke={props.stroke ? props.stroke : '#1F2023'} strokeWidth="1.5" />
      <circle cx="1.5" cy="1.5" r="1.5" fill={props.fill ? props.fill : '#1F2023'} />
      <circle cx="12.5" cy="1.5" r="1.5" fill={props.fill ? props.fill : '#1F2023'} />
      <circle cx="12.5" cy="12.5" r="1.5" fill={props.fill ? props.fill : '#1F2023'} />
      <circle cx="1.5" cy="12.5" r="1.5" fill={props.fill ? props.fill : '#1F2023'} />
    </svg>
  );
};
