import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Profile from './Profile';
import Menu, { menus } from './Menu';
import { Dialog, Modal, Popover, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { Tutorial } from 'components/Tutorial';
import { hideScroll } from 'styles/utils';
import Shortcut from 'components/Shortcut';
import { recordUserRequestSurveyV1SurveysRequestPost } from 'queries';
import SuggestionConfirmDialog from 'components/SuggestionDialog/SuggetionConfirmDialog';
import SuggestionDialog, { SuggestionType } from 'components/SuggestionDialog';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  max-width: 80px;
  justify-content: space-between;
  overflow-y: scroll;
  ${hideScroll()}
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const UserActions = styled.div`
  width: 80px;
  padding-bottom: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UserActionsPopover = styled.div`
  width: 140px;
  height: auto;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px 0px;
`;

const UserActionsPopoverContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const UserActionsProposalContent = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const GNB = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [language] = useAtom(languageAtom);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tutorialReplay, setTutorialReplay] = useState(false);
  const [shortCutAnchorEl, setShortCutAnchorEl] = useState<null | HTMLElement>(null);
  const [suggestionDialogOpen, setSuggestionDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [submitSuggestion, setSubmitSuggestion] = useState(false);
  const [suggestion, setSuggestion] = useState({
    content: [
      {
        request: '',
        satisfaction: '',
        email: '',
      },
    ],
  });

  useEffect(() => {
    const menu = menus.find((item) => location.pathname.includes(item.path));
    if (menu) {
      setSelectedMenu(menu.name);
    } else {
      setSelectedMenu(menus[0].name);
    }
  }, [location]);

  const handleClickTutorialReplay = () => {
    setTutorialReplay(true);
  };

  const handleCloseTutorialReplay = () => {
    setTutorialReplay(false);
  };

  const handleCloseSuggestion = () => {
    setSuggestionDialogOpen(false);
    setSuggestion({
      content: [
        {
          request: '',
          satisfaction: '',
          email: '',
        },
      ],
    });
  };

  const handleOpenSuggestionConfirm = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
  };

  const handleChangeSuggestion = (suggestion: SuggestionType) => {
    setSuggestion(suggestion);
  };

  const handleClickSuggestionClose = () => {
    const { email, request, satisfaction } = suggestion.content[0];

    email || request || satisfaction ? handleOpenSuggestionConfirm() : handleCloseSuggestion();
    setTimeout(() => {
      if (submitSuggestion) {
        setSubmitSuggestion(false);
      }
    }, 200);
  };

  const handleClickSuggestionSubmit = async () => {
    try {
      setSuggestion({
        content: [
          {
            request: '',
            satisfaction: '',
            email: '',
          },
        ],
      });
      const success = await recordUserRequestSurveyV1SurveysRequestPost(suggestion);
      if (success) {
        setSubmitSuggestion(true);
      }
    } catch (error) {
      language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
    }
  };

  return (
    <Container>
      <div>
        <Profile />
        <Menu selectedMenu={selectedMenu} />
      </div>
      <UserActions>
        <Tooltip title={t('gnb.tooltip.shortcut')} placement="right" disableInteractive>
          <IconButton
            onClick={(e) => {
              setShortCutAnchorEl(e.currentTarget);
            }}
            style={{ border: `1px solid ${COLORS.gray200}`, width: 48, height: 48, marginBottom: '8px' }}
          >
            <Icons.ShortcutKey width={32} height={32} stroke={COLORS.gray700} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('gnb.tooltip.user_manual')} placement="right" disableInteractive>
          <IconButton
            id="tutorial-replay"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            style={{ border: `1px solid ${COLORS.gray200}`, width: 48, height: 48, marginBottom: '8px' }}
          >
            <Icons.Question fill={COLORS.gray700} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('suggestion.title')} placement="right" disableInteractive>
          <IconButton onClick={() => setSuggestionDialogOpen(true)} style={{ border: `1px solid ${COLORS.gray200}`, width: 48, height: 48 }}>
            <Icons.Bulb />
          </IconButton>
        </Tooltip>
        <Popover
          open={Boolean(shortCutAnchorEl)}
          anchorEl={shortCutAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setShortCutAnchorEl(null)}
          PaperProps={{
            sx: {
              'scrollbarWidth': 'none', // Firefox 용 스크롤바를 숨김
              '&::-webkit-scrollbar': {
                display: 'none', // Webkit 기반 브라우저 (Chrome, Safari) 용 스크롤바를 숨김
              },
            },
          }}
          sx={{ marginLeft: '4px' }}
        >
          <Shortcut onClose={() => setShortCutAnchorEl(null)} />
        </Popover>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setAnchorEl(null)}
          sx={{ marginLeft: '4px' }}
        >
          <UserActionsPopover>
            <UserActionsProposalContent href="https://www.sigcrew.com/1ec7df99-538c-4368-8851-87598180691a" target="_blank" rel="noreferrer">
              <Icons.ListSearch width={16} height={16} />
              <div style={{ marginLeft: '8px' }}>{t('manual.menu.user_manual')}</div>
            </UserActionsProposalContent>
            <UserActionsPopoverContent onClick={handleClickTutorialReplay}>
              <Icons.TutorialReplay />
              <div style={{ marginLeft: '8px' }}>{t('manual.menu.tutorial')}</div>
            </UserActionsPopoverContent>
            {/* <UserActionsProposalContent href="https://tally.so/r/31Xjk1" target="_blank" rel="noreferrer">
              <Icons.Bulb width={16} height={16} />
              <div style={{ marginLeft: '8px' }}> 제안하기</div>
            </UserActionsProposalContent> */}
          </UserActionsPopover>
        </Popover>
      </UserActions>
      <Modal open={tutorialReplay} onClose={handleCloseTutorialReplay}>
        <Tutorial replay={tutorialReplay} />
      </Modal>
      <Dialog
        open={suggestionDialogOpen}
        onClose={handleClickSuggestionClose}
        PaperProps={{
          style: {
            maxWidth: 'none',
          },
        }}
      >
        <SuggestionDialog
          data={suggestion}
          submit={submitSuggestion}
          onChange={handleChangeSuggestion}
          onSubmit={handleClickSuggestionSubmit}
          onClose={handleClickSuggestionClose}
        />
      </Dialog>
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirm}>
        <SuggestionConfirmDialog onCloseConfirmDialog={handleCloseConfirm} onCloseSuggestionDialog={handleCloseSuggestion} />
      </Dialog>
    </Container>
  );
};

export default GNB;
