import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';

export interface SubTaskCheckboxProps extends CheckboxProps {
  bordercolor?: string;
  width?: number;
  height?: number;
  focus?: boolean;
  multiSelect?: boolean;
}

const SubTaskCheckbox = ({ bordercolor, width, height, focus = false, multiSelect = false, ...props }: SubTaskCheckboxProps) => {
  const UncheckedIcon = focus ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="url(#paint0_linear_17862_134882)" />
      <defs>
        <linearGradient id="paint0_linear_17862_134882" x1="8.02215" y1="0.282359" x2="8.01772" y2="15.6729" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C471ED" />
          <stop offset="1" stopColor="#F64F59" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill={multiSelect ? COLORS.sub3 : 'white'} stroke={bordercolor} />
    </svg>
  );
  const CheckedIcon = focus ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="url(#paint0_linear_17862_94586)" />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_17862_94586" x1="8.02215" y1="0.282359" x2="8.01772" y2="15.6729" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C471ED" />
          <stop offset="1" stopColor="#F64F59" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill={bordercolor} />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
  return <Checkbox {...props} icon={UncheckedIcon} checkedIcon={CheckedIcon} sx={{ padding: 0, margin: 0, ...props.sx }} />;
};
export default SubTaskCheckbox;
