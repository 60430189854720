import { SVGProps } from 'react';

export const TaskboxConvertButton = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.0035 12.2179C11.2308 12.2179 12.2257 11.223 12.2257 9.99566C12.2257 8.76836 11.2308 7.77344 10.0035 7.77344C8.77617 7.77344 7.78125 8.76836 7.78125 9.99566C7.78125 11.223 8.77617 12.2179 10.0035 12.2179Z"
        fill={props.fill || '#656871'}
      />
      <path
        d="M10.0026 16.6615C13.6845 16.6615 16.6693 13.6767 16.6693 9.99479C16.6693 6.31289 13.6845 3.32812 10.0026 3.32812C6.32071 3.32812 3.33594 6.31289 3.33594 9.99479C3.33594 13.6767 6.32071 16.6615 10.0026 16.6615Z"
        stroke={props.stroke || '#656871'}
        strokeWidth="1.77778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
