import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';

interface PaymentCompleteProps {
  onClose?: () => void;
}

const PaymentComplete = ({ onClose }: PaymentCompleteProps) => {
  const [language] = useAtom(languageAtom);
  const navigate = useNavigate();

  return (
    <Container>
      <IconWrapper>
        <Icons.Check width={60} height={60} stroke="white" />
      </IconWrapper>
      <HeaderWrapper>
        <HeaderTitle>{language === 'ko' ? '결제 완료!' : 'Payment Complete!'}</HeaderTitle>
        <HeaderSubtitle>{language === 'ko' ? '빌리버로 함께 하게 되어서 반가워요!' : 'Excited to have you join us as a Believer!'}</HeaderSubtitle>
      </HeaderWrapper>
      <PaymentInfoWrapper>
        <div style={{ width: '46%', display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
          <div style={{ fontWeight: 700 }}>{language === 'ko' ? '플랜' : 'Plan'}</div>
          <div style={{ marginLeft: 12 }}>{language === 'ko' ? '빌리버' : 'Believer'}</div>
        </div>
        <div style={{ width: '46%', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontWeight: 700 }}>{language === 'ko' ? '결제 금액' : 'Payment Amount'}</div>
          <div style={{ marginLeft: 12 }}>$200</div>
        </div>
      </PaymentInfoWrapper>
      <Button
        onClick={onClose}
        variant="contained"
        sx={{ width: '198px', height: '48px', borderRadius: '8px', padding: '14px 16px', marginTop: '24px', fontWeight: 700, textTransform: 'none' }}
      >
        {language === 'ko' ? '빌리버 정보 확인하기' : 'View Believer Information'}
      </Button>
    </Container>
  );
};

export default PaymentComplete;

const Container = styled.div`
  width: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.sub2};
  border-radius: 50%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const HeaderSubtitle = styled.div`
  font-size: 16px;
`;

const PaymentInfoWrapper = styled.div`
  width: 332px;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 32px;
`;
