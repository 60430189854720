import { atom } from 'jotai';

export type TimerType = 'COUNTUP' | 'COUNTDOWN';

export const totalTimeAtom = atom<number>(0);
export const pauseAtom = atom<boolean>(true);
export const timerTypeAtom = atom<TimerType>('COUNTDOWN');
export const resetTimerAtom = atom(null, (get, set) => {
  set(totalTimeAtom, 0);
  set(pauseAtom, true);
});
export const ritualAlarmTimeAtom = atom<number>(0);
