import dayjs from 'lib/dayjs';
import { atom } from 'jotai';
import { listWorksV1WorksGet } from 'queries';
import { OutWorks } from 'queries/model';

export type WorkDragView =
  | 'plan'
  | 'inbox'
  | 'backlog'
  | 'planning'
  | 'plan-inbox-issue'
  | 'plan-inbox-task'
  | 'plan-planning'
  | 'onboarding'
  | 'taskboard'
  | 'ritual'
  | 'side-project'
  | 'project-detail'
  | 'side-routine'
  | 'routine-detail';
export type WorkDraggableType =
  | 'task'
  | 'taskbox'
  | 'subtask'
  | 'issues'
  | 'issue'
  | 'multi-taskbox'
  | 'multi-subtask'
  | 'project'
  | 'project-subtask'
  | 'project-multi-subtask'
  | 'instance'
  | 'SOMEDAY_TASK';
export type WorkDragContext = {
  id?: string;
  view?: WorkDragView;
  title?: string;
  type?: WorkDraggableType;
  data?: any;
  done?: boolean;
  date?: Date;
  projectId?: string;
};

export const dragContextAtom = atom<WorkDragContext | undefined | null>(null);
export const worksAtom = atom<OutWorks>({});
export const inboxAtom = atom<OutWorks>({});
export const backlogAtom = atom<OutWorks>({});
export const fetchTasksAtom = atom(
  (get) => [get(inboxAtom), get(backlogAtom)],
  async (get, set) => {
    const [inbox, someday] = await Promise.all([listWorksV1WorksGet({ section_type: 'inbox' }), listWorksV1WorksGet({ section_type: 'someday' })]);
    set(inboxAtom, inbox || {});
    set(backlogAtom, someday || {});
  },
);

export default { dragContextAtom, worksAtom };
