import { SVGProps } from 'react';

export const Link = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1891_709)">
        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E7EAF4" />
        <path
          d="M11 13.2419C11.2273 13.4819 11.4986 13.6726 11.7981 13.8028C12.0975 13.933 12.419 14 12.7438 14C13.0686 14 13.3901 13.933 13.6896 13.8028C13.989 13.6726 14.2603 13.4819 14.4876 13.2419L17.2777 10.3554C17.7402 9.87694 18 9.228 18 8.55134C18 7.87468 17.7402 7.22574 17.2777 6.74727C16.8152 6.2688 16.1879 6 15.5339 6C14.8798 6 14.2526 6.2688 13.7901 6.74727L13.4413 7.10808"
          stroke="#0039A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 10.7581C12.7727 10.5181 12.5014 10.3274 12.2019 10.1972C11.9025 10.067 11.581 10 11.2562 10C10.9314 10 10.6099 10.067 10.3104 10.1972C10.011 10.3274 9.7397 10.5181 9.51239 10.7581L6.72231 13.6446C6.25982 14.1231 6 14.772 6 15.4487C6 16.1253 6.25982 16.7743 6.72231 17.2527C7.18479 17.7312 7.81206 18 8.46611 18C9.12016 18 9.74743 17.7312 10.2099 17.2527L10.5587 16.8919"
          stroke="#0039A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1891_709">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
