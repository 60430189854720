import { SVGProps } from 'react';

export const RadioDisableUnchecked = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99967 13.6673C6.07745 13.6673 5.21079 13.4922 4.39967 13.142C3.58856 12.7922 2.88301 12.3173 2.28301 11.7173C1.68301 11.1173 1.20812 10.4118 0.858341 9.60065C0.508119 8.78954 0.333008 7.92287 0.333008 7.00065C0.333008 6.07843 0.508119 5.21176 0.858341 4.40065C1.20812 3.58954 1.68301 2.88398 2.28301 2.28398C2.88301 1.68398 3.58856 1.20887 4.39967 0.858651C5.21079 0.508873 6.07745 0.333984 6.99967 0.333984C7.9219 0.333984 8.78856 0.508873 9.59967 0.858651C10.4108 1.20887 11.1163 1.68398 11.7163 2.28398C12.3163 2.88398 12.7912 3.58954 13.141 4.40065C13.4912 5.21176 13.6663 6.07843 13.6663 7.00065C13.6663 7.92287 13.4912 8.78954 13.141 9.60065C12.7912 10.4118 12.3163 11.1173 11.7163 11.7173C11.1163 12.3173 10.4108 12.7922 9.59967 13.142C8.78856 13.4922 7.9219 13.6673 6.99967 13.6673ZM6.99967 12.334C8.48856 12.334 9.74967 11.8173 10.783 10.784C11.8163 9.75065 12.333 8.48954 12.333 7.00065C12.333 5.51176 11.8163 4.25065 10.783 3.21732C9.74967 2.18398 8.48856 1.66732 6.99967 1.66732C5.51079 1.66732 4.24967 2.18398 3.21634 3.21732C2.18301 4.25065 1.66634 5.51176 1.66634 7.00065C1.66634 8.48954 2.18301 9.75065 3.21634 10.784C4.24967 11.8173 5.51079 12.334 6.99967 12.334Z"
        fill="#ABB0BF"
      />
    </svg>
  );
};
