import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { ArrowDownSmall, Search } from '../Icons';

export interface DropdownOptionType {
  value: string;
  label: string;
  description: string;
}

export type NoteItemType = 'objective' | 'agendas' | 'shareables' | 'discussables' | 'tasks' | 'keyContents';

interface PropertySelectProps {
  disabled?: boolean;
  optionDisabled?: boolean;
  optionLabel: string;
  hasSearch?: boolean;
  dropdownOptions: DropdownOptionType[];
  itemType: NoteItemType;
  searchValue?: string;
  placeholder: string;
  inputRef: any;
  onSearch?: () => void;
  onClickOption: (option: DropdownOptionType) => void;
  onHandleKeyUp?: (event: any) => void;
}

export const PropertySelect = ({
  disabled = false,
  optionDisabled = false,
  hasSearch = false,
  dropdownOptions,
  itemType,
  searchValue,
  placeholder,
  optionLabel,
  onClickOption,
  onSearch, // search button클릭 시 적용되는 함수.
  onHandleKeyUp,
  inputRef,
}: PropertySelectProps) => {
  const [focus, setFocus] = useState(false);
  const [visibleDropdown, setVisibleDropdown] = React.useState(false);

  const onClickDropdownOption = (option: DropdownOptionType) => {
    onClickOption(option);
    setVisibleDropdown(false);
  };

  const toggleDropdown = () => {
    !visibleDropdown && setFocus(true);
    setVisibleDropdown(!visibleDropdown);
  };
  const onHandleFocusOut = (event: any) => {
    !visibleDropdown && setFocus(false);
  };

  return (
    <Wrapper disabled={disabled} focus={focus}>
      <SelectContainer>
        <ContentInput
          ref={inputRef}
          placeholder={placeholder}
          data-type={itemType}
          style={{
            width: '80%',
          }}
          onKeyUp={onHandleKeyUp}
          onFocus={() => setFocus(true)}
          onBlur={onHandleFocusOut}
        />

        <SelectButton disabled={disabled} onClick={disabled ? () => {} : toggleDropdown}>
          <Fonts.Badge style={{ marginRight: '4px' }}>{optionLabel}</Fonts.Badge>
          <ArrowDownSmall width={16} height={16} fill={disabled ? COLORS.gray500 : undefined} />
        </SelectButton>
      </SelectContainer>
      {visibleDropdown && (
        <Dropdown>
          {hasSearch && (
            <SearchBox>
              <Input name="property-input" type="text" />
              <Button type="submit" onClick={onSearch} disabled={disabled}>
                {disabled ? <Search fill={COLORS.gray500} /> : <Search />}
              </Button>
            </SearchBox>
          )}
          <DropdownUl>
            {dropdownOptions.map((option) => (
              <DropdownLi onClick={() => onClickDropdownOption(option)} role="option" optionDisabled={optionDisabled}>
                <OptionLabel optionDisabled={optionDisabled}>{option.label}</OptionLabel>
                <OptionText optionDisabled={optionDisabled}>{option.description}</OptionText>
              </DropdownLi>
            ))}
          </DropdownUl>
        </Dropdown>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled: boolean; focus: boolean }>`
  position: relative;
  height: 48px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  color: ${COLORS.gray900};

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${COLORS.gray200};
      background-color: ${COLORS.gray100};
      color: ${COLORS.gray500};
    `}
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}

    div:focus-visible {
    outline: none;
    border-width: 0;
  }
`;

const ContentInput = styled.input`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  &:focus-visible {
    outline: none;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  &:focus-visible {
    background-color: ${COLORS.brand1};
  }
`;

const SelectButton = styled.div<{ disabled: boolean }>`
  width: fit-content;
  cursor: pointer;
  height: 24px;
  border-radius: 100px;
  background-color: ${COLORS.sub3};
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.gray200};
    `}
`;

const Dropdown = styled.div`
  position: absolute;
  right: 0px;
  top: 40px;

  box-shadow: 0px 8px 16px 0px #1a1e2729;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  display: flex;

  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  margin-left: 25px;
`;

const SearchBox = styled.div`
  width: 161px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding-left: 16px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 105px;
  height: 46px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  border: none;
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  outline: none !important;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: none;
  background-color: ${COLORS.white};
  cursor: pointer;
`;

const DropdownUl = styled.ul`
  background-color: ${COLORS.white};
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 0px;
  padding: 0px;
`;

const DropdownLi = styled.li<{
  optionDisabled?: boolean;
}>`
  cursor: pointer;
  height: 40px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray900};
  &:hover {
    background-color: ${COLORS.sub3};
  }

  ${(props) =>
    props.optionDisabled &&
    css`
      cursor: no-drop;
      &:hover {
        background-color: ${COLORS.white};
      }
    `}
`;

const OptionLabel = styled.div<{
  optionDisabled?: boolean;
}>`
  width: fit-content;
  height: 23px;
  background-color: ${COLORS.sub3};
  border-radius: 100px;
  box-sizing: border-box;
  color: ${COLORS.gray900};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 8px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.25px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.white};
  }

  ${(props) =>
    props.optionDisabled &&
    css`
      color: ${COLORS.gray400};
      background-color: ${COLORS.gray200};
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.25px;
      cursor: no-drop;
    `}
`;
const OptionText = styled.div<{
  optionDisabled?: boolean;
}>`
  width: fit-content;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray600};

  ${(props) =>
    props.optionDisabled &&
    css`
      color: ${COLORS.gray300};
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.25px;
      cursor: no-drop;
    `}
`;
