import styled from '@emotion/styled';
import { IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { useEffect, useState } from 'react';
import Memo from 'pages/Task/SidePanel/tabs/Memo';
import Backlog from 'pages/Task/SidePanel/tabs/Backlog';
import { COLORS } from 'styles/constants';
import { useEventListener, useKeyboardEvent } from '@react-hookz/web';
import { isMouseInsideMemoContainer, removeMemoHandle } from 'components/Remirror/utils';
import { Resizable } from 're-resizable';
import { useAtom } from 'jotai';
import { foldPlanSidePanelViewAtom, planSidePanelWidthAtom } from 'atoms/foldView';
import { createPortal } from 'react-dom';
import { osName } from 'react-device-detect';
import { deleteProjectV1ProjectProjectIdDelete, deleteRoutineV1RoutineRoutineIdDelete } from 'queries';
import { projectsAtom, selectedProjectAtom, syncInstanceAtom } from 'atoms/projects';
import ProjectDetailView from './ProjectDetailView';
import { routinesAtom, selectedRoutineAtom } from 'atoms/routine';
import RoutineDetailView from './RoutineDetailView';
import { languageAtom } from 'atoms/language';

const Container = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bgColor};
`;

const TabPanel = styled.div`
  height: 100%;
  width: 100%;
`;

const FoldTapPanel = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 50px;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  padding: 2px;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

const SidePanel = () => {
  const [tabIndex, setTabIndex] = useState(Number(localStorage.getItem('tabpanel-index') || 1));
  const [sidePanelWidth, setSidePanelWidth] = useAtom(planSidePanelWidthAtom);
  const [bodyWidth, setBodyWidth] = useState(document.body.getBoundingClientRect().width);
  const [isHoverWidthLine, setIsHoverWidthLine] = useState(false);
  const [foldSidePanel, setFoldSidePanel] = useAtom(foldPlanSidePanelViewAtom);
  const [isBlinking, setIsBlinking] = useState(false);
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);
  const [, setSyncInstance] = useAtom(syncInstanceAtom);
  const [, fetchProjects] = useAtom(projectsAtom);
  const [, fetchRoutines] = useAtom(routinesAtom);
  const [language] = useAtom(languageAtom);

  useEffect(() => {
    localStorage.setItem('tabpanel-index', tabIndex.toString());
  }, [tabIndex]);

  useEventListener(
    document.querySelector('.side-panel-resizable'),
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, document.querySelector('.side-panel-resizable') as Element);
      if (!inside) {
        removeMemoHandle();
      }
    },
    { passive: true },
  );

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement;
      if (!element) return;
      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
      ) {
        return;
      }
      if (ev.altKey && ev.code === 'Digit1') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(0);
        } else {
          setTabIndex(0);
        }
      }

      if (ev.altKey && ev.code === 'Digit2') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(1);
        } else {
          setTabIndex(1);
        }
      }

      if (ev.altKey && ev.code === 'Digit3') {
        if (foldSidePanel) {
          handleClickFoldSidePanelView(2);
        } else {
          setTabIndex(2);
        }
      }

      if ((ev.metaKey || ev.ctrlKey) && ev.altKey && ev.code == 'BracketRight') {
        handleClickFoldSidePanelView();
      }
    },
    [],

    { event: 'keydown', eventOptions: { passive: true } },
  );

  useEventListener(
    window,
    'resize',
    () => {
      if (bodyWidth - document.body.getBoundingClientRect().width > 100 || bodyWidth - document.body.getBoundingClientRect().width < -100) {
        setSidePanelWidth(Math.round((document.body.getBoundingClientRect().width - 80) * (sidePanelWidth / (bodyWidth - 80))));
        localStorage.setItem(
          'panel-side-panel-width',
          JSON.stringify(Math.round((document.body.getBoundingClientRect().width - 80) * (sidePanelWidth / (bodyWidth - 80)))),
        );
      }

      setBodyWidth(document.body.getBoundingClientRect().width);
    },
    { passive: true },
  );

  const handleResizsStart = () => {
    setIsHoverWidthLine(true);
  };

  const handleResizeStop = (e: MouseEvent | TouchEvent, direction: string, ref: HTMLElement, d: { width: number; height: number }) => {
    if (!localStorage.getItem('plan-side-panel-width')) {
      const ele = document.querySelector('.side-panel-resizable') as HTMLElement;
      const width = Math.round(ele.getBoundingClientRect().width);
      setSidePanelWidth(width);
      localStorage.setItem('plan-side-panel-width', JSON.stringify(width));
    } else {
      setSidePanelWidth(sidePanelWidth + d.width);
      localStorage.setItem('plan-side-panel-width', JSON.stringify(sidePanelWidth + d.width));
    }
  };

  const handleClickFoldSidePanelView = (index?: number) => {
    if (index !== undefined) setTabIndex(index!);
    setFoldSidePanel(!foldSidePanel);
    localStorage.setItem('plan-fold-side-panel-view', JSON.stringify(!foldSidePanel));
    setIsHoverWidthLine(false);
  };

  const handleCloseProjectDetail = () => {
    setSelectedProject(null);
    setSyncInstance(null);
    fetchProjects();
  };

  const handleFetchProjects = () => {
    fetchProjects();
  };

  const handleDeleteProject = async (projectId: string) => {
    await deleteProjectV1ProjectProjectIdDelete(projectId);
    handleCloseProjectDetail();
  };

  const handleFetchRoutines = () => {
    fetchRoutines();
  };

  const handleCloseRoutineDetail = () => {
    setSelectedRoutine(null);
    fetchRoutines();
  };

  const handleDeleteRoutine = async (routineId: string) => {
    await deleteRoutineV1RoutineRoutineIdDelete(routineId);
    handleCloseRoutineDetail();
  };

  return (
    <>
      {!foldSidePanel && (
        <Resizable
          className="side-panel-resizable"
          size={{
            width: sidePanelWidth,
            height: '100%',
          }}
          minWidth={374}
          maxWidth={(bodyWidth - 120) * 0.5}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          onResizeStart={() => handleResizsStart()}
          onResize={() => {
            setIsHoverWidthLine(true);
          }}
          onResizeStop={(e, direction, ref, d) => {
            handleResizeStop(e, direction, ref, d);
            setIsHoverWidthLine(false);
          }}
          handleComponent={{
            left: (
              <div
                onMouseEnter={() => {
                  setIsHoverWidthLine(true);
                  removeMemoHandle();
                }}
                onMouseDown={() => setIsHoverWidthLine(true)}
                onMouseLeave={() => setIsHoverWidthLine(false)}
                onClick={() => setIsHoverWidthLine(true)}
                style={{ width: 6, borderRight: isHoverWidthLine ? `2px solid ${COLORS.sub2}` : `2px solid transparent`, height: '100%' }}
              />
            ),
          }}
          style={{
            padding: tabIndex === 1 ? '8px 20px 0px 20px' : '0px',
            position: 'relative',
            backgroundColor: tabIndex === 1 ? COLORS.white : COLORS.gray100,
          }}
        >
          <Container className="side-panel-resizable" bgColor={tabIndex === 1 ? COLORS.white : COLORS.gray100}>
            <div style={{ borderBottom: `1px solid ${COLORS.gray300}`, margin: tabIndex === 1 ? '0px 8px' : '8px 28px 0px 28px' }}>
              <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
                <Tab
                  icon={<Icons.Later />}
                  iconPosition="start"
                  label={language === 'ko' ? '나중에' : 'Later'}
                  style={{ width: '50%', textTransform: 'none' }}
                />
                <Tab
                  icon={<Icons.MemoTab />}
                  iconPosition="start"
                  label={language === 'ko' ? '메모' : 'Memo'}
                  style={{ width: '50%', textTransform: 'none' }}
                />
              </Tabs>
            </div>
            <div style={{ marginTop: tabIndex === 1 ? 16 : 0, height: '100%', width: '100%' }}>
              <TabPanel role="tabpanel" hidden={!(tabIndex === 0)}>
                <Backlog active={tabIndex === 0} />
              </TabPanel>
              <TabPanel role="tabpanel" hidden={!(tabIndex === 1)}>
                <Memo active={tabIndex === 1} />
              </TabPanel>
            </div>
            <div
              onMouseOver={() => {
                setIsHoverWidthLine(true);
                removeMemoHandle();
              }}
              onMouseLeave={() => setIsHoverWidthLine(false)}
              style={{ width: '15px' }}
            >
              {isHoverWidthLine && (
                <Tooltip
                  title={
                    <div style={{ padding: '5px 8px' }}>
                      <span>{language === 'ko' ? '오른쪽 탭 접기' : 'Collapse right tab'}</span>
                      <KeyboardButtonRect style={{ marginLeft: 8 }}> {osName === 'Windows' ? 'Ctrl' : '⌘'}</KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect>{`]`}</KeyboardButtonRect>
                    </div>
                  }
                  placement="left"
                  disableInteractive
                  sx={{ padding: '0px' }}
                >
                  <IconButton
                    onClick={() => handleClickFoldSidePanelView()}
                    sx={{
                      'width': '32px',
                      'height': '32px',
                      'position': 'absolute',
                      'left': -15,
                      'top': '48%',
                      'zIndex': 10000,
                      'backgroundColor': COLORS.white,
                      'borderRadius': '8px',
                      'border': `1px solid ${COLORS.gray300}`,
                      'boxShadow': `0px 8px 16px 0px rgba(26, 30, 39, 0.16)`,
                      'padding': '4px',
                      ':hover': {
                        backgroundColor: COLORS.sub3,
                      },
                    }}
                  >
                    <Icons.ArrowRightSmall />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Container>
          <ProjectDetailView
            isSide
            selectedProject={selectedProject || undefined}
            onClose={handleCloseProjectDetail}
            onDelete={handleDeleteProject}
            onFetch={handleFetchProjects}
          />
          <RoutineDetailView
            isSide
            planView
            selectedRoutine={selectedRoutine || undefined}
            onClose={handleCloseRoutineDetail}
            onFetch={handleFetchRoutines}
            onDelete={handleDeleteRoutine}
          />
        </Resizable>
      )}
      {foldSidePanel &&
        createPortal(
          <FoldTapPanel style={{ zIndex: 100 }}>
            <Tooltip
              title={
                <div style={{ padding: '5px 8px' }}>
                  <span>{language === 'ko' ? '메모 보기' : 'View memo'}</span>
                  <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect>1</KeyboardButtonRect>
                </div>
              }
              placement="left"
              disableInteractive
              sx={{ padding: '0px' }}
            >
              <IconButton
                onClick={() => handleClickFoldSidePanelView(0)}
                sx={{
                  'width': '28px',
                  'height': '28px',
                  'backgroundColor': COLORS.white,
                  'borderRadius': '8px',
                  'padding': '4px',
                  ':hover': {
                    backgroundColor: COLORS.sub3,
                  },
                }}
              >
                <Icons.Memo fill={COLORS.gray900} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ padding: '5px 8px' }}>
                  <span>{language === 'ko' ? '나중에 보기' : 'View later'}</span>
                  <KeyboardButtonRect style={{ marginLeft: 8 }}>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect>2</KeyboardButtonRect>
                </div>
              }
              placement="left"
              disableInteractive
              sx={{ padding: '0px' }}
            >
              <IconButton
                onClick={() => handleClickFoldSidePanelView(1)}
                sx={{
                  'width': '28px',
                  'height': '28px',
                  'backgroundColor': isBlinking ? COLORS.positive2 : COLORS.white,
                  'borderRadius': '8px',
                  'padding': '4px',
                  ':hover': {
                    backgroundColor: COLORS.sub3,
                  },
                }}
              >
                <Icons.Later fill={isBlinking ? COLORS.positive1 : COLORS.gray900} />
              </IconButton>
            </Tooltip>
          </FoldTapPanel>,
          document.body,
        )}
    </>
  );
};

export default SidePanel;
