import { SVGProps } from 'react';

export const Todo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.10171 7.40345H27.9472M8.10171 7.40345C7.07259 7.40345 6.55674 7.40345 6.16367 7.60373C5.81791 7.77991 5.53701 8.06082 5.36084 8.40659C5.16056 8.79968 5.16056 9.31464 5.16056 10.3438V19.8994C5.16056 21.9577 5.16056 22.987 5.56112 23.7732C5.91346 24.4647 6.47527 25.027 7.16679 25.3793C7.95216 25.7795 8.98081 25.7795 11.035 25.7795H25.0111C27.0653 25.7795 28.0925 25.7795 28.8779 25.3793C29.5694 25.027 30.1337 24.4646 30.4861 23.7731C30.8862 22.9877 30.8862 21.9603 30.8862 19.906V10.3271C30.8862 9.30907 30.8862 8.79755 30.687 8.40659C30.5109 8.06082 30.2287 7.77991 29.8829 7.60373C29.4898 7.40345 28.9764 7.40345 27.9472 7.40345M8.10171 7.40345H5.11518C3.55493 7.40345 2.77526 7.40345 2.33245 7.13068C1.74152 6.76666 1.40648 6.10021 1.46572 5.40867C1.51015 4.89003 1.97456 4.26277 2.90489 3.00678C3.17398 2.64351 3.30855 2.46182 3.47325 2.32311C3.69287 2.13815 3.95347 2.0068 4.23281 1.94041C4.44231 1.89062 4.66718 1.89062 5.11928 1.89062H30.9265C31.3786 1.89062 31.6041 1.89062 31.8136 1.94041C32.0929 2.0068 32.3534 2.13815 32.573 2.32311C32.7377 2.46182 32.8728 2.64266 33.1419 3.00593C34.0722 4.26191 34.5374 4.88992 34.5818 5.40857C34.6411 6.1001 34.3048 6.76666 33.7139 7.13068C33.2711 7.40345 32.4896 7.40345 30.9293 7.40345H27.9472M14.3483 16.5915H21.6985"
        stroke="#ABB0BF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
