import styled from '@emotion/styled';
import { Button, Checkbox, Dialog, Divider, IconButton, MenuItem, MenuList, Popover, Slide, TextField, Tooltip } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Icons } from 'components';
import {
  InCreateLink,
  InCreateTaskBoxInRecurrence,
  OutCategory,
  OutFrequentWork,
  OutLink,
  OutProject,
  OutTaskboxDetailResponse,
  UpdateTaskForTaskBox,
  UpdateTaskbox,
} from 'queries/model';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import TaskBlockInputList, { ProjectTaskBlockAction, TaskBlock, TaskBlockHandle } from './TaskBlockInputList';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { HighlightTimeSelectionModal } from './HighlightTimeSelectionModal';
import dayjs, { Dayjs } from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_5, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import TimeDateSelectionModal from 'components/TimeSelectionModal/TimeDateSelectionModal';
import {
  createLinkV1LinkPost,
  createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost,
  updateLinkV1LinkLinkIdPatch,
  updateProjectV1ProjectProjectIdPatch,
  updateTaskboxV1TaskboxesTaskboxIdPut,
} from 'queries';
import toast from 'react-hot-toast';
import { ContinueDateType, DateType } from './TimelineTaskbox';
import { v4 as uuidv4 } from 'uuid';
import ConfirmDialog, { DialogElement } from 'components/ConfirmDialog';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import FrequentWorkDialog from './TemplateDialog';
import { TaskboxContinueDetail } from './TaskboxContinueDetail';
import React from 'react';
import { hideScroll } from 'styles/utils';
import { useEventListener, useKeyboardEvent } from '@react-hookz/web';
import Timer from 'components/Timer';
import { TimerActionType } from '..';
import { TemplateType } from '../Week';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType } from 'remirror';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { DeadlinePopover } from './DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { isMouseInsideMemoContainer, removeMemoHandle } from 'components/Remirror/utils';
import { useAtom } from 'jotai';
import { loadSubtaskFromProjectAtom, projectsAtom } from 'atoms/projects';
import ProjectDetailView from 'pages/Desk/components/SidePanel/ProjectDetailView';
import { languageAtom } from 'atoms/language';

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 18px 52px;
`;

const DialogContent = styled.div`
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  ${hideScroll()}
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const TaskboxWrapper = styled.div`
  width: 760px;

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }
`;

const TaskboxHeader = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  margin-bottom: 8px;
`;

const TaskboxTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 26px;
  margin-bottom: 10px;

  .Mui-readOnly {
    cursor: pointer;
  }
`;

const MenuItemText = styled.span`
  font-size: 12px;
  margin-left: 8px;

  .Mui-readOnly {
    cursor: pointer;
  }
`;

const ShowingTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    .unselect-time {
      display: block;
    }
  }

  .unselect-time {
    display: none;
  }
`;

const MemoWrapper = styled.div``;

const LinkWrapper = styled.div`
  padding: 16px 0px;
`;

const LinkContentWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  position: relative;

  .link-icon {
    opacity: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :hover {
    background-color: ${COLORS.gray100};
    .link-icon {
      opacity: 1;
    }
  }
`;

const CreateLinkButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const ShowingTime = styled.div`
  font-size: 12px;
  margin-left: 4px;
  color: ${COLORS.gray500};
`;

const InProgressTaskbox = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const InProgressTaskboxContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const LinkPopoverContainer = styled.div`
  width: 365px;
`;

const LinkPopoverWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
`;

const LinkURLWrapper = styled.div``;

const LinkTitleWrapper = styled.div`
  margin-top: 12px;
`;

const LinkDeleteWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

export const focusModeContextMenuName = {
  HIGHLIGHT: 'HIGHLIGHT',
  TIME: 'TIME',
  CALENDAR: 'CALENDAR',
  TEMPLATE: 'TEMPLATE',
  DELETE: 'DELETE',
} as const;

export type FocusModeContextMenuType = typeof focusModeContextMenuName[keyof typeof focusModeContextMenuName];

interface FocusModeDialogProps {
  open: boolean;
  taskbox?: OutTaskboxDetailResponse;
  templateList?: OutFrequentWork[];
  hide?: boolean;
  categoryList?: OutCategory[];
  onFetch?: () => void;
  onClose?: (focusedTaskbox: UpdateTaskbox | null) => void;
  onHide?: (focusedTaskbox: UpdateTaskbox) => void;
  onDelete?: (focusedTaskbox: OutTaskboxDetailResponse) => void;
  onCreateTemplate?: (focusedTaskbox: OutTaskboxDetailResponse) => void;
  onPutTemplate?: (focusedTaskbox: OutFrequentWork) => void;
  onDeleteTemplate?: (focusedTaskbox: OutFrequentWork) => void;
  onTemplateToTaskbox?: (focusedTaskbox: OutFrequentWork) => void;
  onChangeTemplate?: (template: OutFrequentWork, type: TemplateType) => void;
  onChangeInprogress?: (taskbox: OutTaskboxDetailResponse, date: Date | null, isAllDay: boolean, dateType: ContinueDateType) => void;
  onChangeTimer?: (type: TimerActionType) => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType) => void;
  onChangeDeadline?: (date: Dayjs | null) => void;
  onClickInstanceContinue?: (taskbox?: OutTaskboxDetailResponse) => void;
  onChangeInstanceSubtask?: (block: TaskBlock, action: ProjectTaskBlockAction) => void;
  onPushProjectRecommendTask?: (tasks: TaskBlock[], blockId: string, index: number) => void;
  onDeleteProject?: (id: string) => void;
}

interface focusModeTaskbox extends OutTaskboxDetailResponse {
  beforeId?: string | null;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FocusModeDialog = ({
  open,
  taskbox,
  templateList = [],
  hide,
  categoryList = [],
  onFetch,
  onClose,
  onHide,
  onDelete,
  onCreateTemplate,
  onPutTemplate,
  onDeleteTemplate,
  onChangeTemplate,
  onChangeInprogress,
  onChangeTimer,
  onClickCategoryActions,
  onChangeDeadline,
  onClickInstanceContinue,
  onChangeInstanceSubtask,
  onPushProjectRecommendTask,
  onDeleteProject,
}: FocusModeDialogProps) => {
  const [language] = useAtom(languageAtom);
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [focusModeProject, setFocusModeProject] = useState<OutProject | null>(null);
  const [focusModeTaskbox, setFocusModeTaskbox] = useState<focusModeTaskbox | undefined>(taskbox);
  const [highlightAnchorEl, setHighlightAnchorEl] = useState<HTMLElement | null>(null);
  const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLElement | null>(null);
  const [simpleTimeAnchorEl, setSimpleTimeAnchorEl] = useState<HTMLDivElement | null>(null);
  const [inprogressAnchorEl, setInprogressAnchorEl] = useState<HTMLElement | null>(null);
  const [inprogressSettingAnchorEl, setInprogressSettingAnchorEl] = useState<HTMLElement | null>(null);
  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const [focusModeMemo, setFocusModeMemo] = useState<Element | null>(null);
  const [projects, fetchProjects] = useAtom(projectsAtom);
  const [, setIsLoadSubtaskFromProject] = useAtom(loadSubtaskFromProjectAtom);
  const openTimeRef = useRef(0);

  useEffect(() => {
    setTimeout(() => {
      const focusMemo = document.querySelector('.focus-mode-memo');
      setFocusModeMemo(focusMemo);
      const remirror = focusMemo?.firstChild as HTMLDivElement;
      if (remirror) remirror.classList.add('focus-mode-memo');
    }, 100);
  }, []);

  useEffect(() => {
    setFocusModeTaskbox(taskbox);
  }, [taskbox]);

  useEffect(() => {
    if (focusModeProject) {
      const project = projects.find((project) => project.id === focusModeProject.id);
      if (project) {
        setFocusModeProject(project);
      }
    }
  }, [projects]);

  useEffect(() => {
    if (open) {
      openTimeRef.current = performance.now();
    } else {
      openTimeRef.current = 0;
    }
  }, [open]);

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.timeStamp < openTimeRef.current) return;
      if (ev.shiftKey && ev.code === 'KeyF') {
        const element = document.activeElement;
        if (!element) return;
        if (
          element.tagName === 'TEXTAREA' ||
          (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
          (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        ) {
          return;
        }

        onHide && onHide(focusModeTaskbox! as UpdateTaskbox);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  useEventListener(
    focusModeMemo,
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, focusModeMemo as Element);
      if (!inside) {
        removeMemoHandle();
      }
    },
    { passive: true },
  );

  const handleChangeTasks = (tasks: TaskBlock[]) => {
    if (!focusModeTaskbox) return;

    const doneTasksCount = tasks.filter((task) => task.done).length;
    const isAllTasksDone = !focusModeTaskbox.done && tasks.length === 0 ? false : doneTasksCount === tasks.length;

    setFocusModeTaskbox({
      ...focusModeTaskbox,
      done: isAllTasksDone,
      tasks: tasks,
    });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocusModeTaskbox({ ...focusModeTaskbox, title: e.target.value });
  };

  const handleClickTaskboxCheck = () => {
    const tasks = focusModeTaskbox?.tasks?.map((task) => ({ ...task, done: !focusModeTaskbox?.done }));
    setFocusModeTaskbox({ ...focusModeTaskbox, done: !focusModeTaskbox?.done, tasks });
  };

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      refTaskBlockList?.current?.add();
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleClose = () => {
    const taskbox = {
      ...focusModeTaskbox,
      tasks: focusModeTaskbox!.tasks!.map((task) => ({ ...task, categoryId: task.category ? [task.category.id] : [] })),
    };
    removeMemoHandle();
    onClose && onClose(taskbox! as UpdateTaskbox);
    setTimeout(() => {
      onChangeTimer && onChangeTimer('RESET');
    }, 300);
  };

  const handleHide = () => {
    const taskbox = {
      ...focusModeTaskbox,
      tasks: focusModeTaskbox!.tasks!.map((task) => ({ ...task, categoryId: task.category ? [task.category.id] : [] })),
    };
    removeMemoHandle();
    onHide && onHide(taskbox! as UpdateTaskbox);
  };

  const refConfirm = useRef<DialogElement>(null);
  const handleClickContextMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, menu: FocusModeContextMenuType) => {
    if (menu === 'HIGHLIGHT') {
      handleHighlight();
    } else if (menu === 'TIME') {
      setTimeAnchorEl(e.currentTarget);
    } else if (menu === 'CALENDAR') {
      handleGoogleCalendar();
    } else if (menu === 'TEMPLATE') {
      handleClickTemplate();
    } else {
      refConfirm.current?.open();
    }
  };

  const handleHighlight = async () => {
    setFocusModeTaskbox({ ...focusModeTaskbox, focus: !focusModeTaskbox?.focus });
    setContextMenuAnchor(null);
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { focus: !focusModeTaskbox?.focus });
    if (success) onFetch && onFetch();
  };

  const handleClickHighlight = (start: string, end: string) => {
    setFocusModeTaskbox({
      ...focusModeTaskbox,
      focus: true,
      start: { datetime: dayjs(start).format(DATE_FORMAT_1) },
      end: { datetime: dayjs(end).format(DATE_FORMAT_1) },
    });
    setHighlightAnchorEl(null);
    setContextMenuAnchor(null);
  };

  const showingTime = (event?: OutTaskboxDetailResponse, duration?: number) => {
    // 시간과 기간이 모두 제공되지 않으면 기본 메시지를 반환
    if (!event && duration === undefined) {
      return language === 'ko' ? '시간 설정' : 'Set time';
      // return 'Set time';
    }

    // 이벤트 객체가 제공된 경우
    if (event?.start?.datetime && event.end?.datetime) {
      const start = dayjs(event.start.datetime);
      const end = dayjs(event.end.datetime);
      duration = end.diff(start, 'minutes'); // 기간 계산
    }

    // 기간이 제공된 경우
    if (duration !== undefined) {
      const hour = Math.floor(duration / 60);
      const minute = duration % 60;

      const durationTime =
        language === 'ko'
          ? hour === 0
            ? `${minute}분 동안`
            : minute === 0
            ? `${hour}시간 동안`
            : `${hour}시간 ${minute}분 동안`
          : hour === 0
          ? `${minute}min`
          : minute === 0
          ? `${hour}hr`
          : `${hour}hr ${minute}min`;
      // const durationTime = hour === 0 ? `${minute}min` : minute === 0 ? `${hour}hr` : `${hour}hr ${minute}min`;
      // 이벤트 객체가 제공된 경우 시간 포맷과 결합하여 반환
      if (event?.start?.datetime) {
        const timeboxTime = `${dayjs(event.start.datetime, { utc: true }).format(TIME_FORMAT_2)}, ${durationTime}`;
        return timeboxTime;
      }

      // 이벤트 객체가 제공되지 않은 경우 기간만 반환
      return durationTime;
    }

    return language === 'ko' ? '시간 설정' : 'Set time';
    // return 'Set time';
  };

  const handleGoogleCalendar = async () => {
    setFocusModeTaskbox({ ...focusModeTaskbox, lockedIn: !focusModeTaskbox?.lockedIn });
    setContextMenuAnchor(null);
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { lockedIn: !focusModeTaskbox?.lockedIn });
    if (success) onFetch && onFetch();
  };

  /** 타임라인 시간 변경 */
  const handleChangeTime = async (
    startTime: string,
    endTime: string,
    timeDiff: number,
    eventId?: string,
    isAllDay?: boolean,
    taskboxRecurrence?: InCreateTaskBoxInRecurrence,
  ) => {
    if (!focusModeTaskbox) return;
    const updateTaskbox: UpdateTaskbox = isAllDay
      ? {
          start: { date: dayjs(focusModeTaskbox.start?.date || focusModeTaskbox.start?.datetime).format(DATE_FORMAT_4) },
          end: {
            date: dayjs(focusModeTaskbox.start?.date || focusModeTaskbox.start?.datetime)
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          durationMin: timeDiff > 0 ? timeDiff : null,
          beforeId: null,
        }
      : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
      ? {
          start: { date: dayjs(startTime).format(DATE_FORMAT_4) },
          end: { date: dayjs(endTime).add(1, 'day').format(DATE_FORMAT_4) },
          beforeId: null,
        }
      : {
          start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
          end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
        };

    setFocusModeTaskbox({
      ...focusModeTaskbox,
      start: updateTaskbox.start,
      end: updateTaskbox.end,
      durationMin: updateTaskbox.durationMin,
      beforeId: updateTaskbox.beforeId,
    });

    if (taskboxRecurrence) {
      setFocusModeTaskbox({
        ...focusModeTaskbox,
        start: updateTaskbox.start,
        end: updateTaskbox.end,
        isRecurrence: taskboxRecurrence.recurrence?.intervalUnit ? true : false,
        recurrence: taskboxRecurrence.recurrence?.intervalUnit
          ? {
              ...taskboxRecurrence.recurrence,
              interval: taskboxRecurrence.recurrence!.interval,
            }
          : undefined,
      });
      await createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost(eventId!, taskboxRecurrence);
    }
  };

  const handleChangeDate = async (taskboxDate: Date | null, isAllDay = false, dateType: DateType | null = null) => {
    if (!focusModeTaskbox) return;
    if (taskboxDate) {
      const start = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(focusModeTaskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(focusModeTaskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      const taskbox = { ...focusModeTaskbox, start, end };
      setFocusModeTaskbox(taskbox);
      if (dateType === 'tomorrow') {
        language === 'ko' ? toast.success('태스크박스를 다음 날로 옮겼습니다.') : toast.success('Moved taskbox to the next day.');
        // toast.success('Moved taskbox to the next day.');
      } else if (dateType === 'nextWeek') {
        language === 'ko' ? toast.success('태스크박스를 다음주 월요일로 옮겼습니다.') : toast.success('Moved taskbox to the next day.');
        // toast.success('Moved taskbox to the next day.');
      } else if (dateType === 'calendar') {
        language === 'ko'
          ? toast.success(`태스크박스를 ${dayjs(taskboxDate).format(DATE_FORMAT_5)}로 옮겼습니다.`)
          : toast.success('Moved taskbox to the calendar.');
        // toast.success(`Moved taskbox to ${dayjs(taskboxDate).format(DATE_FORMAT_5)} `);
      }
      onClose && onClose(taskbox as UpdateTaskbox);
      setTimeAnchorEl(null);
      setContextMenuAnchor(null);
    } else {
      onClose && onClose({ ...(focusModeTaskbox as UpdateTaskbox), workSectionType: 'someday' });
    }
  };

  const handleConfirm = () => {
    onDelete && onDelete(focusModeTaskbox!);
  };

  const handleClickTemplate = () => {
    setContextMenuAnchor(null);
    onCreateTemplate && onCreateTemplate(focusModeTaskbox!);
  };

  const handleClickUnSelectTime = () => {
    setFocusModeTaskbox({
      ...focusModeTaskbox,
      start: { date: dayjs(focusModeTaskbox?.start?.datetime).format(DATE_FORMAT_4) },
      end: { date: dayjs(focusModeTaskbox?.start?.datetime).add(1, 'day').format(DATE_FORMAT_4) },
      durationMin: null,
      beforeId: null,
    });
  };

  const handleLoadTemplate = () => {
    setLoadTemplate(true);
  };

  const handleTemplateToTaskbox = (template: OutFrequentWork) => {
    if (!template) return;
    const taskboxTasks = focusModeTaskbox?.tasks || [];
    const templateTasks = template.tasks!.map((task) => ({ ...task, id: uuidv4(), categoryId: task.category ? [task.category.id] : [] }));
    setFocusModeTaskbox({ ...focusModeTaskbox, tasks: [...taskboxTasks, ...templateTasks] });
    setLoadTemplate(false);
  };

  const handleChangeInprogress = async (taskboxDate: Date | null, isAllDay = false, dateType: ContinueDateType | null = null) => {
    onChangeInprogress && onChangeInprogress(focusModeTaskbox!, taskboxDate, isAllDay, dateType!);
  };

  const saveMemo = async (data: any) => {
    if (!data) return;
    if (focusModeTaskbox?.project) {
      await updateProjectV1ProjectProjectIdPatch(focusModeTaskbox.project.id, { memo: data });
    } else {
      await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { memo: data });
    }
  };

  const handleChangeMemo = (data: any) => {
    if (!data) return;
    setFocusModeTaskbox({ ...focusModeTaskbox, memo: data });
    saveMemo(data);
  };

  const [selectedLink, setSelectedLink] = useState<OutLink | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState<HTMLElement | null>(null);
  const createLinkInputRef = useRef<HTMLInputElement>(null);

  const handleCreateLink = () => {
    const links = focusModeTaskbox?.links || [];
    if (links[links.length - 1]?.title === '' && links[links.length - 1]?.url === '') {
      setSelectedLink(links[links.length - 1]);
      setTimeout(() => {
        if (createLinkInputRef.current) createLinkInputRef.current?.focus();
      }, 100);
    } else {
      const newLink = {
        title: '',
        url: '',
        id: uuidv4(),
        taskId: focusModeTaskbox?.id,
      };
      setSelectedLink(newLink as OutLink);
      setFocusModeTaskbox({ ...focusModeTaskbox, links: [...links, newLink] as OutLink[] });
    }
  };

  const handleSaveLink = async () => {
    if (!selectedLink || selectedLink?.url === '') {
      clearLinkState();
      return;
    }

    const links = focusModeTaskbox?.links || [];
    const targetLink = links.find((v) => v.id === selectedLink.id);
    if (targetLink) {
      targetLink.url = selectedLink.url;
      targetLink.title = selectedLink.title;
      if (targetLink?.createdAt) {
        await updateLinkV1LinkLinkIdPatch(targetLink.id, { ...targetLink });
        setFocusModeTaskbox({ ...focusModeTaskbox, links: [...links] });
      } else {
        const success = await createLinkV1LinkPost(targetLink as InCreateLink);
        if (success) {
          targetLink.createdAt = success.createdAt;
          setFocusModeTaskbox({ ...focusModeTaskbox, links: [...links] });
          if (focusModeTaskbox?.project) {
            await updateProjectV1ProjectProjectIdPatch(focusModeTaskbox.project.id, { linkIds: links.map((link) => link.id) });
          } else {
            await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { linkIds: links.map((link) => link.id) });
          }
        }
      }
    }
    clearLinkState();
  };

  const clearLinkState = () => {
    setSelectedLink(null);
    setLinkAnchorEl(null);
  };

  const handleClickLinkUpdate = (link: OutLink) => {
    setSelectedLink(link);
    setTimeout(() => {
      const el = document.querySelector(`[data-link-id="${link.id}"]`) as HTMLDivElement;
      setLinkAnchorEl(el);
    }, 100);
  };

  const handleCopyClipBoard = async (text: string) => {
    if (text === '') return language === 'ko' ? toast.error('링크가 없습니다.') : toast.error('No link available.');
    // if (text === '') return toast.error('No link available.');

    try {
      await navigator.clipboard.writeText(text);
      language === 'ko' ? toast.success('클립보드에 링크가 복사되었습니다.') : toast.success('Link copied to clipboard.');
      // toast.success('Link copied to clipboard.');
    } catch (e) {
      language === 'ko' ? toast.error('복사에 실패하였습니다') : toast.error('Failed to copy.');
      // toast.error('Failed to copy.');
    }
  };

  const handleDeleteLink = async () => {
    await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, {
      ...(focusModeTaskbox as UpdateTaskbox),
      linkIds: focusModeTaskbox?.links?.filter((link) => link.id !== selectedLink?.id).map((link) => link.id),
    });
    clearLinkState();
    setFocusModeTaskbox({ ...focusModeTaskbox, links: focusModeTaskbox?.links?.filter((link) => link.id !== selectedLink?.id) });
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      const updatedTaskbox = { ...focusModeTaskbox, category };

      const value = refInput.current?.value;
      if (value && value.includes('#')) {
        refInput.current.value = value.replace(/#/g, '');
        updatedTaskbox.title = value.replace(/#/g, '');
      }

      setFocusModeTaskbox(updatedTaskbox);
      setCategoryAnchorEl(null);
    } else {
      if (action === 'DELETE') {
        const updatedTasks = focusModeTaskbox?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: undefined };
          }
          return task;
        });
        setFocusModeTaskbox({ ...focusModeTaskbox!, category: null, tasks: updatedTasks });
        setCategoryAnchorEl(null);
      } else if (action === 'UPDATE' || action === 'CREATE') {
        const updatedTasks = focusModeTaskbox?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: category! };
          }
          return task;
        });
        setFocusModeTaskbox({
          ...focusModeTaskbox!,
          category: focusModeTaskbox?.category && focusModeTaskbox!.category!.id === category!.id ? category : focusModeTaskbox?.category,
          tasks: updatedTasks,
        });
      }
      onClickCategoryActions && onClickCategoryActions(category, action);
    }
  };

  const handleClickDeadline = (date: Dayjs | null) => {
    const updatedTaskbox = { ...focusModeTaskbox, deadline: date?.format(DATE_FORMAT_1) };

    const value = refInput.current?.value;
    if (value && value.includes('$')) {
      refInput.current.value = value.replace(/\$/g, '');
      updatedTaskbox.title = value.replace(/\$/g, '');
    }

    setFocusModeTaskbox(updatedTaskbox);
    setDeadlineAnchorEl(null);
  };

  const handleClickInstanceTitle = async () => {
    if (!focusModeTaskbox) return;
    if (!focusModeTaskbox.project) return;

    if (!focusModeTaskbox) return;
    // 프로젝트 디테일창 열기 전에 태스크박스 수정사항 저장
    const { memo, category, ...updateSelectedTaskbox } = focusModeTaskbox;
    const start = focusModeTaskbox.start;
    const end = focusModeTaskbox.end;
    const categoryId = focusModeTaskbox.category ? [focusModeTaskbox.category.id] : [];

    const tasks =
      (focusModeTaskbox!.tasks!.map((task) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox.id!, { ...updateSelectedTaskbox, tasks, start, end, categoryId });

    const targetProject = projects.find((project) => project.id === focusModeTaskbox.project!.id);

    if (targetProject) {
      const targetInstance = targetProject.tasks.find((task) => task.id === focusModeTaskbox?.id);
      targetInstance!.tasks = focusModeTaskbox?.tasks;
      setFocusModeProject(targetProject);
    }
  };

  const handleCloseProjectDetail = () => {
    setFocusModeProject(null);
    fetchProjects();
    onFetch && onFetch();
  };

  /** 프로젝트 일부 완료하기 */
  const handleClickInstanceContinue = async () => {
    onClickInstanceContinue && onClickInstanceContinue(focusModeTaskbox);
    setInprogressAnchorEl(null);
  };

  const handleClickLoadSubtaskFromProject = () => {
    handleClickInstanceTitle();
    setIsLoadSubtaskFromProject(true);
  };

  return (
    <Dialog
      className="focus-mode-dialog"
      ref={open ? ref : null}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 1100 }}
      PaperProps={{
        ref: ref,
      }}
    >
      <DialogHeader>
        <div>
          <Tooltip
            title={
              <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0px' }}>
                <span>
                  {language === 'ko' ? '닫기' : 'Close'}
                  {/* Close */}
                </span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px',
                    backgroundColor: COLORS.gray100,
                    color: COLORS.gray500,
                    marginLeft: '8px',
                    padding: '0px 4px',
                  }}
                >
                  ESC
                </span>
              </div>
            }
            disableInteractive
          >
            <IconButton onClick={handleClose}>
              <Icons.Close width={22} height={22} stroke={COLORS.gray500} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
            title={
              <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0px' }}>
                <span>{language === 'ko' ? '포커스모드 최소화' : 'Minimize focus mode'}</span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px',
                    backgroundColor: COLORS.gray100,
                    color: COLORS.gray500,
                    marginLeft: '8px',
                    padding: '0px 4px',
                  }}
                >
                  Shift
                </span>
                <span
                  style={{
                    fontWeight: 700,
                    margin: '0px 2px',
                  }}
                >
                  +
                </span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px',
                    backgroundColor: COLORS.gray100,
                    color: COLORS.gray500,
                    padding: '0px 4px',
                  }}
                >
                  F
                </span>
              </div>
            }
            disableInteractive
          >
            <IconButton onClick={handleHide}>
              <Icons.UnfocusMode width={24} height={24} fill={COLORS.gray500} />
            </IconButton>
          </Tooltip> */}
        </div>
        {process.env.REACT_APP_MODE === 'development' && <Timer onChange={onChangeTimer} />}
        <IconButton
          onClick={(e) => {
            setContextMenuAnchor(e.currentTarget);
          }}
        >
          <Icons.More width={24} height={24} />
        </IconButton>
      </DialogHeader>
      <DialogContent>
        <TaskboxWrapper>
          <TaskboxHeader>
            {focusModeTaskbox && focusModeTaskbox.category ? (
              <Tooltip
                title={
                  language === 'ko' ? '카테고리 설정하기' : 'Set category'
                  // 'Set category'
                }
                disableInteractive
              >
                <CategoryShowingWrapper
                  textColor={getCategoryTextColor(focusModeTaskbox.category!.color)}
                  bgColor={getCategoryBgColor(focusModeTaskbox.category!.color)}
                  onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                >
                  {`# ${focusModeTaskbox.category.name}`}
                  <IconButton
                    className="category-detach-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickCategory(null, 'SELECT');
                    }}
                    sx={{
                      'width': '12px',
                      'height': '12px',
                      'borderRadius': '4px',
                      'marginLeft': '4px',
                      'marginTop': '1px',
                      'padding': '0px',
                      ':hover': {
                        backgroundColor: hexToRGBA(getCategoryTextColor(focusModeTaskbox.category!.color)!, 0.3),
                      },
                    }}
                    style={categoryAnchorEl ? { display: 'flex' } : {}}
                  >
                    <Icons.Close width={8} height={8} stroke={getCategoryTextColor(focusModeTaskbox.category!.color)} />
                  </IconButton>
                </CategoryShowingWrapper>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  language === 'ko' ? '카테고리 설정하기' : 'Set category'
                  // 'Set category'
                }
                disableInteractive
              >
                <IconButton
                  onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                  sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
                >
                  <Icons.Hashtag />
                </IconButton>
              </Tooltip>
            )}
            {focusModeTaskbox && focusModeTaskbox.deadline ? (
              <Tooltip
                title={
                  language === 'ko' ? '기한 설정하기' : 'Set deadline'
                  // 'Set deadline'
                }
                disableInteractive
              >
                <DeadlineShowingWrapper date={focusModeTaskbox.deadline} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
                  <Icons.Flag
                    fill={
                      dayjs(focusModeTaskbox.deadline).isToday()
                        ? COLORS.brand1
                        : dayjs(focusModeTaskbox.deadline).isBefore(dayjs())
                        ? COLORS.negative1
                        : COLORS.gray600
                    }
                  />
                  <span style={{ marginLeft: '2px' }}>
                    {dayjs(focusModeTaskbox.deadline).isToday()
                      ? language === 'ko'
                        ? '오늘'
                        : 'Today'
                      : dayjs(focusModeTaskbox.deadline).isYesterday()
                      ? language === 'ko'
                        ? '어제'
                        : 'Yesterday'
                      : dayjs(focusModeTaskbox.deadline).isTomorrow()
                      ? language === 'ko'
                        ? '내일'
                        : 'Tomorrow'
                      : language === 'ko'
                      ? dayjs(focusModeTaskbox.deadline).format('M월 D일 (dd)')
                      : dayjs(focusModeTaskbox.deadline).format('dddd, MM D')}
                    {/* {dayjs(focusModeTaskbox.deadline).isToday()
                      ? 'Today'
                      : dayjs(focusModeTaskbox.deadline).isYesterday()
                      ? 'Yesterday'
                      : dayjs(focusModeTaskbox.deadline).isTomorrow()
                      ? 'Tomorrow'
                      : dayjs(focusModeTaskbox.deadline).format('dddd, MM D')} */}
                  </span>
                  <IconButton
                    className="deadline-detach-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickDeadline(null);
                    }}
                    sx={{
                      'width': '12px',
                      'height': '12px',
                      'borderRadius': '4px',
                      'marginLeft': '4px',
                      'marginTop': '1px',
                      'padding': '0px',
                      ':hover': {
                        backgroundColor: hexToRGBA(
                          dayjs(focusModeTaskbox!.deadline).isToday()
                            ? COLORS.brand1
                            : dayjs(focusModeTaskbox!.deadline).isBefore(dayjs())
                            ? COLORS.negative1
                            : COLORS.gray600,
                          0.3,
                        ),
                      },
                    }}
                    style={deadlineAnchorEl ? { display: 'flex' } : {}}
                  >
                    <Icons.Close
                      width={8}
                      height={8}
                      stroke={
                        dayjs(focusModeTaskbox!.deadline).isToday()
                          ? COLORS.brand1
                          : dayjs(focusModeTaskbox!.deadline).isBefore(dayjs())
                          ? COLORS.negative1
                          : COLORS.gray600
                      }
                    />
                  </IconButton>
                </DeadlineShowingWrapper>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  language === 'ko' ? '기한 설정하기' : 'Set deadline'
                  // 'Set deadline'
                }
                disableInteractive
              >
                <IconButton
                  onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
                  sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
                >
                  <Icons.Flag />
                </IconButton>
              </Tooltip>
            )}
            {((focusModeTaskbox?.start?.datetime && focusModeTaskbox?.end?.datetime) || focusModeTaskbox?.durationMin) && (
              <ShowingTimeWrapper>
                <div onClick={(e) => setSimpleTimeAnchorEl(e.currentTarget)} style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Time stroke={COLORS.gray500} />
                  <ShowingTime>
                    {focusModeTaskbox?.durationMin ? showingTime(undefined, focusModeTaskbox?.durationMin) : showingTime(focusModeTaskbox)}
                  </ShowingTime>
                </div>
                <Tooltip
                  title={
                    language === 'ko' ? '시간 해제하기' : 'Remove time'
                    // 'Remove time'
                  }
                  disableInteractive
                >
                  <IconButton
                    className="unselect-time"
                    onClick={handleClickUnSelectTime}
                    sx={{ borderRadius: '8px', padding: '4px', height: '24px', width: '24px', marginLeft: '4px' }}
                  >
                    <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                  </IconButton>
                </Tooltip>
              </ShowingTimeWrapper>
            )}
          </TaskboxHeader>
          <TaskboxTitle style={{ opacity: focusModeTaskbox?.done ? 0.5 : 1 }}>
            {focusModeTaskbox!.tasks!.filter((task) => task.done).length > 0 && !focusModeTaskbox?.done ? (
              <Checkbox
                checked={false}
                icon={
                  focusModeTaskbox!.focus ? (
                    <Icons.FocusInprogressCheck width={28} height={28} />
                  ) : (
                    <Icons.InprogressCheck
                      width={28}
                      height={28}
                      fill={focusModeTaskbox!.project ? COLORS.issue2 : focusModeTaskbox!.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                    />
                  )
                }
                sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                style={{ width: 28, height: 28 }}
                onClick={(e) => setInprogressAnchorEl(e.currentTarget)}
              />
            ) : (
              <PriorityIssueCheckbox
                width={28}
                height={28}
                focus={focusModeTaskbox?.focus}
                bordercolor={focusModeTaskbox?.project ? COLORS.issue2 : focusModeTaskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                checked={!!focusModeTaskbox?.done}
                onClick={handleClickTaskboxCheck}
                style={{ marginTop: '2px' }}
              />
            )}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: 8 }}>
              {focusModeTaskbox?.project && (
                <div style={{ marginRight: 4 }}>
                  <Icons.Issue width={20} height={20} />
                </div>
              )}
              {focusModeTaskbox?.lockedIn && <div style={{ marginRight: '4px', textDecoration: 'none' }}>{focusModeTaskbox.done ? '✅' : '🌟'}</div>}
              {taskbox?.project ? (
                <Tooltip
                  autoFocus={false}
                  title={
                    language === 'ko' ? '프로젝트 상세보기' : 'View project details'
                    // 'View project details'
                  }
                  placement="bottom-start"
                  disableInteractive
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    defaultValue={focusModeTaskbox?.title}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      style: { fontSize: 24, fontWeight: 700, color: COLORS.gray900, padding: '0px', height: '30px', cursor: 'pointer' },
                    }}
                    onClick={handleClickInstanceTitle}
                    style={{ textDecoration: focusModeTaskbox?.done ? 'line-through' : 'none' }}
                  />
                </Tooltip>
              ) : (
                <TextField
                  inputRef={refInput}
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  onKeyDown={handleKeydownInput}
                  onChange={(e) => {
                    handleChangeTitle(e);
                  }}
                  defaultValue={focusModeTaskbox?.title}
                  InputProps={{ disableUnderline: true, style: { fontSize: 24, fontWeight: 700, color: COLORS.gray900, padding: '0px', height: '30px' } }}
                  style={{ textDecoration: focusModeTaskbox?.done ? 'line-through' : 'none' }}
                />
              )}
            </div>
          </TaskboxTitle>
          <div style={{ paddingLeft: '3px' }}>
            <TaskBlockInputList
              ref={refTaskBlockList}
              size="large"
              date={dayjs(focusModeTaskbox?.start?.date || focusModeTaskbox?.start?.datetime, { utc: true }).toDate()}
              done={focusModeTaskbox?.done}
              maxHeight={false}
              taskbox={focusModeTaskbox as OutTaskboxDetailResponse}
              project={projects.find((project) => project.id === focusModeTaskbox?.project?.id)}
              isFocus={focusModeTaskbox?.focus}
              isRecurrence={focusModeTaskbox?.isRecurrence}
              tasks={focusModeTaskbox?.tasks as TaskBlock[]}
              categoryList={categoryList}
              suppressVisibleTag
              suppressTaskboxCategoryDeadline
              suppressLoadSubtask={!focusModeTaskbox?.project}
              suppressTemplate={!!focusModeTaskbox?.project}
              suppressTaskMore={!!focusModeTaskbox?.project}
              suppressAddTime={!focusModeTaskbox?.allDay}
              onChange={handleChangeTasks}
              onLoadTemplate={handleLoadTemplate}
              onClickCategory={handleClickCategory}
              onChangeInstanceSubtask={onChangeInstanceSubtask}
              onPushProjectRecommendTask={onPushProjectRecommendTask}
              onClickLoadSubtaskFromProject={handleClickLoadSubtaskFromProject}
            />
          </div>
          <Divider sx={{ width: '100%', margin: '16px 0px' }} />
          <MemoWrapper className="focus-mode-memo">
            <RemirrorEditor
              id={focusModeTaskbox?.id || ''}
              data={focusModeTaskbox && focusModeTaskbox.memo ? (focusModeTaskbox.memo as RemirrorContentType) : undefined}
              onChangeData={handleChangeMemo}
            />
          </MemoWrapper>
          <Divider sx={{ width: '100%', margin: '16px 0px' }} />
          <LinkWrapper>
            {focusModeTaskbox?.links?.map((link, idx) => (
              <LinkContentWrapper data-link-id={link.id} key={link.id}>
                <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                  <Icons.Link2 />
                  {link.url === '' ? (
                    <TextField
                      data-link-input-id={link.id}
                      inputRef={createLinkInputRef}
                      fullWidth
                      autoFocus
                      placeholder={
                        language === 'ko' ? 'URL 주소(https://…)를 붙여넣고 Enter로 입력하기' : 'Paste a URL (https://...) and press Enter to submit'
                        // 'Paste a URL (https://...) and press Enter to submit'
                      }
                      onChange={(e) => {
                        setSelectedLink({ ...link, url: e.target.value, title: e.target.value });
                      }}
                      onBlur={handleSaveLink}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (e.nativeEvent.isComposing) return;
                          e.preventDefault();
                          handleSaveLink();
                        }
                      }}
                      inputProps={{
                        style: { padding: '0px', border: 'none' },
                      }}
                      sx={{ border: 'none', fontSize: '16px', marginLeft: '8px' }}
                    />
                  ) : (
                    <Tooltip title={link.url} disableInteractive>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          width: '100%',
                          fontSize: '16px',
                          marginLeft: '8px',
                          textDecoration: 'underline',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {link.title || link.url}
                      </a>
                    </Tooltip>
                  )}
                </div>
                <div
                  className="link-icon"
                  style={{
                    backgroundColor: COLORS.white,
                    border: `1px solid ${COLORS.gray200}`,
                    borderRadius: '8px',
                    padding: '2px',
                    position: 'absolute',
                    right: '8px',
                  }}
                >
                  <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                    <Icons.Duplicate stroke={COLORS.gray400} />
                  </IconButton>
                  <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleClickLinkUpdate(link)}>
                    <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
                  </IconButton>
                </div>
              </LinkContentWrapper>
            ))}
            <CreateLinkButton onClick={handleCreateLink}>
              <Icons.AddLink />
              <div style={{ fontSize: '16px', color: COLORS.gray500, marginLeft: '8px' }}>
                {language === 'ko' ? '링크 추가하기' : 'Add link'}
                {/* Add link */}
              </div>
            </CreateLinkButton>
          </LinkWrapper>
        </TaskboxWrapper>
      </DialogContent>
      {contextMenuAnchor && (
        <Popover
          open={Boolean(contextMenuAnchor)}
          anchorEl={contextMenuAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setContextMenuAnchor(null)}
        >
          <MenuList sx={{ padding: '8px', width: 'auto' }}>
            <MenuItem
              disableRipple
              sx={{ 'padding': '8px', 'borderRadius': '4px', ':hover': { backgroundColor: COLORS.gray100 } }}
              onClick={(e) => handleClickContextMenu(e, 'HIGHLIGHT')}
            >
              {focusModeTaskbox?.focus ? (
                <>
                  <Icons.UnfocusTime />
                  <MenuItemText>
                    {language === 'ko' ? '하이라이트풀기' : 'Unhighlight'}
                    {/* Unhighlight */}
                  </MenuItemText>
                </>
              ) : (
                <>
                  <Icons.FocusTime />
                  <MenuItemText>
                    {language === 'ko' ? '하이라이트하기' : 'Highlight'}
                    {/* Highlight */}
                  </MenuItemText>
                </>
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              sx={{ 'padding': '8px', 'borderRadius': '4px', ':hover': { backgroundColor: COLORS.gray100 } }}
              onClick={(e) => handleClickContextMenu(e, 'TIME')}
            >
              <Icons.TimerAdd width={16} height={16} stroke={COLORS.gray900} />
              <MenuItemText>
                {language === 'ko' ? '시간 설정' : 'Set date and time'}
                {/* Set date and time */}
              </MenuItemText>
            </MenuItem>
            <MenuItem
              disableRipple
              sx={{ 'padding': '8px', 'borderRadius': '4px', ':hover': { backgroundColor: COLORS.gray100 } }}
              onClick={(e) => handleClickContextMenu(e, 'CALENDAR')}
            >
              {focusModeTaskbox?.lockedIn ? (
                <>
                  <Icons.LockOut stroke={COLORS.gray900} />
                  <MenuItemText>
                    {language === 'ko' ? '구글캘린더에서 해제하기' : 'Remove from Google Calendar'}
                    {/* Remove from Google Calendar */}
                  </MenuItemText>
                </>
              ) : (
                <>
                  <Icons.LockIn stroke={COLORS.gray900} />
                  <MenuItemText>
                    {language === 'ko' ? '구글캘린더에 표시하기' : 'Display in Google Calendar'}
                    {/* Display in Google Calendar */}
                  </MenuItemText>
                </>
              )}
            </MenuItem>
            {focusModeTaskbox?.tasks?.length === 0 ? (
              <Tooltip
                title={
                  language === 'ko' ? '템플릿으로 저장할 하위업무가 없어요.' : 'No subtasks to save as a template.'
                  // 'No subtasks to save as a template.'
                }
                disableInteractive
              >
                <MenuItem
                  disableRipple
                  sx={{
                    'padding': '8px',
                    'borderRadius': '4px',
                    'cursor': 'default',
                    ':hover': { backgroundColor: COLORS.gray100 },
                  }}
                >
                  <Icons.Template />
                  <MenuItemText>
                    {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                    {/* Save as template */}
                  </MenuItemText>
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem
                disableRipple
                sx={{
                  'padding': '8px',
                  'borderRadius': '4px',
                  ':hover': { backgroundColor: COLORS.gray100 },
                }}
                onClick={(e) => handleClickContextMenu(e, 'TEMPLATE')}
              >
                <Icons.Template />
                <MenuItemText>
                  {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                  {/* Save as template */}
                </MenuItemText>
              </MenuItem>
            )}
            <MenuItem
              disableRipple
              sx={{ 'padding': '8px', 'borderRadius': '4px', ':hover': { backgroundColor: COLORS.gray100 } }}
              onClick={(e) => handleClickContextMenu(e, 'DELETE')}
            >
              <Icons.Delete fill={COLORS.negative1} />
              <MenuItemText style={{ color: COLORS.negative1 }}>
                {language === 'ko' ? '삭제' : 'Delete'}
                {/* Delete */}
              </MenuItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      )}
      {highlightAnchorEl && (
        <Popover
          open={Boolean(highlightAnchorEl)}
          anchorEl={highlightAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ marginTop: '4px' }}
          onClose={() => setHighlightAnchorEl(null)}
        >
          <HighlightTimeSelectionModal event={focusModeTaskbox} onClose={() => setHighlightAnchorEl(null)} onClick={handleClickHighlight} />
        </Popover>
      )}
      {timeAnchorEl && (
        <Popover
          open={Boolean(timeAnchorEl)}
          anchorEl={timeAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ marginTop: '4px' }}
          onClose={() => setTimeAnchorEl(null)}
        >
          <TimeDateSelectionModal
            event={focusModeTaskbox}
            onClose={() => setTimeAnchorEl(null)}
            onChangeTime={handleChangeTime}
            onChangeDate={handleChangeDate}
          />
        </Popover>
      )}
      {simpleTimeAnchorEl && (
        <Popover
          open={Boolean(simpleTimeAnchorEl)}
          anchorEl={simpleTimeAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ marginTop: '4px' }}
          onClose={() => setSimpleTimeAnchorEl(null)}
        >
          <SimpleTimeSelectionModal event={focusModeTaskbox} onClose={() => setSimpleTimeAnchorEl(null)} onChangeTime={handleChangeTime} />
        </Popover>
      )}
      {inprogressAnchorEl && (
        <Popover
          open={Boolean(inprogressAnchorEl)}
          anchorEl={inprogressAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => setInprogressAnchorEl(null)}
          sx={{ marginTop: '4px' }}
        >
          <InProgressTaskbox>
            <InProgressTaskboxContent
              onClick={() => {
                setInprogressAnchorEl(null);
                handleClickTaskboxCheck();
              }}
            >
              {focusModeTaskbox?.focus ? (
                <Icons.FocusCheck width={16} height={16} />
              ) : (
                <Icons.TaskboxCheck
                  width={16}
                  height={16}
                  fill={focusModeTaskbox?.project ? COLORS.issue2 : focusModeTaskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                />
              )}
              <span style={{ marginLeft: '8px' }}>
                {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                {/* Complete all */}
              </span>
            </InProgressTaskboxContent>
            {focusModeTaskbox?.project ? (
              <Tooltip
                title={
                  <span>
                    {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                    <br />
                    {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                  </span>
                  // <span>
                  //   Complete the instance and send any incomplete
                  //   <br />
                  //   subtasks to the next instance or project.
                  // </span>
                }
                placement="right"
              >
                <InProgressTaskboxContent onClick={handleClickInstanceContinue}>
                  <Icons.ContinueTaskbox fill={focusModeTaskbox?.project ? COLORS.issue2 : focusModeTaskbox.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                  <span style={{ marginLeft: '8px' }}>
                    {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                    {/* Complete some */}
                  </span>
                  <span style={{ marginLeft: '4px' }}>
                    <Icons.QuestionInfo />
                  </span>
                </InProgressTaskboxContent>
              </Tooltip>
            ) : (
              <InProgressTaskboxContent
                onClick={(e) => {
                  setInprogressSettingAnchorEl(e.currentTarget);
                }}
              >
                {focusModeTaskbox?.focus ? (
                  <Icons.ContinueFocusTaskbox />
                ) : (
                  <Icons.ContinueTaskbox fill={focusModeTaskbox?.project ? COLORS.issue2 : focusModeTaskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                )}
                <span style={{ marginLeft: '8px' }}>
                  {language === 'ko' ? '일부 완료하고 이어서하기' : 'Complete some and continue'}
                  {/* Complete some and continue */}
                </span>
              </InProgressTaskboxContent>
            )}
          </InProgressTaskbox>
        </Popover>
      )}
      {linkAnchorEl && (
        <Popover
          open={Boolean(linkAnchorEl)}
          anchorEl={linkAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleSaveLink}
          sx={{ marginLeft: '30px' }}
        >
          <LinkPopoverContainer>
            <LinkPopoverWrapper>
              <LinkURLWrapper>
                <div>
                  {language === 'ko' ? '링크 URL' : 'Link URL'}
                  {/* Link URL */}
                </div>
                <TextField
                  autoFocus
                  fullWidth
                  value={selectedLink?.url}
                  placeholder={
                    language === 'ko' ? '링크 URL 주소(https://…)를 입력해주세요.' : 'Please enter the link URL (https://...).'
                    // 'Please enter the link URL (https://...).'
                  }
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, url: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkURLWrapper>
              <LinkTitleWrapper>
                <div>
                  {language === 'ko' ? '링크 제목' : 'Link title'}
                  {/* Link title */}
                </div>
                <TextField
                  fullWidth
                  value={selectedLink?.title}
                  placeholder={
                    language === 'ko' ? '링크 제목을 입력해주세요.' : 'Please enter the link title.'
                    // 'Please enter the link title.'
                  }
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, title: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkTitleWrapper>
            </LinkPopoverWrapper>
            <Divider />
            <LinkDeleteWrapper>
              <Button
                onClick={handleDeleteLink}
                sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
              >
                <Icons.Delete fill={COLORS.negative1} />
                <span style={{ marginLeft: '8px' }}>
                  {language === 'ko' ? '링크 삭제' : 'Delete link'}
                  {/* Delete link */}
                </span>
              </Button>
            </LinkDeleteWrapper>
          </LinkPopoverContainer>
        </Popover>
      )}
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs(focusModeTaskbox?.start?.date || focusModeTaskbox?.start?.datetime).toDate()} onChangeDeadline={handleClickDeadline} />
        </Popover>
      )}
      <ProjectDetailView
        instance={focusModeTaskbox}
        selectedProject={focusModeProject || undefined}
        onClose={handleCloseProjectDetail}
        onFetch={fetchProjects}
        onDelete={onDeleteProject}
      />
      {inprogressSettingAnchorEl && (
        <TaskboxContinueDetail
          anchorEl={inprogressSettingAnchorEl}
          event={focusModeTaskbox}
          onCloseContinue={() => setInprogressSettingAnchorEl(null)}
          onChangeDate={handleChangeInprogress}
        />
      )}
      <FrequentWorkDialog
        open={loadTemplate}
        templateList={templateList}
        categoryList={categoryList}
        onClose={() => setLoadTemplate(false)}
        onPut={onPutTemplate}
        onDelete={onDeleteTemplate}
        onAdd={handleTemplateToTaskbox}
        onChange={onChangeTemplate}
        onClickCategory={onClickCategoryActions}
      />
      <ConfirmDialog
        ref={refConfirm}
        type={focusModeTaskbox?.project ? 'project' : focusModeTaskbox?.routine ? 'routine' : 'taskbox'}
        title={
          language === 'ko'
            ? focusModeTaskbox?.project
              ? '인스턴스 삭제'
              : focusModeTaskbox?.routine
              ? '루틴 삭제'
              : '태스크박스 삭제'
            : focusModeTaskbox?.project
            ? 'Delete instance'
            : focusModeTaskbox?.routine
            ? 'Delete routine'
            : 'Delete taskbox'
        }
        onConfirm={handleConfirm}
      >
        <div style={{ color: `${COLORS.negative1}`, fontWeight: 500, fontSize: '1.25rem', textAlign: 'center' }}>
          {language === 'ko'
            ? focusModeTaskbox?.project
              ? '인스턴스를 삭제하시겠어요?'
              : focusModeTaskbox?.routine
              ? '루틴을 삭제하시겠어요?'
              : '태스크박스를 삭제하시겠어요?'
            : focusModeTaskbox?.project
            ? 'Delete instance?'
            : focusModeTaskbox?.routine
            ? 'Delete routine?'
            : 'Delete taskbox?'}
        </div>
        <div style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center', fontSize: '1rem', marginTop: 16 }}>
          {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
        </div>
      </ConfirmDialog>
    </Dialog>
  );
};

export default FocusModeDialog;
