import { CSSProperties, HTMLAttributes, memo, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

const TagContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  font-size: 12px;
  color: ${COLORS.gray600};
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  cursor: pointer;
`;

const TaskTag = ({ style, children, ...props }: PropsWithChildren & { style?: CSSProperties } & HTMLAttributes<HTMLSpanElement>) => {
  return (
    <TagContainer style={style} {...props}>
      {children}
    </TagContainer>
  );
};

export default memo(TaskTag);
