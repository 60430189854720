import { SVGProps } from 'react';

export const People = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.781C3.28115 10.281 3.95942 10 4.66667 10H7.33333C8.04058 10 8.71885 10.281 9.21895 10.781C9.71905 11.2811 10 11.9594 10 12.6667V14"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 2.08594C11.2406 2.2328 11.749 2.5664 12.1121 3.03414C12.4752 3.50188 12.6722 4.07716 12.6722 4.66927C12.6722 5.26138 12.4752 5.83666 12.1121 6.3044C11.749 6.77214 11.2406 7.10574 10.667 7.2526"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.0016V12.6682C13.9966 12.0797 13.7986 11.5088 13.4368 11.0445C13.0751 10.5802 12.5699 10.2487 12 10.1016"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
