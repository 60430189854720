import { saveToken, removeTokens, saveWorkspaceToken, getToken } from 'utils/token';
import {
  createWorkspaceV1AuthWorkspacesPost,
  getUserMeV1UsersMeGet,
  listWorkspacesV1AuthWorkspacesGet,
  partialUpdateUserSettingV1UsersSettingPatch,
  retrieveUserSettingV1UsersSettingGet,
  userMeV1AuthMeGet,
} from 'queries';
import { saveItemToSession, WORKSPACE_ID } from 'utils/storage';
import { useAtom } from 'jotai';
import { meAtom, userAtom, workspacesAtom } from 'atoms/user';
import toast from 'react-hot-toast';
import { removeMeasured } from 'utils/measured';
import { timezoneSetterAtom } from 'atoms/timezone';
import { languageAtom } from 'atoms/language';
import { userSettingAtom } from 'atoms/userSetting';
import { partial } from 'lodash';

const useAuthAction = () => {
  const [me, setMe] = useAtom(meAtom);
  const [, setUser] = useAtom(userAtom);
  const [, setWorkspaces] = useAtom(workspacesAtom);
  const [, setTimezone] = useAtom(timezoneSetterAtom);
  const [, setLanguage] = useAtom(languageAtom);

  const isAuthenticated = () => me.name && !!getToken();
  const surveyTarget = localStorage.getItem('surveyTarget');
  const brandLanguage = localStorage.getItem('language-from-brand');

  const login = async (token: string) => {
    try {
      saveToken(token);
      const [me, workspaces] = await Promise.all([userMeV1AuthMeGet(), listWorkspacesV1AuthWorkspacesGet()]);
      setMe(me);
      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || '');
        setWorkspaces(workspaces);
        // if (workspaces[0].accessToken) {
        //   const user = await getUserMeV1UsersMeGet();
        //   if (user) setUser(user);
        //   const survey = await getSignupSurveyV1SurveysSignupGet();
        //   if (survey) setSurvey(survey);
        //   const userSetting = await retrieveUserSettingV1UsersSettingGet();
        //   if (!userSetting.timezone) {
        //     await partialUpdateUserSettingV1UsersSettingPatch({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        //   }

        //   user.isCalendarPicked || survey.isTarget
        //     ? syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet()
        //     : await syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet();
        // }
      } else {
        const success = await createWorkspaceV1AuthWorkspacesPost({ name: me.name!, isSignupSurveyTarget: surveyTarget === 'false' ? false : true });
        if (success) {
          toast.success(navigator.language.split('-')[0] === 'ko' ? '새로운 워크스페이스를 생성하였습니다.' : 'A new workspace has been created.');
          const workspaces = await listWorkspacesV1AuthWorkspacesGet();
          if (workspaces.length) {
            saveItemToSession(WORKSPACE_ID, workspaces[0].id);
            saveWorkspaceToken(workspaces[0].accessToken || '');
            setWorkspaces(workspaces);
            // if (workspaces[0].accessToken) {
            //   const user = await getUserMeV1UsersMeGet();
            //   if (user) setUser(user);
            //   const survey = await getSignupSurveyV1SurveysSignupGet();
            //   if (survey) setSurvey(survey);
            //   const userSetting = await retrieveUserSettingV1UsersSettingGet();
            //   if (!userSetting.timezone) {
            //     await partialUpdateUserSettingV1UsersSettingPatch({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
            //   }

            //   user.isCalendarPicked || survey.isTarget
            //     ? syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet()
            //     : await syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet();
            // }
          }
        } else toast.error(navigator.language.split('-')[0] === 'ko' ? '새로운 워크스페이스 생성에 실패하였습니다.' : 'Failed to create new workspace.');
      }
      const settings = await retrieveUserSettingV1UsersSettingGet();

      if (settings.timezone) {
        setTimezone(settings.timezone);
      }

      if (settings.language) {
        setLanguage(settings.language === 'kor' ? 'ko' : 'en');
      }

      if (brandLanguage) {
        const update = await partialUpdateUserSettingV1UsersSettingPatch({
          language: brandLanguage === 'ko' ? 'kor' : 'eng',
        });

        if (update) {
          setLanguage(brandLanguage === 'ko' ? 'ko' : 'en');
          localStorage.removeItem('language-from-brand');
        }
      }

      return true;
    } catch (e) {
      removeTokens();
      console.log(e);
      return false;
    }
  };

  const reLogin = async () => {
    try {
      const [me, workspaces] = await Promise.all([userMeV1AuthMeGet(), listWorkspacesV1AuthWorkspacesGet()]);
      setMe(me);

      const settings = await retrieveUserSettingV1UsersSettingGet();
      if (settings.timezone) {
        setTimezone(settings.timezone);
      }

      if (settings.language) {
        setLanguage(settings.language === 'kor' ? 'ko' : 'en');
      }

      if (brandLanguage) {
        const update = await partialUpdateUserSettingV1UsersSettingPatch({
          language: brandLanguage === 'ko' ? 'kor' : 'eng',
        });

        if (update) {
          setLanguage(brandLanguage === 'ko' ? 'ko' : 'en');
          localStorage.removeItem('language-from-brand');
        }
      }

      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || '');
        setWorkspaces(workspaces);
        if (workspaces[0].accessToken) {
          const user = getUserMeV1UsersMeGet();
          if (user) setUser(await user);
        }
      }

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const logout = (redirectTo = '') => {
    removeTokens();
    removeMeasured();
    redirectLogin(redirectTo);
  };

  const deleteAccount = (redirectTo = '') => {
    localStorage.clear();
    removeMeasured();
    redirectLogin(redirectTo);
  };

  const redirectLogin = (to = '') => window.location.replace(`${window.location.origin}${to ? to : ''}`);

  return {
    login,
    logout,
    deleteAccount,
    reLogin,
    redirectLogin,
    isAuthenticated,
  };
};

export { useAuthAction };
