import styled from '@emotion/styled';
import { Divider, TextField, Tooltip } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { projectsAtom } from 'atoms/projects';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { OutProject } from 'queries/model';
import React, { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';

export const CategoryAction = {
  CREATE: 'CREATE',
  SELECT: 'SELECT',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  DETACH: 'DETACH',
} as const;

export type CategoryActionType = typeof CategoryAction[keyof typeof CategoryAction];

interface ProjectListPopoverProps {
  onClick?: (project: OutProject) => void;
}

const ProjectListPopover = ({ onClick }: ProjectListPopoverProps) => {
  const [language] = useAtom(languageAtom);
  const [projects, setProjects] = useAtom(projectsAtom);
  const [drawerProjectList, setDrawerProjectList] = useState<OutProject[]>(projects.filter((p) => p.place === 'DESK'));
  const [filteredProjectList, setFilteredProjectListList] = useState<OutProject[]>([]);
  const [projectName, setProjectName] = useState<string>('');
  const [hoverId, setHoverId] = useState<string | null>(null);

  useEffect(() => {
    setDrawerProjectList(projects.filter((p) => p.place === 'DESK'));
  }, [projects]);

  const handleFilterProjectList = (value: string) => {
    if (value === '') {
      setFilteredProjectListList([]);
      setProjectName('');
      return;
    }

    setProjectName(value);
    const filtered = drawerProjectList.filter((p) => p.title.includes(value));
    setFilteredProjectListList(filtered);
  };

  const handleKeyDownTextField = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredProjectList.length > 0 ? setHoverId(filteredProjectList[0].id) : setHoverId(drawerProjectList[0].id);
      const el =
        filteredProjectList.length > 0
          ? (document.querySelector(`[data-project-id="${filteredProjectList[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-project-id="${drawerProjectList[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredProjectList.length > 0
        ? setHoverId(filteredProjectList[filteredProjectList.length - 1].id)
        : setHoverId(drawerProjectList[drawerProjectList.length - 1].id);
      const el =
        filteredProjectList.length > 0
          ? (document.querySelector(`[data-project-id="${filteredProjectList[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-project-id="${drawerProjectList[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }
  };

  const handleKeyDownProjectList = (e: React.KeyboardEvent, project: OutProject) => {
    if (e.key === 'Enter') {
      onClick && onClick(project);
    }

    if (e.key === 'ArrowDown') {
      const index =
        filteredProjectList.length > 0 ? filteredProjectList.findIndex((c) => c.id === hoverId) : drawerProjectList.findIndex((c) => c.id === hoverId);
      if (index === filteredProjectList.length - 1 || index === drawerProjectList.length - 1) return;

      const nextProject = filteredProjectList.length > 0 ? filteredProjectList[index + 1] : drawerProjectList[index + 1];
      setHoverId(nextProject.id);
      const el = document.querySelector(`[data-project-id="${nextProject.id}"]`) as HTMLElement;
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const index =
        filteredProjectList.length > 0 ? filteredProjectList.findIndex((c) => c.id === hoverId) : drawerProjectList.findIndex((c) => c.id === hoverId);
      if (index === 0) return;

      const nextProject = filteredProjectList.length > 0 ? filteredProjectList[index - 1] : drawerProjectList[index - 1];
      setHoverId(nextProject.id);
      const el = document.querySelector(`[data-project-id="${nextProject.id}"]`) as HTMLElement;
      el.focus();
      return;
    }
  };

  const handleClickProject = (project: OutProject) => {
    onClick && onClick(project);
  };

  return (
    <Container className="project-popover">
      <TextFieldWrapper>
        <TextField
          autoFocus
          autoComplete="off"
          fullWidth
          placeholder={language === 'ko' ? '프로젝트를 검색하거나 선택해주세요.' : 'Search or select a project.'}
          value={projectName}
          inputProps={{
            style: {
              padding: '0px',
              fontSize: '12px',
            },
          }}
          onChange={(e) => handleFilterProjectList(e.target.value)}
          onKeyDown={(e) => handleKeyDownTextField(e)}
        />
      </TextFieldWrapper>
      <Divider />
      <div style={{ padding: 8 }}>
        {filteredProjectList.length > 0
          ? filteredProjectList.map((v) =>
              v.title.length > 22 ? (
                <Tooltip title={v.title} disableInteractive>
                  <ProjectShowingWrapper
                    key={v.id}
                    data-project-id={v.id}
                    tabIndex={0}
                    isHover={hoverId === v.id}
                    onClick={() => handleClickProject(v)}
                    onKeyDown={(e) => handleKeyDownProjectList(e, v)}
                  >
                    <Icons.Issue />
                    <ProjectTitleWrapper>{v.title}</ProjectTitleWrapper>
                    <div>{v.title.length}</div>
                  </ProjectShowingWrapper>
                </Tooltip>
              ) : (
                <ProjectShowingWrapper
                  key={v.id}
                  data-project-id={v.id}
                  tabIndex={0}
                  isHover={hoverId === v.id}
                  onClick={() => handleClickProject(v)}
                  onKeyDown={(e) => handleKeyDownProjectList(e, v)}
                >
                  <Icons.Issue />
                  <ProjectTitleWrapper>{v.title}</ProjectTitleWrapper>
                </ProjectShowingWrapper>
              ),
            )
          : drawerProjectList.map((v) =>
              v.title.length > 22 ? (
                <Tooltip title={v.title} disableInteractive>
                  <ProjectShowingWrapper
                    key={v.id}
                    data-project-id={v.id}
                    tabIndex={0}
                    isHover={hoverId === v.id}
                    onClick={() => handleClickProject(v)}
                    onKeyDown={(e) => handleKeyDownProjectList(e, v)}
                  >
                    <Icons.Issue />
                    <ProjectTitleWrapper>{v.title}</ProjectTitleWrapper>
                  </ProjectShowingWrapper>
                </Tooltip>
              ) : (
                <ProjectShowingWrapper
                  key={v.id}
                  data-project-id={v.id}
                  tabIndex={0}
                  isHover={hoverId === v.id}
                  onClick={() => handleClickProject(v)}
                  onKeyDown={(e) => handleKeyDownProjectList(e, v)}
                >
                  <Icons.Issue />
                  <ProjectTitleWrapper>{v.title}</ProjectTitleWrapper>
                </ProjectShowingWrapper>
              ),
            )}
      </div>
    </Container>
  );
};

export default ProjectListPopover;

const Container = styled.div`
  min-width: 200px;
`;

const TextFieldWrapper = styled.div`
  height: 50px;
  padding: 16px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const ProjectShowingWrapper = styled.div<{ isHover?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
  }

  ${(props) =>
    props.isHover &&
    `
    background-color: ${COLORS.gray100};
  `}
`;

const ProjectTitleWrapper = styled.div`
  max-width: 186px;
  margin-left: 8px;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
