import { SVGProps } from 'react';

export const RadioChecked = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99967 10.333C7.9219 10.333 8.70812 10.0079 9.35834 9.35767C10.0081 8.7079 10.333 7.9219 10.333 6.99967C10.333 6.07745 10.0081 5.29123 9.35834 4.64101C8.70812 3.99123 7.9219 3.66634 6.99967 3.66634C6.07745 3.66634 5.29145 3.99123 4.64167 4.64101C3.99145 5.29123 3.66634 6.07745 3.66634 6.99967C3.66634 7.9219 3.99145 8.7079 4.64167 9.35767C5.29145 10.0079 6.07745 10.333 6.99967 10.333ZM6.99967 13.6663C6.07745 13.6663 5.21079 13.4912 4.39967 13.141C3.58856 12.7912 2.88301 12.3163 2.28301 11.7163C1.68301 11.1163 1.20812 10.4108 0.858341 9.59967C0.508119 8.78856 0.333008 7.9219 0.333008 6.99967C0.333008 6.07745 0.508119 5.21079 0.858341 4.39967C1.20812 3.58856 1.68301 2.88301 2.28301 2.28301C2.88301 1.68301 3.58856 1.2079 4.39967 0.857674C5.21079 0.507897 6.07745 0.333008 6.99967 0.333008C7.9219 0.333008 8.78856 0.507897 9.59967 0.857674C10.4108 1.2079 11.1163 1.68301 11.7163 2.28301C12.3163 2.88301 12.7912 3.58856 13.141 4.39967C13.4912 5.21079 13.6663 6.07745 13.6663 6.99967C13.6663 7.9219 13.4912 8.78856 13.141 9.59967C12.7912 10.4108 12.3163 11.1163 11.7163 11.7163C11.1163 12.3163 10.4108 12.7912 9.59967 13.141C8.78856 13.4912 7.9219 13.6663 6.99967 13.6663ZM6.99967 12.333C8.48856 12.333 9.74967 11.8163 10.783 10.783C11.8163 9.74967 12.333 8.48856 12.333 6.99967C12.333 5.51079 11.8163 4.24967 10.783 3.21634C9.74967 2.18301 8.48856 1.66634 6.99967 1.66634C5.51079 1.66634 4.24967 2.18301 3.21634 3.21634C2.18301 4.24967 1.66634 5.51079 1.66634 6.99967C1.66634 8.48856 2.18301 9.74967 3.21634 10.783C4.24967 11.8163 5.51079 12.333 6.99967 12.333Z"
        fill="#0039A7"
      />
    </svg>
  );
};
