import { atom } from 'jotai';
import { setDefaultTimeZone } from 'lib/dayjs';
import { setMomentTimeZone } from 'lib/moment';

// const timezoneAtom = atom(Intl.DateTimeFormat().resolvedOptions().timeZone);
export const timezoneAtom = atom('Asia/Seoul');

export const timezoneSetterAtom = atom(
  (get) => get(timezoneAtom),
  (get, set, newTimezone: string) => {
    setDefaultTimeZone(newTimezone);
    setMomentTimeZone(newTimezone);
    set(timezoneAtom, newTimezone);
  },
);
