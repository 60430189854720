import { SVGProps } from 'react';

export const RitualDoneEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M16.667 34.6317C15.2164 34.3026 13.8232 33.7583 12.5337 33.0167M7.63203 28.4917C6.72287 27.1708 6.03237 25.7121 5.58703 24.1717M5.20703 17.5033C5.4737 15.92 5.98703 14.42 6.70703 13.045L6.9887 12.5367M11.512 7.635C13.0715 6.5615 14.8209 5.79453 16.667 5.375"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3349 34.6317C24.7856 34.3026 26.1788 33.7583 27.4683 33.0167M34.7949 17.5033C34.5283 15.92 34.0149 14.42 33.2949 13.045L33.0133 12.5367M28.4899 7.635C26.9305 6.5615 25.1811 5.79453 23.3349 5.375"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 20.0052L18.3333 23.3385L25 16.6719" stroke="#ABB0BF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M31.166 24.1686C31.6473 23.5999 32.8068 22.9061 34 23.001C35.5201 23.1219 37.0717 23.9717 37 25.5003C37 27.6669 33.666 28.6669 33.666 28.6669V30.5004"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M33.666 34V34.0167" stroke="#ABB0BF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
