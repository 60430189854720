import { SVGProps } from 'react';

interface PriorityIssueUncheckProps extends SVGProps<SVGSVGElement> {
  markerColor?: string;
}

export const PriorityIssueUncheck = ({ markerColor, ...props }: PriorityIssueUncheckProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="9.4" stroke={props.stroke || '#0039A7'} strokeWidth="1.2" />
      <path d="M5 10.625L8.33333 13.75L15 7.5" stroke={markerColor || '#E2ECFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
