import { SVGProps } from 'react';

export const Home = ({
  width = 32,
  height = 32,
  fill = '#1F2023',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1748_496)">
        <path
          d="M26.455 11.0581L18.4555 4.84188C17.7535 4.29624 16.8894 4 16 4C15.1106 4 14.2465 4.29624 13.5445 4.84188L5.5435 11.0581C5.06267 11.4317 4.67364 11.9101 4.40613 12.4569C4.13863 13.0036 3.99971 13.6042 4 14.2127V25.0028C4 25.7977 4.31607 26.56 4.87868 27.1221C5.44129 27.6842 6.20435 28 7 28H25C25.7957 28 26.5587 27.6842 27.1213 27.1221C27.6839 26.56 28 25.7977 28 25.0028V14.2127C28 12.9794 27.43 11.8149 26.455 11.0581Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1748_496">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
