import { SVGProps } from 'react';

export const TaskboxCheck = ({
  width = 24,
  height = 24,
  fill = '#C8CDDB',
  opacity = 1,
  ...props
}: {
  width?: number;
  height?: number;
  fill?: string;
  opacity?: number;
  props?: SVGProps<SVGSVGElement>;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={opacity} {...props}>
      <circle cx="12" cy="12" r="11.25" fill={fill ? fill : '#C8CDDB'} />
      <path d="M6 12.75L10 16.5L18 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
