import { SVGProps } from 'react';

export const MobileSurveyArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="162" viewBox="0 0 14 162" fill="none" {...props}>
      <path
        d="M7 0.996094L7 161M7 161L13 155M7 161L1 155"
        stroke="url(#paint0_linear_19269_273537)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_19269_273537" x1="1" y1="160.848" x2="1" y2="0.996101" gradientUnits="userSpaceOnUse">
          <stop stopColor="#80CAFF" />
          <stop offset="1" stopColor="#2D6ADF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
