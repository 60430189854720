import { debounce } from 'lodash';
import { useCallback } from 'react';

export const useDebouncedSync = <T>(setFunction: (value: T) => void, delay: number) => {
  return useCallback(
    debounce((value: T) => {
      setFunction(value);
    }, delay),
    [setFunction, delay],
  );
};
