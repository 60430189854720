import { SVGProps } from 'react';

export const Subtask = ({ stroke = '#858994', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.00521 4.0026H7.00521M1.67188 1.33594H4.33854M3.00521 1.33594V8.66927C3.00521 8.84608 3.07545 9.01565 3.20047 9.14068C3.32549 9.2657 3.49506 9.33594 3.67188 9.33594H7.00521M7.00521 3.33594C7.00521 3.15913 7.07545 2.98956 7.20047 2.86453C7.3255 2.73951 7.49506 2.66927 7.67188 2.66927H11.6719C11.8487 2.66927 12.0183 2.73951 12.1433 2.86453C12.2683 2.98956 12.3385 3.15913 12.3385 3.33594V4.66927C12.3385 4.84608 12.2683 5.01565 12.1433 5.14068C12.0183 5.2657 11.8487 5.33594 11.6719 5.33594H7.67188C7.49506 5.33594 7.3255 5.2657 7.20047 5.14068C7.07545 5.01565 7.00521 4.84608 7.00521 4.66927V3.33594ZM7.00521 8.66927C7.00521 8.49246 7.07545 8.32289 7.20047 8.19787C7.3255 8.07284 7.49506 8.0026 7.67188 8.0026H11.6719C11.8487 8.0026 12.0183 8.07284 12.1433 8.19787C12.2683 8.32289 12.3385 8.49246 12.3385 8.66927V10.0026C12.3385 10.1794 12.2683 10.349 12.1433 10.474C12.0183 10.599 11.8487 10.6693 11.6719 10.6693H7.67188C7.49506 10.6693 7.3255 10.599 7.20047 10.474C7.07545 10.349 7.00521 10.1794 7.00521 10.0026V8.66927Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
