import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { COLORS } from 'styles/constants';

export type SuggestionType = {
  content: {
    request: string;
    satisfaction: string;
    email: string;
  }[];
};

interface SuggestionProps {
  data: SuggestionType;
  submit?: boolean;
  onChange?: (suggestion: SuggestionType) => void;
  onSubmit?: () => void;
  onClose?: () => void;
}

const SuggestionDialog = ({ data, submit = false, onChange, onSubmit, onClose }: SuggestionProps) => {
  const [suggestion, setSuggestion] = useState(data);
  const [emailError, setEmailError] = useState(false);
  const refEmailInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSuggestion(data);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (submit) handleClickClose();
    }, 2000);
  }, [submit]);

  const handleChange = (suggestion: { content: { request: string; satisfaction: string; email: string }[] }) => {
    onChange && onChange(suggestion);
  };

  const handleClickSubmit = () => {
    if (suggestion.content[0].email && !validateEmail(suggestion.content[0].email)) {
      setEmailError(true);
      refEmailInput.current?.focus();
      return;
    }

    onSubmit && onSubmit();
  };

  const handleClickClose = () => {
    onClose && onClose();
  };

  const validateEmail = (value = '') => RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm').test(value);

  const { t } = useTranslation();

  return !submit ? (
    <Container>
      <SuggestionHeader>
        <SuggestionHeaderTitle>SLASH - {t('suggestion.title')}</SuggestionHeaderTitle>
        <ButtonWrapper>
          <Button
            variant="outlined"
            onClick={handleClickClose}
            sx={{
              'width': '55px',
              'height': '40px',
              'minWidth': 'none',
              'border': `1px solid ${COLORS.gray200}`,
              'borderRadius': '8px',
              'color': COLORS.gray900,
              'fontSize': '13px',
              'fontWeight': 700,
              'marginRight': '8px',
              ':hover': {
                border: `1px solid ${COLORS.gray200}`,
              },
            }}
          >
            {t('suggestion.common.cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={suggestion.content[0].request === ''}
            onClick={handleClickSubmit}
            sx={{ width: '77px', height: '40px', borderRadius: '8px', boxShadow: 'none', fontSize: '13px', fontWeight: 700 }}
          >
            {t('suggestion.common.submit')}
          </Button>
        </ButtonWrapper>
      </SuggestionHeader>
      <SuggestionContentWrapper>
        <SuggestionContent>
          <SuggestionContentTitle>
            <div style={{ marginRight: 8 }}>
              <Trans
                i18nKey={'suggestion.question1.title'}
                components={{
                  1: <span style={{ color: COLORS.sub2 }} />,
                }}
              />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <circle cx="8" cy="8" r="8" fill="#F2F5FC" />
              <path
                d="M8.36118 11H7.35872L7.44717 8.71499L5.50123 9.93857L5 9.0688L7.0344 8.00737L5 6.9312L5.50123 6.06143L7.44717 7.28501L7.35872 5H8.36118L8.27273 7.28501L10.2187 6.06143L10.7199 6.9312L8.70025 8.00737L10.7199 9.0688L10.2187 9.93857L8.27273 8.71499L8.36118 11Z"
                fill="#1F2023"
              />
            </svg>
          </SuggestionContentTitle>
          <TextField
            multiline
            fullWidth
            minRows={2}
            placeholder={t('suggestion.question1.placeholder')}
            onChange={(e) => {
              handleChange({
                content: [
                  {
                    ...suggestion.content[0],
                    request: e.target.value,
                  },
                ],
              });
            }}
            sx={{ fontSize: '12px', marginTop: '12px' }}
          />
        </SuggestionContent>
        <SuggestionContent>
          <SuggestionContentTitle>
            <Trans
              i18nKey={'suggestion.question2.title'}
              components={{
                1: <span style={{ color: COLORS.sub2, marginLeft: 4 }} />,
              }}
            />
          </SuggestionContentTitle>
          <TextField
            multiline
            fullWidth
            minRows={2}
            placeholder={t('suggestion.question2.placeholder')}
            onChange={(e) => {
              handleChange({
                content: [
                  {
                    ...suggestion.content[0],
                    satisfaction: e.target.value,
                  },
                ],
              });
            }}
            sx={{ fontSize: '12px', marginTop: '12px' }}
          />
        </SuggestionContent>
        <SuggestionContent>
          <SuggestionContentTitle>
            <span>{t('suggestion.question3.title')}</span>
          </SuggestionContentTitle>
          <TextField
            inputRef={refEmailInput}
            fullWidth
            placeholder="abc@slash.com"
            error={emailError}
            helperText={emailError && t('suggestion.email.validation')}
            onChange={(e) => {
              handleChange({
                content: [
                  {
                    ...suggestion.content[0],
                    email: e.target.value,
                  },
                ],
              });
            }}
            sx={{ fontSize: '12px', marginTop: '12px' }}
          />
        </SuggestionContent>
      </SuggestionContentWrapper>
    </Container>
  ) : (
    <Container style={{ width: 456, height: 356, padding: '0px' }}>
      <div
        style={{
          width: 60,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: COLORS.sub3,
          borderRadius: '50%',
          fontSize: 32,
          marginBottom: 40,
        }}
      >
        👍
      </div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 8,
        }}
      >
        {t('suggestion.appreciation.title')}
      </div>
      <div
        style={{
          fontSize: 16,
        }}
      >
        {t('suggestion.appreciation.description')}
      </div>
    </Container>
  );
};

export default SuggestionDialog;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.white};
  padding: 32px 80px 80px 80px;
`;

const SuggestionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SuggestionHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const ButtonWrapper = styled.div``;

const SuggestionContentWrapper = styled.div``;

const SuggestionContent = styled.div`
  margin-top: 32px;
`;

const SuggestionContentTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
`;
