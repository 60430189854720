import { SVGProps } from 'react';

export const AddLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_19846_144142)">
        <path
          d="M6.66797 9.33098C6.88522 9.55271 7.14453 9.72887 7.43072 9.84912C7.71691 9.96938 8.02421 10.0313 8.33464 10.0313C8.64506 10.0313 8.95237 9.96938 9.23855 9.84912C9.52474 9.72887 9.78405 9.55271 10.0013 9.33098L12.668 6.66431C13.11 6.22229 13.3583 5.62277 13.3583 4.99765C13.3583 4.37253 13.11 3.77301 12.668 3.33098C12.2259 2.88895 11.6264 2.64063 11.0013 2.64062C10.3762 2.64062 9.77666 2.88895 9.33464 3.33098L9.0013 3.66431"
          stroke="#858994"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33489 6.66909C9.11763 6.44736 8.85832 6.27121 8.57214 6.15095C8.28595 6.03069 7.97865 5.96875 7.66822 5.96875C7.3578 5.96875 7.05049 6.03069 6.7643 6.15095C6.47812 6.27121 6.21881 6.44736 6.00155 6.66909L3.33489 9.33576C2.89286 9.77778 2.64453 10.3773 2.64453 11.0024C2.64453 11.6275 2.89286 12.2271 3.33489 12.6691C3.77691 13.1111 4.37643 13.3594 5.00155 13.3594C5.62667 13.3594 6.22619 13.1111 6.66822 12.6691L7.00155 12.3358"
          stroke="#858994"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle cx="12" cy="11" r="3.625" fill="#858994" stroke="white" strokeWidth="0.75" />
      <path d="M12 9V13" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
      <path d="M14 11L10 11" stroke="white" strokeWidth="0.75" strokeLinecap="round" />
      <defs>
        <clipPath id="clip0_19846_144142">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
