import React, { forwardRef, useImperativeHandle, useRef, useState, CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Popover, TextField, CheckboxProps, Tooltip, IconButton } from '@mui/material';
import { CreateTaskbox, OutCategory, OutProject, OutRoutine, OutTaskboxDetail, OutTaskboxDetailResponse } from 'queries/model';
import { COLORS } from 'styles/constants';
import TaskBlockInputList, { ProjectTaskBlockAction, TaskBlock, TaskBlockHandle } from './TaskBlockInputList';
import { useClickOutside, useUpdateEffect } from '@react-hookz/web';
import TaskboxCalendar, { TaskboxCalendarMenuItem } from './TaskboxCalendar';
import dayjs, { Dayjs } from 'lib/dayjs';
import { Icons } from 'components';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { loadSubtaskFromProjectAtom, projectsAtom } from 'atoms/projects';
import { useAtom } from 'jotai';
import { DeadlinePopover } from './DeadlinePopover';
import ProjectListPopover from './ProjectListPopover';
import { MultiSelectSubtaskIds } from '../Today';
import { InboxContextMenuType } from 'components/InboxContextMenu';
import { changedRoutineInstanceIdAtom, routinesAtom } from 'atoms/routine';
import { isEqual } from 'lodash';
import Fonts from 'components/Fonts';
import { languageAtom } from 'atoms/language';
const TaskboxCreationContainer = styled.div<{ focus?: boolean }>`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 0px 20px 16px 0px;
  border: ${(props) => props.focus && '1px solid var(--brand1)'};

  .taskbox-title {
    :hover {
      color: ${COLORS.issue2};
    }
  }

  /* &:focus-within {
    border: 1px solid var(--brand1);
    color: var(--gray-900);
  } */
`;

const TaskListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const TaskboxCompletionWrapper = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const TaskboxCompletionButton = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const InstanceTitleWrapper = styled.div<{ done?: boolean }>`
  width: fit-content;
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 27px;

  ${(props) =>
    props.done &&
    `
    opacity: 0.5;
  `}

  :hover {
    color: #68387f;
    .unlink-project {
      display: block;
    }
  }
`;

const InstanceTitle = styled.div<{ isProject?: boolean; isRoutine?: boolean }>`
  color: ${COLORS.gray700};
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  margin-left: 10px;
  :hover {
    ${(props) => props.isProject && `color: ${COLORS.issue1};`}
    ${(props) => props.isRoutine && `color: ${COLORS.sub4};`}
  }
`;

const UpdateRoutineInstanceWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 20px;
  padding: 4px 8px;
  cursor: pointer;
  :hover {
    border: 1px solid ${COLORS.gray200};

    .apply-changes-btn {
      opacity: 1;
    }
  }
`;

export const TaskboxCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} sx={{ padding: 0, margin: 0, ...props.sx }} style={{ width: 20, height: 20, ...props.style }} />;
};

export type TaskboxCreationType = 'current' | 'today' | 'tomorrow' | 'anotherDay' | 'custom';

export interface TaskboxCreationImperativeHandle {
  focus?: () => void;
}

export interface TaskboxCreationViewProps {
  date?: Date | null;
  taskbox:
    | (CreateTaskbox & {
        done?: boolean;
        isRecurrence?: boolean;
        focus?: boolean;
        project?: OutProject;
        category?: OutCategory;
        routine?: OutRoutine;
        allDay?: boolean;
      })
    | undefined;
  categoryList?: OutCategory[];
  cancelable?: boolean;
  schedulable?: boolean;
  multiSelectSubtaskIds?: MultiSelectSubtaskIds[];
  multiSelectSubtaskAnchorEl?: HTMLElement | null;
  onChange?: (taskbox: CreateTaskbox | undefined) => void;
  onContinueOnAnotherDay?: (taskbox: (CreateTaskbox & { done?: boolean }) | undefined, date: Date) => void;
  onClickInstanceContinue?: (taskbox: (CreateTaskbox & { done?: boolean }) | undefined) => void;
  onClickCancel?: () => void;
  onClickSave?: (taskbox: CreateTaskbox, type: TaskboxCreationType, custom?: { start: string; end: string }) => void;
  onLoadTemplate?: () => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType) => void;
  onClickInstanceTitle?: (taskbox: CreateTaskbox & { project?: OutProject }) => void;
  onChangeInstanceSubtask?: (block: TaskBlock, action: ProjectTaskBlockAction) => void;
  onPushProjectRecommendTask?: (tasks: TaskBlock[], blockId: string, index: number) => void;
  onChangeDeadline?: (deadlineDate: Dayjs | null) => void;
  onClickProjectList?: (project: OutProject) => void;
  onClickTaskboxTitle?: () => void;
  onClickRoutineTitle?: (taskbox: CreateTaskbox & { routine?: OutRoutine }) => void;
  onChangeMultiSelectSubtaskIds?: (subtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => void;
  onClickMultiSelectSubtaskAction?: (type: InboxContextMenuType, data: any) => void;
  onCloseMultiSelectSubtask?: () => void;
  onMultiSelectSubtaskAnchorElNull?: () => void;
  onReflectRoutine?: () => void;
  onUnlinkProject?: (taskbox: CreateTaskbox & { project?: OutProject }) => void;
  onSetTaskboxTime?: () => void;
  onSetTaskboxCategory?: () => void;
  onSetTaskboxDeadline?: () => void;
  style?: CSSProperties;
}

const TaskboxCreationView = (
  {
    date,
    taskbox,
    categoryList,
    multiSelectSubtaskIds,
    multiSelectSubtaskAnchorEl,
    onChange,
    onContinueOnAnotherDay,
    onClickInstanceContinue,
    onLoadTemplate,
    onClickCategoryActions,
    onClickInstanceTitle,
    onClickRoutineTitle,
    onChangeInstanceSubtask,
    onPushProjectRecommendTask,
    onChangeDeadline,
    onClickProjectList,
    onClickTaskboxTitle,
    onChangeMultiSelectSubtaskIds,
    onClickMultiSelectSubtaskAction,
    onCloseMultiSelectSubtask,
    onMultiSelectSubtaskAnchorElNull,
    onReflectRoutine,
    onUnlinkProject,
    onSetTaskboxTime,
    onSetTaskboxCategory,
    onSetTaskboxDeadline,
    style,
  }: TaskboxCreationViewProps,
  ref: any,
) => {
  const refTaskboxInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [taskboxCompletionPopover, setTaskboxCompletionPopover] = useState<HTMLElement | null>(null);
  const [taskboxCalendarPopover, setTaskboxCalendarPopover] = useState<HTMLElement | null>(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [projects] = useAtom(projectsAtom);
  const [routines] = useAtom(routinesAtom);
  const [changedRoutineInstanceId, setChangedRoutineInstanceId] = useAtom(changedRoutineInstanceIdAtom);
  const [language] = useAtom(languageAtom);

  useUpdateEffect(() => {
    if (taskbox?.project && refTaskboxInput.current) {
      refTaskboxInput.current.focus();
    }
  }, [taskbox?.title]);

  useClickOutside(refContainer, () => {
    setFocused(false);
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      refTaskboxInput?.current?.focus();
    },
  }));

  const updateTaskbox = (taskbox: (CreateTaskbox & { done?: boolean }) | undefined) => {
    onChange && onChange(taskbox);
  };

  const handleChangeTaskBlocks = (tasks: { id: string; content?: string; done?: boolean; category?: OutCategory; deadline?: string | null }[] = []) => {
    let taskboxDone = taskbox?.done;
    if (tasks.filter((v: any) => v.done === true).length === tasks.length || tasks.filter((v: any) => v.done === false).length === tasks.length) {
      taskboxDone = !!tasks[0]?.done;
      updateTaskbox({
        ...taskbox,
        title: taskbox!.title!,
        tasks: tasks.map((v: any) => ({
          id: v.id,
          content: v.content || '',
          done: taskboxDone,
          category: v.category,
          deadline: v.deadline,
        })),
        done: taskboxDone,
      });
    } else {
      if (tasks.length >= 2) {
        taskboxDone = false;
      }
      updateTaskbox({
        ...taskbox,
        title: taskbox!.title!,
        tasks: tasks.map((v: any) => ({
          id: v.id,
          content: v.content || '',
          done: v.done,
          category: v.category,
          deadline: v.deadline,
        })),
        done: taskboxDone,
      });
    }

    if (tasks.length === 0) setTimeout(() => refTaskboxInput?.current?.focus(), 50);
  };

  const handleTaskboxKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      if (taskbox?.title || taskbox?.tasks?.length) return;

      e.preventDefault();
      refTaskboxInput?.current?.blur();
      setFocused(false);
      updateTaskbox(undefined);
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      if (taskbox?.project && taskbox?.title === '') {
        updateTaskbox({ ...taskbox, title: taskbox?.project?.title });
        setTimeout(() => {
          refTaskBlockList?.current?.add();
        }, 100);
        return;
      }

      refTaskBlockList?.current?.add();
    }

    // if (e.key === 'Backspace' && (taskbox?.title?.length === 1 || taskbox?.title === taskbox?.project?.title)) {
    //   console.log(refTaskboxInput?.current);
    //   refTaskboxInput?.current?.focus();
    // }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refTaskboxInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refTaskboxInput.current);
      }, 100);
    }

    if (e.key === '>' && !taskbox?.isRecurrence && !taskbox?.routine) {
      setTimeout(() => {
        setProjectListAnchorEl(refTaskboxInput.current);
      }, 100);
    }
  };

  const handleChangeDone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const done = e.currentTarget.checked;
    updateTaskbox({
      ...taskbox,
      title: taskbox!.title!,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: done })),
      done: done,
    });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateTaskbox({ ...taskbox, title: e.target.value });
  };

  const handleBlurTitle = () => {
    if (taskbox?.project && taskbox?.title === '') {
      updateTaskbox({ ...taskbox, title: taskbox?.project?.title });
    }
  };

  const handleCloseTaskboxCompletionPopover = () => {
    setTaskboxCompletionPopover(null);
  };

  const handleClickTaskboxCompletion = () => {
    if (!taskbox) return;
    setTaskboxCompletionPopover(null);
    updateTaskbox({
      ...taskbox,
      title: taskbox.title!,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: true })),
      done: true,
    });
  };

  const handleCloseTaskboxCalendarPopover = () => {
    setTaskboxCalendarPopover(null);
  };

  const handleClickContinueOnAnotherDay = (date: Date) => {
    setTaskboxCompletionPopover(null);
    setTaskboxCalendarPopover(null);
    onContinueOnAnotherDay && onContinueOnAnotherDay(taskbox, date);
  };

  const isInprogress = taskbox?.tasks && taskbox.tasks?.filter((v: any) => v.done).length > 0 && !taskbox?.done;
  const checkboxColor = taskbox?.project ? COLORS.issue2 : taskbox?.isRecurrence || taskbox?.routine ? COLORS.sub4 : COLORS.brand1;

  const handleClickInstanceTitle = () => {
    if (!taskbox) return;
    if (!taskbox.project) return;

    onClickInstanceTitle && onClickInstanceTitle(taskbox);
  };

  const handleClickRoutineTitle = () => {
    if (!taskbox) return;
    if (!taskbox.routine) return;

    onClickRoutineTitle && onClickRoutineTitle(taskbox);
  };

  const handleClickInstanceContinue = () => {
    onClickInstanceContinue && onClickInstanceContinue(taskbox);
  };

  const handleClickCategoryActions = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      if (taskbox?.title?.includes('#')) {
        taskbox.title = taskbox.title.replace(/#/g, '');
      }
      setCategoryAnchorEl(null);
    }

    if (action === 'DELETE' && taskbox?.category?.id && category?.id === taskbox?.category.id) {
      if (taskbox?.title?.includes('#')) {
        taskbox.title = taskbox.title.replace(/#/g, '');
      }
      setCategoryAnchorEl(null);
    }
    onClickCategoryActions && onClickCategoryActions(category, action);
  };

  const handleChangeDeadline = (deadlineDate: Dayjs | null) => {
    if (taskbox?.title?.includes('$')) {
      taskbox.title = taskbox.title.replace(/\$/g, '');
    }
    onChangeDeadline && onChangeDeadline(deadlineDate);
    setTimeout(() => setDeadlineAnchorEl(null), 100);
  };

  const [isLoadSubtaskFromProject, setIsLoadSubtaskFromProject] = useAtom(loadSubtaskFromProjectAtom);
  const handleClickLoadSubtaskFromProject = () => {
    setIsLoadSubtaskFromProject(true);
    handleClickInstanceTitle();
  };

  const [projectListAnchorEl, setProjectListAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickProjectList = (project: OutProject) => {
    setProjectListAnchorEl(null);
    onClickProjectList && onClickProjectList(project);
  };

  const handleReflectRoutine = () => {
    onReflectRoutine && onReflectRoutine();
  };

  const handleUnlinkProject = () => {
    if (!taskbox) return;
    if (taskbox.project) onUnlinkProject && onUnlinkProject(taskbox);
  };

  const isSameRoutineMaster = (() => {
    if (!taskbox) return;
    const routine = routines.find((routine) => routine.id === taskbox.routine?.id);
    if (!taskbox.routine || !routine) return false;

    const { lastMasterStart, lastMasterEnd } = routine;

    // start가 date 형식이면 두 event도 date 형식이어야 함
    if (lastMasterStart?.date) return !!taskbox.start?.date;

    // datetime 비교
    if (lastMasterStart?.datetime) {
      if (taskbox.start?.date) return false;

      // 날짜를 무시하고 시간과 분만 비교
      const startHourMinuteSame =
        dayjs(taskbox.start?.datetime).hour() === dayjs(lastMasterStart.datetime).hour() &&
        dayjs(taskbox.start?.datetime).minute() === dayjs(lastMasterStart.datetime).minute();

      const endHourMinuteSame =
        dayjs(taskbox.end?.datetime).hour() === dayjs(lastMasterEnd?.datetime).hour() &&
        dayjs(taskbox.end?.datetime).minute() === dayjs(lastMasterEnd?.datetime).minute();

      return startHourMinuteSame && endHourMinuteSame;
    }

    return true;
  })();

  const handleSetTaskboxTime = () => {
    onSetTaskboxTime && onSetTaskboxTime();
  };

  const handleSetTaskboxCategory = () => {
    onSetTaskboxCategory && onSetTaskboxCategory();
  };

  const handleSetTaskboxDeadline = () => {
    onSetTaskboxDeadline && onSetTaskboxDeadline();
  };

  return (
    <TaskboxCreationContainer ref={refContainer} onFocusCapture={() => setFocused(true)} focus={focused} style={style}>
      {taskbox?.project && taskbox?.project.title !== taskbox?.title && (
        <InstanceTitleWrapper
          done={taskbox?.done}
          onClick={(e) => {
            if (!e.metaKey && !e.ctrlKey) {
              handleClickInstanceTitle();
              setIsLoadSubtaskFromProject(false);
            }
          }}
        >
          <Icons.Issue />
          <InstanceTitle isProject>{taskbox?.project.title}</InstanceTitle>
          <Tooltip
            title={
              <div>
                <div style={{ fontWeight: 700 }}>프로젝트와 연결 해지하기</div>
                <div>프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다</div>
              </div>
            }
            disableInteractive
          >
            <IconButton
              className="unlink-project"
              onClick={(e) => {
                e.stopPropagation();
                handleUnlinkProject();
              }}
              sx={{
                'width': '24px',
                'height': '24px',
                'borderRadius': '4px',
                'marginLeft': '4px',
                'padding': '4px',
                'display': 'none',
                ':hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              <Icons.UnlinkProject />
            </IconButton>
          </Tooltip>
        </InstanceTitleWrapper>
      )}
      {taskbox?.routine && taskbox?.routine.title !== taskbox?.title && (
        <InstanceTitleWrapper
          done={taskbox?.done}
          onClick={(e) => {
            if (!e.metaKey && !e.ctrlKey) handleClickRoutineTitle();
          }}
        >
          <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
          <InstanceTitle isRoutine>{taskbox?.routine.title}</InstanceTitle>
        </InstanceTitleWrapper>
      )}
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 27 }}>
        {isInprogress ? (
          <Checkbox
            checked={false}
            icon={taskbox?.focus ? <Icons.FocusInprogressCheck /> : <Icons.InprogressCheck fill={checkboxColor} />}
            sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            style={{ width: 20, height: 20, marginRight: 8 }}
            onClick={(e) => setTaskboxCompletionPopover(e.currentTarget)}
          />
        ) : (
          <TaskboxCheckbox
            icon={
              taskbox?.focus ? (
                <Icons.FocusUncheck />
              ) : (
                <Icons.TaskboxUncheck
                  circle={checkboxColor}
                  marker={taskbox?.project ? '#F3DAFE' : taskbox?.isRecurrence || taskbox?.routine ? '#E9F9FA' : '#E2ECFF'}
                />
              )
            }
            checkedIcon={taskbox?.focus ? <Icons.FocusCheck opacity={0.5} /> : <Icons.TaskboxCheck fill={checkboxColor} opacity={0.5} />}
            checked={!!taskbox?.done}
            onChange={handleChangeDone}
            style={{ marginRight: 8 }}
          />
        )}
        {taskbox?.project && taskbox?.project.title === taskbox.title && (
          <div style={{ marginRight: 4, opacity: taskbox.done ? 0.5 : 1 }}>
            <Icons.Issue />
          </div>
        )}
        {taskbox?.routine && taskbox?.routine.title === taskbox.title && (
          <div style={{ marginRight: 4, opacity: taskbox.done ? 0.5 : 1 }}>
            <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
          </div>
        )}
        {taskbox?.lockedIn && <span style={{ marginRight: 4 }}>{taskbox.done ? '✅' : '🌟'}</span>}
        <TextField
          inputRef={refTaskboxInput}
          autoComplete="off"
          fullWidth
          variant="standard"
          placeholder={taskbox?.routine ? taskbox.routine.title : taskbox?.project ? taskbox.project.title : '태스크박스 이름을 입력해주세요.'}
          value={taskbox?.title || ''}
          onChange={handleChangeTitle}
          onKeyDown={handleTaskboxKeydown}
          onBlur={handleBlurTitle}
          InputProps={{
            disableUnderline: true,
            style: taskbox?.done
              ? { fontWeight: 'bold', color: COLORS.gray500, textDecoration: 'line-through' }
              : { fontWeight: 'bold', color: COLORS.gray900, cursor: taskbox?.project && taskbox?.project.title === taskbox.title ? 'pointer' : 'default' },
          }}
          sx={{
            '& .MuiInputBase-input': {
              'cursor': 'text',
              ':hover':
                taskbox?.project && taskbox?.project.title === taskbox.title
                  ? {
                      color: COLORS.issue1,
                    }
                  : taskbox?.routine && taskbox?.routine.title === taskbox.title
                  ? {
                      color: COLORS.sub4,
                    }
                  : {},
            },
          }}
          onClick={() => {
            if (taskbox?.routine && taskbox?.routine.title === taskbox.title) {
              handleClickRoutineTitle();
            } else if (taskbox?.project && taskbox?.project.title === taskbox.title) {
              setIsLoadSubtaskFromProject(false);
              handleClickInstanceTitle();
            } else if (!taskbox?.project && !taskbox?.routine) {
              onClickTaskboxTitle && onClickTaskboxTitle();
            }
          }}
        />
      </div>
      <TaskListWrapper>
        <TaskBlockInputList
          ref={refTaskBlockList}
          date={date}
          done={taskbox?.done}
          taskbox={taskbox as OutTaskboxDetailResponse}
          tasks={(taskbox?.tasks as TaskBlock[]) || []}
          categoryList={categoryList}
          suppressVisibleTag
          suppressLoadSubtask={!taskbox?.project}
          suppressTemplate={!!taskbox?.project}
          suppressTaskMore={!!taskbox?.project}
          suppressAddTime={!taskbox?.allDay}
          project={projects.find((v) => v.id === taskbox?.project?.id)}
          isRecurrence={taskbox?.isRecurrence || !!taskbox?.routine}
          isFocus={taskbox?.focus}
          multiSelectSubtaskIds={multiSelectSubtaskIds}
          multiSelectSubtaskAnchorEl={multiSelectSubtaskAnchorEl}
          onChange={handleChangeTaskBlocks}
          onLoadTemplate={onLoadTemplate}
          onClickCategory={onClickCategoryActions}
          onChangeInstanceSubtask={onChangeInstanceSubtask}
          onPushProjectRecommendTask={onPushProjectRecommendTask}
          onClickLoadSubtaskFromProject={handleClickLoadSubtaskFromProject}
          onChangeMultiSelectSubtaskIds={onChangeMultiSelectSubtaskIds}
          onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
          onCloseMultiSelectSubtask={onCloseMultiSelectSubtask}
          onMultiSelectSubtaskAnchorElNull={onMultiSelectSubtaskAnchorElNull}
          onSetTaskboxTime={handleSetTaskboxTime}
          onSetTaskboxCategory={handleSetTaskboxCategory}
          onSetTaskboxDeadline={handleSetTaskboxDeadline}
        />
      </TaskListWrapper>
      {taskbox?.routine && changedRoutineInstanceId === taskbox.id && (
        <UpdateRoutineInstanceWrapper
          onClick={(e) => {
            handleReflectRoutine();
          }}
        >
          <Icons.UpdateRecurrence />
          <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>이후 인스턴스에 변경 일정 반영하기</Fonts.Body2>
          <IconButton
            className="apply-changes-btn"
            onClick={(e) => {
              e.stopPropagation();
              setChangedRoutineInstanceId(null);
            }}
            sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px', opacity: 0 }}
          >
            <Icons.Close width={12} height={12} stroke={COLORS.gray300} />
          </IconButton>
        </UpdateRoutineInstanceWrapper>
      )}
      {taskboxCompletionPopover && (
        <Popover
          open={Boolean(taskboxCompletionPopover)}
          anchorEl={taskboxCompletionPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskboxCompletionPopover}
        >
          <TaskboxCompletionWrapper>
            <TaskboxCompletionButton onClick={handleClickTaskboxCompletion}>
              <Icons.TaskboxCheck width={16} height={16} fill={checkboxColor} />
              <span style={{ marginLeft: '8px' }}>
                {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                {/* Complete all */}
              </span>
            </TaskboxCompletionButton>
            {taskbox?.project ? (
              <Tooltip
                title={
                  <span>
                    {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                    <br />
                    {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                  </span>
                  // <span>
                  //   Complete the instance and send any incomplete
                  //   <br />
                  //   subtasks to the next instance or project.
                  // </span>
                }
                placement="right"
              >
                <TaskboxCompletionButton onClick={handleClickInstanceContinue}>
                  <Icons.ContinueTaskbox fill={checkboxColor} />
                  <span style={{ marginLeft: '8px' }}>
                    {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                    {/* Complete some */}
                  </span>
                  <span style={{ marginLeft: '4px' }}>
                    <Icons.QuestionInfo />
                  </span>
                </TaskboxCompletionButton>
              </Tooltip>
            ) : (
              <TaskboxCompletionButton onClick={(e) => setTaskboxCalendarPopover(e.currentTarget)}>
                <Icons.ContinueTaskbox fill={checkboxColor} />
                <span style={{ marginLeft: '8px' }}>
                  {language === 'ko' ? '일부 완료하고 이어서하기' : 'Complete some and continue'}
                  {/* Complete some and continue */}
                </span>
              </TaskboxCompletionButton>
            )}
          </TaskboxCompletionWrapper>
        </Popover>
      )}
      {taskboxCalendarPopover && (
        <Popover
          open={Boolean(taskboxCalendarPopover)}
          anchorEl={taskboxCalendarPopover}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskboxCalendarPopover}
        >
          <TaskboxCalendar currentDate={date} onChange={(date) => handleClickContinueOnAnotherDay(dayjs(date).toDate())}>
            <TaskboxCalendarMenuItem
              icon={<Icons.Today width={16} height={16} />}
              label="오늘 중"
              onClick={() => handleClickContinueOnAnotherDay(dayjs(date).toDate())}
            />
            <TaskboxCalendarMenuItem
              icon={<Icons.Tomorrow />}
              label="다음 날"
              onClick={() => handleClickContinueOnAnotherDay(dayjs(date).add(1, 'day').toDate())}
            />
            <TaskboxCalendarMenuItem
              icon={<Icons.NextMonday />}
              label="다음주 월요일"
              onClick={() => handleClickContinueOnAnotherDay(dayjs(date).day(8).toDate())}
            />
          </TaskboxCalendar>
        </Popover>
      )}
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
        </Popover>
      )}
      {/* 태스크박스 프로적트 리스트 팝오버 */}
      <Popover
        open={Boolean(projectListAnchorEl)}
        anchorEl={projectListAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setProjectListAnchorEl(null);
        }}
        sx={{ marginTop: '4px' }}
      >
        <ProjectListPopover onClick={handleClickProjectList} />
      </Popover>
    </TaskboxCreationContainer>
  );
};

export default forwardRef<TaskboxCreationImperativeHandle, TaskboxCreationViewProps>(TaskboxCreationView);
