import { SVGProps } from 'react';

export const TaskGroup = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 8L7 10L11 6" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="2.75" y="2.75" width="10.5" height="10.5" stroke="#1F2023" strokeWidth="1.5" fill="transparent" />
      <circle cx="2.5" cy="2.5" r="1.5" fill="#1F2023" />
      <circle cx="13.5" cy="2.5" r="1.5" fill="#1F2023" />
      <circle cx="13.5" cy="13.5" r="1.5" fill="#1F2023" />
      <circle cx="2.5" cy="13.5" r="1.5" fill="#1F2023" />
    </svg>
  );
};
