import { SVGProps } from 'react';

export const IssueLinkage = ({ width = 14, height = 14, fill = '#1F2023' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6663 12C11.233 12 10.8441 11.8751 10.4997 11.6253C10.1552 11.3751 9.91634 11.0556 9.78301 10.6667H6.33301C5.59967 10.6667 4.9719 10.4056 4.44967 9.88333C3.92745 9.36111 3.66634 8.73333 3.66634 8C3.66634 7.26667 3.92745 6.63889 4.44967 6.11667C4.9719 5.59444 5.59967 5.33333 6.33301 5.33333H7.66634C8.03301 5.33333 8.34701 5.20267 8.60834 4.94133C8.86923 4.68044 8.99967 4.36667 8.99967 4C8.99967 3.63333 8.86923 3.31933 8.60834 3.058C8.34701 2.79711 8.03301 2.66667 7.66634 2.66667H4.21634C4.0719 3.05556 3.83034 3.37511 3.49167 3.62533C3.15256 3.87511 2.76634 4 2.33301 4C1.77745 4 1.30523 3.80556 0.916341 3.41667C0.527452 3.02778 0.333008 2.55556 0.333008 2C0.333008 1.44444 0.527452 0.972222 0.916341 0.583333C1.30523 0.194444 1.77745 0 2.33301 0C2.76634 0 3.15256 0.124889 3.49167 0.374667C3.83034 0.624889 4.0719 0.944444 4.21634 1.33333H7.66634C8.39967 1.33333 9.02745 1.59444 9.54967 2.11667C10.0719 2.63889 10.333 3.26667 10.333 4C10.333 4.73333 10.0719 5.36111 9.54967 5.88333C9.02745 6.40556 8.39967 6.66667 7.66634 6.66667H6.33301C5.96634 6.66667 5.65256 6.79711 5.39167 7.058C5.13034 7.31933 4.99967 7.63333 4.99967 8C4.99967 8.36667 5.13034 8.68067 5.39167 8.942C5.65256 9.20289 5.96634 9.33333 6.33301 9.33333H9.78301C9.92745 8.94444 10.1692 8.62489 10.5083 8.37467C10.847 8.12489 11.233 8 11.6663 8C12.2219 8 12.6941 8.19444 13.083 8.58333C13.4719 8.97222 13.6663 9.44444 13.6663 10C13.6663 10.5556 13.4719 11.0278 13.083 11.4167C12.6941 11.8056 12.2219 12 11.6663 12ZM2.33301 2.66667C2.5219 2.66667 2.68034 2.60289 2.80834 2.47533C2.9359 2.34733 2.99967 2.18889 2.99967 2C2.99967 1.81111 2.9359 1.65267 2.80834 1.52467C2.68034 1.39711 2.5219 1.33333 2.33301 1.33333C2.14412 1.33333 1.98567 1.39711 1.85767 1.52467C1.73012 1.65267 1.66634 1.81111 1.66634 2C1.66634 2.18889 1.73012 2.34733 1.85767 2.47533C1.98567 2.60289 2.14412 2.66667 2.33301 2.66667Z"
        fill="#ABB0BF"
      />
    </svg>
  );
};
