import { SVGProps } from 'react';

export const ShortcutWithText = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="16" height="16" rx="8" fill="#858994" />
      <path
        d="M4 10.7143L6.14286 5L8.28571 10.7143M7.53571 8.71429H4.75M9.25 7.57143C9.46768 7.05911 9.98214 6.71429 10.5714 6.71429C11.3929 6.71429 12 7.28571 12 8.14286V10.7143"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14258 9.69531C9.14258 10.3382 9.62222 10.731 10.214 10.731C11.1783 10.731 11.9997 10.2489 11.9997 8.83817V8.57031C11.6426 8.57031 10.964 8.58817 10.3569 8.6596C9.77169 8.72853 9.14258 8.99888 9.14258 9.69531Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
