import { SVGProps } from 'react';

export const ArrowRightLong = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2201_10333)">
        <path d="M6.6665 16H25.3332" stroke={props.stroke || '#1F2023'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3335 24L25.3335 16" stroke={props.stroke || '#1F2023'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3335 8L25.3335 16" stroke={props.stroke || '#1F2023'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
