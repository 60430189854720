import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from '../../styles/constants';
import { Search } from '../Icons';

interface SearchboxProps {
  size?: number;
  complete?: boolean;
  disabled?: boolean;
  focus?: boolean;
  label: string;
  value: string;
  onChange: () => void;
  onClick: () => void;
  handleFocus: () => void;
}

export const Searchbox = ({
  size = 240,
  complete = false,
  disabled = false,
  focus = false,
  label,
  value,
  onChange, // input 박스에 글자 입력시 발동
  onClick, // search 버튼 누를시 작동.
  handleFocus, // input 누를 시 focus 변경시키는 함수
}: SearchboxProps) => {
  return (
    <Wrapper size={size} focus={focus} disabled={disabled}>
      <Input
        name="search-input"
        type="text"
        onChange={onChange}
        onClick={handleFocus}
        placeholder={label}
        value={value}
        complete={complete}
        disabled={disabled}
      />
      <Button type="submit" onClick={onClick} disabled={disabled}>
        {disabled ? <Search fill={COLORS.gray500} /> : <Search />}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  size: number;
  focus: boolean;
  disabled: boolean;
}>`
  width: ${(props) => props.size}px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.gray200};
  padding-left: 16px;
  padding-right: 12px;

  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      background-color: ${COLORS.gray100};
      color: ${COLORS.gray200};
    `}
`;

const Input = styled.input<{
  complete: boolean;
  disabled: boolean;
}>`
  width: 180px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  border: none;
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  outline: none !important;
  &:focus {
    color: ${COLORS.gray900};
  }
  ${(props) =>
    props.complete &&
    css`
      &::placeholder {
        color: ${COLORS.gray900};
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      background-color: ${COLORS.gray100};
    `}
`;

const Button = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-contents: center;
  padding: 0px;
  border: none;
  background-color: ${COLORS.white};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      background-color: ${COLORS.gray100};
    `}
`;
