import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';

const UncheckedIcon = <Icons.IssueUncheck width={20} height={20} />;
const CheckedIcon = <Icons.IssueCheck width={20} height={20} />;

export interface IssueCheckboxProps extends CheckboxProps {}

const IssueCheckbox = (props: IssueCheckboxProps) => {
  return (
    <Checkbox
      {...props}
      icon={UncheckedIcon}
      checkedIcon={CheckedIcon}
      sx={{ padding: 0, margin: 0, ...props.sx }}
      style={{ width: 16, height: 16, ...props.style }}
    />
  );
};
export default IssueCheckbox;
