import { Icons } from 'components';
import { useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

interface Props {
  children: JSX.Element;
}
export const InnerAlert = ({ children }: Props) => {
  const [visible, setVisible] = useState(true);
  if (visible) {
    return (
      <Wrapper>
        <div>{children}</div>
        <div onClick={() => setVisible(false)} style={{ cursor: 'pointer' }}>
          <Icons.Close width={12} height={12} />
        </div>
      </Wrapper>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.gray100};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
`;
