import styled from '@emotion/styled';
import { Button, Checkbox, Divider, IconButton, MenuItem, Select, SelectChangeEvent, Skeleton, Switch, Tooltip } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { languageAtom } from 'atoms/language';
import { userAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { getUserMeV1UsersMeGet } from 'queries';
import { InUpdateCalendar, OutCalendar, OutStateStorage, OutUserDetail, TaskViewEnum, UserSettingModel } from 'queries/model';
import { SVGProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';

const AntSwitch = styled(Switch)(({ theme }) => ({
  'width': 28,
  'height': 16,
  'padding': 0,
  'display': 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    '&.Mui-checked': {
      'transform': 'translateX(12px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: COLORS.brand1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: 'width 0.2s',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: COLORS.gray300,
    boxSizing: 'border-box',
  },
}));

interface CalendarProps {
  reload?: boolean;
  setting?: UserSettingModel;
  userState?: OutStateStorage;
  googleCalendarList?: OutCalendar[];
  onChange?: (calendarData: UserSettingModel) => void;
  onChangeGoogleCalendar?: (calendarId: string, updateCalendar: InUpdateCalendar, sync?: boolean) => void;
  onClickReloadCalendar?: () => void;
  onChangeBoardViewMode?: (view: TaskViewEnum) => void;
}

const TaskCheckIcon = ({ stroke = '#ABB0BF' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke={stroke} />
  </svg>
);

const TaskCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="4" fill={props?.fill || COLORS.brand1} />
    <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const handleLoginGoogle = useGoogleLogin(options);
  return (
    <Button
      variant="contained"
      onClick={() => handleLoginGoogle()}
      sx={{
        width: '288px',
        height: '48px',
        borderRadius: '8px',
        boxShadow: 'none',
        color: COLORS.white,
        fontSize: '13px',
        fontWeight: 700,
        padding: '14px 16px',
        marginLeft: '16px',
        textTransform: 'none',
      }}
    >
      <Icons.GoogleCalendar style={{ marginRight: '4px' }} />
      {label}
    </Button>
  );
};

const CalendarSetting = ({
  setting,
  userState,
  reload = false,
  googleCalendarList = [],
  onChange,
  onChangeGoogleCalendar,
  onClickReloadCalendar,
  onChangeBoardViewMode,
}: CalendarProps) => {
  const [language] = useAtom(languageAtom);
  const [user] = useAtom(userAtom);
  const [lockIn, setLockIn] = useState(setting?.isTaskOnGoogleCalendar);
  const [highlight, setHighlight] = useState(setting?.isFocusTaskOnGoogleCalendar);
  const [startDay, setStartDay] = useState(setting?.startOfWeekDayInt);
  const [boardView, setBoardView] = useState(userState?.data.taskViewMode);
  const [userData, setUserData] = useState<OutUserDetail>();

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google/calendar`,
    state: userData?.id,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setUserData(user ? user : await getUserMeV1UsersMeGet());
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (setting) {
      setLockIn(setting.isTaskOnGoogleCalendar);
      setHighlight(setting.isFocusTaskOnGoogleCalendar);
      setStartDay(setting.startOfWeekDayInt);
    }
  }, [setting]);

  useEffect(() => {
    if (userState) {
      setBoardView(userState.data.taskViewMode);
    }
  }, [userState]);

  const handleChangeLockIn = () => {
    setLockIn(!lockIn);
    onChange?.({ ...setting, isTaskOnGoogleCalendar: !lockIn, isFocusTaskOnGoogleCalendar: true });
  };

  const handleChangeHighlight = () => {
    setHighlight(!highlight);
    onChange?.({ ...setting, isFocusTaskOnGoogleCalendar: !highlight });
  };

  const handleChangeStartDay = (event: SelectChangeEvent) => {
    setStartDay(event.target.value === 'SU' ? 0 : 1);
    onChange?.({ ...setting, startOfWeekDayInt: event.target.value === 'SU' ? 0 : 1 });
  };

  const handleChangeDefaultGoogleCalendar = (calendar: OutCalendar) => {
    if (calendar.accessRole !== 'owner') return;
    const updateCalendar: InUpdateCalendar = {
      shown: !calendar.default ? true : calendar.shown,
      default: !calendar.default,
    };
    onChangeGoogleCalendar?.(calendar.id, updateCalendar);
  };

  const handleChangeGoogleCalendarShown = (e: React.ChangeEvent<HTMLInputElement>, calendar: OutCalendar) => {
    if (calendar.default) return;
    const updateCalendar: InUpdateCalendar = {
      shown: e.target.checked,
      default: calendar.default,
    };
    onChangeGoogleCalendar?.(calendar.id, updateCalendar, e.target.checked);
  };

  const handleClickReloadCalendar = () => {
    onClickReloadCalendar?.();
  };

  const handleChangeBoardView = (view: TaskViewEnum) => {
    if (view !== userState?.data.taskViewMode) {
      setBoardView(view);
      onChangeBoardViewMode?.(view);
    }
  };

  const { t } = useTranslation();

  return (
    <Container>
      <CalendarBoardSettingWrapper style={{ paddingTop: '28px' }}>
        <TitleWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <Title>{t('settings.calendar.header')}</Title>
        </TitleWrapper>
        {googleCalendarList.length === 0 ? (
          <ConnectViewControlWrapper>
            <ConnectContentWrapper>
              <ConnectTitleWrapper>
                <Icons.CalendarWithoutClock width={20} height={20} style={{ marginRight: 8 }} />
                <div>
                  <ConnectTitle>{t('calendar.connect.header')}</ConnectTitle>
                  <ConnectSubTitle>{t('calendar.connect.description')}</ConnectSubTitle>
                </div>
              </ConnectTitleWrapper>
              <img
                src={require(`assets/images/connectCalendar.png`)}
                alt="google-calendar"
                style={{
                  width: '100%',
                  height: '350px',
                  margin: '20px 0px 32px 0px',
                }}
              />
              <ButtonWrapper>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <GoogleLogInButton options={googleLoginOptions} label={t('calendar.connect.complete')} />
                </GoogleOAuthProvider>
              </ButtonWrapper>
            </ConnectContentWrapper>
          </ConnectViewControlWrapper>
        ) : (
          <SettingContentScrollWrapper>
            <SettingContentWrapper>
              <SettingContent>
                <SettingContentTextWrapper style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  {reload ? (
                    <Skeleton animation="wave" sx={{ height: '20px' }} />
                  ) : (
                    <GoogleCalenderSettingHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M15 4.15625H5.00004C4.07957 4.15625 3.33337 4.90244 3.33337 5.82292V15.8229C3.33337 16.7434 4.07957 17.4896 5.00004 17.4896H15C15.9205 17.4896 16.6667 16.7434 16.6667 15.8229V5.82292C16.6667 4.90244 15.9205 4.15625 15 4.15625Z"
                            stroke="#1F2023"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M13.3334 2.5V5.83333" stroke="#1F2023" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.66748 2.5V5.83333" stroke="#1F2023" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                          <rect x="5.625" y="8.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="8.125" y="8.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="10.625" y="8.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="13.125" y="8.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="5.625" y="11.25" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="8.125" y="11.25" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="10.625" y="11.25" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="13.125" y="11.25" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="5.625" y="13.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="8.125" y="13.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="10.625" y="13.75" width="1.25" height="1.25" fill="#1F2023" />
                          <rect x="13.125" y="13.75" width="1.25" height="1.25" fill="#1F2023" />
                        </svg>
                        <div style={{ fontSize: '13px', fontWeight: 700, marginLeft: '8px' }}>{t('settings.calendar.section1_title')}</div>
                      </div>
                      <IconButton onClick={handleClickReloadCalendar} sx={{ padding: '0px' }}>
                        <Icons.Reload />
                      </IconButton>
                    </GoogleCalenderSettingHeader>
                  )}
                  <GoogleCalendarSettingContent>
                    {reload ? (
                      <Skeleton animation="wave" sx={{ height: '19.5px' }} />
                    ) : (
                      <GoogleCalendarPrimaryWrapper style={{ display: 'flex', alignItems: 'center' }}>
                        <Icons.GoogleCalendar />
                        <span style={{ marginLeft: '10px' }}>{googleCalendarList.find((calendar) => calendar.primary)?.summary}</span>
                      </GoogleCalendarPrimaryWrapper>
                    )}

                    <Divider sx={{ margin: '8px 0px' }} />
                    {googleCalendarList.map((calendar) =>
                      reload ? (
                        <Skeleton key={calendar.id} animation="wave" sx={{ height: '30px' }} />
                      ) : (
                        <GoogleCalendarContentWrapper key={calendar.id}>
                          <Checkbox
                            checked={calendar.shown}
                            icon={<TaskCheckIcon stroke={calendar.backgroundColor} />}
                            checkedIcon={<TaskCheckedIcon fill={calendar.backgroundColor} />}
                            onChange={(e) => handleChangeGoogleCalendarShown(e, calendar)}
                            sx={{
                              padding: '0px',
                              marginRight: '8px',
                            }}
                          />
                          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div key={calendar.id}>{calendar.summary}</div>
                            {calendar.default ? (
                              <div
                                style={{
                                  height: '20px',
                                  backgroundColor: COLORS.sub3,
                                  borderRadius: '6px',
                                  color: COLORS.brand1,
                                  fontSize: '10px',
                                  padding: '4px 10px',
                                }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div style={{ width: '4px', height: '4px', borderRadius: '50%', backgroundColor: COLORS.brand1, marginRight: '4px' }} />
                                  <span style={{ lineHeight: '11px' }}>{t('settings.calendar.section1_default')}</span>
                                </div>
                              </div>
                            ) : (
                              <Tooltip
                                title={calendar.accessRole === 'owner' ? `${t('settings.calendar.tooltip1')}` : `${t('settings.calendar.tooltip2')}`}
                                disableInteractive
                              >
                                <DefaultCalendarButtonWrapper className="calendar-button-wrapper">
                                  <Button
                                    onClick={() => handleChangeDefaultGoogleCalendar(calendar)}
                                    sx={{
                                      'height': '20px',
                                      'borderRadius': '6px',
                                      'color': COLORS.gray500,
                                      'fontSize': '10px',
                                      'padding': '4px 10px',
                                      ':hover': {
                                        'backgroundColor': calendar.accessRole === 'owner' ? COLORS.gray200 : 'transparent',
                                        'color': calendar.accessRole === 'owner' ? COLORS.brand1 : COLORS.gray400,
                                        '& .calendar-button-dot': {
                                          backgroundColor: calendar.accessRole === 'owner' ? COLORS.brand1 : 'transparent',
                                          border: calendar.accessRole === 'owner' ? 'none' : `1px solid ${COLORS.gray400}`,
                                        },
                                      },
                                    }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div className="calendar-button-dot" style={{ width: '4px', height: '4px', borderRadius: '50%', marginRight: '4px' }} />
                                      <span>{t('settings.calendar.sectiong1_make_default')}</span>
                                    </div>
                                  </Button>
                                </DefaultCalendarButtonWrapper>
                              </Tooltip>
                            )}
                          </div>
                        </GoogleCalendarContentWrapper>
                      ),
                    )}
                  </GoogleCalendarSettingContent>
                </SettingContentTextWrapper>
              </SettingContent>
              <SettingContent>
                <SettingContentTextWrapper>
                  {lockIn ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M13.332 2.5V5.83333M6.66539 2.5V5.83333M4.9987 4.16504H14.9987C15.9192 4.16504 16.6654 4.91123 16.6654 5.83171V15.8317C16.6654 16.7522 15.9192 17.4984 14.9987 17.4984H4.9987C4.07822 17.4984 3.33203 16.7522 3.33203 15.8317V5.83171C3.33203 4.91123 4.07822 4.16504 4.9987 4.16504Z"
                        stroke="#1F2023"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.31149 10.4162C6.19195 10.3057 6.25688 10.1059 6.41857 10.0867L8.70963 9.81499C8.77553 9.80718 8.83277 9.76581 8.86056 9.70556L9.8269 7.61089C9.89509 7.46306 10.1053 7.46303 10.1735 7.61086L11.1398 9.70551C11.1676 9.76576 11.2245 9.80725 11.2904 9.81506L13.5816 10.0867C13.7432 10.1059 13.808 10.3057 13.6885 10.4163L11.9948 11.9826C11.9461 12.0276 11.9244 12.0947 11.9373 12.1597L12.3868 14.4223C12.4186 14.5819 12.2486 14.7057 12.1065 14.6261L10.0934 13.4991C10.0355 13.4667 9.96511 13.4669 9.90721 13.4993L7.89385 14.6259C7.75177 14.7054 7.5815 14.5819 7.61323 14.4223L8.06279 12.1599C8.07572 12.0948 8.05409 12.0276 8.00537 11.9826L6.31149 10.4162Z"
                        fill="#1F2023"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M13.332 2.5V5.83333M6.66539 2.5C6.66539 2.5 6.66539 4.53159 6.66539 5.83333M4.9987 4.16504H14.9987C15.9192 4.16504 16.6654 4.91123 16.6654 5.83171V15.8317C16.6654 16.7522 15.9192 17.4984 14.9987 17.4984H4.9987C4.07822 17.4984 3.33203 16.7522 3.33203 15.8317V5.83171C3.33203 4.91123 4.07822 4.16504 4.9987 4.16504Z"
                        stroke="#1F2023"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M12.0417 11.25H7.875" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                  <div style={{ marginTop: '1px', marginLeft: '8px' }}>
                    <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.calendar.section2_title')}</div>
                    <div style={{ fontSize: '12px', fontWeight: 400, color: COLORS.gray500 }}>{t('settings.calendar.section2_description')}</div>
                  </div>
                </SettingContentTextWrapper>
                <AntSwitch checked={lockIn} onChange={handleChangeLockIn} inputProps={{ 'aria-label': 'ant design' }} />
              </SettingContent>
              <SettingContent style={{ opacity: lockIn ? '50%' : '100%' }}>
                <SettingContentTextWrapper>
                  <Icons.FocusTime width={20} height={20} />
                  <div style={{ marginTop: '1px', marginLeft: '8px' }}>
                    <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.calendar.section3_title')}</div>
                    <div style={{ fontSize: '12px', fontWeight: 400, color: COLORS.gray500 }}>{t('settings.calendar.section3_description')}</div>
                  </div>
                </SettingContentTextWrapper>
                <AntSwitch checked={highlight} onChange={handleChangeHighlight} inputProps={{ 'aria-label': 'ant design' }} />
              </SettingContent>
              {process.env.REACT_APP_MODE === 'development' && (
                <SettingContent>
                  <SettingContentTextWrapper>
                    <Icons.CalendarWeek />
                    <div style={{ marginTop: '1px', marginLeft: '8px' }}>
                      <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.calendar.section4_title')}</div>
                      <div style={{ fontSize: '12px', fontWeight: 400, color: COLORS.gray500 }}>{t('settings.calendar.section4_description')}</div>
                    </div>
                  </SettingContentTextWrapper>
                  <Select value={startDay === 0 ? 'SU' : 'MO'} onChange={handleChangeStartDay} sx={{ width: '86px', height: '42px', fontSize: '13px' }}>
                    <MenuItem value={'SU'} sx={{ display: 'flex', justifyContent: 'center' }}>
                      {t('settings.calendar.section4_select1')}
                    </MenuItem>
                    <MenuItem value={'MO'} sx={{ display: 'flex', justifyContent: 'center' }}>
                      {t('settings.calendar.section4_select2')}
                    </MenuItem>
                  </Select>
                </SettingContent>
              )}
            </SettingContentWrapper>
          </SettingContentScrollWrapper>
        )}
      </CalendarBoardSettingWrapper>
      <CalendarBoardSettingWrapper style={{ height: 'fit-content', paddingBottom: '90px' }}>
        <TitleWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <Title>{t('settings.journal.header')}</Title>
        </TitleWrapper>
        <SettingContentScrollWrapper>
          <SettingContentWrapper>
            <SettingContent style={{ display: 'block' }}>
              <SettingContentTextWrapper>
                <Icons.BoardSetting />
                <div style={{ marginTop: '1px', marginLeft: '8px' }}>
                  <div style={{ fontSize: '13px', fontWeight: 700, marginBottom: 4 }}>{t('settings.journal.title')}</div>
                  <div style={{ fontSize: '12px', fontWeight: 400, color: COLORS.gray500 }}>{t('settings.journal.description')}</div>
                </div>
              </SettingContentTextWrapper>
              <BoardViewSelectWrapper>
                <BoardViewSelectContent>
                  <div
                    onClick={() => handleChangeBoardView('SPLIT')}
                    style={{
                      backgroundColor: 'rgba(226, 236, 255, 0.55)',
                      border: boardView === 'SPLIT' ? `1px solid ${COLORS.brand1}` : '1px solid transparent',
                      borderRadius: 8,
                      cursor: 'pointer',
                      padding: 12,
                    }}
                  >
                    <img
                      src={require(language === 'ko' ? 'assets/images/split.png' : 'assets/images/split_en.png')}
                      alt="split-board"
                      style={{ width: 255, height: 217 }}
                    />
                    <div style={{ color: COLORS.gray700, fontSize: 12, marginTop: 8 }}>{t('settings.journal.view1_description')}</div>
                  </div>
                  <div style={{ fontSize: 12, marginTop: 4, fontWeight: boardView === 'SPLIT' ? 700 : 400 }}>{t('settings.journal.view1_title')}</div>
                </BoardViewSelectContent>
                <BoardViewSelectContent>
                  <div
                    onClick={() => handleChangeBoardView('MERGED')}
                    style={{
                      backgroundColor: 'rgba(226, 236, 255, 0.55)',
                      border: boardView === 'MERGED' ? `1px solid ${COLORS.brand1}` : '1px solid transparent',
                      borderRadius: 8,
                      cursor: 'pointer',
                      padding: 12,
                    }}
                  >
                    <img
                      src={require(language === 'ko' ? 'assets/images/merge.png' : 'assets/images/merge_en.png')}
                      alt="merge-board"
                      style={{ width: 255, height: 217 }}
                    />
                    <div style={{ color: COLORS.gray700, fontSize: 12, marginTop: 8, whiteSpace: 'pre-line' }}>{t('settings.journal.view2_description')}</div>
                  </div>
                  <div style={{ fontSize: 12, marginTop: 4, fontWeight: boardView === 'MERGED' ? 700 : 400 }}>{t('settings.journal.view2_title')}</div>
                </BoardViewSelectContent>
              </BoardViewSelectWrapper>
            </SettingContent>
          </SettingContentWrapper>
        </SettingContentScrollWrapper>
      </CalendarBoardSettingWrapper>
    </Container>
  );
};

export default CalendarSetting;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const CalendarBoardSettingWrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  width: 630px;
  font-size: 24px;
  font-weight: 700;
`;

const SettingContentScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  ${hideScroll()}
`;

const SettingContentWrapper = styled.div`
  width: 630px;
  height: 100%;
`;

const SettingContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 24px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  .MuiSelect-select {
    margin-top: 3px;
  }
`;

const SettingContentTextWrapper = styled.div`
  display: flex;
`;

const GoogleCalenderSettingHeader = styled.div`
  display: flex;
  align-items: center;
`;

const GoogleCalendarSettingContent = styled.div`
  width: 100%;
  font-size: 13px;
  margin-top: 8px;
  padding-left: 28px;
`;

const GoogleCalendarPrimaryWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GoogleCalendarContentWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 6px;

  .calendar-button-wrapper {
    opacity: 0;
  }

  :hover {
    background-color: #f2f5fc;

    .calendar-button-wrapper {
      opacity: 1;
    }
  }
`;

const DefaultCalendarButtonWrapper = styled.div`
  .calendar-button-dot {
    background-color: ${COLORS.gray500};
  }
`;

const BoardViewSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const BoardViewSelectContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConnectViewControlWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const ConnectContentWrapper = styled.div`
  width: 630px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 24px;
`;

const ConnectTitleWrapper = styled.div`
  display: flex;
`;

const ConnectTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
`;

const ConnectSubTitle = styled.div`
  color: ${COLORS.gray500};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.25px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
