import { SVGProps } from 'react';

export const TextDelete = ({ width = 16, height = 16, fill = '#C8CDDB' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path d="M4.66669 4.66699L11.3334 11.3337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3333 4.66699L4.66665 11.3337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
