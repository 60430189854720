import { SVGProps } from 'react';

export const RitualEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M31.667 18.331H35.0003M33.3336 16.6644V19.9977M20.0003 4.99771H20.6553C18.5142 6.98722 17.1379 9.66412 16.7658 12.5631C16.3936 15.462 17.0491 18.3998 18.6182 20.8656C20.1873 23.3314 22.5709 25.1694 25.3546 26.0602C28.1383 26.9509 31.1462 26.838 33.8553 25.741C32.8132 28.2485 31.1099 30.4261 28.9273 32.0415C26.7446 33.6569 24.1644 34.6494 21.4618 34.9134C18.7592 35.1773 16.0357 34.7026 13.5818 33.54C11.1278 32.3775 9.03547 30.5705 7.52793 28.312C6.02039 26.0535 5.15421 23.4282 5.02176 20.716C4.88932 18.0038 5.49559 15.3065 6.77591 12.9119C8.05622 10.5173 9.96255 8.51508 12.2916 7.11893C14.6206 5.72278 17.2849 4.98503 20.0003 4.98438V4.99771ZM28.3336 6.66437C28.3336 7.54843 28.6848 8.39628 29.3099 9.0214C29.9351 9.64652 30.7829 9.99771 31.667 9.99771C30.7829 9.99771 29.9351 10.3489 29.3099 10.974C28.6848 11.5991 28.3336 12.447 28.3336 13.331C28.3336 12.447 27.9824 11.5991 27.3573 10.974C26.7322 10.3489 25.8843 9.99771 25.0003 9.99771C25.8843 9.99771 26.7322 9.64652 27.3573 9.0214C27.9824 8.39628 28.3336 7.54843 28.3336 6.66437Z"
      stroke="#ABB0BF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
