import { SVGProps } from 'react';

export const LockIn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33335 1.33594C5.70154 1.33594 6.00002 1.63441 6.00002 2.0026V2.66797H10V2.0026C10 1.63441 10.2985 1.33594 10.6667 1.33594C11.0349 1.33594 11.3333 1.63441 11.3333 2.0026V2.66797H12C13.1046 2.66797 14 3.5634 14 4.66797V12.668C14 13.7725 13.1046 14.668 12 14.668H4C2.89543 14.668 2 13.7725 2 12.668V4.66797C2 3.5634 2.89543 2.66797 4 2.66797H4.66669V2.0026C4.66669 1.63441 4.96516 1.33594 5.33335 1.33594ZM4.66669 4.0013H4C3.63181 4.0013 3.33333 4.29978 3.33333 4.66797V12.668C3.33333 13.0362 3.63181 13.3346 4 13.3346H12C12.3682 13.3346 12.6667 13.0362 12.6667 12.668V4.66797C12.6667 4.29978 12.3682 4.0013 12 4.0013H11.3333V4.66927C11.3333 5.03746 11.0349 5.33594 10.6667 5.33594C10.2985 5.33594 10 5.03746 10 4.66927V4.0013H6.00002V4.66927C6.00002 5.03746 5.70154 5.33594 5.33335 5.33594C4.96516 5.33594 4.66669 5.03746 4.66669 4.66927V4.0013Z"
        fill={props.fill || '#1F2023'}
      />
      <path
        d="M5.04919 8.33297C4.95356 8.24455 5.00551 8.08469 5.13486 8.06936L6.9677 7.852C7.02042 7.84575 7.06621 7.81265 7.08845 7.76445L7.86152 6.08871C7.91608 5.97045 8.08423 5.97042 8.13878 6.08869L8.91185 7.76441C8.93409 7.81261 8.97958 7.8458 9.0323 7.85205L10.8652 8.06936C10.9946 8.08469 11.0464 8.2446 10.9508 8.33302L9.59585 9.58605C9.55687 9.62209 9.53952 9.67573 9.54987 9.72779L9.90946 11.5378C9.93484 11.6656 9.79887 11.7645 9.68521 11.7009L8.07469 10.7993C8.02837 10.7734 7.97209 10.7735 7.92577 10.7994L6.31508 11.7007C6.20142 11.7643 6.0652 11.6655 6.09058 11.5378L6.45023 9.72791C6.46058 9.67585 6.44327 9.62208 6.4043 9.58604L5.04919 8.33297Z"
        fill={props.fill || '#1F2023'}
      />
    </svg>
  );
};
