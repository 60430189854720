import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

export const MainLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UniversalLayout = styled.div`
  width: 100%;
  min-height: 40px;
`;

export const NavLayout = styled.div`
  width: 80px;
`;

export const PageLayout = styled.div`
  width: calc(100% - 80px);
  min-width: 1024px;
  height: 100%;
  display: flex;
  background-color: ${COLORS.gray100};
  overflow: hidden;
`;
