import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from '../../styles/constants';

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

export const CheckboxRound = ({ checked = false, disabled = false, onChange }: CheckboxProps) => {
  return <InputBox type="checkbox" checked={checked} disabled={disabled} onChange={onChange} readOnly={!onChange} />;
};

const InputBox = styled.input<{ checked: boolean; disabled: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  appearance: none;
  border-radius: 50%;
  ${(props) =>
    props.checked
      ? props.disabled
        ? css`
            border: none;
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
            background-size: 100% 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-color: ${COLORS.gray300};
            cursor: no-drop;
          `
        : css`
            border: none;
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
            background-size: 100% 100%;
            background-position: 50%;
            background-repeat: no-repeat;
            background-color: ${COLORS.brand1};
            cursor: pointer;
          `
      : props.disabled
      ? css`
          background-color: ${COLORS.gray200};
          border: 1px solid ${COLORS.gray300};
          cursor: no-drop;
        `
      : css`
          border: 1px solid ${COLORS.brand1};
          background-color: ${COLORS.white};
          cursor: ${props.disabled} ? 'default' : 'pointer';
        `}
`;
