import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { Fail } from '../Icons';

interface PopupProps {
  label: string;
}

export const FailPopup = ({ label }: PopupProps) => {
  return (
    <Wrapper>
      <Fail />
      <Fonts.H3 style={{ marginLeft: '8px' }}>{label}</Fonts.H3>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  box-shadow: 0px 16px 24px 0px #1a1e2729;
  background-color: ${COLORS.negative2};
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;
