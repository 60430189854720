import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SubtaskMode } from './TimelineTaskbox';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface SubtaskModeMenuProps {
  mode?: SubtaskMode;
  onChange?: (mode: SubtaskMode) => void;
}

const SubtaskModeMenu = ({ mode, onChange }: SubtaskModeMenuProps) => {
  const [language] = useAtom(languageAtom);
  const handleChange = (mode: SubtaskMode) => {
    if (onChange) {
      onChange(mode);
    }
  };

  return (
    <Menu>
      <MenuItem selected={mode === 'IMPORT'} onClick={() => handleChange('IMPORT')}>
        <MenuTextWrapper>
          <Icons.LoadToInstance style={{ marginRight: 8 }} />
          <span>{language === 'ko' ? '하위 업무 가져오기' : 'Import subtask'}</span>
        </MenuTextWrapper>
        {mode === 'IMPORT' && <Icons.Check width={16} height={16} stroke={COLORS.brand1} />}
      </MenuItem>
      <MenuItem selected={mode === 'ADD'} onClick={() => handleChange('ADD')}>
        <MenuTextWrapper>
          <Icons.Plus width={16} height={16} stroke={COLORS.gray900} style={{ marginRight: 8 }} />
          <span>{language === 'ko' ? '하위 업무 추가하기' : 'Add subtask'}</span>
        </MenuTextWrapper>
        {mode === 'ADD' && <Icons.Check width={16} height={16} stroke={COLORS.brand1} />}
      </MenuItem>
    </Menu>
  );
};

export default SubtaskModeMenu;

const Menu = styled.div`
  width: 180px;
  padding: 8px;
`;

const MenuItem = styled.div<{ selected?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.gray100};
    `}

  :hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const MenuTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;
