import { RefObject, useEffect, useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { Button, IconButton, Menu, MenuItem, Popover, Select, Stack, Tooltip, Typography } from '@mui/material';
import dayjs from 'lib/dayjs';
import TodayCalendarEvent, { TodayCalendarEventProps } from './TodayCalendarEvent';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import { useEventListener, useMountEffect, useRafCallback } from '@react-hookz/web';
import { readTaskboxesV1TaskboxesGet } from 'queries';
import { groupBy, omit } from 'lodash';
import { OutCategory, OutFeatureStorage, OutTaskboxDetailResponse, OutTaskboxDetailResponseCategory } from 'queries/model';
import { osName } from 'react-device-detect';
import { useAtom } from 'jotai';
import {
  calendarWidthAtom,
  calendarWidthWithSidePanelAtom,
  foldCalendarViewAtom,
  foldSidePanelViewAtom,
  sidePanelWidthAtom,
  sidePanelWidthWithCalendarAtom,
} from 'atoms/foldView';
import { Resizable } from 're-resizable';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarEventsFilter } from '../Month';
import { CategoryActionType } from '../components/CategoryPopover';
import { DATE_FORMAT } from 'constants/dateFormat';
import { languageAtom } from 'atoms/language';
import { createEventInfoCloseAtom } from 'atoms/createEventInfoClose';
import { calendarListAtom } from 'atoms/calendarList';
import { isOpenConnectCalendarDialogAtom } from 'atoms/dialog';

const CalendarViewWrapper = styled.div`
  height: 100%;
  background-color: #f2f5fc;
  position: relative;
`;

const CalendarViewControlWrapper = styled.div`
  height: 100px;

  .MuiSelect-icon {
    right: 1px;
  }

  .MuiSelect-select {
    font-size: 12px;
    padding: 0px 0px 0px 9px !important;
  }

  .MuiSelect-select span:nth-of-type(2) {
    display: none !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.gray900};
    font-weight: 700;
  }
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 128px);
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};
  margin-top: 6px;

  .rbc-events-container {
    /* margin-right: 14px; */
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-time-header {
    height: 62px;
    /* margin-bottom: 20px; */
    overflow: hidden;
    /* max-height: 300px; */
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    min-height: 62px;
    /* display: none; */
  }

  .rbc-time-header.rbc-overflowing {
    margin-right: 8px !important;
  }

  .rbc-event.rbc-selected {
    /* background-color: ${COLORS.sub3}; */
    /* border: 1px solid ${COLORS.brand1}; */
    /* box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16); */
  }

  .rbc-event {
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-addons-dnd-drag-row {
    .rbc-event-content {
      height: 28px;
    }
  }

  .rbc-addons-dnd-drag-prewview {
  }

  .rbc-event-continues-later {
    .rbc-event-content {
      height: 100%;

      > div {
        height: 100%;

        > div {
          height: 100%;
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }
`;

const AllDayEventContainer = styled.div<{ height?: number }>`
  width: 100%;
  display: flex;
  position: relative;
`;

const ViewMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 24px;
  cursor: pointer;
`;

const AllDayTopBorder = styled.div`
  height: 1px;
  width: calc(100% - 52px);
  background-color: ${COLORS.gray300};
  margin-top: 6px;
  position: absolute;
  right: 1px;
`;

const DayWrapper = styled.div<{ bgColor?: string; borderColor?: string }>`
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${(props) => props.bgColor || 'inherit'};
  color: ${(props) => (props.bgColor === COLORS.brand1 ? 'white' : COLORS.gray900)};
  border-radius: 50%;
  cursor: pointer;

  ${(props) =>
    props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `};
`;

const DayTaskStatusDot = styled.div<{ bgColor?: string }>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || 'inherit'};
`;

const WeekTaskStatusContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: fit-content;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

interface WeekTaskStatusProps {
  currentDate: Date;
  weekTasks?: { date: string; tasks: OutTaskboxDetailResponse[] }[];
  onClick?: (date: Date) => void;
}

const WeekTaskStatus = ({ currentDate, weekTasks = [], onClick }: WeekTaskStatusProps) => {
  return (
    <WeekTaskStatusContainer>
      {weekTasks.map((item, idx) => {
        const { date, tasks } = item;
        const isDone = tasks.length > 0 && tasks.every((task) => task.done);
        const dayColor = dayjs(date).isToday() ? COLORS.brand1 : dayjs(date).isSame(currentDate, 'day') ? COLORS.sub3 : tasks?.length ? 'white' : '';
        const statusColor = isDone ? COLORS.sub4 : dayjs().isAfter(date, 'day') ? COLORS.negative1 : COLORS.brand1;

        return (
          <div key={idx} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: 10, color: COLORS.gray500, marginBottom: 2 }}>{dayjs(date).format('ddd')}</div>
            <DayWrapper
              bgColor={dayColor}
              borderColor={dayjs(date).isSame(currentDate, 'day') ? COLORS.brand1 : ''}
              onClick={() => onClick?.(dayjs(date).toDate())}
            >
              {dayjs(date).format('D')}
            </DayWrapper>
            <DayTaskStatusDot bgColor={tasks.length ? statusColor : 'transparent'} style={{ marginTop: 4 }} />
          </div>
        );
      })}
    </WeekTaskStatusContainer>
  );
};

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
  isRecurrence?: boolean;
  isProject?: boolean;
  focus?: boolean;
  done?: boolean;
  category?: OutTaskboxDetailResponseCategory | OutCategory;
  durationMin?: number | null;
} & Event;

export interface CalendarViewProps {
  refCalendarView?: RefObject<HTMLDivElement>;
  newEventId?: string | null;
  events?: CustomEvent[];
  selectedEventId?: string;
  currentDate: Date;
  calendarFilter?: CalendarEventsFilter;
  meetingFilter?: OutFeatureStorage;
  onClickRefresh?: () => void;
  onClickToggleView?: () => void;
  onClickCalendarFilter?: (filter: CalendarEventsFilter) => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEvent?: ({
    eventId,
    startTime,
    endTime,
    isAllDay,
    title,
  }: {
    eventId: string;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
    title?: string;
  }) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onChangeEventEditing?: () => void;
  onClickTimeSlot?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDoubleClickEvent?: (eventId: string) => void;
  onContextMenuEvent?: (eventId: string) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onClickFoldCalendarView?: () => void;
  onClickMeetingFilter?: () => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType, eventId?: string) => void;
  onClickAddButton?: () => void;
  onClickNeverShowCreateEventInfo?: () => void;
  onScrollToCurrentTime?: (ele: HTMLElement) => void;
  onChangeOngoingTaskbox?: (eventId: string) => void;
}

const CalendarView = React.forwardRef(
  (
    {
      refCalendarView,
      events = [],
      selectedEventId,
      currentDate,
      calendarFilter,
      meetingFilter,
      onClickRefresh,
      onClickToggleView,
      onClickCalendarFilter,
      onSelectEvent,
      onUpdateEvent,
      onUpdateEventTitle,
      onChangeEventEditing,
      onClickTimeSlot,
      onDoubleClickEvent,
      onContextMenuEvent,
      onChangeCurrentDate,
      onDropFromOutside,
      onClickFoldCalendarView,
      onClickMeetingFilter,
      onClickCategoryActions,
      onClickAddButton,
      onClickNeverShowCreateEventInfo,
      onScrollToCurrentTime,
      onChangeOngoingTaskbox,
    }: CalendarViewProps,
    parentRef,
  ) => {
    const navigate = useNavigate();

    //useRef
    const prevEventsRef = useRef(events);
    const ref = useRef<HTMLDivElement>(null);

    //useState
    const [isVisibleViewMore, setIsVisibleViewMore] = useState(false);
    const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);
    const [weekTasks, setWeekTasks] = useState<{ date: string; tasks: OutTaskboxDetailResponse[] }[]>([]);
    const [isHoverWidthLine, setIsHoverWidthLine] = useState(false);
    const [mount, setMount] = useState(true);
    const [bodyWidth, setBodyWidth] = useState(document.body.getBoundingClientRect().width);
    const [calendarFilterPopoverAnchor, setCalendarFilterPopoverAnchor] = useState<HTMLElement | null>(null);
    const [meetingInfoPopoverAnchor, setMeetingInfoPopover] = useState<HTMLElement | null>(null);

    //useAtom
    const [language] = useAtom(languageAtom);
    const [foldCalendar] = useAtom(foldCalendarViewAtom);
    const [calendarWidth, setCalendarWidth] = useAtom(calendarWidthAtom);
    const [calendarWidthWithSidePanel, setCalendarWidthWithSidePanel] = useAtom(calendarWidthWithSidePanelAtom);
    const [foldSidePanel] = useAtom(foldSidePanelViewAtom);
    const [sidePanelWidthWithCalendar] = useAtom(sidePanelWidthWithCalendarAtom);

    useMountEffect(() => {
      scrollToCurrentIndicator();
    });

    useEffect(() => {
      const prevEvents = prevEventsRef.current;
      const taskEvents = events.filter((v) => v.type === 'task');
      const prevTaskEvents = prevEvents.filter((v) => v.type === 'task');

      const eventsChanged = JSON.stringify(prevTaskEvents) !== JSON.stringify(taskEvents);

      if (eventsChanged) {
        fetchWeekTasks(currentDate);
      }

      prevEventsRef.current = events;
    }, [events]);

    useEffect(() => {
      fetchWeekTasks(currentDate);
    }, [currentDate]);

    useEffect(() => {
      if (!ref || !ref.current) return;
      const currentTimeIndicator = ref.current.querySelector('.rbc-current-time-indicator') as HTMLElement;
      if (!currentTimeIndicator) return;
      let rbcEvents: HTMLElement[] = [];
      const updateEvents = () => {
        if (ref.current) {
          rbcEvents = Array.from(ref.current.querySelectorAll('.rbc-event-content')) as HTMLElement[];
        }
      };

      const findAndHandleEvents = () => {
        if (!ref.current) return;

        const validEvents = events.filter((event) => event.type === 'task' && !event.allDay && !event.done);
        const indicatorRect = currentTimeIndicator.getBoundingClientRect();

        const overlappingEvents = rbcEvents.filter((event) => {
          const eventRect = event.getBoundingClientRect();
          return (
            eventRect.left < indicatorRect.right &&
            eventRect.right > indicatorRect.left &&
            eventRect.top < indicatorRect.bottom &&
            eventRect.bottom > indicatorRect.top
          );
        });

        const calendarEvents: (CustomEvent | undefined)[] = overlappingEvents
          .map((v) => {
            const eventId = (v.childNodes[0] as HTMLElement).dataset.calendarId;
            return validEvents.find((event) => event.id === eventId);
          })
          .filter(Boolean) as CustomEvent[];

        return calendarEvents;
      };

      //events가 변경될 때마다 updateEvents, findAndHandleEvents 실행
      updateEvents();
      const calendarEvents = findAndHandleEvents();
      if (calendarEvents) handleSelectOngoingTaskbox(calendarEvents);

      //current time indicator가 변할 때마다 findAndHandleEvents 실행
      const observer = new MutationObserver(() => {
        const calendarEvents = findAndHandleEvents();
        if (calendarEvents) handleSelectOngoingTaskbox(calendarEvents);
      });

      observer.observe(currentTimeIndicator, { attributes: true, attributeFilter: ['style'] });

      return () => observer.disconnect();
    }, [events]);

    useLayoutEffect(() => {
      if (!mount) {
        if (foldSidePanel) return;
        if (calendarWidth > calendarWidthWithSidePanel) {
          setCalendarWidthWithSidePanel(bodyWidth - sidePanelWidthWithCalendar - 782);
          localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(bodyWidth - sidePanelWidthWithCalendar - 782));
        } else if (calendarWidth < calendarWidthWithSidePanel) {
          setCalendarWidthWithSidePanel(calendarWidth);
          localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(calendarWidth));
        }
      }
      setMount(false);
    }, [foldSidePanel]);

    const formats = useMemo(
      () => ({
        timeGutterFormat: language === 'ko' ? 'a hh시' : 'a hh',
        eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
          `${localizer?.format(range.start, 'a h:mm', culture)}~${localizer?.format(range.end, 'a h:mm', culture)}`,
      }),
      [],
    );

    useEventListener(
      window,
      'resize',
      () => {
        if (bodyWidth - document.body.getBoundingClientRect().width > 100 && !foldCalendar && !foldSidePanel) {
          setCalendarWidthWithSidePanel(300);
          localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(300));
        }

        if (bodyWidth - document.body.getBoundingClientRect().width > 100 || bodyWidth - document.body.getBoundingClientRect().width < -100) {
          setCalendarWidth(Math.round((document.body.getBoundingClientRect().width - 80) * (calendarWidth / (bodyWidth - 80))));
          localStorage.setItem(
            'calendar-width',
            JSON.stringify(Math.round((document.body.getBoundingClientRect().width - 80) * (calendarWidth / (bodyWidth - 80)))),
          );
        }

        setBodyWidth(document.body.getBoundingClientRect().width);
      },
      { passive: true },
    );

    const fetchWeekTasks = useCallback(async (date: Date) => {
      const day = dayjs(date).day();
      const targetDate = dayjs(date).format(DATE_FORMAT.YYYY_MM_DD);
      const start = dayjs(targetDate).subtract(day, 'day').format(DATE_FORMAT.YYYY_MM_DD);
      const end = dayjs(targetDate)
        .add(7 - day, 'day')
        .format(DATE_FORMAT.YYYY_MM_DD);

      const data = await readTaskboxesV1TaskboxesGet({ start_date: start, end_date: end });
      const taskboxes = data.map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime, { utc: true }).format('YYYY-MM-DD') }));
      const groups = groupBy(taskboxes, 'date');

      setWeekTasks(
        [...Array(7).keys()].map((idx) => ({
          date: dayjs(start).add(idx, 'day').format('YYYY-MM-DD'),
          tasks: groups[dayjs(start).add(idx, 'day').format('YYYY-MM-DD')]?.map((item) => omit(item, 'date')) || [],
        })),
      );
    }, []);
    const resizableAccessor = useCallback((event: CustomEvent) => !event.allDay, [events]);
    const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
    const eventPropGetter = useCallback(
      (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
        if (event.type === 'meeting') {
          if (!event.allDay) {
            return {
              style: {
                border: `2px solid ${COLORS.gray100}`,
              },
            };
          }
        }

        // 종일 taskbox를 특정 시간대로 이동시 스타일
        if (event.allDay && isSelected === undefined) {
          return {
            style: { maxHeight: '56px' },
          };
        }

        if (isSelected && event.focus) {
          return {
            style: {
              border: '1px solid transparent',
              backgroundImage: 'linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%)',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
            },
          };
        }

        return {
          style: { border: `1px solid ${isSelected ? (event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1) : COLORS.gray200}` },
        };
      },
      [events],
    );

    const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
      onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
    });

    const [handleContextMenu] = useRafCallback((event: CustomEvent) => {
      onContextMenuEvent && onContextMenuEvent(event.id!);
    });

    const [updateCategory] = useRafCallback((event: CustomEvent, category: OutCategory | null, action: CategoryActionType) => {
      if (!event.id) return;
      onClickCategoryActions && onClickCategoryActions(category, action, event.id);
    });

    const scrollToCurrentIndicator = () => {
      setTimeout(() => {
        if (!ref || !ref.current) return;
        const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
        if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
      }, 30);
    };

    const handleClickCalendarPopoverItem = (value: Date | null) => {
      if (!value) return;

      setIsVisibleCalendarPopover(false);
      onChangeCurrentDate && onChangeCurrentDate(value);
    };

    const handleChangeCurrentDate = (date: Date) => {
      onChangeCurrentDate && onChangeCurrentDate(date);
    };

    const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
      if (event.allDay && isAllDay) return; // 종일 > 종일로 이동 불가
      let endTime = event.allDay && !isAllDay ? dayjs(start).add(event.durationMin ? event.durationMin : 60, 'minute') : dayjs(end); // 종일 이벤트를 특정시간으로 이동시 1시간으로 조정

      // endTime이 15분 단위가 아닌 경우
      if (dayjs(endTime).get('minute') % 15 !== 0) {
        endTime = dayjs(endTime).set('minutes', Math.round(dayjs(endTime).get('minute') / 15) * 15);
      }

      onUpdateEvent &&
        onUpdateEvent({
          eventId: event.id!,
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(endTime).format(DATE_FORMAT_1),
          isAllDay: Boolean(isAllDay),
        });
    };

    const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
      onUpdateEvent &&
        onUpdateEvent({
          eventId: event.id!,
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(end).format(DATE_FORMAT_1),
          isAllDay: Boolean(isAllDay),
        });
    };

    const handleSelectEvent = (event: CustomEvent) => {
      // if (event.type === 'meeting') return;
      onSelectEvent && onSelectEvent(event.id!);
    };

    const handleSelectSlot = (slot: SlotInfo) => {
      if (calendarFilter === 'MEETING') onClickCalendarFilter && onClickCalendarFilter('ALL');

      const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
      const endTime =
        slot.slots.length === 2
          ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
          : dayjs(slot.end).format(DATE_FORMAT_1);

      onClickTimeSlot && onClickTimeSlot({ startTime, endTime, isAllDay: slot?.slots.length === 1 ? true : false });
    };

    const handleDoubleClickEvent = (event: CustomEvent) => {
      onDoubleClickEvent && onDoubleClickEvent(event.id!);
    };

    const handleDropFromOutside = ({ start, end, allDay }: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
      onDropFromOutside &&
        onDropFromOutside({
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(end).format(DATE_FORMAT_1),
          isAllDay: allDay,
        });
    };

    const handleClickViewMore = () => {
      const timeHeader = ref.current?.querySelector('.rbc-time-header') as HTMLDivElement;
      timeHeader.style.height = `${isVisibleViewMore ? 52 : 212}px`;
      setIsVisibleViewMore(!isVisibleViewMore);
    };

    const handleEventInput = (event: CustomEvent, title: string) => {
      updateEventTitle(event, title);
    };

    const handleEventCategory = (event: CustomEvent, category: OutCategory | null, action: CategoryActionType) => {
      updateCategory(event, category, action);
    };

    const EventComponentWrapper = useCallback((props: TodayCalendarEventProps) => {
      return (
        <TodayCalendarEvent
          {...props}
          onInput={(event, value) => handleEventInput(event, value)}
          onChangeCategory={(event, category, action) => handleEventCategory(event, category, action)}
          onChangeEditing={onChangeEventEditing}
          onContextMenu={handleContextMenu}
        />
      );
    }, []);

    const handleResizeStop = (e: MouseEvent | TouchEvent, direction: string, ref: HTMLElement, d: { width: number; height: number }) => {
      if (!foldCalendar && !foldSidePanel) {
        setCalendarWidthWithSidePanel(calendarWidthWithSidePanel + d.width);
        localStorage.setItem('calendar-width-with-side-panel', JSON.stringify(calendarWidthWithSidePanel + d.width));
      } else {
        if (!localStorage.getItem('calendar-width')) {
          const ele = document.querySelector('.calendar-resizable') as HTMLElement;
          const width = Math.round(ele.getBoundingClientRect().width);
          setCalendarWidth(width);
          localStorage.setItem('calendar-width', JSON.stringify(width));
        } else {
          setCalendarWidth(calendarWidth + d.width);
          localStorage.setItem('calendar-width', JSON.stringify(calendarWidth + d.width));
        }
      }
    };

    const handleResizeStart = () => {
      setIsHoverWidthLine(true);
    };

    const scrollToCurrentTime = () => {
      const scrollEle = ref.current?.querySelector('.rbc-time-content') as HTMLElement;
      if (scrollEle) onScrollToCurrentTime && onScrollToCurrentTime(scrollEle);
    };

    const handleClickRefreshButton = () => {
      onClickRefresh && onClickRefresh();
    };

    const handleClickAddButton = (e: any) => {
      scrollToCurrentTime();
      setTimeout(() => {
        onClickAddButton && onClickAddButton();
      }, 300);
    };

    const handleSelectOngoingTaskbox = (events: (CustomEvent | undefined)[]) => {
      if (events.length === 0) {
        onChangeOngoingTaskbox && onChangeOngoingTaskbox('');
        return;
      }

      if (events.length > 1) {
        // 여러 이벤트가 겹칠 경우 시작 시간이 현재 시간과 가장 가까운 이벤트 선택. 시작 시간이 같은 경우 종료 시간이 현재 시간과 가장 가까운 이벤트 선택. 시작 시간과 종료 시간이 모두 같을 경우 첫번째 이벤트 선택
        const currentTime = dayjs();
        const closestEvent = events.reduce((prev, current) => {
          const prevStartDiff = Math.abs(dayjs(prev?.start).diff(currentTime, 'minute'));
          const currentStartDiff = Math.abs(dayjs(current?.start).diff(currentTime, 'minute'));

          const prevEndDiff = Math.abs(dayjs(prev?.end).diff(currentTime, 'minute'));
          const currentEndDiff = Math.abs(dayjs(current?.end).diff(currentTime, 'minute'));

          // 시작 시간이 동일하면 종료 시간 기준으로 가까운 이벤트 선택
          if (prevStartDiff === currentStartDiff) {
            if (prevEndDiff === currentEndDiff) {
              return prev; // 시작/종료 시간이 모두 같으면 기존(prev) 유지
            }
            return prevEndDiff < currentEndDiff ? prev : current; // 종료 시간이 더 가까운 이벤트
          }

          // 시작 시간이 더 가까운 이벤트 선택
          return prevStartDiff < currentStartDiff ? prev : current;
        }, events[0]); // 초기값으로 첫 번째 이벤트 설정

        if (closestEvent) {
          onChangeOngoingTaskbox && onChangeOngoingTaskbox(closestEvent.id!);
        }
      } else {
        if (events[0]) {
          onChangeOngoingTaskbox && onChangeOngoingTaskbox(events[0].id!);
        }
      }
    };

    return (
      <Resizable
        className="calendar-resizable"
        size={{
          width:
            !foldCalendar && !foldSidePanel ? calendarWidthWithSidePanel : foldSidePanel && !localStorage.getItem('calendar-width') ? '35%' : calendarWidth,
          height: '100%',
        }}
        minWidth={284}
        maxWidth={foldSidePanel ? '50%' : !foldCalendar && !foldSidePanel ? bodyWidth - sidePanelWidthWithCalendar - 782 : 'none'}
        onResizeStart={() => handleResizeStart()}
        onResize={(e, direction, ref, delta) => {
          setIsHoverWidthLine(true);
        }}
        onResizeStop={(e, direction, ref, delta) => {
          handleResizeStop(e, direction, ref, delta);
          setIsHoverWidthLine(false);
        }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleComponent={{
          right: (
            <div
              onMouseEnter={() => setIsHoverWidthLine(true)}
              onMouseDown={() => setIsHoverWidthLine(true)}
              onMouseLeave={() => setIsHoverWidthLine(false)}
              onClick={() => setIsHoverWidthLine(true)}
              style={{ paddingLeft: 2, width: 8, borderRight: isHoverWidthLine ? `2px solid ${COLORS.sub2}` : `2px solid transparent`, height: '100%' }}
            />
          ),
        }}
        style={{ padding: '24px', position: 'relative', backgroundColor: '#f2f5fc' }}
      >
        <div style={{ height: '100%' }} ref={refCalendarView}>
          <CalendarViewControlWrapper>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarPopover
                  isOpen={isVisibleCalendarPopover}
                  defaultValue={currentDate}
                  onClickOutside={() => setIsVisibleCalendarPopover(false)}
                  onClickItem={handleClickCalendarPopoverItem}
                >
                  <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
                    <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                      <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                        {language === 'ko' ? dayjs(currentDate).format('M월') : dayjs(currentDate).format('MMM')}
                        {/* {dayjs(currentDate).format('MMM')} */}
                      </Typography>
                    </ArrowToggleButton>
                  </span>
                </CalendarPopover>
                <Select
                  value={
                    language === 'ko' ? '일간' : 'Daily'
                    // 'Daily'
                  }
                  sx={{ width: language === 'ko' ? '53px' : '55px', height: '30px', fontSize: '12px' }}
                  MenuProps={{
                    MenuListProps: {
                      style: {
                        padding: '8px',
                      },
                    },
                    style: { marginTop: '2px', left: '23px' },
                  }}
                >
                  <MenuItem
                    value={
                      language === 'ko' ? '일간' : 'Daily'
                      // 'Daily'
                    }
                    sx={{
                      width: '82px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '6px',
                      fontSize: '12px',
                      padding: '8px',
                    }}
                  >
                    <span>
                      {language === 'ko' ? '일간' : 'Daily'}
                      {/* Daily */}
                    </span>
                    <KeyboardButtonRect style={{ width: '16px' }}>D</KeyboardButtonRect>
                  </MenuItem>
                  <MenuItem
                    value={
                      language === 'ko' ? '주간' : 'Weekly'
                      // 'Weekly'
                    }
                    onClick={() => navigate('/task/week')}
                    sx={{
                      width: '82px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '6px',
                      fontSize: '12px',
                      padding: '8px',
                    }}
                  >
                    <span>
                      {language === 'ko' ? '주간' : 'Weekly'}
                      {/* Weekly */}
                    </span>
                    <KeyboardButtonRect style={{ width: '16px' }}>W</KeyboardButtonRect>
                  </MenuItem>
                  <MenuItem
                    value={
                      language === 'ko' ? '월간' : 'Monthly'
                      // 'Monthly'
                    }
                    onClick={() => navigate('/task/month')}
                    sx={{
                      width: '82px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '6px',
                      fontSize: '12px',
                      padding: '8px',
                    }}
                  >
                    <span>
                      {language === 'ko' ? '월간' : 'Monthly'}
                      {/* Monthly */}
                    </span>
                    <KeyboardButtonRect style={{ width: '16px' }}>M</KeyboardButtonRect>
                  </MenuItem>
                </Select>
              </div>
              <div style={{ marginLeft: 12, position: 'relative' }}>
                <Tooltip
                  title={
                    <div style={{ display: 'flex' }}>
                      <span>
                        {language === 'ko' ? '이전 일지 보기' : 'View Previous Log'}
                        {/* View Previous Log */}
                      </span>
                      <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                        ←
                      </KeyboardButtonRect>
                    </div>
                  }
                  disableInteractive
                >
                  <IconButton
                    aria-label="previous day"
                    onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(1, 'day').toDate())}
                    sx={{ padding: '4px' }}
                  >
                    <Icons.ArrowLeftSmall />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <div style={{ display: 'flex' }}>
                      <span>
                        {language === 'ko' ? '오늘로 이동' : 'Move to today'}
                        {/* Move to today */}
                      </span>
                      <KeyboardButtonRect small style={{ padding: '0px 3px', marginLeft: 8 }}>
                        Shift
                      </KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect small style={{ padding: '0px 3px' }}>
                        T
                      </KeyboardButtonRect>
                    </div>
                  }
                  disableInteractive
                >
                  <Button
                    sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
                    size="small"
                    style={{ minWidth: '48px', marginLeft: 2, marginRight: 2, textTransform: 'none' }}
                    onClick={() => handleChangeCurrentDate(dayjs().toDate())}
                  >
                    <b>
                      {language === 'ko' ? '오늘' : 'Today'}
                      {/* Today */}
                    </b>
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    <div style={{ display: 'flex' }}>
                      <span>
                        {language === 'ko' ? '다음 일지 보기' : 'View next log'}
                        {/* View next log */}
                      </span>
                      <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                        →
                      </KeyboardButtonRect>
                    </div>
                  }
                  disableInteractive
                >
                  <IconButton aria-label="next day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(1, 'day').toDate())} sx={{ padding: '4px' }}>
                    <Icons.ArrowRightSmall />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    language === 'ko' ? '할 일 및 일정 필터' : 'Tasks & Schedules FIlter'
                    // 'Tasks & Events FIlter'
                  }
                  disableInteractive
                >
                  <IconButton
                    onClick={(e) => setCalendarFilterPopoverAnchor(e.currentTarget)}
                    sx={{ position: 'absolute', top: 4, left: 95, borderRadius: '4px', padding: '4px' }}
                  >
                    <Icons.MoreVertical />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <div style={{ marginTop: 4 }}>
              <WeekTaskStatus currentDate={currentDate} weekTasks={weekTasks} onClick={(date) => handleChangeCurrentDate(date)} />
            </div>
          </CalendarViewControlWrapper>
          <CalendarViewDaySchedulerWrapper>
            <AllDayEventContainer>
              <ViewMoreButtonWrapper style={{ top: isVisibleViewMore ? 200 : 52 }} onClick={handleClickViewMore}>
                <IconButton size="small" style={{ padding: 2, marginRight: 8, border: `1px solid ${COLORS.gray200}`, background: COLORS.gray200 }}>
                  {isVisibleViewMore ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
                </IconButton>
              </ViewMoreButtonWrapper>
              <AllDayTopBorder />
            </AllDayEventContainer>
            <CalendarContainer ref={ref}>
              <DnDCalendar
                style={{ height: '100%' }}
                date={currentDate}
                getNow={() => dayjs().toDate()}
                selected={events.find((item) => item.id === selectedEventId)}
                formats={formats}
                defaultView="day"
                toolbar={false}
                timeslots={4}
                step={15}
                // max={dayjs(currentDate).endOf('day').toDate()}
                events={events}
                // events={events.filter((v) => v.type === 'task' || v.allDay)}
                // backgroundEvents={events.filter((v) => v.type === 'meeting' && !v.allDay)}
                dayLayoutAlgorithm={'overlap'}
                selectable={true}
                // draggableAccessor={draggableAccessor}
                resizableAccessor={resizableAccessor}
                allDayAccessor={allDayAccessor}
                components={{ event: EventComponentWrapper }}
                eventPropGetter={eventPropGetter}
                onDropFromOutside={handleDropFromOutside}
                onEventDrop={handleEventDrop}
                onEventResize={handleEventResize}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                onDoubleClickEvent={handleDoubleClickEvent}
                onNavigate={() => ({})}
                showAllEvents={true}
                showMultiDayTimes={true}
                culture={language}
              />
            </CalendarContainer>
          </CalendarViewDaySchedulerWrapper>
          {/* <div style={{ position: 'absolute', bottom: 80, right: 16 }}>
            <IconButton
              aria-label="sync"
              sx={{ width: '40px', height: '40px', boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
              style={{ background: COLORS.brand1, zIndex: 30 }}
              onClick={onClickRefresh}
            >
              <Icons.Add width={28} height={28} strokeWidth={1} />
            </IconButton>
          </div> */}
          <div style={{ position: 'absolute', bottom: 34, right: 72 }}>
            <IconButton
              aria-label="sync"
              sx={{ width: '32px', height: '32px', boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
              style={{ background: 'white', zIndex: 30 }}
              onClick={handleClickRefreshButton}
            >
              <Icons.Reload width={16} height={16} strokeWidth={1.2} />
            </IconButton>
          </div>
          <div style={{ position: 'absolute', bottom: 26, right: 16 }}>
            <IconButton
              aria-label="sync"
              sx={{ width: '48px', height: '48px', boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
              style={{ background: COLORS.brand1, zIndex: 30 }}
              onClick={(e) => handleClickAddButton(e)}
            >
              <Icons.Plus stroke={COLORS.white} strokeWidth={2.5} />
            </IconButton>
          </div>
          <div onMouseOver={() => setIsHoverWidthLine(true)} onMouseLeave={() => setIsHoverWidthLine(false)} style={{ width: '15px' }}>
            {isHoverWidthLine && (
              <Tooltip
                title={
                  <div style={{ display: 'flex', alignItems: 'center', padding: '5px 8px' }}>
                    <span>
                      {language === 'ko' ? '왼쪽 탭 접기/펼치기' : 'Collapse/expand left tab'}
                      {/* Collapse/expand left tab */}
                    </span>
                    <KeyboardButtonRect style={{ marginLeft: 8 }}> {osName === 'Windows' ? 'Ctrl' : '⌘'}</KeyboardButtonRect>
                    <KeyboardCommandPlus>+</KeyboardCommandPlus>
                    <KeyboardButtonRect>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                    <KeyboardCommandPlus>+</KeyboardCommandPlus>
                    <KeyboardButtonRect>{`[`}</KeyboardButtonRect>
                  </div>
                }
                placement="right"
                disableInteractive
                sx={{ padding: '0px' }}
              >
                <IconButton
                  onClick={() => onClickFoldCalendarView?.()}
                  sx={{
                    'width': '32px',
                    'height': '32px',
                    'position': 'absolute',
                    'right': -18,
                    'top': 114,
                    'zIndex': 10000,
                    'backgroundColor': COLORS.white,
                    'borderRadius': '8px',
                    'border': `1px solid ${COLORS.gray300}`,
                    'boxShadow': `0px 8px 16px 0px rgba(26, 30, 39, 0.16)`,
                    'padding': '4px',
                    ':hover': {
                      backgroundColor: COLORS.sub3,
                    },
                  }}
                >
                  <Icons.ArrowLeftSmall />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {calendarFilterPopoverAnchor && (
          <Menu
            open={Boolean(calendarFilterPopoverAnchor)}
            anchorEl={calendarFilterPopoverAnchor}
            onClose={() => setCalendarFilterPopoverAnchor(null)}
            MenuListProps={{ style: { padding: '8px' } }}
          >
            <MenuItem
              selected={calendarFilter === 'ALL'}
              onClick={() => {
                setCalendarFilterPopoverAnchor(null);
                onClickCalendarFilter && onClickCalendarFilter('ALL');
              }}
              sx={{
                width: '73px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>
                {language === 'ko' ? '모두' : 'All'}
                {/* All */}
              </span>
              {calendarFilter === 'ALL' && <Icons.Check width={20} height={20} stroke={COLORS.brand1} />}
            </MenuItem>
            <MenuItem
              selected={calendarFilter === 'TASK'}
              onClick={() => {
                setCalendarFilterPopoverAnchor(null);
                onClickCalendarFilter && onClickCalendarFilter('TASK');
              }}
              sx={{
                width: '73px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>
                {language === 'ko' ? '할 일' : 'Tasks'}
                {/* Tasks */}
              </span>
              {calendarFilter === 'TASK' && <Icons.Check width={20} height={20} stroke={COLORS.brand1} />}
            </MenuItem>
            <MenuItem
              selected={calendarFilter === 'MEETING'}
              onClick={() => {
                setMeetingInfoPopover(calendarFilterPopoverAnchor);
                setCalendarFilterPopoverAnchor(null);
                onClickCalendarFilter && onClickCalendarFilter('MEETING');
              }}
              sx={{
                width: '73px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>
                {language === 'ko' ? '일정' : 'Events'}
                {/* Events */}
              </span>
              {calendarFilter === 'MEETING' && <Icons.Check width={20} height={20} stroke={COLORS.brand1} />}
            </MenuItem>
          </Menu>
        )}
        {/* {meetingInfoPopoverAnchor && meetingFilter?.data.click !== true && (
          <CalendarInfoWrapper>
            <Popover
              disablePortal
              open={Boolean(meetingInfoPopoverAnchor)}
              anchorEl={meetingInfoPopoverAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={() => {
                setMeetingInfoPopover(null);
                onClickMeetingFilter && onClickMeetingFilter();
              }}
              sx={{ backgroundColor: 'transparent' }}
            >
              <div>
                <div style={{ width: '100%', height: '10px', backgroundColor: 'transparent', boxShadow: 'none' }}></div>
                <MeetingInfoWrapper>
                  <div style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center', padding: '12px' }}>
                    🛠️ 일정 생성·수정 기능은 준비중입니다.
                    <br />
                    조금만 기다려주세요!
                  </div>
                </MeetingInfoWrapper>
              </div>
            </Popover>
          </CalendarInfoWrapper>
        )} */}
        {meetingInfoPopoverAnchor && meetingFilter?.data.click !== true && (
          <Popover
            open={Boolean(meetingInfoPopoverAnchor)}
            anchorEl={meetingInfoPopoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => {
              setMeetingInfoPopover(null);
              onClickMeetingFilter && onClickMeetingFilter();
            }}
            sx={{ backgroundColor: 'transparent' }}
          >
            {/* <div style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center', padding: '12px' }}>
              🛠️ 일정 생성·수정 기능은 준비중입니다.
              <br />
              조금만 기다려주세요!
            </div> */}
            <div style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center', padding: '12px' }}>
              {language === 'ko' ? '일정 생성·수정 기능은 준비중입니다.' : 'The event creation and modification features are under preparation.'}
              {/* 🛠️ The event creation and modification features are under preparation. */}
              <br />
              {language === 'ko' ? '조금만 기다려주세요!' : 'Please wait a moment!'}
              {/* Please wait a moment! */}
            </div>
          </Popover>
        )}
      </Resizable>
    );
  },
);

export default CalendarView;
