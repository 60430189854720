import { SVGProps } from 'react';

export const ColorPin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3975 5.39749C13.6904 5.69038 13.6904 6.16525 13.3975 6.45815C13.1046 6.75104 12.6297 6.75104 12.3368 6.45815L12.1528 6.27408L10.4899 7.95945L9.59694 10.3406L8.70402 11.2335L4.53707 7.06653L5.42998 6.17361L7.8111 5.28069L9.474 3.59532L9.30632 3.42764C9.01342 3.13474 9.01342 2.65987 9.30632 2.36698C9.59921 2.07408 10.0741 2.07408 10.367 2.36698L13.3975 5.39749ZM6.58303 9.18759C6.29014 8.8947 5.81527 8.8947 5.52237 9.18759L3.24949 11.4605C2.9566 11.7534 2.9566 12.2282 3.24949 12.5211C3.54238 12.814 4.01726 12.814 4.31015 12.5211L6.58303 10.2483C6.87593 9.95536 6.87593 9.48049 6.58303 9.18759Z"
        fill="#0039A7"
      />
    </svg>
  );
};
