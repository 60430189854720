import { SVGProps } from 'react';

export const SubTaskExpand = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.66602 11.3346H6.66602M2.66602 8.0013H8.66602M11.9993 7.33464V12.668M11.9993 12.668L13.9993 10.668M11.9993 12.668L9.99935 10.668M2.66602 4.66797H10.666"
        stroke={props.fill || '#1F2023'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
