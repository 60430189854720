import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { PriorityIssueCheck } from 'components/Icons';
import { useAtom } from 'jotai';
import { COLORS } from 'styles/constants';

interface Props {
  open?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export const ConfirmDeleteDialog = ({ open = false, onClose, onDelete }: Props) => {
  const [language] = useAtom(languageAtom);
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '16px',
          padding: '24px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${COLORS.gray200}`,
          borderRadius: 100,
          color: COLORS.gray900,
          textAlign: 'center',
          fontSize: 13,
          fontWeight: 700,
          padding: '4px 12px',
        }}
      >
        <PriorityIssueCheck width={16} height={16} />
        <span style={{ margin: '1px 0px 0px 8px' }}>
          {language === 'ko' ? '태스크박스 삭제' : 'Delete taskbox'}
          {/* Delete taskbox */}
        </span>
      </DialogTitle>
      <DialogContent
        style={{
          padding: '35px 24px',
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: COLORS.negative1, textAlign: 'center', fontSize: 16, fontWeight: 700, marginBottom: '4px' }}
        >
          {language === 'ko' ? '태스크박스를 삭제하시겠어요?' : 'Delete taskbox?'}
          {/* Delete taskbox? */}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13 }}>
          {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
          {/* All related data will be deleted. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ width: '100%', display: 'flex', alignItems: 'center', padding: '0px' }}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onClose && onClose();
          }}
          sx={{
            'height': '40px',
            'backgroundColor': COLORS.gray100,
            'borderRadius': '8px',
            'boxShadow': 'none',
            'color': COLORS.gray700,
            'fontSize': '13px',
            'fontWeight': 700,
            'textTransform': 'none',
            ':hover': {
              backgroundColor: COLORS.gray100,
            },
          }}
        >
          {language === 'ko' ? '취소' : 'Cancel'}
          {/* Cancel */}
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onDelete && onDelete();
          }}
          sx={{
            'height': '40px',
            'backgroundColor': COLORS.negative1,
            'borderRadius': '8px',
            'boxShadow': 'none',
            'fontSize': '13px',
            'fontWeight': 700,
            'textTransform': 'none',
            ':hover': {
              backgroundColor: COLORS.negative1,
            },
          }}
        >
          {language === 'ko' ? '삭제' : 'Delete'}
          {/* Delete */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
