import styled from '@emotion/styled';
import { IconButton, Popover, Tooltip } from '@mui/material';
import { categoryAtom } from 'atoms/category';
import { selectedProjectAtom } from 'atoms/projects';
import { Icons } from 'components';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useAtom } from 'jotai';
import dayjs, { Dayjs } from 'lib/dayjs';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import {
  createCategoryV1CategoryPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  updateProjectV1ProjectProjectIdPatch,
  updateRoutineV1RoutineRoutineIdPatch,
} from 'queries';
import { OutCategory, OutProject, OutRoutine, OutTaskboxDetailProjectOnly, OutTaskboxDetailRoutineOnly, RoutineStatusEnum } from 'queries/model';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { css } from '@emotion/react';
import { selectedRoutineAtom } from 'atoms/routine';
import { languageAtom } from 'atoms/language';
import { DATE_FORMAT_2 } from 'utils/datetimeFormat';

interface RoutineViewProps {
  routine?: OutRoutine;
  onKeydownRoutine?: (e: React.KeyboardEvent<HTMLDivElement>, routineId: string) => void;
  onClickRoutineCheck?: (routineId: string, status: RoutineStatusEnum) => void;
  onSelectRoutine?: (routine: OutRoutine) => void;
  onFetch?: () => void;
  onDrop?: (routineId: string) => void;
}

const RoutineView = ({
  routine,
  onKeydownRoutine = () => {},
  onClickRoutineCheck = () => {},
  onSelectRoutine = () => {},
  onFetch = () => {},
  onDrop = () => {},
}: RoutineViewProps) => {
  const [language] = useAtom(languageAtom);
  const [routineStatusPopover, setRoutineStatusPopover] = useState<HTMLElement | null>();
  const [routineSubtasks, setRoutineSubtasks] = useState<OutTaskboxDetailRoutineOnly[]>(routine?.tasks || []);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedRoutine] = useAtom(selectedRoutineAtom);

  useEffect(() => {
    setRoutineSubtasks(routine!.tasks!);
  }, [routine]);

  const handleClickRoutineCheck = async (status: RoutineStatusEnum) => {
    onClickRoutineCheck(routine!.id, status);
    setRoutineStatusPopover(null);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!routine) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            await updateRoutineV1RoutineRoutineIdPatch(routine.id!, { ...routine, categoryId: category ? category.id : undefined });
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 선택할 수 없습니다.' : 'Failed to select category.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 생성할 수 없습니다.' : 'Failed to create category.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 삭제할 수 없습니다.' : 'Failed to delete category.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 수정할 수 없습니다.' : 'Failed to update category.');
          }
        }
        break;
    }
  };

  const handleDropToRoutine = async (projectId: string) => {
    onDrop(projectId);
    setIsDragOver(false);
  };

  const showingTime = (datetime: string) => {
    const time = dayjs(datetime, { utc: true });
    const hours = time.format('a h시');
    const minutes = time.minute();

    return language === 'ko' ? (minutes === 0 ? hours : `${hours} ${minutes}분`) : dayjs(time).format('a h:mm');
  };

  const showingReccurence = (routine: OutRoutine) => {
    const { recurrence } = routine;
    const selectableRecurrenceDays = [
      { kr: '일', en: 'SU' },
      { kr: '월', en: 'MO' },
      { kr: '화', en: 'TU' },
      { kr: '수', en: 'WE' },
      { kr: '목', en: 'TH' },
      { kr: '금', en: 'FR' },
      { kr: '토', en: 'SA' },
    ];

    if (recurrence.interval === 1) {
      if (recurrence.intervalUnit === 'DAILY') {
        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Daily, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence.occurrences) {
          if (language === 'ko') {
            return `매일, ${recurrence.occurrences}회`;
          } else {
            return `Daily, ${recurrence.occurrences} times`;
          }
        } else {
          return language === 'ko' ? `매일` : `Daily`;
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays: string[] = [];
        const targetArray = language === 'ko' ? ['월요일', '화요일', '수요일', '목요일', '금요일'] : ['MO', 'TU', 'WE', 'TH', 'FR'];
        // const targetArray = ['MO', 'TU', 'WE', 'TH', 'FR'];
        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        const weekEveryday = newWeekdays.length === targetArray.length && targetArray.every((day) => newWeekdays.includes(day));

        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Weekly on ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence.occurrences) {
          if (language === 'ko') {
            return `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`;
          } else {
            return `Weekly on ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`;
          }
        } else {
          if (weekEveryday) {
            return language === 'ko' ? `주중 매일(월-금)` : `Every weekday (Mon-Fri)`;
          } else {
            if (language === 'ko') {
              return `매주 ${newWeekdays.join(', ')}`;
            } else {
              return `Weekly on ${newWeekdays.join(', ')}`;
            }
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`;
          //   } else {
          //     return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}, until ${dayjs(
          //       recurrence.endDate,
          //     ).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, ${recurrence.occurrences}회`;
            } else {
              return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}, ${recurrence.occurrences} times`;
            }
          } else {
            if (language === 'ko') {
              return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일`;
            } else {
              return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}`;
            }
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
          //       'dddd',
          //     )}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   } else {
          //     return `Monthly on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}, until ${dayjs(
          //       recurrence.endDate,
          //     ).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}, ${
                recurrence.occurrences
              }회`;
            } else {
              return `Monthly on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}, ${
                recurrence.occurrences
              } times`;
            }
          } else {
            if (language === 'ko') {
              return `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}`;
            } else {
              return `Monthly on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}`;
            }
          }
        } else {
          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(
          //       DATE_FORMAT_2,
          //     )}`;
          //   } else {
          //     return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}, until ${dayjs(
          //       recurrence.endDate,
          //     ).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, ${recurrence.occurrences}회`;
            } else {
              return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}, ${recurrence.occurrences} times`;
            }
          } else {
            if (language === 'ko') {
              return `매월 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일`;
            } else {
              return `Monthly on day ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}`;
            }
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `매년 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
        //       routine.lastMasterStart.date || routine.lastMasterStart.datetime,
        //     ).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Annually on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
        //       routine.lastMasterStart.date || routine.lastMasterStart.datetime,
        //     ).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence.occurrences) {
          if (language === 'ko') {
            return `매년 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}일, ${recurrence.occurrences}회`;
          } else {
            return `Annually on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}, ${recurrence.occurrences} times`;
          }
        } else {
          if (language === 'ko') {
            return `매년 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}일`;
          } else {
            return `Annually on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}`;
          }
        }
      }
    } else {
      if (recurrence.intervalUnit === 'DAILY') {
        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Every ${recurrence.interval} days, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence?.occurrences) {
          if (language === 'ko') {
            return `${recurrence.interval}일마다, ${recurrence.occurrences}회`;
          } else {
            return `Every ${recurrence.interval} days, ${recurrence.occurrences} times`;
          }
        } else {
          if (language === 'ko') {
            return `${recurrence.interval}일마다`;
          } else {
            return `Every ${recurrence.interval} days`;
          }
        }
      } else if (recurrence.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        if (recurrence.weekdays) {
          for (const weekdayCode of recurrence.weekdays) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === weekdayCode);
            if (weekday) {
              language === 'ko' ? newWeekdays.push(`${weekday.kr}요일`) : newWeekdays.push(`${weekday.en}`);
              // newWeekdays.push(`${weekday.en}`);
            }
          }
        }

        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence.occurrences) {
          if (language === 'ko') {
            return `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`;
          } else {
            return `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}, ${recurrence.occurrences} times`;
          }
        } else {
          if (language === 'ko') {
            return `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`;
          } else {
            return `Every ${recurrence.interval} weeks ${newWeekdays.join(', ')}`;
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `${recurrence.interval}개월마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, 종료일: ${dayjs(
          //       recurrence.endDate,
          //     ).format(DATE_FORMAT_2)}`;
          //   } else {
          //     return `Every ${recurrence.interval} months on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
          //       'D',
          //     )}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일, ${
                recurrence.occurrences
              }회`;
            } else {
              return `Every ${recurrence.interval} months on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}, ${
                recurrence.occurrences
              } times`;
            }
          } else {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}일`;
            } else {
              return `Every ${recurrence.interval} months on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('D')}`;
            }
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          const suffix =
            recurrence.monthlyTypeOrder === 1
              ? `${recurrence.monthlyTypeOrder}st`
              : recurrence.monthlyTypeOrder === 2
              ? `${recurrence.monthlyTypeOrder}nd`
              : recurrence.monthlyTypeOrder === 3
              ? `${recurrence.monthlyTypeOrder}rd`
              : `${recurrence.monthlyTypeOrder}th`;

          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(
          //       routine.lastMasterStart.date || routine.lastMasterStart.datetime,
          //     ).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   } else {
          //     return `Every ${recurrence.interval} months on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
          //       'dddd',
          //     )}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(
                routine.lastMasterStart.date || routine.lastMasterStart.datetime,
              ).format('dddd')}, ${recurrence.occurrences}회`;
            } else {
              return `Every ${recurrence.interval} months on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
                'dddd',
              )}, ${recurrence.occurrences} times`;
            }
          } else {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(
                routine.lastMasterStart.date || routine.lastMasterStart.datetime,
              ).format('dddd')}`;
            } else {
              return `Every ${recurrence.interval} months on the ${suffix} ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
                'dddd',
              )}`;
            }
          }
        } else {
          // if (recurrence.endDate) {
          //   if (language === 'ko') {
          //     return `${recurrence.interval}개월마다 마지막 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
          //       'dddd',
          //     )}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   } else {
          //     return `Every ${recurrence.interval} months on the last ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
          //       'dddd',
          //     )}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
          //   }
          // }
          if (recurrence.occurrences) {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 마지막 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}, ${
                recurrence.occurrences
              }회`;
            } else {
              return `Every ${recurrence.interval} months on the last ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
                'dddd',
              )}, ${recurrence.occurrences} times`;
            }
          } else {
            if (language === 'ko') {
              return `${recurrence.interval}개월마다 마지막 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('dddd')}`;
            } else {
              return `Every ${recurrence.interval} months on the last ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format(
                'dddd',
              )}`;
            }
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        // if (recurrence.endDate) {
        //   if (language === 'ko') {
        //     return `${recurrence.interval}년마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
        //       routine.lastMasterStart.date || routine.lastMasterStart.datetime,
        //     ).format('D')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   } else {
        //     return `Every ${recurrence.interval} years on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
        //       routine.lastMasterStart.date || routine.lastMasterStart.datetime,
        //     ).format('D')}, until ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`;
        //   }
        // }
        if (recurrence.occurrences) {
          if (language === 'ko') {
            return `${recurrence.interval}년마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}일, ${recurrence.occurrences}회`;
          } else {
            return `Every ${recurrence.interval} years on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}, ${recurrence.occurrences} times`;
          }
        } else {
          if (language === 'ko') {
            return `${recurrence.interval}년마다 ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('M')}월 ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}일`;
          } else {
            return `Every ${recurrence.interval} years on ${dayjs(routine.lastMasterStart.date || routine.lastMasterStart.datetime).format('MMMM')} ${dayjs(
              routine.lastMasterStart.date || routine.lastMasterStart.datetime,
            ).format('D')}`;
          }
        }
      }
    }
  };

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragOver(false);
      }}
      style={{ width: '100%' }}
    >
      <div hidden={isDragOver} className="routine-card">
        <RoutineContainer
          key={routine!.id}
          isFocus={selectedRoutine?.id === routine?.id}
          // done={routine?.status === 'DONE'}
          onClick={(e) => {
            e.stopPropagation();
            onSelectRoutine(routine!);
          }}
        >
          <RoutineLine />
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0px 4px', marginLeft: '8px' }}>
            <div>
              <RoutineHeader>
                {routine && routine.category && (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(routine.category.color)}
                    bgColor={getCategoryBgColor(routine.category.color)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryAnchorEl(e.currentTarget);
                    }}
                  >
                    {`# ${routine.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(routine.category!.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} stroke={getCategoryTextColor(routine.category!.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                )}
                <RoutineReccurenceWrapper>
                  {routine?.lastMasterStart.datetime
                    ? language === 'ko'
                      ? `${showingReccurence(routine!)} ${showingTime(routine.lastMasterStart.datetime)}`
                      : `${showingTime(routine.lastMasterStart.datetime)}, ${showingReccurence(routine!)}`
                    : `${showingReccurence(routine!)}`}
                </RoutineReccurenceWrapper>
              </RoutineHeader>
              <RoutineContent>
                {routine?.status === 'ACTIVE' && (
                  <PriorityIssueCheckbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setRoutineStatusPopover(e.currentTarget);
                    }}
                    checked={false}
                    bordercolor={COLORS.sub4}
                    markercolor={COLORS.sub5}
                    width={20}
                    height={20}
                  />
                )}
                {routine?.status === 'HOLD' && (
                  <Tooltip title={language === 'ko' ? '홀드 해제' : 'Unhold'} disableInteractive>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickRoutineCheck('ACTIVE');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.sub4 }}
                    >
                      <Icons.DeskIssueHold width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {routine?.status === 'ARCHIVED' && (
                  <Tooltip title={language === 'ko' ? '아카이브 해제' : 'Unarchive'} disableInteractive>
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickRoutineCheck('ACTIVE');
                      }}
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.sub4 }}
                    >
                      <Icons.DeskIssueCheck />
                    </IconButton>
                  </Tooltip>
                )}
                <ProjectContentTitle contentEditable={false} suppressContentEditableWarning={true} onKeyDown={(e) => onKeydownRoutine(e, routine!.id)}>
                  {routine!.title}
                </ProjectContentTitle>
              </RoutineContent>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {routine && routine.memo && (
                <div>
                  <Icons.TaskboxMemo width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {routine && routine.links && routine.links.length > 0 && (
                <div style={{ marginLeft: 4 }}>
                  <Icons.Link2 width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {routineSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length > 0 && (
                <RoutineSubtask>
                  <Icons.Subtask width={18} height={18} stroke={COLORS.gray500} />
                  <div style={{ color: COLORS.gray500, marginLeft: '4px', marginTop: 2 }}>
                    {routineSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length}
                  </div>
                </RoutineSubtask>
              )}
            </div>
          </div>
          {routineStatusPopover && (
            <Popover
              className="routine-status"
              open={Boolean(routineStatusPopover)}
              anchorEl={routineStatusPopover}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ marginTop: 0.5 }}
              onClose={() => setRoutineStatusPopover(null)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RoutineStatusWrapper>
                <RoutineStatusDetail
                  onClick={() => {
                    handleClickRoutineCheck('HOLD');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                    <Icons.DeskIssueHold />
                  </IconButton>
                  {language === 'ko' ? '홀드' : 'Hold'}
                </RoutineStatusDetail>
                <RoutineStatusDetail
                  onClick={() => {
                    handleClickRoutineCheck('ARCHIVED');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.sub4, marginRight: '8px' }}>
                    <Icons.DeskIssueCheck />
                  </IconButton>
                  {language === 'ko' ? '아카이브' : 'Archive'}
                </RoutineStatusDetail>
              </RoutineStatusWrapper>
            </Popover>
          )}
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setCategoryAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
        </RoutineContainer>
      </div>
      <RoutineDropContainer hidden={!isDragOver} onDrop={() => handleDropToRoutine(routine!.id)} />
    </div>
  );
};

const RoutineDropContainer = styled.div`
  width: 582px;
  height: 102px;
  background-color: ${COLORS.sub5};
  border-radius: 8px;
  border: 1px dashed ${COLORS.sub4};
`;

const RoutineContainer = styled.div<{ done?: boolean; isFocus?: boolean }>`
  width: 582px;
  height: 102px;
  display: flex;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid ${COLORS.gray200};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 20px 1px ${COLORS.gray200};

    .routine-detail {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isFocus &&
    css`
      border: 1px solid ${COLORS.sub4};
    `}

  ${(props) =>
    props.done &&
    css`
      opacity: 0.6;
    `}
`;

const RoutineLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.sub4};
  border-radius: 100px;
`;

const RoutineHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RoutineReccurenceWrapper = styled.div`
  background-color: ${COLORS.gray100};
  border-radius: 4px;
  color: ${COLORS.gray600};
  font-size: 10px;
  padding: 2px 6px;
`;

const RoutineContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;
`;

const ProjectContentTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-left: 8px;
`;

const RoutineSubtask = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4px;
`;

const CabinetContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CabinetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const RoutineStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0px;
`;

const RoutineStatusDetail = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

export default RoutineView;
