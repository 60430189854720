import { SVGProps } from 'react';

export const Membership = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none" {...props}>
      <path
        d="M3 8.99997C3.04244 9 3.08617 9 3.13127 9H7M3 8.99997C2.34115 8.99947 1.99556 8.99121 1.72786 8.85482C1.47698 8.72699 1.27316 8.52248 1.14532 8.2716C1 7.98638 1 7.61353 1 6.8668V3.13346C1 2.38673 1 2.01308 1.14532 1.72786C1.27316 1.47698 1.47698 1.27316 1.72786 1.14532C2.01308 1 2.38673 1 3.13346 1H10.8668C11.6135 1 11.9864 1 12.2716 1.14532C12.5225 1.27316 12.727 1.47698 12.8548 1.72786C13 2.0128 13 2.386 13 3.13127V6.86873C13 7.614 13 7.98666 12.8548 8.2716C12.727 8.52248 12.5225 8.72699 12.2716 8.85482C11.9867 9 11.614 9 10.8687 9H7M3 8.99997C3.00003 8.2636 3.89545 7.66667 5 7.66667C6.10457 7.66667 7 8.26362 7 9M3 8.99997C3 8.99998 3 8.99996 3 8.99997ZM11 6.33333H8.33333M11 4.33333H9M5 5.66667C4.26362 5.66667 3.66667 5.06971 3.66667 4.33333C3.66667 3.59695 4.26362 3 5 3C5.73638 3 6.33333 3.59695 6.33333 4.33333C6.33333 5.06971 5.73638 5.66667 5 5.66667Z"
        stroke={props.stroke || '#1F2023'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
