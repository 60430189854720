import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';
import { Close } from 'components/Icons';
import { AppUserModelOutUser } from 'queries/model';
import { text } from 'stream/consumers';

interface InputTagProps {
  size?: number;
  disabled?: boolean;
  complete?: boolean;
  change?: string;
  selectedEventMembers: AppUserModelOutUser[];
  onClick: () => void;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeMember: (member: AppUserModelOutUser[]) => void;
  onAddWorkspaceMember: (user: any) => void;
  onChangeMemberModal: () => void;
  findMemberModal: boolean;
  workspaceMembers: AppUserModelOutUser[];
}

export const InputTag = ({
  size = 240,
  disabled = false,
  complete = false,
  change,
  selectedEventMembers,
  onChange,
  onKeyPress,
  onClick,
  label,
  onChangeMember,
  onAddWorkspaceMember,
  findMemberModal,
  workspaceMembers,
  onChangeMemberModal,
}: InputTagProps) => {
  return (
    <Wrapper>
      <Input
        onClick={onClick}
        className="member-input"
        style={{ maxWidth: '412px' }}
        name="input-tag"
        type="text"
        disabled={disabled}
        size={size}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={label}
        value={change}
      />
      <div style={{ position: 'relative' }}>
        <NameContainer>
          {selectedEventMembers && selectedEventMembers.length > 0
            ? selectedEventMembers.map((text) =>
                text.name === '' ? (
                  <Name key={text.id} style={{ backgroundColor: `${COLORS.alert2}` }}>
                    <Fonts.Blockquote style={{ color: `${COLORS.gray900}`, marginRight: '4px' }}>{text.email}</Fonts.Blockquote>
                    <Button
                      style={{ backgroundColor: `${COLORS.alert2}` }}
                      onClick={() => {
                        const filtertedText = selectedEventMembers.filter((value) => value.id !== text.id);
                        onChangeMember(filtertedText);
                      }}
                    >
                      <Close width={14} height={14} fill={COLORS.gray500} />
                    </Button>
                  </Name>
                ) : (
                  <Name key={text.id}>
                    <Fonts.Blockquote style={{ color: `${COLORS.gray900}`, marginRight: '4px' }}>{text.name}</Fonts.Blockquote>
                    <Button
                      onClick={() => {
                        const filtertedText = selectedEventMembers.filter((value) => value.id !== text.id);
                        onChangeMember(filtertedText);
                      }}
                    >
                      <Close width={14} height={14} fill={COLORS.gray500} />
                    </Button>
                  </Name>
                ),
              )
            : null}
        </NameContainer>
        {findMemberModal && (
          <MemberModal>
            <>
              <MemberModalHeader>
                <Fonts.Blockquote>워크스페이스 멤버</Fonts.Blockquote>
                <button onClick={onChangeMemberModal}>
                  <Close width={16} height={16} />
                </button>
              </MemberModalHeader>
              {workspaceMembers.map((user, index) => (
                <SelectMemberContainer
                  key={user.id}
                  onClick={() => {
                    onAddWorkspaceMember(user);
                  }}
                >
                  <div style={{ marginRight: '10px' }} key={index}>
                    {user.name ? user.name : user.email}
                  </div>
                  {user.profileImage ? (
                    <img src={user.profileImage} alt="user-profileImage" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                  ) : null}
                </SelectMemberContainer>
              ))}
            </>
          </MemberModal>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  border: none;
`;

const Input = styled.input<{
  disabled: boolean;
  size: number;
}>`
  width: ${(props) => props.size}px;
  height: 48px;
  background-color: ${COLORS.white};
  box-sizing: border-box;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding-left: 16px;
  color: ${COLORS.gray500};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  outline: none !important;
  &:focus {
    border: 1px solid ${COLORS.brand1};
    color: ${COLORS.gray900};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      color: ${COLORS.gray500};
      background-color: ${COLORS.gray100};
    `}
`;

const NameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 412px;
  max-height: 102px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    height: 10%; /* 스크롤바의 길이 */
    background: ${COLORS.sub3}; /* 스크롤바의 색상 */
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${COLORS.white}; /*스크롤바 뒷 배경 색상*/
  }
`;

const Name = styled.div`
  width: fit-content;
  height: 26px;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.sub3};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  margin-top: 8px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 6px;
`;

const Button = styled.button`
  width: 14px;
  height: 14px;
  background-color: ${COLORS.sub3};
  border: none;
  padding: 0px;
  cursor: pointer;
`;

const MemberModal = styled.div`
  position: absolute;
  top: 8px;
  width: 412px;
  padding: 16px 0px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px #1a1e2729;
  background-color: ${COLORS.white};
`;

const MemberModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px;
  width: 410px;
  margin-bottom: 6px;
`;

const SelectMemberContainer = styled.div`
  width: 410px;
  height: 32px;
  padding: 9px 0px 8px 16px;
  font-family: Pretendard;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.25px;
  text-align: left;
  color: ${COLORS.gray900};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
    color: ${COLORS.brand1};
  }
  display: flex;
  align-items: center;
`;
