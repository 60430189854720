import { SVGProps } from 'react';

export const Postpone = ({ width = 16, height = 16, stroke = '#C8CDDB', fill = '#C8CDDB' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0013 3.33203H4.0013C3.26492 3.33203 2.66797 3.92898 2.66797 4.66536V12.6654C2.66797 13.4017 3.26492 13.9987 4.0013 13.9987H12.0013C12.7377 13.9987 13.3346 13.4017 13.3346 12.6654V4.66536C13.3346 3.92898 12.7377 3.33203 12.0013 3.33203Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.668 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.33203 2V4.66667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="4.5" y="7" width="1" height="1" fill={fill} />
      <rect x="6.5" y="7" width="1" height="1" fill={fill} />
      <rect x="8.5" y="7" width="1" height="1" fill={fill} />
      <rect x="10.5" y="7" width="1" height="1" fill={fill} />
      <rect x="4.5" y="9" width="1" height="1" fill={fill} />
      <rect x="6.5" y="9" width="1" height="1" fill={fill} />
      <rect x="8.5" y="9" width="1" height="1" fill={fill} />
      <rect x="10.5" y="9" width="1" height="1" fill={fill} />
      <rect x="4.5" y="11" width="1" height="1" fill={fill} />
      <rect x="6.5" y="11" width="1" height="1" fill={fill} />
      <rect x="8.5" y="11" width="1" height="1" fill={fill} />
      <rect x="10.5" y="11" width="1" height="1" fill={fill} />
    </svg>
  );
};
