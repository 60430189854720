import React, { SVGProps, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import { setCaretToEnd, setCaretPosition, getCaretPosition } from 'utils';
import { Icons } from 'components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { debounce, isEqual } from 'lodash';
import TaskTag from 'components/TaskTag';
import dayjs, { Dayjs } from 'lib/dayjs';
import { Checkbox, CheckboxProps, CircularProgress, Divider, Icon, IconButton, Popover, Popper, Tooltip } from '@mui/material';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import {
  createTaskboxV1TaskboxesPost,
  createWorkboxV2WorkboxesPost,
  updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  updateTaskboxV1TaskboxesTaskboxIdPut,
} from 'queries';
import { hideScroll } from 'styles/utils';
import {
  CreateTaskbox,
  OutCategory,
  OutProject,
  OutTaskboxDetailProjectOnly,
  OutTaskboxDetailResponse,
  OutTaskboxDetailResponseForSubtask,
} from 'queries/model';
import { DATE_FORMAT_4 } from 'utils/datetimeFormat';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { DeadlinePopover } from './DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { useAtom } from 'jotai';
import toast from 'react-hot-toast';
import { isPrintableKey } from 'utils/keyUp';
import { selectedProjectAtom, syncInstanceAtom } from 'atoms/projects';
import { useDebouncedSync } from 'utils/debounce';
import { MultiSelectSubtaskIds } from '../Today';
import { useClickOutside } from '@react-hookz/web';
import SubtaskMultiSelectPopover from './SubtaskMultiSelectPopover';
import { languageAtom } from 'atoms/language';
import { SubtaskMode } from './TimelineTaskbox';
import SubtaskModeMenu from './SubtaskModeMenu';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TaskBlockInputListContainer = styled.div`
  width: 100%;
  max-height: 240px;
  overflow-y: scroll;
  padding-left: 22px;
  ${hideScroll()}
`;

const BlockAddArea = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  padding-left: 29px;
  margin-top: 4px;

  :hover {
    .additional-btn {
      display: flex;
      align-items: center;
    }
  }

  .additional-btn {
    display: none;
  }

  .add-subtask {
    :hover {
      background-color: ${COLORS.gray100};
    }
  }

  .add-time {
    :hover {
      background-color: ${COLORS.gray100};
    }
  }

  .load-subtask {
    :hover {
      background-color: ${COLORS.gray100};
    }
  }

  .template-wrapper {
    :hover {
      background-color: ${COLORS.gray100};
    }
  }

  .template {
    &:hover {
      background-color: transparent;
      filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
    }
  }
`;

const EditableTextFieldWrapper = styled.div<{ select?: boolean; multiSelected?: boolean }>`
  position: relative;
  word-break: break-all;
  cursor: 'text';
  border: 1px solid transparent;
  padding: 3px 6px;
  border-radius: 8px;
  margin-bottom: 4px;

  .task-more-btn {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  :hover {
    background-color: ${COLORS.gray100};

    .task-more-btn {
      opacity: 1;
    }
    .task-more-wrapper {
      background-color: ${COLORS.white};
    }
    .drag-handle {
      opacity: 0.4;
    }
  }

  ${(props) => props.select && `border: 1px solid ${COLORS.gray200}`}

  ${(props) => props.multiSelected && `background-color: ${COLORS.gray100};`}
`;

const EditableTextFieldStyle = styled.div<{ select?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DragHandleIcon = styled.span<{ isHover?: boolean }>`
  position: absolute;
  opacity: ${(props) => (props.isHover ? 0.4 : 0)};
  transition: opacity 0.3s ease-in-out;
  left: -14px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const EditableTextField = styled.div<{ size?: TaskBlockListSize }>`
  width: 100%;
  flex: 1;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  ${(props) => (props.size === 'large' ? `font-size: 16px;` : `font-size: 13px;`)}

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
      color: ${COLORS.gray500};
    }
  }
`;

const TruncateTaskTag = styled(TaskTag)`
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CalendarInitButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: 12px;
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

const TaskMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 2px;
  position: absolute;
  right: 2px;
`;

const TaskIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 4px;
  padding: 2px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);

    .later {
      filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
    }
    .delete {
      filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
    }
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const RecommendTaskWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  padding: 0px 4px;
`;

const RecommendButtonRect = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  background-color: ${COLORS.gray100};
  border-radius: 2px;
  font-size: 8px;
  padding: 0px 4px;
`;

const TaskCheckIcon = ({ stroke = '#ABB0BF' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke={stroke} />
  </svg>
);

const TaskCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="4" fill={props?.fill || COLORS.brand1} />
    <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const TaskFocusCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="4" fill="url(#paint0_linear_17862_94586)" />
    <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_17862_94586" x1="8.02215" y1="0.282359" x2="8.01772" y2="15.6729" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C471ED" />
        <stop offset="1" stopColor="#F64F59" />
      </linearGradient>
    </defs>
  </svg>
);

const TaskFocusUncheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="url(#paint0_linear_17862_134882)" />
    <defs>
      <linearGradient id="paint0_linear_17862_134882" x1="8.02215" y1="0.282359" x2="8.01772" y2="15.6729" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C471ED" />
        <stop offset="1" stopColor="#F64F59" />
      </linearGradient>
    </defs>
  </svg>
);

const TaskCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} sx={{ padding: 0, margin: 0, ...props.sx }} style={{ width: 16, height: 16, ...props.style }} />;
};

export type TaskBlock = {
  id: string;
  title?: string;
  content?: string;
  dueDate?: string;
  done?: boolean;
  doneAt?: string;
  meetingNoteId?: string;
  meetingNoteTitle?: string;
  category?: OutCategory;
  deadline?: string | null;
};

export type ProjectTaskBlockAction = 'CREATE' | 'UPDATE' | 'DELETE' | 'MOVE_TO_AFTER' | 'TAB';

export type TaskBlockListSize = 'small' | 'medium' | 'large';

export interface TaskBlockHandle {
  add: () => void;
}

export interface TaskBlockInputListProps {
  tasks?: TaskBlock[];
  taskbox?: OutTaskboxDetailResponse;
  categoryList?: OutCategory[];
  date?: Date | null;
  project?: OutProject;
  instance?: OutTaskboxDetailProjectOnly;
  size?: TaskBlockListSize;
  draggable?: boolean;
  maxHeight?: boolean;
  suppressKeyEvent?: boolean;
  suppressModification?: boolean;
  suppressCheckboxToggle?: boolean;
  suppressVisibleTag?: boolean;
  suppressTemplate?: boolean;
  suppressCategory?: boolean;
  suppressDeadline?: boolean;
  suppressLoadSubtask?: boolean;
  suppressMoveToAfter?: boolean;
  suppressTaskMore?: boolean;
  suppressAddTime?: boolean;
  suppressTaskboxCategoryDeadline?: boolean;
  done?: boolean;
  projectDoneFilter?: boolean;
  isRecurrence?: boolean;
  isRoutine?: boolean;
  isFocus?: boolean;
  isProjectUnallocateSubtask?: boolean;
  isMoveToInstance?: boolean;
  multiSelectSubtaskIds?: MultiSelectSubtaskIds[];
  multiSelectSubtaskAnchorEl?: HTMLElement | null;
  onChange?: (tasks: TaskBlock[]) => void;
  onLoadTemplate?: () => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
  onChangeInstanceSubtask?: (block: TaskBlock, action: ProjectTaskBlockAction) => void;
  onChangeUnallocateSubtask?: (block: TaskBlock, action: ProjectTaskBlockAction) => void;
  onChangeProjectDetailInstanceSubtask?: (instanceId: string, block: TaskBlock, action: ProjectTaskBlockAction, index?: number, blocks?: TaskBlock[]) => void;
  onPushProjectRecommendTask?: (tasks: TaskBlock[], blockId: string, index: number) => void;
  onClickLoadSubtaskFromProject?: () => void;
  onFocusOnTitle?: () => void;
  onCreateListViewTaskbox?: () => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, block: OutTaskboxDetailProjectOnly) => void;
  onDragEnd?: () => void;
  onChangeMultiSelectSubtaskIds?: (subtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => void;
  onClickMultiSelectSubtaskAction?: (type: InboxContextMenuType, data: any) => void;
  onCloseMultiSelectSubtask?: () => void;
  onMultiSelectSubtaskAnchorElNull?: () => void;
  onSetTaskboxTime?: () => void;
  onSetTaskboxCategory?: () => void;
  onSetTaskboxDeadline?: () => void;
}

const TaskBlockInputList = (props: TaskBlockInputListProps, ref: any) => {
  const {
    tasks = [],
    categoryList = [],
    taskbox,
    date,
    project,
    instance,
    size = 'small',
    draggable = true,
    maxHeight = true,
    suppressKeyEvent = false,
    suppressModification = false,
    suppressCheckboxToggle = false,
    suppressVisibleTag = false,
    suppressTemplate = false,
    suppressCategory = false,
    suppressDeadline = false,
    suppressLoadSubtask = false,
    suppressMoveToAfter = false,
    suppressTaskMore = false,
    suppressAddTime = false,
    suppressTaskboxCategoryDeadline = false,
    done = false,
    projectDoneFilter,
    isRecurrence = false,
    isRoutine = false,
    isFocus = false,
    isProjectUnallocateSubtask = false,
    isMoveToInstance = false,
    multiSelectSubtaskIds = [],
    multiSelectSubtaskAnchorEl,
    onChange,
    onLoadTemplate,
    onClickCategory,
    onChangeInstanceSubtask,
    onChangeUnallocateSubtask,
    onChangeProjectDetailInstanceSubtask,
    onPushProjectRecommendTask,
    onClickLoadSubtaskFromProject,
    onFocusOnTitle,
    onCreateListViewTaskbox,
    onDragStart,
    onDragEnd,
    onChangeMultiSelectSubtaskIds,
    onClickMultiSelectSubtaskAction,
    onCloseMultiSelectSubtask,
    onMultiSelectSubtaskAnchorElNull,
    onSetTaskboxTime,
    onSetTaskboxCategory,
    onSetTaskboxDeadline,
  } = props;
  const [language] = useAtom(languageAtom);
  const [blocks, setBlocks] = useState<TaskBlock[]>([]);
  const [clickedTask, setClickedTask] = useState<TaskBlock | undefined>();
  const [isProject, setIsProject] = useState<boolean>(false);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [, setSyncInstance] = useAtom(syncInstanceAtom);
  const [selectedProject] = useAtom(selectedProjectAtom);
  const refPopperMenu = useRef<HTMLDivElement>(null);
  const debouncedSyncInstance = useDebouncedSync(setSyncInstance, 100);
  const [subtaskMode, setSubtaskMode] = useState<SubtaskMode>('IMPORT');
  const [subtaskModeAnchorEl, setSubtaskModeAnchorEl] = useState<HTMLElement | null>(null);
  const [projectSubtasks, setProjectSubtasks] = useState<OutTaskboxDetailProjectOnly[]>([]);
  const [recommendTaskIndex, setRecommendTaskIndex] = useState<number>(0);
  const [projectDayTasks, setProjectDayTasks] = useState<OutTaskboxDetailProjectOnly[]>([]);
  const [nextInstance, setNextInstance] = useState<OutTaskboxDetailProjectOnly | null>(null);
  const [delayRecommendTask, setDelayRecommendTask] = useState(false);
  const [isHoverLoadSubtasks, setIsHoverLoadSubtasks] = useState(false);
  const [isInstanceTyping, setIsInstanceTyping] = useState(false);
  const [isRemoveTextAndRecommend, setIsRemoveTextAndRecommend] = useState(false);

  useImperativeHandle(ref, () => ({
    add: () => {
      const block = createBlock();
      insertBlock(block);
      setFocusElement(block.id);
    },
  }));

  useEffect(() => {
    const taskList = tasks.map((item) => ({ ...item, id: item.id }));
    if (isEqual(tasks, blocks)) {
      taskList.forEach((task) => {
        const index = blocks.findIndex((block) => block.id === task.id);
        if (index !== -1)
          blocks[index] =
            isRoutine || isProjectUnallocateSubtask
              ? {
                  ...task,
                  id: task.id,
                  title: task.title || '',
                }
              : { ...task, id: task.id, content: task.content || '' };
      });
    } else {
      setBlocks(
        tasks.map((item) =>
          isRoutine || isProjectUnallocateSubtask
            ? {
                ...item,
                id: item.id,
                title: item.title || '',
              }
            : { ...item, id: item.id, content: item.content || '' },
        ),
      );
    }
  }, [tasks]);

  useEffect(() => {
    if (project?.id) setIsProject(true);
    else setIsProject(false);
  }, [project?.id]);

  useEffect(() => {
    if (project) {
      if (project.tasks.length > 0) {
        const somedayTasks = project!.tasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK' && !v.done) || [];
        const dayTasks =
          project!.tasks.filter(
            (v) => v.type === 'PROJ_DAY_TASK' || v.type === 'PROJ_TIME_TASK' || v.type === 'PROJ_LINK_DAY_TASK' || v.type === 'PROJ_LINK_TIME_TASK',
          ) || [];

        setProjectSubtasks(somedayTasks);
        setProjectDayTasks(dayTasks);

        const instanceIdx = instance ? dayTasks.findIndex((v) => v.id === instance?.id) : dayTasks.findIndex((v) => v.id === taskbox?.id);
        if (instanceIdx !== -1 && instanceIdx < dayTasks.length - 1) {
          setNextInstance(dayTasks[instanceIdx + 1]);
        } else {
          setNextInstance(null);
        }
      }
    }
  }, [project]);

  useEffect(() => {
    setTimeout(() => {
      if (delayRecommendTask) setDelayRecommendTask(false);
    }, 1000);
  }, [delayRecommendTask]);

  /**  외부 클릭 시 멀티셀렉 풀리게 */
  useClickOutside(
    refPopperMenu,
    (e: Event) => {
      const confirmDialog = document.querySelector('.confirm-dialog') as HTMLElement;
      if (confirmDialog) return;
      if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
      onCloseMultiSelectSubtask && onCloseMultiSelectSubtask();
    },
    ['mouseup'],
  );

  const updateBlocks = (blocks: TaskBlock[] = []) => {
    setBlocks(blocks);
    onChange && onChange(blocks);
  };

  const createBlock = (content?: any): TaskBlock => {
    return isProjectUnallocateSubtask || isRoutine
      ? {
          id: uuidv4(),
          title: content || '',
          done: false,
        }
      : {
          id: uuidv4(),
          content: content || '',
          done: false,
        };
  };

  const insertBlock = (block: TaskBlock, index = -1) => {
    if (suppressModification) return;

    if (index === -1) {
      updateBlocks([...blocks, block]);
    } else {
      const newBlocks = blocks.slice(0);
      newBlocks.splice(index, 0, block);
      updateBlocks(newBlocks);
    }
  };

  const deleteBlock = (blockId: string) => {
    if (suppressModification) return;

    const newBlocks = blocks.filter(({ id }) => id !== blockId);
    updateBlocks(newBlocks);
  };

  const swapBlock = (srcBlock: TaskBlock, dstBlock: TaskBlock) => {
    if (!srcBlock) return;
    if (!dstBlock) return;

    const dstBlockIndex = blocks.findIndex((item) => item.id === dstBlock.id);
    if (dstBlockIndex === -1) return;

    const newBlocks = blocks.filter((item) => item.id !== srcBlock.id);
    newBlocks.splice(dstBlockIndex, 0, srcBlock);

    updateBlocks([...newBlocks]);
  };

  const updateBlockData = (
    id: string,
    data: { title?: string; content?: string; dueDate?: string; done?: boolean; category?: OutCategory; deadline?: string | null },
  ) => {
    const blockIndex = blocks.map(({ id }) => id).indexOf(id);
    if (blockIndex === -1) return;
    blocks[blockIndex] = { ...blocks[blockIndex], ...data };
  };

  const setFocusElement = (blockId: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-field-id="${blockId}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickAdd = async () => {
    if (isRoutine && blocks.length >= 7) {
      toast.error('루틴 반복 하위 업무는 최대 7개까지만 입력 가능합니다.');
      return;
    }

    const block = createBlock();
    insertBlock(block);
    setClickedTask(block);
    if (isProject && isProjectUnallocateSubtask) onChangeUnallocateSubtask && onChangeUnallocateSubtask(block, 'CREATE');
    if (isProject && instance?.id) onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, block, 'CREATE');
    setFocusElement(block.id);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, blockId: string, index: number) => {
    if (delayRecommendTask) {
      e.preventDefault();
      return;
    }

    // 텍스트 삭제하여 빈 텍스트 시 태스크 추천
    if (isRemoveTextAndRecommend) setIsRemoveTextAndRecommend(false);

    // 인스턴스 타이핑 시작하면 추천 태스크 숨김
    if (isProject && isPrintableKey(e.code)) setIsInstanceTyping(true);

    if (suppressKeyEvent) {
      if (e.key === 'Enter') e.preventDefault();
      return;
    }

    const text = e.currentTarget.textContent || '';

    if (e.key === 'Escape') {
      onFocusOnTitle && onFocusOnTitle();
    }

    if (e.key === 'Backspace') {
      const caretPosition = window.getSelection()?.focusOffset;
      if (text && index >= 0 && caretPosition === 0) {
        const prevBlock = blocks[index - 1];
        const block = blocks.find(({ id }) => id === blockId);

        if (!block) return;
        if (!prevBlock) return;
        updateBlockData(
          prevBlock.id,
          isProjectUnallocateSubtask ? { title: `${prevBlock.title}${block?.title}` } : { content: `${prevBlock.content}${block?.content}` },
        );
        deleteBlock(block.id);
        if (isProject && isProjectUnallocateSubtask) {
          onChangeUnallocateSubtask && onChangeUnallocateSubtask(block, 'DELETE');
          onChangeUnallocateSubtask && onChangeUnallocateSubtask({ ...prevBlock, title: `${prevBlock.title}${block?.title}` }, 'UPDATE');
        }

        if (isProject && instance?.id) {
          onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, block, 'DELETE');
          onChangeProjectDetailInstanceSubtask &&
            onChangeProjectDetailInstanceSubtask(instance?.id, { ...prevBlock, content: `${prevBlock.content}${block?.content}` }, 'UPDATE');
        }

        setClickedTask(prevBlock);
        setTimeout(() => {
          const prevEl = document.querySelector(`[data-field-id="${prevBlock.id}"]`) as HTMLDivElement;
          prevEl && setCaretPosition(prevEl, prevBlock?.content?.length || 0);
        }, 100);

        e.preventDefault();
      } else {
        if (isInstanceTyping && text.length === 1) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }

        if (isProject && text.length === 1 && tasks.find(({ id }) => id === blockId)?.content !== '') {
          setTimeout(() => {
            setIsRemoveTextAndRecommend(true);
          }, 10);
        }

        if (!text) {
          const nextBlock = blocks[index === 0 ? index + 1 : index - 1];
          if (nextBlock) {
            setFocusElement(nextBlock.id);
            setClickedTask(nextBlock);
          }
          const dstBlock = blocks.find(({ id }) => id === blockId);
          if (dstBlock) {
            deleteBlock(dstBlock.id);
            if (isProject && isProjectUnallocateSubtask) onChangeUnallocateSubtask && onChangeUnallocateSubtask(dstBlock, 'DELETE');
            if (isProject && instance?.id) onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, dstBlock, 'DELETE');
            setTimeout(() => {
              if (dstBlock.id === blocks[0].id) onFocusOnTitle && onFocusOnTitle();
            }, 50);
          }

          e.preventDefault();
        }
      }

      return;
    }

    if (e.key === 'Enter' && e.shiftKey) {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      e.preventDefault();
      onCreateListViewTaskbox && onCreateListViewTaskbox();
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      if (isRoutine && blocks.length >= 7) {
        toast.error('루틴 반복 하위 업무는 최대 7개까지만 입력 가능합니다.');
        e.preventDefault();
        return;
      }

      const caret = getCaretPosition(e.target);
      const blockText = text || '';
      const block = blocks.find(({ id }) => id === blockId);

      const insertNewBlock = (startIndex: number, content: string) => {
        e.currentTarget.blur();
        const block = createBlock(content);
        insertBlock(block, startIndex);
        setFocusElement(block.id);
        setClickedTask(block);

        if (isProject && isProjectUnallocateSubtask) onChangeUnallocateSubtask && onChangeUnallocateSubtask(block, 'CREATE');
        if (isProject && instance?.id)
          onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, block, 'CREATE', startIndex, blocks);
      };

      if (caret === 0 && blockText.length !== 0) {
        insertNewBlock(index, '');
      } else if (caret === blockText.length) {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        insertNewBlock(index + 1, '');
      } else {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        updateBlockData(blockId, isProjectUnallocateSubtask ? { title: blockText.slice(0, caret) } : { content: blockText.slice(0, caret) });
        if (block && isProjectUnallocateSubtask) {
          onChangeUnallocateSubtask && onChangeUnallocateSubtask({ ...block, title: blockText.slice(0, caret) }, 'UPDATE');
        }
        if (block && isProject && instance?.id) {
          onChangeProjectDetailInstanceSubtask &&
            onChangeProjectDetailInstanceSubtask(instance?.id, { ...block, content: blockText.slice(0, caret) }, 'UPDATE');
        }
        insertNewBlock(index + 1, blockText.slice(caret, blockText.length));
      }
      return;
    }

    if (e.key === 'ArrowDown') {
      if (isInstanceTyping) {
        setTimeout(() => {
          setIsInstanceTyping(false);
        }, 30);
      }

      const blockIndex = index >= blocks.length - 1 ? blocks.length - 1 : index + 1;
      setFocusElement(blocks[blockIndex].id);
      setTimeout(() => {
        setClickedTask(blocks[blockIndex]);
      }, 50);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      if (isInstanceTyping) {
        setTimeout(() => {
          setIsInstanceTyping(false);
        }, 30);
      }

      const blockIndex = index <= 0 ? 0 : index - 1;
      setFocusElement(blocks[blockIndex].id);
      setTimeout(() => {
        setClickedTask(blocks[blockIndex]);
      }, 50);
      e.preventDefault();
      return;
    }

    if (text === '' && isProject && (!isProjectUnallocateSubtask || !instance?.id)) {
      if (e.key === 'ArrowRight') {
        setRecommendTaskIndex((prev) => (prev >= projectSubtasks.length - 1 ? 0 : prev + 1));
      }

      if (e.key === 'ArrowLeft') {
        setRecommendTaskIndex((prev) => (prev > 0 ? prev - 1 : projectSubtasks.length - 1));
      }

      if (e.key === 'Tab') {
        e.preventDefault();
        // 프로젝트 상세창에서 인스턴스 하위업무 추가 막기 => 사용 후 기능 풀릴수도
        if (!isProject || projectSubtasks.length === 0 || isProjectUnallocateSubtask || instance?.id) return;
        if (delayRecommendTask) return;

        updateBlockData(blockId, { content: projectSubtasks[recommendTaskIndex].title === '' ? '제목 없음' : projectSubtasks[recommendTaskIndex].title! });
        e.currentTarget.blur();
        const block = createBlock('');
        insertBlock(block, index + 1);
        setFocusElement(block.id);
        setClickedTask(block);

        if (isProject && !isProjectUnallocateSubtask && !instance?.id && projectSubtasks.length > 0) {
          setDelayRecommendTask(true);
        }

        if (isProject && !isProjectUnallocateSubtask) {
          onPushProjectRecommendTask &&
            onPushProjectRecommendTask(
              blocks.filter((v) => v.id !== blockId),
              projectSubtasks[recommendTaskIndex].id!,
              index,
            );
          setProjectSubtasks(projectSubtasks.filter((v) => v.id !== projectSubtasks[recommendTaskIndex].id && !v.done));
          if (recommendTaskIndex === projectSubtasks.length - 1) setRecommendTaskIndex(0);
        }

        if (isProject && instance?.id) {
          let blockList = [];

          if (index === -1) {
            blockList = [...blocks, block];
          } else {
            blockList = [...blocks.slice(0, index + 1), block, ...blocks.slice(index + 1)];
          }
          onChangeProjectDetailInstanceSubtask &&
            onChangeProjectDetailInstanceSubtask(instance?.id, projectSubtasks[recommendTaskIndex] as TaskBlock, 'TAB', index, blockList);

          setProjectSubtasks(projectSubtasks.filter((v) => v.id !== projectSubtasks[recommendTaskIndex].id && !v.done));
          if (recommendTaskIndex === projectSubtasks.length - 1) setRecommendTaskIndex(0);
        }
      }
    }
  };

  const handleInput = (e: React.FormEvent<HTMLDivElement>, id: string) => {
    const target = e.currentTarget as HTMLDivElement;
    // 프로젝트 상세창 열려있을 때
    if (selectedProject) {
      if (instance) {
        // 프로젝트 상세창 인스턴스 하위업무 수정 후 일지 태스크박스 하위업무와 동기화
        const newTasks = instance.tasks!.map((task) => {
          if (task.id === id && task.content !== target.textContent) {
            return { ...task, content: e.currentTarget.textContent };
          } else {
            return task;
          }
        });
        debouncedSyncInstance({
          ...instance,
          tasks: newTasks,
          syncType: 'project',
        });
        return;
      } else if (taskbox && taskbox.project) {
        // 일지 태스크박스 하위업무 수정 후 프로젝트 상세창 인스턴스와 동기화
        const newTasks = taskbox.tasks!.map((task) => {
          if (task.id === id && task.content !== target.textContent) {
            return { ...task, content: e.currentTarget.textContent };
          } else {
            return task;
          }
        });
        debouncedSyncInstance({
          ...taskbox,
          tasks: newTasks,
          syncType: 'taskbox',
        });
      } else {
        debouncedSyncInstance(null);
      }
    }
  };

  const handleKeydownAddButton = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (suppressKeyEvent) return;
    if (e.key !== 'Enter') return;
    if (e.nativeEvent.isComposing) return;
    if (e.repeat) {
      e.preventDefault();
      return;
    }

    const block = createBlock();
    insertBlock(block);
    setFocusElement(block.id);
    e.preventDefault();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const srcBlock = blocks.find((item) => item.id === result.draggableId);
    if (!srcBlock) return;

    const dstBlock = blocks[result.destination.index];
    if (!dstBlock) return;

    swapBlock(srcBlock, dstBlock);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, blockId: string) => {
    const block = blocks.find((item) => item.id === blockId);
    if (!block) return;

    isProjectUnallocateSubtask || isRoutine ? (block.title = e.currentTarget.textContent || '') : (block.content = e.currentTarget.textContent || '');

    const isBefore = dayjs(date).isBefore(dayjs(), 'date');
    if (isBefore) {
      block.done = true;
    }
    updateBlocks(blocks);
    if (block && isProjectUnallocateSubtask) {
      onChangeUnallocateSubtask && onChangeUnallocateSubtask({ ...block, title: e.currentTarget.textContent! }, 'UPDATE');
    }
    if (block && instance?.id) {
      onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, { ...block, content: e.currentTarget.textContent! }, 'UPDATE');
    }
    setClickedTask(undefined);
  };

  const handleChangeDone = async (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newBlocks = blocks.map((item) => (item.id === id ? { ...item, done: e.currentTarget.checked } : item));
    updateBlocks(newBlocks);

    if (isProject && isProjectUnallocateSubtask) {
      try {
        await updateTaskboxV1TaskboxesTaskboxIdPut(id, { done: e.currentTarget.checked });
      } catch {
        language === 'ko' ? toast.error('완료 상태를 변경하는데 실패하였습니다.') : toast.error('Failed to change completion status.');
        // toast.error('Failed to change completion status.');
      }
    } else if (isProject && instance?.id) {
      try {
        await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instance?.id, id, { done: e.currentTarget.checked });
      } catch {
        language === 'ko' ? toast.error('완료 상태를 변경하는데 실패하였습니다.') : toast.error('Failed to change completion status.');
        // toast.error('Failed to change completion status.');
      }
    }
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setContextMenuPopover(e.currentTarget);
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    switch (menu) {
      case 'DELETE':
        deleteBlock(id);
        if (isProject && isProjectUnallocateSubtask) onChangeUnallocateSubtask && onChangeUnallocateSubtask(blocks.find((item) => item.id === id)!, 'DELETE');
        if (isProject && instance?.id)
          onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, blocks.find((item) => item.id === id)!, 'DELETE');
        break;
      case 'MOVE_TO_AFTER': {
        const block = blocks.find((item) => item.id === id);
        if (!block) return;
        if (instance?.id) {
          onChangeProjectDetailInstanceSubtask && onChangeProjectDetailInstanceSubtask(instance?.id, block, 'MOVE_TO_AFTER');
        } else if (isProject) {
          onChangeInstanceSubtask && onChangeInstanceSubtask(block, 'MOVE_TO_AFTER');
        } else {
          await createWorkboxV2WorkboxesPost({ id: uuidv4(), title: block?.content || '' });
        }
        deleteBlock(id);
        break;
      }
      case 'CONVERT_TO_TASKBOX': {
        const block = blocks.find((item) => item.id === id);
        if (!block) return;

        const newTaskbox: CreateTaskbox = {
          id: uuidv4(),
          title: block.content!,
          start: { date: dayjs(date).format(DATE_FORMAT_4) },
          end: { date: dayjs(date).format(DATE_FORMAT_4) },
          categoryId: block.category ? [block.category?.id] : [],
          deadline: block.deadline,
        };
        await createTaskboxV1TaskboxesPost(newTaskbox);
        deleteBlock(id);
        break;
      }
    }
    setContextMenuPopover(null);
  };

  const handleClickCategory = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setCategoryAnchorEl(e.currentTarget);
  };

  const handleChangeCategory = async (category: OutCategory | undefined, action: CategoryActionType, detachTaskId?: string) => {
    if (action === 'SELECT') {
      if (!category && detachTaskId) {
        updateBlockData(detachTaskId, { category: undefined });
        onChange && onChange(blocks);
        if (isProject && isProjectUnallocateSubtask) {
          try {
            await updateTaskboxV1TaskboxesTaskboxIdPut(detachTaskId, {
              categoryId: [],
            });
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 제거하는데 실패하였습니다.') : toast.error('Failed to remove category.');
            // toast.error('Failed to remove category.');
          }
        } else if (isProject && instance?.id) {
          try {
            await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instance?.id, detachTaskId, {
              categoryId: [],
            });
          } catch {
            language === 'ko' ? toast.error('카테고리를 제거하는데 실패하였습니다.') : toast.error('Failed to remove category.');
            // toast.error('Failed to remove category.');
          }
        }
      } else {
        if (!clickedTask) return;
        const task = clickedTask;
        const newCategory = category;
        updateBlockData(task.id, { category: newCategory });
        onChange && onChange(blocks);
        setClickedTask(undefined);
        setCategoryAnchorEl(null);
        if (isProject && isProjectUnallocateSubtask) {
          try {
            await updateTaskboxV1TaskboxesTaskboxIdPut(clickedTask.id, {
              categoryId: [category!.id],
            });
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 설정하는데 실패하였습니다.') : toast.error('Failed to set category.');
            // toast.error('Failed to set category.');
          }
        } else if (isProject && instance?.id) {
          try {
            await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instance?.id, task.id, {
              categoryId: [category!.id],
            });
          } catch (e) {
            language === 'ko' ? toast.error('카테고리를 설정하는데 실패하였습니다.') : toast.error('Failed to set category.');
            // toast.error('Failed to set category.');
          }
        }
      }
    } else {
      if (action === 'UPDATE') {
        updateBlockData(clickedTask!.id, { category: clickedTask?.category && clickedTask?.category.id === category?.id ? category : clickedTask?.category });
        onChange && onChange(blocks);
      } else if (action === 'DELETE') {
        updateBlockData(clickedTask!.id, { category: undefined });
        setClickedTask(undefined);
        setCategoryAnchorEl(null);
        onChange && onChange(blocks);
      }
      onClickCategory && onClickCategory(category!, action);
    }
  };

  const handleClickDeadline = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setDeadlineAnchorEl(e.currentTarget);
  };

  const handleChangeDeadline = async (value: Dayjs | null, detachTaskId?: string) => {
    if (!value && detachTaskId) {
      updateBlockData(detachTaskId, { deadline: null });
      onChange && onChange(blocks);
      if (isProject && isProjectUnallocateSubtask) {
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(detachTaskId, {
            deadline: null,
          });
        } catch (e) {
          language === 'ko' ? toast.error('기한을 제거하는데 실패하였습니다.') : toast.error('Failed to remove deadline.');
          // toast.error('Failed to remove deadline.');
        }
      } else if (isProject && instance?.id) {
        try {
          await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instance?.id, detachTaskId, {
            deadline: null,
          });
        } catch (e) {
          language === 'ko' ? toast.error('기한을 제거하는데 실패하였습니다.') : toast.error('Failed to remove deadline.');
          // toast.error('Failed to remove deadline.');
        }
      }
    } else {
      if (!clickedTask) return;
      const task = clickedTask;
      const deadline = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null;

      if (task.deadline !== deadline) {
        updateBlockData(task.id, { deadline: deadline as any });
      }

      setClickedTask(undefined);
      setDeadlineAnchorEl(null);
      onChange && onChange(blocks);

      if (isProject && isProjectUnallocateSubtask) {
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(clickedTask.id, {
            deadline: value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null,
          });
        } catch (e) {
          language === 'ko' ? toast.error('기한을 설정하는데 실패하였습니다.') : toast.error('Failed to set deadline.');
          // toast.error('Failed to set deadline.');
        }
      } else if (isProject && instance?.id) {
        try {
          await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(instance?.id, task.id, {
            deadline: value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null,
          });
        } catch (e) {
          language === 'ko' ? toast.error('기한을 설정하는데 실패하였습니다.') : toast.error('Failed to set deadline.');
          // toast.error('Failed to set deadline.');
        }
      }
    }
  };

  const handleTestDragStart = (e: React.DragEvent<HTMLDivElement>, task: OutTaskboxDetailProjectOnly) => {
    onDragStart && onDragStart(e, task);
  };

  const handleTestDragEnd = () => {
    onDragEnd && onDragEnd();
  };

  const handleMultiSelect = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    const targetId = taskbox?.id || instance?.id || `unallocate-${projectDoneFilter}`;
    if (multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].taskboxId !== targetId) {
      language === 'ko'
        ? toast.error('동일한 태스크박스 내에서만 멀티선택이 가능합니다.')
        : toast.error('Multi-selection is only available within the same taskbox.');
      // toast.error('Multi-selection is only available within the same taskbox.');
      return;
    }

    const currentSubtaskIds = multiSelectSubtaskIds[0]?.subtaskIds || [];
    const newSubtaskIds = currentSubtaskIds.includes(id) ? currentSubtaskIds.filter((v) => v !== id) : [...currentSubtaskIds, id];

    const newMultiSelectSubtaskIds: MultiSelectSubtaskIds = {
      taskboxId: targetId,
      subtaskIds: newSubtaskIds,
    };

    onChangeMultiSelectSubtaskIds && onChangeMultiSelectSubtaskIds(newSubtaskIds.length > 0 ? [newMultiSelectSubtaskIds] : [], e);
  };

  const handleSetTaskboxTime = () => {
    onSetTaskboxTime && onSetTaskboxTime();
  };

  const handleSetTaskboxCategory = () => {
    onSetTaskboxCategory && onSetTaskboxCategory();
  };

  const handleSetTaskboxDeadline = () => {
    onSetTaskboxDeadline && onSetTaskboxDeadline();
  };

  const handleClickSubtaskModeIconBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const el = document.querySelector('.select-subtask-mode') as HTMLElement;
    setSubtaskModeAnchorEl(el);
  };

  const handleChangeSubtaskMode = (mode: SubtaskMode) => {
    setSubtaskMode(mode);
    setSubtaskModeAnchorEl(null);
  };

  const multiSelectId = taskbox?.id || instance?.id || `unallocate-${projectDoneFilter}`;
  const multiSelected = multiSelectSubtaskIds.length > 0;

  return (
    <Container>
      <TaskBlockInputListContainer style={{ maxHeight: maxHeight ? '240px' : 'none' }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={'task-list'}>
            {(provided) => (
              <div className={'task-list'} {...provided.droppableProps} ref={provided.innerRef}>
                {blocks
                  .filter((v) => (projectDoneFilter === undefined || projectDoneFilter === null ? true : projectDoneFilter === v.done))
                  .map(({ id, content, title, done: taskDone, dueDate, meetingNoteTitle, category, deadline }, idx) => (
                    <Draggable draggableId={id} index={idx} key={id} isDragDisabled={!draggable}>
                      {(provided) => (
                        <EditableTextFieldWrapper
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          select={clickedTask?.id === id}
                          multiSelected={multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].subtaskIds.includes(id)}
                          style={{ ...provided.draggableProps.style, display: 'flex' }}
                          draggable={!!project}
                          onDragStart={(e) => handleTestDragStart(e, blocks.find((item) => item.id === id) as OutTaskboxDetailProjectOnly)}
                          onDragEnd={handleTestDragEnd}
                          onClick={(e) => {
                            if (e.ctrlKey || e.metaKey) handleMultiSelect(e, id);
                          }}
                        >
                          <EditableTextFieldStyle>
                            {draggable && <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />}
                            <TaskCheckbox
                              icon={
                                isFocus ? (
                                  <TaskFocusUncheckIcon />
                                ) : (
                                  <TaskCheckIcon stroke={isProject ? COLORS.issue2 : isRecurrence || isRoutine ? COLORS.sub4 : COLORS.brand1} />
                                )
                              }
                              checkedIcon={
                                isFocus ? (
                                  <TaskFocusCheckIcon opacity={done ? 0.5 : 1} />
                                ) : (
                                  <TaskCheckedIcon
                                    fill={isProject ? COLORS.issue2 : isRecurrence || isRoutine ? COLORS.sub4 : COLORS.brand1}
                                    opacity={done ? 0.5 : 1}
                                  />
                                )
                              }
                              sx={{ padding: 0 }}
                              checked={!!taskDone}
                              disabled={suppressCheckboxToggle}
                              onChange={(e) => handleChangeDone(e, id)}
                            />
                            <EditableTextField
                              style={{ marginLeft: 8, color: taskDone ? COLORS.gray500 : COLORS.gray800, textDecoration: taskDone ? 'line-through' : 'none' }}
                              size={size}
                              tabIndex={0}
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              spellCheck={false}
                              data-field-id={`${id}`}
                              onKeyDown={(e) => handleKeyDown(e, id, idx)}
                              onInput={(e) => handleInput(e, id)}
                              onMouseEnter={(e) => e.stopPropagation()}
                              onBlur={(e) => handleBlur(e, id)}
                              placeholder={
                                !delayRecommendTask &&
                                project?.id &&
                                !instance?.id &&
                                !isProjectUnallocateSubtask &&
                                clickedTask?.id === id &&
                                projectSubtasks.length > 0
                                  ? projectSubtasks[recommendTaskIndex].title === ''
                                    ? language === 'ko'
                                      ? '제목 없음'
                                      : 'Untitled'
                                    : projectSubtasks[recommendTaskIndex].title!
                                  : 'Title'
                                // !delayRecommendTask &&
                                // project?.id &&
                                // !instance?.id &&
                                // !isProjectUnallocateSubtask &&
                                // clickedTask?.id === id &&
                                // projectSubtasks.length > 0
                                //   ? projectSubtasks[recommendTaskIndex].title === ''
                                //     ? 'Untitled'
                                //     : projectSubtasks[recommendTaskIndex].title!
                                //   : 'Title'
                              }
                              onClick={(e) => {
                                if (e.ctrlKey || e.metaKey) return;
                                const targetBlock = blocks.find((item) => item.id === id);
                                setClickedTask(targetBlock);
                              }}
                            >
                              {(isProject && isProjectUnallocateSubtask) || isRoutine ? title : content}
                            </EditableTextField>
                            {(!isInstanceTyping &&
                              clickedTask?.id === id &&
                              project?.id &&
                              !isProjectUnallocateSubtask &&
                              !instance?.id &&
                              projectSubtasks.length > 0 &&
                              content === '') ||
                            (isRemoveTextAndRecommend &&
                              clickedTask?.id === id &&
                              project?.id &&
                              !isProjectUnallocateSubtask &&
                              !instance?.id &&
                              projectSubtasks.length > 0) ? (
                              !delayRecommendTask ? (
                                <RecommendTaskWrapper>
                                  {language === 'ko' ? (
                                    <>
                                      {projectSubtasks.length > 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                          <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                          <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                          <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                        </div>
                                      )}
                                      <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                      <span>으로 추가하기</span>
                                    </>
                                  ) : (
                                    <>
                                      {projectSubtasks.length > 1 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                          <span style={{ marginRight: 4 }}>Navigate with</span>
                                          <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                          <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                          <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                        </div>
                                      )}
                                      <span>Add with</span>
                                      <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect>
                                    </>
                                  )}
                                  {/* {projectSubtasks.length > 1 && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                      <span style={{ marginRight: 4 }}>Navigate with</span>
                                      <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                      <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                      <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                    </div>
                                  )}
                                  <span>Add with</span>
                                  <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect> */}
                                </RecommendTaskWrapper>
                              ) : (
                                <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                              )
                            ) : (
                              !multiSelected && (
                                <TaskMoreButtonWrapper className="task-more-wrapper">
                                  {!suppressCategory &&
                                    (category ? (
                                      <CategoryShowingWrapper
                                        className="task-more-btn"
                                        textColor={getCategoryTextColor(category.color)}
                                        bgColor={getCategoryBgColor(category.color)}
                                        onClick={(e) => handleClickCategory(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                        style={categoryAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                                      >
                                        {`# ${category.name}`}
                                        <IconButton
                                          className="category-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChangeCategory(undefined, 'SELECT', id);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(getCategoryTextColor(category!.color)!, 0.3),
                                            },
                                          }}
                                          style={categoryAnchorEl && clickedTask!.id === id ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close width={8} height={8} stroke={getCategoryTextColor(category!.color)} />
                                        </IconButton>
                                      </CategoryShowingWrapper>
                                    ) : (
                                      content !== '' && (
                                        <IconButton
                                          className="task-more-btn"
                                          onClick={(e) => handleClickCategory(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                          sx={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            padding: '0px',
                                            marginRight: '4px',
                                          }}
                                          style={categoryAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                                        >
                                          <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                        </IconButton>
                                      )
                                    ))}
                                  {!suppressDeadline &&
                                    (deadline ? (
                                      <DeadlineShowingWrapper
                                        date={deadline}
                                        className="task-more-btn"
                                        onClick={(e) => handleClickDeadline(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                        style={deadlineAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                                      >
                                        <Icons.Flag
                                          fill={
                                            dayjs(deadline).isToday() ? COLORS.brand1 : dayjs(deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                          }
                                        />
                                        <span style={{ marginLeft: '2px' }}>
                                          {dayjs(deadline).isToday()
                                            ? '오늘'
                                            : dayjs(deadline).isYesterday()
                                            ? '어제'
                                            : dayjs(deadline).isTomorrow()
                                            ? '내일'
                                            : dayjs(deadline).format('M월 D일 (dd)')}
                                        </span>
                                        <span style={{ marginLeft: '2px' }}>
                                          {dayjs(deadline).isToday()
                                            ? language === 'ko'
                                              ? '오늘'
                                              : 'Today'
                                            : dayjs(deadline).isYesterday()
                                            ? language === 'ko'
                                              ? '어제'
                                              : 'Yesterday'
                                            : dayjs(deadline).isTomorrow()
                                            ? language === 'ko'
                                              ? '내일'
                                              : 'Tomorrow'
                                            : language === 'ko'
                                            ? dayjs(deadline).format('M월 D일 (dd)')
                                            : dayjs(deadline).format('dddd, MMM D')}
                                          {/* {dayjs(deadline).isToday()
                                            ? 'Today'
                                            : dayjs(deadline).isYesterday()
                                            ? 'Yesterday'
                                            : dayjs(deadline).isTomorrow()
                                            ? 'Tomorrow'
                                            : dayjs(deadline).format('dddd, MMM D')} */}
                                        </span>
                                        <IconButton
                                          className="deadline-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChangeDeadline(null, id);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(
                                                dayjs(deadline).isToday()
                                                  ? COLORS.brand1
                                                  : dayjs(deadline).isBefore(dayjs())
                                                  ? COLORS.negative1
                                                  : COLORS.gray600,
                                                0.3,
                                              ),
                                            },
                                          }}
                                          style={deadlineAnchorEl && clickedTask!.id === id ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close
                                            width={8}
                                            height={8}
                                            stroke={
                                              dayjs(deadline).isToday() ? COLORS.brand1 : dayjs(deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                                            }
                                          />
                                        </IconButton>
                                      </DeadlineShowingWrapper>
                                    ) : (
                                      content !== '' && (
                                        <IconButton
                                          className="task-more-btn"
                                          onClick={(e) => handleClickDeadline(e, blocks.find((item) => item.id === id) as TaskBlock)}
                                          sx={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            padding: '0px',
                                            marginRight: '4px',
                                          }}
                                          style={deadlineAnchorEl && clickedTask!.id === id ? { opacity: 1 } : {}}
                                        >
                                          <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                        </IconButton>
                                      )
                                    ))}
                                  {!suppressMoveToAfter &&
                                    !isProjectUnallocateSubtask &&
                                    content !== '' &&
                                    (isProject ? (
                                      <Tooltip
                                        title={
                                          nextInstance ? (
                                            <div>
                                              <div>
                                                {language === 'ko' ? '다음 인스턴스로 이동해요' : 'Move to next instance'}
                                                {/* Move to next instance */}
                                              </div>
                                              <div style={{ fontWeight: 700 }}>
                                                {language === 'ko'
                                                  ? nextInstance.start?.datetime
                                                    ? dayjs(nextInstance.start?.datetime).format('M월 D일 dddd A h:mm')
                                                    : dayjs(nextInstance.start?.date).format('M월 D일 dddd')
                                                  : nextInstance.start?.datetime
                                                  ? dayjs(nextInstance.start?.datetime).format('ddd, MMM D dddd A h:mm')
                                                  : dayjs(nextInstance.start?.date).format('ddd, MMM D')}
                                                {/* {nextInstance.start?.datetime
                                                  ? dayjs(nextInstance.start?.datetime).format('dddd, MMM D dddd A h:mm')
                                                  : dayjs(nextInstance.start?.date).format('dddd, MMM D')} */}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              {language === 'ko' ? '프로젝트의 미할당으로 이동해요' : 'Move to project unassigned'}
                                              {/* Move to project unassigned */}
                                            </div>
                                          )
                                        }
                                        disableInteractive
                                      >
                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                          <Divider className="task-more-btn" orientation="vertical" flexItem sx={{ marginRight: '4px' }} />
                                          <TaskIconWrapper
                                            aria-label="later"
                                            className="task-more-btn"
                                            onClick={() => handleClickContextMenu(id, 'task', 'MOVE_TO_AFTER')}
                                          >
                                            <Icons.ArrowRightLong width={16} height={16} stroke={COLORS.gray400} />
                                          </TaskIconWrapper>
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      <TaskIconWrapper
                                        aria-label="later"
                                        className="task-more-btn"
                                        onClick={() => handleClickContextMenu(id, 'task', 'MOVE_TO_AFTER')}
                                      >
                                        <div className="later">
                                          <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                                        </div>
                                      </TaskIconWrapper>
                                    ))}
                                  <TaskIconWrapper
                                    aria-label="delete"
                                    className="task-more-btn"
                                    onClick={() => handleClickContextMenu(id, 'task', 'DELETE')}
                                    style={{
                                      marginRight: !suppressTaskMore ? 4 : 0,
                                    }}
                                  >
                                    <div className="delete">
                                      <Icons.Delete fill={COLORS.gray400} />
                                    </div>
                                  </TaskIconWrapper>
                                  {!suppressTaskMore && (
                                    <IconButton
                                      data-task-id={id}
                                      size="small"
                                      aria-label="more"
                                      className="task-more-btn"
                                      onClick={handleClickMenu}
                                      sx={{ background: 'white', padding: 0 }}
                                      style={contextMenuPopover && contextMenuPopover.getAttribute('data-task-id') === id ? { opacity: 1 } : {}}
                                    >
                                      <Icons.ColorMore />
                                    </IconButton>
                                  )}
                                </TaskMoreButtonWrapper>
                              )
                            )}
                          </EditableTextFieldStyle>
                          {/* {!suppressVisibleTag && (
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2, marginLeft: 24 }}>
                            {meetingNoteTitle && <TruncateTaskTag style={{ marginRight: 8 }}>{meetingNoteTitle}</TruncateTaskTag>}
                            {dueDate && (
                              <TaskTag onClick={(e) => handleClickDueDate(e, blocks[idx])}>
                                <Icons.Flag />
                                {dayjs(dueDate).format('MM월 DD일')}
                              </TaskTag>
                            )}
                          </div>
                        )} */}
                        </EditableTextFieldWrapper>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TaskBlockInputListContainer>

      {!suppressModification && (
        <BlockAddArea onKeyDown={(e) => handleKeydownAddButton(e)}>
          {!suppressLoadSubtask && projectSubtasks.length > 0 ? (
            <div
              className="add-subtask select-subtask-mode"
              onClick={subtaskMode === 'ADD' ? handleClickAdd : onClickLoadSubtaskFromProject}
              style={{
                height: 24,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '6px',
                border: `1px solid ${COLORS.gray200}`,
                borderRadius: '4px',
                marginLeft: -4,
              }}
            >
              {subtaskMode === 'ADD' ? (
                <Icons.Plus width={12} height={12} stroke={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} style={{ marginRight: 4 }} />
              ) : (
                <Icons.LoadToInstance width={12} height={12} fill={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} style={{ marginRight: 4 }} />
              )}
              <span style={{ fontSize: size === 'large' ? 16 : 10, color: done ? COLORS.gray400 : COLORS.gray500 }}>
                {language === 'ko'
                  ? isProjectUnallocateSubtask
                    ? '미할당 업무 추가하기'
                    : subtaskMode === 'IMPORT'
                    ? '하위 업무 가져오기'
                    : '하위 업무 추가하기'
                  : isProjectUnallocateSubtask
                  ? 'Add unassigned task'
                  : subtaskMode === 'IMPORT'
                  ? 'Import subtask'
                  : 'Add subtask'}
              </span>
              <IconButton
                onClick={(e) => handleClickSubtaskModeIconBtn(e)}
                sx={{
                  marginLeft: '4px',
                  padding: '0px',
                }}
              >
                <Icons.ArrowDownSmall />
              </IconButton>
            </div>
          ) : (
            <div
              className="add-subtask"
              onClick={handleClickAdd}
              style={{
                height: 24,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '6px',
                border: `1px solid ${COLORS.gray200}`,
                borderRadius: '4px',
                marginLeft: -4,
              }}
            >
              <Icons.Plus width={12} height={12} stroke={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} style={{ marginRight: 4 }} />
              <span style={{ fontSize: size === 'large' ? 16 : 10, color: done ? COLORS.gray400 : COLORS.gray500 }}>
                {language === 'ko'
                  ? isProjectUnallocateSubtask
                    ? '미할당 업무 추가하기'
                    : '하위 업무 추가하기'
                  : isProjectUnallocateSubtask
                  ? 'Add unassigned task'
                  : 'Add subtask'}
                {/* {isProjectUnallocateSubtask ? 'Add unassigned task' : 'Add subtask'} */}
              </span>
            </div>
          )}
          {!suppressAddTime && (
            <div
              className="add-time"
              onClick={handleSetTaskboxTime}
              style={{
                height: 24,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '6px',
                border: `1px solid ${COLORS.gray200}`,
                borderRadius: '4px',
                marginLeft: 4,
              }}
            >
              <Icons.Time width={12} height={12} stroke={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} style={{ marginRight: 4 }} />
              <span style={{ fontSize: size === 'large' ? 16 : 10, color: done ? COLORS.gray400 : COLORS.gray500 }}>
                {language === 'ko' ? '시간 추가하기' : 'Add time'}
                {/* {isProjectUnallocateSubtask ? 'Add unassigned task' : 'Add subtask'} */}
              </span>
            </div>
          )}
          {taskbox && (!taskbox.category || !taskbox.deadline) && !taskbox.routine && !suppressTaskboxCategoryDeadline && (
            <>
              <Divider orientation="vertical" flexItem sx={{ margin: '0px 6px' }} />
              {taskbox && !taskbox.category && (
                <Tooltip
                  title={
                    language === 'ko' ? '카테고리 설정하기' : 'Set category'
                    // 'Set category'
                  }
                  disableInteractive
                >
                  <IconButton
                    onClick={handleSetTaskboxCategory}
                    sx={{
                      'width': '24px',
                      'height': '24px',
                      'border': `1px solid ${COLORS.gray200}`,
                      'borderRadius': '4px',
                      'padding': '0px',
                      'marginRight': '4px',
                      ':hover': {
                        backgroundColor: COLORS.gray100,
                      },
                    }}
                  >
                    <Icons.Hashtag fill={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
                  </IconButton>
                </Tooltip>
              )}
              {taskbox && !taskbox.deadline && (
                <Tooltip
                  title={
                    language === 'ko' ? '기한 설정하기' : 'Set deadline'
                    // 'Set deadline'
                  }
                  disableInteractive
                >
                  <IconButton
                    onClick={handleSetTaskboxDeadline}
                    sx={{
                      'width': '24px',
                      'height': '24px',
                      'border': `1px solid ${COLORS.gray200}`,
                      'borderRadius': '4px',
                      'padding': '0px',
                      'marginRight': '4px',
                      ':hover': {
                        backgroundColor: COLORS.gray100,
                      },
                    }}
                  >
                    <Icons.Flag fill={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </BlockAddArea>
      )}
      {contextMenuPopover && (
        <InboxContextMenuPopover
          id={contextMenuPopover.getAttribute('data-task-id') || ''}
          type={'subtask'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          menus={['CONVERT_TO_TASKBOX']}
          onClose={() => setContextMenuPopover(null)}
          onClickMenu={handleClickContextMenu}
        />
      )}
      {/* 하위업무 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleChangeCategory} />
        </Popover>
      )}
      {/* 하위업무 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={date!} onChangeDeadline={handleChangeDeadline} />
        </Popover>
      )}
      {/* 멀티 선택 */}
      {multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].taskboxId === multiSelectId && (
        <Popper
          open={Boolean(multiSelectSubtaskAnchorEl)}
          anchorEl={multiSelectSubtaskAnchorEl}
          placement={'right-start'}
          ref={refPopperMenu}
          sx={{ zIndex: 1000 }}
        >
          <SubtaskMultiSelectPopover
            menus={
              taskbox && !taskbox.project
                ? ['MOVE_TO_AFTER', 'CONVERT_TO_TASKBOX', 'DELETE']
                : isProjectUnallocateSubtask && isMoveToInstance
                ? ['INSTANCE', 'DELETE']
                : isProjectUnallocateSubtask && !isMoveToInstance
                ? ['MOVE_TO_TODAY', 'DELETE']
                : ['MOVE_TO_AFTER', 'DELETE']
            }
            subtaskIds={multiSelectSubtaskIds[0].subtaskIds}
            onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
          />
        </Popper>
      )}
      {/** 하위업무 모드 선택 */}
      <Popover
        open={Boolean(subtaskModeAnchorEl)}
        anchorEl={subtaskModeAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setSubtaskModeAnchorEl(null)}
      >
        <SubtaskModeMenu mode={subtaskMode} onChange={handleChangeSubtaskMode} />
      </Popover>
    </Container>
  );
};

export default forwardRef<TaskBlockHandle, TaskBlockInputListProps>(TaskBlockInputList);
