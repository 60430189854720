import React, { FC, useCallback } from 'react';
import { TableExtension } from '@remirror/extension-tables';
import { useActive, useCommands } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

export interface CreateTableButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const CreateTableButton: FC<CreateTableButtonProps> = (props) => {
  const [language] = useAtom(languageAtom);
  const { createTable } = useCommands<TableExtension>();
  const onClose = props.onClose;

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (createTable.enabled()) {
      createTable();
    }
  }, [createTable]);

  const active = useActive<TableExtension>().table();
  const enabled = createTable.enabled();

  return <CommandButton {...props} commandName={language === 'ko' ? '테이블' : 'Table'} active={active} enabled={enabled} onSelect={handleSelect} />;
};
