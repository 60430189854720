import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { SVGProps } from 'react';
import { Icons } from 'components';

interface LoginProps {
  width?: string;
  onClick?: () => void;
  label: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const Login = ({ width, onClick, label, icon }: LoginProps) => {
  return (
    <Wrapper width={width} onClick={onClick}>
      {icon ? icon({}) : <Icons.GoogleLogo />}
      <Fonts.H3 style={{ marginLeft: '8px' }}>{label}</Fonts.H3>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ width?: string }>`
  width: 400px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  color: ${COLORS.gray900};
  ${(props) => props.width && `width: ${props.width};`}
  &:hover {
    background-color: ${COLORS.gray100};
  }
  cursor: pointer;
`;
