import { SVGProps } from 'react';

export const CompleteNow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.66732 7.99772L7.96715 11.2976L15.0388 4.22656M1.36719 8.03125L4.66702 11.3311M11.738 4.26009L8.20247 7.79563"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
