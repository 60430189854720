import { SVGProps } from 'react';

export const UnlinkProject = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24985 3.25506C3.62492 2.87998 4.13363 2.66927 4.66406 2.66927H5.9974C6.36559 2.66927 6.66406 2.96775 6.66406 3.33594C6.66406 3.70413 6.36559 4.0026 5.9974 4.0026H4.66406C4.48725 4.0026 4.31768 4.07284 4.19266 4.19787C4.06763 4.32289 3.9974 4.49246 3.9974 4.66927V12.6693C3.9974 12.8461 4.06763 13.0157 4.19266 13.1407C4.31768 13.2657 4.48725 13.3359 4.66406 13.3359H7.8974C8.26559 13.3359 8.56407 13.6344 8.56407 14.0026C8.56407 14.3708 8.26559 14.6693 7.8974 14.6693H4.66406C4.13363 14.6693 3.62492 14.4586 3.24985 14.0835C2.87478 13.7084 2.66406 13.1997 2.66406 12.6693V4.66927C2.66406 4.13884 2.87478 3.63013 3.24985 3.25506ZM9.33073 3.33594C9.33073 2.96775 9.62921 2.66927 9.9974 2.66927H11.3307C11.8612 2.66927 12.3699 2.87998 12.7449 3.25506C13.12 3.63013 13.3307 4.13884 13.3307 4.66927V7.25259C13.3307 7.62078 13.0323 7.91926 12.6641 7.91926C12.2959 7.91926 11.9974 7.62078 11.9974 7.25259V4.66927C11.9974 4.49246 11.9272 4.32289 11.8021 4.19787C11.6771 4.07284 11.5075 4.0026 11.3307 4.0026H9.9974C9.62921 4.0026 9.33073 3.70413 9.33073 3.33594Z"
        fill="#D8204C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33073 2.66927C6.96254 2.66927 6.66406 2.96775 6.66406 3.33594C6.66406 3.70413 6.96254 4.0026 7.33073 4.0026H8.66406C9.03225 4.0026 9.33073 3.70413 9.33073 3.33594C9.33073 2.96775 9.03225 2.66927 8.66406 2.66927H7.33073ZM5.33073 3.33594C5.33073 2.23137 6.22616 1.33594 7.33073 1.33594H8.66406C9.76863 1.33594 10.6641 2.23137 10.6641 3.33594C10.6641 4.44051 9.76863 5.33594 8.66406 5.33594H7.33073C6.22616 5.33594 5.33073 4.44051 5.33073 3.33594Z"
        fill="#D8204C"
      />
      <path
        d="M15 11.75C15 13.5449 13.5449 15 11.75 15C9.95507 15 8.5 13.5449 8.5 11.75C8.5 9.95507 9.95507 8.5 11.75 8.5C13.5449 8.5 15 9.95507 15 11.75Z"
        fill="#D8204C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.525 11.75C9.525 11.4186 9.79363 11.15 10.125 11.15L13.375 11.15C13.7064 11.15 13.975 11.4186 13.975 11.75C13.975 12.0814 13.7064 12.35 13.375 12.35H10.125C9.79363 12.35 9.525 12.0814 9.525 11.75Z"
        fill="white"
      />
    </svg>
  );
};
