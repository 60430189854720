import { categoryColors } from 'constants/categoryColor';

export const getCategoryTextColor = (color: string) => {
  return categoryColors.find((v) => v.color === color)?.textColor;
};

export const getCategoryBgColor = (color: string) => {
  return categoryColors.find((v) => v.color === color)?.bgColor;
};

export const getCategoryStatColor = (color: string) => {
  return categoryColors.find((v) => v.color === color)?.statColor;
};

export const hexToRGBA = (hex: string, alpha = 1) => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
