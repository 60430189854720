import { SVGProps } from 'react';

export const ExclamationInfo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="9.75" stroke={props.stroke || '#1F2023'} strokeWidth="1.5" />
      <path d="M12 10.5V17.25" stroke={props.stroke || '#1F2023'} strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="12" cy="6.75" r="0.75" fill={props.fill || '#1F2023'} stroke={props.stroke || '#1F2023'} />
    </svg>
  );
};
