import { SVGProps } from 'react';

export const CalendarVisibility = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.66623 2.66406L13.3329 13.3307M10.9996 11.168C10.0978 11.6537 9.07864 11.9973 7.99956 11.9973C5.64579 11.9973 3.57713 10.3626 2.39084 9.18327C2.0777 8.87196 1.92116 8.71633 1.8215 8.41081C1.75043 8.19292 1.75041 7.80177 1.8215 7.58389C1.92119 7.27837 2.07806 7.12237 2.39181 6.81051C2.98974 6.2162 3.81164 5.50646 4.7811 4.94857M12.9996 9.75303C13.2216 9.55775 13.4249 9.36562 13.6074 9.18424L13.6094 9.18221C13.9218 8.87168 14.0784 8.71598 14.1779 8.41113C14.249 8.19325 14.2488 7.80195 14.1778 7.58406C14.0781 7.27864 13.9214 7.12256 13.6085 6.81147C12.4222 5.63212 10.3533 3.9974 7.99956 3.9974C7.77456 3.9974 7.55217 4.01233 7.33289 4.04038M8.8815 8.9974C8.64646 9.20485 8.33771 9.33073 7.99956 9.33073C7.26318 9.33073 6.66623 8.73378 6.66623 7.9974C6.66623 7.63773 6.80864 7.31145 7.04015 7.07161"
        stroke="#ABB0BF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
