import styled from '@emotion/styled';
import { OutCategory, OutFrequentWork, OutTaskboxDetailResponse, OutTaskboxDetailResponseForSubtask } from 'queries/model';
import { DragDropContext, DragStart, DropResult, Droppable } from 'react-beautiful-dnd';
import { Taskbox } from './Taskbox';
import { hideScroll } from 'styles/utils';
import { MultiSelectType } from '..';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface TaskboxViewProps {
  date?: Date;
  doneStatus?: boolean;
  selectedEvent?: OutTaskboxDetailResponse;
  taskboxes?: OutTaskboxDetailResponse[];
  multiSelectIds?: string[];
  multiSelectAnchorEl?: HTMLElement | null;
  categoryList?: OutCategory[];
  templateList?: OutFrequentWork[];
  onFetchTaskbox?: (date: Date) => void;
  onChangeTaskboxDragImage?: (taskbox: OutTaskboxDetailResponse) => void;
  onChangeSubtask?: (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => void;
  onBeautifulDragEnd?: (result: DropResult) => void;
  onBeautifulDragStart?: (start: DragStart) => void;
  onChangeMultiSelectIds?: (e: React.MouseEvent<HTMLDivElement>, eventId: string) => void;
  onCloseMultiSelect?: () => void;
  onClickMultiSelectActions?: (tpye: MultiSelectType, date?: Dayjs) => void;
  onMultiSelectDragStart?: (e: React.DragEvent<HTMLDivElement>, done: boolean) => void;
  onMultiSelectDragEnd?: () => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onFetchCategoryList?: () => void;
  onFetchTemplateList?: () => void;
  onFetchEntireTaskboxList?: () => void;
}

const TaskboxView = ({
  date,
  doneStatus = false,
  selectedEvent,
  taskboxes = [],
  multiSelectIds = [],
  multiSelectAnchorEl,
  categoryList = [],
  templateList = [],
  onFetchTaskbox,
  onChangeTaskboxDragImage,
  onChangeSubtask,
  onBeautifulDragEnd,
  onBeautifulDragStart,
  onChangeMultiSelectIds,
  onCloseMultiSelect,
  onClickMultiSelectActions,
  onMultiSelectDragStart,
  onMultiSelectDragEnd,
  onClickTaskbox,
  onFetchCategoryList,
  onFetchTemplateList,
  onFetchEntireTaskboxList,
}: TaskboxViewProps) => {
  const [language] = useAtom(languageAtom);

  const handleBeautifulDragEnd = (result: DropResult) => {
    onBeautifulDragEnd?.(result);
  };

  const handleBeautifulDragStart = (start: DragStart) => {
    onBeautifulDragStart?.(start);
  };

  return (
    <Container>
      <DragDropContext onDragStart={handleBeautifulDragStart} onDragEnd={handleBeautifulDragEnd}>
        {taskboxes.filter((v) => v.allDay).length !== 0 && (
          <TodoWrapper>
            <HeaderWrapper>{language === 'ko' ? '할 일 목록' : 'To-do List'}</HeaderWrapper>
            <TaskboxViewWrapper>
              <Droppable droppableId="all-taskbox" type="taksbox">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {taskboxes
                      ?.filter((v) => v.allDay)
                      .map((taskbox, index) => (
                        <Taskbox
                          key={taskbox.id}
                          index={index}
                          date={date}
                          event={taskbox}
                          events={taskboxes}
                          doneStatus={doneStatus}
                          selectedEvent={selectedEvent}
                          multiSelectIds={multiSelectIds}
                          multiSelected={multiSelectIds.includes(taskbox.id!)}
                          multiSelectAnchorEl={multiSelectAnchorEl}
                          categoryList={categoryList}
                          templateList={templateList}
                          onChange={onFetchTaskbox}
                          onChangeTaskboxImage={onChangeTaskboxDragImage}
                          onChangeSubtask={onChangeSubtask}
                          onChangeMultiSelectIds={onChangeMultiSelectIds}
                          onCloseMultiSelect={onCloseMultiSelect}
                          onClickMultiSelectActions={onClickMultiSelectActions}
                          onMultiSelectDragStart={onMultiSelectDragStart}
                          onMultiSelectDragEnd={onMultiSelectDragEnd}
                          onClickTaskbox={onClickTaskbox}
                          onFetchCategoryList={onFetchCategoryList}
                          onFetchTemplateList={onFetchTemplateList}
                        />
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </TaskboxViewWrapper>
          </TodoWrapper>
        )}
        {taskboxes?.filter((v) => !v.allDay).length !== 0 && (
          <TimelineWrapper>
            <HeaderWrapper>{language === 'ko' ? '타임라인' : 'Timeline'}</HeaderWrapper>
            <TaskboxViewWrapper>
              {taskboxes
                ?.filter((v) => !v.allDay)
                .map((taskbox, index) => (
                  <Taskbox
                    key={taskbox.id}
                    index={index}
                    date={date}
                    event={taskbox}
                    events={taskboxes}
                    doneStatus={doneStatus}
                    selectedEvent={selectedEvent}
                    multiSelectIds={multiSelectIds}
                    multiSelected={multiSelectIds.includes(taskbox.id!)}
                    multiSelectAnchorEl={multiSelectAnchorEl}
                    categoryList={categoryList}
                    templateList={templateList}
                    onChange={onFetchTaskbox}
                    onChangeTaskboxImage={onChangeTaskboxDragImage}
                    onChangeSubtask={onChangeSubtask}
                    onChangeMultiSelectIds={onChangeMultiSelectIds}
                    onCloseMultiSelect={onCloseMultiSelect}
                    onClickMultiSelectActions={onClickMultiSelectActions}
                    onMultiSelectDragStart={onMultiSelectDragStart}
                    onMultiSelectDragEnd={onMultiSelectDragEnd}
                    onClickTaskbox={onClickTaskbox}
                    onFetchCategoryList={onFetchCategoryList}
                    onFetchTemplateList={onFetchTemplateList}
                  />
                ))}
            </TaskboxViewWrapper>
          </TimelineWrapper>
        )}
      </DragDropContext>
    </Container>
  );
};

export default TaskboxView;

const Container = styled.div`
  height: calc(100% - 80px);
  overflow-y: scroll;
  ${hideScroll()}
`;

const TodoWrapper = styled.div``;

const TimelineWrapper = styled.div``;

const HeaderWrapper = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 0px 24px;
`;

const TaskboxViewWrapper = styled.div`
  padding: 0px 24px;
`;
