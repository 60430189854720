import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface ProfileProps {
  name: string;
  disabled: boolean;
  onClick: () => void;
}

export const Profile = ({ name, disabled = false, onClick }: ProfileProps) => {
  return (
    <Wrapper disabled={disabled} onClick={onClick}>
      <ProfileBox />
      <Fonts.H3 style={{ marginLeft: '8px' }}>{name}</Fonts.H3>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled: boolean }>`
  width: 300px;
  height: 56px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  background-color: ${COLORS.white};
  color: ${COLORS.gray900};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.sub3};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORS.gray400};
      cursor: no-drop;
      &:hover {
        background-color: ${COLORS.white};
      }
    `}
`;

const ProfileBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${COLORS.gray400};
`;
