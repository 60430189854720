import styled from '@emotion/styled';
import { Divider, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { useRef } from 'react';
import { COLORS } from 'styles/constants';
import ConfirmDialog, { DialogElement } from './ConfirmDialog';
import { useKeyboardEvent } from '@react-hookz/web';
import { osName } from 'react-device-detect';
import { GAEventTrigger } from 'lib/gtag';
import _ from 'lodash';
import { languageAtom } from 'atoms/language';
import { useAtom } from 'jotai';
import { OutTaskboxDetailResponse } from 'queries/model';

const Container = styled.div`
  min-width: 160px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.gray100};
    border-radius: 8px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

const MenuItemText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray900};
`;

const HotkeyText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray600};
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 4px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.gray500};
  margin: 0px 4px;
`;

export const InboxContextMenuName = {
  START_NOW: 'START_NOW',
  START_AFTER: 'START_AFTER',
  ADD_TIME: 'ADD_TIME',
  COMPLETE_NOW: 'COMPLETE_NOW',
  SWITCH_TO_TASK: 'SWITCH_TO_TASK',
  SWITCH_TO_ISSUE: 'SWITCH_TO_ISSUE',
  MOVE_TO_THIS_WEEK: 'MOVE_TO_THIS_WEEK',
  MOVE_TO_NEXT_WEEK: 'MOVE_TO_NEXT_WEEK',
  MOVE_TO_AFTER: 'MOVE_TO_AFTER',
  MOVE_TO_TODAY: 'MOVE_TO_TODAY',
  MOVE_TO_TOP: 'MOVE_TO_TOP',
  MOVE_TO_BOTTOM: 'MOVE_TO_BOTTOM',
  COMPLETED_AND_MOVE_TO_TODAY: 'COMPLETED_AND_MOVE_TO_TODAY',
  MERGE_TASK_GROUP: 'MERGE_TASK_GROUP',
  DELETE: 'DELETE',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  DUPLICATE: 'DUPLICATE',
  COMPLETE: 'COMPLETE',
  CONVERT_TO_TASKBOX: 'CONVERT_TO_TASKBOX',
  CATEGORY: 'CATEGORY',
  DEADLINE: 'DEADLINE',
  CONVERT_TO_PROJECT: 'CONVERT_TO_PROJECT',
  LINK_TO_PROJECT: 'LINK_TO_PROJECT',
  INSTANCE: 'INSTANCE',
  HIGHLIGHT: 'HIGHLIGHT',
  FOCUS_MODE: 'FOCUS_MODE',
  CALENDAR_LOCK: 'CALENDAR_LOCK',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  STOP_ROUTINE: 'STOP_ROUTINE',
} as const;

export type InboxContextMenuType = typeof InboxContextMenuName[keyof typeof InboxContextMenuName];
export interface InboxContextMenuProps {
  id: string;
  type: string;
  done?: boolean;
  menus?: InboxContextMenuType[];
  event?: OutTaskboxDetailResponse;
  data?: any;
  onClickMenu?: (id: string, type: string, menu: InboxContextMenuType, data: any) => void;
}

export const InboxContextMenu = ({ id, type, done = false, menus = [], onClickMenu, data, event }: InboxContextMenuProps) => {
  const [language] = useAtom(languageAtom);
  const refConfirm = useRef<DialogElement>(null);

  useKeyboardEvent(
    true,
    (ev) => {
      if (menus.includes('MERGE_TASK_GROUP') && ev.code === 'KeyG') {
        _clickMenu(id, type, 'MERGE_TASK_GROUP', data);
      } else if (menus.includes('COMPLETED_AND_MOVE_TO_TODAY') && ev.shiftKey && ev.code === 'KeyD') {
        _clickMenu(id, type, 'COMPLETED_AND_MOVE_TO_TODAY', data);
      } else if (menus.includes('MOVE_TO_AFTER') && ev.shiftKey && ev.code === 'KeyL') {
        _clickMenu(id, type, 'MOVE_TO_AFTER', data);
      } else if (menus.includes('MOVE_TO_TODAY') && ev.code === 'Enter') {
        _clickMenu(id, type, 'MOVE_TO_TODAY', data);
      } else if (menus.includes('MOVE_TO_TOP') && ev.code === 'KeyT') {
        _clickMenu(id, type, 'MOVE_TO_TOP', data);
      } else if (menus.includes('MOVE_TO_BOTTOM') && ev.code === 'KeyB') {
        _clickMenu(id, type, 'MOVE_TO_BOTTOM', data);
      } else if (menus.includes('START_NOW') && ev.code === 'KeyS') {
        _clickMenu(id, type, 'START_NOW', data);
      } else if (menus.includes('START_AFTER') && ev.code === 'KeyF') {
        _clickMenu(id, type, 'START_AFTER', data);
      } else if (menus.includes('ADD_TIME') && ev.code === 'KeyA') {
        _clickMenu(id, type, 'ADD_TIME', data);
      } else if (menus.includes('COMPLETE_NOW') && ev.code === 'KeyC') {
        _clickMenu(id, type, 'COMPLETE_NOW', data);
      } else if (menus.includes('INSTANCE') && ev.code === 'Enter') {
        _clickMenu(id, type, 'INSTANCE', data);
      } else if (
        (menus.includes('DELETE') && ev.code === 'Delete' && osName === 'Windows') ||
        (menus.includes('DELETE') && ev.code === 'Backspace' && ev.metaKey && osName === 'Mac OS')
      ) {
        if (type === 'subtask' || type === 'instance' || type === 'instance-type') _clickMenu(id, type, 'DELETE', data);
        else refConfirm.current?.open();
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleClickMenuItem = async (menu: InboxContextMenuType) => {
    _clickMenu(id, type, menu, data);
  };

  const handleClickMenuDelete = async () => {
    if (type === 'instance' || type === 'subtask' || type === 'instance-subtask') {
      _clickMenu(id, type, 'DELETE', data);
    } else {
      refConfirm.current?.open();
    }
  };

  const handleConfirm = (data: any) => {
    _clickMenu(id, type, 'DELETE', data);
  };

  const _clickMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickMenu && onClickMenu(id, type, menu, data);
  };

  return (
    <>
      <Container>
        <MenuList>
          {menus.includes('START_NOW') && (
            <Tooltip
              title={
                language === 'ko' ? '현재 시간으로 시작 시간을 수정합니다.' : 'Update the start time to the current time.'
                // 'Update the start time to the current time.'
              }
              disableInteractive
            >
              <MenuItem
                onClick={() => {
                  handleClickMenuItem('START_NOW');
                  GAEventTrigger({ action: 'timebox_now_start', category: 'timebox_now_start' });
                }}
                style={{ justifyContent: 'space-between' }}
              >
                <div>
                  <span style={{ marginRight: 8 }}>
                    <Icons.StartNow />
                  </span>
                  <MenuItemText>
                    {language === 'ko' ? '지금 시작하기' : 'Start now'}
                    {/* Start now */}
                  </MenuItemText>
                </div>
                <KeyboardButtonRect small>S</KeyboardButtonRect>
              </MenuItem>
            </Tooltip>
          )}
          {menus.includes('START_AFTER') && (
            <Tooltip
              title={
                language === 'ko' ? '직전 태스크박스의 종료 시간으로 시작 시간을 수정합니다.' : 'Update the start time to the current time.'
                // 'Update the start time to the current time.'
              }
              disableInteractive
            >
              <MenuItem onClick={() => handleClickMenuItem('START_AFTER')} style={{ justifyContent: 'space-between' }}>
                <div>
                  <span style={{ marginRight: 8 }}>
                    <Icons.StartAfter />
                  </span>
                  <MenuItemText>
                    {language === 'ko' ? '이어서 시작하기' : 'Start after'}
                    {/* Start after */}
                  </MenuItemText>
                </div>
                <KeyboardButtonRect small>F</KeyboardButtonRect>
              </MenuItem>
            </Tooltip>
          )}
          {menus.includes('MOVE_TO_TOP') && (
            <MenuItem onClick={() => handleClickMenuItem('MOVE_TO_TOP')} style={{ justifyContent: 'space-between' }}>
              <div>
                <span style={{ marginRight: 8 }}>
                  <Icons.ArrowUpLong />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '맨 위로 올리기' : 'Move to top'}
                  {/* Move to top */}
                </MenuItemText>
              </div>
              <KeyboardButtonRect small>T</KeyboardButtonRect>
            </MenuItem>
          )}
          {menus.includes('MOVE_TO_BOTTOM') && (
            <MenuItem onClick={() => handleClickMenuItem('MOVE_TO_BOTTOM')} style={{ justifyContent: 'space-between' }}>
              <div>
                <span style={{ marginRight: 8 }}>
                  <Icons.ArrowDownLong />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '맨 아래로 내리기' : 'Move to bottom'}
                  {/* Move to bottom */}
                </MenuItemText>
              </div>
              <KeyboardButtonRect small>B</KeyboardButtonRect>
            </MenuItem>
          )}
          {(menus.includes('START_NOW') || menus.includes('START_AFTER')) && <Divider sx={{ margin: '4px 0px' }} />}
          {menus.includes('ADD_TIME') && (
            <MenuItem onClick={() => handleClickMenuItem('ADD_TIME')} style={{ justifyContent: 'space-between' }}>
              <div>
                <span style={{ marginRight: 8 }}>
                  <Icons.AddTime />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '30분 추가하기' : 'Add 30 minutes'}
                  {/* Add 30 minutes */}
                </MenuItemText>
              </div>
              <KeyboardButtonRect small>A</KeyboardButtonRect>
            </MenuItem>
          )}
          {menus.includes('COMPLETE_NOW') && (
            <MenuItem
              onClick={() => {
                handleClickMenuItem('COMPLETE_NOW');
                GAEventTrigger({ action: 'timebox_now_complete', category: 'timebox_now_complete' });
              }}
              style={{ justifyContent: 'space-between' }}
            >
              <div>
                <span style={{ marginRight: 8 }}>
                  <Icons.CompleteNow />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '지금 완료하기' : 'Complete now'}
                  {/* Complete now */}
                </MenuItemText>
              </div>
              <KeyboardButtonRect small>C</KeyboardButtonRect>
            </MenuItem>
          )}
          {(menus.includes('ADD_TIME') || menus.includes('COMPLETE_NOW')) && <Divider sx={{ margin: '4px 0px' }} />}
          {process.env.REACT_APP_MODE === 'development' && menus.includes('SWITCH_TO_ISSUE') && (
            <MenuItem onClick={() => handleClickMenuItem('SWITCH_TO_ISSUE')}>
              <span style={{ marginRight: 8 }}>
                <Icons.AddTask fill="#1C1B1F" />
              </span>
              <MenuItemText>
                {language === 'ko' ? '이슈로 전환하기' : 'Convert to issue'}
                {/* Convert to issue */}
              </MenuItemText>
            </MenuItem>
          )}
          {menus.includes('SWITCH_TO_TASK') && (
            <MenuItem onClick={() => handleClickMenuItem('SWITCH_TO_TASK')}>
              <span style={{ marginRight: 8 }}>
                <Icons.SplitScreen fill="#1C1B1F" />
              </span>
              <MenuItemText>
                {language === 'ko' ? '태스크로 전환하기' : 'Convert to task'}
                {/* Convert to task */}
              </MenuItemText>
            </MenuItem>
          )}
          {((process.env.REACT_APP_MODE === 'development' && menus.includes('SWITCH_TO_ISSUE')) || menus.includes('SWITCH_TO_TASK')) && (
            <Divider style={{ margin: '4px 0px' }} />
          )}
          {menus.includes('MOVE_TO_THIS_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_THIS_WEEK')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.CalendarWithoutClock fill="#1C1B1F" width={14} height={14} />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '플랜 - 이번주' : 'Plan - This week'}
                  {/* Plan - This week */}
                </MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+I</HotkeyText>
            </MenuItem>
          )}
          {menus.includes('MOVE_TO_NEXT_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_NEXT_WEEK')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.NextWeek fill="#1C1B1F" />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '플랜 - 다음주' : 'Plan - Next week'}
                  {/* Plan - Next week */}
                </MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+K</HotkeyText>
            </MenuItem>
          )}
          {menus.includes('MERGE_TASK_GROUP') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MERGE_TASK_GROUP')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.TaskGroup width={16} height={16} fill="#1C1B1F" />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '태스크 그룹으로 합치기' : 'Merge to task group'}
                  {/* Merge to task group */}
                </MenuItemText>
              </div>
              <div>
                <KeyboardButtonRect small>G</KeyboardButtonRect>
              </div>
            </MenuItem>
          )}
          {(menus.includes('MOVE_TO_THIS_WEEK') || menus.includes('MOVE_TO_NEXT_WEEK')) && <Divider style={{ margin: '4px 0px' }} />}
          {menus.includes('COMPLETED_AND_MOVE_TO_TODAY') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('COMPLETED_AND_MOVE_TO_TODAY')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.Check stroke="#1C1B1F" />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '완료하고 오늘로 보내기' : 'Complete and move to today'}
                  {/* Complete and move to today */}
                </MenuItemText>
              </div>
              <div>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  Shift
                </KeyboardButtonRect>
                <KeyboardCommandPlus>+</KeyboardCommandPlus>
                <KeyboardButtonRect small>D</KeyboardButtonRect>
              </div>
            </MenuItem>
          )}
          {menus.includes('MOVE_TO_AFTER') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_AFTER')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  {type === 'instance-subtask' ? <Icons.ArrowRightLong width={16} height={16} /> : <Icons.Later width={16} height={16} fill="#1C1B1F" />}
                </span>
                <MenuItemText>
                  {language === 'ko' ? (type === 'instance-subtask' ? '다음에 하기' : '나중에') : type === 'instance-subtask' ? 'Do next' : 'Later'}
                  {/* {type === 'instance-subtask' ? 'Do next' : 'Later'} */}
                </MenuItemText>
              </div>
              <div>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  Shift
                </KeyboardButtonRect>
                <KeyboardCommandPlus>+</KeyboardCommandPlus>
                <KeyboardButtonRect small>L</KeyboardButtonRect>
              </div>
            </MenuItem>
          )}
          {menus.includes('COMPLETE') && (
            <>
              {done ? (
                <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('COMPLETE')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>
                      <Icons.Uncheck />
                    </span>
                    <MenuItemText>
                      {language === 'ko' ? '완료 해제하기' : 'Undo completion'}
                      {/* Undo completion */}
                    </MenuItemText>
                  </div>
                </MenuItem>
              ) : (
                <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('COMPLETE')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>
                      <Icons.Check />
                    </span>
                    <MenuItemText>
                      {language === 'ko' ? '완료하기' : 'Complete'}
                      {/* Complete */}
                    </MenuItemText>
                  </div>
                </MenuItem>
              )}
            </>
          )}
          {menus.includes('FOCUS_MODE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('FOCUS_MODE')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.FocusMode stroke={COLORS.gray900} />
                </span>
                <MenuItemText>{language === 'ko' ? '포커스모드 진입하기' : 'Focus mode'}</MenuItemText>
              </div>
              <div>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  F
                </KeyboardButtonRect>
              </div>
            </MenuItem>
          )}
          {menus.includes('HIGHLIGHT') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('HIGHLIGHT')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>{event?.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}</span>
                <MenuItemText>
                  {language === 'ko' ? (event?.focus ? '하이라이트 풀기' : '하이라이트 하기') : event?.focus ? 'Unhighlight' : 'Highlight'}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('CALENDAR_LOCK') && (
            <Tooltip
              title={
                language === 'ko'
                  ? event?.recurrence || event?.routine
                    ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                    : event?.lockedIn
                    ? '구글 캘린더에서 해제하기'
                    : '구글 캘린더에 표시하기'
                  : event?.recurrence || event?.routine
                  ? `Support for 'Display on Google Calendar' for recurring tasks will be added later.`
                  : event?.lockedIn
                  ? 'Unmark from Google Calendar'
                  : 'Display on Google Calendar'
              }
              disableInteractive
            >
              <MenuItem
                style={{ justifyContent: 'space-between' }}
                onClick={() => {
                  event?.recurrence || event?.routine ? null : handleClickMenuItem('CALENDAR_LOCK');
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8 }}>{event?.lockedIn ? <Icons.LockOut stroke={COLORS.gray900} /> : <Icons.LockIn />}</span>
                  <MenuItemText>
                    {language === 'ko'
                      ? event?.lockedIn
                        ? '구글 캘린더에서 해제하기'
                        : '구글 캘린더에 표시하기'
                      : event?.lockedIn
                      ? 'Unmark from Google Calendar'
                      : 'Display on Google Calendar'}
                  </MenuItemText>
                </div>
              </MenuItem>
            </Tooltip>
          )}
          {menus.includes('CATEGORY') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('CATEGORY')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.Category />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '카테고리 추가하기' : 'Add category'}
                  {/* Add category */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('DEADLINE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('DEADLINE')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.Flag width={16} height={16} fill={COLORS.gray900} />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '마감기한 추가하기' : 'Add deadline'}
                  {/* Add deadline */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {(menus.includes('CATEGORY') ||
            menus.includes('DEADLINE') ||
            menus.includes('FOCUS_MODE') ||
            menus.includes('HIGHLIGHT') ||
            menus.includes('CALENDAR_LOCK')) &&
            type !== 'instance' && <Divider sx={{ margin: '4px 0px' }} />}
          {menus.includes('SAVE_TEMPLATE') && (
            <>
              {data.length === 0 ? (
                <Tooltip
                  title={
                    language === 'ko' ? '템플릿으로 저장할 하위 업무가 없어요.' : 'No subtasks to save as a template.'
                    // 'No subtasks to save as a template.'
                  }
                  disableInteractive
                >
                  <MenuItem style={{ justifyContent: 'space-between', cursor: 'default' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: 8 }}>
                        <Icons.Template width={14} height={14} />
                      </span>
                      <MenuItemText>
                        {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                        {/* Save as template */}
                      </MenuItemText>
                    </div>
                    {/* <div>
                      <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                        Shift
                      </KeyboardButtonRect>
                      <KeyboardCommandPlus>+</KeyboardCommandPlus>
                      <KeyboardButtonRect small>T</KeyboardButtonRect>
                    </div> */}
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('SAVE_TEMPLATE')}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>
                      <Icons.Template width={14} height={14} />
                    </span>
                    <MenuItemText>
                      {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                      {/* Save as template */}
                    </MenuItemText>
                  </div>
                  {/* <div>
                    <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                      Shift
                    </KeyboardButtonRect>
                    <KeyboardCommandPlus>+</KeyboardCommandPlus>
                    <KeyboardButtonRect small>T</KeyboardButtonRect>
                  </div> */}
                </MenuItem>
              )}
            </>
          )}
          {menus.includes('LOAD_TEMPLATE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('LOAD_TEMPLATE')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.Template width={14} height={14} />
                </span>
                <MenuItemText>{language === 'ko' ? '템플릿 불러오기' : 'Load template'}</MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('DUPLICATE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('DUPLICATE')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.Duplicate />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '태스크박스 복제하기' : 'Duplicate taskbox'}
                  {/* Duplicate taskbox */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {(menus.includes('DUPLICATE') || menus.includes('SAVE_TEMPLATE') || menus.includes('LOAD_TEMPLATE')) && <Divider sx={{ margin: '4px 0px' }} />}
          {menus.includes('CONVERT_TO_PROJECT') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('CONVERT_TO_PROJECT')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.ConvertToProject />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '새로운 프로젝트로 전환하기' : 'Convert to new project'}
                  {/* Convert to new project */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('LINK_TO_PROJECT') && (
            <MenuItem className="link-project" style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('LINK_TO_PROJECT')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.LinkToProject />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '기존 프로젝트에 연결하기' : 'Link to existing project'}
                  {/* Link to existing project */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('INSTANCE') && (
            <>
              <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('INSTANCE')}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8 }}>
                    <Icons.LoadToInstance />
                  </span>
                  <MenuItemText>
                    {language === 'ko' ? '이 인스턴스로 가져오기' : 'Import into this instance'}
                    {/* Import into this instance */}
                  </MenuItemText>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <KeyboardButtonRect small>Enter</KeyboardButtonRect>
                </div>
              </MenuItem>
              <Divider sx={{ margin: '8px 0px' }} />
            </>
          )}
          {menus.includes('MOVE_TO_TODAY') && (
            <>
              <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_TODAY')}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 8 }}>
                    <Icons.MoveToToday />
                  </span>
                  <MenuItemText>
                    {language === 'ko' ? '오늘로 가져오기' : 'Import into today'}
                    {/* Import into today */}
                  </MenuItemText>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <KeyboardButtonRect small>Enter</KeyboardButtonRect>
                </div>
              </MenuItem>
              <Divider sx={{ margin: '8px 0px' }} />
            </>
          )}
          {menus.includes('CONVERT_TO_TASKBOX') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('CONVERT_TO_TASKBOX')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.ConvertToTaskbox width={14} height={14} />
                </span>
                <MenuItemText>
                  {language === 'ko' ? '태스크박스로 전환하기' : 'Convert to taskbox'}
                  {/* Convert to taskbox */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('STOP_ROUTINE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('STOP_ROUTINE')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Stop style={{ marginRight: 8 }} />
                <MenuItemText style={{ color: COLORS.negative1 }}>
                  {language === 'ko' ? '이 루틴 홀드하기' : 'Hold this routine'}
                  {/* Convert to taskbox */}
                </MenuItemText>
              </div>
            </MenuItem>
          )}
          {menus.includes('DELETE') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={handleClickMenuDelete}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  {type === 'instance' ? <Icons.DeleteInstance /> : <Icons.Delete fill={COLORS.negative1} width={14} height={14} />}
                </span>
                <MenuItemText style={{ color: COLORS.negative1, marginRight: '22px' }}>
                  {
                    language === 'ko'
                      ? type === 'instance' || type === 'routine'
                        ? '인스턴스 제거하기'
                        : '삭제'
                      : type === 'instance'
                      ? 'Delete instance'
                      : 'Delete'
                    // type === 'instance' ? 'Delete instance' : 'Delete'
                  }
                </MenuItemText>
              </div>
              {osName === 'Windows' ? (
                <div>
                  <KeyboardButtonRect small>Delete</KeyboardButtonRect>
                </div>
              ) : (
                <div>
                  <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                    ⌘
                  </KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect small>Backspace</KeyboardButtonRect>
                </div>
              )}
            </MenuItem>
          )}
        </MenuList>
      </Container>
      <ConfirmDialog
        ref={refConfirm}
        onConfirm={handleConfirm}
        data={data}
        type={type}
        title={
          language === 'ko'
            ? type === 'merge'
              ? '선택된 태스크 삭제'
              : type === 'task'
              ? '태스크 삭제'
              : type === 'taskbox'
              ? '태스크박스 삭제'
              : type === 'instance'
              ? '인스턴스 삭제'
              : type === 'reflection'
              ? '작성된 회고 삭제'
              : '이슈'
            : type === 'merge'
            ? 'Delete selected tasks'
            : type === 'task'
            ? 'Delete task'
            : type === 'taskbox'
            ? 'Delete taskbox'
            : type === 'instance'
            ? 'Delete instance'
            : type === 'reflection'
            ? 'Delete written reflection'
            : 'Delete issue'
          // type === 'merge'
          //   ? 'Delete selected tasks'
          //   : type === 'task'
          //   ? 'Delete task'
          //   : type === 'taskbox'
          //   ? 'Delete taskbox'
          //   : type === 'instance'
          //   ? 'Delete instance'
          //   : type === 'reflection'
          //   ? 'Delete written reflection'
          //   : 'Delete issue'
        }
      >
        <div style={{ color: `${COLORS.negative1}`, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
          {language === 'ko'
            ? `${
                type === 'merge'
                  ? '선택된 태스크'
                  : type === 'task'
                  ? '태스크'
                  : type === 'taskbox'
                  ? '태스크박스'
                  : type === 'reflection'
                  ? '작성된 회고'
                  : '이슈'
              }를 삭제하시겠어요?`
            : `Delete ${
                type === 'merge'
                  ? 'selected tasks'
                  : type === 'task'
                  ? 'task'
                  : type === 'taskbox'
                  ? 'taskbox'
                  : type === 'reflection'
                  ? 'written reflection'
                  : 'issue'
              }?`}
          {/* {`Delete
          ${
            type === 'merge'
              ? 'selected tasks'
              : type === 'task'
              ? 'task'
              : type === 'taskbox'
              ? 'taskbox'
              : type === 'reflection'
              ? 'written reflection'
              : 'issue'
          }?`} */}
        </div>
        <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>
          {language === 'ko' ? '관련 데이터를 모두 삭제합니다.' : 'All related data will be deleted.'}
          {/* Deletes all related data. */}
        </div>
      </ConfirmDialog>
    </>
  );
};

export default InboxContextMenu;
