import { SVGProps } from 'react';

export const StartNowTaskboxAction = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M4.16406 14.4437V5.55484C4.16406 4.82187 4.16406 4.45498 4.31831 4.23811C4.45293 4.04883 4.66043 3.92456 4.89079 3.89485C5.15463 3.86082 5.47823 4.0334 6.12451 4.37809L14.4578 8.82253L14.4609 8.82387C15.1751 9.20479 15.5324 9.39533 15.6496 9.6493C15.7518 9.87085 15.7518 10.1266 15.6496 10.3481C15.5322 10.6025 15.1741 10.7938 14.4578 11.1758L6.12451 15.6202C5.47777 15.9651 5.15473 16.1371 4.89079 16.103C4.66043 16.0733 4.45293 15.9491 4.31831 15.7598C4.16406 15.5429 4.16406 15.1767 4.16406 14.4437Z"
        stroke="url(#paint0_linear_27698_387854)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient id="paint0_linear_27698_387854" x1="15.7452" y1="9.99895" x2="4.18308" y2="9.99895" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4760DE" />
          <stop offset="1" stopColor="#2A92F3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
