import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { Icons, Login } from 'components';
import Fonts from 'components/Fonts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Slide from 'components/Slide';
import { COLORS } from 'styles/constants';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AppleSignin from 'react-apple-signin-auth';
import { v4 as uuidv4 } from 'uuid';
import { signinUserAppleMobileV1AuthAppleMobilePost, signinUserAppleV1AuthApplePost, signinUserAppleV2V2AuthApplePost } from 'queries';
import { InAppleAuthModel } from 'queries/model';
import Toast from 'components/Toast';
import toast from 'react-hot-toast';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  header {
    position: absolute;
    left: 40px;
    top: 40px;
  }

  section.signin {
    white-space: pre-line;
    padding: 24px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.25px;
    }
    footer {
      position: absolute;
      bottom: 80px;
    }
  }

  section.intro {
    flex: 3;
    overflow: hidden;
    background-color: #2d6adf;
  }
`;

const LoginErrorWrapper = styled.div`
  width: 400px;
  display: flex;
  padding: 16px 24px;
  margin-top: 30px;
  border-radius: 8px;
  background-color: ${COLORS.negative2};
`;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
const APPLE_SERVICE_ID = process.env.REACT_APP_APPLE_SERVICE_ID!;
const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI!;

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const onHandleLoginGoogle = useGoogleLogin(options);
  return <Login onClick={onHandleLoginGoogle} label={label} />;
};

const AppleLogInButton = ({ label, onClick }: { label: string; onClick: () => void }) => {
  return <Login label={label} icon={Icons.AppleLogo} onClick={onClick} />;
};

const SignIn = () => {
  const location = useLocation();
  const error = new URLSearchParams(location.search).get('error');
  const surveyStatus = new URLSearchParams(location.search).get('survey_target');
  const { t, i18n } = useTranslation();
  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      setLoginError('error');
    }
  }, [error]);

  const SlidePages = [
    {
      title: t('signin.slide1'),
      image: i18n.language === 'ko' ? require('assets/images/newSwipe1.png') : require('assets/images/newSwipeEn1.png'),
    },
    {
      title: t('signin.slide2'),
      image: i18n.language === 'ko' ? require('assets/images/newSwipe2.png') : require('assets/images/newSwipeEn2.png'),
    },
    {
      title: t('signin.slide3'),
      image: i18n.language === 'ko' ? require('assets/images/newSwipe3.png') : require('assets/images/newSwipeEn3.png'),
    },
  ];

  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/auth`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  const appleAuthOptions = {
    clientId: APPLE_SERVICE_ID,
    redirectURI: APPLE_REDIRECT_URI,
    scope: 'email name',
    nonce: uuidv4(),
    state: uuidv4(),
    usePopup: true,
  };

  console.log(appleAuthOptions);

  useEffect(() => {
    if (surveyStatus === 'false') {
      localStorage.setItem('surveyTarget', 'false');
    }
  }, []);

  const navigate = useNavigate();

  const handleSuccessAppleLogin = async (res: any) => {
    const { code, id_token } = res.authorization;
    const { email, name } = res.user || {};

    const params: InAppleAuthModel = {
      authorization: {
        code,
        idToken: id_token,
      },
      user:
        email && name
          ? {
              email,
              name: {
                firstName: name.firstName,
                lastName: name.lastName,
              },
            }
          : undefined,
    };

    try {
      const response = await signinUserAppleV2V2AuthApplePost(params);
      if (response) {
        navigate('/auth?access_token=' + response.accessToken);
      }
    } catch (e: any) {
      if (e.response.data.code === 2313) {
        toast.error(t('signin.login_failed_exist_email'));
      } else {
        toast.error(t('signin.apple_login_failed'));
      }
      console.log(e);
    }
  };

  const handleErrorAppleLogin = (error: any) => {
    switch (error.error) {
      case 'popup_closed_by_user':
        setLoginError('error');
        break;
    }
  };

  return (
    <Wrapper>
      <header>
        <Icons.SlashBeta width={96} height={28} />
      </header>
      <section className="signin">
        {loginError ? <div className="title pb-3">{t('signin.title.login')}</div> : <div className="title pb-3">{t('signin.title.welcome')}</div>}
        <Fonts.Body1 className="text-gray800 pb-6">{t('signin.description')}</Fonts.Body1>
        <div style={{ marginBottom: 20 }}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogInButton options={googleLoginOptions} label={t('signin.login')} />
          </GoogleOAuthProvider>
        </div>
        <div style={{ marginBottom: 32 }}>
          <AppleSignin
            authOptions={appleAuthOptions}
            onSuccess={(res: any) => handleSuccessAppleLogin(res)}
            onError={handleErrorAppleLogin}
            uiType="light"
            render={(props: any) => <AppleLogInButton label={t('signin.apple_login')} onClick={props.onClick} />}
          />
        </div>
        <div style={{ fontSize: '12px' }}>
          <Trans
            i18nKey={'signin.terms_of_service'}
            components={{
              1: (
                <a href="https://terms.doslash.io/" style={{ color: COLORS.sub2, textDecoration: 'underline' }}>
                  이용약관
                </a>
              ),
              2: (
                <a href="https://policy.doslash.io/" target="_blank" rel="noreferrer" style={{ color: COLORS.sub2, textDecoration: 'underline' }}>
                  개인정보 처리방침
                </a>
              ),
            }}
          />
        </div>
        {loginError && (
          <LoginErrorWrapper>
            <div
              style={{
                width: '24px',
                height: '24px',
                marginRight: '8px',
                borderRadius: '50%',
                backgroundColor: COLORS.negative1,
                color: COLORS.white,
                fontWeight: 'bold',
              }}
            >
              !
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Fonts.H3>{t('signin.access_denied')}</Fonts.H3>
              <Fonts.Body1 style={{ textAlign: 'left', marginTop: '4px' }}> {t('signin.login_failed')}</Fonts.Body1>
            </div>
          </LoginErrorWrapper>
        )}
      </section>
      <section className="intro px-16">
        <Slide pages={SlidePages} />
      </section>
    </Wrapper>
  );
};

export default SignIn;
