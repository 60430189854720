import { Box, ToggleButton, ToggleButtonProps, Tooltip } from '@mui/material';
import React, { FC, MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react';
import { CoreIcon, isString } from '@remirror/core';
import { UseCommandOptionValuesParams, useCommandOptionValues } from '../use-command-option-value';
import { COLORS } from 'styles/constants';
import { useKeyboardEvent } from '@react-hookz/web';
import { MemoActionType } from '../Menubar';
import { setCaretToEnd } from 'utils';

export interface CommandButtonProps extends Omit<ToggleButtonProps, 'value' | 'aria-label'>, Omit<UseCommandOptionValuesParams, 'active' | 'attrs'> {
  'active'?: UseCommandOptionValuesParams['active'];
  'aria-label'?: string;
  'label'?: NonNullable<ReactNode>;
  'commandName': string;
  'displayShortcut'?: boolean;
  'onSelect': () => void;
  'onChangeUserAction'?: (actionType: MemoActionType) => HTMLElement | null;
  'icon'?: CoreIcon | JSX.Element;
  'attrs'?: UseCommandOptionValuesParams['attrs'];
  'hover'?: boolean;
}

export const CommandButton: FC<CommandButtonProps> = ({
  commandName,
  active = false,
  enabled = true,
  hover = false,
  attrs,
  onSelect,
  onChange,
  onChangeUserAction,
  icon,
  displayShortcut = true,
  'aria-label': ariaLabel,
  label,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: MouseEvent<HTMLElement>, value: any) => {
      const targetElement = onChangeUserAction?.('CLICK');
      if (targetElement && targetElement.innerText.includes('/') && targetElement.innerText.length > 1) {
        const innerTextWithoutSlash = targetElement.innerText.replace(/\/$/, '');
        targetElement.innerText = innerTextWithoutSlash;

        const newElement = document.createElement('p');
        const lineBreak = document.createElement('br');
        lineBreak.className = 'ProseMirror-trailingBreak';

        const parentNode = targetElement!.parentNode;
        parentNode!.insertBefore(newElement, targetElement!.nextSibling);
        setCaretToEnd(newElement);
      } else if (targetElement && targetElement.innerText.includes('/') && targetElement.innerText.length === 1) {
        targetElement.remove();
      }
      setTimeout(() => {
        onSelect();
        onChange?.(e, value);
      }, 20);
    },
    [onSelect, onChange],
  );

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === 'Enter') {
        if (hover) {
          const targetElement = onChangeUserAction?.('PRESS');
          if (targetElement && targetElement.innerText.includes('/') && targetElement.innerText.length > 1) {
            const innerTextWithoutSlash = targetElement.innerText.replace(/\/$/, '');
            targetElement.innerText = innerTextWithoutSlash;
          } else if (targetElement && targetElement.innerText.includes('/') && targetElement.innerText.length === 1) {
            targetElement.remove();
          }

          setTimeout(() => {
            onSelect();
          }, 20);
        }
      }
    },
    [onSelect],
    { eventOptions: { passive: false }, target: document.querySelector('.ProseMirror') as HTMLElement },
  );

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
  }, []);

  const commandOptions = useCommandOptionValues({ commandName, active, enabled, attrs });

  let fallbackIcon = null;

  if (commandOptions.icon) {
    fallbackIcon = isString(commandOptions.icon) ? commandOptions.icon : commandOptions.icon.name;
  }

  const labelText = ariaLabel ?? commandOptions.label ?? '';
  const tooltipText = label ?? labelText;
  const shortcutText = displayShortcut && commandOptions.shortcut ? ` (${commandOptions.shortcut})` : '';

  return (
    <Tooltip title={`${tooltipText}${shortcutText}`}>
      {/*
        This Box<span> wrapper fixes an MUI tooltip error when the button is disabled.
      */}
      <Box component="span" sx={{ '&:not(:first-of-type)': { marginLeft: '-1px' } }}>
        <ToggleButton
          aria-label={labelText}
          selected={active}
          disabled={!enabled}
          onMouseDown={handleMouseDown}
          size="small"
          sx={{
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'flex-start',
            'border': 'none',
            'borderRadius': '6px',
            'padding': '3px',
            'backgroundColor': hover ? COLORS.gray100 : 'transparent',
            'textTransform': 'none',
            '&.Mui-selected': {
              backgroundColor: 'primary.main',
              color: COLORS.white,
            },
            '&.Mui-disabled': {
              border: 'none',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'primary.dark',
              color: COLORS.white,
            },
            '&:not(:first-of-type)': {
              borderLeft: '1px solid transparent',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            '&:not(:last-of-type)': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            ':hover': {
              backgroundColor: hover ? COLORS.gray100 : 'transparent',
            },
          }}
          {...rest}
          value={commandName}
          onChange={handleChange}
        >
          <div style={{ border: `1px solid ${COLORS.gray200}`, borderRadius: '5px', padding: '5px', backgroundColor: COLORS.white }}>{icon}</div>
          <div style={{ fontSize: '13px', marginLeft: '10px', fontWeight: 'bold' }}>{commandName}</div>
        </ToggleButton>
      </Box>
    </Tooltip>
  );
};
