import styled from '@emotion/styled';
import { Avatar, Button, TextField, Tooltip } from '@mui/material';
import { OutUserDetail } from 'queries/model';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';

interface AccountProps {
  me?: OutUserDetail;
  userName?: string;
  onChangeName?: (name: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onOpenDeleteDialog?: () => void;
}

const AccountSetting = ({ me, userName, onChangeName = () => {}, onBlur, onKeyDown, onOpenDeleteDialog }: AccountProps) => {
  const { t } = useTranslation();
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  return (
    <Container>
      <MainHeaderText>{t('settings.privacy.header')}</MainHeaderText>
      <UserInfoWrapper>
        <UserNameWrapper>
          <UserInfoText>{t('settings.privacy.name')}</UserInfoText>
          <TextField fullWidth value={userName ? userName : ''} onChange={(e) => onChangeName(e.target.value)} onBlur={onBlur} onKeyDown={onKeyDown} />
        </UserNameWrapper>
        <UserProfile>
          <UserInfoText>{t('settings.privacy.profile')}</UserInfoText>
          <UserProfileImage>
            <ProfileImage>
              {me?.name ? (
                me.profileImage ? (
                  <img src={me?.profileImage} />
                ) : (
                  <Avatar
                    {...stringAvatar(me.name)}
                    style={{
                      width: '48px',
                      height: '48px',
                    }}
                  />
                )
              ) : (
                <EmptyProfileImage />
              )}
            </ProfileImage>
            <Tooltip title={t('settings.privacy.preparing')}>
              <Button
                variant="outlined"
                sx={{
                  'color': COLORS.gray800,
                  'borderColor': COLORS.gray200,
                  'borderRadius': '8px',
                  'fontSize': '13px',
                  'fontWeight': 700,
                  'padding': '6px 12px',
                  'textTransform': 'none',
                  '&:hover': { borderColor: COLORS.gray200 },
                }}
              >
                {t('settings.privacy.change')}
              </Button>
            </Tooltip>
          </UserProfileImage>
        </UserProfile>
        <UserGoogle>
          <UserInfoText>{t('settings.privacy.google_account')}</UserInfoText>
          <UserGoogleEmail>{me?.email}</UserGoogleEmail>
        </UserGoogle>
      </UserInfoWrapper>
      <AccountDelete>
        <DeleteText>
          <div style={{ fontSize: '16px', fontWeight: 700 }}>{t('settings.privacy.delete_account')}</div>
          <div style={{ fontSize: '13px' }}>{t('settings.privacy.delete_account_description')}</div>
        </DeleteText>
        <Button
          variant="contained"
          color="error"
          onClick={onOpenDeleteDialog}
          sx={{ padding: '6px 12px', borderRadius: '8px', fontSize: '13px', boxShadow: 'none', backgroundColor: COLORS.negative1, textTransform: 'none' }}
        >
          {t('settings.privacy.unscribe')}
        </Button>
      </AccountDelete>
    </Container>
  );
};

const Container = styled.div``;

const MainHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const UserInfoWrapper = styled.div`
  width: 630px;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
  background-color: ${COLORS.white};
  margin-bottom: 24px;
`;

const UserInfoText = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const UserNameWrapper = styled.div``;

const UserProfile = styled.div`
  margin: 24px 0px;
`;

const UserProfileImage = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  cursor: pointer;
`;

const EmptyProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #abb0bf;
`;

const UserGoogle = styled.div``;

const UserGoogleEmail = styled.div`
  font-size: 13px;
`;

const AccountDelete = styled.div`
  padding: 16px 24px;
  width: 100%;
  background-color: ${COLORS.gray200};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteText = styled.div``;

export default AccountSetting;
