import { SVGProps } from 'react';

export const OrganizeToday = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#clip0_20430_267389)">
        <path
          d="M16.3313 11.1C14.1211 11.115 11.9954 10.2517 10.4214 8.70004C8.84745 7.14835 7.95402 5.03517 7.9375 2.825C7.94171 2.12412 8.03623 1.42671 8.21875 0.75C6.17847 0.958141 4.28716 1.91318 2.90846 3.43148C1.52975 4.94978 0.760999 6.92416 0.75 8.975C0.75 13.5438 4.5 17.25 9.1375 17.25C13.0437 17.25 16.3125 14.6125 17.25 11.05C16.9428 11.0835 16.634 11.1002 16.325 11.1H16.3313Z"
          fill="url(#paint0_linear_20430_267389)"
          stroke="#72B9D5"
          strokeWidth="0.375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_20430_267389" x1="3.95625" y1="2.375" x2="12.2625" y2="16.7562" gradientUnits="userSpaceOnUse">
          <stop stopColor="#86C3DB" />
          <stop offset="0.5" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <clipPath id="clip0_20430_267389">
          <rect width="16.875" height="16.875" fill="white" transform="translate(0.5625 0.5625)" />
        </clipPath>
      </defs>
    </svg>
  );
};
