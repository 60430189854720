import styled from '@emotion/styled';
import { Button, Divider } from '@mui/material';
import { languageAtom } from 'atoms/language';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';

interface MembershipProps {
  onClickJoinBeliever?: () => void;
}

const MembershipPage = ({ onClickJoinBeliever }: MembershipProps) => {
  const { t } = useTranslation();
  const [language] = useAtom(languageAtom);
  const CoreFeature = [
    {
      title: language === 'ko' ? '실행' : 'Action',
      features:
        language === 'ko'
          ? ['제한 없이 태스크 추가', '타임블로킹', '캘린더 일정 생성 · 수정', '반복 업무, 반복 일정', '메모', '빠른 입력', '태스크 그루핑', '통계']
          : [
              'Add tasks without limits',
              'Time-blocking',
              'Create and edit calendar events',
              'Recurring tasks and events',
              'Memos',
              'Quick input',
              'Task grouping',
              'Statistics',
            ],
    },
  ];
  const ProFeature = [
    // {
    //   title: language === 'ko' ? '목표' : 'Goal',
    //   status: language === 'ko' ? '준비중' : 'Coming soon',
    //   features: language === 'ko' ? ['월간 목표', '목표 리마인더'] : ['Monthly goals', 'Goal reminders'],
    // },
    {
      title: language === 'ko' ? '계획' : 'Plan',
      status: language === 'ko' ? '현재 무료' : 'Currently free',
      features: language === 'ko' ? ['프로젝트', '프로젝트 타임블로킹', '루틴'] : ['Projects', 'Project time-blocking', 'Routines'],
    },
    {
      title: language === 'ko' ? '회고' : 'Reflection',
      status: language === 'ko' ? '현재 무료' : 'Currently free',
      features: language === 'ko' ? ['데일리 회고', '지난 회고 다시 보기'] : ['Daily reflection', 'Review past reflections'],
    },
  ];
  const BelieverFeature = [
    {
      title: 'Early Access',
      features: language === 'ko' ? ['새로운 기능 우선 출시'] : ['Early Access to New Features'],
      // features: language === 'ko' ? ['새로운 기능 우선 출시', '목표 리마인더'] : ['Early Access to New Features', 'Goal reminders'],
    },
    {
      title: 'Contributions',
      features: language === 'ko' ? ['로드맵 확인', '신규 기능 투표'] : ['View roadmap', 'Vote on new features'],
    },
    {
      title: '100',
      features: language === 'ko' ? ['베타 참여 고객을 위한 100개 멤버십 한정 판매'] : ['Limited sale of 100 memberships for beta participants'],
    },
  ];

  return (
    <Container>
      <PricingHeader>{t(`membership.tab1`)}</PricingHeader>
      <PricingContent>
        <PricingContentCard>
          <PricingContentCardTitle>Core</PricingContentCardTitle>
          <PricingContentPrice>Free</PricingContentPrice>
          <div
            style={{
              width: '100%',
              height: '40px',
              border: `1px solid ${COLORS.gray200}`,
              borderRadius: '8px',
              boxShadow: 'none',
              color: COLORS.brand1,
              margin: '56px 0px 24px 0px',
              padding: '10px 0px',
              fontSize: '13px',
              fontWeight: 700,
              textAlign: 'center',
              textTransform: 'none',
            }}
          >
            {language === 'ko' ? '현재 이용중' : 'Currently using'}
          </div>
          <FeatureWrapper>
            <FeatureDescription>{language === 'ko' ? '포함하는 기능' : 'Included Features'}</FeatureDescription>
            {CoreFeature.map((feature, idx) => (
              <FeatureListWrapper key={idx}>
                <FeatureListTitle>{feature.title}</FeatureListTitle>
                {feature.features.map((feature, idx) => (
                  <FeatureListContent key={idx}>
                    <Icons.Check
                      width={24}
                      height={24}
                      style={{
                        marginRight: 4,
                        minWidth: 24,
                      }}
                    />
                    <span style={{ marginTop: 1 }}>{feature}</span>
                  </FeatureListContent>
                ))}
              </FeatureListWrapper>
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: COLORS.gray100,
                borderRadius: '12px',
                padding: '12px',
                fontSize: '12px',
                marginTop: '28px',
              }}
            >
              {language === 'ko'
                ? 'Pro 출시 전까지 Pro의 모든 기능이 무료로 제공돼요!'
                : 'Until Pro is officially launched, all Pro features are available for free'}
            </div>
          </FeatureWrapper>
        </PricingContentCard>
        <PricingContentCard>
          <PricingContentCardTitle>Pro</PricingContentCardTitle>
          <PricingContentPrice style={{ color: COLORS.gray600 }}>{language === 'ko' ? '$10 / 월' : '$10 / Month'}</PricingContentPrice>
          <Button
            variant="contained"
            disabled
            sx={{
              width: '100%',
              height: '40px',
              backgroundColor: COLORS.gray300,
              borderRadius: '8px',
              boxShadow: 'none',
              margin: '56px 0px 24px 0px',
              padding: '10px 0px',
              fontSize: '13px',
              fontWeight: 700,
              textTransform: 'none',
            }}
          >
            {language === 'ko' ? '준비중' : 'Coming soon'}
          </Button>
          <FeatureWrapper>
            <FeatureDescription>{language === 'ko' ? 'Core의 모든 기능과' : 'All Core Features Plus'}</FeatureDescription>
            {ProFeature.map((feature, idx) => (
              <FeatureListWrapper
                key={idx}
                style={
                  idx === 2
                    ? {
                        marginTop: '20px',
                      }
                    : {}
                }
              >
                <FeatureListTitle>
                  <div>{feature.title} </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: idx === 0 ? COLORS.gray100 : COLORS.sub3,
                      borderRadius: '6px',
                      color: idx === 0 ? COLORS.gray500 : COLORS.brand1,
                      fontSize: '10px',
                      marginLeft: '6px',
                      padding: '2px 6px',
                    }}
                  >
                    {feature.status}
                  </div>
                </FeatureListTitle>
                {feature.features.map((feature, idx) => (
                  <FeatureListContent key={idx}>
                    <Icons.Check
                      width={24}
                      height={24}
                      style={{
                        marginRight: 4,
                        minWidth: 24,
                      }}
                    />
                    <span style={{ marginTop: 1 }}>{feature}</span>
                  </FeatureListContent>
                ))}
                {idx === 0 && <Divider sx={{ margin: '20px 0px' }} />}
              </FeatureListWrapper>
            ))}
          </FeatureWrapper>
        </PricingContentCard>
        <PricingContentCard>
          <PricingContentCardTitle>Believer</PricingContentCardTitle>
          <PricingContentPrice>{language === 'ko' ? '$200 / 5년' : '$200 / 5 Years'}</PricingContentPrice>
          <PricingContentSubPrice>
            <div
              style={{
                backgroundColor: COLORS.negative1,
                borderRadius: 10,
                color: COLORS.white,
                fontSize: 12,
                marginRight: 10,
                padding: '2px 8px',
              }}
            >
              -67%
            </div>
            <div>{language === 'ko' ? '$3.3 / 월' : '$3.3 / Month'}</div>
          </PricingContentSubPrice>
          <Button
            variant="contained"
            onClick={onClickJoinBeliever}
            sx={{
              width: '100%',
              height: '40px',
              borderRadius: '9.6px',
              boxShadow: 'none',
              margin: '24px 0px 24px 0px',
              padding: '10px 0px',
              fontSize: '13px',
              fontWeight: 700,
              textTransform: 'none',
            }}
          >
            {language === 'ko' ? '빌리버로 참여하기' : 'Join as a Believer'}
          </Button>
          <FeatureWrapper>
            <FeatureDescription>{language === 'ko' ? 'Pro의 모든 기능과' : 'All Pro Features Plus'}</FeatureDescription>
            {BelieverFeature.map((feature, idx) => (
              <FeatureListWrapper key={idx} style={idx === 0 ? {} : { marginTop: '20px' }}>
                <FeatureListTitle>
                  <div>{feature.title}</div>
                </FeatureListTitle>
                {feature.features.map((feature, idx) => (
                  <FeatureListContent key={idx}>
                    <Icons.Check
                      width={24}
                      height={24}
                      style={{
                        marginRight: 4,
                        minWidth: 24,
                      }}
                    />
                    <span style={{ marginTop: 1 }}>{feature}</span>
                  </FeatureListContent>
                ))}
              </FeatureListWrapper>
            ))}
          </FeatureWrapper>
          <Button href="https://doslash.io/believer" target="_blank" sx={{ width: '100%', borderRadius: '8px', marginTop: '24px', padding: '10px 16px' }}>
            {language === 'ko' ? '더 알아보기' : 'Learn more'}
          </Button>
        </PricingContentCard>
      </PricingContent>
    </Container>
  );
};

export default MembershipPage;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  overflow-y: scroll;
  ${hideScroll}
`;

const PricingHeader = styled.div`
  width: 955px;
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 700;
`;

const PricingContent = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PricingContentCard = styled.div`
  width: 305px;
  height: 100%;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray100};
  border-radius: 20px;
  box-shadow: 0px 4.8px 19.2px 0px rgba(45, 106, 223, 0.08);
  padding: 24px;
  box-sizing: border-box;
  :not(:last-child) {
    margin-right: 20px;
  }
`;

const PricingContentCardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const PricingContentPrice = styled.div`
  text-align: right;
  font-size: 32px;
  font-weight: 700;
  margin-top: 8px;
`;

const PricingContentSubPrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 8px;
`;

const FeatureWrapper = styled.div``;

const FeatureDescription = styled.div`
  color: ${COLORS.gray600};
  font-size: 13px;
  margin-bottom: 12px;
`;

const FeatureListWrapper = styled.div``;

const FeatureListTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const FeatureListContent = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
