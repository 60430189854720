import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

const BlockAddCommentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${COLORS.gray500};
`;

interface BlockAddCommentProps {
  icon?: ReactNode;
  content?: string;
}

const BlockAddComment = ({ icon, content, children }: BlockAddCommentProps & PropsWithChildren) => {
  return (
    <BlockAddCommentWrapper>
      {icon && <span style={{ minWidth: 16, minHeight: 16, marginRight: 8 }}>{icon}</span>}
      {<span>{content || children}</span>}
    </BlockAddCommentWrapper>
  );
};

export default BlockAddComment;
