import { SVGProps } from 'react';

export const DeleteInstance = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 13.25C3.37457 13.25 3.15837 13.1604 2.99896 13.001C2.83955 12.8416 2.75 12.6254 2.75 12.4L2.75 3.6C2.75 3.37457 2.83955 3.15837 2.99896 2.99896C3.15836 2.83955 3.37457 2.75 3.6 2.75L12.4 2.75C12.6254 2.75 12.8416 2.83955 13.001 2.99896C13.1604 3.15836 13.25 3.37457 13.25 3.6V6.06667C13.25 6.48088 13.5858 6.81667 14 6.81667C14.4142 6.81667 14.75 6.48088 14.75 6.06667V3.6C14.75 2.97674 14.5024 2.37901 14.0617 1.9383C13.621 1.49759 13.0233 1.25 12.4 1.25L3.6 1.25C2.97674 1.25 2.37901 1.49759 1.9383 1.9383C1.49759 2.37901 1.25 2.97674 1.25 3.6L1.25 12.4C1.25 13.0233 1.49759 13.621 1.9383 14.0617C2.37901 14.5024 2.97674 14.75 3.6 14.75L6.06667 14.75C6.48088 14.75 6.81667 14.4142 6.81667 14C6.81667 13.5858 6.48088 13.25 6.06667 13.25L3.6 13.25Z"
        fill={props.fill || '#D8204C'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11.0224C7.93656 11.0261 7.87262 11.028 7.80825 11.028C6.02752 11.028 4.58203 9.58519 4.58203 7.80305C4.58203 6.02091 6.02752 4.57812 7.80825 4.57812C9.58899 4.57812 11.0345 6.02091 11.0345 7.80305C11.0345 7.86919 11.0325 7.93486 11.0286 8H9.52334C9.53069 7.93537 9.53447 7.86965 9.53447 7.80305C9.53447 6.8504 8.76162 6.07812 7.80825 6.07812C6.85489 6.07812 6.08203 6.8504 6.08203 7.80305C6.08203 8.7557 6.85489 9.52798 7.80825 9.52798C7.87306 9.52798 7.93704 9.52441 8 9.51746V11.0224ZM7.80865 8.37928C8.12643 8.37928 8.38405 8.12186 8.38405 7.80431C8.38405 7.48676 8.12643 7.22933 7.80865 7.22933C7.49086 7.22933 7.23324 7.48676 7.23324 7.80431C7.23324 8.12186 7.49086 8.37928 7.80865 8.37928Z"
        fill={props.fill || '#D8204C'}
      />
      <path
        d="M15 11.75C15 13.5449 13.5449 15 11.75 15C9.95507 15 8.5 13.5449 8.5 11.75C8.5 9.95507 9.95507 8.5 11.75 8.5C13.5449 8.5 15 9.95507 15 11.75Z"
        fill={props.fill || '#D8204C'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.525 11.75C9.525 11.4186 9.79363 11.15 10.125 11.15L13.375 11.15C13.7064 11.15 13.975 11.4186 13.975 11.75C13.975 12.0814 13.7064 12.35 13.375 12.35L10.125 12.35C9.79363 12.35 9.525 12.0814 9.525 11.75Z"
        fill="white"
      />
    </svg>
  );
};
