import { SVGProps } from 'react';

export const SplitScreen = ({ fill = '#1C1B1F', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.99935 4.99967H9.99935V1.66634H1.99935V4.99967ZM1.99935 6.33301C1.63268 6.33301 1.3189 6.20234 1.05802 5.94101C0.796682 5.68012 0.666016 5.36634 0.666016 4.99967V1.66634C0.666016 1.29967 0.796682 0.985674 1.05802 0.724341C1.3189 0.463452 1.63268 0.333008 1.99935 0.333008H9.99935C10.366 0.333008 10.68 0.463452 10.9413 0.724341C11.2022 0.985674 11.3327 1.29967 11.3327 1.66634V4.99967C11.3327 5.36634 11.2022 5.68012 10.9413 5.94101C10.68 6.20234 10.366 6.33301 9.99935 6.33301H1.99935ZM1.99935 12.333H9.99935V8.99967H1.99935V12.333ZM1.99935 13.6663C1.63268 13.6663 1.3189 13.5359 1.05802 13.275C0.796682 13.0137 0.666016 12.6997 0.666016 12.333V8.99967C0.666016 8.63301 0.796682 8.31901 1.05802 8.05767C1.3189 7.79679 1.63268 7.66634 1.99935 7.66634H9.99935C10.366 7.66634 10.68 7.79679 10.9413 8.05767C11.2022 8.31901 11.3327 8.63301 11.3327 8.99967V12.333C11.3327 12.6997 11.2022 13.0137 10.9413 13.275C10.68 13.5359 10.366 13.6663 9.99935 13.6663H1.99935Z"
        fill={fill}
      />
    </svg>
  );
};
