import { SVGProps } from 'react';

export const Template = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.99906 2.73594L3.3324 3.66927L4.26573 2.0026L3.3324 0.335938L4.99906 1.26927L6.66573 0.335938L5.7324 2.0026L6.66573 3.66927L4.99906 2.73594ZM12.9991 9.26927L14.6657 8.33594L13.7324 10.0026L14.6657 11.6693L12.9991 10.7359L11.3324 11.6693L12.2657 10.0026L11.3324 8.33594L12.9991 9.26927ZM14.6657 0.335938L13.7324 2.0026L14.6657 3.66927L12.9991 2.73594L11.3324 3.66927L12.2657 2.0026L11.3324 0.335938L12.9991 1.26927L14.6657 0.335938ZM8.8924 7.5226L10.5191 5.89594L9.10573 4.4826L7.47906 6.10927L8.8924 7.5226ZM9.57906 3.8626L11.1391 5.4226C11.3991 5.66927 11.3991 6.1026 11.1391 6.3626L3.35906 14.1426C3.09906 14.4026 2.66573 14.4026 2.41906 14.1426L0.859062 12.5826C0.599063 12.3359 0.599063 11.9026 0.859062 11.6426L8.63906 3.8626C8.89906 3.6026 9.3324 3.6026 9.57906 3.8626Z"
        fill={props.fill ? props.fill : '#1F2023'}
      />
    </svg>
  );
};
