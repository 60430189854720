import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import Fonts from 'components/Fonts';

interface Props {
  icon: React.ReactNode;
  pointer?: boolean;
  text: string;
  bgColor?: string;
  color?: string;
}

export const TextWithIcon = ({ icon, text, pointer = false, bgColor, color }: Props) => {
  return (
    <Wrapper pointer={pointer} bgColor={bgColor} color={color}>
      <div>{icon}</div>
      <Fonts.H5>{text}</Fonts.H5>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  pointer: boolean;
  bgColor?: string;
  color?: string;
}>`
  border-radius: 100px;
  gap: 4px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.bgColor ? COLORS.black : COLORS.white)};
  color: ${(props) => (props.color ? props.color : COLORS.gray800)};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;
