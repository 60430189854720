import { SVGProps } from 'react';

export const TaskboxUncheck = ({
  circle,
  marker,
  width = 24,
  height = 24,
  ...props
}: {
  circle?: string | undefined;
  marker?: string | undefined;
  width?: number;
  height?: number;
  props?: SVGProps<SVGSVGElement>;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="11.25" stroke={circle || '#0039A7'} strokeWidth="1.5" />
      <path d="M6 12.75L10 16.5L18 9" stroke={marker || '#E2ECFF'} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
