import { SVGProps } from 'react';

export const FocusUncheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="9.5" stroke="url(#paint0_linear_17871_72499)" />
      <path d="M5 10.625L8.33333 13.75L15 7.5" stroke="#E2ECFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_17871_72499" x1="10.0277" y1="0.352949" x2="10.0222" y2="19.5911" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C471ED" />
          <stop offset="1" stopColor="#F64F59" />
        </linearGradient>
      </defs>
    </svg>
  );
};
