import { SVGProps } from 'react';

export const MemoTab = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5 17V5C20.5 3.879 19.621 3 18.5 3C17.379 3 16.5 3.879 16.5 5V17L18.5 19L20.5 17Z"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.5 7H20.5" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.5 19H5.5C4.96957 19 4.46086 18.7893 4.08579 18.4142C3.71071 18.0391 3.5 17.5304 3.5 17C3.5 16.4696 3.71071 15.9609 4.08579 15.5858C4.46086 15.2107 4.96957 15 5.5 15H9.5C10.0304 15 10.5391 14.7893 10.9142 14.4142C11.2893 14.0391 11.5 13.5304 11.5 13C11.5 12.4696 11.2893 11.9609 10.9142 11.5858C10.5391 11.2107 10.0304 11 9.5 11H6.5"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
