import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'lib/dayjs';
import { OutRecord } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { StaticDatePicker } from 'components/StaticDatePicker';
export interface CalendarReflection {
  date: string;
  done: boolean;
  color: string;
  backgroundColor: string;
}

export interface ReflectionCalendarProps {
  date?: Date;
  complete?: boolean;
  edit?: boolean;
  records?: OutRecord[];
  onChangeDate?: (date: Date) => void;
  onMonthChange?: (date: Date) => void;
}

export const ReflectionCalendar = ({
  date = new Date(),
  complete = false,
  edit = false,
  records = [],
  onChangeDate,
  onMonthChange,
}: ReflectionCalendarProps) => {
  const [calendarReflection, setCalendarReflection] = useState<CalendarReflection[]>([]);
  // const color = ['#D8FCFC', '#B0F0F4', '#73DEE5', '#37C0C9', '#149BA4'];
  const color = ['#B7E9EC', '#6FD4DA', '#10B8C2', '#0C939B', '#085C61'];
  const continuous: CalendarReflection[] = [];

  useEffect(() => {
    init();
  }, [records]);

  const init = () => {
    if (!records) return;
    records!.forEach((v, idx) => {
      continuous.push({
        date: v.date,
        done: v ? true : false,
        color: v.comboHit === 5 ? COLORS.white : COLORS.gray900,
        backgroundColor: records.length > 1 ? (v.comboHit === -1 ? COLORS.gray200 : color[v.comboHit - 1]) : color[0],
      });
    });

    setCalendarReflection(continuous);
  };

  return (
    <Container isToday={dayjs(date).isSame(dayjs(), 'date')}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={date}
        disableFuture
        onChange={(value) => onChangeDate && onChangeDate(dayjs(value).toDate())}
        onMonthChange={(value) => onMonthChange && onMonthChange(dayjs(value).toDate())}
        renderInput={(params) => <TextField {...params} />}
        renderDay={(date, selectedDates, pickersDayProps) => {
          return renderWeekPickerDay(date, selectedDates, pickersDayProps, calendarReflection, complete, edit, onChangeDate);
        }}
      />
    </Container>
  );
};

const renderWeekPickerDay = (
  date: Date,
  selectedDates: Array<Date | null>,
  pickersDayProps: PickersDayProps<Date>,
  reflection: CalendarReflection[],
  writing?: boolean,
  edit?: boolean,
  onChange?: (date: Date) => void,
) => {
  if (!reflection) return <PickersDay {...pickersDayProps} />;
  const isCalendar = reflection.map((v) => v.date).includes(dayjs(date).format('YYYY-MM-DD'));
  const isToday = dayjs(date).isSame(dayjs(), 'date');

  return (
    <PickersDay
      {...pickersDayProps}
      today={false}
      onDaySelect={(value) => {
        onChange && onChange(dayjs(value).toDate());
      }}
      sx={
        isToday
          ? {
              'color': COLORS.white,
              'backgroundColor': COLORS.brand1,
              ':hover': {
                border: `1px solid ${COLORS.gray900}`,
                backgroundColor: COLORS.brand1,
              },
            }
          : isCalendar
          ? {
              'backgroundColor': reflection.find((v) => v.date === dayjs(date).format('YYYY-MM-DD'))?.backgroundColor,
              'color': reflection.find((v) => v.date === dayjs(date).format('YYYY-MM-DD'))?.color,
              ':hover': {
                border: `1px solid ${COLORS.gray900}`,
                backgroundColor: reflection.find((v) => v.date === dayjs(date).format('YYYY-MM-DD'))?.backgroundColor,
              },
            }
          : {
              'pointerEvents': 'cursor',
              ':hover': {
                border: `1px solid ${COLORS.gray900}`,
                backgroundColor: COLORS.white,
              },
            }
      }
    />
  );
};

const Container = styled.div<{ isToday?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  .PrivatePickersSlideTransition-root {
    min-height: 230px;
  }

  .MuiPickerStaticWrapper-content {
    border-radius: 8px;
    border: 1px solid ${COLORS.gray200};
  }

  .MuiCalendarPicker-viewTransitionContainer {
    padding-bottom: 16px;
  }

  .Mui-selected {
    background-color: #e2ecff !important;
    color: ${COLORS.gray900} !important;
    border: 1px solid ${COLORS.gray900};

    ${(props) =>
      props.isToday &&
      `
      background-color: ${COLORS.brand1} !important;
      color: ${COLORS.white} !important;
      border: 1px solid ${COLORS.gray900};
    `}
  }
`;
