import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Divider, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { osName } from 'react-device-detect';
import { InboxContextMenuType } from 'components/InboxContextMenu';
import { useKeyboardEvent } from '@react-hookz/web';
import { useAtom } from 'jotai';
import { languageAtom } from 'atoms/language';

interface Props {
  event: OutTaskboxDetailResponse;
  detail?: HTMLElement | null;
  focusedTaskbox?: OutTaskboxDetailResponse;
  suppressDivider?: boolean;
  onCloseDetail?: () => void;
  onClickMenu?: (menu: InboxContextMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const TimelineTaskboxDetail = ({ event, detail, focusedTaskbox, suppressDivider = false, onCloseDetail = () => {}, onClickMenu = () => {} }: Props) => {
  const [language] = useAtom(languageAtom);
  const [removeTaskbox, setRemoveTaskbox] = useState(false);

  useKeyboardEvent(
    true,
    (ev) => {
      if ((ev.code === 'Delete' && osName === 'Windows') || (ev.code === 'Backspace' && ev.metaKey && osName === 'Mac OS')) {
        if (focusedTaskbox) return;
        handleRemoveTaskbox();
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleRemoveTaskbox = () => {
    if (event?.project) {
      onClickMenu('DELETE');
    } else {
      setRemoveTaskbox(true);
    }
  };

  const handleClickConfirmDelete = () => {
    setRemoveTaskbox(false);
    onClickMenu('DELETE');
  };

  return (
    <>
      <Popover
        open={Boolean(detail)}
        anchorEl={detail}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onCloseDetail}
        sx={{ marginTop: '4px' }}
      >
        <DetailModal>
          <DetailModalOption
            className="detail-text"
            onClick={() => {
              onClickMenu('FOCUS_MODE');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.FocusMode stroke={COLORS.gray900} />
              <span style={{ marginLeft: '8px' }}>
                {
                  language === 'ko' ? '포커스모드 진입하기' : 'Focus mode'
                  // event.focus ? 'Unhighlight' : 'Highlight'
                }
              </span>
            </div>
            <KeyboardButtonRect style={{ padding: '0px 4px' }}>F</KeyboardButtonRect>
          </DetailModalOption>
          <DetailModalOption
            className="detail-text"
            onClick={() => {
              onClickMenu('HIGHLIGHT');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {event?.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
              <span style={{ marginLeft: '8px' }}>
                {
                  language === 'ko' ? (event.focus ? '하이라이트 풀기' : '하이라이트 하기') : event.focus ? 'Unhighlight' : 'Highlight'
                  // event.focus ? 'Unhighlight' : 'Highlight'
                }
              </span>
            </div>
          </DetailModalOption>
          <Tooltip
            title={
              language === 'ko'
                ? event.recurrence || event.routine
                  ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                  : event.lockedIn
                  ? '구글 캘린더에서 해제하기'
                  : '구글 캘린더에 표시하기'
                : event.recurrence || event.routine
                ? `Support for 'Display on Google Calendar' for recurring tasks will be added later.`
                : event.lockedIn
                ? 'Unmark from Google Calendar'
                : 'Display on Google Calendar'
            }
            disableInteractive
          >
            <DetailModalOption
              className="detail-text"
              onClick={() => {
                event.recurrence || event.routine ? null : onClickMenu('CALENDAR_LOCK');
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {event?.lockedIn ? <Icons.LockOut stroke={COLORS.gray900} /> : <Icons.LockIn />}
                <span style={{ marginLeft: '8px' }}>
                  {
                    language === 'ko'
                      ? event?.lockedIn
                        ? '구글 캘린더에서 해제하기'
                        : '구글 캘린더에 표시하기'
                      : event?.lockedIn
                      ? 'Unmark from Google Calendar'
                      : 'Display on Google Calendar'
                    // event.focus ? 'Unhighlight' : 'Highlight'
                  }
                </span>
              </div>
            </DetailModalOption>
          </Tooltip>
          {!suppressDivider && <Divider sx={{ width: '100%', margin: '4px 0px' }} />}
          {/* <DetailModalOption
            className="detail-text"
            onClick={() => {
              onClickMenu('CATEGORY');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.Category />
              <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '카테고리 추가하기' : 'Add category'}</span>
            </div>
          </DetailModalOption> */}
          {/* <DetailModalOption
            className="detail-text"
            onClick={() => {
              onClickMenu('DEADLINE');
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.Flag width={16} height={16} fill={COLORS.gray900} />
              <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '기한 추가하기' : 'Add deadline'}</span>
            </div>
          </DetailModalOption> */}
          {/* {!suppressDivider && <Divider sx={{ width: '100%', margin: '4px 0px' }} />} */}
          {!event?.project && (
            <>
              {event!.tasks!.length > 0 ? (
                <DetailModalOption
                  className="detail-text"
                  onClick={() => {
                    onClickMenu('SAVE_TEMPLATE');
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icons.Template />
                    <span style={{ marginLeft: '8px' }}>
                      {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                      {/* Save as template */}
                    </span>
                  </div>
                </DetailModalOption>
              ) : (
                <Tooltip
                  title={
                    language === 'ko' ? '템플릿으로 저장할 하위 업무가 없어요.' : 'No subtasks to save as a template.'
                    // 'No subtasks to save as a template.'
                  }
                  disableInteractive
                >
                  <DetailModalOption className="detail-text" style={{ cursor: 'default' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icons.Template />
                      <span style={{ marginLeft: '8px' }}>
                        {language === 'ko' ? '템플릿으로 저장하기' : 'Save as template'}
                        {/* Save as template */}
                      </span>
                    </div>
                  </DetailModalOption>
                </Tooltip>
              )}
            </>
          )}
          {!event?.project && (
            <DetailModalOption
              className="detail-text"
              onClick={() => {
                onClickMenu('LOAD_TEMPLATE');
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Template />
                <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '템플릿 불러오기' : 'Load template'}</span>
              </div>
            </DetailModalOption>
          )}
          {!event?.project && !event?.routine && (
            <DetailModalOption
              className="detail-text"
              onClick={() => {
                onClickMenu('DUPLICATE');
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Duplicate />
                <span style={{ marginLeft: '8px' }}>
                  {language === 'ko' ? '태스크박스 복제하기' : 'Duplicate taskbox'}
                  {/* Duplicate taskbox */}
                </span>
              </div>
            </DetailModalOption>
          )}
          {!suppressDivider && <Divider sx={{ width: '100%', margin: '4px 0px' }} />}
          {/* {!event?.project && !event?.isRecurrence && (
            <DetailModalOption
              className="detail-text"
              onClick={() => {
                onClickMenu('CONVERT_TO_PROJECT');
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.ConvertToProject />
                <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '새로운 프로젝트로 전환하기' : 'Convert to new project'}</span>
              </div>
            </DetailModalOption>
          )} */}
          {/* {!event?.project && !event?.isRecurrence && (
            <DetailModalOption
              className="detail-text"
              onClick={(e) => {
                onClickMenu('LINK_TO_PROJECT', e);
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.LinkToProject />
                <span style={{ marginLeft: '8px' }}>{language === 'ko' ? '기존 프로젝트에 연결하기' : 'Link to existing project'}</span>
              </div>
            </DetailModalOption>
          )} */}
          {event.routine && (
            <DetailModalOption
              className="detail-text"
              onClick={() => {
                onClickMenu('STOP_ROUTINE');
              }}
              style={{ color: `${COLORS.negative1}` }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Stop />
                <span style={{ marginLeft: '8px', marginRight: '22px' }}>
                  {language === 'ko' ? '이 루틴 홀드하기' : 'Hold this routine'}
                  {/* {event?.project ? 'Delete instance' : 'Delete'} */}
                </span>
              </div>
            </DetailModalOption>
          )}
          <DetailModalOption className="detail-text" onClick={handleRemoveTaskbox} style={{ color: `${COLORS.negative1}` }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {event?.project ? <Icons.DeleteInstance /> : <Icons.Delete fill={COLORS.negative1} />}
              <span style={{ marginLeft: '8px', marginRight: '22px' }}>
                {language === 'ko' ? (event?.project || event?.routine ? '인스턴스 제거하기' : '삭제') : event?.project ? 'Delete instance' : 'Delete'}
                {/* {event?.project ? 'Delete instance' : 'Delete'} */}
              </span>
            </div>
            {osName === 'Windows' ? (
              <KeyboardButtonRect small>Delete</KeyboardButtonRect>
            ) : (
              <div style={{ color: COLORS.gray500, marginLeft: '8px' }}>
                <KeyboardButtonRect small>⌘</KeyboardButtonRect>
                <KeyboardCommandPlus>+</KeyboardCommandPlus>
                <KeyboardButtonRect small>Backspace</KeyboardButtonRect>
              </div>
            )}
          </DetailModalOption>
        </DetailModal>
      </Popover>
      <ConfirmDeleteDialog open={removeTaskbox} onClose={() => setRemoveTaskbox(false)} onDelete={handleClickConfirmDelete} />
    </>
  );
};

const DetailModal = styled.div`
  min-width: 240px;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 8px;
  z-index: 10;
`;

const DetailModalOption = styled.div`
  font-size: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '2px 3px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.gray500};
  margin: 0px 4px;
`;
