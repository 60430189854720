import { SVGProps } from 'react';

export const CalendarWithoutClock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={32} height={32} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.0001 3.33325H4.00008C3.2637 3.33325 2.66675 3.93021 2.66675 4.66659V12.6666C2.66675 13.403 3.2637 13.9999 4.00008 13.9999H12.0001C12.7365 13.9999 13.3334 13.403 13.3334 12.6666V4.66659C13.3334 3.93021 12.7365 3.33325 12.0001 3.33325Z"
        stroke={props.stroke || '#1F2023'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.6667 2V4.66667" stroke={props.stroke || '#1F2023'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.33325 2V4.66667" stroke={props.stroke || '#1F2023'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.66675 7.33325H13.3334" stroke={props.stroke || '#1F2023'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
