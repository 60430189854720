import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';

interface Props {
  children: React.ReactNode;
  onClick: () => void;
  bgColor?: string;
}
export const ButtonSmall = ({ children, onClick, bgColor = COLORS.gray200 }: Props) => (
  <Wrapper onClick={onClick} bgColor={bgColor}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div<{ bgColor: string }>`
  width: fit-content;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
`;
