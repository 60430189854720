import { SVGProps } from 'react';

export const AddTime = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.0013 4.0026L12.668 2.66927M6.66797 1.33594H9.33463M8.0013 14.0026C5.05578 14.0026 2.66797 11.6148 2.66797 8.66927C2.66797 5.72375 5.05578 3.33594 8.0013 3.33594C10.9468 3.33594 13.3346 5.72375 13.3346 8.66927C13.3346 11.6148 10.9468 14.0026 8.0013 14.0026Z"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.70948 10.6016C8.77131 10.6016 8.21217 9.87576 8.21485 8.60156C8.21217 7.33275 8.77668 6.60156 9.70948 6.60156C10.6423 6.60156 11.2041 7.33275 11.2041 8.60156C11.2041 9.87844 10.645 10.6016 9.70948 10.6016ZM8.91915 8.60156C8.91915 9.53705 9.22023 10.0129 9.70948 10.0155C10.196 10.0129 10.4998 9.53705 10.4998 8.60156C10.4971 7.67414 10.196 7.1849 9.70948 7.18221C9.22292 7.1849 8.92184 7.67414 8.91915 8.60156Z"
        fill="#1F2023"
      />
      <path
        d="M6.44086 10.6016C5.61022 10.6016 5.01613 10.1499 5 9.49404H5.7043C5.72312 9.80586 6.03495 10.0129 6.44086 10.0102C6.86828 10.0129 7.17742 9.77629 7.17742 9.42952C7.17742 9.08006 6.87634 8.83006 6.37097 8.82737H6.02151V8.28973H6.37097C6.78495 8.28973 7.07258 8.05855 7.06989 7.71984C7.07258 7.39189 6.82527 7.17145 6.44624 7.17145C6.08065 7.17145 5.76613 7.37576 5.75269 7.70371H5.08065C5.09677 7.05049 5.69086 6.60156 6.45161 6.60156C7.24194 6.60156 7.75269 7.08543 7.74731 7.68221C7.75269 8.11769 7.46237 8.43221 7.03763 8.52092V8.55318C7.58602 8.62576 7.89785 8.97791 7.89247 9.46715C7.89785 10.1258 7.28226 10.6016 6.44086 10.6016Z"
        fill="#1F2023"
      />
    </svg>
  );
};
