import styled from '@emotion/styled';

export const RoundTag = styled.span`
  padding: 4px 8px;
  height: 23px;
  max-height: 23px;
  border-radius: 100px;
  background-color: var(--sub3);
  color: var(--gray-900);
  font-weight: 400;
  font-size: 10px;
`;

export const IconRadio = styled.span`
  display: inline-flex;
  border: 1px solid var(--brand1);
  border-radius: 8px;
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--gray-200);
`;

export const BlockAddArea = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const EditableTextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  /* word-break: break-all; */
`;

export const EditableTextField = styled.div`
  width: 100%;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
`;

export const DragHandleIcon = styled.span<{ isHover?: boolean }>`
  position: relative;
  transform: rotate(90deg);

  ::before {
    content: '...';
    position: absolute;
    opacity: ${(props) => (props.isHover ? 0.8 : 0)};
    transition: opacity 0.3s ease-in-out;
    left: -6px;
    top: -8px;
    color: gray;
    text-shadow: 0px 4px;
  }
`;
