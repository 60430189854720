import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { updateUserAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { getSignupSurveyV1SurveysSignupGet, getUserMeV1UsersMeGet, recordUserSurveyV1SurveysSurveyIdPost, updateMyProfileV1UsersMePatch } from 'queries';
import { OutUserDetail, WelcomeSurveyOut } from 'queries/model';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 72px 0px;
  background-color: ${COLORS.gray200};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const SurveyWrapper = styled.div`
  .MuiTextField-root {
    height: 59px;
  }

  .MuiSelect-select {
    margin-top: 4px;
  }

  .MuiFormControlLabel-root {
    font-size: 13px;
    margin-right: 0px;
    padding-left: 16px;
  }

  .MuiInputBase-root {
    width: 260px;
    height: 36px;
    font-size: 13px;
    background-color: ${COLORS.white};
  }

  .MuiInputBase-multiline {
    width: 100%;
    height: auto;
  }

  .select {
    height: 44px;
  }

  .multiline {
    height: auto;
  }
`;

const Header = styled.div``;
const HeaderWelcome = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px 12px 0px;
  .MuiInputBase-root {
    width: 160px;
    height: 48px;
    font-size: 13px;
    background-color: ${COLORS.white};
  }
`;

const HeaderWelComeText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const SurveyQuestionWrapper = styled.div`
  width: 630px;
  padding: 24px;
  border: 1px solid ${COLORS.white};
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12), 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  margin-top: 32px;
  > div:not(:first-of-type) {
    margin-top: 24px;
  }
`;

const SurveyQuestion = styled.div``;
const SurveyQuestionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 16px;
`;

const OccupationWrapper = styled.div`
  width: 50%;
`;

const SignUpSurvey = () => {
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<WelcomeSurveyOut>();
  const [, fetchUser] = useAtom(updateUserAtom);
  const [user, setUser] = useState<OutUserDetail>();
  const [userName, setUserName] = useState('');
  const [funnelOptions, setFunnelOptions] = useState<
    {
      name: string;
      status: boolean;
      label: string;
    }[]
  >([]);
  const [toolOptions, setToolOptions] = useState<
    {
      name: string;
      status: boolean;
      label: string;
    }[]
  >([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setFunnelOptions([
      { name: 'acquaintance', status: false, label: t('signin_survey.select.funnel1') },
      { name: 'slash', status: false, label: t('signin_survey.select.funnel2') },
      { name: 'instagram/facebook', status: false, label: t('signin_survey.select.funnel3') },
      { name: 'google', status: false, label: t('signin_survey.select.funnel4') },
      { name: 'community', status: false, label: t('signin_survey.select.funnel5') },
      { name: 'naver', status: false, label: t('signin_survey.select.funnel6') },
      { name: 'etc', status: false, label: t('signin_survey.select.funnel7') },
    ]);
    setToolOptions([
      { name: 'notion', status: false, label: t('signin_survey.select.tool1') },
      { name: 'jira', status: false, label: t('signin_survey.select.tool2') },
      { name: 'slack', status: false, label: t('signin_survey.select.tool3') },
      { name: 'ms', status: false, label: t('signin_survey.select.tool4') },
      { name: 'google', status: false, label: t('signin_survey.select.tool5') },
      { name: 'asana', status: false, label: t('signin_survey.select.tool6') },
      { name: 'naver', status: false, label: t('signin_survey.select.tool7') },
      { name: 'flow', status: false, label: t('signin_survey.select.tool8') },
      { name: 'dooray', status: false, label: t('signin_survey.select.tool9') },
      { name: 'sunsama', status: false, label: t('signin_survey.select.tool10') },
      { name: 'todoist', status: false, label: t('signin_survey.select.tool11') },
      { name: 'akiflow', status: false, label: t('signin_survey.select.tool12') },
      { name: 'trello', status: false, label: t('signin_survey.select.tool13') },
      { name: 'obsidian', status: false, label: t('signin_survey.select.tool14') },
      { name: 'ticktick', status: false, label: t('signin_survey.select.tool15') },
      { name: 'etc', status: false, label: t('signin_survey.select.tool16') },
    ]);
  }, [i18n.language]);

  const [requiredError, setRequiredError] = useState({
    funnel: false,
    tool: false,
    occupation: false,
    position: false,
  });
  const [funnelEtcInput, setFunnelEtcInput] = useState('');
  const [funnelEtcInputError, setFunnelEtcInputError] = useState(false);
  const [toolEtcInput, setToolEtcInput] = useState('');
  const [toolEtcInputError, setToolEtcInputError] = useState(false);
  const [occupationEtcInput, setOccupationEtcInput] = useState('');
  const [occupationEtcInputError, setOccupationEtcInputError] = useState(false);
  const [occupation, setOccupation] = useState('');
  const [position, setPosition] = useState('');
  const [userOpinion, setUserOpinion] = useState('');

  const occupationList = [
    t('signin_survey.select.occupation1'),
    t('signin_survey.select.occupation2'),
    t('signin_survey.select.occupation3'),
    t('signin_survey.select.occupation4'),
    t('signin_survey.select.occupation5'),
    t('signin_survey.select.occupation6'),
    t('signin_survey.select.occupation7'),
    t('signin_survey.select.occupation8'),
    t('signin_survey.select.occupation9'),
    t('signin_survey.select.occupation10'),
    t('signin_survey.select.occupation11'),
    t('signin_survey.select.occupation12'),
    t('signin_survey.select.occupation13'),
  ];

  const positionList = [
    t('signin_survey.select.position1'),
    t('signin_survey.select.position2'),
    t('signin_survey.select.position3'),
    t('signin_survey.select.position4'),
  ];

  useEffect(() => {
    (async () => {
      const survey = await getSignupSurveyV1SurveysSignupGet();
      const user = await getUserMeV1UsersMeGet();
      if (!survey.isTarget) {
        if (!user?.isCalendarPicked) navigate('/calendar');
        else if (!user?.tutorialStatus && !localStorage.getItem('onboarding')) navigate('/welcome');
        else navigate('/task');
      } else {
        setSurvey(survey);
      }
      setUser(user);
      setUserName(user.name!);
    })();
  }, []);

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user) setUserName(event.target.value);
  };

  /** 유입경로  */
  const handleChangeFunnel = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = funnelOptions[idx];
    checkedOption.status = event.target.checked;
    setFunnelOptions([...funnelOptions]);
    if (requiredError.funnel) setRequiredError({ ...requiredError, funnel: false });
    if (event.target.name === 'etc') {
      setFunnelEtcInputError(false);
    }
  };

  const handleChangeFunnelEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunnelEtcInput(event.target.value);
    setFunnelEtcInputError(false);
  };

  const handleFunnelEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (funnelEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 생산성 툴  */
  const handleChangeTool = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = toolOptions[idx];
    checkedOption.status = event.target.checked;
    setToolOptions([...toolOptions]);

    if (requiredError.tool) setRequiredError({ ...requiredError, tool: false });
    if (event.target.name === 'etc') {
      setToolEtcInputError(false);
    }
  };

  const handleChangeToolEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToolEtcInput(event.target.value);
    setToolEtcInputError(false);
  };

  const handleToolEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (toolEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직군 로직 */
  const handleChangeOccupation = (event: SelectChangeEvent) => {
    setTimeout(() => {
      setOccupation(event.target.value);
      if (requiredError.occupation) setRequiredError({ ...requiredError, occupation: false });
      if (occupationEtcInputError) setOccupationEtcInputError(false);
    }, 200);
  };
  /** 직군 기타창 */
  const handleChangeOccupationEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOccupationEtcInput(event.target.value);
    setOccupationEtcInputError(false);
  };

  const handleOccupationEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (occupationEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직책 로직 */
  const handleChangePosition = (event: SelectChangeEvent) => {
    setPosition(event.target.value);
    if (requiredError.position) setRequiredError({ ...requiredError, position: false });
  };

  /** 자유 의견 로직 */
  const handleUserOpinion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserOpinion(event.target.value);
  };

  const handleSurveySubmit = async () => {
    const funnelList: any[] = funnelOptions.filter((v) => v.status).map((v) => v.name);
    const funnelEtcIndex = funnelList.findIndex((v) => v === 'etc');
    if (funnelEtcIndex !== -1) funnelList[funnelEtcIndex] = { etc: funnelEtcInput };
    const toolList: any[] = toolOptions.filter((v) => v.status).map((v) => v.name);
    const toolEtcIndex = toolList.findIndex((v) => v === 'etc');
    if (toolEtcIndex !== -1) toolList[toolEtcIndex] = { etc: toolEtcInput };

    setRequiredError({
      ...requiredError,
      funnel: funnelList.length === 0 ? true : false,
      tool: toolList.length === 0 ? true : false,
      occupation: occupation === '' ? true : false,
      position: position === '' ? true : false,
    });

    if (funnelEtcIndex !== -1 && !funnelEtcInput) setFunnelEtcInputError(true);
    if (toolEtcIndex !== -1 && !toolEtcInput) setToolEtcInputError(true);
    if (occupation === t('signin_survey.select.occupation13') && !occupationEtcInput) setOccupationEtcInputError(true);

    if (
      funnelList.length === 0 ||
      toolList.length === 0 ||
      occupation === '' ||
      position === '' ||
      (funnelEtcIndex !== -1 && !funnelEtcInput) ||
      (toolEtcIndex !== -1 && !toolEtcInput) ||
      (occupation === t('signin_survey.select.occupation13') && !occupationEtcInput)
    )
      return;

    const userSurveyResponse = {
      funnel: funnelList,
      tool: toolList,
      occupation: occupation === t('signin_survey.select.occupation13') ? { etc: occupationEtcInput } : occupation,
      position: position,
      userOpinion: userOpinion,
    };

    const success =
      user!.name !== userName
        ? await Promise.all([
            updateMyProfileV1UsersMePatch({ ...user!, name: userName }),
            recordUserSurveyV1SurveysSurveyIdPost(survey!.survey!.id, userSurveyResponse),
          ])
        : await recordUserSurveyV1SurveysSurveyIdPost(survey!.survey!.id, userSurveyResponse);

    if (success) {
      if (user!.name !== userName) fetchUser();
      // '/calendar'로 변경

      if (!user?.isCalendarPicked) navigate('/calendar');
      else if (!user?.tutorialStatus && !localStorage.getItem('onboarding')) navigate('/welcome');
      else navigate('/task');
    }
  };

  return (
    <Container>
      {survey && (
        <SurveyWrapper>
          <Header>
            <Icons.SlashBeta />
            <HeaderWelcome>
              <TextField autoComplete="off" value={userName} onChange={handleChangeUserName} sx={{ marginRight: '8px' }} />
              <HeaderWelComeText>{t('signin_survey.header.welcome')}</HeaderWelComeText>
            </HeaderWelcome>
            <div>{t('signin_survey.header.title')}</div>
          </Header>
          <SurveyQuestionWrapper>
            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    <Trans
                      i18nKey="signin_survey.question.how_you_found"
                      components={{
                        1: <span style={{ color: COLORS.sub2 }}>어떻게</span>,
                        2: <span style={{ fontWeight: 400 }}>(복수선택)</span>,
                      }}
                    />
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {requiredError.funnel && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>{t('signin_survey.question.feedback')}</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <FormControl component="fieldset" variant="standard" sx={{ marginTop: '8px' }}>
                <FormGroup sx={{ width: '580px', display: 'flex', flexDirection: 'row' }}>
                  {funnelOptions.map((option, idx) => (
                    <div key={option.name} style={{ width: '290px' }}>
                      <FormControlLabel
                        control={<Checkbox checked={option.status} onChange={(e) => handleChangeFunnel(e, idx)} name={option.name} />}
                        label={option.label}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              {funnelOptions.find((v) => v.name === 'etc')?.status && (
                <TextField
                  error={funnelEtcInputError}
                  autoComplete="off"
                  autoFocus
                  placeholder={t('signin_survey.common.placeholder')}
                  helperText={!funnelEtcInput ? t('signin_survey.common.textfield_required') : ''}
                  onChange={handleChangeFunnelEtcInput}
                  onKeyDown={handleFunnelEtcInputKeyDown}
                  sx={{ marginTop: '4px', marginLeft: '14px' }}
                />
              )}
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    <Trans
                      i18nKey="signin_survey.question.collaboration_tool"
                      components={{
                        1: <span style={{ color: COLORS.sub2 }}>협업툴/생산성</span>,
                        2: <span style={{ fontWeight: 400 }}>(복수선택)</span>,
                      }}
                    />
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {requiredError.tool && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>{t('signin_survey.common.required_question')}</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <FormControl component="fieldset" variant="standard" sx={{ marginTop: '8px' }}>
                <FormGroup sx={{ width: '580px', display: 'flex', flexDirection: 'row' }}>
                  {toolOptions.map((option, idx) => (
                    <div key={option.name} style={{ width: '290px' }}>
                      <FormControlLabel
                        control={<Checkbox checked={option.status} onChange={(e) => handleChangeTool(e, idx)} name={option.name} />}
                        label={option.label}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              {toolOptions.find((v) => v.name === 'etc')?.status && (
                <TextField
                  error={toolEtcInputError}
                  autoComplete="off"
                  autoFocus
                  placeholder={t('signin_survey.common.placeholder')}
                  helperText={!toolEtcInput ? t('signin_survey.common.textfield_required') : ''}
                  onChange={handleChangeToolEtcInput}
                  onKeyDown={handleToolEtcInputKeyDown}
                  sx={{ marginTop: '4px', marginLeft: '304px' }}
                />
              )}
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    <Trans
                      i18nKey="signin_survey.question.job_description"
                      components={{
                        1: <span style={{ color: COLORS.sub2 }}>직군과 직책</span>,
                      }}
                    />
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {(requiredError.occupation || requiredError.position) && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>{t('signin_survey.common.required_question')}</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <SelectWrapper>
                <OccupationWrapper>
                  <FormControl error={requiredError.occupation}>
                    {!occupation && <InputLabel sx={{ fontSize: '13px', top: '-3px' }}>{t('signin_survey.placeholder.occupation')}</InputLabel>}
                    <Select
                      className="select"
                      value={occupation}
                      label={occupation ? '' : t('signin_survey.placeholder.occupation')}
                      onChange={handleChangeOccupation}
                    >
                      {occupationList.map((occupation, idx) => (
                        <MenuItem value={occupation} key={idx}>
                          {occupation}
                        </MenuItem>
                      ))}
                    </Select>
                    {requiredError.occupation && <FormHelperText>{t('signin_survey.common.select')}</FormHelperText>}
                  </FormControl>

                  {occupation === t('signin_survey.select.occupation13') && (
                    <TextField
                      error={occupationEtcInputError}
                      autoComplete="off"
                      autoFocus
                      placeholder={t('signin_survey.common.placeholder')}
                      helperText={!occupationEtcInput ? t('signin_survey.common.textfield_required') : ''}
                      onChange={handleChangeOccupationEtcInput}
                      // onBlur={handleBlurOccupationEtcInput}
                      onKeyDown={handleOccupationEtcInputKeyDown}
                      sx={{ marginTop: '4px' }}
                    />
                  )}
                </OccupationWrapper>

                <FormControl error={requiredError.position}>
                  {!position && <InputLabel sx={{ fontSize: '13px', top: '-3px' }}>{t('signin_survey.placeholder.position')}</InputLabel>}
                  <Select className="select" value={position} label={position ? '' : t('signin_survey.placeholder.position')} onChange={handleChangePosition}>
                    {positionList.map((position, idx) => (
                      <MenuItem value={position} key={idx}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                  {requiredError.position && <FormHelperText>{t('signin_survey.common.select')}</FormHelperText>}
                </FormControl>
              </SelectWrapper>
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ fontWeight: 700, marginRight: '4px' }}>
                  <Trans
                    i18nKey="signin_survey.question.management_difficulty"
                    components={{
                      1: <span style={{ color: COLORS.sub2 }}>어려움</span>,
                    }}
                  />
                </div>
              </SurveyQuestionTitle>
              <TextField
                className="multiline"
                multiline
                minRows={2}
                onChange={handleUserOpinion}
                sx={{ width: '100%', fontSize: '13px', marginTop: '12px', padding: '0px 16px' }}
                placeholder={t('signin_survey.placeholder.feedback')}
              />
            </SurveyQuestion>
          </SurveyQuestionWrapper>
          <Button
            variant="contained"
            onClick={handleSurveySubmit}
            sx={{ width: '100%', height: '48px', marginTop: '32px', borderRadius: '8px', textTransform: 'none' }}
          >
            {t('signin_survey.button.submit')}
          </Button>
        </SurveyWrapper>
      )}
    </Container>
  );
};

export default SignUpSurvey;
