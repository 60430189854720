import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAllStateStorageV1StateStorageGet } from 'queries';
import { OutStateStorage } from 'queries/model';

const request = async () => await getAllStateStorageV1StateStorageGet();
const stat = atomWithDefault<OutStateStorage>(request);
export const userStateAtom = atom(
  (get) => get(stat),
  async (_, set) => {
    const res = await getAllStateStorageV1StateStorageGet();
    set(stat, res || []);
  },
);
