import styled from '@emotion/styled';
import { MultiSelectSubtaskIds } from '../Today';
import { Divider, MenuItem, MenuList } from '@mui/material';
import { COLORS } from 'styles/constants';
import InboxContextMenu, { InboxContextMenuType } from 'components/InboxContextMenu';

interface SubtaskMultiSelectPopoverProps {
  menus?: InboxContextMenuType[];
  subtaskIds?: string[];
  onClose?: () => void;
  onClickMultiSelectSubtaskAction?: (type: InboxContextMenuType, data: any) => void;
}

const SubtaskMultiSelectPopover = ({ menus = [], subtaskIds = [], onClose, onClickMultiSelectSubtaskAction }: SubtaskMultiSelectPopoverProps) => {
  //   const handleSelectSubtask = (subtask) => {
  //     onSelectSubtask(subtask);
  //   };

  const multiSelectCount = subtaskIds.length;

  const handleClickMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickMultiSelectSubtaskAction && onClickMultiSelectSubtaskAction(menu, data);
  };

  return (
    <Container className="subtask-multi-select">
      <Header>
        <span style={{ color: COLORS.brand1 }}>{multiSelectCount}</span>
        <span>개의 하위업무가 선택되었습니다.</span>
      </Header>
      <InboxContextMenu
        id={''}
        type={!menus.includes('CONVERT_TO_TASKBOX') ? 'instance-subtask' : 'subtask'}
        menus={menus}
        onClickMenu={handleClickMenu}
        data={subtaskIds}
      />
      {/* <MenuList sx={{ fontSize: '12px', padding: '8px 16px' }}>
        {menus.includes('LATER') && (
          <MenuItem
            sx={{
              'fontSize': '12px',
              'padding': '8px',
              ':hover': {
                backgroundColor: COLORS.gray100,
                borderRadius: '8px',
              },
            }}
          >
            나중에
          </MenuItem>
        )}
        {menus.includes('CONVERT_TO_TASKBOX') && (
          <MenuItem
            sx={{
              'fontSize': '12px',
              'padding': '8px',
              ':hover': {
                backgroundColor: COLORS.gray100,
                borderRadius: '8px',
              },
            }}
          >
            태스크박스로 전환하기
          </MenuItem>
        )}
        {menus.includes('DELETE') && (
          <MenuItem
            sx={{
              'color': COLORS.negative1,
              'fontSize': '12px',
              'padding': '8px',
              ':hover': {
                backgroundColor: COLORS.gray100,
                borderRadius: '8px',
              },
            }}
          >
            삭제
          </MenuItem>
        )}
      </MenuList> */}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  margin-left: 8px;
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding: 16px 16px 8px 16px;
`;

export default SubtaskMultiSelectPopover;
