import { Calendar, Event, momentLocalizer, CalendarProps } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'moment/locale/ko';
import './styles.scss';

const localizer = momentLocalizer(moment);
const DnDCalendar = (props: withDragAndDropProps<Event>) => <Calendar {...props} localizer={localizer} />;

export default withDragAndDrop(DnDCalendar) as React.ComponentType<Omit<CalendarProps<Event, object>, 'localizer'> & withDragAndDropProps<Event>>;
