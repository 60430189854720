import { SVGProps } from 'react';

export const Description = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M3.33301 4.66406H12.6663" stroke={props.stroke || '#ABB0BF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33301 11.3359H5.99967H8.66634" stroke={props.stroke || '#ABB0BF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.33301 8H12.6663" stroke={props.stroke || '#ABB0BF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
