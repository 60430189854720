import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet } from 'queries';
import { OutFeatureStorage } from 'queries/model';

const request = async () => await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('event-creation-info');
export const createEventInfoCloseFeature = atomWithDefault<OutFeatureStorage>(request);
export const createEventInfoCloseAtom = atom(
  (get) => get(createEventInfoCloseFeature),
  async (_, set) => {
    const res = await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('event-creation-info');
    set(createEventInfoCloseFeature, res || null);
  },
);
