import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from '@emotion/styled';
import { RoundTag } from '../styles';
import { Icons } from 'components';
import { motion } from 'framer-motion';

const Container = styled(motion.div)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  padding: 12px;
  color: var(--gray-500);
  background: ${({ disabled }) => (disabled ? 'var(--gray-100)' : 'var(--white)')};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  div:first-of-type {
    flex: 1;
  }
  div:last-child {
    display: flex;
    justify-content: center;
  }
  &:focus-within {
    border: 1px solid var(--brand1);
    color: var(--gray-900);
  }
  input {
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${(props) => (props.disabled ? 'var(--gray-400)' : 'var(--gray-600)')};
    background: transparent;
  }
`;

const RoundDropdownButton = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  color: var(--gray-900);
  background: var(--sub3);
  border-radius: 100px;
  font-size: var(--font-size-6);

  & > span:first-of-type {
    margin-right: 6px;
  }
`;

const PropertySelectOptionWrapper = styled.ul`
  min-width: 250px;
  min-height: 100px;
  overflow-y: auto;
  background: var(--white);
  border: 1px solid var(--gray-200);
  box-shadow: 0px 8px 16px var(--shadow-100);
  border-radius: 8px;
  padding: 24px 16px;
  color: var(--gray-600);

  li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const PropertySelectOptionItem = styled.li`
  display: flex;
  align-items: center;
  font-size: var(--font-size-6);
  cursor: pointer;
`;

export type PropertySelectValue = { key: string; text: string };
export type PropertySelectOption = { key: string; title?: string; value?: string; placeholder?: string };

export interface PropertySelectProps extends PropsWithChildren {
  onInputEnter?: (value: { key: string; text: string }) => void;
  onBlur?: (value: { key: string; text: string }) => void;
  options?: PropertySelectOption[];
  disabled?: boolean;
}

const PropertySelect = ({ onInputEnter, onBlur, options = [], disabled }: PropertySelectProps) => {
  const [current, setCurrent] = useState<PropertySelectOption>(options[0]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputFocus();
  }, []);

  useEffect(() => {
    setCurrent(options[0]);
  }, [options]);

  const setInputFocus = () => textInput.current && (textInput.current as HTMLElement).focus();

  const handleClickPopover = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickContent = (value: PropertySelectOption) => {
    setIsPopoverOpen(false);
    setCurrent(value);
    setInputFocus();
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      onInputEnter && onInputEnter({ key: current.key, text: e.currentTarget.value || '' });
      e.preventDefault();
      e.currentTarget.value = '';
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.currentTarget.contains(e.relatedTarget)) return;

    setTimeout(() => {
      if (isPopoverOpen) return;
      onBlur && onBlur({ key: current.key, text: textInput?.current?.value || '' });
    }, 100);
  };

  const handleClickPopoverOutside = () => {
    setIsPopoverOpen(false);

    if (textInput.current?.textContent === '') {
      onBlur && onBlur({ key: current.key, text: textInput?.current?.value || '' });
    }
  };

  return (
    <Container onBlur={handleBlur} disabled={disabled} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delayChildren: 0.3 } }}>
      <div>
        <input ref={textInput} placeholder={current.placeholder} onKeyDown={handleInputKeyDown} disabled={disabled} />
      </div>
      <div>
        <Popover
          isOpen={isPopoverOpen}
          align="end"
          padding={8}
          positions={['bottom']}
          content={
            <PropertySelectOptionWrapper>
              {options.map((v) => (
                <PropertySelectOptionItem key={v.key} onClick={() => handleClickContent(v)}>
                  <RoundTag>{v.title}</RoundTag>
                  <span style={{ marginLeft: 12 }}>{v.value}</span>
                </PropertySelectOptionItem>
              ))}
            </PropertySelectOptionWrapper>
          }
          onClickOutside={handleClickPopoverOutside}
          clickOutsideCapture={true}
        >
          <RoundDropdownButton onClick={handleClickPopover}>
            <span className="text">{current.title}</span>
            <span>{isPopoverOpen ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />} </span>
          </RoundDropdownButton>
        </Popover>
      </div>
    </Container>
  );
};

export default PropertySelect;
