import styled from '@emotion/styled';
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import {
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  createCategoryV1CategoryPost,
  createProjectV1ProjectPost,
  createWorkboxV2WorkboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  getAllProjectV1ProjectGet,
  getAProjectV1ProjectProjectIdGet,
  orderProjectV1ProjectProjectIdOrderPatch,
  updateCategoryV1CategoryCategoryIdPatch,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
  updateProjectV1ProjectProjectIdPatch,
  updateRoutineOrderV1RoutineRoutineIdOrderPatch,
  updateRoutineStatusV1RoutineRoutineIdStatusPatch,
} from 'queries';
import { OutCategory, OutFeatureStorage, OutProject, OutRoutine, OutTaskboxDetailResponse, RoutineStatusEnum } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom, useSetAtom } from 'jotai';
import { userAtom } from 'atoms/user';
import { hideScroll } from 'styles/utils';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { categoryAtom } from 'atoms/category';
import { quickTaskPopupVisibleAtom } from 'atoms/popup';
import { QuickTaskInputPopup } from 'pages/Task';
import { tasksAtom } from 'atoms/tasks';
import { useClickOutside, useKeyboardEvent } from '@react-hookz/web';
import { dragContextAtom } from 'atoms/works';
import ProductionPlan from 'pages/Beta/ProductionPlan';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { projectFeatureAtom, projectsAtom, selectedProjectAtom } from 'atoms/projects';
import SidePanel from './components/SidePanel';
import { useTranslation } from 'react-i18next';
import RoutineCreationView from './components/RoutineCreationView';
import { routinesAtom, selectedRoutineAtom, selectedRoutineDateAtom } from 'atoms/routine';
import RoutineView from './components/RoutineView';
import ProjectView from './components/ProjectView';
import { languageAtom } from 'atoms/language';

export type ProjectStatus = 'DONE' | 'HOLD' | 'CANCEL' | 'UNLOCK';

export const Desk = () => {
  // 상태 관리 및 다국어 번역
  const { t } = useTranslation();

  // 상태 관리 (useState)
  const [render, setRender] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [inputTitle, setInputTitle] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const [deskProjects, setDeskProjects] = useState<OutProject[]>([]);
  const [cabinetProjects, setCabinetProjects] = useState<OutProject[]>([]);
  const [archiveProjects, setArchiveProjects] = useState<OutProject[]>([]);
  const [showTooltip, setShowTooltip] = useState(true);
  const [intro, setIntro] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [quickCategory, setQuickCategory] = useState<OutCategory | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [quickDeadline, setQuickDeadline] = useState<string | null>(null);
  const [openRoutineCreation, setOpenRoutineCreation] = useState(false);
  const [deskRoutines, setDeskRoutines] = useState<OutRoutine[]>([]);
  const [cabinetRoutines, setCabinetRoutines] = useState<OutRoutine[]>([]);
  const [archiveRoutines, setArchiveRoutines] = useState<OutRoutine[]>([]);
  const [foldProjectDrawer, setFoldProjectDrawer] = useState(false);
  const [foldRoutineDrawer, setFoldRoutineDrawer] = useState(false);

  // 상태 관리 (useAtom)
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [user] = useAtom(userAtom);
  const [isOpen, setIsOpen] = useAtom(quickTaskPopupVisibleAtom);
  const updateTasks = useSetAtom(tasksAtom);
  const [dragContext, setDragContext] = useAtom(dragContextAtom);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [projects] = useAtom(projectsAtom);
  const [projectFeature, fetchProjectFeature] = useAtom(projectFeatureAtom);
  const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);
  const [, setSelectedRoutineDate] = useAtom(selectedRoutineDateAtom);
  const [routines, fetchRoutines] = useAtom(routinesAtom);
  const [language] = useAtom(languageAtom);

  // ref 설정
  const ref = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);

  // 로컬 저장 데이터
  const projectData = localStorage.getItem('project-data');

  useEffect(() => {
    localStorage.getItem('desk-expanded-status') && setExpanded(JSON.parse(localStorage.getItem('desk-expanded-status') as string));
    routines.length > 0 && projects.length === 0 && setTabValue(1);
  }, []);

  useEffect(() => {
    fetchProject();
    if (projectFeature && !projectFeature.data.create && projects.length) {
      handleUpdateProjectFeature(projectFeature);
    }
  }, [projects]);

  useEffect(() => {
    if (routines.length) setIntro(false);
    if (selectedRoutine) {
      const targetRoutine = routines.find((item) => item.id === selectedRoutine?.id);
      if (targetRoutine) {
        setSelectedRoutine(targetRoutine);
      }
    }
    setDeskRoutines(routines.filter((r) => r.status === 'ACTIVE'));
    setCabinetRoutines(routines.filter((r) => r.status === 'HOLD'));
    setArchiveRoutines(routines.filter((r) => r.status === 'ARCHIVED'));
  }, [routines]);

  useClickOutside(ref, (e) => {
    const el = e as MouseEvent;
    if (el.clientX < 80) {
      setSelectedProject(null);
      setSelectedRoutine(null);
    }
  });

  useKeyboardEvent(
    true,
    (ev) => {
      const isQuickTaskCmd = (ev.metaKey || ev.ctrlKey) && ev.key === '/';
      const isTaskboardInputCmd = !ev.metaKey && !ev.ctrlKey && ev.key === '/';
      if (!isQuickTaskCmd && !isTaskboardInputCmd) return;

      if (isQuickTaskCmd) {
        setShowTooltip(false);
        setIsOpen(true);
      }

      if (isTaskboardInputCmd) {
        const element = document.activeElement;

        if (element) {
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
        }

        const taskView = document.getElementById('task-view');
        const taskBoard = document.querySelector(`[data-board-id="${dayjs().format('YYYY-MM-DD')}"]`) as HTMLDivElement;
        taskView?.scrollTo({ top: taskBoard?.offsetTop - 12, behavior: 'auto' });

        setTimeout(() => {
          document.getElementById('taskboard-input')?.focus();
        }, 100);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const fetchProject = async (suppress?: boolean) => {
    const data = await getAllProjectV1ProjectGet();
    if (data.length) {
      setIntro(false);
      localStorage.setItem('project-data', JSON.stringify(true));
    } else {
      localStorage.setItem('project-data', JSON.stringify(false));
    }
    setDeskProjects(data.filter((item) => item.place === 'DESK'));
    setCabinetProjects(data.filter((item) => item.place === 'DRAWER_MOVED' || item.place === 'DRAWER_CREATED'));
    setArchiveProjects(data.filter((item) => item.place === 'ARCHIVE'));
    setRender(true);

    if (selectedProject) {
      if (suppress) {
        setSelectedProject(null);
        return;
      }
      const project = data.find((item) => item.id === selectedProject.id);
      if (project) setSelectedProject(project);
    }
  };

  const handleUpdateProjectFeature = async (feature: OutFeatureStorage) => {
    const data = {
      data: {
        create: true,
      },
    };
    try {
      await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(feature.id, data);
      fetchProjectFeature();
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateTask = async (taskbox: OutTaskboxDetailResponse) => {
    if (taskbox) {
      await createWorkboxV2WorkboxesPost({ id: uuidv4(), title: taskbox.title! });
      toast.success(language === 'ko' ? '새로운 태스크를 생성하였습니다.' : 'New task created.');
      updateTasks();
    }
  };

  const handlePopupClose = () => {
    setIsOpen(false);
    setShowTooltip(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      if (inputTitle) {
        refInput.current?.blur();
        return;
      }
      e.preventDefault();
      refInput.current?.blur();
      setIsFocus(false);
      if (quickCategory) setQuickCategory(null);
      if (quickDeadline) setQuickDeadline(null);
    }

    if (e.key === 'Enter') {
      if (!inputTitle) return;
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      try {
        await createProjectV1ProjectPost({
          id: uuidv4(),
          title: inputTitle,
          place: 'DESK',
          categoryIds: quickCategory ? [quickCategory.id] : undefined,
          due: quickDeadline ? quickDeadline : null,
        });

        if (deskProjects.length >= 7) {
          toast.success(
            language === 'ko'
              ? '데스크 개수 제한이 초과되어 서랍에 새로운 프로젝트가 추가되었습니다.'
              : 'Due to the desk limit, a new project was added to the drawer.',
          );
        } else {
          toast.success(language === 'ko' ? '새로운 프로젝트를 생성하였습니다.' : 'New project created.');
        }
        fetchProject();
        setInputTitle('');
        if (quickCategory) setQuickCategory(null);
        if (quickDeadline) setQuickDeadline(null);
        if (projectFeature && !projectFeature.data.create) handleUpdateProjectFeature(projectFeature);
      } catch (error) {
        toast.error(language === 'ko' ? '프로젝트 생성에 실패하였습니다.' : 'Failed to create project.');
      }
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleKeydownProject = async (e: React.KeyboardEvent<HTMLDivElement>, projectId: string) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const title = e.currentTarget.textContent || '';
      const success = await updateProjectV1ProjectProjectIdPatch(projectId, { title });
      if (success) toast.success(language === 'ko' ? '프로젝트 제목을 수정하였습니다.' : 'Modified project title.');
    }
  };

  const handleClickProjectCheck = async (projectId: string, status: ProjectStatus) => {
    switch (status) {
      case 'DONE':
        {
          try {
            await updateProjectV1ProjectProjectIdPatch(projectId, { done: true, place: 'ARCHIVE' });
            toast.success(language === 'ko' ? '완료한 프로젝트를 아카이브로 옮겼습니다.' : 'Archived the completed project.');
          } catch (error) {
            toast.error(language === 'ko' ? '프로젝트 완료에 실패하였습니다.' : 'Failed to complete the project.');
          }
        }
        break;
      case 'HOLD': {
        try {
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: 'DRAWER_MOVED' });
          toast.success(language === 'ko' ? '홀드된 프로젝트는 서랍으로 이동되었습니다.' : 'Project on hold has been moved to the drawer.');
        } catch (error) {
          toast.error(language === 'ko' ? '프로젝트 홀드에 실패하였습니다.' : 'Failed to hold the project.');
        }
        break;
      }
      case 'CANCEL': {
        try {
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: 'ARCHIVE' });
          toast.success(language === 'ko' ? '취소된 프로젝트는 아카이브로 이동되었습니다.' : 'Canceled project moved to archive.');
        } catch (error) {
          toast.error(language === 'ko' ? '프로젝트 취소에 실패하였습니다.' : 'Failed to cancel project.');
        }
        break;
      }
      case 'UNLOCK': {
        try {
          const project = archiveProjects.find((item) => item.id === projectId);
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: deskProjects.length >= 7 ? 'DRAWER_MOVED' : 'DESK', done: false });
          if (deskProjects.length >= 7) {
            toast.success(
              language === 'ko' ? '데스크의 개수 제한이 초과되어 프로젝트가 서랍으로 이동되었습니다.' : 'Project moved to the drawer due to desk limit.',
            );
          } else {
            toast.success(language === 'ko' ? '프로젝트가 데스크로 이동되었습니다.' : 'Project moved to desk.');
          }
        } catch (error) {
          toast.error(language === 'ko' ? '프로젝트 해제에 실패하였습니다.' : 'Failed to unassign project.');
        }
        break;
      }
    }
    fetchProject();
  };

  const handleSelectProject = async (project: OutProject) => {
    const targetProject = await getAProjectV1ProjectProjectIdGet(project.id);
    if (targetProject) {
      setSelectedProject(targetProject);
    }
  };

  const handleDropInput = async () => {
    if (!dragContext) return;

    try {
      await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(dragContext.id!);
      if (deskProjects.length >= 7) {
        toast.success(
          language === 'ko'
            ? '데스크 개수 제한이 초과되어 서랍에 새로운 프로젝트가 추가되었습니다.'
            : 'Due to the desk limit, a new project was added to the drawer.',
        );
      } else {
        toast.success(language === 'ko' ? '새로운 프로젝트를 생성하였습니다.' : 'New project created.');
      }
    } catch {
      toast.error(language === 'ko' ? '프로젝트 생성에 실패하였습니다.' : 'Failed to create project.');
    }

    setIsDragOver(false);
    setDragContext(null);
    fetchProject();
    updateTasks();
  };

  const handleDropToProject = async (projectId: string) => {
    if (!dragContext) return;
    await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(projectId, { taskIds: [dragContext.id!] });
    setIsDragOver(false);
    setDragContext(null);
    fetchProject();
    updateTasks();
  };

  const handleStartProject = () => {
    setIntro(false);
  };

  const handleClickFoldProjectDrawer = () => {
    setFoldProjectDrawer(!foldProjectDrawer);
  };

  const handleClickFoldRoutineDrawer = () => {
    setFoldRoutineDrawer(!foldRoutineDrawer);
  };

  const handleProjectBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    if (result.source.droppableId === 'desk-project') {
      if (result.destination.droppableId === 'desk-project') {
        const newProject = deskProjects.filter((item) => item.id !== result.draggableId);
        newProject.splice(result.destination.index, 0, deskProjects[result.source.index]);
        setDeskProjects([...newProject]);
        if (result.source.index < result.destination.index) {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: deskProjects[result.destination.index].id,
              place: 'DESK',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
          }
        } else {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskProjects[result.destination.index - 1].id,
              place: 'DESK',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'cabinet-project') {
        const newDeskProject = deskProjects.filter((item) => item.id !== result.draggableId);
        const moveToCabinetProject = deskProjects[result.source.index];
        moveToCabinetProject.place = 'DRAWER_MOVED';
        cabinetProjects.splice(result.destination.index, 0, moveToCabinetProject);
        setDeskProjects([...newDeskProject]);
        setCabinetProjects([...cabinetProjects]);

        try {
          const success = await updateProjectV1ProjectProjectIdPatch(result.draggableId, { place: 'DRAWER_MOVED' });
          if (success) {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
              place: 'DRAWER',
            });
          }
        } catch (error) {
          toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'cabinet-project') {
      if (result.destination.droppableId === 'cabinet-project') {
        const newProject = cabinetProjects.filter((item) => item.id !== result.draggableId);
        newProject.splice(result.destination.index, 0, cabinetProjects[result.source.index]);
        setCabinetProjects([...newProject]);
        if (result.source.index < result.destination.index) {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: cabinetProjects[result.destination.index].id,
              place: 'DRAWER',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
          }
        } else {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
              place: 'DRAWER',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'desk-project') {
        if (deskProjects.length >= 7) {
          toast.error(language === 'ko' ? '데스크가 가득 찼습니다.' : 'Desk is full.');
          return;
        }

        const newCabinetProject = cabinetProjects.filter((item) => item.id !== result.draggableId);
        const moveToDeskProject = cabinetProjects[result.source.index];
        moveToDeskProject.place = 'DESK';
        deskProjects.splice(result.destination.index, 0, moveToDeskProject);
        setCabinetProjects([...newCabinetProject]);
        setDeskProjects([...deskProjects]);

        try {
          const success = await updateProjectV1ProjectProjectIdPatch(result.draggableId, { place: 'DESK' });
          if (success) {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskProjects[result.destination.index - 1].id,
              place: 'DESK',
            });
          }
        } catch (error) {
          toast.error(language === 'ko' ? '프로젝트 순서 변경에 실패하였습니다.' : 'Failed to reorder project.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'archive-project') {
      const newProject = archiveProjects.filter((item) => item.id !== result.draggableId);
      newProject.splice(result.destination.index, 0, archiveProjects[result.source.index]);
      setArchiveProjects([...newProject]);

      // if (result.source.index < result.destination.index) {
      //   try {
      //     await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
      //       beforeId: cabinetProjects[result.destination.index].id,
      //       place: 'ARCHIVE',
      //     });
      //   } catch (error) {
      //     toast.error('프로젝트 순서 변경에 실패하였습니다.');
      //   }
      // } else {
      //   try {
      //     await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
      //       beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
      //       place: 'ARCHIVE',
      //     });
      //   } catch (error) {
      //     toast.error('프로젝트 순서 변경에 실패하였습니다.');
      //   }
      // }
      return;
    }
  };

  const handleRoutineBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    if (result.source.droppableId === 'desk-routine') {
      if (result.destination.droppableId === 'desk-routine') {
        const newRoutines = deskRoutines.filter((item) => item.id !== result.draggableId);
        newRoutines.splice(result.destination.index, 0, deskRoutines[result.source.index]);
        setDeskRoutines([...newRoutines]);
        if (result.source.index < result.destination.index) {
          try {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: deskRoutines[result.destination.index].id,
              status: 'ACTIVE',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
          }
        } else {
          try {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskRoutines[result.destination.index - 1].id,
              status: 'ACTIVE',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'cabinet-routine') {
        const newDeskRoutines = deskRoutines.filter((item) => item.id !== result.draggableId);
        const moveToCabinetRoutine = deskRoutines[result.source.index];
        moveToCabinetRoutine.status = 'HOLD';
        cabinetRoutines.splice(result.destination.index, 0, moveToCabinetRoutine);
        setDeskRoutines([...newDeskRoutines]);
        setCabinetRoutines([...cabinetRoutines]);

        try {
          const success = await updateRoutineStatusV1RoutineRoutineIdStatusPatch(result.draggableId, {
            status: 'HOLD',
            startDate: dayjs().format('YYYY-MM-DD'),
          });
          if (success) {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetRoutines[result.destination.index - 1].id,
              status: 'HOLD',
            });
          }
          toast.success(language === 'ko' ? '루틴을 홀드하였습니다.' : 'Routine on hold.');
        } catch (error) {
          toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'cabinet-routine') {
      if (result.destination.droppableId === 'cabinet-routine') {
        const newRoutines = cabinetRoutines.filter((item) => item.id !== result.draggableId);
        newRoutines.splice(result.destination.index, 0, cabinetRoutines[result.source.index]);
        setCabinetRoutines([...newRoutines]);
        if (result.source.index < result.destination.index) {
          try {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: cabinetRoutines[result.destination.index].id,
              status: 'HOLD',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
          }
        } else {
          try {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetRoutines[result.destination.index - 1].id,
              status: 'HOLD',
            });
          } catch (error) {
            toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'desk-routine') {
        const newCabinetRoutines = cabinetRoutines.filter((item) => item.id !== result.draggableId);
        const moveToDeskRoutine = cabinetRoutines[result.source.index];
        moveToDeskRoutine.status = 'ACTIVE';
        deskRoutines.splice(result.destination.index, 0, moveToDeskRoutine);
        setCabinetRoutines([...newCabinetRoutines]);
        setDeskRoutines([...deskRoutines]);

        try {
          const success = await updateRoutineStatusV1RoutineRoutineIdStatusPatch(result.draggableId, {
            status: 'ACTIVE',
            startDate: dayjs().format('YYYY-MM-DD'),
          });
          if (success) {
            await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskRoutines[result.destination.index - 1].id,
              status: 'ACTIVE',
            });
          }
          toast.success(language === 'ko' ? '루틴을 데스크로 이동하였습니다.' : 'Moved the routine to the desk.');
        } catch (error) {
          toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'archive-routine') {
      const newRoutines = archiveRoutines.filter((item) => item.id !== result.draggableId);
      newRoutines.splice(result.destination.index, 0, archiveRoutines[result.source.index]);
      setArchiveRoutines([...newRoutines]);

      if (result.source.index < result.destination.index) {
        try {
          await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
            beforeId: cabinetProjects[result.destination.index].id,
            status: 'ARCHIVED',
          });
        } catch (error) {
          toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
        }
      } else {
        try {
          await updateRoutineOrderV1RoutineRoutineIdOrderPatch(result.draggableId, {
            beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
            status: 'ARCHIVED',
          });
        } catch (error) {
          toast.error(language === 'ko' ? '루틴 순서 변경에 실패하였습니다.' : 'Failed to reorder routine.');
        }
      }
      return;
    }
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          setQuickCategory(category);

          if (inputTitle.includes('#')) {
            setInputTitle(inputTitle.replace(/#/g, ''));
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 생성할 수 없습니다.' : 'Failed to create category.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            fetchProject();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 삭제할 수 없습니다.' : 'Failed to delete category.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            fetchProject();
          } catch (e) {
            toast.error(language === 'ko' ? '카테고리를 수정할 수 없습니다.' : 'Failed to update category.');
          }
        }
        break;
    }
  };

  const handleChangeDeadline = (date: Dayjs) => {
    setTimeout(() => {
      setDeadlineAnchorEl(null);
    }, 100);
    setQuickDeadline(date.toISOString());
    if (inputTitle.includes('$')) {
      setInputTitle(inputTitle.replace(/\$/g, ''));
    }
  };

  const handleCloseRoutineCreation = () => {
    fetchRoutines();
    setOpenRoutineCreation(false);
  };

  const handleSelectRoutine = async (routine: OutRoutine) => {
    const targetRoutine = routines.find((item) => item.id === routine.id);
    if (targetRoutine) {
      setSelectedRoutine(targetRoutine);
      setSelectedRoutineDate(dayjs().toDate());
    }
  };

  //TODO: 시작 시간 수정
  const handleClickRoutineCheck = async (routineId: string, status: RoutineStatusEnum) => {
    switch (status) {
      case 'ACTIVE':
        {
          try {
            await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
              status: 'ACTIVE',
              startDate: dayjs().format('YYYY-MM-DD'),
            });
            language === 'ko' ? toast.success('루틴을 데스크로 옮겼습니다.') : toast.success('Moved the routine to the desk.');
          } catch (error) {
            language === 'ko' ? toast.error('루틴을 데스크로 옮기는데 실패하였습니다.') : toast.error('Failed to move the routine to the desk.');
          }
        }
        break;
      case 'HOLD':
        {
          try {
            await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
              status: 'HOLD',
              startDate: dayjs().format('YYYY-MM-DD'),
            });
            language === 'ko' ? toast.success('홀드된 루틴은 서랍으로 이동되었습니다.') : toast.success('Routine on hold has been moved to the drawer.');
          } catch (error) {
            language === 'ko' ? toast.error('루틴 홀드에 실패하였습니다.') : toast.error('Failed to hold routine.');
          }
        }
        break;
      case 'ARCHIVED': {
        try {
          await updateRoutineStatusV1RoutineRoutineIdStatusPatch(routineId, {
            status: 'ARCHIVED',
            startDate: dayjs().format('YYYY-MM-DD'),
          });
          language === 'ko' ? toast.success('루틴을 아카이브로 옮겼습니다.') : toast.success('Archived the routine.');
        } catch (error) {
          language === 'ko' ? toast.error('루틴 아카이브에 실패하였습니다.') : toast.error('Failed to archive routine.');
        }
        break;
      }
    }
    fetchRoutines();
  };

  const [archiveFilter, setArchiveFilter] = useState(language === 'ko' ? '프로젝트' : 'Project');
  const handleChangeArchiveFilter = (event: SelectChangeEvent) => {
    setArchiveFilter(event.target.value);
  };

  return (
    <>
      {render ? (
        <>
          {intro ? (
            <ProductionPlan suppressBeta onStart={handleStartProject} />
          ) : (
            <Container
              ref={ref}
              onClick={() => {
                setSelectedProject(null);
                setSelectedRoutine(null);
              }}
            >
              <PlanViewWrapper>
                <PlanHeader>
                  {language === 'ko' ? `${user?.name}님의 중요한 업무는 무엇인가요?` : `What is your most important task, ${user?.name}?`}
                </PlanHeader>
                <TabPanelHeader>
                  <Tabs value={tabValue} onChange={handleChangeTab}>
                    <Tooltip
                      placement="top-start"
                      title={
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                            <Icons.Wand />
                            <span style={{ marginLeft: 4 }}>Magic number 7</span>
                          </div>
                          <div style={{ margin: '8px 0px 8px 0px' }}>{t('plan.tooltip.content1')}</div>
                          <div>{t('plan.tooltip.content2')}</div>
                        </div>
                      }
                      disableInteractive
                    >
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{language === 'ko' ? '프로젝트' : 'Project'}</span>
                            <span style={{ color: deskProjects.length === 7 ? COLORS.negative1 : COLORS.gray400, margin: '0px 4px 0px 4px' }}>
                              ({deskProjects.length}/7)
                            </span>
                            <Icons.QuestionInfo fill={COLORS.gray400} />
                          </div>
                        }
                        sx={{ textTransform: 'none' }}
                      />
                    </Tooltip>
                    <Tab label={language === 'ko' ? '루틴' : 'Routine'} sx={{ textTransform: 'none' }} />
                    <Tab label={language === 'ko' ? '아카이브' : 'Archive'} sx={{ textTransform: 'none' }} />
                  </Tabs>
                </TabPanelHeader>

                <TabPanel hidden={tabValue !== 0}>
                  <ProjectViewWrapper>
                    {dragContext ? (
                      <CreateProjectDropWrapper
                        dragOver={isDragOver}
                        onDrop={handleDropInput}
                        onDragOver={(e) => {
                          e.preventDefault();
                          setIsDragOver(true);
                        }}
                        onDragLeave={() => setIsDragOver(false)}
                      >
                        <Icons.DropZone stroke={isDragOver ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '4px' }}>
                          {language === 'ko' ? '여기로 드래그해서 프로젝트로 만들어보세요' : 'Drag here to create a project'}
                        </span>
                      </CreateProjectDropWrapper>
                    ) : (
                      <CreateProjectWrapper>
                        <IconButton
                          onClick={() => refInput.current?.focus()}
                          sx={{
                            'padding': '0px',
                            ':hover': {
                              backgroundColor: COLORS.white,
                            },
                          }}
                        >
                          <Icons.Plus height={16} width={16} fill={COLORS.gray400} />
                        </IconButton>
                        <TextField
                          inputRef={refInput}
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          value={inputTitle}
                          placeholder={
                            isFocus
                              ? language === 'ko'
                                ? '프로젝트를 입력해주세요'
                                : 'Enter project'
                              : language === 'ko'
                              ? '새로운 프로젝트 입력하기'
                              : 'Enter new project'
                          }
                          onKeyDown={handleKeydownInput}
                          onChange={handleInputTitle}
                          onFocus={() => setIsFocus(true)}
                          onBlur={() => setIsFocus(false)}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              fontSize: 16,
                              fontWeight: 700,
                              color: COLORS.gray900,
                              marginLeft: 8,
                              height: 24,
                            },
                          }}
                          sx={{ flex: 1 }}
                        />
                        {quickCategory && (
                          <CategoryShowingWrapper textColor={getCategoryTextColor(quickCategory.color)} bgColor={getCategoryBgColor(quickCategory.color)}>
                            {`# ${quickCategory.name}`}
                          </CategoryShowingWrapper>
                        )}
                        {quickDeadline && (
                          <DeadlineShowingWrapper date={quickDeadline}>
                            <Icons.Flag
                              fill={dayjs(quickDeadline).isToday() ? COLORS.brand1 : dayjs(quickDeadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                            />
                            <span style={{ marginLeft: '2px' }}>
                              {dayjs(quickDeadline).isToday()
                                ? language === 'ko'
                                  ? '오늘'
                                  : 'Today'
                                : dayjs(quickDeadline).isYesterday()
                                ? language === 'ko'
                                  ? '어제'
                                  : 'Yesterday'
                                : dayjs(quickDeadline).isTomorrow()
                                ? language === 'ko'
                                  ? '내일'
                                  : 'Tomorrow'
                                : language === 'ko'
                                ? dayjs(quickDeadline).format('M월 D일 (dd)')
                                : dayjs(quickDeadline).format('dddd, MMM D')}
                            </span>
                            {/* <span style={{ marginLeft: '2px' }}>
                              {dayjs(quickDeadline).isToday()
                                ? 'Today'
                                : dayjs(quickDeadline).isYesterday()
                                ? 'Yesterday'
                                : dayjs(quickDeadline).isTomorrow()
                                ? 'Tomorrow'
                                : dayjs(quickDeadline).format('dddd, MMM D')}
                            </span> */}
                          </DeadlineShowingWrapper>
                        )}
                        {isFocus && !quickCategory && !quickDeadline && (
                          <CategoryDeadlineInfoPlaceholder>
                            {language === 'ko' ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '2px',
                                    backgroundColor: COLORS.gray100,
                                    marginRight: '4px',
                                  }}
                                >
                                  #
                                </div>
                                <span style={{ marginRight: '4px' }}>으로 카테고리 지정</span>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '2px',
                                    backgroundColor: COLORS.gray100,
                                    marginRight: '4px',
                                  }}
                                >
                                  $
                                </div>
                                <span>으로 기한 지정</span>
                              </div>
                            ) : (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px' }}>Set category with</span>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '2px',
                                    backgroundColor: COLORS.gray100,
                                    marginRight: '4px',
                                  }}
                                >
                                  #
                                </div>
                                <span>Set deadline with</span>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '16px',
                                    height: '16px',
                                    borderRadius: '2px',
                                    backgroundColor: COLORS.gray100,
                                    marginRight: '4px',
                                  }}
                                >
                                  $
                                </div>
                              </div>
                            )}
                            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginRight: '4px' }}>Set category with</span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '16px',
                                  height: '16px',
                                  borderRadius: '2px',
                                  backgroundColor: COLORS.gray100,
                                  marginRight: '4px',
                                }}
                              >
                                #
                              </div>
                              <span>Set deadline with</span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '16px',
                                  height: '16px',
                                  borderRadius: '2px',
                                  backgroundColor: COLORS.gray100,
                                }}
                              >
                                $
                              </div>
                            </div> */}
                          </CategoryDeadlineInfoPlaceholder>
                        )}
                      </CreateProjectWrapper>
                    )}
                    <DragDropContext onDragEnd={handleProjectBeautifulDragEnd}>
                      <ProjectViewScroll>
                        <Droppable droppableId="desk-project">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {deskProjects.length > 0 ? (
                                deskProjects.map((project, index) => (
                                  <Draggable key={project.id} draggableId={project.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <ProjectView
                                            key={project.id}
                                            project={project}
                                            onKeydownProject={handleKeydownProject}
                                            onClickProjectCheck={handleClickProjectCheck}
                                            onSelectProject={handleSelectProject}
                                            onFetch={fetchProject}
                                            onDrop={handleDropToProject}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))
                              ) : (
                                <EmptyDestWrapper>
                                  <Icons.Desk />
                                  <EmptyDeskTitle>
                                    <Fonts.H5 style={{ color: COLORS.gray800, marginBottom: '4px' }}>
                                      {language === 'ko' ? '현재 집중할 프로젝트는 무엇인가요?' : 'What is your current focus project?'}
                                    </Fonts.H5>
                                    <Fonts.Blockquote style={{ color: COLORS.gray500 }}>
                                      {language === 'ko'
                                        ? '데스크에서 한 주 계획이나 중요한 일을 관리해보세요'
                                        : 'Manage your weekly plan or important tasks on the desk'}
                                    </Fonts.Blockquote>
                                  </EmptyDeskTitle>
                                </EmptyDestWrapper>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="cabinet-project">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {cabinetProjects.length > 0 && tabValue !== 1 && (
                                <CabinetContainer>
                                  <CabinetHeader>
                                    <CabinetLineWrapper />
                                    <CabinetFoldButton>
                                      <Tooltip
                                        title={
                                          <div>
                                            <div style={{ fontWeight: 700 }}>
                                              {language === 'ko' ? '중요한 일이 너무 많다면?' : 'Too many important tasks?'}
                                            </div>
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                              {language === 'ko'
                                                ? `잠시 대기 중인 프로젝트, 상대적으로 주의력을\n적게 요구하는 프로젝트는 서랍에서 관리해보세요.`
                                                : `Projects on hold or needing less attention\ncan be managed in the drawer.`}
                                            </div>
                                          </div>
                                        }
                                        disableInteractive
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'default' }}>
                                          <Icons.Cabinet />
                                          <div style={{ fontWeight: 700, fontSize: '13px', margin: '0px 4px' }}>{language === 'ko' ? '서랍' : 'Drawer'}</div>
                                          <div style={{ fontWeight: 700, fontSize: '13px', color: COLORS.gray400 }}>{`(${cabinetProjects.length})`}</div>
                                        </div>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          foldProjectDrawer
                                            ? language === 'ko'
                                              ? '서랍 열기'
                                              : 'Open drawer'
                                            : language === 'ko'
                                            ? '서랍 접기'
                                            : 'Close drawer'
                                        }
                                      >
                                        <IconButton
                                          onClick={handleClickFoldProjectDrawer}
                                          sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '0px' }}
                                        >
                                          {foldProjectDrawer ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                                        </IconButton>
                                      </Tooltip>
                                    </CabinetFoldButton>
                                    <CabinetLineWrapper />
                                  </CabinetHeader>
                                  {cabinetProjects.length > 0 &&
                                    !foldProjectDrawer &&
                                    cabinetProjects.map((project, index) => (
                                      <Draggable key={project.id} draggableId={project.id} index={index}>
                                        {(provided) => {
                                          const style = {
                                            width: '100%',
                                            marginBottom: '8px',
                                            ...provided.draggableProps.style,
                                          };
                                          return (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                              <ProjectView
                                                key={project.id}
                                                project={project}
                                                exceed={deskProjects.length >= 7}
                                                onKeydownProject={handleKeydownProject}
                                                onClickProjectCheck={handleClickProjectCheck}
                                                onSelectProject={handleSelectProject}
                                                onFetch={fetchProject}
                                                onDrop={handleDropToProject}
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                </CabinetContainer>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </ProjectViewScroll>
                    </DragDropContext>
                  </ProjectViewWrapper>
                </TabPanel>
                <TabPanel hidden={tabValue !== 1}>
                  <RoutineViewWrapper>
                    <div style={{ width: 582, marginBottom: 32 }}>
                      <Button
                        variant="contained"
                        startIcon={<Icons.Plus width={16} height={16} fill={COLORS.gray900} />}
                        onClick={() => setOpenRoutineCreation(true)}
                        sx={{
                          'backgroundColor': COLORS.white,
                          'borderRadius': '8px',
                          'color': COLORS.gray900,
                          'boxShadow': 'none',
                          'fontSize': '13px',
                          'fontWeight': 700,
                          'textTransform': 'none',
                          ':hover': {
                            backgroundColor: COLORS.gray200,
                          },
                        }}
                      >
                        {language === 'ko' ? '새로운 루틴 만들기' : 'Create a new routine'}
                      </Button>
                    </div>
                    <DragDropContext onDragEnd={handleRoutineBeautifulDragEnd}>
                      <RoutineViewScroll>
                        <Droppable droppableId="desk-routine">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {deskRoutines.length > 0 ? (
                                deskRoutines.map((routine, index) => (
                                  <Draggable key={routine.id} draggableId={routine.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <RoutineView
                                            routine={routine}
                                            onFetch={fetchRoutines}
                                            onSelectRoutine={handleSelectRoutine}
                                            onClickRoutineCheck={handleClickRoutineCheck}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))
                              ) : (
                                <EmptyRoutineWrapper>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M53.2234 2.17129C51.5432 0.598904 48.9064 0.686304 47.334 2.3665C45.7617 4.0467 45.849 6.68344 47.5292 8.25582L53.8138 14.1371L50.0272 14.1136C49.8747 14.1127 49.7241 14.1199 49.5757 14.135C27.5841 14.3561 9.37521 31.3709 7.70896 53.3512C6.03169 75.4722 21.6857 95.1506 43.6167 98.4935C65.5503 101.839 86.3564 87.7162 91.3465 66.0992C91.8641 63.857 90.466 61.6197 88.2238 61.1021C85.9816 60.5845 83.7443 61.9826 83.2267 64.2248C79.2169 81.5952 62.4979 92.9435 44.8732 90.2554L44.8728 90.2554C27.2497 87.5691 14.6707 71.7559 16.0184 53.9812L16.0185 53.9811C17.3663 36.2015 32.1826 22.4661 50.0095 22.4661C50.1365 22.4661 50.2621 22.4605 50.3861 22.4494L54.2468 22.4733L47.0947 29.4364C45.4459 31.0417 45.4106 33.6797 47.0159 35.3285C48.6211 36.9773 51.2591 37.0126 52.9079 35.4073L67.3412 21.3552C68.1571 20.5609 68.6123 19.4673 68.6011 18.3287C68.5899 17.19 68.1131 16.1056 67.2817 15.3275L53.2234 2.17129Z"
                                      fill="url(#paint0_linear_26864_287505)"
                                      fillOpacity="0.3"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M96.7924 24.0357C98.3662 25.7145 98.2812 28.3513 96.6024 29.9252L54.9357 68.9877C53.333 70.4902 50.8389 70.4902 49.2362 68.9877L28.4029 49.4564C26.7241 47.8825 26.639 45.2457 28.2129 43.5669C29.7868 41.8881 32.4236 41.8031 34.1024 43.3769L52.086 60.2365L90.9029 23.8457C92.5817 22.2718 95.2185 22.3569 96.7924 24.0357Z"
                                      fill="url(#paint1_linear_26864_287505)"
                                      fillOpacity="0.3"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_26864_287505"
                                        x1="49.5201"
                                        y1="1.04687"
                                        x2="49.5201"
                                        y2="98.9831"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#02CB8F" />
                                        <stop offset="1" stopColor="#0CB0BB" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint1_linear_26864_287505"
                                        x1="62.5026"
                                        y1="22.7187"
                                        x2="62.5026"
                                        y2="70.1146"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#02CB8F" />
                                        <stop offset="1" stopColor="#0CB0BB" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div style={{ color: COLORS.gray800, fontSize: 12, fontWeight: 700, marginTop: 20 }}>
                                    {language === 'ko' ? '내 중심을 잡아줄 루틴은 무엇인가요?' : 'What is your routine to keep you centered?'}
                                  </div>
                                  <div style={{ color: COLORS.gray500, fontSize: 12, marginTop: 4 }}>
                                    {language === 'ko'
                                      ? '루틴으로 급하지 않지만 중요한 일들을 꾸준히 관리해보세요.'
                                      : 'Manage important but not urgent tasks steadily with routines.'}
                                  </div>
                                </EmptyRoutineWrapper>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="cabinet-routine">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {cabinetRoutines.length > 0 && tabValue !== 0 && (
                                <CabinetContainer>
                                  <CabinetHeader>
                                    <CabinetLineWrapper />
                                    <CabinetFoldButton>
                                      <Tooltip
                                        title={
                                          <div>
                                            <div style={{ fontWeight: 700 }}>
                                              {language === 'ko' ? '중요한 일이 너무 많다면?' : 'Too many important tasks?'}
                                            </div>
                                            <div>
                                              {language === 'ko'
                                                ? `잠시 대기 중인 프로젝트, 상대적으로 주의력을\n적게 요구하는 프로젝트는 서랍에서 관리해보세요.`
                                                : `Projects on hold or needing less attention\ncan be managed in the drawer.`}
                                            </div>
                                          </div>
                                        }
                                        disableInteractive
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'default' }}>
                                          <Icons.Cabinet />
                                          <div style={{ fontWeight: 700, fontSize: '13px', margin: '0px 4px' }}>{language === 'ko' ? '서랍' : 'Drawer'}</div>
                                          <div style={{ fontWeight: 700, fontSize: '13px', color: COLORS.gray400 }}>{`(${cabinetRoutines.length})`}</div>
                                        </div>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          foldRoutineDrawer
                                            ? language === 'ko'
                                              ? '서랍 열기'
                                              : 'Open drawer'
                                            : language === 'ko'
                                            ? '서랍 접기'
                                            : 'Close drawer'
                                        }
                                      >
                                        <IconButton
                                          onClick={handleClickFoldRoutineDrawer}
                                          sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '0px' }}
                                        >
                                          {foldRoutineDrawer ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                                        </IconButton>
                                      </Tooltip>
                                    </CabinetFoldButton>
                                    <CabinetLineWrapper />
                                  </CabinetHeader>
                                  {cabinetRoutines.length > 0 &&
                                    !foldRoutineDrawer &&
                                    cabinetRoutines.map((routine, index) => (
                                      <Draggable key={routine.id} draggableId={routine.id} index={index}>
                                        {(provided) => {
                                          const style = {
                                            width: '100%',
                                            marginBottom: '8px',
                                            ...provided.draggableProps.style,
                                          };
                                          return (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                              <RoutineView
                                                routine={routine}
                                                onFetch={fetchRoutines}
                                                onSelectRoutine={handleSelectRoutine}
                                                onClickRoutineCheck={handleClickRoutineCheck}
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                </CabinetContainer>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </RoutineViewScroll>
                    </DragDropContext>
                  </RoutineViewWrapper>
                </TabPanel>
                <TabPanel hidden={tabValue !== 2}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <SelectWrapper>
                      <Select
                        value={archiveFilter}
                        onChange={handleChangeArchiveFilter}
                        sx={{ fontSize: '13px', fontWeight: '700', padding: '0px' }}
                        MenuProps={{
                          MenuListProps: {
                            style: {
                              padding: '8px',
                            },
                          },
                          style: { marginTop: '0px' },
                        }}
                      >
                        <MenuItem
                          value={language === 'ko' ? '프로젝트' : 'Project'}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '6px',
                            fontSize: '12px',
                            padding: '8px',
                          }}
                        >
                          {language === 'ko' ? '프로젝트' : 'Project'}
                        </MenuItem>
                        <MenuItem
                          value={language === 'ko' ? '루틴' : 'Routine'}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '6px',
                            fontSize: '12px',
                            padding: '8px',
                          }}
                        >
                          {language === 'ko' ? '루틴' : 'Routine'}
                        </MenuItem>
                      </Select>
                    </SelectWrapper>
                  </div>
                  <DragDropContext
                    onDragEnd={
                      archiveFilter === (language === 'ko' ? '프로젝트' : 'Project')
                        ? (result) => handleProjectBeautifulDragEnd(result)
                        : (result) => handleRoutineBeautifulDragEnd(result)
                    }
                  >
                    <ArchiveViewScroll>
                      <Droppable droppableId={archiveFilter === (language === 'ko' ? '프로젝트' : 'Project') ? 'archive-project' : 'archive-routine'}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ArchiveWrapper>
                              {archiveFilter === (language === 'ko' ? '프로젝트' : 'Project') &&
                                archiveProjects.length > 0 &&
                                archiveProjects.map((project, index) => (
                                  <Draggable key={project.id} draggableId={project.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <ProjectView
                                            key={project.id}
                                            project={project}
                                            exceed={deskProjects.length >= 7}
                                            onKeydownProject={handleKeydownProject}
                                            onClickProjectCheck={handleClickProjectCheck}
                                            onSelectProject={handleSelectProject}
                                            onFetch={fetchProject}
                                            onDrop={handleDropToProject}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                              {archiveFilter === (language === 'ko' ? '루틴' : 'Routine') &&
                                archiveRoutines.length > 0 &&
                                archiveRoutines.map((routine, index) => (
                                  <Draggable key={routine.id} draggableId={routine.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <RoutineView
                                            routine={routine}
                                            onFetch={fetchRoutines}
                                            onSelectRoutine={handleSelectRoutine}
                                            onClickRoutineCheck={handleClickRoutineCheck}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                              {archiveProjects.length === 0 && routines.filter((r) => r.status === 'ARCHIVED').length === 0 && (
                                <EmptyArchiveWrapper>
                                  <Icons.EmptyArchive style={{ marginBottom: 20 }} />
                                  <Fonts.H5>
                                    {language === 'ko'
                                      ? '완료 또는 취소된 프로젝트·루틴이 표시됩니다'
                                      : 'Completed or canceled projects·routines are displayed.'}
                                  </Fonts.H5>
                                </EmptyArchiveWrapper>
                              )}
                            </ArchiveWrapper>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ArchiveViewScroll>
                  </DragDropContext>
                </TabPanel>
              </PlanViewWrapper>
              <SidePanelWrapper>
                <SidePanel />
              </SidePanelWrapper>
              <RoutineCreationView open={openRoutineCreation} onClose={handleCloseRoutineCreation} />
              {isOpen && <QuickTaskInputPopup isOpen={isOpen} onClose={handlePopupClose} onCreate={handleCreateTask} showTooltip={showTooltip} />}
              <Popover
                open={Boolean(categoryAnchorEl)}
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setCategoryAnchorEl(null)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{ marginTop: '4px' }}
              >
                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
              </Popover>
              <Popover
                open={Boolean(deadlineAnchorEl)}
                anchorEl={deadlineAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setDeadlineAnchorEl(null)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{ marginTop: '4px' }}
              >
                <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
              </Popover>
            </Container>
          )}
        </>
      ) : (
        <>
          {intro && projectData === 'false' ? (
            <Stack spacing={1} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Skeleton variant="rounded" animation="wave" width={320} height={32} style={{ marginTop: '20px' }} />
              <Skeleton variant="rounded" animation="wave" width={260} height={32} />
              <Skeleton variant="rounded" animation="wave" width={450} height={24} style={{ marginTop: '12px' }} />
              <Skeleton variant="rounded" animation="wave" width={98} height={40} style={{ marginTop: '32px' }} />
              <Skeleton variant="rounded" animation="wave" width={830} height={460} style={{ marginTop: '32px' }} />
            </Stack>
          ) : (
            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
              <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
                <Skeleton variant="rounded" animation="wave" width={357} height={36} style={{ width: 582 }} />
                <div style={{ width: 582, display: 'flex', alignItems: 'center', margin: '35px 0px' }}>
                  <Skeleton variant="rounded" animation="wave" width={113} height={24} style={{ marginRight: 4 }} />
                  <Skeleton variant="rounded" animation="wave" width={113} height={24} />
                </div>
                <Skeleton variant="rounded" animation="wave" width={582} height={102} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
              </div>
              <Divider orientation="vertical" sx={{ height: '100%' }} />
              <div style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="rounded" animation="wave" width={148} height={24} style={{ marginRight: 12 }} />
                  <Skeleton variant="rounded" animation="wave" width={148} height={24} />
                </div>
                <div style={{ width: '308px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0px 12px 0px' }}>
                  <Skeleton variant="rounded" animation="wave" width={67} height={24} style={{ marginRight: 12 }} />
                  <Skeleton variant="rounded" animation="wave" width={24} height={24} />
                </div>
                <Skeleton variant="rounded" animation="wave" width={308} height={36} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${COLORS.gray100};
`;

const PlanViewWrapper = styled.div`
  width: 100%;
  min-height: 649px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const PlanHeader = styled.div`
  width: 582px;
  font-size: 24px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-bottom: 24px;
`;

const TabPanelHeader = styled.div`
  width: 582px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabPanel = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ProjectViewScroll = styled.div`
  width: 100%;
  height: calc(100vh - 294px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${hideScroll()}
`;

const ProjectViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyDestWrapper = styled.div`
  width: 582px;
  height: 360px;
  border: 1px dashed ${COLORS.gray400};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyDeskTitle = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const ArchiveViewScroll = styled.div`
  width: 100%;
  height: calc(100vh - 254px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${hideScroll()}
`;

const ArchiveWrapper = styled.div``;

const EmptyArchiveWrapper = styled.div`
  width: 582px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${COLORS.gray400};
  border-radius: 8px;
`;

const CreateProjectDropWrapper = styled.div<{ dragOver?: boolean }>`
  width: 582px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  border-radius: 8px;
  color: ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  margin-bottom: 24px;
  padding: 12px;
  font-size: 13px;
`;

const CreateProjectWrapper = styled.div`
  width: 582px;
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.gray200};
  background-color: ${COLORS.white};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 12px;
`;

const SidePanelWrapper = styled.div`
  border-left: 1px solid ${COLORS.gray400};
`;

const CategoryDeadlineInfoPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  margin-right: 8px;
`;

const RoutineViewScroll = styled.div`
  width: 100%;
  height: calc(100vh - 294px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${hideScroll()}
`;

const RoutineViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RoutineWrapper = styled.div``;

const EmptyRoutineWrapper = styled.div`
  width: 582px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${COLORS.gray400};
  border-radius: 8px;
`;

const CabinetContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CabinetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const CabinetLineWrapper = styled.div`
  flex-grow: 1;
  height: 1px;
  border-top: 1px dashed ${COLORS.gray300};
`;

const CabinetFoldButton = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 8px;
`;

const ProjectStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
`;

const ProjectStatusDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 16px;
  font-size: 12px;
  width: 80px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const SelectWrapper = styled.div`
  width: 582px;

  .MuiSelect-select {
    padding: 0px 0px 0px 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;
