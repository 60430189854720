import { SVGProps } from 'react';

export const ChevronRightDuo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.66406 5.33594L11.3307 8.0026L8.66406 10.6693M4.66406 5.33594L7.33073 8.0026L4.66406 10.6693"
        stroke={props.stroke || 'white'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
