const ACCESS_TOKEN = 'accessToken';
const WORKSPACE_TOKEN = 'workspaceToken';
const ENTRYPOINT = 'entrypoint';
const SURVEY_TARGET = 'surveyTarget';

/** local storage에 access token 저장 */
export const saveToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

/** local storage에서 access token 불러오기 */
export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

/** local storage에 workspace access token 저장 */
export const saveWorkspaceToken = (accessToken: string) => {
  if (accessToken) {
    localStorage.setItem(WORKSPACE_TOKEN, accessToken);
  } else {
    console.error('No Workspace Token');
  }
};

/** local storage에서 workspace access token 불러오기 */
export const getWorkspaceToken = () => {
  return localStorage.getItem(WORKSPACE_TOKEN);
};

/** localStorage에서 토큰 정보 지우기 */
export const removeTokens = () => {
  localStorage.removeItem(WORKSPACE_TOKEN);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ENTRYPOINT);
  localStorage.removeItem(SURVEY_TARGET);
};
