import { SVGProps } from 'react';

export const NextWeek = ({ fill = '#1C1B1F', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.33398 10.6663L9.00065 7.99967L6.33398 5.33301L5.40065 6.26634L7.13398 7.99967L5.40065 9.73301L6.33398 10.6663ZM1.66732 12.9997C1.30065 12.9997 0.986873 12.8692 0.725984 12.6083C0.464651 12.347 0.333984 12.033 0.333984 11.6663V4.33301C0.333984 3.96634 0.464651 3.65256 0.725984 3.39167C0.986873 3.13034 1.30065 2.99967 1.66732 2.99967H4.33398V1.66634C4.33398 1.29967 4.46465 0.985674 4.72598 0.724341C4.98687 0.463452 5.30065 0.333008 5.66732 0.333008H8.33398C8.70065 0.333008 9.01465 0.463452 9.27598 0.724341C9.53687 0.985674 9.66732 1.29967 9.66732 1.66634V2.99967H12.334C12.7007 2.99967 13.0147 3.13034 13.276 3.39167C13.5369 3.65256 13.6673 3.96634 13.6673 4.33301V11.6663C13.6673 12.033 13.5369 12.347 13.276 12.6083C13.0147 12.8692 12.7007 12.9997 12.334 12.9997H1.66732ZM1.66732 11.6663H12.334V4.33301H1.66732V11.6663ZM5.66732 2.99967H8.33398V1.66634H5.66732V2.99967Z"
        fill={fill}
      />
    </svg>
  );
};
