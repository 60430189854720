import { SVGProps } from 'react';

export const TaskListFilter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.33333 12.7273H8.66667C9.03333 12.7273 9.33333 12.4 9.33333 12C9.33333 11.6 9.03333 11.2727 8.66667 11.2727H7.33333C6.96667 11.2727 6.66667 11.6 6.66667 12C6.66667 12.4 6.96667 12.7273 7.33333 12.7273ZM2 4.72727C2 5.12727 2.3 5.45455 2.66667 5.45455H13.3333C13.7 5.45455 14 5.12727 14 4.72727C14 4.32727 13.7 4 13.3333 4H2.66667C2.3 4 2 4.32727 2 4.72727ZM4.66667 9.09091H11.3333C11.7 9.09091 12 8.76364 12 8.36364C12 7.96364 11.7 7.63636 11.3333 7.63636H4.66667C4.3 7.63636 4 7.96364 4 8.36364C4 8.76364 4.3 9.09091 4.66667 9.09091Z"
        fill={props.fill || '#858994'}
      />
    </svg>
  );
};
