import styled from '@emotion/styled';
import { ModalLayout } from '../ModaILayout';
import { COLORS } from '../../../styles/constants';
import { Linkline, Mail, Slack } from '../../Icons';
import Fonts from '../../../components/Fonts';

interface Props {
  modal?: boolean;
  primary?: boolean;
  toggle?: boolean;
  title: string;
  button?: boolean;
  handleClose: () => void;
  onClickShareEmail: () => void;
}

export const ShareModal = ({ modal = false, button = false, title, handleClose, onClickShareEmail }: Props) => {
  return (
    <ModalLayout button={button} title={title} label={''} handleButton={() => {}} handleClose={handleClose}>
      <Sharing>
        <SlackShare style={{ cursor: 'pointer' }} onClick={onClickShareEmail}>
          <Send>
            <Mail />
            <Fonts.H4 style={{ color: `${COLORS.gray900}`, marginLeft: '8px' }}>이메일로 보내기</Fonts.H4>
          </Send>
        </SlackShare>
        <SlackShare style={{ cursor: 'no-drop' }}>
          <Send>
            <Slack />
            <Fonts.H4 style={{ color: `${COLORS.gray500}`, marginLeft: '8px' }}>Slack으로 보내기</Fonts.H4>
          </Send>
          <Fonts.H4 style={{ color: `${COLORS.brand1}` }}>추후 지원됩니다</Fonts.H4>
        </SlackShare>
        <SlackShare style={{ cursor: 'no-drop' }}>
          <Send>
            <Linkline />
            <Fonts.H4 style={{ color: `${COLORS.gray500}`, marginLeft: '8px' }}>링크 복사</Fonts.H4>
          </Send>
          <Fonts.H4 style={{ color: `${COLORS.brand1}` }}>추후 지원됩니다</Fonts.H4>
        </SlackShare>
      </Sharing>
    </ModalLayout>
  );
};

const Sharing = styled.div``;

const SlackShare = styled.div`
  width: 452px;
  height: 56px;
  background-color: ${COLORS.gray100};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const Send = styled.div`
  display: flex;
`;
