import { SVGProps } from 'react';

export const Map = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.33301 6.61523C3.33301 9.84978 6.16266 12.5246 7.41513 13.5501C7.59438 13.6969 7.68508 13.7712 7.81881 13.8088C7.92295 13.8381 8.0762 13.8381 8.18034 13.8088C8.31432 13.7711 8.40438 13.6976 8.58431 13.5502C9.83679 12.5247 12.6663 9.85008 12.6663 6.61553C12.6663 5.39145 12.1746 4.21737 11.2995 3.35181C10.4243 2.48626 9.2374 2 7.99973 2C6.76205 2 5.57501 2.48634 4.69984 3.35189C3.82467 4.21744 3.33301 5.39116 3.33301 6.61523Z"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66634 6C6.66634 6.73638 7.26329 7.33333 7.99967 7.33333C8.73605 7.33333 9.33301 6.73638 9.33301 6C9.33301 5.26362 8.73605 4.66667 7.99967 4.66667C7.26329 4.66667 6.66634 5.26362 6.66634 6Z"
        stroke={props.stroke || '#ABB0BF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
