import styled from '@emotion/styled';
import { Dialog, IconButton, Popover, Slide, TextField, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useEventListener, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import {
  createCategoryV1CategoryPost,
  createWorkboxV2WorkboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  getUserSurveyListV1SurveysGet,
  recordUserSurveyV1SurveysSurveyIdPost,
  retrieveUserSettingV1UsersSettingGet,
  suspenseSurveyV1SurveysSurveyIdPatch,
  updateCategoryV1CategoryCategoryIdPatch,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
} from 'queries';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import TaskToday from './Today';
import TaskWeek from './Week';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { tasksAtom } from 'atoms/tasks';
import { quickTaskPopupVisibleAtom, startNowPopupVisibleAtom } from 'atoms/popup';
import { useAtom, useSetAtom } from 'jotai';
import SidePanel from './SidePanel';
import { osName } from 'react-device-detect';
import dayjs, { Dayjs } from 'lib/dayjs';
import PMFSurvey from 'pages/Survey/PMFSurvey';
import { OutCategory, OutTaskboxDetailResponse, RecordUserSurveyV1SurveysSurveyIdPostBody, SurveyResponseOut } from 'queries/model';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { timerTypeAtom, pauseAtom, totalTimeAtom } from 'atoms/timer';
import { foldCalendarViewAtom, foldSidePanelViewAtom, sidePanelWidthAtom, taskViewAtom } from 'atoms/foldView';
import { css } from '@emotion/react';
import { highlightNotificationAtom, ritualAlarmAtom, ritualEntryAtom, shutdownHighlightNotificationAtom } from 'atoms/notification';
import { DATE_FORMAT_4 } from 'utils/datetimeFormat';
import TaskMonth from './Month';
import { userSettingAtom } from 'atoms/userSetting';
import { Icons } from 'components';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import CategoryPopover, { CategoryActionType } from './components/CategoryPopover';
import { DeadlinePopover } from './components/DeadlinePopover';
import { categoryAtom } from 'atoms/category';
import { getCategoryBgColor, getCategoryTextColor } from 'utils/category';
import { selectedInstanceAtom, selectedProjectAtom } from 'atoms/projects';
import ComebackSurvey from 'pages/Survey/ComebackSurvey';
import { languageAtom } from 'atoms/language';
import { isOpenConnectCalendarDialogAtom } from 'atoms/dialog';
import ConnectCalendarDialog from 'components/ConnectCalendarDialog';

export type TimerActionType = 'START' | 'PAUSE' | 'RESET';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const TaskViewWrapper = styled.div<{ foldCalendar?: boolean; foldSidePanel?: boolean; sidePanelWidth?: number; taskView?: string }>`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.foldSidePanel &&
    css`
      width: 100%;
      min-width: 100%;
    `}

  ${(props) =>
    props.foldCalendar &&
    props.foldSidePanel &&
    css`
      width: 100%;
      min-width: 100%;
    `}

  ${(props) =>
    props.taskView !== 'today' &&
    css`
      min-width: 50%;
    `}
`;

const TaskInputWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '2px' : '4px'}`};
`;

const StartNowWrapper = styled.div`
  padding: 24px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const CategoryDeadlineInfoPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  margin-right: 8px;
`;

const QuickTaskCheckbox = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17722_176418)">
      <rect width="16" height="16" rx="1" fill="#87C8F5" />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_17722_176418">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const QuickTaskInputPopup = ({
  isOpen,
  showTooltip,
  categoryList,
  onClose,
  onCreate,
  onClickCategoryActions,
}: {
  isOpen: boolean;
  showTooltip?: boolean;
  categoryList?: OutCategory[];
  onClose?: () => void;
  onCreate?: (workbox: OutTaskboxDetailResponse) => void;
  onClickCategoryActions?: (category: OutCategory, action: CategoryActionType) => void;
}) => {
  const [language] = useAtom(languageAtom);
  const refInput = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [quickCategory, setQuickCategory] = useState<OutCategory | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [quickDeadline, setQuickDeadline] = useState<string | null>(null);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const workbox = {
        title: `${value}`.trim(),
        category: quickCategory,
        deadline: quickDeadline,
      };

      onCreate?.(workbox);
      setValue('');
      setQuickCategory(null);
      setQuickDeadline(null);
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      setQuickCategory(category);
      setCategoryAnchorEl(null);

      // 마지막 텍스트가 #이면 #을 지워준다.
      if (value && value.includes('#')) setValue(value.replace(/#/g, ''));
    } else {
      onClickCategoryActions && onClickCategoryActions(category!, action);
    }
  };

  const handleClickDeadline = (date: Dayjs | null) => {
    setQuickDeadline(date ? date.format(DATE_FORMAT_4) : null);
    setTimeout(() => {
      setDeadlineAnchorEl(null);
    }, 100);

    // 마지막 텍스트가 $이면 $을 지워준다.
    if (value && value.includes('$')) setValue(value.replace(/\$/g, ''));
  };

  return (
    <Dialog
      open={isOpen}
      transitionDuration={0}
      sx={{
        'borderRadius': 8,
        'marginTop': '114px',
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        style: { width: '500px', height: '56px', boxShadow: '0px 8px 16px 0px #1A1E2729', border: `1px solid ${COLORS.gray200}` },
      }}
      hideBackdrop={true}
      onClose={handleClose}
    >
      <NoMaxWidthTooltip
        open={showTooltip}
        placement="top"
        title={
          <div style={{ margin: '4px 8px' }}>
            <span>
              {language === 'ko' ? '다음부터 단축키로 빠르게 입력창을 열어보세요' : 'From now on, quickly open the input window with a shortcut'}
              {/* From now on, quickly open the input window with a shortcut */}
            </span>
            <KeyboardButtonRect small style={{ marginLeft: 28 }}>
              {osName === 'Windows' ? 'Ctrl' : '⌘'}
            </KeyboardButtonRect>
            + <KeyboardButtonRect small>/</KeyboardButtonRect>
          </div>
        }
      >
        <TaskInputWrapper>
          <QuickTaskCheckbox />
          <TextField
            ref={refInput}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus={true}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder={
              language === 'ko' ? '태스크를 입력해주세요.' : 'Please enter the task.'
              // 'Please enter the task.'
            }
            onKeyDown={handleKeydownInput}
            InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
            style={{ marginLeft: 8, flex: 1 }}
          />
          {quickCategory && (
            <CategoryShowingWrapper textColor={getCategoryTextColor(quickCategory.color)} bgColor={getCategoryBgColor(quickCategory.color)}>
              {`# ${quickCategory.name}`}
            </CategoryShowingWrapper>
          )}
          {quickDeadline && (
            <DeadlineShowingWrapper date={quickDeadline}>
              <Icons.Flag fill={dayjs(quickDeadline).isToday() ? COLORS.brand1 : dayjs(quickDeadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600} />
              <span style={{ marginLeft: '2px' }}>
                {dayjs(quickDeadline).isToday()
                  ? language === 'ko'
                    ? '오늘'
                    : 'Today'
                  : dayjs(quickDeadline).isYesterday()
                  ? language === 'ko'
                    ? '어제'
                    : 'Yesterday'
                  : dayjs(quickDeadline).isTomorrow()
                  ? language === 'ko'
                    ? '내일'
                    : 'Tomorrow'
                  : language === 'ko'
                  ? dayjs(quickDeadline).format('M월 D일 (dd)')
                  : dayjs(quickDeadline).format('dddd, MM D')}
                {/* {dayjs(quickDeadline).isToday()
                  ? 'Today'
                  : dayjs(quickDeadline).isYesterday()
                  ? 'Yesterday'
                  : dayjs(quickDeadline).isTomorrow()
                  ? 'Tomorrow'
                  : dayjs(quickDeadline).format('dddd, MM D')} */}
              </span>
            </DeadlineShowingWrapper>
          )}
          {!quickCategory && !quickDeadline && (
            <CategoryDeadlineInfoPlaceholder>
              {language === 'ko' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '16px',
                      height: '16px',
                      borderRadius: '2px',
                      backgroundColor: COLORS.gray100,
                      marginRight: '4px',
                    }}
                  >
                    #
                  </div>
                  <span style={{ marginRight: '4px' }}>으로 카테고리 지정</span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '16px',
                      height: '16px',
                      borderRadius: '2px',
                      backgroundColor: COLORS.gray100,
                      marginRight: '4px',
                    }}
                  >
                    $
                  </div>
                  <span>으로 기한 지정</span>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '4px' }}>Set category with</span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '16px',
                      height: '16px',
                      borderRadius: '2px',
                      backgroundColor: COLORS.gray100,
                      marginRight: '4px',
                    }}
                  >
                    #
                  </div>
                  <span>Set deadline with</span>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '16px',
                      height: '16px',
                      borderRadius: '2px',
                      backgroundColor: COLORS.gray100,
                      marginRight: '4px',
                    }}
                  >
                    $
                  </div>
                </div>
              )}
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '4px' }}>Set category with</span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '16px',
                    height: '16px',
                    borderRadius: '2px',
                    backgroundColor: COLORS.gray100,
                    marginRight: '4px',
                  }}
                >
                  #
                </div>
                <span>Set deadline with</span>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '16px',
                    height: '16px',
                    borderRadius: '2px',
                    backgroundColor: COLORS.gray100,
                  }}
                >
                  $
                </div>
              </div> */}
            </CategoryDeadlineInfoPlaceholder>
          )}
        </TaskInputWrapper>
      </NoMaxWidthTooltip>
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickDeadline} />
        </Popover>
      )}
    </Dialog>
  );
};

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StartNowPopup = ({ isOpen, onClose }: { isOpen: boolean; onClose?: () => void }) => {
  const [language] = useAtom(languageAtom);
  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      sx={{
        'borderRadius': 8,
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        style: {
          boxShadow: '0px 8px 16px 0px #1A1E2729',
          border: `1px solid ${COLORS.gray200}`,
          margin: '0px',
          top: `${document.body.getBoundingClientRect().height - 340}px`,
          right: '360px',
        },
      }}
      hideBackdrop={true}
      onClose={handleClose}
    >
      <StartNowWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: COLORS.gray700, fontSize: '20px', fontWeight: 700 }}>
          <div>
            {language === 'ko' ? '현재 시간으로 업무를 시작하고 싶으신가요?' : 'Would you like to start this task now?'}
            {/* Would you like to start this task now? */}
          </div>
          <IconButton onClick={onClose}>
            <Icons.Close width={20} height={20} />
          </IconButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '2px 0px 20px 0px' }}>
          <div style={{ backgroundColor: COLORS.sub3, borderRadius: '8px', padding: '6px' }}>
            <Icons.TutorialReplay />
          </div>
          {language === 'ko' ? (
            <span style={{ marginLeft: '8px' }}>
              다음부터 캘린더에서 태스크를 <span style={{ color: COLORS.gray700, fontWeight: 700 }}>마우스 오른쪽 클릭</span>하고 &apos;지금 시작하기&apos;를
              해보세요.
            </span>
          ) : (
            <span style={{ marginLeft: '8px' }}>
              Try <span style={{ color: COLORS.gray700, fontWeight: 700 }}>right-clicking</span> on a task in the calendar and selecting &apos;Start Now&apos;
              next time.
            </span>
          )}
          {/* <span style={{ marginLeft: '8px' }}>
            Try <span style={{ color: COLORS.gray700, fontWeight: 700 }}>right-clicking</span> on a task in the calendar and selecting &apos;Start Now&apos;
            next time.
          </span> */}
        </div>
        <img src={require('assets/images/StartNow.png')} />
      </StartNowWrapper>
    </Dialog>
  );
};

export const TaskView = () => {
  const location = useLocation();
  const defaultView = localStorage.getItem('task-view');
  const [language] = useAtom(languageAtom);
  const [isOpen, setIsOpen] = useAtom(quickTaskPopupVisibleAtom);
  const [showTooltip, setShowTooltip] = useState(true);
  const [survey, setSurvey] = useState<SurveyResponseOut>();
  const [isOpenPMFSurvey, setIsOpenPMFSurvey] = useState(false);
  const [isOpenComebackSurvey, setIsOpenComebackSurvey] = useState(false);
  const [userMouse, setUserMouse] = useState(false);
  const updateTasks = useSetAtom(tasksAtom);
  const [, setTotalTime] = useAtom(totalTimeAtom);
  const [, setTimerPause] = useAtom(pauseAtom);
  const [timer] = useAtom(timerTypeAtom);
  const [countDownTime, setCountDownTime] = useState(dayjs().add(1, 'hour').toDate());
  const [foldCalendar] = useAtom(foldCalendarViewAtom);
  const [foldSidePanel] = useAtom(foldSidePanelViewAtom);
  const [sidePanelWidth] = useAtom(sidePanelWidthAtom);
  const [ritualAlarm, setRitualAlarm] = useAtom(ritualAlarmAtom);
  const [ritualEntryDate, setRitualEntryDate] = useAtom(ritualEntryAtom);
  const [, setHighlightNotification] = useAtom(highlightNotificationAtom);
  const [shutdownHighlightNotification, setShutdownHighlightNotification] = useAtom(shutdownHighlightNotificationAtom);
  const [userSetting] = useAtom(userSettingAtom);
  const [taskView] = useAtom(taskViewAtom);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [startNowPopupVisible, fetchStartNowPopupVisible] = useAtom(startNowPopupVisibleAtom);

  useEffect(() => {
    handleHighlightNotification();
    const ritualEntry = JSON.parse(localStorage.getItem('ritual-entry') || '[]');
    if (ritualEntry[1]) {
      setRitualAlarm(false);
      localStorage.setItem('ritual-alarm', 'false');
    }
  }, []);

  useEffect(() => {
    if (userSetting.isRecordAlarmSet === false) {
      setRitualAlarm(false);
      localStorage.setItem('ritual-alarm', 'false');
    } else {
      handleRitualNotification();
    }
  }, [userSetting]);

  useUpdateEffect(() => {
    if (userMouse) {
      getPMFSurvey();
    }
  }, [userMouse]);

  useEventListener(
    window,
    'mousemove',
    (e: MouseEvent) => {
      if (e) {
        if (!userMouse) setUserMouse(true);
      }
    },
    { passive: true },
  );

  useKeyboardEvent(
    true,
    (ev) => {
      const isQuickTaskCmd = (ev.metaKey || ev.ctrlKey) && ev.key === '/';
      const isTaskboardInputCmd = !ev.metaKey && !ev.ctrlKey && ev.key === '/';
      if (!isQuickTaskCmd && !isTaskboardInputCmd) return;

      if (isQuickTaskCmd) {
        setShowTooltip(false);
        setIsOpen(true);
      }

      if (isTaskboardInputCmd) {
        const element = document.activeElement;

        if (element) {
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
        }

        const taskView = document.getElementById('task-view');
        const taskBoard = document.querySelector(`[data-board-id="${dayjs().format('YYYY-MM-DD')}"]`) as HTMLDivElement;
        taskView?.scrollTo({ top: taskBoard?.offsetTop - 52, behavior: 'auto' });

        setTimeout(() => {
          document.getElementById('taskboard-input')?.focus();
        }, 100);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleCreateTask = async (workbox: OutTaskboxDetailResponse) => {
    if (workbox) {
      await createWorkboxV2WorkboxesPost({
        id: uuidv4(),
        title: workbox.title!,
        categoryId: workbox.category ? [workbox.category!.id] : [],
        deadline: workbox.deadline,
      });
      language === 'ko' ? toast.success('새로운 태스크를 생성하였습니다.') : toast.success('New task created.');
      // toast.success('New task created.');
      updateTasks();
    }
  };

  const handlePopupClose = () => {
    setIsOpen(false);
    setShowTooltip(true);
  };

  const getPMFSurvey = async () => {
    const survey = await getUserSurveyListV1SurveysGet();
    if (survey[0]) {
      setSurvey(survey[0]);
      if (survey[0].id === '37241b41-29a9-4fea-995e-73c9faab9539') setIsOpenPMFSurvey(true);
      else setIsOpenComebackSurvey(true);
    } else {
      setSurvey(undefined);
    }
  };

  const handleClosePMFSurvey = () => {
    setIsOpenPMFSurvey(false);
  };

  const handleClickLaterPMFSurvey = async () => {
    await suspenseSurveyV1SurveysSurveyIdPatch(survey!.id);
    setIsOpenPMFSurvey(false);
  };

  const handleClickSubmitSurvey = async (content?: RecordUserSurveyV1SurveysSurveyIdPostBody) => {
    if (!survey) return;
    const success = await recordUserSurveyV1SurveysSurveyIdPost(survey!.id, { content });
    if (success) {
      setTimeout(() => {
        setIsOpenPMFSurvey(false);
        setIsOpenComebackSurvey(false);
      }, 2000);
    }
  };

  const handleCloseComebackSurvey = () => {
    setIsOpenComebackSurvey(false);
  };

  const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (delay === null) return;
      const id = setInterval(() => savedCallback.current!(), delay);
      return () => clearInterval(id);
    }, [delay]);
  };

  /** 6시간마다 유저 마우스 이벤트 체크 */
  useInterval(() => {
    if (userMouse) setUserMouse(false);
  }, 1000 * 60 * 60 * 6);

  const countUpTimer = useStopwatch({ autoStart: false });
  const countDownTimer = useTimer({
    expiryTimestamp: countDownTime,
    onExpire: () => handleExpireTimer(),
    autoStart: false,
  });

  useEffect(() => {
    if (timer === 'COUNTUP') {
      setTotalTime(countUpTimer.totalSeconds);
    } else {
      setTotalTime(countDownTimer.totalSeconds);
    }
  }, [timer === 'COUNTUP' ? countUpTimer.totalSeconds : countDownTimer.totalSeconds]);

  const handleExpireTimer = () => {
    language === 'ko' ? toast.success('타이머가 종료되었습니다.') : toast.success('Timer ended.');
    // toast.success('Timer ended.');
    setTimerPause(true);
    countDownTimer.restart(countDownTime, false);
  };

  const handleChangeTimer = (type: TimerActionType, time?: Date) => {
    if (timer === 'COUNTUP') {
      switch (type) {
        case 'START':
          setTimerPause(false);
          countUpTimer.start();
          break;
        case 'PAUSE':
          setTimerPause(true);
          countUpTimer.pause();
          break;
        case 'RESET':
          setTimerPause(true);
          setTotalTime(0);
          countUpTimer.reset(undefined, false);
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'START':
          setTimerPause(false);
          countDownTimer.resume();
          break;
        case 'PAUSE':
          setTimerPause(true);
          countDownTimer.pause();
          break;
        case 'RESET':
          setTimerPause(true);
          setCountDownTime(time ? time : dayjs().add(1, 'hour').toDate());
          countDownTimer.restart(time ? time : dayjs().add(1, 'hour').toDate(), false);
          break;
        default:
          break;
      }
    }
  };

  //isRecordAlarmSet false 이면 useInterval내에서 ritualAlarm false로 변경
  useInterval(() => {
    if (!userSetting.isRecordAlarmSet && ritualAlarm) {
      setRitualAlarm(false);
      localStorage.setItem('ritual-alarm', 'false');
    } else {
      handleRitualNotification();
    }
    handleHighlightNotification();
  }, 60000);

  const handleRitualNotification = () => {
    const currentTime = dayjs();
    const newDay = dayjs().startOf('day');
    const snoozeTime = JSON.parse(localStorage.getItem('snooze-time') || '[]');
    const [entryDate, entryCompleted] = ritualEntryDate;
    let firstAlarmTime: Dayjs = dayjs().set('hour', 17).set('minute', 0).set('second', 0);
    let secondAlarmTime: Dayjs | undefined = undefined;

    if (userSetting?.recordAlarmTimeCollection) {
      firstAlarmTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${userSetting.recordAlarmTimeCollection[0]}`);
      secondAlarmTime = userSetting.recordAlarmTimeCollection[1]
        ? dayjs(`${dayjs().format('YYYY-MM-DD')} ${userSetting.recordAlarmTimeCollection[1]}`)
        : undefined;
    }

    if (!entryDate || newDay.isAfter(dayjs(entryDate).format(DATE_FORMAT_4))) {
      setRitualAlarm(false);
      setRitualEntryDate([newDay.format(DATE_FORMAT_4), false]);
      localStorage.setItem('ritual-alarm', 'false');
      localStorage.setItem('ritual-entry', JSON.stringify([newDay.format(DATE_FORMAT_4), false]));
    }

    if (
      (dayjs(currentTime).isSame(firstAlarmTime) ||
        dayjs(currentTime).isAfter(firstAlarmTime) ||
        (secondAlarmTime && (dayjs(currentTime).isSame(secondAlarmTime) || dayjs(currentTime).isAfter(secondAlarmTime)))) &&
      !ritualAlarm &&
      !entryCompleted &&
      (!snoozeTime || snoozeTime[0] === 0)
    ) {
      setRitualAlarm(true);
      localStorage.setItem('ritual-alarm', 'true');
    }
  };

  const handleHighlightNotification = () => {
    const newDay = dayjs().startOf('day');
    if (newDay.isAfter(dayjs(shutdownHighlightNotification[0]).format(DATE_FORMAT_4))) {
      setHighlightNotification(false);
      localStorage.setItem('highlight-notification', 'false');
      setShutdownHighlightNotification([newDay.format(DATE_FORMAT_4), false]);
      localStorage.setItem('shutdown-highlight-notification', JSON.stringify([newDay.format(DATE_FORMAT_4), false]));
    }
  };

  const handleCloseStartNowPopup = async () => {
    await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(startNowPopupVisible.id, {
      data: { popup: false },
    });
    fetchStartNowPopupVisible();
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    switch (action) {
      case 'CREATE':
        {
          if (!category) return;
          const success = await createCategoryV1CategoryPost(category);
          if (success) {
            fetchCategoryList();
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
          if (success) {
            fetchCategoryList();
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
          fetchCategoryList();
        }
        break;
    }
  };

  const [openConnectCalendarDialog, seOpenConnectCalendarDialog] = useAtom(isOpenConnectCalendarDialogAtom);

  const handleCloseConnectCalendarDialog = () => {
    seOpenConnectCalendarDialog(false);
  };

  return (
    <>
      <Container>
        <TaskViewWrapper foldCalendar={foldCalendar} foldSidePanel={foldSidePanel} sidePanelWidth={sidePanelWidth} taskView={taskView}>
          {location.pathname === '/task' ? (
            <>
              {defaultView === 'month' ? (
                <TaskMonth />
              ) : defaultView === 'week' ? (
                <TaskWeek onChangeTimer={handleChangeTimer} />
              ) : (
                <TaskToday onChangeTimer={handleChangeTimer} />
              )}
            </>
          ) : location.pathname === '/task/month' ? (
            <TaskMonth onChangeTimer={handleChangeTimer} />
          ) : location.pathname === '/task/week' ? (
            <TaskWeek onChangeTimer={handleChangeTimer} />
          ) : location.pathname === '/task/today' ? (
            <TaskToday onChangeTimer={handleChangeTimer} />
          ) : (
            <Outlet />
          )}
        </TaskViewWrapper>
        <SidePanel />
        {isOpen && (
          <QuickTaskInputPopup
            isOpen={isOpen}
            showTooltip={showTooltip}
            categoryList={categoryList}
            onClose={handlePopupClose}
            onCreate={handleCreateTask}
            onClickCategoryActions={handleClickCategoryActions}
          />
        )}
        <StartNowPopup isOpen={!!startNowPopupVisible.data.popup} onClose={handleCloseStartNowPopup} />
        <PMFSurvey open={isOpenPMFSurvey} onClose={handleClosePMFSurvey} onClickSubmit={handleClickSubmitSurvey} onClickLater={handleClickLaterPMFSurvey} />
        <ComebackSurvey open={isOpenComebackSurvey} onClose={handleCloseComebackSurvey} onClickSubmit={handleClickSubmitSurvey} />
        {openConnectCalendarDialog && <ConnectCalendarDialog open={openConnectCalendarDialog} onClose={handleCloseConnectCalendarDialog} />}
      </Container>
    </>
  );
};

export default TaskView;
