import { SVGProps } from 'react';

export const More = ({ width = 16, height = 16, fill = '#858994' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="8" r="1" fill={fill} />
      <circle cx="8" cy="8" r="1" fill={fill} />
      <circle cx="12" cy="8" r="1" fill={fill} />
    </svg>
  );
};
