import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';
import { ColorCheck } from '../Icons';

interface PopupProps {
  id: string;
  label: string;
  callback: () => void;
}

export const SuccessPopup = ({ id, label, callback }: PopupProps) => {
  useEffect(() => {
    const element: any = document.querySelector(`#${id}`);

    setTimeout(() => {
      element.className = element.className.replace('show', '');
      callback();
    }, 1500);
  }, []);

  return (
    <Wrapper id={id} className="show">
      <ColorCheck />
      <Fonts.H3 style={{ marginLeft: '8px' }}>{label}</Fonts.H3>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  visibility: hidden;
  width: fit-content;
  box-sizing: border-box;
  box-shadow: 0px 16px 24px 0px #1a1e2729;
  background-color: ${COLORS.positive2};
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  &.show {
    visibility: visible;
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
`;
