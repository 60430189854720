import dayjs from 'dayjs';
import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { listRoutineV1RoutineGet } from 'queries';
import { OutReadEvent, OutRoutine } from 'queries/model';

const request = async () =>
  await listRoutineV1RoutineGet(
    {
      status: ['ACTIVE', 'HOLD', 'ARCHIVED'],
    },
    {
      paramsSerializer: (params: any) => {
        if (Array.isArray(params.status)) {
          return params.status.map((param: any) => `status=${param}`).join('&');
        }
        return '';
      },
    },
  );

export const routines = atomWithDefault<OutRoutine[]>(request);
export const routinesAtom = atom(
  (get) => get(routines),
  async (_, set) => {
    const res = await listRoutineV1RoutineGet(
      {
        status: ['ACTIVE', 'HOLD', 'ARCHIVED'],
      },
      {
        paramsSerializer: (params: any) => {
          if (Array.isArray(params.status)) {
            return params.status.map((param: any) => `status=${param}`).join('&');
          }
          return '';
        },
      },
    );
    set(routines, res || []);
  },
);

export const selectedRoutineAtom = atom<OutRoutine | null>(null);
export const syncPlanRoutine = atom<OutRoutine | null>(null);
export const fetchRoutineFromDetailViewAtom = atom<boolean>(false);
export const selectedRoutineDateAtom = atom<Date>(new Date(dayjs().format('YYYY-MM-DD HH:mm:ss')));
export const changedRoutineInstanceIdAtom = atom<string | null>(null);
