import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Checkbox, CircularProgress, Dialog, Divider, IconButton, Popover, Popper, Tooltip } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import {
  updateTaskboxV1TaskboxesTaskboxIdPut,
  createTaskboxTaskV1TaskboxesTaskboxIdTasksPost,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  createTaskboxV1TaskboxesPost,
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete,
  updateFrequentWorkV1FrequentworksFrequentWorkIdPut,
  createFrequentWorkV1FrequentworksPost,
  createCategoryV1CategoryPost,
  updateCategoryV1CategoryCategoryIdPatch,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch,
  subtaskToTaskV1TaskboxesSubtasksTaskTypePatch,
  deleteProjectV1ProjectProjectIdDelete,
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  updateProjectV1ProjectProjectIdPatch,
  readTaskboxesV1TaskboxesGet,
  absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost,
  linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost,
  createRoutineFromTaskV1RoutineFromTaskTaskIdPost,
  unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch,
  updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch,
  unlinkWithProjectV1ProjectProjectIdUnlinkingTaskTaskboxIdPatch,
  updateRoutineStatusV1RoutineRoutineIdStatusPatch,
} from 'queries';
import {
  UpdateTaskbox,
  OutPlanWeekly,
  UpdateIssueDetail,
  OutTaskboxDetailResponse,
  CreateTaskbox,
  RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
  InCreateTaskBoxInRecurrenceTarget,
  OutFrequentWork,
  CreateFrequentWork,
  UpdateTaskForTaskBox,
  CreateTaskboxTask,
  CreateFrequentWorkTask,
  OutTaskboxDetailResponseForSubtask,
  OutCategory,
  UpdateTaskBoxRecurrence,
  InRecurrence,
  RecurrenceActionType,
  OutProject,
  OutTaskboxDetailProjectOnly,
  OutRoutine,
  InUpdateRoutineRuleOptionalTime,
} from 'queries/model';
import { ForwardedRef, forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { setCaretToEnd } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { convertDatetimeFormat, DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_5, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { TimelineTaskboxDetail } from './TimelineTaskboxDetail';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getCaretPosition } from 'utils/caret';
import { IssueLinkage } from 'components/Icons';
import IssueDetailView from './IssueDetailView';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { dragContextAtom } from 'atoms/works';
import toast from 'react-hot-toast';
import { SubtaskDetail } from './SubtaskDetail';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { TaskboxContinueDetail } from './TaskboxContinueDetail';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { tasksAtom } from 'atoms/tasks';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import SubTaskCheckbox from 'components/SubTaskCheckbox';
import ConfettiExplosion from 'react-confetti-explosion';
import TimeDateSelectionModal from 'components/TimeSelectionModal/TimeDateSelectionModal';
import { TaskboxMultiSelectPopover } from './TaskboxMultiSelectPopover';
import { HighlightTimeSelectionModal } from './HighlightTimeSelectionModal';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { focusModeTaskboxAtom, hideFocusModeTaskboxAtom } from 'atoms/focus';
import FocusModeDialog from './FocusModeDialog';
import { TimerActionType } from '..';
import { RemoveRecurrenceDialog } from 'components/Recurrence/RemoveRecurrenceDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { osName } from 'react-device-detect';
import InboxContextMenu, { InboxContextMenuType } from 'components/InboxContextMenu';
import { MultiSelectType } from 'pages/Ritual/Organize';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { DeadlinePopover } from './DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { debounce, isEqual } from 'lodash';
import TemplateDialog from './TemplateDialog';
import {
  covertedToProjectIdAtom,
  fetchInstancesFromProjectDetailViewAtom,
  loadSubtaskFromProjectAtom,
  moveToInstanceAtom,
  projectsAtom,
  selectedInstanceAtom,
  selectedProjectAtom,
  syncInstanceAtom,
} from 'atoms/projects';
import { ProjectTaskBlockAction, TaskBlock } from './TaskBlockInputList';
import { isPrintableKey } from 'utils/keyUp';
import ProjectListPopover from './ProjectListPopover';
import { userStateAtom } from 'atoms/userState';
import { useDebouncedSync } from 'utils/debounce';
import { syncTaskboxDetailViewAtom, taskboxDetailViewAtom } from 'atoms/taskboxDetail';
import { MultiSelectSubtaskIds } from '../Today';
import SubtaskMultiSelectPopover from './SubtaskMultiSelectPopover';
import { changedRoutineInstanceIdAtom, routinesAtom, selectedRoutineAtom, selectedRoutineDateAtom } from 'atoms/routine';
import { languageAtom } from 'atoms/language';
import { changedSubtaskTaskboxAtom, isEditingSubtaskAtom } from 'atoms/subtask';
import SubtaskModeMenu from './SubtaskModeMenu';
import TaskboxConvertMenu, { ConvertMenuType } from './TaskboxConvertMenu';
import RoutineCreationView from 'pages/Desk/components/RoutineCreationView';
import { featureStorageAtom } from 'atoms/featureStorage';
import { foldSidePanelViewAtom } from 'atoms/foldView';
import { sidePanelTabIndexAtom } from 'atoms/sidePanel';
import { TaskboxPostponeDetail } from './TaskboxPostponeDetail';
import { isOpenConnectCalendarDialogAtom } from 'atoms/dialog';
import { calendarListAtom } from 'atoms/calendarList';

export interface Props {
  index?: number;
  expanded?: boolean;
  date?: Date;
  event: OutTaskboxDetailResponse;
  events?: OutTaskboxDetailResponse[];
  templateList?: OutFrequentWork[];
  selectedEvent?: OutTaskboxDetailResponse;
  selectedSubtaskId?: string;
  multiSelected?: boolean;
  multiSelectIds?: string[];
  multiSelectAnchorEl?: HTMLElement | null;
  categoryList?: OutCategory[];
  multiSelectSubtaskIds?: MultiSelectSubtaskIds[];
  multiSelectSubtaskAnchorEl?: HTMLElement | null;
  match?: boolean;
  onFetchIncompleteTaskboxes?: () => void;
  onFetchTemplateList?: () => void;
  onChange?: (startDate: Date, endDate?: Date, move?: boolean) => void;
  onChangeWithoutFetchData?: (taskbox: OutTaskboxDetailResponse) => void;
  onChangeSubtask?: (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => void;
  onChangeTaskboxImage?: (event: OutTaskboxDetailResponse) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onClickSubtask?: (id: string) => void;
  onChangeMultiSelectIds?: (eventId: string, e?: React.MouseEvent<HTMLDivElement>) => void;
  onChangeMultiSelectIdsList?: (id: string[]) => void;
  onCloseMultiSelect?: () => void;
  onClickMultiSelectActions?: (type: MultiSelectType, date?: Dayjs) => void;
  onMultiSelectDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  onMultiSelectDragEnd?: () => void;
  onChangeTimer?: (type: TimerActionType, time?: Date) => void;
  onFetchCategoryList?: () => void;
  onDeleteProject?: (projectId: string) => void;
  onContextMenuTaskbox?: (id: string) => void;
  onChangeMultiSelectSubtaskIds?: (subtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => void;
  onClickMultiSelectSubtaskAction?: (type: InboxContextMenuType, data: any) => void;
  onCloseMultiSelectSubtask?: () => void;
  onMultiSelectSubtaskAnchorElNull?: () => void;
}

//TODO 멀티셀렉 관련 함수 통합하기
type Tasks = {
  id: string;
  content?: string;
  beforeId?: string | null;
};

export type DateType = 'tomorrow' | 'nextWeek' | 'calendar';
export type ContinueDateType = 'today' | 'tomorrow' | 'nextWeek' | 'calendar';
export type SubtaskContextMenu = 'CONVERT_TO_TASKBOX' | 'LATER' | 'DELETE' | 'CATEGORY' | 'DEADLINE';
export type SubtaskMode = 'ADD' | 'IMPORT';

export const TimelineTaskbox = forwardRef(
  (
    {
      index,
      expanded,
      date,
      event,
      events = [],
      templateList = [],
      selectedEvent,
      selectedSubtaskId,
      multiSelected = false,
      multiSelectIds = [],
      multiSelectAnchorEl,
      categoryList = [],
      multiSelectSubtaskIds = [],
      multiSelectSubtaskAnchorEl,
      match = false,
      onFetchIncompleteTaskboxes,
      onFetchTemplateList,
      onChange,
      onChangeWithoutFetchData,
      onChangeSubtask,
      onChangeTaskboxImage,
      onClickTaskbox,
      onClickSubtask,
      onChangeMultiSelectIds,
      onChangeMultiSelectIdsList,
      onCloseMultiSelect,
      onClickMultiSelectActions,
      onMultiSelectDragStart,
      onMultiSelectDragEnd,
      onChangeTimer,
      onFetchCategoryList,
      onChangeMultiSelectSubtaskIds,
      onClickMultiSelectSubtaskAction,
      onCloseMultiSelectSubtask,
      onMultiSelectSubtaskAnchorElNull,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    // State 관리 훅
    const [timeSelection, setTimeSelection] = useState<HTMLElement | null>(null);
    const [simpleTimeSelection, setSimpleTimeSelection] = useState<HTMLElement | null>(null);
    const [highlightTimeSelection, setHighlightTimeSelection] = useState<HTMLElement | null>(null);
    const [taskboxDetail, setTaskboxDetail] = useState<HTMLElement | null>(null);
    const [taskboxPostponeAnchorEl, setTaskboxPostponeAnchorEl] = useState<HTMLElement | null>(null);
    const [subtaskDetail, setSubtaskDetail] = useState<HTMLElement | null>(null);
    const [subtaskId, setSubtaskId] = useState<string | undefined>();
    const [tasks, setTasks] = useState<OutTaskboxDetailResponseForSubtask[]>(event!.tasks!);
    const [clickedIssue, setClickedIssue] = useState<string | undefined>();
    const [plan, setPlan] = useState<OutPlanWeekly[]>([]);
    const [taskboxHeight, setTaskboxHeight] = useState<number | undefined>();
    const [isDragging, setIsDragging] = useState(false);
    const [draggingToTaskbox, setDraggingToTaskbox] = useState(false);
    const [selectedTaskbox, setSelectedTaskbox] = useState<string | undefined>('');
    const [focusedTaskbox, setFocusedTaskbox] = useState<OutTaskboxDetailResponse | undefined>(selectedEvent);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [getCaret, setGetCaret] = useState<number | null>(null);
    const [inprogressPopover, setInprogressPopover] = useState<HTMLElement | null>(null);
    const [continueSettingPopover, setContinueSettingPopover] = useState<HTMLElement | null>(null);
    const [contextTaskbox, setContextTaskbox] = useState<OutTaskboxDetailResponse | null>(null);
    const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>(null);
    const [isOpenMultiSelectDeleteDialog, setIsOpenMultiSelectDeleteDialog] = useState(false);
    // const [taskboxDone, setTaskboxDone] = useState<boolean>(event!.done!);
    const [updateRecurrence, setUpdateRecurrence] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [deleteWithKeydown, setDeleteWithKeydown] = useState(false);
    const [deleteRecurrenceWithKeydown, setDeleteRecurrenceWithKeydown] = useState(false);
    const [foldTaskboxFieldWidth, setFoldTaskboxFieldWidth] = useState<number>(0);
    const [openRecurrenceUpdate, setOpenRecurrenceUpdate] = useState(false);
    const [delayRecommendTask, setDelayRecommendTask] = useState(false);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
    const [isQuickCategory, setIsQuickCategory] = useState(false);
    const [isQuickDeadline, setIsQuickDeadline] = useState(false);
    const [isQuickProject, setIsQuickProject] = useState(false);
    const [isHoverLoadSubtasks, setIsHoverLoadSubtasks] = useState(false);
    const [isNextInstance, setIsNextInstance] = useState<OutTaskboxDetailProjectOnly | null>(null);
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
    const [isInstanceTyping, setIsInstanceTyping] = useState(false);
    const [isRemoveTextAndRecommend, setIsRemoveTextAndRecommend] = useState(false);
    const [matchCalendarAndBoardDate, setMatchCalendarAndBoardDate] = useState(false);
    const [projectSubtasks, setProjectSubtasks] = useState<OutTaskboxDetailProjectOnly[]>([]);
    const [recommendTaskIndex, setRecommendTaskIndex] = useState<number>(0);
    const [isRecommendTask, setIsRecommendTask] = useState(false);
    const [projectListAnchorEl, setProjectListAnchorEl] = useState<HTMLElement | null>(null);

    // Ref 훅
    const refTask = useRef<HTMLDivElement>(null);
    const refTaskbox = useRef<HTMLDivElement>(null);
    const refPopperMenu = useRef<HTMLDivElement>(null);
    const refTaskboxInput = useRef<HTMLInputElement>(null);
    const refInstanceIcon = useRef<HTMLDivElement>(null);
    const refLockInIcon = useRef<HTMLDivElement>(null);

    // Atom 관련 훅
    const [, fetchTasks] = useAtom(tasksAtom);
    const [dragContext, setDragContext] = useAtom(dragContextAtom);
    const [focusModeTaskbox, setFocusModeTaskbox] = useAtom(focusModeTaskboxAtom);
    const [hideFocusMode, setHideFocusMode] = useAtom(hideFocusModeTaskboxAtom);
    const [, setFetchInstancesFromProjectDetailView] = useAtom(fetchInstancesFromProjectDetailViewAtom);
    const [, setIsLoadSubtaskFromProject] = useAtom(loadSubtaskFromProjectAtom);
    const [moveToInstance, setMoveToInstance] = useAtom(moveToInstanceAtom);
    const [userState] = useAtom(userStateAtom);
    const [, setSyncInstance] = useAtom(syncInstanceAtom);
    const [, setSyncTaskboxDetailView] = useAtom(syncTaskboxDetailViewAtom);
    const [taskboxDetailView, setTaskboxDetailView] = useAtom(taskboxDetailViewAtom);
    const debouncedSyncInstance = useDebouncedSync(setSyncInstance, 100);
    const debouncedSyncTaskbox = useDebouncedSync(setSyncTaskboxDetailView, 100);
    const [projects, fetchProjects] = useAtom(projectsAtom);
    const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
    const [, setSelectedInstance] = useAtom(selectedInstanceAtom);
    const [routines, fetchRoutines] = useAtom(routinesAtom);
    const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);
    const [, setSelectedRoutineDate] = useAtom(selectedRoutineDateAtom);
    const [changedRoutineInstanceId, setChangedRoutineInstanceId] = useAtom(changedRoutineInstanceIdAtom);
    const [language] = useAtom(languageAtom);
    const [changedSubtaskTaskbox, setChangedSubtaskTaskbox] = useAtom(changedSubtaskTaskboxAtom);
    const [isEditingSubtask, setIsEditingSubtask] = useAtom(isEditingSubtaskAtom);
    const [convertedToProjectId, setConvertedToProjectId] = useAtom(covertedToProjectIdAtom);
    const [foldSidePanel, setFoldSidePanel] = useAtom(foldSidePanelViewAtom);
    const [featureStorage, setFeatureStorage] = useAtom(featureStorageAtom);
    const [sidePanelTabIndex, setSidePanelTabIndex] = useAtom(sidePanelTabIndexAtom);
    const calendarList = useAtomValue(calendarListAtom);
    const setOpenConnectCalendarDialog = useSetAtom(isOpenConnectCalendarDialogAtom);
    // 기타 함수
    const generateTaskId = () => uuidv4();

    useEffect(() => {
      handleDeleteSubtaskWithEmptyTitle();
    }, []);

    useEffect(() => {
      setMatchCalendarAndBoardDate(match);
    }, [match]);

    useEffect(() => {
      setTimeout(() => {
        if (delayRecommendTask) setDelayRecommendTask(false);
      }, 1000);
    }, [delayRecommendTask]);

    useEffect(() => {
      const targetProject = projects.find((project) => project.id === event.project?.id);
      if (!targetProject) return;
      const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
      const lastIndex = instances.findIndex((instance) => instance.id === event.id);
      const nextInstance = instances[lastIndex + 1];
      setIsNextInstance(nextInstance);
    }, [event]);

    useEffect(() => {
      if (event && event.project) {
        const project = projects.find((p) => p.id === event.project!.id);
        if (project && project?.tasks.length > 0) setProjectSubtasks(project!.tasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK' && !v.done) || []);
      }
    }, [event, projects]);

    useEffect(() => {
      if (!moveToInstance) return;
      const targetEvent = events.find((event) => event.id === moveToInstance.id);
      setTimeout(() => {
        if (targetEvent) onClickTaskbox && onClickTaskbox(targetEvent);
        setMoveToInstance(null);
      }, 500);
    }, [moveToInstance]);

    useEffect(() => {
      setFocusedTaskbox(selectedEvent !== focusedTaskbox ? selectedEvent : undefined);
      if (selectedProject) setSelectedInstance(selectedEvent?.project ? selectedEvent : null);
    }, [selectedEvent]);

    useLayoutEffect(() => {
      setTasks(event?.tasks ? event.tasks : []);
      if (focusModeTaskbox?.id === event?.id) {
        setFocusModeTaskbox(event!);
      }
    }, [event, event?.tasks]);

    // useUpdateEffect(() => {
    //   setTaskboxDone(event!.done!);
    //   setIsExploding(event!.done!);
    // }, [event!.done!]);

    // console.log(taskboxDone);

    useEffect(() => {
      setTaskboxHeight(refTaskbox.current?.getBoundingClientRect().height);
      if (!expanded) {
        const width = document.querySelector(`[data-foldShowingId="${event.id}"]`)?.getBoundingClientRect().width;
        if (width) setFoldTaskboxFieldWidth(width);
      }
    }, [tasks, focusedTaskbox, expanded]);

    useEffect(() => {
      if (getCaret) focusInput(event!.id!);
      setGetCaret(null);
    }, [getCaret]);

    useUpdateEffect(() => {
      handleTaskboxDone(tasks);
    }, [tasks.length]);

    useEffect(() => {
      setSelectedTaskId(selectedSubtaskId!);
    }, [selectedSubtaskId]);

    /**태스크박스 외부 클릭 시 태스크박스 닫기 */
    useClickOutside(refTaskbox, (e) => {
      if (event?.id === focusedTaskbox?.id && matchCalendarAndBoardDate) {
        setTimeout(() => {
          handleDeleteSubtaskClickOutside();
        }, 100);
      }

      if (
        focusedTaskbox !== selectedEvent &&
        !taskboxDetail &&
        !subtaskDetail &&
        !taskboxPostponeAnchorEl &&
        !timeSelection &&
        !simpleTimeSelection &&
        !highlightTimeSelection &&
        !inprogressPopover &&
        !continueSettingPopover &&
        !openTemplateDialog
      ) {
        setFocusedTaskbox(undefined);
      }
    });

    useClickOutside(refTask, () => {
      const element = document.activeElement as HTMLElement;
      if (element?.classList.contains('subtask')) {
        element.blur();
      }
      handleSelectedTaskId('');
    });

    /** 태스크박스 외부 클릭 시 멀티셀렉 풀리게 */
    useClickOutside(
      refPopperMenu,
      (e: Event) => {
        const confirmDialog = document.querySelector('.confirm-dialog') as HTMLElement;
        if (isOpenMultiSelectDeleteDialog) return;
        if (confirmDialog) return;
        if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
        onCloseMultiSelect && onCloseMultiSelect();
        onCloseMultiSelectSubtask && onCloseMultiSelectSubtask();
      },
      ['mouseup'],
    );

    useKeyboardEvent(
      true,
      (ev) => {
        if (ev.code === 'Enter') {
          if (isEditingSubtask) return;
          if (focusedTaskbox) {
            const element = document.activeElement as HTMLElement;
            if (!element) return;
            if (
              element.tagName === 'TEXTAREA' ||
              (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
              (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
            ) {
              return;
            }

            if (selectedRoutine || selectedProject || taskboxDetailView) return;
            if (selectedTaskId) setFocusElement(selectedTaskId);
            setFocusElement(focusedTaskbox.id!);
          }
        }

        if ((osName === 'Windows' && ev.code === 'Delete' && ev.shiftKey) || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey && ev.shiftKey)) {
          ev.preventDefault();
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          if (selectedTaskId && tasks.find((task) => task.id === selectedTaskId)) {
            handleClickSubtaskContextMenu(selectedTaskId, 'DELETE');
            const index = tasks.findIndex((task) => task.id === selectedTaskId);
            if (index === 0) {
              if (tasks[index + 1]) {
                setFocusElement(tasks[index + 1].id!);
                handleSelectedTaskId(tasks[index + 1].id!);
              }
            } else {
              if (tasks[index - 1]) {
                setFocusElement(tasks[index - 1].id!);
                handleSelectedTaskId(tasks[index - 1].id!);
              }
            }
            return;
          }
          if (focusedTaskbox.id === event?.id) setDeleteWithKeydown(true);
        }

        if ((osName === 'Windows' && ev.code === 'Delete') || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey)) {
          ev.preventDefault();
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }

          if (selectedTaskId && tasks.find((task) => task.id === selectedTaskId)) {
            handleClickSubtaskContextMenu(selectedTaskId, 'DELETE');
            const index = tasks.findIndex((task) => task.id === selectedTaskId);
            if (index === 0) {
              if (tasks[index + 1]) {
                setFocusElement(tasks[index + 1].id!);
                handleSelectedTaskId(tasks[index + 1].id!);
              }
            } else {
              if (tasks[index - 1]) {
                setFocusElement(tasks[index - 1].id!);
                handleSelectedTaskId(tasks[index - 1].id!);
              }
            }
            return;
          }
          if (focusedTaskbox.id === event?.id) setDeleteWithKeydown(true);
        }

        if (ev.altKey && ev.shiftKey && ev.code === 'KeyV') {
          ev.preventDefault();
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          if (focusedTaskbox.id === event?.id) handleClickTaskboxCheck();
        }

        if (ev.code === 'KeyV' && !ev.altKey && !ev.ctrlKey && !ev.metaKey) {
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
          if (focusedTaskbox.id === event?.id) handleClickTaskboxCheck();
        }

        if (ev.code === 'KeyT' && !ev.altKey && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          const childElement = element.childNodes[0] as HTMLElement;

          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true') ||
            (childElement && childElement.classList && childElement.classList.length > 0 && childElement.classList.contains('deadline-popover'))
          ) {
            return;
          }
          const el = document.querySelector(`[data-timeselectionid="${event!.id}"]`) as HTMLDivElement;
          if (el && event!.id === focusedTaskbox.id) {
            setTimeSelection(el);
            setSelectedTaskbox(event!.id!);
          }
        }

        if (ev.code === 'KeyF' && !ev.altKey && !ev.ctrlKey && !ev.metaKey) {
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          const popover = document.querySelector('.MuiPopover-paper') as HTMLElement;
          if (popover) return;

          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
          handleClickFocusMode(focusedTaskbox);
          element.blur();
        }

        if (ev.altKey && ev.shiftKey && ev.code === 'KeyF') {
          if (!focusedTaskbox) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          handleClickFocusMode(focusedTaskbox);
          element.blur();
        }

        if (ev.code === 'KeyL' && !ev.altKey && !ev.ctrlKey && !ev.metaKey) {
          if (!selectedTaskId) return;
          if (isEditingSubtask) return;
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
          if (tasks.find((task) => task.id === selectedTaskId)) {
            handleClickSubtaskContextMenu(selectedTaskId, 'LATER');
          }
        }

        if (ev.altKey && ev.shiftKey && ev.code === 'KeyL') {
          if (!selectedTaskId) return;
          if (isEditingSubtask) return;
          if (tasks.find((task) => task.id === selectedTaskId)) {
            handleClickSubtaskContextMenu(selectedTaskId, 'LATER');
          }
        }

        if (ev.code === 'ArrowDown' && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true') ||
            element.dataset.categoryId
          ) {
            return;
          }

          if (focusedTaskbox && focusedTaskbox.id === event.id) {
            if (isQuickCategory || isQuickProject) return;
            const index = events.findIndex((item) => item.id === focusedTaskbox.id);
            const nextTaskbox = events[index + 1];
            if (nextTaskbox) {
              handleClickTaskbox(nextTaskbox);
            } else {
              handleClickTaskbox(events[0]);
            }
          }

          if (multiSelectIds.length === 1) {
            if (!events.map((v) => v.id).includes(multiSelectIds[0])) return;
            const index = events.findIndex((item) => item.id === multiSelectIds[0]);
            const nextTaskbox = events[index + 1];
            if (nextTaskbox) {
              onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([nextTaskbox.id!]);
            } else {
              onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([events[0].id!]);
            }
          }
        }

        if (ev.code === 'ArrowUp' && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true') ||
            element.dataset.categoryId
          ) {
            return;
          }

          if (focusedTaskbox && focusedTaskbox.id === event.id) {
            if (isQuickCategory || isQuickProject) return;
            const index = events.findIndex((item) => item.id === focusedTaskbox.id);
            const prevTaskbox = events[index - 1];
            if (prevTaskbox) {
              handleClickTaskbox(prevTaskbox);
            } else {
              handleClickTaskbox(events[events.length - 1]);
            }
          }

          if (multiSelectIds.length === 1) {
            if (!events.map((v) => v.id).includes(multiSelectIds[0])) return;
            const index = events.findIndex((item) => item.id === multiSelectIds[0]);
            const prevTaskbox = events[index - 1];
            if (prevTaskbox) {
              onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([prevTaskbox.id!]);
            } else {
              onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([events[events.length - 1].id!]);
            }
          }
        }

        if (ev.code === 'ArrowDown' && ev.shiftKey) {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }

          if (focusedTaskbox) {
            const index = events.findIndex((item) => item.id === focusedTaskbox.id);
            const nextTaskbox = events[index + 1];
            if (index === -1 || !nextTaskbox) return;
            onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([focusedTaskbox.id!, nextTaskbox.id!]);
            return;
          }

          if (multiSelectIds.length > 0) {
            const index = events.findIndex((item) => item.id === multiSelectIds[multiSelectIds.length - 1]);
            const nextTaskbox = events[index + 1];
            if (index === -1 || !nextTaskbox) return;
            if (multiSelectIds.includes(nextTaskbox.id!)) {
              onChangeMultiSelectIds && onChangeMultiSelectIds(multiSelectIds[multiSelectIds.length - 1]);
            } else {
              onChangeMultiSelectIds && onChangeMultiSelectIds(nextTaskbox.id!);
            }
            return;
          }
        }

        if (ev.code === 'ArrowUp' && ev.shiftKey) {
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }

          if (focusedTaskbox) {
            const index = events.findIndex((item) => item.id === focusedTaskbox.id);
            const prevTaskbox = events[index - 1];
            if (index === -1 || !prevTaskbox) return;
            onChangeMultiSelectIdsList && onChangeMultiSelectIdsList([focusedTaskbox.id!, prevTaskbox.id!]);
            return;
          }

          if (multiSelectIds.length > 0) {
            const index = events.findIndex((item) => item.id === multiSelectIds[multiSelectIds.length - 1]);
            const prevTaskbox = events[index - 1];
            if (index === -1 || !prevTaskbox) return;
            if (multiSelectIds.includes(prevTaskbox.id!)) {
              onChangeMultiSelectIds && onChangeMultiSelectIds(multiSelectIds[multiSelectIds.length - 1]);
            } else {
              onChangeMultiSelectIds && onChangeMultiSelectIds(prevTaskbox.id!);
            }
            return;
          }
        }
      },
      [],
      { eventOptions: { passive: false } },
    );

    const showingTime = (event?: OutTaskboxDetailResponse, duration?: number) => {
      // 시간과 기간이 모두 제공되지 않으면 기본 메시지를 반환
      if (!event && duration === undefined) {
        return language === 'ko' ? '시간 설정' : 'Set time';
        // return 'Set time';
      }

      // 이벤트 객체가 제공된 경우
      if (event?.start?.datetime && event.end?.datetime) {
        const start = dayjs(event.start.datetime);
        const end = dayjs(event.end.datetime);
        duration = end.diff(start, 'minutes'); // 기간 계산
      }

      // 기간이 제공된 경우
      if (duration !== undefined) {
        const hour = Math.floor(duration / 60);
        const minute = duration % 60;
        const durationTime =
          language === 'ko'
            ? hour === 0
              ? `${minute}분 동안`
              : minute === 0
              ? `${hour}시간 동안`
              : `${hour}시간 ${minute}분 동안`
            : hour === 0
            ? `${minute}min`
            : minute === 0
            ? `${hour}hr`
            : `${hour}hr ${minute}min`;
        // const durationTime = hour === 0 ? `${minute}min` : minute === 0 ? `${hour}hr` : `${hour}hr ${minute}min`;

        // 이벤트 객체가 제공된 경우 시간 포맷과 결합하여 반환
        if (event?.start?.datetime) {
          const timeboxTime = `${convertDatetimeFormat(event.start.datetime, TIME_FORMAT_2)}, ${durationTime} `;
          return timeboxTime;
        }

        // 이벤트 객체가 제공되지 않은 경우 기간만 반환
        return durationTime;
      }

      return language === 'ko' ? '시간 설정' : 'Set time';
      // return 'Set time';
    };

    /** 하위업무 만들고 바로 새로고침 시 title 없는 하위업무 제거 */
    const handleDeleteSubtaskWithEmptyTitle = async () => {
      if (!event) return;
      if (!event.tasks) return;
      if (event.tasks.length === 0) return;

      const lastTextIndex = event.tasks
        .slice()
        .reverse()
        .findIndex((task) => task.content !== '');

      if (lastTextIndex === -1) {
        setTasks([]);
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: [] });
        await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { tasks: [] });
      } else {
        const index = event!.tasks!.length - lastTextIndex - 1;
        if (index + 1 !== event!.tasks!.length) {
          const filter = event!.tasks!.slice(0, index + 1);
          setTasks(filter);
          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: filter });
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { tasks: filter as UpdateTaskForTaskBox[] });
        }
      }
    };

    /** 태스크박스 외부 클릭 시 title 없는 하위업무 제거 */
    const handleDeleteSubtaskClickOutside = async () => {
      if (!event) return;
      if (focusModeTaskbox) return;

      const lastTextIndex = tasks
        .slice()
        .reverse()
        .findIndex((task) => task.content !== '');

      if (lastTextIndex === -1) {
        setTasks([]);
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: [] });
        await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { tasks: [] });
      } else {
        const index = tasks.length - lastTextIndex - 1;
        if (index + 1 !== tasks.length) {
          const filter = tasks.slice(0, index + 1);
          setTasks(filter);
          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: filter });
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { tasks: filter as UpdateTaskForTaskBox[] });
        }
      }
    };

    /** 태스크박스 완료 */
    const handleTaskboxDone = async (tasks: OutTaskboxDetailResponseForSubtask[]) => {
      if (!event) return;

      const updateTaskbox = async (done: boolean) => {
        try {
          onChangeWithoutFetchData &&
            onChangeWithoutFetchData({
              ...event,
              tasks,
              done,
            });
          if (taskboxDetailView) {
            setSyncTaskboxDetailView({
              ...event,
              done,
              tasks,
              syncType: 'taskbox',
            });
          }
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { done, tasks: tasks as UpdateTaskForTaskBox[] });
          if (dayjs(date).isBefore(dayjs(), 'date')) {
            onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
          }
        } catch (error) {
          toast.error(language === 'ko' ? '작업을 수행하는데에 실패하였습니다.' : 'Failed to perform the task.');
        }
      };

      if (event.done && tasks.length !== tasks.filter((task) => task.done).length) {
        await updateTaskbox(false);
      } else if (!event.done && tasks.length > 0 && tasks.length === tasks.filter((task) => task.done).length) {
        setTimeout(() => {
          updateTaskbox(true);
        }, 200);
      }
    };

    /** 태스크박스 상세창 닫기*/
    const handleCloseTimelineDetail = () => {
      setSelectedTaskbox(undefined);
      setTaskboxDetail(null);
    };

    /** 태스크박스 커서 focus*/
    const setFocusElement = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        el && setCaretToEnd(el);
      }, 0);
    };

    /** 타임라인 제목에서 blur 시 저장 */
    const handleBlurTitle = async (e: React.FocusEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const title = e.currentTarget.textContent;
      await new Promise((resolve) => setTimeout(resolve, 100));
      const categoryPopover = document.querySelector('.category-popover');
      const deadlinePopover = document.querySelector('.deadline-popover');
      const projectPopover = document.querySelector('.project-popover');
      if (categoryPopover || deadlinePopover || projectPopover) return;
      if (event.title !== title) {
        const updateTitle = event.project && title === event.project.title ? '' : title;
        const updateTaskbox: UpdateTaskbox = { title: updateTitle };
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, updateTaskbox);
        if (success) {
          language === 'ko'
            ? event.project
              ? toast.success('인스턴스를 수정하였습니다.')
              : toast.success('태스크박스를 수정하였습니다.')
            : event.project
            ? toast.success('Instance modified.')
            : toast.success('Taskbox modified.');
          // event.project ? toast.success('Instance modified.') : toast.success('Taskbox modified.');
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
          if (selectedProject) fetchProjects();
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        }
      }
    };

    /** 타임라인 시간 변경 */
    const handleChangeTime = async (
      startTime: string,
      endTime: string,
      timeDiff?: number,
      eventId?: string,
      isAllDay?: boolean,
      taskboxRecurrence?: InUpdateRoutineRuleOptionalTime,
    ) => {
      if (taskboxRecurrence && !event.routine) {
        // if (event.isRecurrence) {
        //   await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(eventId!, {
        //     ...taskboxRecurrence,
        //     isCancelled: taskboxRecurrence.recurrence.interval === 0,
        //   } as UpdateTaskBoxRecurrence);
        // } else {
        //   await createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost(eventId!, taskboxRecurrence);
        // }
        if (event.tasks && event.tasks.length >= 7) {
          language === 'ko'
            ? toast.error('7개 이상의 하위업무가 있는 태스크박스는 루틴으로 변경할 수 없습니다.')
            : toast.error(`Taskbox with more than 7 subtasks can't convert to routines.`);
          // toast.error(`Taskbox with more than 7 subtasks can't convert to routines.`);
          return;
        }

        try {
          await createRoutineFromTaskV1RoutineFromTaskTaskIdPost(eventId!, taskboxRecurrence);
          await new Promise((resolve) => setTimeout(resolve, 300));
          language === 'ko' ? toast.success('루틴으로 변경하였습니다.') : toast.success('Changed to routine.');
          // toast.success('Changed to routine.');
          fetchRoutines();
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
          }
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        } catch (e) {
          language === 'ko' ? toast.error('루틴으로 변경하는데 실패하였습니다.') : toast.error('Failed to change to routine.');
          // toast.error('Failed to change to routine.');
        }
      } else {
        const updateTaskbox: UpdateTaskbox = isAllDay
          ? {
              start: { date: dayjs(event.start?.date || event.start?.datetime, { utc: true }).format(DATE_FORMAT_4) },
              end: {
                date: dayjs(event.start?.date || event.start?.datetime, { utc: true })
                  .add(1, 'day')
                  .format(DATE_FORMAT_4),
              },
              durationMin: timeDiff,
              beforeId: null,
            }
          : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
          ? {
              start: { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) },
              end: { date: dayjs(endTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) },
              beforeId: null,
            }
          : {
              start: { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
              end: { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
            };

        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(eventId!, updateTaskbox);
        if (success) {
          language === 'ko'
            ? event.project
              ? toast.success('인스턴스를 수정하였습니다.')
              : toast.success('태스크박스를 수정하였습니다.')
            : event.project
            ? toast.success('Instance modified.')
            : toast.success('Taskbox modified.');
          // event.project ? toast.success('Instance modified.') : toast.success('Taskbox modified.');
          if (dayjs(endTime).diff(dayjs(startTime), 'day') > 0) {
            onChange && onChange(dayjs(startTime).toDate(), dayjs(endTime).toDate());
          } else {
            onChange && onChange(date!);
          }
          if (event.routine) {
            setTimeout(() => {
              setChangedRoutineInstanceId(eventId!);
            }, 1000);
          }
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        }
      }
    };

    /** 종일 업무 하이라이트 지정하기 */
    const handleClickHighlightTime = async (startTime: string, endTime: string) => {
      const updateTaskbox: UpdateTaskbox = {
        start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
        end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
        focus: true,
      };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, updateTaskbox);
      if (success) {
        onChange && onChange(date!);
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      }
    };

    /**태스크박스 시간 모달 열기 */
    const handleOpenTimeSelectionModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setTimeSelection(e.currentTarget);
      setSelectedTaskbox(event!.id!);
    };

    /**태스크박스 시간 모달 닫기 */
    const handleCloseTimeSelectionModal = () => {
      setTimeSelection(null);
      setSelectedTaskbox(undefined);
    };

    /** 태스크박스 하이라이트 시간 모달 열기 */
    const handleOpenHighlightTimeSelectionModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setHighlightTimeSelection(e.currentTarget);
      setSelectedTaskbox(event!.id!);
    };

    /**태스크박스 하이라이트 시간 모달 닫기 */
    const handleCloseHighlightTimeSelectionModal = () => {
      setHighlightTimeSelection(null);
      setSelectedTaskbox(undefined);
    };

    /** 태스크박스 삭제 */
    const handleDeleteTaskbox = async (event: OutTaskboxDetailResponse, params?: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      if (event.isRecurrence) {
        setDeleteRecurrenceWithKeydown(true);
        return;
      }

      try {
        if (event.tasks && event.tasks.length > 0 && event.project) {
          await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(event.project.id, { taskIds: event.tasks.map((task) => task.id) as string[] });
          fetchProjects();
        }
        setTaskboxDetail(null);
        setSelectedTaskbox(undefined);
        if (contextMenuPopover) setContextMenuPopover(null);
        if (contextTaskbox) setContextTaskbox(null);

        const remove = await removeTaskboxV1TaskboxesTaskboxIdDelete(event.id!);
        if (remove) {
          language === 'ko'
            ? toast.success(
                event.project
                  ? event.tasks && event.tasks.length > 0
                    ? '하위업무를 프로젝트로 옮겼습니다.'
                    : '선택한 인스턴스를 삭제했습니다.'
                  : '태스크박스를 삭제하였습니다.',
              )
            : toast.success(
                event.project ? (event.tasks && event.tasks.length > 0 ? 'Subtask moved to project.' : 'Selected instance deleted.') : 'Taskbox deleted.',
              );
          // toast.success(
          //   event.project ? (event.tasks && event.tasks.length > 0 ? 'Subtask moved to project.' : 'Selected instance deleted.') : 'Taskbox deleted.',
          // );
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        }
      } catch (error) {
        language === 'ko' ? toast.error('태스크박스를 삭제하지 못했습니다.') : toast.error('Failed to delete taskbox.');
        // toast.error('Failed to delete taskbox.');
      }
    };

    const handleDeleteRecurrence = async (target: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      if (selectedTaskbox) setSelectedTaskbox(undefined);
      if (taskboxDetail) setTaskboxDetail(null);
      if (contextTaskbox) setContextTaskbox(null);
      setDeleteRecurrenceWithKeydown(false);
      const remove = await removeTaskboxV1TaskboxesTaskboxIdDelete(event!.id!, target);
      if (remove) {
        language === 'ko' ? toast.success('반복 업무를 삭제하였습니다.') : toast.success('Recurring task deleted.');
        // toast.success('Recurring task deleted.');
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
      }
    };

    /** 타임라인 키다운 로직 */
    const handleTimelineKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const filteredTimeline = events;
      const title = e.currentTarget.textContent;
      const filteredTimelineIndex = filteredTimeline.map(({ id }) => id).indexOf(event.id);

      if (e.key === 'Enter') {
        if (event.title === title) {
          e.preventDefault();
          const newTask: CreateTaskboxTask = {
            id: generateTaskId(),
            beforeId: null,
            content: '',
          };
          const taskList: CreateTaskboxTask[] = [newTask, ...(tasks as CreateTaskboxTask[])];
          setTasks(taskList);
          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: taskList });
          // onChangeSubtask && onChangeSubtask(event.id!, taskList);
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${newTask.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(newTask.id);
          }
          handleSelectedTaskId && handleSelectedTaskId(newTask.id);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        } else {
          e.preventDefault();
          if (e.nativeEvent.isComposing) return;
          const updateTitle = event.project && title === event.project.title ? '' : title;
          const updateTaskbox: UpdateTaskbox = { title: updateTitle };
          const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, updateTaskbox);
          if (success) {
            language === 'ko'
              ? event.project
                ? toast.success('인스턴스를 수정하였습니다.')
                : toast.success('태스크박스를 수정하였습니다.')
              : event.project
              ? toast.success('Instance modified.')
              : toast.success('Taskbox modified.');
            // event.project ? toast.success('Instance modified.') : toast.success('Taskbox modified.');
            if (event.type === 'TERM_TASK') {
              onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
            } else {
              onChange && onChange(date!);
            }
            if (selectedProject) fetchProjects();
            if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
          }

          setFocusElement(event.id!);
        }
        return;
      }

      if (e.key === 'Backspace') {
        if (title!.length !== 0) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        const prevTimeline = filteredTimelineIndex !== 0 ? filteredTimeline[filteredTimelineIndex - 1] : filteredTimeline[filteredTimeline.length - 1];
        if (prevTimeline) {
          if (prevTimeline.tasks && prevTimeline.tasks.length !== 0) {
            if (prevTimeline.type === 'TERM_TASK') {
              setFocusElement(
                `${prevTimeline.tasks![prevTimeline.tasks!.length - 1].id!}-${prevTimeline.matchDate?.findIndex(
                  (v) => v === dayjs(date).format(DATE_FORMAT_4),
                )}`,
              );
            } else {
              setFocusElement(prevTimeline.tasks![prevTimeline.tasks!.length - 1].id!);
            }
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(prevTimeline.tasks![prevTimeline.tasks!.length - 1].id!);
            }, 0);
          } else {
            if (prevTimeline.type === 'TERM_TASK') {
              setFocusElement(`${prevTimeline.id!}-${prevTimeline.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(prevTimeline.id!);
            }
          }
          handleClickTaskbox(prevTimeline);
        }
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (event.tasks && event.tasks.length !== 0) {
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${event.tasks[0].id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(event.tasks[0].id!);
          }
          handleSelectedTaskId && handleSelectedTaskId(event.tasks[0].id!);
        } else {
          const nextTimeline = filteredTimeline[filteredTimelineIndex + 1];
          if (nextTimeline) {
            if (nextTimeline.type === 'TERM_TASK') {
              setFocusElement(`${nextTimeline.id!}-${nextTimeline.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(nextTimeline.id!);
            }
            handleClickTaskbox(nextTimeline);
          } else {
            if (filteredTimeline[0].type === 'TERM_TASK') {
              setFocusElement(`${filteredTimeline[0].id!}-${filteredTimeline[0].matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(filteredTimeline[0].id!);
            }
            handleClickTaskbox(filteredTimeline[0]);
          }
        }
        return;
      }

      if (e.key === '#') {
        if (event.id === focusedTaskbox!.id!) {
          const active = document.activeElement as HTMLElement;
          setTimeout(() => {
            active.blur();
            setIsQuickCategory(true);
            setCategoryAnchorEl(refTaskboxInput.current);
          }, 50);
        }
      }

      if (e.key === '$') {
        if (event.id === focusedTaskbox!.id!) {
          const active = document.activeElement as HTMLElement;
          setTimeout(() => {
            active.blur();
            setIsQuickDeadline(true);
            setDeadlineAnchorEl(refTaskboxInput.current);
          }, 50);
        }
      }

      if (e.key === '>' && !event.isRecurrence) {
        if (!event.project && event.id === focusedTaskbox!.id!) {
          const active = document.activeElement as HTMLElement;
          setTimeout(() => {
            active.blur();
            setIsQuickProject(true);
            setProjectListAnchorEl(refTaskboxInput.current);
          }, 50);
        }
      }
    };

    /** tasks 로직 */
    const createTask = (newTask: Tasks) => {
      setTasks([...tasks, newTask]);
    };

    const insertTask = (index: number, newTask: Tasks) => {
      tasks.splice(index + 1, 0, newTask);
      setTasks([...tasks]);
      onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks });
      // onChangeSubtask && onChangeSubtask(event.id!, [...tasks]);
    };

    const removeTask = (taskId: string) => {
      setTasks(tasks.filter(({ id }) => id !== taskId));
    };

    const debouncedOnChangeTermTask = useCallback(
      debounce(() => {
        onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
      }, 5000),
      [],
    );

    const debouncedSaveTasks = useCallback(
      debounce(async (tasks) => {
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { tasks: tasks as UpdateTaskForTaskBox[] });
        } catch (error) {
          toast.error(language === 'ko' ? '하위업무 저장에 실패하였습니다.' : 'Failed to save task.');
        }
      }, 2000),
      [],
    );

    const debounceChangeEditingSubtask = useCallback(
      debounce(() => {
        setIsEditingSubtask(false);
      }, 1000),
      [],
    );

    useEffect(() => {
      return () => {
        debouncedSaveTasks.cancel();
        debounceChangeEditingSubtask.cancel();
      };
    }, []);

    /** 태스크 키다운 로직 */
    const handleTaskKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, taskId: string, index: number) => {
      if (delayRecommendTask) {
        e.preventDefault();
        return;
      }

      if (isRemoveTextAndRecommend) setIsRemoveTextAndRecommend(false);

      const text = e.currentTarget.textContent || '';
      if (text !== '') setIsRecommendTask(false);

      // 인스턴스 타이핑 시작하면 추천 태스크 숨김
      if (focusedTaskbox?.project && isPrintableKey(e.code)) setIsInstanceTyping(true);

      if (e.key === 'Enter' && event) {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        const caret = getCaretPosition(e.target);
        const taskIndex = tasks.map(({ id }) => id).indexOf(taskId);
        const createNewTask = (beforeId: string | null, content: string) => ({
          id: generateTaskId(),
          content,
          beforeId,
        });

        const handleFocusAndSelect = (newTaskId: string) => {
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${newTaskId}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(newTaskId);
          }
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTaskId);
          }, 0);
        };

        try {
          if (caret === text.length) {
            if (isInstanceTyping) {
              setTimeout(() => setIsInstanceTyping(false), 30);
            }
            e.currentTarget.blur();
            const newTask = createNewTask(taskId, '');
            insertTask(taskIndex, newTask);
            handleFocusAndSelect(newTask.id);
            // await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
          } else if (caret === 0) {
            e.preventDefault();
            if (isInstanceTyping) {
              setTimeout(() => setIsInstanceTyping(false), 30);
            }

            const beforeId = tasks[taskIndex - 1]?.id || null;
            e.currentTarget.blur();
            const newTask = createNewTask(beforeId, '');
            insertTask(taskIndex - 1, newTask);
            handleFocusAndSelect(newTask.id);
            // await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
          } else {
            e.preventDefault();
            const prevText = text.slice(0, caret);
            const nextText = text.slice(caret);
            const targetTask = { ...tasks[taskIndex], content: prevText };
            const newTask = createNewTask(taskId, nextText);

            e.currentTarget.blur();
            tasks[taskIndex] = targetTask;
            tasks.splice(index + 1, 0, newTask);
            setTasks([...tasks]);
            onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks });
            handleFocusAndSelect(newTask.id);
            // await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event.id!, taskId, targetTask);
            // await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
          }
        } catch (error) {
          toast.error(language === 'ko' ? '하위업무 작업에 실패하였습니다.' : 'Failed to work on subtasks.');
        }
      }

      if (e.key === 'Backspace' && event) {
        if (e.repeat) {
          if (text.length === 0) e.preventDefault();
          return;
        }

        const caret = getCaretPosition(e.target);
        const taskIndex = tasks.map(({ id }) => id).indexOf(taskId);

        //커서가 맨 앞에 있을 시 현재 하위업무 삭제하고 이전 하위업무와 합침
        if (caret === 0 && text.length > 0) {
          if (taskIndex === 0) return;
          const prevTask = tasks[taskIndex - 1];
          const newPrevTask = { ...prevTask, content: prevTask.content! + text };
          tasks[taskIndex - 1] = newPrevTask;
          const newTaskList = tasks.filter(({ id }) => id !== taskId);
          setTasks([...newTaskList]);
          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: newTaskList });
          // onChangeSubtask && onChangeSubtask(event.id!, newTaskList);
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${newPrevTask.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(newPrevTask.id!);
          }
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newPrevTask.id!);
          }, 0);

          // await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event.id!, prevTask.id!, newPrevTask);
          // await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, taskId);
        } else {
          if (isInstanceTyping && text.length === 1) {
            setTimeout(() => {
              setIsInstanceTyping(false);
            }, 30);
          }

          if (focusedTaskbox?.project && text.length === 1 && tasks.find(({ id }) => id === taskId)?.content !== '') {
            setIsRemoveTextAndRecommend(true);
          }

          if (text.length !== 0) return;

          const findIndex = index === 0 ? index + 1 : index - 1;
          const task = tasks[findIndex];
          if (task) {
            if (event.type === 'TERM_TASK') {
              setFocusElement(`${task.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(task.id!);
            }
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(task.id!);
            }, 0);
          } else {
            if (event.type === 'TERM_TASK') {
              setFocusElement(`${event.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(event.id!);
            }
            handleSelectedTaskId && handleSelectedTaskId('');
          }
          const changeTasks = tasks.filter(({ id }) => id !== taskId);
          removeTask(taskId);
          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: changeTasks });
          // onChangeSubtask && onChangeSubtask(event.id!, changeTasks);
          // await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, taskId);
        }
      }

      if (e.key === 'ArrowDown') {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        const filteredTimeline = events!;
        if (index === tasks.length - 1) {
          const timeboxIndex = filteredTimeline!.map(({ id }) => id).indexOf(event!.id);
          if (filteredTimeline[timeboxIndex + 1]) {
            if (filteredTimeline[timeboxIndex + 1].type === 'TERM_TASK') {
              setFocusElement(
                `${filteredTimeline[timeboxIndex + 1].id!}-${filteredTimeline[timeboxIndex + 1].matchDate?.findIndex(
                  (v) => v === dayjs(date).format(DATE_FORMAT_4),
                )}`,
              );
            } else {
              setFocusElement(filteredTimeline[timeboxIndex + 1].id!);
            }
            handleClickTaskbox(filteredTimeline[timeboxIndex + 1]);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          } else {
            if (filteredTimeline[0].type === 'TERM_TASK') {
              setFocusElement(`${filteredTimeline[0].id!}-${filteredTimeline[0].matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(filteredTimeline[0].id!);
            }
            handleClickTaskbox(filteredTimeline[0]);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          }
        } else {
          const task = tasks[index + 1];
          if (task) {
            if (event.type === 'TERM_TASK') {
              setFocusElement(`${task.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            } else {
              setFocusElement(task.id!);
            }
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(task.id!);
            }, 50);
          }
        }
        return;
      }

      if (e.key === 'ArrowUp') {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        if (event.type === 'TERM_TASK') {
          if (index === 0) {
            setFocusElement(`${event.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          } else {
            const task = tasks[index - 1];
            if (task) {
              setFocusElement(`${task.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
              setTimeout(() => {
                handleSelectedTaskId && handleSelectedTaskId(task.id!);
              }, 50);
            }
            e.preventDefault();
          }
          return;
        } else {
          if (index === 0) {
            setFocusElement(event!.id!);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          } else {
            const task = tasks[index - 1];
            if (task) {
              setFocusElement(task.id!);
              setTimeout(() => {
                handleSelectedTaskId && handleSelectedTaskId(task.id!);
              }, 50);
            }
            e.preventDefault();
          }
          return;
        }
      }

      if (event.project && text === '') {
        if (e.key === 'ArrowRight') {
          setRecommendTaskIndex((prev) => (prev >= projectSubtasks.length - 1 ? 0 : prev + 1));
        }

        if (e.key === 'ArrowLeft') {
          setRecommendTaskIndex((prev) => (prev > 0 ? prev - 1 : projectSubtasks.length - 1));
        }

        if (e.key === 'Tab') {
          e.preventDefault();
          if (projectSubtasks.length === 0) return;
          if (delayRecommendTask) return;
          if (event.project) {
            setIsRecommendTask(true);
          }

          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId: taskId,
          };

          insertTask(tasks.map(({ id }) => id).indexOf(taskId), newTask);
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${newTask.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(newTask.id);
          }
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
            if (event.project && projectSubtasks.length > 0) setDelayRecommendTask(true);
          }, 50);
          // await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        }
      }

      if (event.type === 'TERM_TASK') {
        debouncedOnChangeTermTask();
      }

      // if (event.project) {
      //   debouncedOnChangeProjectTask();
      // }
    };

    const handleInputTask = (e: React.FormEvent<HTMLDivElement>, taskId: string) => {
      setIsEditingSubtask(true);
      debounceChangeEditingSubtask();
      debouncedSaveTasks(tasks);

      if (selectedProject) {
        const target = e.currentTarget as HTMLDivElement;
        const newTasks = event.tasks!.map((task) => {
          if (task.id === taskId) {
            return { ...task, content: target.textContent || '' };
          } else {
            return task;
          }
        });
        debouncedSyncInstance({
          ...event,
          tasks: newTasks,
          syncType: 'taskbox',
        });
      } else {
        debouncedSyncInstance(null);
      }
    };

    const handleSelectedTaskId = (id: string) => {
      onClickSubtask?.(id);
    };

    /** 업무를 추가해주세요 클릭 시 테스크 생성 */
    const handleClickTaskAdd = async (event: OutTaskboxDetailResponse) => {
      handleClickTaskbox(event);
      const prevTask = tasks[tasks.length - 1];
      const newTask: Tasks = {
        id: generateTaskId(),
        content: '',
        beforeId: prevTask && prevTask.id,
      };
      createTask(newTask);
      const taskList = [...tasks, newTask];
      onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: taskList });
      // onChangeSubtask && onChangeSubtask(event.id!, taskList);
      if (event.type === 'TERM_TASK') {
        setFocusElement(`${newTask.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
      } else {
        setFocusElement(newTask.id!);
      }
      setTimeout(() => {
        handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
      }, 0);
      // event && (await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask));
    };

    /** Task 완료 여부 저장 */
    const handleClickSubtaskCheckbox = async (taskIndex: number) => {
      if (!event) return;

      const originEvent = { ...event };
      const targetTask = { ...tasks[taskIndex], done: !tasks[taskIndex].done };
      tasks[taskIndex] = targetTask;

      const taskboxDone = tasks.filter((task) => task.done).length === tasks.length;

      onChangeWithoutFetchData &&
        onChangeWithoutFetchData({
          ...event,
          tasks,
          done: taskboxDone,
        });

      if (taskboxDetailView) {
        setSyncTaskboxDetailView({
          ...event,
          tasks,
          done: taskboxDone,
          syncType: 'taskbox',
        });
      }

      try {
        debouncedSaveTasks(tasks);
        // await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event.id!, targetTask.id!, { done: targetTask.done });
      } catch (e) {
        language === 'ko' ? toast.error('하위업무를 수정하는데 실패하였습니다.') : toast.error('Failed to update subtask.');
        onChangeWithoutFetchData && onChangeWithoutFetchData(originEvent);
      }
    };

    /** focus out 시 변경된 하위업무 저장 */
    const handleBlurSubtask = async (e: React.FocusEvent<HTMLDivElement>, taskIndex: number) => {
      setIsEditingSubtask(true);
      debounceChangeEditingSubtask();
      const content = e.currentTarget.textContent!;
      const isBefore = dayjs(date).isBefore(dayjs(), 'date');

      if (projectSubtasks.length > 0 && isRecommendTask && tasks[taskIndex].content === '' && event.project) {
        if (recommendTaskIndex === projectSubtasks.length - 1) {
          setRecommendTaskIndex(0);
        }
        setProjectSubtasks(projectSubtasks.filter((subtask) => subtask.id !== projectSubtasks[recommendTaskIndex].id));

        const targetTask = { ...tasks[taskIndex], id: projectSubtasks[recommendTaskIndex].id!, content: projectSubtasks[recommendTaskIndex].title };
        tasks[taskIndex] = targetTask;
        setTasks([...tasks]);
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: tasks });
        // onChangeSubtask && onChangeSubtask(event.id!, tasks);
        setIsRecommendTask(false);

        const assignTask = {
          tasks: [projectSubtasks[recommendTaskIndex].id!],
          beforeId: tasks[taskIndex - 1] && tasks[taskIndex - 1].id ? tasks[taskIndex - 1].id! : null,
        };

        try {
          await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, tasks[taskIndex].id!);
          await assignTasksV1TaskboxesTaskboxIdAssignPost(event.id!, assignTask);
          // if (taskboxDetailView || selectedProject || selectedRoutine) setIsLoadingSaveSubtask(true);
          // debouncedSaveTasks(tasks);
          fetchProjects();
        } catch (e) {
          toast.error(language === 'ko' ? '' : '');
        }
        return;
      }

      if (isBefore) {
        if (!isEqual(tasks[taskIndex].content, content) || isEqual(tasks[taskIndex].content, '')) {
          const targetTask = { ...tasks[taskIndex], content, done: true };
          const filteredTasks = tasks.filter((task) => task.id !== tasks[taskIndex].id);
          const taskboxDone = filteredTasks.filter((task) => task.done).length === filteredTasks.length;
          tasks[taskIndex] = targetTask;
          setTasks([...tasks]);

          onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: tasks, done: taskboxDone });
          // onChangeSubtask && onChangeSubtask(event.id!, tasks);
          //디테일창 닫혔을 때 서브태스크 한번 저장하기 위함.
          if (taskboxDetailView || selectedProject || selectedRoutine)
            setChangedSubtaskTaskbox({
              ...event,
              tasks,
            });
          debouncedSaveTasks(tasks);
          return;
        }
      }

      if (!isEqual(tasks[taskIndex].content, content)) {
        const targetTask = { ...tasks[taskIndex], content };
        tasks[taskIndex] = targetTask;
        setTasks([...tasks]);
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: tasks });
        // onChangeSubtask && onChangeSubtask(event.id!, tasks);
        //디테일창 닫혔을 때 서브태스크 한번 저장하기 위함.
        if (taskboxDetailView || selectedProject || selectedRoutine)
          setChangedSubtaskTaskbox({
            ...event,
            tasks,
          });
        debouncedSaveTasks(tasks);
        // await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event.id!, tasks[taskIndex].id!, targetTask);
      }
    };

    /**이슈 상세창 열기 */
    const handleClickIssueDetail = (id: string) => {
      setClickedIssue(id);
      // setSelectedTasks(event?.tasks ? event.tasks : []);
    };

    /**이슈 상세창 닫기 */
    const handleClickCancelIssue = () => {
      setClickedIssue(undefined);
    };

    /**이슈 상세창 저장 */
    const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
      if (!value) return;

      await updateIssueV1WorksIssuesIssueIdPut(id, { content: value.content, description: value.description, dueDate: value.dueDate, tasks: value.tasks });

      // if (tasks.filter((task) => task.simple === true).length > 0) {
      //   const selectedTask = tasks.filter((task) => selectedTaskIds?.includes(task.id));
      //   const seletedId = selectedTask.map((item) => item.id);

      //   const addId = selectedTaskIds!.filter((id) => !seletedId.includes(id));
      //   const addTask: UpdateTaskForTaskBox[] = value.tasks!.filter((v) => addId.includes(v.id!)).map(({ id, content }) => ({ id: id!, content }));

      //   const renewalTask = [...selectedTask, ...tasks.filter((v) => v.simple === true)];
      //   const newTask: UpdateTaskForTaskBox[] = [...renewalTask, ...addTask];

      //   await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // } else {
      //   const newTask = value.tasks!.filter((task) => selectedTaskIds?.includes(task.id!)).map(({ id, content }) => ({ id: id!, content }));
      //   await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // }

      // onChange();
      setClickedIssue(undefined);
    };

    /**태스크박스 완료 체크 */
    const handleClickTaskboxCheck = async () => {
      if (event) {
        const originEvent = { ...event };
        const updateTaskbox: UpdateTaskbox = { done: !event.done, tasks: tasks.map((task) => ({ ...task, done: !event.done })) as UpdateTaskForTaskBox[] };
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, done: !event.done, tasks: tasks.map((task) => ({ ...task, done: !event.done })) });
        if (taskboxDetailView)
          setSyncTaskboxDetailView({
            ...event,
            done: !event.done,
            tasks: tasks.map((task) => ({ ...task, done: !event.done })),
            syncType: 'taskbox',
          });
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, updateTaskbox);
        } catch (e) {
          toast.error(language === 'ko' ? '태스크박스를 수정하는데에 실패하였습니다.' : 'Failed to update the taskbox.');
          onChangeWithoutFetchData && onChangeWithoutFetchData(originEvent);
          setSyncTaskboxDetailView({ ...originEvent, syncType: 'taskbox' });
        }

        // if (success) {
        //   onChangeWithoutFetchData && onChangeWithoutFetchData(success);
        //   if (event.type === 'TERM_TASK') {
        //     onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        //   } else {
        //     onChange && onChange(date!);
        //   }
        //   if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        //   if (taskboxDetailView)
        //     setSyncTaskboxDetailView({
        //       ...event,
        //       done: !taskboxDone,
        //       tasks: tasks.map((task) => ({ ...task, done: !taskboxDone })),
        //       syncType: 'taskbox',
        //     });
        // }
      }
    };

    /** 프로젝트 일부 완료하기 */
    const handleClickInstanceContinue = async () => {
      setInprogressPopover(null);
      if (!event) return;

      const targetProject = projects.find((project) => project.id === event.project?.id);
      if (!targetProject) return;

      const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
      const index = instances.findIndex((instance) => instance.id === event.id);
      const nextInstance = instances[index + 1];

      const assignTasks = tasks.filter((task) => !task.done).map((task) => task.id!);

      try {
        if (nextInstance) {
          const nextInstanceTasks = nextInstance.tasks;
          await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
            beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
            tasks: assignTasks,
          });
          language === 'ko'
            ? toast.success('미완료 업무가 프로젝트의 다음 인스턴스로 이동했습니다.')
            : toast.success('Incompleted tasks moved to the next project instance.');
          // toast.success('Incompleted tasks moved to the next project instance.');
        } else {
          await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
            taskIds: assignTasks,
          });
          language === 'ko' ? toast.success('미완료 업무가 프로젝트의 미할당으로 이동했습니다.') : toast.success('Incompleted tasks moved to unassigned.');
          // toast.success('Incompleted tasks moved to unassigned.');
        }
      } catch (error) {
        language === 'ko' ? toast.error('일부 완료에 실패했습니다.') : toast.error('Failed to complete part of the task.');
        // toast.error('Failed to complete part of the task.');
      }

      onChange?.(date!);
      fetchProjects();
    };

    /** 태스크박스 날짜 변경 */
    const handleChangeTaskboxDate = async (taskboxDate: Date | null, isAllDay = false, dateType: DateType | null = null) => {
      if (!event) return;
      if (taskboxDate) {
        const start = isAllDay
          ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
          : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
        const end = isAllDay
          ? { date: dayjs(taskboxDate).add(1, 'day').format(DATE_FORMAT_4) }
          : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

        const updateAndNotify = async (successMessage: string) => {
          try {
            await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { start, end });
            toast.success(successMessage);
          } catch (error) {
            language === 'ko' ? toast.error('태스크박스를 수정할 수 없습니다.') : toast.error('Failed to modify the taskbox.');
            // toast.error('Failed to modify the taskbox.');
          }
          onChange && onChange(date!, undefined, true);
          await new Promise((resolve) => setTimeout(resolve, 100));
          onChange &&
            onChange(
              dateType === 'tomorrow'
                ? dayjs(date).add(1, 'day').toDate()
                : dateType === 'nextWeek'
                ? dayjs(date).add(1, 'week').day(1).toDate()
                : dayjs(taskboxDate).toDate(),
              undefined,
              true,
            );
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        };

        if (event.project) {
          const targetBoardTaskboxes = await readTaskboxesV1TaskboxesGet({
            start_date: dayjs(taskboxDate).format(DATE_FORMAT_4),
            end_date: dayjs(taskboxDate).add(1, 'day').format(DATE_FORMAT_4),
          });

          const sameProjectInstance = targetBoardTaskboxes.find((taskbox) => taskbox.project?.id === event.project?.id && taskbox.allDay);

          if (sameProjectInstance && sameProjectInstance.start?.date) {
            try {
              await assignTasksV1TaskboxesTaskboxIdAssignPost(sameProjectInstance.id!, {
                tasks: [event.id!],
              });
              language === 'ko'
                ? toast.success('해당 날짜에 동일한 프로젝트의 인스턴스가 존재해 하나로 합쳤어요.')
                : toast.success('The same project instance already exists on this date and has been merged.');
              // toast.success('The same project instance already exists on this date and has been merged.');
            } catch (error) {
              language === 'ko' ? toast.error('인스턴스를 수정할 수 없습니다.') : toast.error('Failed to modify the instance.');
              // toast.error('Failed to modify the instance.');
            }
            if (dateType) {
              onChange && onChange(date!, undefined, true);
              await new Promise((resolve) => setTimeout(resolve, 100));
              onChange &&
                onChange(
                  dateType === 'tomorrow'
                    ? dayjs(date).add(1, 'day').toDate()
                    : dateType === 'nextWeek'
                    ? dayjs(date).add(1, 'week').day(1).toDate()
                    : dayjs(taskboxDate).toDate(),
                  undefined,
                  true,
                );
              if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
            }
          } else {
            await updateAndNotify(
              language === 'ko'
                ? `인스턴스를 ${
                    dateType === 'tomorrow' ? '다음 날로' : dateType === 'nextWeek' ? '다음주 월요일로' : `${dayjs(taskboxDate).format(DATE_FORMAT_5)}로`
                  } 옮겼습니다.`
                : `Instance moved to ${
                    dateType === 'tomorrow' ? 'the next day' : dateType === 'nextWeek' ? 'the next Monday' : dayjs(taskboxDate).format(DATE_FORMAT_5)
                  }.`,
              // `Instance moved to ${
              //   dateType === 'tomorrow' ? 'the next day' : dateType === 'nextWeek' ? 'the next Monday' : dayjs(taskboxDate).format(DATE_FORMAT_5)
              // }.`,
            );
          }
        } else {
          await updateAndNotify(
            language === 'ko'
              ? `태스크박스를 ${
                  dateType === 'tomorrow' ? '다음 날로' : dateType === 'nextWeek' ? '다음주 월요일로' : `${dayjs(taskboxDate).format(DATE_FORMAT_5)}로`
                } 옮겼습니다.`
              : `Taskbox moved to ${
                  dateType === 'tomorrow' ? 'the next day' : dateType === 'nextWeek' ? 'the next Monday' : dayjs(taskboxDate).format(DATE_FORMAT_5)
                }.`,
            // `Taskbox moved to ${
            //   dateType === 'tomorrow' ? 'the next day' : dateType === 'nextWeek' ? 'the next Monday' : dayjs(taskboxDate).format(DATE_FORMAT_5)
            // }.`,
          );
        }
      } else {
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { workSectionType: 'someday' });
          language === 'ko' ? toast.success('태스크박스를 나중에로 옮겼습니다.') : toast.success('Taskbox moved to Later.');
          // toast.success('Taskbox moved to Later.');
        } catch (error) {
          language === 'ko' ? toast.error('태스크박스를 수정할 수 없습니다.') : toast.error('Failed to modify the taskbox.');
          // toast.error('Failed to modify the taskbox.');
        }
        onChange && onChange(date!, undefined, true);
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        setTimeout(() => {
          fetchTasks();
        }, 100);
      }
    };

    /**태스크박스 드래그 시작될 때 로직 */
    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      if (event.type === 'TERM_TASK') {
        language === 'ko' ? toast.error('종료일이 지정된 태스크박스는 타임박싱할 수 없어요.') : toast.error(`Taskbox with end date can't be time-boxed.`);
        // toast.error(`Taskbox with end date can't be time-boxed.`);
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      onChangeTaskboxImage?.(event!);

      const refTaskboard = ref as React.RefObject<HTMLDivElement>;
      const taskboardElement = refTaskboard?.current;

      if (!taskboardElement) return;

      const dragImageElement = taskboardElement.querySelector('#taskbox-drag-image');
      if (dragImageElement) {
        e.dataTransfer.setDragImage(dragImageElement, 40, 3);
      }

      if (event) {
        setDragContext({
          type: event.project ? 'instance' : 'taskbox',
          view: 'taskboard',
          id: event.id,
          title: event.title!,
          data: event.tasks,
          date: date!,
          projectId: event.project?.id ? event.project.id : undefined,
        });
      }
    };

    /**서브태스크 상세창 닫는 로직 */
    const handleCloseSubtaskDetail = () => {
      setSubtaskId(undefined);
      setSubtaskDetail(null);
    };

    /**서브태스크 detail 로직 */
    const handleClickSubtaskContextMenu = async (taskId: string, type: SubtaskContextMenu) => {
      if (type === 'CONVERT_TO_TASKBOX') {
        setSubtaskDetail(null);
        removeTask(taskId);
        const success = await subtaskToTaskV1TaskboxesSubtasksTaskTypePatch({
          subtaskIds: [taskId],
          start: { date: dayjs(date).format(DATE_FORMAT_4) },
          end: { date: dayjs(date).format(DATE_FORMAT_4) },
        });
        if (success) {
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        }
      } else if (type === 'LATER') {
        setSubtaskDetail(null);
        removeTask(taskId);
        if (event.project) {
          const targetProject = projects.find((project) => project.id === event.project?.id);
          if (!targetProject) return;

          const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
          const index = instances.findIndex((instance) => instance.id === event.id);
          const nextInstance = instances[index + 1];

          try {
            if (nextInstance) {
              const nextInstanceTasks = nextInstance.tasks;
              await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
                beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
                tasks: [taskId],
              });
              language === 'ko' ? toast.success('프로젝트의 다음 인스턴스로 이동했습니다.') : toast.success('Moved to the next instance of the project.');
              // toast.success('Moved to the next instance of the project.');
            } else {
              await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
                taskIds: [taskId],
              });
              language === 'ko' ? toast.success('프로젝트의 미할당으로 이동했습니다.') : toast.success('Moved to unassigned.');
              // toast.success('Moved to unassigned.');
            }
          } catch (error) {
            language === 'ko' ? toast.error('일부 완료에 실패했습니다.') : toast.error('Failed to complete part of the task.');
            // toast.error('Failed to complete part of the task.');
          }

          onChange?.(date!);
          if (nextInstance) onChange?.(dayjs(nextInstance.matchDate![0]).toDate());
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes?.();
          fetchProjects();
        } else {
          await subtaskToTaskV1TaskboxesSubtasksTaskTypePatch({ subtaskIds: [taskId] });
          setTimeout(() => {
            if (event.type === 'TERM_TASK') {
              onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
            } else {
              onChange && onChange(date!);
            }
            if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
            fetchTasks();
          }, 100);
        }
      } else if (type === 'DELETE') {
        const changeTasks = tasks.filter(({ id }) => id !== taskId);
        setSubtaskDetail(null);
        removeTask(taskId);
        onChangeWithoutFetchData && onChangeWithoutFetchData({ ...event, tasks: changeTasks });
        // onChangeSubtask && onChangeSubtask(event.id!, changeTasks);
        await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event!.id!, taskId);
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!);
        }
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      } else if (type === 'CATEGORY') {
      } else if (type === 'DEADLINE') {
      }
    };

    /**태스크박스에서 캘린더로의 드래그 시작되는 것 알리는 로직  */
    const handleDragToCalendarStart = () => {
      // 드래그 핸들에서 드래그 시작 시 호출되는 콜백 함수
      setIsDragging(true);
    };

    /**태스크박스에서 캘린더로의 드래그 끝나는 것 알리는 로직 */
    const handleDragEnd = () => {
      setDragContext(null);
    };

    /**태스크박스 락인/락아웃 로직 */
    const handleLockInOutButton = async (lock: boolean) => {
      if (calendarList.length === 0) {
        setOpenConnectCalendarDialog(true);
        return;
      }
      if (event?.recurrence || event?.routine) return;
      setTaskboxDetail(null);
      setSelectedTaskbox(undefined);

      try {
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
          lockedIn: !lock,
        });

        if (success) {
          const message = lock
            ? language === 'ko'
              ? '구글 캘린더에서 할 일을 표시 해제했습니다.'
              : 'Task unmarked on Google Calendar.'
            : language === 'ko'
            ? '구글 캘린더에 할 일을 표시했습니다.'
            : 'Task marked on Google Calendar.';
          // const message = lock ? 'Task unmarked on Google Calendar.' : 'Task marked on Google Calendar.';
          toast.success(message);

          if (event.type === 'TERM_TASK') {
            const [startDate, endDate] = [event.matchDate?.[0], event.matchDate?.[event.matchDate?.length - 1]];
            onChange && onChange(dayjs(startDate).toDate(), dayjs(endDate).toDate());
          } else {
            onChange && onChange(date!);
          }

          if (taskboxDetailView) {
            setSyncTaskboxDetailView({
              ...event,
              lockedIn: !lock,
              syncType: 'taskbox',
            });
          }
        }
      } catch (error) {
        language === 'ko' ? toast.error('태스크박스를 수정할 수 없습니다.') : toast.error('Failed to modify the taskbox.');
      }
    };

    /**태스크박스에 태스크 들어왔을 때 로직 */
    const handleDragEnterToTaskbox = () => {
      const taskbox = events.find((v) => v.id === dragContext?.id);
      if (taskbox?.isRecurrence) return;
      if (!dragContext || dragContext.id === event?.id) return;
      if (dragContext.type === 'multi-taskbox' && dragContext.data.includes(event!.id!)) return;
      if (['task', 'taskbox', 'multi-taskbox', 'SOMEDAY_TASK'].includes(dragContext!.type!)) {
        setDraggingToTaskbox(true);
        return;
      }
      if (dragContext.type === 'instance' || dragContext.type === 'project-subtask' || dragContext.type === 'project-multi-subtask') {
        const isSameProject = dragContext?.projectId === event?.project?.id;
        if (isSameProject) setDraggingToTaskbox(true);
        return;
      }
    };

    /**태스크박스에서 태스크 떠났을 때 로직 */
    const handleDragLeaveFromTaskbox = (e: React.DragEvent<HTMLDivElement>) => {
      if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
      setDraggingToTaskbox(false);
    };

    /**태스크를 태스크박스 안에 드랍하여 하위 태스크로 만드는 로직 */
    const handleDragDropToTaskbox = async (eventId: string) => {
      setDraggingToTaskbox(false);
      if (!dragContext || !eventId) return;
      const { data, view, type, id } = dragContext;
      const isBefore = dayjs(date).isBefore(dayjs(), 'date');
      const tasks = view === 'backlog' ? data.map((task: any) => task.id) : type === 'multi-taskbox' ? data : [id];

      if (view === 'project-detail') {
        try {
          const update = await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(eventId, {
            beforeId: null,
            taskIds: type === 'project-multi-subtask' ? data : data.map((task: any) => task.id!),
            done: isBefore,
          });
          if (update) {
            onChange && onChange(dayjs(update.start?.date || update.start?.datetime).toDate());
            fetchProjects();
          }
        } catch (error) {
          language === 'ko' ? toast.error('해당 인스턴스로 가져오기에 실패했습니다.') : toast.error('Failed to import into the instance.');
          // toast.error('Failed to import into the instance.');
        }
        return;
      }

      const result = isBefore
        ? await assignTasksV1TaskboxesTaskboxIdAssignPost(eventId, { tasks, done: true })
        : await assignTasksV1TaskboxesTaskboxIdAssignPost(eventId, { tasks });

      if (result) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!);
        }
        if (dragContext.date !== date) onChange && onChange(dragContext.date!);
        setTimeout(fetchTasks, 100);
      }
    };

    /** 타임라인에서 할 일 목록으로 옮기기 위해 시간 없애는 로직 */
    const handleUnSelectTime = async (eventId: string) => {
      const formattedDate = dayjs(date).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = {
        start: { date: formattedDate },
        end: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
        beforeId: null,
      };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(eventId, updateTaskbox);

      if (success) {
        language === 'ko'
          ? event.project
            ? toast.success('인스턴스를 수정하였습니다.')
            : toast.success('태스크박스를 수정하였습니다.')
          : event.project
          ? toast.success('Modified instance.')
          : toast.success('Modified taskbox.');
        // event.project ? toast.success('Modified instance.') : toast.success('Modified taskbox.');
        //TODO 논의사항 : 태스크박스가 엄청 쌓여있으면 잘 못 알아봄
        onChange && onChange(date!, undefined, true);
      }
    };

    const focusInput = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        const range = document.createRange();
        const selection = window.getSelection()!;
        range.selectNodeContents(el);
        range.setStart(el.childNodes[0], getCaret!);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 50);
    };

    const handleClickTaskbox = (event: OutTaskboxDetailResponse) => {
      if (focusedTaskbox?.id !== event.id) onClickTaskbox && onClickTaskbox(event);
      if (event && event.project) {
        fetchProjects(); //이 코드가 필요한가???
        const project = projects.find((p) => p.id === event.project?.id);
        if (selectedProject) setSelectedInstance(event);
        if (project && selectedProject) setSelectedProject(project);
        if (selectedRoutine) setSelectedRoutine(null);
        if (taskboxDetailView) setTaskboxDetailView(null);
      } else if (event && event.routine) {
        const routine = routines.find((r) => r.id === event.routine?.id);
        if (routine && selectedRoutine) setSelectedRoutine(routine);
        if (selectedProject) setSelectedProject(null);
        if (taskboxDetailView) setTaskboxDetailView(null);
        setSelectedInstance(null);
      } else {
        if (taskboxDetailView) setTaskboxDetailView(event);
        setSelectedInstance(null);
        setSelectedProject(null);
        setSelectedRoutine(null);
      }
    };

    const handleClickInstanceTitle = (event: OutTaskboxDetailResponse) => {
      onClickTaskbox && onClickTaskbox(event);
      if (taskboxDetailView) setTaskboxDetailView(null);
      if (selectedRoutine) setSelectedRoutine(null);
      if (event && event.project) {
        fetchProjects(); // 이 코드가 필요한가???
        const project = projects.find((p) => p.id === event.project?.id);
        setSelectedInstance(event);
        if (project) setSelectedProject(project);
      }
    };

    const handleClickRoutineTitle = (event: OutTaskboxDetailResponse) => {
      onClickTaskbox && onClickTaskbox(event);
      if (taskboxDetailView) setTaskboxDetailView(null);
      if (selectedProject) setSelectedProject(null);
      if (event && event.routine) {
        const routine = routines.find((r) => r.id === event.routine?.id);
        if (!routine) return;
        setSelectedRoutine(routine);
      }
      setSelectedRoutineDate(dayjs(date).toDate());
    };

    const handleCloseInprogressPopover = () => {
      setSelectedTaskbox(undefined);
      setInprogressPopover(null);
    };

    const handleCloseContinueSettingPopover = () => {
      setContinueSettingPopover(null);
    };

    const handleChangeInprogress = async (taskboxDate: Date | null) => {
      setInprogressPopover(null);
      setContinueSettingPopover(null);

      if (!event) return;
      if (!taskboxDate) return;

      const start = event.allDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = event.allDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      const doneTasks = tasks.filter((task) => task.done) as UpdateTaskForTaskBox[];
      const undoneTasks = tasks
        .filter((task) => !task.done)
        .map((task) => ({
          ...task,
          content: task.content || '',
        })) as CreateTaskboxTask[];

      const doneTaskbox: UpdateTaskbox = { tasks: doneTasks, done: true };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, doneTaskbox);

      const continueTaskbox: CreateTaskbox = { ...event, id: uuidv4(), title: event.title!, tasks: undoneTasks, start, end };
      const success2 = await createTaskboxV1TaskboxesPost(continueTaskbox);

      if (success && success2) {
        onChange && onChange(date!);
        await new Promise((resolve) => setTimeout(resolve, 100));
        onChange && onChange(dayjs(taskboxDate).toDate());
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        language === 'ko'
          ? toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다.')
          : toast.success('Created new taskbox with incomplete subtasks.');
        // toast.success('Created new taskbox with incomplete subtasks.');
      }
    };

    /** 반복 업무 */
    const handleCloseRecurrenceUpdateDialog = () => {
      setSelectedTaskbox(undefined);
      setOpenRecurrenceUpdate(false);
    };

    const handleOpenRecurrenceUpdateDialog = () => {
      setSelectedTaskbox(event!.id!);
      setOpenRecurrenceUpdate(true);
    };

    // 이후 반복 업무에 변경사항 반영
    const handleReflectRoutine = async () => {
      if (!event) return;
      const routine = routines.find((r) => r.id === event.routine?.id);
      if (!routine) return;
      try {
        await updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch(routine.id, {
          start: {
            datetime: event.start?.datetime,
          },
          end: {
            datetime: event.end?.datetime,
          },
          recurrence: event.routine?.recurrence as InRecurrence,
        });
        language === 'ko' ? toast.success('이후 인스턴스에 변경사항이 반영되었습니다.') : toast.success('Changes applied to future instances.');
        // toast.success('Changes applied to future instances.');
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
      } catch (e) {
        language === 'ko' ? toast.error('이후 인스턴스에 변경사항을 반영할 수 없습니다.') : toast.error('Failed to apply changes to future instances.');
        // toast.error('Failed to apply changes to future instances.');
      }
    };

    // 반복 업무 시간 해제하기
    const handleRemoveRecurrenceTime = async (target: InCreateTaskBoxInRecurrenceTarget) => {
      setOpenRecurrenceUpdate(false);
      const taskBoxInRecurrence: UpdateTaskBoxRecurrence = {
        target: target as RecurrenceActionType,
        recurrence: event!.recurrence as InRecurrence,
        start: { date: dayjs(date).format(DATE_FORMAT_4) },
        end: { date: dayjs(date).format(DATE_FORMAT_4) },
      };
      await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(event!.id!, taskBoxInRecurrence);
      language === 'ko'
        ? event.project
          ? toast.success('인스턴스를 수정하였습니다.')
          : toast.success('태스크박스를 수정하였습니다.')
        : event.project
        ? toast.success('Instance modified.')
        : toast.success('Taskbox modified.');
      // event.project ? toast.success('Instance modified.') : toast.success('Taskbox modified.');
      if (event.type === 'TERM_TASK') {
        onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
      } else {
        onChange && onChange(date!, dayjs(date).add(7, 'day').toDate(), true);
      }
    };

    //반복 업무 지금 시작하기
    const handleRecurrenceStartNow = async (target: InCreateTaskBoxInRecurrenceTarget) => {
      setOpenRecurrenceUpdate(false);
      setContextTaskbox(null);
      setContextMenuPopover(null);
      const timeDiff = dayjs(event?.end?.datetime).diff(dayjs(event?.start?.datetime), 'minute');
      const taskBoxInRecurrence: UpdateTaskBoxRecurrence = {
        target: target as RecurrenceActionType,
        recurrence: event!.recurrence as InRecurrence,
        start: { datetime: dayjs().format(DATE_FORMAT_1) },
        end: { datetime: !timeDiff ? dayjs().add(1, 'hour').format(DATE_FORMAT_1) : dayjs().add(timeDiff, 'minute').format(DATE_FORMAT_1) },
      };
      await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(event!.id!, taskBoxInRecurrence);
      language === 'ko'
        ? event.project
          ? toast.success('인스턴스를 수정하였습니다.')
          : toast.success('태스크박스를 수정하였습니다.')
        : event.project
        ? toast.success('Instance modified.')
        : toast.success('Taskbox modified.');
      // event.project ? toast.success('Instance modified.') : toast.success('Taskbox modified.');
      if (event.type === 'TERM_TASK') {
        onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
      } else {
        onChange && onChange(date!, dayjs(date).add(7, 'day').toDate(), true);
      }
    };

    // 자주 하는 업무
    const handleOpenTemplateDialog = () => {
      setOpenTemplateDialog(true);
    };

    const handleCloseTemplateDialog = () => {
      setTaskboxDetail(null);
      setSelectedTaskbox(undefined);
      setOpenTemplateDialog(false);
    };

    const handlePutTemplate = async (template: OutFrequentWork) => {
      const success = await updateFrequentWorkV1FrequentworksFrequentWorkIdPut(template.id!, {
        ...template,
        categoryId: template.category ? [template.category.id] : [],
        tasks: template.tasks!.map((v) => ({ ...v, categoryId: v.category ? [v.category.id] : [] })),
      });
      if (success) {
        onFetchTemplateList && onFetchTemplateList();
      }
    };

    const handleDeleteTemplate = async (template: OutFrequentWork) => {
      const success = await deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete(template.id!);
      if (success) {
        onFetchTemplateList && onFetchTemplateList();
        language === 'ko' ? toast.success('템플릿이 삭제되었습니다.') : toast.success('Template deleted.');
        // toast.success('Template deleted.');
      }
    };

    const handleTemplateToTaskbox = async (template: OutFrequentWork) => {
      setFocusedTaskbox(event);
      // const assignTasks = template!.tasks!.map((v) => v.id);
      // await assignTasksV1TaskboxesTaskboxIdAssignPost(event!.id!, { tasks: assignTasks });

      const newTasks = template!.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] }));
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: [...event!.tasks!, ...newTasks] as UpdateTaskForTaskBox[] });

      if (success) {
        handleCloseTemplateDialog();
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!);
        }
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
      }
    };

    const handleHighlightTaskbox = async (taskbox: OutTaskboxDetailResponse) => {
      if (!taskbox) return;
      setIsExploding(false);
      setTaskboxDetail(null);
      setSelectedTaskbox(undefined);
      try {
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { focus: !taskbox.focus });
        if (success) {
          onChange && onChange(date!);
          if (taskboxDetailView) {
            setSyncTaskboxDetailView({
              ...taskbox,
              focus: !event?.focus,
              syncType: 'taskbox',
            });
          }
        }
      } catch (error) {
        language === 'ko' ? toast.error('태스크박스를 수정할 수 없습니다.') : toast.error('Failed to modify the taskbox.');
      }
    };

    const handleOpenMultiSelectDeleteDialog = () => {
      setIsOpenMultiSelectDeleteDialog(true);
    };

    const handleCloseMultiSelectDeleteDialog = () => {
      setIsOpenMultiSelectDeleteDialog(false);
    };

    const handleOpenSimpleTimeSelectionModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setSelectedTaskbox(event!.id!);
      setSimpleTimeSelection(e.currentTarget);
    };

    const handleCloseSimpleTimeSelectionModal = () => {
      setSimpleTimeSelection(null);
      if (isSettingUnderbarTime) setIsSettingUnderbarTime(false);
    };

    const handleClickFocusMode = async (taskbox: OutTaskboxDetailResponse = event!) => {
      if (!taskbox) return;
      setTaskboxDetail(null);
      setSelectedTaskbox(undefined);
      setFocusModeTaskbox(taskbox!);
      setHideFocusMode(false);
      if (taskbox.start?.datetime)
        onChangeTimer &&
          onChangeTimer(
            'RESET',
            dayjs()
              .add(dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime)))
              .toDate(),
          );
    };

    const handleCloseFocusMode = async (taskbox?: UpdateTaskbox | null) => {
      if (!taskbox) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        await new Promise((resolve) => setTimeout(resolve, 100));
        fetchTasks();
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
        if (event.project) fetchProjects();
        // navigate('/task');
        return;
      }

      const typeTaskbox = taskbox as OutTaskboxDetailResponse;
      const { memo, category, ...updateTaskbox } = typeTaskbox;
      const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

      const tasks =
        (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
          return {
            ...task,
            content: task.content,
            categoryId: task.category ? [task.category.id] : [],
          };
        }) as UpdateTaskForTaskBox[]) || [];

      // 마지막 항목이 비어있다면 삭제
      if (tasks.length > 0) {
        for (let i = tasks.length - 1; i >= 0; i--) {
          if (tasks[i].content === '') tasks.pop();
          else break;
        }
      }
      taskbox.tasks = tasks;

      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (taskbox.workSectionType) fetchTasks();
        if (event.project) fetchProjects();
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
        // navigate('/task');
      }
    };

    const handleHideFocusMode = async (taskbox: UpdateTaskbox) => {
      if (!taskbox) return;

      const typeTaskbox = taskbox as OutTaskboxDetailResponse;
      const { memo, category, ...updateTaskbox } = typeTaskbox;
      const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

      const tasks =
        (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
          return {
            ...task,
            content: task.content,
            categoryId: task.category ? [task.category.id] : [],
          };
        }) as UpdateTaskForTaskBox[]) || [];

      // 마지막 항목이 비어있다면 삭제
      if (tasks.length > 0) {
        for (let i = tasks.length - 1; i >= 0; i--) {
          if (tasks[i].content === '') tasks.pop();
          else break;
        }
      }
      taskbox.tasks = tasks;

      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (taskbox.workSectionType) fetchTasks();
        if (event.project) fetchProjects();
        setHideFocusMode(true);
      }
    };

    const handleDeleteFocusMode = async (taskbox: OutTaskboxDetailResponse) => {
      const success = await removeTaskboxV1TaskboxesTaskboxIdDelete(focusModeTaskbox!.id!);
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
        language === 'ko' ? toast.success('태스크박스를 삭제하였습니다.') : toast.success('Taskbox deleted.');
        // toast.success('Taskbox deleted.');
      }
    };

    const handleCreateTemplate = async (taskbox: OutTaskboxDetailResponse) => {
      const newTemplate: CreateFrequentWork = {
        id: uuidv4(),
        title: taskbox?.title,
        tasks: taskbox!.tasks!.map((item) => ({ ...item, id: uuidv4(), categoryId: item.category ? [item.category.id] : [] })) as CreateFrequentWorkTask[],
      };

      try {
        const success = await createFrequentWorkV1FrequentworksPost(newTemplate);
        if (success) {
          onFetchTemplateList && onFetchTemplateList();
          language === 'ko' ? toast.success('템플릿 목록에 추가되었습니다.') : toast.success('Added to template list.');
          // toast.success('Added to template list.');
          setSelectedTaskbox(undefined);
          setTaskboxDetail(null);
        }
      } catch (error) {
        language === 'ko' ? toast.error('템플릿 목록에 추가할 수 없습니다.') : toast.error('Failed to added to the template list.');
      }
    };

    const handleChangeFocusModeInprogress = async (
      taskbox: OutTaskboxDetailResponse,
      taskboxDate: Date | null,
      isAllDay = false,
      dateType: ContinueDateType,
    ) => {
      if (!taskbox) return;
      if (!taskboxDate) return;

      const start = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      const doneTasks = taskbox.tasks!.filter((task) => task.done) as UpdateTaskForTaskBox[];
      const undoneTasks = tasks
        .filter((task) => !task.done)
        .map((task) => ({
          ...task,
          content: task.content || '',
        })) as CreateTaskboxTask[];

      const doneTaskbox: UpdateTaskbox = { tasks: doneTasks, done: true };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, doneTaskbox);

      const continueTaskbox: CreateTaskbox = { ...taskbox, id: uuidv4(), title: taskbox.title!, tasks: undoneTasks, start, end };
      const success2 = await createTaskboxV1TaskboxesPost(continueTaskbox);

      if (success && success2) {
        handleCloseFocusMode();
        language === 'ko'
          ? toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다.')
          : toast.success('Created new taskbox with incomplete subtasks.');
        // toast.success('Created new taskbox with incomplete subtasks.');
      }
    };

    const handleDeleteWithKeydown = () => {
      if (event?.isRecurrence) {
        setDeleteWithKeydown(false);
        setDeleteRecurrenceWithKeydown(true);
        return;
      } else {
        setDeleteWithKeydown(false);
        handleDeleteTaskbox(event!);
      }
    };

    const handleDeleteRecurrenceWithKeydown = (target: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      handleDeleteRecurrence(target);
    };

    const handleDuplicateTaskbox = async (taskbox: OutTaskboxDetailResponse) => {
      const newTasks = taskbox.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] })) as CreateTaskboxTask[];
      const newTaskbox: CreateTaskbox = {
        ...taskbox,
        id: uuidv4(),
        title: taskbox.title!,
        categoryId: taskbox.category ? [taskbox.category.id] : [],
        tasks: newTasks,
      };

      try {
        const success = await createTaskboxV1TaskboxesPost(newTaskbox);
        if (success) {
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
          if (dayjs(date).isBefore(dayjs(), 'date')) onFetchIncompleteTaskboxes && onFetchIncompleteTaskboxes();
          language === 'ko' ? toast.success('태스크박스가 복제되었습니다.') : toast.success('Taskbox duplicated.');
          // toast.success('Taskbox duplicated.');
          setSelectedTaskbox(undefined);
          setTaskboxDetail(null);
        }
      } catch (error) {
        language === 'ko' ? toast.error('태스크박스를 복제할 수 없습니다.') : toast.error('Failed to duplicate taskbox.');
      }
    };

    const handleClickCategory = () => {
      setCategoryAnchorEl(taskboxDetail);
      setTaskboxDetail(null);
    };

    const handleClickDeadline = () => {
      setDeadlineAnchorEl(taskboxDetail);
      setTaskboxDetail(null);
    };

    const handleClickTaskboxDetailMenu = async (menu: InboxContextMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!event) return;
      try {
        switch (menu) {
          case 'DELETE':
            handleDeleteTaskbox(event);
            break;
          case 'SAVE_TEMPLATE':
            handleCreateTemplate(event);
            break;
          case 'DUPLICATE':
            handleDuplicateTaskbox(event);
            break;
          case 'CATEGORY':
            handleClickCategory();
            break;
          case 'DEADLINE':
            handleClickDeadline();
            break;
          case 'CONVERT_TO_PROJECT':
            handleConvertToProject(event);
            break;
          case 'LINK_TO_PROJECT':
            if (e) handleLinkToProject(e);
            break;
          case 'START_NOW':
            break;
          case 'MOVE_TO_TOP':
            break;
          case 'MOVE_TO_BOTTOM':
            break;
          case 'FOCUS_MODE':
            handleClickFocusMode(event);
            break;
          case 'HIGHLIGHT':
            handleHighlightTaskbox(event);
            break;
          case 'CALENDAR_LOCK':
            handleLockInOutButton(event.lockedIn!);
            break;
          case 'LOAD_TEMPLATE':
            handleOpenTemplateDialog();
            break;
          case 'STOP_ROUTINE':
            handleStopRoutine(event);
        }
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => {
      switch (action) {
        case 'CREATE':
          {
            if (!category) return;
            const success = await createCategoryV1CategoryPost(category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
            }
          }
          break;
        case 'SELECT':
          {
            if (subtaskId || detachSubtaskId) {
              const updateTasks = tasks.map((item) =>
                item.id === subtaskId || detachSubtaskId ? { ...item, categoryId: category ? [category.id!] : [] } : item,
              );
              try {
                await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: updateTasks as UpdateTaskForTaskBox[] });
                setCategoryAnchorEl(null);
                setSubtaskId(undefined);
                if (isSettingUnderbarCategory) setIsSettingUnderbarCategory(false);
                if (event.type === 'TERM_TASK') {
                  onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
                } else {
                  onChange && onChange(date!);
                }
              } catch (e) {
                language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
                // toast.error(`The operation can't be performed.`);
              }
            } else {
              try {
                const success = event.project
                  ? await updateProjectV1ProjectProjectIdPatch(event.project.id!, { categoryIds: category ? [category.id!] : [] })
                  : await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { categoryId: category ? [category.id!] : [] });

                if (success) {
                  setSelectedTaskbox(undefined);
                  setCategoryAnchorEl(null);
                  if (event.type === 'TERM_TASK') {
                    onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
                  } else {
                    onChange && onChange(date!);
                  }
                  if (event.project) fetchProjects();
                  if (taskboxDetailView)
                    setSyncTaskboxDetailView({
                      ...event,
                      category: category,
                      syncType: 'taskbox',
                    });
                }
              } catch (e) {
                language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
                // toast.error(`The operation can't be performed.`);
              }
            }

            if (isQuickCategory) {
              setFocusElement(focusedTaskbox!.id!);
              setIsQuickCategory(false);

              const value = refTaskboxInput.current?.textContent;
              if (value && value.includes('#')) {
                refTaskboxInput.current.textContent = value.replace(/#/g, '');
              }
            }
          }
          break;
        case 'UPDATE':
          {
            if (!category) return;
            const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
              if (focusModeTaskbox) return;
              if (event.type === 'TERM_TASK') {
                onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
              } else {
                onChange && onChange(date!);
              }
            }
          }
          break;
        case 'DELETE':
          {
            if (!category) return;
            await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
            onFetchCategoryList && onFetchCategoryList();
            if (focusModeTaskbox) return;
            if (event.type === 'TERM_TASK') {
              onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
            } else {
              onChange && onChange(date!);
            }
          }
          break;
      }
    };

    const handleChangeDeadline = async (deadlineDate: Dayjs | null, detachSubtaskId?: string) => {
      if (subtaskId || detachSubtaskId) {
        const updateTasks = tasks.map((item) =>
          item.id === subtaskId || detachSubtaskId
            ? { ...item, deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null, categoryId: item.category ? [item.category.id] : [] }
            : item,
        );
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: updateTasks as UpdateTaskForTaskBox[] });
          setDeadlineAnchorEl(null);
          setSubtaskId(undefined);
          if (setIsSettingUnderbarDeadline) setIsSettingUnderbarDeadline(false);
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
        } catch (e) {
          language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
          // toast.error(`The operation can't be performed.`);
        }
      } else {
        try {
          const success = event.project
            ? await updateProjectV1ProjectProjectIdPatch(event.project.id!, { due: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null })
            : await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null });

          if (success) {
            setDeadlineAnchorEl(null);
            if (event.type === 'TERM_TASK') {
              onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
            } else {
              onChange && onChange(date!);
            }
            if (event.project) fetchProjects();
            if (taskboxDetailView)
              setSyncTaskboxDetailView({
                ...event,
                deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null,
                syncType: 'taskbox',
              });
          }
        } catch (e) {
          language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
          // toast.error(`The operation can't be performed.`);
        }
      }
      if (isQuickDeadline) {
        setFocusElement(focusedTaskbox!.id!);
        setIsQuickDeadline(false);

        const value = refTaskboxInput.current?.textContent;
        if (value && value.includes('$')) {
          refTaskboxInput.current.textContent = value.replace(/\$/g, '');
        }
      }
    };

    const handleFetchFocusMode = async () => {
      onChange && onChange(date!);
      if (focusModeTaskbox) {
        const focus = events.find((event) => event.id === focusModeTaskbox.id);
        setFocusModeTaskbox(focus!);
      }
    };

    const handleDeleteProject = async (id: string) => {
      try {
        await deleteProjectV1ProjectProjectIdDelete(id);
        if (focusModeTaskbox) setFocusModeTaskbox(null);
        language === 'ko' ? toast.success('프로젝트를 삭제하였습니다.') : toast.success('Project deleted.');
        // toast.success('Project deleted.');
        fetchProjects();
        setFetchInstancesFromProjectDetailView(true);
      } catch (error) {
        language === 'ko' ? toast.error('프로젝트를 삭제할 수 없습니다.') : toast.error('Failed to delete project.');
        // toast.error('Failed to delete project.');
      }
    };

    const handleConvertToProject = async (taskbox: OutTaskboxDetailResponse) => {
      try {
        if (!taskbox || !taskbox.id) return;

        const success = await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(taskbox.id);
        setSelectedTaskbox(undefined);
        setTaskboxDetail(null);
        onChange && onChange(date!);
        fetchProjects();

        const isFirstShowConvertProjectToInstanceGuide =
          featureStorage.find((feature) => feature.name === 'convert-project-instance-guide')?.data.show || false;
        if (!isFirstShowConvertProjectToInstanceGuide) {
          setConvertedToProjectId((success as { id: string }).id);
          if (foldSidePanel) {
            setFoldSidePanel(false);
            localStorage.setItem('fold-side-panel-view', JSON.stringify(false));
          }

          if (sidePanelTabIndex !== 0) {
            setSidePanelTabIndex(0);
            localStorage.setItem('tabpanel-index', JSON.stringify(0));
          }
        }

        if (projects.filter((project) => project.place === 'DESK').length >= 7) {
          language === 'ko'
            ? toast.success('데스크가 가득 차서 서랍에 프로젝트를 생성했습니다.')
            : toast.success('Due to desk limit, project added to drawer.');
          // toast.success('Due to desk limit, project added to drawer.');
        } else {
          language === 'ko' ? toast.success('태스크박스를 프로젝트로 전환했습니다.') : toast.success('Converted taskbox to project.');
          // toast.success('Converted taskbox to project.');
        }
      } catch (error) {
        language === 'ko' ? toast.error('프로젝트로 전환할 수 없습니다.') : toast.error('Failed to convert to project.');
      }
    };

    const handleLinkToProject = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setProjectListAnchorEl(e.currentTarget);
    };

    const handlePushProjectRecommendTask = async (blocks: TaskBlock[], blockId: string, index: number) => {
      if (!focusModeTaskbox) return;
      const updateBlocks = blocks.map((block) => ({ ...block, content: block.content }));
      await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { tasks: updateBlocks as UpdateTaskForTaskBox[] });
      await assignTasksV1TaskboxesTaskboxIdAssignPost(focusModeTaskbox!.id!, { tasks: [blockId], beforeId: blocks[index - 1]?.id });
      fetchProjects();
    };

    const handleChangeFocusModeInstanceSubtask = async (block: TaskBlock, action: ProjectTaskBlockAction) => {
      if (!event) return;

      switch (action) {
        case 'MOVE_TO_AFTER': {
          const targetProject = projects.find((project) => project.id === event.project?.id);
          if (!targetProject) return;

          const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
          const index = instances.findIndex((instance) => instance.id === event.id);
          const nextInstance = instances[index + 1];

          const taskExistsInTaskbox = event.tasks?.some((task) => task.id === block.id);

          const assignTaskToNextInstance = async () => {
            if (nextInstance) {
              const nextInstanceTasks = nextInstance.tasks;
              await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
                beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
                tasks: [block.id],
              });
              language === 'ko' ? toast.success('프로젝트의 다음 인스턴스로 이동했습니다.') : toast.success('Moved to the next instance of the project.');
              // toast.success('Moved to the next instance of the project.');
            } else {
              await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
                taskIds: [block.id],
              });
              language === 'ko' ? toast.success('프로젝트의 미할당으로 이동했습니다.') : toast.success('Moved to unassigned.');
              // toast.success('Moved to unassigned.');
            }
          };

          try {
            if (taskExistsInTaskbox) {
              await assignTaskToNextInstance();
            } else {
              const create = await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, {
                id: block.id,
                content: block.content!,
              });

              if (create) {
                await assignTaskToNextInstance();
              }
            }
            fetchProjects();
          } catch (error) {
            language === 'ko' ? toast.error('일부 완료에 실패했습니다.') : toast.error('Failed to complete part of the task.');
            // toast.error('Failed to complete part of the task.');
          }
        }
      }
    };

    const handleClickProjectList = async (project: OutProject) => {
      try {
        setProjectListAnchorEl(null);
        setTaskboxDetail(null);
        setConvertAnchorEl(null);
        setSelectedTaskbox(undefined);
        await linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost(project.id!, event!.id!);
        language === 'ko' ? toast.success('프로젝트에 연결되었습니다.') : toast.success('Linked to project.');
        // toast.success('Linked to project.');
        onChange && onChange(dayjs(event.start?.date || event.start?.datetime).toDate());
        if (taskboxDetailView) {
          setTaskboxDetailView(null);
          setSelectedProject(project);
        }
        fetchProjects();
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }

      if (isQuickProject) {
        setFocusElement(focusedTaskbox!.id!);
        setIsQuickProject(false);

        const value = refTaskboxInput.current?.textContent;
        if (value && value.includes('>')) {
          refTaskboxInput.current.textContent = value.replace(/>/g, '');
        }
      }
    };

    const handleClickContext = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      setContextTaskbox(event);
      setContextMenuPopover(e.currentTarget);
    };

    const handleClickStartNow = async (event: OutTaskboxDetailResponse, fromTaskboxAction?: boolean) => {
      if (!event) return;
      // if (event.recurrence) {
      //   handleOpenRecurrenceUpdateDialog();
      //   return;
      // }

      setContextTaskbox(null);
      setContextMenuPopover(null);
      const timeDiff = dayjs(event?.end?.datetime).diff(dayjs(event?.start?.datetime), 'minute');
      try {
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
          start: { datetime: dayjs().format(DATE_FORMAT_1) },
          end: { datetime: !timeDiff ? dayjs().add(1, 'hour').format(DATE_FORMAT_1) : dayjs().add(timeDiff, 'minute').format(DATE_FORMAT_1) },
        });
        dayjs(date).isAfter(dayjs()) ? onChange && onChange(dayjs().toDate(), date!) : onChange && onChange(date!, dayjs().toDate());
        if (fromTaskboxAction) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setHideFocusMode(true);
          setFocusModeTaskbox({
            ...event,
            start: { datetime: dayjs().format(DATE_FORMAT_1) },
            end: { datetime: !timeDiff ? dayjs().add(1, 'hour').format(DATE_FORMAT_1) : dayjs().add(timeDiff, 'minute').format(DATE_FORMAT_1) },
            allDay: false,
          });
        }
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleClickMoveToTop = async (event: OutTaskboxDetailResponse) => {
      if (!event) return;
      setContextTaskbox(null);
      setContextMenuPopover(null);
      try {
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
          beforeId: null,
        });
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleClickMoveToBottom = async (event: OutTaskboxDetailResponse) => {
      if (!event) return;
      setContextTaskbox(null);
      setContextMenuPopover(null);
      try {
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
          beforeId: userState.data.taskViewMode === 'SPLIT' ? events.filter((item) => item.allDay).pop()?.id : events.pop()?.id,
        });
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
      try {
        switch (menu) {
          case 'START_NOW':
            handleClickStartNow(contextTaskbox!);
            break;
          case 'MOVE_TO_TOP':
            handleClickMoveToTop(contextTaskbox!);
            break;
          case 'MOVE_TO_BOTTOM':
            handleClickMoveToBottom(contextTaskbox!);
            break;
          case 'DELETE':
            handleDeleteTaskbox(contextTaskbox!);
            break;
        }
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleSubtaskDragStart = (e: React.DragEvent<HTMLDivElement>, subtask: OutTaskboxDetailResponseForSubtask) => {
      if (multiSelectSubtaskIds.length > 0) onMultiSelectSubtaskAnchorElNull && onMultiSelectSubtaskAnchorElNull();

      e.stopPropagation();
      const refTaskboard = ref as React.RefObject<HTMLDivElement>;
      const taskboardElement = refTaskboard?.current;
      if (!taskboardElement) return;
      const dragImageElement = taskboardElement.querySelector('#taskbox-subtask-drag-image');
      if (dragImageElement) {
        e.dataTransfer.setDragImage(dragImageElement, 40, 3);
      }

      setDragContext({
        type: multiSelectSubtaskIds.length > 0 ? 'multi-subtask' : 'subtask',
        view: 'taskboard',
        id: event.id,
        title: event.title!,
        data: multiSelectSubtaskIds.length > 0 ? multiSelectSubtaskIds[0].subtaskIds : [subtask],
        date: date!,
        projectId: event.project?.id ? event.project.id : undefined,
      });
    };

    const handleSubtaskDragEnd = () => {
      if (multiSelectSubtaskIds.length > 0) onCloseMultiSelectSubtask && onCloseMultiSelectSubtask();
      setDragContext(null);
    };

    const handleTaskboxInput = (e: React.FormEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      if (taskboxDetailView) {
        debouncedSyncTaskbox({
          ...event,
          title: target.textContent,
          syncType: 'taskbox',
        });
      }
    };

    const handleMultiSelectSubtaskIds = (subtaskId: string, e: React.MouseEvent<HTMLDivElement>) => {
      if (multiSelectIds.length > 0) return;
      e.stopPropagation();
      if (multiSelected) return;
      if (multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].taskboxId !== event.id) {
        language === 'ko'
          ? toast.error('동일한 태스크박스 내에서만 멀티선택이 가능합니다.')
          : toast.error('Multi-selection is available within the same taskbox.');
        // toast.error('Multi-selection is available within the same taskbox.');
        return;
      }
      if (!multiSelectSubtaskIds) return;

      const currentSubtaskIds = multiSelectSubtaskIds[0]?.subtaskIds || [];
      const newSubtaskIds = currentSubtaskIds.includes(subtaskId) ? currentSubtaskIds.filter((id) => id !== subtaskId) : [...currentSubtaskIds, subtaskId];

      const newMultiSelectSubtaskIds: MultiSelectSubtaskIds = {
        taskboxId: event.id!,
        subtaskIds: newSubtaskIds,
      };

      onChangeMultiSelectSubtaskIds && onChangeMultiSelectSubtaskIds(newSubtaskIds.length > 0 ? [newMultiSelectSubtaskIds] : [], e);
    };

    const handleUnlinkRoutine = async (eventId: string) => {
      try {
        await unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch(eventId);
        language === 'ko' ? '루틴과 연결을 해제했습니다.' : 'Unlinked from routine.';
        // toast.success('Unlinked from routine.');
        setTimeSelection(null);
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const handleUnlinkProject = async (event: OutTaskboxDetailResponse) => {
      try {
        await unlinkWithProjectV1ProjectProjectIdUnlinkingTaskTaskboxIdPatch(event.project!.id!, event.id!);
        language === 'ko' ? toast.success('프로젝트와 연결을 해지했습니다.') : toast.success('Unlinked from project.');
        // toast.success('프로젝트와 연결을 해지했습니다.');
        onChange && onChange(date!);
        if (selectedProject) setSelectedProject(null);
      } catch (e) {
        language === 'ko' ? toast.error('작업을 수행할 수 없습니다.') : toast.error(`The operation can't be performed.`);
        // toast.error(`The operation can't be performed.`);
      }
    };

    const isSameRoutineMaster = (() => {
      const routine = routines.find((routine) => routine.id === event.routine?.id);
      if (!event.routine || !routine) return false;

      const { lastMasterStart, lastMasterEnd } = routine;

      // start가 date 형식이면 두 event도 date 형식이어야 함
      if (lastMasterStart?.date) return !!event.start?.date;

      // datetime 비교
      if (lastMasterStart?.datetime) {
        if (event.start?.date) return false;

        // 날짜를 무시하고 시간과 분만 비교
        const startHourMinuteSame =
          dayjs(event.start?.datetime).hour() === dayjs(lastMasterStart.datetime).hour() &&
          dayjs(event.start?.datetime).minute() === dayjs(lastMasterStart.datetime).minute();

        const endHourMinuteSame =
          dayjs(event.end?.datetime).hour() === dayjs(lastMasterEnd?.datetime).hour() &&
          dayjs(event.end?.datetime).minute() === dayjs(lastMasterEnd?.datetime).minute();

        return startHourMinuteSame && endHourMinuteSame;
      }

      return true;
    })();

    const contextMenus: InboxContextMenuType[] = (() => {
      const isToday = dayjs(contextTaskbox?.start?.date || contextTaskbox?.start?.datetime).isSame(dayjs(), 'date');

      if (userState.data.taskViewMode === 'SPLIT') {
        if (contextTaskbox?.type === 'TERM_TASK') {
          return ['DELETE'];
        }

        if (events.filter((event) => event.allDay).length === 1) {
          return isToday ? ['START_NOW', 'DELETE'] : ['DELETE'];
        } else if (contextTaskbox?.allDay && events.findIndex((item) => item.id === contextTaskbox?.id) === 0) {
          return isToday ? ['START_NOW', 'MOVE_TO_BOTTOM', 'DELETE'] : ['MOVE_TO_BOTTOM', 'DELETE'];
        } else if (contextTaskbox?.allDay && events.findIndex((item) => item.id === contextTaskbox?.id) === events.filter((item) => item.allDay).length - 1) {
          return isToday ? ['START_NOW', 'MOVE_TO_TOP', 'DELETE'] : ['MOVE_TO_TOP', 'DELETE'];
        } else if (!contextTaskbox?.allDay) {
          return isToday ? ['START_NOW', 'DELETE'] : ['DELETE'];
        } else {
          return isToday ? ['START_NOW', 'MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'] : ['MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'];
        }
      } else if (userState.data.taskViewMode === 'MERGED') {
        if (contextTaskbox?.type === 'TERM_TASK') {
          return ['DELETE'];
        }

        if (events.length === 1) {
          return isToday ? ['START_NOW', 'DELETE'] : ['DELETE'];
        } else if (events.findIndex((item) => item.id === contextTaskbox?.id) === 0) {
          return isToday ? ['START_NOW', 'MOVE_TO_BOTTOM', 'DELETE'] : ['MOVE_TO_BOTTOM', 'DELETE'];
        } else if (events.findIndex((item) => item.id === contextTaskbox?.id) === events.length - 1) {
          return isToday ? ['START_NOW', 'MOVE_TO_TOP', 'DELETE'] : ['MOVE_TO_TOP', 'DELETE'];
        } else {
          return isToday ? ['START_NOW', 'MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'] : ['MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'];
        }
      }
      return ['START_NOW', 'MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'];
    })();

    const subtaskMultiSelected = multiSelectSubtaskIds.length > 0;

    const [taskboxTitleLength, setTaskboxTitleWidth] = useState<number>(0);
    const [isSettingUnderbarTime, setIsSettingUnderbarTime] = useState<boolean>(false);
    const [isSettingUnderbarCategory, setIsSettingUnderbarCategory] = useState<boolean>(false);
    const [isSettingUnderbarDeadline, setIsSettingUnderbarDeadline] = useState<boolean>(false);
    const [subtaskMode, setSubtaskMode] = useState<SubtaskMode>('IMPORT');
    const [subtaskModeAnchorEl, setSubtaskModeAnchorEl] = useState<HTMLElement | null>(null);
    const [convertAnchorEl, setConvertAnchorEl] = useState<HTMLElement | null>(null);
    const [convertToRoutineTaskbox, setConvertToRoutineTaskbox] = useState<OutTaskboxDetailResponse | undefined>(undefined);

    // useEffect(() => {
    //   // 글자수에 따라 taskboxOption 위치 조정
    //   const updateWidth = () => {
    //     if (refTaskboxInput.current) {
    //       const font = getComputedStyle(refTaskboxInput.current).font || '16px Arial';
    //       const text = refTaskboxInput.current.textContent || '';
    //       const getTextWidth = (text: string, font: string) => {
    //         const canvas = document.createElement('canvas');
    //         const context = canvas.getContext('2d');
    //         context!.font = font;
    //         const textWidth = context!.measureText(text).width + 8;
    //         return Math.round(textWidth);
    //       };

    //       const taskboxOptionWidth = refTaskbox.current?.querySelector('.taskbox-option')?.clientWidth || 0;

    //       const textWidth =
    //         getTextWidth(text, font) > refTaskboxInput.current.clientWidth - taskboxOptionWidth + 10
    //           ? refTaskboxInput.current.clientWidth - taskboxOptionWidth + 10
    //           : getTextWidth(text, font) || 100;

    //       const instanceIconWidth = refInstanceIcon.current?.clientWidth ? refInstanceIcon.current?.clientWidth + 4 : 0;
    //       const lockInIconWidth = refLockInIcon.current?.clientWidth ? refLockInIcon.current?.clientWidth + 4 : 0;
    //       const newWidth = textWidth + instanceIconWidth + lockInIconWidth + 32;

    //       setTaskboxTitleWidth(newWidth);
    //     }
    //   };

    //   updateWidth(); // 초기 width 설정

    //   const observer = new MutationObserver(updateWidth);
    //   if (refTaskboxInput.current) {
    //     observer.observe(refTaskboxInput.current, { childList: true, characterData: true, subtree: true });
    //   }

    //   return () => {
    //     observer.disconnect();
    //   };
    // }, [event]);

    const handleClickEmptyTime = (id: string) => {
      setIsSettingUnderbarTime(true);
      setTimeout(() => {
        const el = document.querySelector(`[data-time-taskbox-id="${id}"]`) as HTMLDivElement;
        setSelectedTaskbox(id);
        setSimpleTimeSelection(el);
      }, 100);
    };

    const handleClickEmptyCategory = (id: string) => {
      setIsSettingUnderbarCategory(true);
      setTimeout(() => {
        const el = document.querySelector(`[data-category-taskbox-id="${id}"]`) as HTMLElement;
        setCategoryAnchorEl(el);
      }, 100);
    };

    const handleClickEmptyDeadline = (id: string) => {
      setIsSettingUnderbarDeadline(true);
      setTimeout(() => {
        const el = document.querySelector(`[data-deadline-taskbox-id="${id}"]`) as HTMLElement;
        setDeadlineAnchorEl(el);
      }, 100);
    };

    const handleClickSubtaskModeIconBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const el = document.querySelector(`[data-timeboxId="${event.id}"]`) as HTMLElement;
      setSubtaskModeAnchorEl(el);
    };

    const handleChangeSubtaskMode = (mode: SubtaskMode) => {
      setSubtaskMode(mode);
      setSubtaskModeAnchorEl(null);
    };

    const handleClickConvertIconBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!event) return;
      e.stopPropagation();
      setConvertAnchorEl(e.currentTarget);
      setSelectedTaskbox(event.id!);
    };

    const handleClickTaskboxConvert = async (type: ConvertMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      switch (type) {
        case 'LINK_TO_PROJECT':
          if (!e) return;
          handleLinkToProject(e);
          break;
        case 'CONVERT_TO_PROJECT':
          setSelectedTaskbox(undefined);
          handleConvertToProject(event);
          setConvertAnchorEl(null);
          break;
        case 'CONVERT_TO_ROUTINE':
          setSelectedTaskbox(undefined);
          setConvertToRoutineTaskbox(event);
          setConvertAnchorEl(null);
          break;
      }
    };

    const handleCloseCreateRoutine = (fetch?: boolean) => {
      setConvertToRoutineTaskbox(undefined);
      if (fetch) {
        onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        fetchRoutines();
      }
    };

    const handleClickPostponeIconBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!event) return;
      e.stopPropagation();
      setTaskboxPostponeAnchorEl(e.currentTarget);
      setSelectedTaskbox(event.id!);
    };

    const handleDetachTaskboxFromProject = async (taskboxId: OutTaskboxDetailResponse) => {
      try {
        await unlinkWithProjectV1ProjectProjectIdUnlinkingTaskTaskboxIdPatch(taskboxId.project!.id!, taskboxId.id!);
        language === 'ko' ? toast.success('프로젝트와 연결을 해제했습니다.') : toast.success('Detach from project.');
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('프로젝트와 연결을 해제하는데에 실패하였습니다.') : toast.error('Failed to detach from project.');
      }
    };

    const handleDetachTaskboxFromRoutine = async (taskbox: OutTaskboxDetailResponse) => {
      try {
        await unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch(taskbox.id!);
        language === 'ko' ? toast.success('루틴과 연결을 해제했습니다.') : toast.success('Detach from routine.');
        onChange && onChange(date!);
      } catch (e) {
        language === 'ko' ? toast.error('루틴과 연결을 해제하는데에 실패하였습니다.') : toast.error('Failed to unlink from routine.');
      }
    };

    const handleStopRoutine = async (taskbox: OutTaskboxDetailResponse) => {
      if (!taskbox) return;
      if (!taskbox.routine) return;
      setTaskboxDetail(null);
      setSelectedTaskbox(undefined);

      try {
        await updateRoutineStatusV1RoutineRoutineIdStatusPatch(taskbox.routine.id, {
          status: 'HOLD',
          startDate: dayjs(date).format(DATE_FORMAT_4),
        });
        language === 'ko' ? toast.success('루틴을 중지하였습니다.') : toast.success('Routine stopped.');
        onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        fetchRoutines();
      } catch (e) {
        language === 'ko' ? toast.error('루틴을 중지하는데에 실패하였습니다.') : toast.error('Failed to stop routine.');
      }
    };

    return (
      <>
        {event?.allDay ? (
          <div
            className={`taskbox-drag-container ${event.areaFrom === 'SOMEDAY' ? 'taskbox-from-someday' : ''}`.trim()}
            ref={refTaskbox}
            data-taskbox={`${event!.id}`}
            draggable={!multiSelected || multiSelectIds.length > 0}
            onDragStart={multiSelected ? onMultiSelectDragStart : handleDragStart}
            onDragEnd={multiSelected ? onMultiSelectDragEnd : handleDragEnd}
            onDragEnter={handleDragEnterToTaskbox}
            onDragLeave={(e) => handleDragLeaveFromTaskbox(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) onChangeMultiSelectIds && onChangeMultiSelectIds(event.id!, e);
              if (focusedTaskbox?.id === event?.id) handleClickTaskbox(event);
            }}
            onContextMenu={(e) => handleClickContext(e)}
          >
            <Draggable draggableId={`${event?.id}`} index={index!}>
              {(provided, snapshot) => {
                const style = {
                  backgroundColor: snapshot.isDragging ? 'white' : '',
                  boxShadow: snapshot.isDragging ? `0px 8px 16px 0px rgba(26, 30, 39, 0.16)` : '',
                  borderRadius: snapshot.isDragging ? '0px 16px 16px 0px' : '',
                  marginBottom: '8px',
                  ...provided.draggableProps.style,
                };
                return (
                  <TaskboxDragContainer ref={provided.innerRef} multiSelected={multiSelected} {...provided.draggableProps} style={style}>
                    <Tooltip
                      title={
                        language === 'ko'
                          ? event.type === 'TERM_TASK'
                            ? '종료일이 지정된 태스크박스는 타임박싱 할 수 없어요.'
                            : '왼쪽으로 드래그해서 캘린더로 옮깁니다.'
                          : event.type === 'TERM_TASK'
                          ? `Taskbox with end date can't be time-boxed.`
                          : 'Drag to the left to move to the calendar.'
                        // event.type === 'TERM_TASK' ? `Taskbox with end date can't be time-boxed.` : 'Drag to the left to move to the calendar.'
                      }
                      disableInteractive
                    >
                      <TaskboxDragToCalendarIcon
                        className="taskbox-drag-handle"
                        taskboxHeight={taskboxHeight}
                        style={{ cursor: event.type === 'TERM_TASK' ? 'not-allowed' : 'grab' }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        language === 'ko'
                          ? event.type === 'TERM_TASK'
                            ? '종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.'
                            : '위아래로 드래그해서 순서를 변경합니다.'
                          : event.type === 'TERM_TASK'
                          ? 'Taskbox with end date cannot be reordered.'
                          : 'Drag up and down to reorder.'
                        // event.type === 'TERM_TASK' ? `Taskbox with end date can't be reordered.` : 'Drag up and down to reorder.'
                      }
                      disableInteractive
                    >
                      <TaskboxDragHandleIcon
                        {...provided.dragHandleProps}
                        className="taskbox-drag-handle"
                        taskboxHeight={taskboxHeight}
                        style={{ cursor: event.type === 'TERM_TASK' ? 'not-allowed' : 'grab' }}
                      />
                    </Tooltip>
                    {expanded || focusedTaskbox?.id === event?.id ? (
                      <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                        {(provided) => (
                          <TaskDragContainer
                            className="timeline-container"
                            key={event?.id}
                            data-scroll={`${event?.id}`}
                            event={event}
                            plan={plan}
                            hidden={draggingToTaskbox}
                            taskboxDone={event.done}
                            focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                            selectedTaskbox={selectedTaskbox}
                            multiSelected={multiSelected}
                            onClick={(e) => {
                              if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                            }}
                          >
                            {event && (
                              <div
                                className="flex gap-4 timeline-border"
                                onClick={(e) => {
                                  if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                }}
                              >
                                <div
                                  className="line"
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                  }}
                                />
                                <div
                                  className="flex-1"
                                  style={{ pointerEvents: 'all' }}
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                  }}
                                >
                                  <TaskboxPropertyWrapper style={{ justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {(event.category || isSettingUnderbarCategory) && (
                                        <CategoryShowingWrapper
                                          data-category-taskbox-id={event.id}
                                          textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                          bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setCategoryAnchorEl(e.currentTarget);
                                          }}
                                        >
                                          {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                          {event.category && (
                                            <IconButton
                                              className="category-detach-button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleClickCategoryActions(null, 'SELECT');
                                                setIsSettingUnderbarCategory(false);
                                              }}
                                              sx={{
                                                'width': '12px',
                                                'height': '12px',
                                                'borderRadius': '4px',
                                                'marginLeft': '4px',
                                                'padding': '0px',
                                                ':hover': {
                                                  backgroundColor: hexToRGBA(getCategoryTextColor(event.category.color)!, 0.3),
                                                },
                                              }}
                                              style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                            >
                                              <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category.color)} />
                                            </IconButton>
                                          )}
                                        </CategoryShowingWrapper>
                                      )}
                                      {(event.deadline || isSettingUnderbarDeadline) && (
                                        <DeadlineShowingWrapper
                                          data-deadline-taskbox-id={event.id}
                                          date={event.deadline || ''}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setDeadlineAnchorEl(e.currentTarget);
                                          }}
                                          style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                                        >
                                          <Icons.Flag
                                            fill={
                                              !event.deadline
                                                ? COLORS.gray500
                                                : dayjs(event.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600
                                            }
                                          />
                                          <span style={{ marginLeft: '2px' }}>
                                            {!event.deadline
                                              ? language === 'ko'
                                                ? '기한 설정'
                                                : 'Set deadline'
                                              : dayjs(event.deadline).isToday()
                                              ? language === 'ko'
                                                ? '오늘'
                                                : 'Today'
                                              : dayjs(event.deadline).isYesterday()
                                              ? language === 'ko'
                                                ? '어제'
                                                : 'Yesterday'
                                              : dayjs(event.deadline).isTomorrow()
                                              ? language === 'ko'
                                                ? '내일'
                                                : 'Tomorrow'
                                              : language === 'ko'
                                              ? dayjs(event.deadline).format('M월 D일 (dd)')
                                              : dayjs(event.deadline).format('dddd, MMM D')}
                                          </span>
                                          {event.deadline && (
                                            <IconButton
                                              className="deadline-detach-button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleChangeDeadline(null);
                                                setIsSettingUnderbarDeadline(false);
                                              }}
                                              sx={{
                                                'width': '12px',
                                                'height': '12px',
                                                'borderRadius': '4px',
                                                'marginLeft': '4px',
                                                'padding': '0px',
                                                ':hover': {
                                                  backgroundColor: hexToRGBA(
                                                    dayjs(event.deadline).isToday()
                                                      ? COLORS.brand1
                                                      : dayjs(event.deadline).isBefore(dayjs())
                                                      ? COLORS.negative1
                                                      : COLORS.gray600,
                                                    0.3,
                                                  ),
                                                },
                                              }}
                                              style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                            >
                                              <Icons.Close
                                                width={8}
                                                height={8}
                                                stroke={
                                                  dayjs(event.deadline).isToday()
                                                    ? COLORS.brand1
                                                    : dayjs(event.deadline).isBefore(dayjs())
                                                    ? COLORS.negative1
                                                    : COLORS.gray600
                                                }
                                              />
                                            </IconButton>
                                          )}
                                        </DeadlineShowingWrapper>
                                      )}
                                      {isSettingUnderbarTime && (
                                        <div className="time-modal-container" data-time-taskbox-id={event.id}>
                                          <Icons.Time stroke={COLORS.gray500} />
                                          <Fonts.Blockquote className="text-gray500" style={{ marginLeft: '4px' }}>
                                            {language === 'ko' ? '시간 설정' : 'Set time'}
                                          </Fonts.Blockquote>
                                        </div>
                                      )}
                                      {event.type === 'TERM_TASK' && event.matchDate && (
                                        <Tooltip
                                          title={
                                            language === 'ko'
                                              ? `${dayjs(event.matchDate[0]).format('M월D일')}~${dayjs(event.matchDate[event.matchDate.length - 1]).format(
                                                  'M월D일',
                                                )}`
                                              : `${dayjs(event.matchDate[0]).format('MMM D')}~${dayjs(event.matchDate[event.matchDate.length - 1]).format(
                                                  'MMM D',
                                                )}`
                                            // `${dayjs(event.matchDate?.[0]).format('MMM D')}~${dayjs(event.matchDate?.[event.matchDate.length - 1]).format(
                                            //   'MMM D',
                                            // )}`
                                          }
                                          disableInteractive
                                        >
                                          <DateRangeShowingWrapper>
                                            <Icons.RangeTaskbox />
                                            <span style={{ marginLeft: '4px' }}>
                                              {language === 'ko'
                                                ? `${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${
                                                    event.matchDate?.length
                                                  }일째`
                                                : `Day ${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${
                                                    event.matchDate?.length
                                                  }`}
                                              {/* {`Day ${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${
                                                event.matchDate?.length
                                              }`} */}
                                            </span>
                                          </DateRangeShowingWrapper>
                                        </Tooltip>
                                      )}
                                    </div>
                                    {event.type === 'TERM_TASK' && (
                                      <Tooltip
                                        title={
                                          language === 'ko' ? '종료일이 지정되어 상단에 고정됩니다.' : 'End date set, pinned at the top.'
                                          // '종료일이 지정되어 상단에 고정됩니다.'
                                        }
                                        disableInteractive
                                      >
                                        <div>
                                          <Icons.ColorPin stroke={COLORS.brand1} />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </TaskboxPropertyWrapper>
                                  {event.project && event.project.title !== event.title && (
                                    <InstanceTitleWrapper
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          handleClickInstanceTitle(event);
                                          setIsLoadSubtaskFromProject(false);
                                        }
                                      }}
                                    >
                                      <Icons.Issue />
                                      <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                                      <Tooltip
                                        title={
                                          <div>
                                            <div style={{ fontWeight: 700 }}>
                                              {language === 'ko' ? '프로젝트와 연결 해지하기' : 'Unlink from project'}
                                              {/* Unlink from project */}
                                            </div>
                                            <div>
                                              {language === 'ko'
                                                ? '프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다'
                                                : 'Unlink from project and convert to a regular taskbox'}
                                              {/* Unlink from project and convert to a regular taskbox */}
                                            </div>
                                          </div>
                                        }
                                        disableInteractive
                                      >
                                        <IconButton
                                          className="unlink-project"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleUnlinkProject(event);
                                          }}
                                          sx={{
                                            'width': '24px',
                                            'height': '24px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '4px',
                                            'display': 'none',
                                            ':hover': {
                                              backgroundColor: COLORS.gray100,
                                            },
                                          }}
                                        >
                                          <Icons.UnlinkProject />
                                        </IconButton>
                                      </Tooltip>
                                    </InstanceTitleWrapper>
                                  )}
                                  {event.routine && event.routine.title !== event.title && (
                                    <InstanceTitleWrapper
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          handleClickRoutineTitle(event);
                                        }
                                      }}
                                    >
                                      <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                      <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                                    </InstanceTitleWrapper>
                                  )}
                                  <div className="flex justify-between items-start relative timeline-header">
                                    {/* 타임박스 제목 */}
                                    <div className="taskbox-title-container">
                                      {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                        <Checkbox
                                          checked={false}
                                          icon={
                                            event.focus ? (
                                              <Icons.FocusInprogressCheck />
                                            ) : (
                                              <Icons.InprogressCheck
                                                fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                              />
                                            )
                                          }
                                          sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                          style={{ width: 24, height: 24, marginRight: 8 }}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              if (event.type === 'TERM_TASK') {
                                                handleClickTaskboxCheck();
                                              } else {
                                                setSelectedTaskbox(event.id);
                                                setInprogressPopover(e.currentTarget);
                                              }
                                            }
                                          }}
                                        />
                                      ) : (
                                        <PriorityIssueCheckbox
                                          width={24}
                                          height={24}
                                          focus={event.focus}
                                          bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                          markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                          checked={!!event.done}
                                          // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                          // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                          }}
                                          style={{ marginRight: 8 }}
                                        />
                                      )}
                                      {isExploding && event.focus && (
                                        <ConfettiExplosion
                                          force={0.6}
                                          duration={2500}
                                          particleCount={80}
                                          width={1000}
                                          onComplete={() => setIsExploding(false)}
                                          style={{ position: 'absolute', top: 0, left: 0 }}
                                        />
                                      )}
                                      <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                        {event.project && event.project.title === event.title && (
                                          <InstanceIcon ref={refInstanceIcon}>
                                            <Icons.Issue />
                                          </InstanceIcon>
                                        )}
                                        {event.routine && event.routine.title === event.title && (
                                          <InstanceIcon ref={refInstanceIcon}>
                                            <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                          </InstanceIcon>
                                        )}
                                        {event.lockedIn && <LockInIcon ref={refLockInIcon} event={event} />}
                                        <TaskboxInput
                                          ref={refTaskboxInput}
                                          className="taskbox-title"
                                          data-id={
                                            event.type === 'TERM_TASK'
                                              ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                              : `${event.id}`
                                          }
                                          onBlur={(e) => handleBlurTitle(e, event)}
                                          onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                          onInput={(e) => handleTaskboxInput(e)}
                                          placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                          contentEditable={multiSelected ? false : true}
                                          suppressContentEditableWarning={true}
                                          spellCheck={false}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              if (event.routine && event.routine.title === event.title) {
                                                handleClickRoutineTitle(event);
                                              } else if (event.project && event.project.title === event.title) {
                                                handleClickInstanceTitle(event);
                                                setIsLoadSubtaskFromProject(false);
                                              } else if (!event.project && !event.routine) {
                                                handleClickTaskbox(event);
                                                setGetCaret(window.getSelection()!.anchorOffset);
                                                if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                                  setTaskboxDetailView(event);
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          {event.title}
                                        </TaskboxInput>
                                      </TaskboxInputWrapper>
                                      {event.issueId && (
                                        <Button
                                          variant="outlined"
                                          color="inherit"
                                          onClick={() => handleClickIssueDetail(event.issueId!)}
                                          sx={{
                                            width: '100px',
                                            height: '24px',
                                            borderRadius: '8px',
                                            padding: '0px',
                                            color: `${COLORS.gray400}`,
                                            border: `1px solid ${COLORS.gray400}`,
                                          }}
                                        >
                                          <div className="timelineEvents-issue-detail">
                                            <IssueLinkage />
                                            <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                          </div>
                                        </Button>
                                      )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
                                      {event.memo && <Icons.TaskboxMemo />}
                                      {event.links!.length > 0 && (
                                        <div style={{ marginLeft: '4px' }}>
                                          <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                        </div>
                                      )}
                                      {(event.isRecurrence || event.routine) && (
                                        <div style={{ marginLeft: '4px' }}>
                                          <Icons.Recurrence />
                                        </div>
                                      )}
                                      {tasks.length > 0 && (
                                        <div className="show-subtask">
                                          <Icons.Subtask />
                                          <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                        </div>
                                      )}
                                    </div>
                                    {!multiSelected && (
                                      <TaskboxOptionsContainer
                                        className="taskbox-option"
                                        titleLength={taskboxTitleLength}
                                        style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                      >
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) {
                                                    handleClickStartNow(event, true);
                                                  }
                                                }}
                                              >
                                                <Icons.StartNowTaskboxAction />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            {event.project && (
                                              <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                                <TaskboxDetachButtonWrapper>
                                                  <IconButton
                                                    onClick={() => handleDetachTaskboxFromProject(event)}
                                                    sx={{
                                                      'width': '20px',
                                                      'height': '20px',
                                                      'padding': '0px',
                                                      'borderRadius': '2.5px',
                                                      'backgroundColor': COLORS.issue2,
                                                      ':hover': {
                                                        backgroundColor: COLORS.issue2,
                                                      },
                                                    }}
                                                  >
                                                    <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                  </IconButton>
                                                </TaskboxDetachButtonWrapper>
                                              </Tooltip>
                                            )}
                                            {event.routine && (
                                              <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                                <TaskboxDetachButtonWrapper>
                                                  <IconButton
                                                    onClick={() => handleDetachTaskboxFromRoutine(event)}
                                                    sx={{
                                                      'width': '20px',
                                                      'height': '20px',
                                                      'padding': '0px',
                                                      'borderRadius': '2.5px',
                                                      'backgroundColor': COLORS.sub4,
                                                      ':hover': {
                                                        backgroundColor: COLORS.sub4,
                                                      },
                                                    }}
                                                  >
                                                    <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                  </IconButton>
                                                </TaskboxDetachButtonWrapper>
                                              </Tooltip>
                                            )}
                                            {!event.project && !event.routine && (
                                              <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '4px' }}
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                                  }}
                                                >
                                                  <Icons.TaskboxConvertButton />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                                }}
                                              >
                                                <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip
                                              title={
                                                language === 'ko' ? '더보기' : 'More'
                                                // 'More'
                                              }
                                              disableInteractive
                                            >
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) {
                                                    setSelectedTaskbox(event.id!);
                                                    setTaskboxDetail(e.currentTarget);
                                                  }
                                                }}
                                              >
                                                <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                      </TaskboxOptionsContainer>
                                    )}
                                  </div>
                                  <div className={`${event.id} `} {...provided.droppableProps} ref={provided.innerRef}>
                                    <SubtaskView>
                                      {event &&
                                        tasks?.map((task, index) => (
                                          <Draggable draggableId={task.id!} index={index} key={task.id}>
                                            {(provided) => (
                                              <EditableTextFieldWrapper
                                                {...provided.draggableProps}
                                                className="flex justify-between items-center relative"
                                                ref={provided.innerRef}
                                                key={task.id}
                                                draggable={true}
                                                hover={
                                                  multiSelected || subtaskMultiSelected
                                                    ? false
                                                    : !(selectedEvent?.id === event.id && selectedTaskId === task.id)
                                                }
                                                selectedTaskId={multiSelected ? false : selectedTaskId === task.id}
                                                multiSelected={subtaskMultiSelected && multiSelectSubtaskIds[0].subtaskIds.includes(task.id!)}
                                                onDragStart={(e) => {
                                                  handleSubtaskDragStart(e, task);
                                                }}
                                                onDragEnd={(e) => {
                                                  handleSubtaskDragEnd();
                                                }}
                                                onClick={(e) => {
                                                  if (e.metaKey || e.ctrlKey) {
                                                    handleMultiSelectSubtaskIds(task.id!, e);
                                                  }
                                                }}
                                                style={{ ...provided.draggableProps.style }}
                                              >
                                                <div className="flex items-center box-border task-container">
                                                  <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                                  <SubTaskCheckbox
                                                    focus={event.focus}
                                                    multiSelect={multiSelected}
                                                    bordercolor={
                                                      event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1
                                                    }
                                                    checked={!!task.done}
                                                    onClick={(e) => {
                                                      if (!e.metaKey && !e.ctrlKey) handleClickSubtaskCheckbox(index);
                                                    }}
                                                  />
                                                  <SubtaskField
                                                    className="subtask flex items-center ml-2"
                                                    ref={refTask}
                                                    data-id={
                                                      event.type === 'TERM_TASK'
                                                        ? `${task.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                        : `${task.id}`
                                                    }
                                                    data-timeboxid={event.id}
                                                    placeholder={
                                                      !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                        ? projectSubtasks[recommendTaskIndex].title === ''
                                                          ? language === 'ko'
                                                            ? '제목 없음'
                                                            : 'Untitled'
                                                          : projectSubtasks[recommendTaskIndex].title!
                                                        : 'Title'
                                                      // !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                      //   ? projectSubtasks[recommendTaskIndex].title === ''
                                                      //     ? 'Untitled'
                                                      //     : projectSubtasks[recommendTaskIndex].title!
                                                      //   : 'Title'
                                                    }
                                                    contentEditable={multiSelected ? false : true}
                                                    suppressContentEditableWarning={true}
                                                    spellCheck={false}
                                                    taskboxDone={event.done}
                                                    onBlur={(e) => handleBlurSubtask(e, index)}
                                                    onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                                    onInput={(e) => handleInputTask(e, task.id!)}
                                                    onClick={(e) => {
                                                      if (!e.metaKey && !e.ctrlKey) {
                                                        handleClickTaskbox(event);
                                                        handleSelectedTaskId && handleSelectedTaskId(task.id!);
                                                      }
                                                    }}
                                                  >
                                                    {task.content}
                                                  </SubtaskField>
                                                </div>
                                                {(!isInstanceTyping &&
                                                  selectedTaskId === task.id &&
                                                  event.project &&
                                                  projectSubtasks.length > 0 &&
                                                  task.content === '') ||
                                                (isRemoveTextAndRecommend && selectedTaskId === task.id && event.project && projectSubtasks.length > 0) ? (
                                                  !delayRecommendTask ? (
                                                    <RecommendTaskWrapper>
                                                      {language === 'ko' ? (
                                                        <>
                                                          {projectSubtasks.length > 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                              <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                              <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                              <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                            </div>
                                                          )}
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                                          <span>으로 추가하기</span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {projectSubtasks.length > 1 && (
                                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                              <span style={{ marginRight: 4 }}>Navigate with</span>
                                                              <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                              <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                              <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                            </div>
                                                          )}
                                                          <span>Add with</span>
                                                          <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect>
                                                        </>
                                                      )}
                                                      {/* {projectSubtasks.length > 1 && (
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                          <span style={{ marginRight: 4 }}>Navigate with</span>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                          <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                        </div>
                                                      )}
                                                      <span>Add with</span>
                                                      <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect> */}
                                                    </RecommendTaskWrapper>
                                                  ) : (
                                                    <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                                                  )
                                                ) : (
                                                  <>
                                                    {!multiSelected && !subtaskMultiSelected && (
                                                      <SubtaskAddsOnWrapper className="subtask-addson">
                                                        {task.category ? (
                                                          <CategoryShowingWrapper
                                                            className="detail task-detail"
                                                            textColor={getCategoryTextColor(task.category.color)}
                                                            bgColor={getCategoryBgColor(task.category.color)}
                                                            onClick={(e) => {
                                                              if (!e.metaKey && !e.ctrlKey) {
                                                                if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                setSubtaskId(task.id);
                                                                setCategoryAnchorEl(e.currentTarget);
                                                              }
                                                            }}
                                                            style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                          >
                                                            {`# ${task.category.name}`}
                                                            <IconButton
                                                              className="category-detach-button"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleClickCategoryActions(null, 'SELECT', task.id);
                                                              }}
                                                              sx={{
                                                                'width': '12px',
                                                                'height': '12px',
                                                                'borderRadius': '4px',
                                                                'marginLeft': '4px',
                                                                'padding': '0px',
                                                                ':hover': {
                                                                  backgroundColor: hexToRGBA(getCategoryTextColor(task.category!.color)!, 0.3),
                                                                },
                                                              }}
                                                              style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                            >
                                                              <Icons.Close width={8} height={8} stroke={getCategoryTextColor(task.category.color)} />
                                                            </IconButton>
                                                          </CategoryShowingWrapper>
                                                        ) : (
                                                          <IconButton
                                                            className="detail task-detail"
                                                            onClick={(e) => {
                                                              if (!e.metaKey && !e.ctrlKey) {
                                                                if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                setSubtaskId(task.id);
                                                                setCategoryAnchorEl(e.currentTarget);
                                                              }
                                                            }}
                                                            sx={{
                                                              width: '20px',
                                                              height: '20px',
                                                              borderRadius: '4px',
                                                              padding: '0px',
                                                              marginRight: '4px',
                                                            }}
                                                            style={
                                                              categoryAnchorEl && subtaskId === task.id && !multiSelected
                                                                ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                : {}
                                                            }
                                                          >
                                                            <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                                          </IconButton>
                                                        )}
                                                        {task.deadline ? (
                                                          <DeadlineShowingWrapper
                                                            className="detail task-detail"
                                                            date={task.deadline}
                                                            onClick={(e) => {
                                                              if (!e.metaKey && !e.ctrlKey) {
                                                                if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                setSubtaskId(task.id);
                                                                setDeadlineAnchorEl(e.currentTarget);
                                                              }
                                                            }}
                                                            style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                          >
                                                            <Icons.Flag
                                                              fill={
                                                                dayjs(task!.deadline).isToday()
                                                                  ? COLORS.brand1
                                                                  : dayjs(task!.deadline).isBefore(dayjs())
                                                                  ? COLORS.negative1
                                                                  : COLORS.gray600
                                                              }
                                                            />
                                                            <span style={{ marginLeft: '2px' }}>
                                                              {dayjs(task.deadline).isToday()
                                                                ? language === 'ko'
                                                                  ? '오늘'
                                                                  : 'Today'
                                                                : dayjs(task.deadline).isYesterday()
                                                                ? language === 'ko'
                                                                  ? '어제'
                                                                  : 'Yesterday'
                                                                : dayjs(task.deadline).isTomorrow()
                                                                ? language === 'ko'
                                                                  ? '내일'
                                                                  : 'Tomorrow'
                                                                : language === 'ko'
                                                                ? dayjs(task.deadline).format('M월 D일 (dd)')
                                                                : dayjs(task.deadline).format('dddd, MMM D')}
                                                              {/* {dayjs(task.deadline).isToday()
                                                                ? 'Today'
                                                                : dayjs(task.deadline).isYesterday()
                                                                ? 'Yesterday'
                                                                : dayjs(task.deadline).isTomorrow()
                                                                ? 'Tomorrow'
                                                                : dayjs(task.deadline).format('dddd, MMM D')} */}
                                                            </span>
                                                            <IconButton
                                                              className="deadline-detach-button"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleChangeDeadline(null, task.id);
                                                              }}
                                                              sx={{
                                                                'width': '12px',
                                                                'height': '12px',
                                                                'borderRadius': '4px',
                                                                'marginLeft': '4px',
                                                                'padding': '0px',
                                                                ':hover': {
                                                                  backgroundColor: hexToRGBA(
                                                                    dayjs(task!.deadline).isToday()
                                                                      ? COLORS.brand1
                                                                      : dayjs(task!.deadline).isBefore(dayjs())
                                                                      ? COLORS.negative1
                                                                      : COLORS.gray600,
                                                                    0.3,
                                                                  ),
                                                                },
                                                              }}
                                                              style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                            >
                                                              <Icons.Close
                                                                width={8}
                                                                height={8}
                                                                stroke={
                                                                  dayjs(task!.deadline).isToday()
                                                                    ? COLORS.brand1
                                                                    : dayjs(task!.deadline).isBefore(dayjs())
                                                                    ? COLORS.negative1
                                                                    : COLORS.gray600
                                                                }
                                                              />
                                                            </IconButton>
                                                          </DeadlineShowingWrapper>
                                                        ) : (
                                                          <IconButton
                                                            className="detail task-detail"
                                                            onClick={(e) => {
                                                              if (!e.metaKey && !e.ctrlKey) {
                                                                if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                setSubtaskId(task.id);
                                                                setDeadlineAnchorEl(e.currentTarget);
                                                              }
                                                            }}
                                                            sx={{
                                                              width: '20px',
                                                              height: '20px',
                                                              borderRadius: '4px',
                                                              padding: '0px',
                                                              marginRight: '4px',
                                                            }}
                                                            style={
                                                              deadlineAnchorEl && subtaskId === task.id && !multiSelected
                                                                ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                : {}
                                                            }
                                                          >
                                                            <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                                          </IconButton>
                                                        )}
                                                        {event.project ? (
                                                          <>
                                                            <Divider
                                                              className="detail task-detail"
                                                              orientation="vertical"
                                                              flexItem
                                                              style={{ margin: '0px 4px 0px 0px' }}
                                                            />
                                                            <Tooltip
                                                              title={
                                                                isNextInstance ? (
                                                                  <div>
                                                                    <div>
                                                                      {language === 'ko' ? '다음 인스턴스로 이동해요' : 'Move to next instance'}
                                                                      {/* Move to next instance */}
                                                                    </div>
                                                                    <div style={{ fontWeight: 700 }}>
                                                                      {language === 'ko'
                                                                        ? isNextInstance.start?.datetime
                                                                          ? dayjs(isNextInstance.start?.datetime).format('M월 D일 dddd A h:mm')
                                                                          : dayjs(isNextInstance.start?.date).format('M월 D일 dddd')
                                                                        : isNextInstance.start?.datetime
                                                                        ? dayjs(isNextInstance.start?.datetime).format('ddd, MMM D dddd A h:mm')
                                                                        : dayjs(isNextInstance.start?.date).format('ddd, MMM D')}
                                                                      {/* {isNextInstance.start?.datetime
                                                                        ? dayjs(isNextInstance.start?.datetime).format('dddd, MMM D dddd A h:mm')
                                                                        : dayjs(isNextInstance.start?.date).format('dddd, MMM D')} */}
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <div>
                                                                    {language === 'ko' ? '프로젝트의 미할당으로 이동해요' : 'Move to project unassigned'}
                                                                    {/* Move to project unassigned */}
                                                                  </div>
                                                                )
                                                              }
                                                              disableInteractive
                                                            >
                                                              <TaskIconWrapper
                                                                className="detail task-detail"
                                                                aria-label="later"
                                                                onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                              >
                                                                <Icons.ArrowRightLong width={16} height={16} stroke={COLORS.gray400} />
                                                              </TaskIconWrapper>
                                                            </Tooltip>
                                                          </>
                                                        ) : (
                                                          <TaskIconWrapper
                                                            className="detail task-detail"
                                                            aria-label="later"
                                                            onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                          >
                                                            <div className="later">
                                                              <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                                                            </div>
                                                          </TaskIconWrapper>
                                                        )}
                                                        <TaskIconWrapper
                                                          className="detail task-detail"
                                                          aria-label="delete"
                                                          onClick={() => handleClickSubtaskContextMenu(task.id!, 'DELETE')}
                                                          style={{ marginRight: event.project ? 0 : 4 }}
                                                        >
                                                          <div className="delete">
                                                            <Icons.Delete fill={COLORS.gray400} />
                                                          </div>
                                                        </TaskIconWrapper>
                                                        {!event.project && (
                                                          <div
                                                            className="detail task-detail"
                                                            style={subtaskDetail && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                            onClick={(e) => {
                                                              if (!e.metaKey && !e.ctrlKey) {
                                                                if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                setSubtaskId(task.id);
                                                                setSubtaskDetail(e.currentTarget);
                                                              }
                                                            }}
                                                          >
                                                            <Icons.ColorMore />
                                                          </div>
                                                        )}
                                                      </SubtaskAddsOnWrapper>
                                                    )}
                                                  </>
                                                )}
                                                {/* 태스크 카테고리 */}
                                                {subtaskId === task.id && (
                                                  <Popover
                                                    open={Boolean(categoryAnchorEl)}
                                                    anchorEl={categoryAnchorEl}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'left',
                                                    }}
                                                    onClose={() => {
                                                      setSelectedTaskbox(undefined);
                                                      setSubtaskId(undefined);
                                                      setCategoryAnchorEl(null);
                                                    }}
                                                    sx={{ marginTop: '4px' }}
                                                  >
                                                    <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
                                                  </Popover>
                                                )}
                                                {/* 태스크 기한 */}
                                                {subtaskId === task.id && (
                                                  <Popover
                                                    open={Boolean(deadlineAnchorEl)}
                                                    anchorEl={deadlineAnchorEl}
                                                    anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                      vertical: 'top',
                                                      horizontal: 'left',
                                                    }}
                                                    onClose={() => {
                                                      setSelectedTaskbox(undefined);
                                                      setSubtaskId(undefined);
                                                      setDeadlineAnchorEl(null);
                                                    }}
                                                    sx={{ marginTop: '4px' }}
                                                  >
                                                    <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
                                                  </Popover>
                                                )}

                                                {subtaskId === task.id && (
                                                  <SubtaskDetail
                                                    taskId={task.id}
                                                    detail={subtaskDetail}
                                                    menus={['CONVERT_TO_TASKBOX']}
                                                    onCloseDetail={handleCloseSubtaskDetail}
                                                    onClickSubtaskContextMenu={handleClickSubtaskContextMenu}
                                                  />
                                                )}
                                              </EditableTextFieldWrapper>
                                            )}
                                          </Draggable>
                                        ))}
                                      {provided!.placeholder!}
                                      <TaskboxAddOnWrapper multiSelected={multiSelected}>
                                        {event.project && projectSubtasks.length > 0 ? (
                                          <SubtaskWrapper
                                            data-timeboxid={event.id}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                if (subtaskMode === 'ADD') {
                                                  handleClickTaskAdd(event);
                                                } else {
                                                  handleClickInstanceTitle(event);
                                                  setIsLoadSubtaskFromProject(true);
                                                }
                                              }
                                            }}
                                          >
                                            {subtaskMode === 'ADD' ? (
                                              <Icons.Plus width={12} height={12} stroke={COLORS.gray500} />
                                            ) : (
                                              <Icons.LoadToInstance width={12} height={12} fill={COLORS.gray500} />
                                            )}
                                            <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                              {subtaskMode === 'ADD'
                                                ? language === 'ko'
                                                  ? '하위 업무 추가하기'
                                                  : 'Add subtask'
                                                : language === 'ko'
                                                ? '하위 업무 가져오기'
                                                : 'Import subtask'}
                                              {/* Add subtask */}
                                            </Fonts.Badge>
                                            <IconButton
                                              onClick={(e) => handleClickSubtaskModeIconBtn(e)}
                                              sx={{
                                                marginLeft: '4px',
                                                padding: '0px',
                                              }}
                                            >
                                              <Icons.ArrowDownSmall />
                                            </IconButton>
                                          </SubtaskWrapper>
                                        ) : (
                                          <SubtaskWrapper
                                            data-timeboxid={event.id}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                handleClickTaskAdd(event);
                                              }
                                            }}
                                          >
                                            <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
                                            <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                              {language === 'ko' ? '하위 업무 추가하기' : 'Add subtask'}
                                              {/* Add subtask */}
                                            </Fonts.Badge>
                                          </SubtaskWrapper>
                                        )}
                                        <AdditionalAddOnButton
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) handleClickEmptyTime(event.id!);
                                          }}
                                          style={{ marginLeft: 4 }}
                                        >
                                          <Icons.Time width={12} height={12} stroke={COLORS.gray500} />
                                          <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                            {language === 'ko' ? '시간 추가하기' : 'Add time'}
                                          </Fonts.Badge>
                                        </AdditionalAddOnButton>
                                        {!event.routine && (
                                          <AdditionalAddOnButtonWrapper className="additional-btn">
                                            <Divider orientation="vertical" flexItem sx={{ margin: '0px 6px' }} />
                                            {event && !event.category && (
                                              <Tooltip
                                                title={
                                                  language === 'ko' ? '카테고리 설정하기' : 'Set category'
                                                  // 'Set category'
                                                }
                                                disableInteractive
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) handleClickEmptyCategory(event.id!);
                                                  }}
                                                  sx={{
                                                    'width': '24px',
                                                    'height': '24px',
                                                    'border': `1px solid ${COLORS.gray200}`,
                                                    'borderRadius': '4px',
                                                    'padding': '0px',
                                                    'marginRight': '4px',
                                                    ':hover': {
                                                      backgroundColor: COLORS.gray100,
                                                    },
                                                  }}
                                                >
                                                  <Icons.Hashtag />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                            {event && !event.deadline && (
                                              <Tooltip
                                                title={
                                                  language === 'ko' ? '기한 설정하기' : 'Set deadline'
                                                  // 'Set deadline'
                                                }
                                                disableInteractive
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) handleClickEmptyDeadline(event.id!);
                                                  }}
                                                  sx={{
                                                    'width': '24px',
                                                    'height': '24px',
                                                    'border': `1px solid ${COLORS.gray200}`,
                                                    'borderRadius': '4px',
                                                    'padding': '0px',
                                                    'marginRight': '4px',
                                                    ':hover': {
                                                      backgroundColor: COLORS.gray100,
                                                    },
                                                  }}
                                                >
                                                  <Icons.Flag />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </AdditionalAddOnButtonWrapper>
                                        )}
                                      </TaskboxAddOnWrapper>
                                      {event.routine && changedRoutineInstanceId === event.id && (
                                        <UpdateRoutineInstanceWrapper
                                          onClick={(e) => {
                                            handleReflectRoutine();
                                          }}
                                        >
                                          <Icons.UpdateRecurrence />
                                          <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>
                                            {language === 'ko' ? '이후 인스턴스에 변경 일정 반영하기' : 'Apply changes to future instances'}
                                            {/* Apply changes to future instances */}
                                          </Fonts.Body2>
                                          <IconButton
                                            className="apply-changes-btn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setChangedRoutineInstanceId(null);
                                            }}
                                            sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px', opacity: 0 }}
                                          >
                                            <Icons.Close width={12} height={12} stroke={COLORS.gray300} />
                                          </IconButton>
                                        </UpdateRoutineInstanceWrapper>
                                      )}
                                    </SubtaskView>
                                  </div>
                                  {event.memo && event.memo.content.length > 0 && focusedTaskbox && focusedTaskbox.id === event.id && (
                                    <ShowingMemoWrapper>
                                      <Divider sx={{ margin: '4px 0px' }} />
                                      <Tooltip
                                        title={
                                          language === 'ko' ? '포커스모드에서 편집하기' : 'Edit in focus mode'
                                          // 'Edit in focus mode'
                                        }
                                        followCursor
                                      >
                                        <ShowingMemoTextWrapper
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              handleClickFocusMode(event);
                                            }
                                          }}
                                        >
                                          {event.memo?.content?.[0]?.content?.[0]?.text && <div>{event.memo.content[0].content[0].text}</div>}
                                          {event.memo?.content?.[1]?.content?.[0]?.text && <div>{event.memo.content[1].content[0].text}</div>}
                                        </ShowingMemoTextWrapper>
                                      </Tooltip>
                                    </ShowingMemoWrapper>
                                  )}
                                </div>
                              </div>
                            )}
                          </TaskDragContainer>
                        )}
                      </Droppable>
                    ) : (
                      <TaskDragContainer
                        className="timeline-container"
                        key={event?.id}
                        data-scroll={`${event?.id}`}
                        event={event}
                        taskboxDone={event.done}
                        focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                        plan={plan}
                        hidden={draggingToTaskbox}
                        selectedTaskbox={selectedTaskbox}
                        multiSelected={multiSelected}
                        onClick={(e) => {
                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                        }}
                      >
                        {event && (
                          <div
                            className="flex gap-4 timeline-border"
                            onClick={(e) => {
                              if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                            }}
                          >
                            <div
                              className="line"
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                              }}
                            />
                            <div
                              className="flex-1"
                              style={{ pointerEvents: 'all', width: 'calc(100% - 20px)' }}
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                              }}
                            >
                              <TaskboxPropertyWrapper style={{ justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {(event.category || isSettingUnderbarCategory) && (
                                    <CategoryShowingWrapper
                                      data-category-taskbox-id={event.id}
                                      textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                      bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setCategoryAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                      {event.category && (
                                        <IconButton
                                          className="category-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickCategoryActions(null, 'SELECT');
                                            setIsSettingUnderbarCategory(false);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                            },
                                          }}
                                          style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category!.color)} />
                                        </IconButton>
                                      )}
                                    </CategoryShowingWrapper>
                                  )}
                                  {(event.deadline || isSettingUnderbarDeadline) && (
                                    <DeadlineShowingWrapper
                                      data-deadline-taskbox-id={event.id}
                                      date={event.deadline || ''}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setDeadlineAnchorEl(e.currentTarget);
                                      }}
                                      style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                                    >
                                      <Icons.Flag
                                        fill={
                                          !event.deadline
                                            ? COLORS.gray500
                                            : dayjs(event!.deadline).isToday()
                                            ? COLORS.brand1
                                            : dayjs(event!.deadline).isBefore(dayjs())
                                            ? COLORS.negative1
                                            : COLORS.gray600
                                        }
                                      />
                                      <span style={{ marginLeft: '2px' }}>
                                        {!event.deadline
                                          ? language === 'ko'
                                            ? '기한 설정'
                                            : 'Set deadline'
                                          : dayjs(event.deadline).isToday()
                                          ? language === 'ko'
                                            ? '오늘'
                                            : 'Today'
                                          : dayjs(event.deadline).isYesterday()
                                          ? language === 'ko'
                                            ? '어제'
                                            : 'Yesterday'
                                          : dayjs(event.deadline).isTomorrow()
                                          ? language === 'ko'
                                            ? '내일'
                                            : 'Tomorrow'
                                          : language === 'ko'
                                          ? dayjs(event.deadline).format('M월 D일 (dd)')
                                          : dayjs(event.deadline).format('dddd, MMM D')}
                                      </span>
                                      {event.deadline && (
                                        <IconButton
                                          className="deadline-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChangeDeadline(null);
                                            setIsSettingUnderbarDeadline(false);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(
                                                dayjs(event!.deadline).isToday()
                                                  ? COLORS.brand1
                                                  : dayjs(event!.deadline).isBefore(dayjs())
                                                  ? COLORS.negative1
                                                  : COLORS.gray600,
                                                0.3,
                                              ),
                                            },
                                          }}
                                          style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close
                                            width={8}
                                            height={8}
                                            stroke={
                                              dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600
                                            }
                                          />
                                        </IconButton>
                                      )}
                                    </DeadlineShowingWrapper>
                                  )}
                                  {/**지속 시간만 있는 태스크박스 */}
                                  {event.durationMin && event.durationMin > 0 && (
                                    <div className="time-modal-container">
                                      <Icons.Time stroke={COLORS.gray700} />
                                      <Fonts.Blockquote
                                        className="text-gray700"
                                        onClick={(e) => {
                                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleOpenSimpleTimeSelectionModal(e);
                                          }
                                        }}
                                        style={{ marginLeft: '4px' }}
                                      >
                                        {showingTime(undefined, event.durationMin)}
                                      </Fonts.Blockquote>
                                      {!multiSelected ? (
                                        <Tooltip
                                          title={
                                            language === 'ko' ? '시간 해제하기' : 'Remove time'
                                            // 'Remove time'
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            className="unselect-time"
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                              }
                                            }}
                                            sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                          >
                                            <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        <div style={{ width: '24px', height: '24px' }} />
                                      )}
                                    </div>
                                  )}
                                  {event.type === 'TERM_TASK' && event.matchDate && (
                                    <Tooltip
                                      title={
                                        language === 'ko'
                                          ? `${dayjs(event.matchDate[0]).format('M월D일')}~${dayjs(event.matchDate[event.matchDate.length - 1]).format(
                                              'M월D일',
                                            )}`
                                          : `${dayjs(event.matchDate[0]).format('MMM D')}~${dayjs(event.matchDate[event.matchDate.length - 1]).format('MMM D')}`
                                        // `${dayjs(event.matchDate?.[0]).format('MMM D')}~${dayjs(event.matchDate?.[event.matchDate.length - 1]).format('MMM D')}`
                                      }
                                    >
                                      <DateRangeShowingWrapper>
                                        <Icons.RangeTaskbox />
                                        <span style={{ marginLeft: '4px' }}>
                                          {language === 'ko'
                                            ? `${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${event.matchDate?.length}일째`
                                            : `Day ${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${
                                                event.matchDate?.length
                                              }`}
                                          {/* {`Day ${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${event.matchDate?.length}`} */}
                                        </span>
                                      </DateRangeShowingWrapper>
                                    </Tooltip>
                                  )}
                                </div>
                                {event.type === 'TERM_TASK' && (
                                  <Tooltip
                                    title={
                                      language === 'ko' ? '종료일이 지정되어 상단에 고정됩니다.' : 'End date set, pinned at the top.'
                                      // '종료일이 지정되어 상단에 고정됩니다.'
                                    }
                                    disableInteractive
                                  >
                                    <div>
                                      <Icons.ColorPin stroke={COLORS.brand1} />
                                    </div>
                                  </Tooltip>
                                )}
                              </TaskboxPropertyWrapper>
                              {event.project && event.project.title !== event.title && (
                                <InstanceTitleWrapper
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickInstanceTitle(event);
                                      setIsLoadSubtaskFromProject(false);
                                    }
                                  }}
                                >
                                  <Icons.Issue />
                                  <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                                  <Tooltip
                                    title={
                                      <div>
                                        <div style={{ fontWeight: 700 }}>
                                          {language === 'ko' ? '프로젝트와 연결 해지하기' : 'Unlink from project'}
                                          {/* Unlink from project */}
                                        </div>
                                        <div>
                                          {language === 'ko'
                                            ? '프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다'
                                            : 'Unlink from project and convert to a regular taskbox'}
                                          {/* Unlink from project and convert to a regular taskbox */}
                                        </div>
                                      </div>
                                    }
                                    disableInteractive
                                  >
                                    <IconButton
                                      className="unlink-project"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleUnlinkProject(event);
                                      }}
                                      sx={{
                                        'width': '24px',
                                        'height': '24px',
                                        'borderRadius': '4px',
                                        'marginLeft': '4px',
                                        'padding': '4px',
                                        'display': 'none',
                                        ':hover': {
                                          backgroundColor: COLORS.gray100,
                                        },
                                      }}
                                    >
                                      <Icons.UnlinkProject />
                                    </IconButton>
                                  </Tooltip>
                                </InstanceTitleWrapper>
                              )}
                              {event.routine && event.routine.title !== event.title && (
                                <InstanceTitleWrapper
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickRoutineTitle(event);
                                    }
                                  }}
                                >
                                  <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                  <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                                </InstanceTitleWrapper>
                              )}
                              <div className=" flex justify-between items-center relative timeline-header">
                                {/* 타임박스 제목 */}
                                <div
                                  className="taskbox-title-container"
                                  style={{
                                    width: `calc(100% - ${foldTaskboxFieldWidth}px)`,
                                  }}
                                >
                                  {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                    <Checkbox
                                      checked={false}
                                      icon={
                                        event.focus ? (
                                          <Icons.FocusInprogressCheck />
                                        ) : (
                                          <Icons.InprogressCheck
                                            fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                          />
                                        )
                                      }
                                      sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                      style={{ width: 24, height: 24, marginRight: 8 }}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if (event.type === 'TERM_TASK') {
                                            handleClickTaskboxCheck();
                                          } else {
                                            setSelectedTaskbox(event.id);
                                            setInprogressPopover(e.currentTarget);
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <PriorityIssueCheckbox
                                      width={24}
                                      height={24}
                                      focus={event.focus}
                                      bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                      markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                      checked={!!event.done}
                                      // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                      // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                      }}
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {isExploding && event.focus && (
                                    <ConfettiExplosion
                                      force={0.6}
                                      duration={2500}
                                      particleCount={80}
                                      width={1000}
                                      onComplete={() => setIsExploding(false)}
                                      style={{ position: 'absolute', top: 0, left: 0 }}
                                    />
                                  )}
                                  <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                    {event.project && event.project.title === event.title && (
                                      <InstanceIcon>
                                        <Icons.Issue />
                                      </InstanceIcon>
                                    )}
                                    {event.routine && event.routine.title === event.title && (
                                      <InstanceIcon>
                                        <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                      </InstanceIcon>
                                    )}
                                    {event.lockedIn && <LockInIcon event={event} />}
                                    <TaskboxInput
                                      ref={refTaskboxInput}
                                      className="taskbox-title"
                                      data-id={
                                        event.type === 'TERM_TASK'
                                          ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                          : `${event.id}`
                                      }
                                      onBlur={(e) => handleBlurTitle(e, event)}
                                      onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                      onInput={(e) => handleTaskboxInput(e)}
                                      placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                      contentEditable={multiSelected ? false : true}
                                      suppressContentEditableWarning={true}
                                      spellCheck={false}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if (event.routine && event.routine.title === event.title) {
                                            handleClickRoutineTitle(event);
                                          } else if (event.project && event.project.title === event.title) {
                                            handleClickInstanceTitle(event);
                                            setIsLoadSubtaskFromProject(false);
                                          } else if (!event.project && !event.routine) {
                                            handleClickTaskbox(event);
                                            setGetCaret(window.getSelection()!.anchorOffset);
                                            if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                              setTaskboxDetailView(event);
                                            }
                                          }
                                        }
                                      }}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {event.title}
                                    </TaskboxInput>
                                  </TaskboxInputWrapper>
                                  {event.issueId && (
                                    <Button
                                      variant="outlined"
                                      color="inherit"
                                      onClick={() => handleClickIssueDetail(event.issueId!)}
                                      sx={{
                                        width: '100px',
                                        height: '24px',
                                        borderRadius: '8px',
                                        padding: '0px',
                                        color: `${COLORS.gray400}`,
                                        border: `1px solid ${COLORS.gray400}`,
                                      }}
                                    >
                                      <div className="timelineEvents-issue-detail">
                                        <IssueLinkage />
                                        <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                      </div>
                                    </Button>
                                  )}
                                </div>
                                <div data-foldShowingId={`${event.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                                  {event.memo && <Icons.TaskboxMemo />}
                                  {event.links!.length > 0 && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                    </div>
                                  )}
                                  {(event.isRecurrence || event.routine) && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Recurrence />
                                    </div>
                                  )}
                                  {tasks.length > 0 && (
                                    <div className="show-subtask">
                                      <Icons.Subtask />
                                      <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                    </div>
                                  )}
                                </div>
                                {!multiSelected && (
                                  <TaskboxOptionsContainer
                                    className="taskbox-option"
                                    titleLength={taskboxTitleLength}
                                    style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                  >
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                handleClickStartNow(event, true);
                                              }
                                            }}
                                          >
                                            <Icons.StartNowTaskboxAction />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        {event.project && (
                                          <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                            <TaskboxDetachButtonWrapper>
                                              <IconButton
                                                onClick={() => handleDetachTaskboxFromProject(event)}
                                                sx={{
                                                  'width': '20px',
                                                  'height': '20px',
                                                  'padding': '0px',
                                                  'borderRadius': '2.5px',
                                                  'backgroundColor': COLORS.issue2,
                                                  ':hover': {
                                                    backgroundColor: COLORS.issue2,
                                                  },
                                                }}
                                              >
                                                <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                              </IconButton>
                                            </TaskboxDetachButtonWrapper>
                                          </Tooltip>
                                        )}
                                        {event.routine && (
                                          <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                            <TaskboxDetachButtonWrapper>
                                              <IconButton
                                                onClick={() => handleDetachTaskboxFromRoutine(event)}
                                                sx={{
                                                  'width': '20px',
                                                  'height': '20px',
                                                  'padding': '0px',
                                                  'borderRadius': '2.5px',
                                                  'backgroundColor': COLORS.sub4,
                                                  ':hover': {
                                                    backgroundColor: COLORS.sub4,
                                                  },
                                                }}
                                              >
                                                <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                              </IconButton>
                                            </TaskboxDetachButtonWrapper>
                                          </Tooltip>
                                        )}
                                        {!event.project && !event.routine && (
                                          <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '4px' }}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                              }}
                                            >
                                              <Icons.TaskboxConvertButton />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                            }}
                                          >
                                            <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip
                                          title={
                                            language === 'ko' ? '더보기' : 'More'
                                            // 'More'
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                setSelectedTaskbox(event.id!);
                                                setTaskboxDetail(e.currentTarget);
                                              }
                                            }}
                                          >
                                            <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                  </TaskboxOptionsContainer>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </TaskDragContainer>
                    )}
                  </TaskboxDragContainer>
                );
              }}
            </Draggable>
            {/* 하이라이트 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(highlightTimeSelection)}
                anchorEl={highlightTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseHighlightTimeSelectionModal}
              >
                <HighlightTimeSelectionModal event={event} onClose={handleCloseHighlightTimeSelectionModal} onClick={handleClickHighlightTime} />
              </Popover>
            )}
            {/** 간편 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(simpleTimeSelection)}
                anchorEl={simpleTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                disableEscapeKeyDown
              >
                <SimpleTimeSelectionModal
                  event={event}
                  autoSetting={isSettingUnderbarTime}
                  onClose={handleCloseSimpleTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                />
              </Popover>
            )}
            {/* 날짜 및 시간 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(timeSelection)}
                anchorEl={timeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseTimeSelectionModal}
              >
                <TimeDateSelectionModal
                  event={event}
                  suppressMoveDate={event.type === 'TERM_TASK'}
                  onClose={handleCloseTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                  onUnlinkRoutine={handleUnlinkRoutine}
                />
              </Popover>
            )}
            {/* 태스크박스 상세 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <TimelineTaskboxDetail
                event={event}
                detail={taskboxDetail}
                focusedTaskbox={focusedTaskbox}
                suppressDivider={!!event.project}
                onCloseDetail={handleCloseTimelineDetail}
                onClickMenu={handleClickTaskboxDetailMenu}
              />
            )}
            {/**이어서 완료하기 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(inprogressPopover)}
                anchorEl={inprogressPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handleCloseInprogressPopover}
                sx={{ marginTop: '4px' }}
              >
                <InprogressTaskbox>
                  <InprogressTaskboxContent
                    onClick={() => {
                      handleClickTaskboxCheck();
                      handleCloseInprogressPopover();
                    }}
                  >
                    {event.focus ? (
                      <Icons.FocusCheck width={16} height={16} />
                    ) : (
                      <Icons.TaskboxCheck
                        width={16}
                        height={16}
                        fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                      />
                    )}
                    <span style={{ marginLeft: '8px' }}>
                      {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                      {/* Complete all */}
                    </span>
                  </InprogressTaskboxContent>
                  {event.project ? (
                    <Tooltip
                      title={
                        <span>
                          {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                          <br />
                          {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                        </span>
                        // <span>
                        //   Complete the instance and send any incomplete
                        //   <br />
                        //   subtasks to the next instance or project.
                        // </span>
                      }
                      placement="right"
                    >
                      <InprogressTaskboxContent onClick={handleClickInstanceContinue}>
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '8px' }}>
                          {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                          {/* Complete some */}
                        </span>
                        <Icons.QuestionInfo style={{ marginLeft: '4px' }} />
                      </InprogressTaskboxContent>
                    </Tooltip>
                  ) : (
                    <InprogressTaskboxContent
                      onClick={(e) => {
                        setContinueSettingPopover(e.currentTarget);
                      }}
                    >
                      {event.focus ? (
                        <Icons.ContinueFocusTaskbox />
                      ) : (
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1} />
                      )}
                      <span style={{ marginLeft: '8px' }}>
                        {language === 'ko' ? '일부 완료하고 이어서하기' : 'Complete some and continue'}
                        {/* Complete some and continue */}
                      </span>
                    </InprogressTaskboxContent>
                  )}
                </InprogressTaskbox>
              </Popover>
            )}
            {/** 태스크박스 이어서하기 설정창 */}
            <TaskboxContinueDetail
              anchorEl={continueSettingPopover}
              event={event}
              onCloseContinue={handleCloseContinueSettingPopover}
              onChangeDate={handleChangeInprogress}
            />
            {/* 태스크박스 카테고리 */}
            <Popover
              open={Boolean(categoryAnchorEl)}
              anchorEl={categoryAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setSelectedTaskbox(undefined);
                setCategoryAnchorEl(null);
                if (isQuickCategory) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickCategory(false);
                }
                if (isSettingUnderbarCategory) setIsSettingUnderbarCategory(false);
              }}
              sx={{ marginTop: '4px' }}
            >
              <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
            </Popover>
            {/* 태스크박스 기한 */}
            <Popover
              open={Boolean(deadlineAnchorEl)}
              anchorEl={deadlineAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setSelectedTaskbox(undefined);
                setDeadlineAnchorEl(null);
                if (isQuickDeadline) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickDeadline(false);
                }
                if (isSettingUnderbarDeadline) setIsSettingUnderbarDeadline(false);
              }}
              sx={{ marginTop: '4px' }}
            >
              <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
            </Popover>
            {/* 태스크박스 프로적트 리스트 팝오버 */}
            <Popover
              open={Boolean(projectListAnchorEl)}
              anchorEl={projectListAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setProjectListAnchorEl(null);
                if (isQuickProject) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickProject(false);
                }
              }}
              sx={{ marginTop: '4px' }}
            >
              <ProjectListPopover onClick={handleClickProjectList} />
            </Popover>
            {/* 태스크박스 멀티셀렉  */}
            {multiSelectIds[multiSelectIds.length - 1] === event!.id && (
              <Popper open={Boolean(multiSelectAnchorEl)} anchorEl={multiSelectAnchorEl} placement={'right-start'} ref={refPopperMenu} sx={{ zIndex: 1000 }}>
                <TaskboxMultiSelectPopover
                  date={dayjs(
                    events?.find((v) => v.id === multiSelectIds[0])?.start?.date || events?.find((v) => v.id === multiSelectIds[0])?.start?.datetime,
                  ).toDate()}
                  multiSelectCount={multiSelectIds.length}
                  openDeleteDialog={isOpenMultiSelectDeleteDialog}
                  onClickMultiSelectActions={onClickMultiSelectActions}
                  onOpenDeleteDialog={handleOpenMultiSelectDeleteDialog}
                  onCloseDeleteDialog={handleCloseMultiSelectDeleteDialog}
                />
              </Popper>
            )}
            {/** 하위업무 멀티셀렉 */}
            {multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].taskboxId === event.id && (
              <Popper
                open={Boolean(multiSelectSubtaskAnchorEl)}
                anchorEl={multiSelectSubtaskAnchorEl}
                placement={'right-start'}
                ref={refPopperMenu}
                sx={{ zIndex: 1000 }}
              >
                <SubtaskMultiSelectPopover
                  menus={event.project ? ['MOVE_TO_AFTER', 'DELETE'] : ['CONVERT_TO_TASKBOX', 'MOVE_TO_AFTER', 'DELETE']}
                  subtaskIds={multiSelectSubtaskIds[0].subtaskIds}
                  onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
                />
              </Popper>
            )}
            {/** 태스크박스 컨텍스트 메뉴 */}
            <Popover
              open={Boolean(contextMenuPopover)}
              anchorEl={contextMenuPopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setContextMenuPopover(null);
                setTimeout(() => {
                  setContextTaskbox(null);
                }, 100);
              }}
            >
              <InboxContextMenu
                id={event.id!}
                type={contextTaskbox?.project ? 'instance' : 'taskbox'}
                menus={contextMenus}
                onClickMenu={handleClickContextMenu}
              />
            </Popover>
            {/** 하위업무 모드 선택 */}
            <Popover
              open={Boolean(subtaskModeAnchorEl)}
              anchorEl={subtaskModeAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => setSubtaskModeAnchorEl(null)}
            >
              <SubtaskModeMenu mode={subtaskMode} onChange={handleChangeSubtaskMode} />
            </Popover>
            {/** 태스크박스 프로젝트/루틴으로 전환 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(convertAnchorEl)}
                anchorEl={convertAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setConvertAnchorEl(null);
                  setSelectedTaskbox(undefined);
                }}
                sx={{ marginTop: '4px' }}
              >
                <TaskboxConvertMenu types={['PROJECT', 'ROUTINE']} onClick={handleClickTaskboxConvert} />
              </Popover>
            )}
            {/** 태스크박스 미루기  */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(taskboxPostponeAnchorEl)}
                anchorEl={taskboxPostponeAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setTaskboxPostponeAnchorEl(null);
                  setSelectedTaskbox(undefined);
                }}
                sx={{ marginTop: '4px' }}
              >
                <TaskboxPostponeDetail event={event} onChangeDate={handleChangeTaskboxDate} />
              </Popover>
            )}
            {/** 루틴 생성 창 */}
            <RoutineCreationView open={Boolean(convertToRoutineTaskbox)} event={convertToRoutineTaskbox} onClose={handleCloseCreateRoutine} />
            {/** 자주 하는 업무 팝오버 */}
            {event.id === selectedTaskbox && (
              <TemplateDialog
                open={openTemplateDialog}
                templateList={templateList}
                categoryList={categoryList}
                onClose={handleCloseTemplateDialog}
                onPut={handlePutTemplate}
                onDelete={handleDeleteTemplate}
                onAdd={handleTemplateToTaskbox}
                onClickCategory={handleClickCategoryActions}
              />
            )}
            {/** 포커스 모드 dialog */}
            {focusModeTaskbox && event.id === focusModeTaskbox!.id && (
              <FocusModeDialog
                open={Boolean(focusModeTaskbox) && !hideFocusMode}
                taskbox={focusModeTaskbox}
                templateList={templateList}
                hide={hideFocusMode}
                categoryList={categoryList}
                onFetch={handleFetchFocusMode}
                onClose={handleCloseFocusMode}
                onHide={handleHideFocusMode}
                onDelete={handleDeleteFocusMode}
                onCreateTemplate={handleCreateTemplate}
                onPutTemplate={handlePutTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onChangeInprogress={handleChangeFocusModeInprogress}
                onChangeTimer={onChangeTimer}
                onClickCategoryActions={handleClickCategoryActions}
                onChangeDeadline={handleChangeDeadline}
                onClickInstanceContinue={handleClickInstanceContinue}
                onChangeInstanceSubtask={handleChangeFocusModeInstanceSubtask}
                onPushProjectRecommendTask={handlePushProjectRecommendTask}
                onDeleteProject={handleDeleteProject}
              />
            )}
            {event.id === focusedTaskbox?.id && deleteWithKeydown && (
              <ConfirmDeleteDialog open={deleteWithKeydown} onClose={() => setDeleteWithKeydown(false)} onDelete={handleDeleteWithKeydown} />
            )}
            {(event.id === focusedTaskbox?.id || event.id === selectedTaskbox) && (
              <UpdateRecurrenceDialog open={openRecurrenceUpdate} onClose={handleCloseRecurrenceUpdateDialog} onUpdateRecurrence={handleRecurrenceStartNow} />
            )}
            {(event.id === focusedTaskbox?.id || event.id === selectedTaskbox || event.id === contextTaskbox?.id) && deleteRecurrenceWithKeydown && (
              <RemoveRecurrenceDialog
                open={deleteRecurrenceWithKeydown}
                onClose={() => setDeleteRecurrenceWithKeydown(false)}
                onRemoveRecurrence={handleDeleteRecurrenceWithKeydown}
              />
            )}
            {!!clickedIssue && plan && (
              <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                <IssueDetailView
                  issueId={event!.issueId!}
                  selectable={true}
                  priority={plan.length > 0 ? plan[0].priority : undefined}
                  // timelineView={timelineView}
                  // tasks={selectedTasks}
                  onClickCancel={handleClickCancelIssue}
                  onClickSave={handleClickSaveIssue}
                />
              </Dialog>
            )}
            <TaskDropContainer height={taskboxHeight} onDrop={() => handleDragDropToTaskbox(event.id!)} hidden={!draggingToTaskbox} />
          </div>
        ) : (
          <div
            className={`taskbox-drag-container ${event.areaFrom === 'SOMEDAY' ? 'taskbox-from-someday' : ''}`.trim()}
            ref={refTaskbox}
            data-taskbox={`${event!.id}`}
            draggable={!multiSelected || multiSelectIds.length > 0}
            onDragStart={multiSelected ? onMultiSelectDragStart : handleDragStart}
            onDragEnd={multiSelected ? onMultiSelectDragEnd : handleDragEnd}
            onDragEnter={handleDragEnterToTaskbox}
            onDragLeave={(e) => handleDragLeaveFromTaskbox(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) onChangeMultiSelectIds && onChangeMultiSelectIds(event!.id!, e);
              if (focusedTaskbox?.id === event!.id) handleClickTaskbox(event!);
            }}
            onContextMenu={(e) => handleClickContext(e)}
          >
            {userState.data.taskViewMode === 'MERGED' ? (
              <Draggable draggableId={`${event?.id}`} index={index!}>
                {(provided, snapshot) => {
                  const style = {
                    backgroundColor: snapshot.isDragging ? 'white' : '',
                    boxShadow: snapshot.isDragging ? `0px 8px 16px 0px rgba(26, 30, 39, 0.16)` : '',
                    borderRadius: snapshot.isDragging ? '0px 16px 16px 0px' : '',
                    marginBottom: '8px',
                    ...provided.draggableProps.style,
                  };
                  return (
                    <TaskboxDragContainer ref={provided.innerRef} multiSelected={multiSelected} {...provided.draggableProps} style={style}>
                      <Tooltip
                        title={
                          language === 'ko'
                            ? event.type === 'TERM_TASK'
                              ? '종료일이 지정된 태스크박스는 타임박싱 할 수 없어요.'
                              : '왼쪽으로 드래그해서 캘린더로 옮깁니다.'
                            : event.type === 'TERM_TASK'
                            ? `Taskbox with end date can't be time-boxed.`
                            : 'Drag to the left to move to the calendar.'
                          // event.type === 'TERM_TASK' ? `Taskbox with end date can't be time-boxed.` : 'Drag to the left to move to the calendar.'
                        }
                        disableInteractive
                      >
                        <TaskboxDragToCalendarIcon
                          className="taskbox-drag-handle"
                          taskboxHeight={taskboxHeight}
                          style={{ cursor: event.type === 'TERM_TASK' ? 'not-allowed' : 'grab' }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          language === 'ko'
                            ? event.type === 'TERM_TASK'
                              ? '종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.'
                              : '위아래로 드래그해서 순서를 변경합니다.'
                            : event.type === 'TERM_TASK'
                            ? 'Taskbox with end date cannot be reordered.'
                            : 'Drag up and down to reorder.'
                          // event.type === 'TERM_TASK' ? `Taskbox with end date can't be reordered.` : 'Drag up and down to reorder.'
                        }
                        disableInteractive
                      >
                        <TaskboxDragHandleIcon
                          {...provided.dragHandleProps}
                          className="taskbox-drag-handle"
                          taskboxHeight={taskboxHeight}
                          style={{ cursor: event.type === 'TERM_TASK' ? 'not-allowed' : 'grab' }}
                        />
                      </Tooltip>
                      {expanded || focusedTaskbox?.id === event?.id ? (
                        <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                          {(provided) => (
                            <TaskDragContainer
                              className="timeline-container"
                              key={event?.id}
                              data-scroll={`${event?.id}`}
                              event={event}
                              taskboxDone={event.done}
                              focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                              plan={plan}
                              hidden={draggingToTaskbox}
                              selectedTaskbox={selectedTaskbox}
                              multiSelected={multiSelected}
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                              }}
                            >
                              {event && (
                                <div
                                  className="flex gap-4 timeline-border"
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                  }}
                                >
                                  <div
                                    className="line"
                                    onClick={(e) => {
                                      if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                    }}
                                  />
                                  <div
                                    className="flex-1"
                                    onClick={(e) => {
                                      if (e.currentTarget === e.target) handleClickTaskbox(event!);
                                    }}
                                    style={{ pointerEvents: 'all' }}
                                  >
                                    <TaskboxPropertyWrapper>
                                      {(event.category || isSettingUnderbarCategory) && (
                                        <CategoryShowingWrapper
                                          data-category-taskbox-id={event.id}
                                          textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                          bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setCategoryAnchorEl(e.currentTarget);
                                          }}
                                        >
                                          {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                          {event.category && (
                                            <IconButton
                                              className="category-detach-button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleClickCategoryActions(null, 'SELECT');
                                                setIsSettingUnderbarCategory(false);
                                              }}
                                              sx={{
                                                'width': '12px',
                                                'height': '12px',
                                                'borderRadius': '4px',
                                                'marginLeft': '4px',
                                                'padding': '0px',
                                                ':hover': {
                                                  backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                                },
                                              }}
                                              style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                            >
                                              <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category!.color)} />
                                            </IconButton>
                                          )}
                                        </CategoryShowingWrapper>
                                      )}
                                      {(event.deadline || isSettingUnderbarDeadline) && (
                                        <DeadlineShowingWrapper
                                          data-deadline-taskbox-id={event.id}
                                          date={event.deadline || ''}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setDeadlineAnchorEl(e.currentTarget);
                                          }}
                                          style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                                        >
                                          <Icons.Flag
                                            fill={
                                              !event.deadline
                                                ? COLORS.gray500
                                                : dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600
                                            }
                                          />
                                          <span style={{ marginLeft: '2px' }}>
                                            {!event.deadline
                                              ? language === 'ko'
                                                ? '기한 설정'
                                                : 'Set deadline'
                                              : dayjs(event.deadline).isToday()
                                              ? language === 'ko'
                                                ? '오늘'
                                                : 'Today'
                                              : dayjs(event.deadline).isYesterday()
                                              ? language === 'ko'
                                                ? '어제'
                                                : 'Yesterday'
                                              : dayjs(event.deadline).isTomorrow()
                                              ? language === 'ko'
                                                ? '내일'
                                                : 'Tomorrow'
                                              : language === 'ko'
                                              ? dayjs(event.deadline).format('M월 D일 (dd)')
                                              : dayjs(event.deadline).format('dddd, MMM D')}
                                          </span>
                                          {event.deadline && (
                                            <IconButton
                                              className="deadline-detach-button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleChangeDeadline(null);
                                                setIsSettingUnderbarDeadline(false);
                                              }}
                                              sx={{
                                                'width': '12px',
                                                'height': '12px',
                                                'borderRadius': '4px',
                                                'marginLeft': '4px',
                                                'padding': '0px',
                                                ':hover': {
                                                  backgroundColor: hexToRGBA(
                                                    dayjs(event!.deadline).isToday()
                                                      ? COLORS.brand1
                                                      : dayjs(event!.deadline).isBefore(dayjs())
                                                      ? COLORS.negative1
                                                      : COLORS.gray600,
                                                    0.3,
                                                  ),
                                                },
                                              }}
                                              style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                            >
                                              <Icons.Close
                                                width={8}
                                                height={8}
                                                stroke={
                                                  dayjs(event!.deadline).isToday()
                                                    ? COLORS.brand1
                                                    : dayjs(event!.deadline).isBefore(dayjs())
                                                    ? COLORS.negative1
                                                    : COLORS.gray600
                                                }
                                              />
                                            </IconButton>
                                          )}
                                        </DeadlineShowingWrapper>
                                      )}
                                      {/* 타임박스 시간 */}
                                      <div className="time-modal-container">
                                        {event.start?.datetime ? <Icons.Time stroke={COLORS.gray700} /> : <Icons.Today />}
                                        <Fonts.Blockquote
                                          className="text-gray700"
                                          onClick={(e) => {
                                            // if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                            if (!e.metaKey && !e.ctrlKey) {
                                              handleOpenSimpleTimeSelectionModal(e);
                                            }
                                          }}
                                          style={{ marginLeft: '4px' }}
                                        >
                                          {event.start?.datetime ? showingTime(event) : '오늘'}
                                        </Fonts.Blockquote>
                                        {!multiSelected ? (
                                          <Tooltip
                                            title={
                                              language === 'ko' ? '시간 해제하기' : 'Remove time'
                                              // 'Remove time'
                                            }
                                            disableInteractive
                                          >
                                            <IconButton
                                              className="unselect-time"
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                                }
                                              }}
                                              sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                            >
                                              <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <div style={{ width: '24px', height: '24px' }} />
                                        )}
                                      </div>
                                    </TaskboxPropertyWrapper>
                                    {event.project && event.project.title !== event.title && (
                                      <InstanceTitleWrapper
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleClickInstanceTitle(event);
                                            setIsLoadSubtaskFromProject(false);
                                          }
                                        }}
                                      >
                                        <Icons.Issue />
                                        <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                                        <Tooltip
                                          title={
                                            <div>
                                              <div style={{ fontWeight: 700 }}>
                                                {language === 'ko' ? '프로젝트와 연결 해지하기' : 'Unlink from project'}
                                                {/* Unlink from project */}
                                              </div>
                                              <div>
                                                {language === 'ko'
                                                  ? '프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다'
                                                  : 'Unlink from project and convert to a regular taskbox'}
                                                {/* Unlink from project and convert to a regular taskbox */}
                                              </div>
                                            </div>
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            className="unlink-project"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleUnlinkProject(event);
                                            }}
                                            sx={{
                                              'width': '24px',
                                              'height': '24px',
                                              'borderRadius': '4px',
                                              'marginLeft': '4px',
                                              'padding': '4px',
                                              'display': 'none',
                                              ':hover': {
                                                backgroundColor: COLORS.gray100,
                                              },
                                            }}
                                          >
                                            <Icons.UnlinkProject />
                                          </IconButton>
                                        </Tooltip>
                                      </InstanceTitleWrapper>
                                    )}
                                    {event.routine && event.routine.title !== event.title && (
                                      <InstanceTitleWrapper
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleClickRoutineTitle(event);
                                          }
                                        }}
                                      >
                                        <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                        <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                                      </InstanceTitleWrapper>
                                    )}
                                    <div className="flex justify-between items-start relative timeline-header">
                                      {/* 타임박스 제목 */}
                                      <div className="taskbox-title-container">
                                        {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                          <Checkbox
                                            checked={false}
                                            icon={
                                              event.focus ? (
                                                <Icons.FocusInprogressCheck />
                                              ) : (
                                                <Icons.InprogressCheck
                                                  fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                                />
                                              )
                                            }
                                            sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            style={{ width: 24, height: 24, marginRight: 8 }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                if (event.type === 'TERM_TASK') {
                                                  handleClickTaskboxCheck();
                                                } else {
                                                  setSelectedTaskbox(event.id);
                                                  setInprogressPopover(e.currentTarget);
                                                }
                                              }
                                            }}
                                          />
                                        ) : (
                                          <PriorityIssueCheckbox
                                            width={24}
                                            height={24}
                                            focus={event.focus}
                                            bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                            markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                            checked={!!event.done}
                                            // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                            // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                            }}
                                            style={{ marginRight: 8 }}
                                          />
                                        )}
                                        {isExploding && event.focus && (
                                          <ConfettiExplosion
                                            force={0.6}
                                            duration={2500}
                                            particleCount={80}
                                            width={1000}
                                            onComplete={() => setIsExploding(false)}
                                            style={{ position: 'absolute', top: 0, left: 0 }}
                                          />
                                        )}
                                        <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                          {event.project && event.project.title === event.title && (
                                            <InstanceIcon>
                                              <Icons.Issue />
                                            </InstanceIcon>
                                          )}
                                          {event.routine && event.routine.title === event.title && (
                                            <InstanceIcon>
                                              <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                            </InstanceIcon>
                                          )}
                                          {event.lockedIn && <LockInIcon event={event} />}
                                          <TaskboxInput
                                            ref={refTaskboxInput}
                                            className="taskbox-title"
                                            data-id={
                                              event.type === 'TERM_TASK'
                                                ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                : `${event.id}`
                                            }
                                            onBlur={(e) => handleBlurTitle(e, event)}
                                            onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                            onInput={(e) => handleTaskboxInput(e)}
                                            placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                            contentEditable={multiSelected ? false : true}
                                            suppressContentEditableWarning={true}
                                            spellCheck={false}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                if (event.routine && event.routine.title === event.title) {
                                                  handleClickRoutineTitle(event);
                                                } else if (event.project && event.project.title === event.title) {
                                                  handleClickInstanceTitle(event);
                                                  setIsLoadSubtaskFromProject(false);
                                                } else if (!event.project && !event.routine) {
                                                  handleClickTaskbox(event);
                                                  setGetCaret(window.getSelection()!.anchorOffset);
                                                  if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                                    setTaskboxDetailView(event);
                                                  }
                                                }
                                              }
                                            }}
                                          >
                                            {event.title}
                                          </TaskboxInput>
                                        </TaskboxInputWrapper>
                                        {event.issueId && (
                                          <Button
                                            variant="outlined"
                                            color="inherit"
                                            onClick={() => {
                                              setClickedIssue(event.issueId!);
                                            }}
                                            sx={{
                                              width: '100px',
                                              height: '24px',
                                              borderRadius: '8px',
                                              padding: '0px',
                                              color: `${COLORS.gray400}`,
                                              border: `1px solid ${COLORS.gray400}`,
                                            }}
                                          >
                                            <div className="timelineEvents-issue-detail">
                                              <IssueLinkage />
                                              <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                            </div>
                                          </Button>
                                        )}
                                      </div>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {event.memo && <Icons.TaskboxMemo />}
                                        {event.links!.length > 0 && (
                                          <div style={{ marginLeft: '4px' }}>
                                            <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                          </div>
                                        )}
                                        {(event.isRecurrence || event.routine) && (
                                          <div style={{ marginLeft: '4px' }}>
                                            <Icons.Recurrence />
                                          </div>
                                        )}
                                        {tasks.length > 0 && (
                                          <div className="show-subtask">
                                            <Icons.Subtask />
                                            <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                          </div>
                                        )}
                                      </div>
                                      {!multiSelected && (
                                        <TaskboxOptionsContainer
                                          className="taskbox-option"
                                          titleLength={taskboxTitleLength}
                                          style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                        >
                                          <TaskboxOptionsContent>
                                            <TaskboxOptionsIcon>
                                              <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '4px' }}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) {
                                                      handleClickStartNow(event, true);
                                                    }
                                                  }}
                                                >
                                                  <Icons.StartNowTaskboxAction />
                                                </IconButton>
                                              </Tooltip>
                                            </TaskboxOptionsIcon>
                                          </TaskboxOptionsContent>
                                          <TaskboxOptionsContent>
                                            <TaskboxOptionsIcon>
                                              {event.project && (
                                                <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                                  <TaskboxDetachButtonWrapper>
                                                    <IconButton
                                                      onClick={() => handleDetachTaskboxFromProject(event)}
                                                      sx={{
                                                        'width': '20px',
                                                        'height': '20px',
                                                        'padding': '0px',
                                                        'borderRadius': '2.5px',
                                                        'backgroundColor': COLORS.issue2,
                                                        ':hover': {
                                                          backgroundColor: COLORS.issue2,
                                                        },
                                                      }}
                                                    >
                                                      <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                    </IconButton>
                                                  </TaskboxDetachButtonWrapper>
                                                </Tooltip>
                                              )}
                                              {event.routine && (
                                                <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                                  <TaskboxDetachButtonWrapper>
                                                    <IconButton
                                                      onClick={() => handleDetachTaskboxFromRoutine(event)}
                                                      sx={{
                                                        'width': '20px',
                                                        'height': '20px',
                                                        'padding': '0px',
                                                        'borderRadius': '2.5px',
                                                        'backgroundColor': COLORS.sub4,
                                                        ':hover': {
                                                          backgroundColor: COLORS.sub4,
                                                        },
                                                      }}
                                                    >
                                                      <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                    </IconButton>
                                                  </TaskboxDetachButtonWrapper>
                                                </Tooltip>
                                              )}
                                              {!event.project && !event.routine && (
                                                <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                                  <IconButton
                                                    sx={{ borderRadius: '8px', padding: '4px' }}
                                                    onClick={(e) => {
                                                      if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                                    }}
                                                  >
                                                    <Icons.TaskboxConvertButton />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                            </TaskboxOptionsIcon>
                                          </TaskboxOptionsContent>
                                          <TaskboxOptionsContent>
                                            <TaskboxOptionsIcon>
                                              <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '4px' }}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                                  }}
                                                >
                                                  <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                                </IconButton>
                                              </Tooltip>
                                            </TaskboxOptionsIcon>
                                          </TaskboxOptionsContent>
                                          <TaskboxOptionsContent>
                                            <TaskboxOptionsIcon>
                                              <Tooltip
                                                title={
                                                  language === 'ko' ? '더보기' : 'More'
                                                  // 'More'
                                                }
                                                disableInteractive
                                              >
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '4px' }}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) {
                                                      setSelectedTaskbox(event.id!);
                                                      setTaskboxDetail(e.currentTarget);
                                                    }
                                                  }}
                                                >
                                                  <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                                </IconButton>
                                              </Tooltip>
                                            </TaskboxOptionsIcon>
                                          </TaskboxOptionsContent>
                                        </TaskboxOptionsContainer>
                                      )}
                                    </div>
                                    <div className={`${event.id} `} {...provided.droppableProps} ref={provided.innerRef}>
                                      <SubtaskView>
                                        {event &&
                                          tasks?.map((task, index) => (
                                            <Draggable draggableId={task.id!} index={index} key={task.id}>
                                              {(provided) => (
                                                <EditableTextFieldWrapper
                                                  {...provided.draggableProps}
                                                  className="flex justify-between items-center relative"
                                                  key={task.id}
                                                  ref={provided.innerRef}
                                                  draggable={true}
                                                  hover={
                                                    multiSelected || subtaskMultiSelected
                                                      ? false
                                                      : !(selectedEvent?.id === event.id && selectedTaskId === task.id)
                                                  }
                                                  selectedTaskId={multiSelected ? false : selectedTaskId === task.id ? true : false}
                                                  multiSelected={subtaskMultiSelected && multiSelectSubtaskIds[0].subtaskIds.includes(task.id!)}
                                                  onDragStart={(e) => {
                                                    handleSubtaskDragStart(e, task);
                                                  }}
                                                  onDragEnd={(e) => {
                                                    handleSubtaskDragEnd();
                                                  }}
                                                  onClick={(e) => {
                                                    if (e.metaKey || e.ctrlKey) {
                                                      handleMultiSelectSubtaskIds(task.id!, e);
                                                    }
                                                  }}
                                                  style={{ ...provided.draggableProps.style }}
                                                >
                                                  <div className="flex items-center box-border task-container">
                                                    <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                                    <SubTaskCheckbox
                                                      focus={event.focus}
                                                      multiSelect={multiSelected}
                                                      bordercolor={
                                                        event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1
                                                      }
                                                      checked={!!task.done}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) handleClickSubtaskCheckbox(index);
                                                      }}
                                                    />
                                                    <SubtaskField
                                                      className="subtask flex items-center ml-2"
                                                      ref={refTask}
                                                      data-id={
                                                        event.type === 'TERM_TASK'
                                                          ? `${task.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                          : `${task.id}`
                                                      }
                                                      data-timeboxid={event.id}
                                                      placeholder={
                                                        !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                          ? projectSubtasks[recommendTaskIndex].title === ''
                                                            ? language === 'ko'
                                                              ? '제목 없음'
                                                              : 'Untitled'
                                                            : projectSubtasks[recommendTaskIndex].title!
                                                          : 'Title'
                                                        // !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                        //   ? projectSubtasks[recommendTaskIndex].title === ''
                                                        //     ? 'Untitled'
                                                        //     : projectSubtasks[recommendTaskIndex].title!
                                                        //   : 'Title'
                                                      }
                                                      contentEditable={multiSelected ? false : true}
                                                      suppressContentEditableWarning={true}
                                                      spellCheck={false}
                                                      taskboxDone={event.done}
                                                      onBlur={(e) => handleBlurSubtask(e, index)}
                                                      onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                                      onInput={(e) => handleInputTask(e, task.id!)}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          handleClickTaskbox(event);
                                                          handleSelectedTaskId && handleSelectedTaskId(task.id!);
                                                        }
                                                      }}
                                                    >
                                                      {task.content}
                                                    </SubtaskField>
                                                  </div>
                                                  {(!isInstanceTyping &&
                                                    selectedTaskId === task.id &&
                                                    event.project &&
                                                    projectSubtasks.length > 0 &&
                                                    task.content === '') ||
                                                  (isRemoveTextAndRecommend && selectedTaskId === task.id && event.project && projectSubtasks.length > 0) ? (
                                                    !delayRecommendTask ? (
                                                      <RecommendTaskWrapper>
                                                        {language === 'ko' ? (
                                                          <>
                                                            {projectSubtasks.length > 1 && (
                                                              <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                                <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                                <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                                <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                              </div>
                                                            )}
                                                            <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                                            <span>으로 추가하기</span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {projectSubtasks.length > 1 && (
                                                              <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                                <span style={{ marginRight: 4 }}>Navigate with</span>
                                                                <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                                <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                                <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                              </div>
                                                            )}
                                                            <span>Add with</span>
                                                            <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect>
                                                          </>
                                                        )}
                                                        {/* {projectSubtasks.length > 1 && (
                                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                            <span style={{ marginRight: 4 }}>Navigate with</span>
                                                            <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                            <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                            <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                          </div>
                                                        )}
                                                        <span>Add with</span>
                                                        <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect> */}
                                                      </RecommendTaskWrapper>
                                                    ) : (
                                                      <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                                                    )
                                                  ) : (
                                                    <>
                                                      {!multiSelected && !subtaskMultiSelected && (
                                                        <SubtaskAddsOnWrapper className="subtask-addson">
                                                          {task.category ? (
                                                            <CategoryShowingWrapper
                                                              className="detail task-detail"
                                                              textColor={getCategoryTextColor(task.category.color)}
                                                              bgColor={getCategoryBgColor(task.category.color)}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setCategoryAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                            >
                                                              {`# ${task.category.name}`}
                                                              <IconButton
                                                                className="category-detach-button"
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  handleClickCategoryActions(null, 'SELECT', task.id);
                                                                }}
                                                                sx={{
                                                                  'width': '12px',
                                                                  'height': '12px',
                                                                  'borderRadius': '4px',
                                                                  'marginLeft': '4px',
                                                                  'padding': '0px',
                                                                  ':hover': {
                                                                    backgroundColor: hexToRGBA(getCategoryTextColor(task.category!.color)!, 0.3),
                                                                  },
                                                                }}
                                                                style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                              >
                                                                <Icons.Close width={8} height={8} stroke={getCategoryTextColor(task.category.color)} />
                                                              </IconButton>
                                                            </CategoryShowingWrapper>
                                                          ) : (
                                                            <IconButton
                                                              className="detail task-detail"
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setCategoryAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '4px',
                                                                padding: '0px',
                                                                marginRight: '4px',
                                                              }}
                                                              style={
                                                                categoryAnchorEl && subtaskId === task.id && !multiSelected
                                                                  ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                  : {}
                                                              }
                                                            >
                                                              <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                                            </IconButton>
                                                          )}
                                                          {task.deadline ? (
                                                            <DeadlineShowingWrapper
                                                              className="detail task-detail"
                                                              date={task.deadline}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setDeadlineAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                            >
                                                              <Icons.Flag
                                                                fill={
                                                                  dayjs(task!.deadline).isToday()
                                                                    ? COLORS.brand1
                                                                    : dayjs(task!.deadline).isBefore(dayjs())
                                                                    ? COLORS.negative1
                                                                    : COLORS.gray600
                                                                }
                                                              />
                                                              <span style={{ marginLeft: '2px' }}>
                                                                {dayjs(task.deadline).isToday()
                                                                  ? language === 'ko'
                                                                    ? '오늘'
                                                                    : 'Today'
                                                                  : dayjs(task.deadline).isYesterday()
                                                                  ? language === 'ko'
                                                                    ? '어제'
                                                                    : 'Yesterday'
                                                                  : dayjs(task.deadline).isTomorrow()
                                                                  ? language === 'ko'
                                                                    ? '내일'
                                                                    : 'Tomorrow'
                                                                  : language === 'ko'
                                                                  ? dayjs(task.deadline).format('M월 D일 (dd)')
                                                                  : dayjs(task.deadline).format('dddd, MMM D')}
                                                              </span>
                                                              {/* <span style={{ marginLeft: '2px' }}>
                                                                {dayjs(task.deadline).isToday()
                                                                  ? 'Today'
                                                                  : dayjs(task.deadline).isYesterday()
                                                                  ? 'Yesterday'
                                                                  : dayjs(task.deadline).isTomorrow()
                                                                  ? 'Tomorrow'
                                                                  : dayjs(task.deadline).format('dddd, MMM D')}
                                                              </span> */}
                                                              <IconButton
                                                                className="deadline-detach-button"
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  handleChangeDeadline(null, task.id);
                                                                }}
                                                                sx={{
                                                                  'width': '12px',
                                                                  'height': '12px',
                                                                  'borderRadius': '4px',
                                                                  'marginLeft': '4px',
                                                                  'padding': '0px',
                                                                  ':hover': {
                                                                    backgroundColor: hexToRGBA(
                                                                      dayjs(task!.deadline).isToday()
                                                                        ? COLORS.brand1
                                                                        : dayjs(task!.deadline).isBefore(dayjs())
                                                                        ? COLORS.negative1
                                                                        : COLORS.gray600,
                                                                      0.3,
                                                                    ),
                                                                  },
                                                                }}
                                                                style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                              >
                                                                <Icons.Close
                                                                  width={8}
                                                                  height={8}
                                                                  stroke={
                                                                    dayjs(task!.deadline).isToday()
                                                                      ? COLORS.brand1
                                                                      : dayjs(task!.deadline).isBefore(dayjs())
                                                                      ? COLORS.negative1
                                                                      : COLORS.gray600
                                                                  }
                                                                />
                                                              </IconButton>
                                                            </DeadlineShowingWrapper>
                                                          ) : (
                                                            <IconButton
                                                              className="detail task-detail"
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setDeadlineAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '4px',
                                                                padding: '0px',
                                                                marginRight: '4px',
                                                              }}
                                                              style={
                                                                deadlineAnchorEl && subtaskId === task.id && !multiSelected
                                                                  ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                  : {}
                                                              }
                                                            >
                                                              <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                                            </IconButton>
                                                          )}
                                                          {event.project ? (
                                                            <>
                                                              <Divider
                                                                className="detail task-detail"
                                                                orientation="vertical"
                                                                flexItem
                                                                style={{ margin: '0px 4px 0px 0px' }}
                                                              />
                                                              <Tooltip
                                                                title={
                                                                  isNextInstance ? (
                                                                    <div>
                                                                      <div>
                                                                        {language === 'ko' ? '다음 인스턴스로 이동해요' : 'Move to next instance'}
                                                                        {/* Move to next instance */}
                                                                      </div>
                                                                      <div style={{ fontWeight: 700 }}>
                                                                        {language === 'ko'
                                                                          ? isNextInstance.start?.datetime
                                                                            ? dayjs(isNextInstance.start?.datetime).format('M월 D일 dddd A h:mm')
                                                                            : dayjs(isNextInstance.start?.date).format('M월 D일 dddd')
                                                                          : isNextInstance.start?.datetime
                                                                          ? dayjs(isNextInstance.start?.datetime).format('ddd, MMM D dddd A h:mm')
                                                                          : dayjs(isNextInstance.start?.date).format('ddd, MMM D')}
                                                                        {/* {isNextInstance.start?.datetime
                                                                          ? dayjs(isNextInstance.start?.datetime).format('dddd, MMM D dddd A h:mm')
                                                                          : dayjs(isNextInstance.start?.date).format('dddd, MMM D')} */}
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <div>
                                                                      {language === 'ko' ? '프로젝트의 미할당으로 이동해요' : 'Move to project unassigned'}
                                                                      {/* Move to project unassigned */}
                                                                    </div>
                                                                  )
                                                                }
                                                                disableInteractive
                                                              >
                                                                <TaskIconWrapper
                                                                  className="detail task-detail"
                                                                  aria-label="later"
                                                                  onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                                >
                                                                  <Icons.ArrowRightLong width={16} height={16} stroke={COLORS.gray400} />
                                                                </TaskIconWrapper>
                                                              </Tooltip>
                                                            </>
                                                          ) : (
                                                            <TaskIconWrapper
                                                              className="detail task-detail"
                                                              aria-label="later"
                                                              onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                            >
                                                              <div className="later">
                                                                <Icons.Later width={16} height={16} fill={COLORS.gray400} />{' '}
                                                              </div>
                                                            </TaskIconWrapper>
                                                          )}
                                                          <TaskIconWrapper
                                                            className="detail task-detail"
                                                            aria-label="delete"
                                                            onClick={() => handleClickSubtaskContextMenu(task.id!, 'DELETE')}
                                                            style={{ marginRight: event.project ? 0 : 4 }}
                                                          >
                                                            <div className="delete">
                                                              <Icons.Delete fill={COLORS.gray400} />
                                                            </div>
                                                          </TaskIconWrapper>
                                                          {!event.project && (
                                                            <div
                                                              className="detail task-detail"
                                                              style={subtaskDetail && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setSubtaskDetail(e.currentTarget);
                                                                }
                                                              }}
                                                            >
                                                              <Icons.ColorMore />
                                                            </div>
                                                          )}
                                                        </SubtaskAddsOnWrapper>
                                                      )}
                                                    </>
                                                  )}
                                                  {/* 태스크 카테고리 */}
                                                  {subtaskId === task.id && (
                                                    <Popover
                                                      open={Boolean(categoryAnchorEl)}
                                                      anchorEl={categoryAnchorEl}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                      onClose={() => {
                                                        setSelectedTaskbox(undefined);
                                                        setSubtaskId(undefined);
                                                        setCategoryAnchorEl(null);
                                                      }}
                                                      sx={{ marginTop: '4px' }}
                                                    >
                                                      <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
                                                    </Popover>
                                                  )}
                                                  {/* 태스크 기한 */}
                                                  {subtaskId === task.id && (
                                                    <Popover
                                                      open={Boolean(deadlineAnchorEl)}
                                                      anchorEl={deadlineAnchorEl}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                      onClose={() => {
                                                        setSelectedTaskbox(undefined);
                                                        setSubtaskId(undefined);
                                                        setDeadlineAnchorEl(null);
                                                      }}
                                                      sx={{ marginTop: '4px' }}
                                                    >
                                                      <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
                                                    </Popover>
                                                  )}

                                                  {subtaskId === task.id && (
                                                    <SubtaskDetail
                                                      taskId={task.id}
                                                      detail={subtaskDetail}
                                                      menus={['CONVERT_TO_TASKBOX']}
                                                      onCloseDetail={handleCloseSubtaskDetail}
                                                      onClickSubtaskContextMenu={handleClickSubtaskContextMenu}
                                                    />
                                                  )}
                                                </EditableTextFieldWrapper>
                                              )}
                                            </Draggable>
                                          ))}
                                        {provided!.placeholder!}
                                        <TaskboxAddOnWrapper multiSelected={multiSelected}>
                                          {event.project && projectSubtasks.length > 0 ? (
                                            <SubtaskWrapper
                                              data-timeboxid={event.id}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  if (subtaskMode === 'ADD') {
                                                    handleClickTaskAdd(event);
                                                  } else {
                                                    handleClickInstanceTitle(event);
                                                    setIsLoadSubtaskFromProject(true);
                                                  }
                                                }
                                              }}
                                            >
                                              {subtaskMode === 'ADD' ? (
                                                <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
                                              ) : (
                                                <Icons.LoadToInstance width={12} height={12} fill={COLORS.gray500} />
                                              )}
                                              <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                                {subtaskMode === 'ADD'
                                                  ? language === 'ko'
                                                    ? '하위 업무 추가하기'
                                                    : 'Add subtask'
                                                  : language === 'ko'
                                                  ? '하위 업무 가져오기'
                                                  : 'Import subtask'}
                                                {/* Add subtask */}
                                              </Fonts.Badge>
                                              <IconButton
                                                onClick={(e) => handleClickSubtaskModeIconBtn(e)}
                                                sx={{
                                                  marginLeft: '4px',
                                                  padding: '0px',
                                                }}
                                              >
                                                <Icons.ArrowDownSmall />
                                              </IconButton>
                                            </SubtaskWrapper>
                                          ) : (
                                            <SubtaskWrapper
                                              data-timeboxid={event.id}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  handleClickTaskAdd(event);
                                                }
                                              }}
                                            >
                                              <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
                                              <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                                {language === 'ko' ? '하위 업무 추가하기' : 'Add subtask'}
                                                {/* Add subtask */}
                                              </Fonts.Badge>
                                            </SubtaskWrapper>
                                          )}
                                          <AdditionalAddOnButton
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickEmptyTime(event.id!);
                                            }}
                                            style={{ marginLeft: 4 }}
                                          >
                                            <Icons.Time width={12} height={12} stroke={COLORS.gray500} />
                                            <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                              {language === 'ko' ? '시간 추가하기' : 'Add time'}
                                              {/* Import subtask */}
                                            </Fonts.Badge>
                                          </AdditionalAddOnButton>
                                          {!event.routine && (
                                            <AdditionalAddOnButtonWrapper className="additional-btn">
                                              <Divider orientation="vertical" flexItem sx={{ margin: '0px 6px' }} />
                                              {event && !event.category && (
                                                <Tooltip
                                                  title={
                                                    language === 'ko' ? '카테고리 설정하기' : 'Set category'
                                                    // 'Set category'
                                                  }
                                                  disableInteractive
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      if (!e.metaKey && !e.ctrlKey) handleClickEmptyCategory(event.id!);
                                                    }}
                                                    sx={{
                                                      'width': '24px',
                                                      'height': '24px',
                                                      'border': `1px solid ${COLORS.gray200}`,
                                                      'borderRadius': '4px',
                                                      'padding': '0px',
                                                      'marginRight': '4px',
                                                      ':hover': {
                                                        backgroundColor: COLORS.gray100,
                                                      },
                                                    }}
                                                  >
                                                    <Icons.Hashtag />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                              {event && !event.deadline && (
                                                <Tooltip
                                                  title={
                                                    language === 'ko' ? '기한 설정하기' : 'Set deadline'
                                                    // 'Set deadline'
                                                  }
                                                  disableInteractive
                                                >
                                                  <IconButton
                                                    onClick={(e) => {
                                                      if (!e.metaKey && !e.ctrlKey) handleClickEmptyDeadline(event.id!);
                                                    }}
                                                    sx={{
                                                      'width': '24px',
                                                      'height': '24px',
                                                      'border': `1px solid ${COLORS.gray200}`,
                                                      'borderRadius': '4px',
                                                      'padding': '0px',
                                                      'marginRight': '4px',
                                                      ':hover': {
                                                        backgroundColor: COLORS.gray100,
                                                      },
                                                    }}
                                                  >
                                                    <Icons.Flag />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                            </AdditionalAddOnButtonWrapper>
                                          )}
                                        </TaskboxAddOnWrapper>
                                        {event.routine && changedRoutineInstanceId === event.id && (
                                          <UpdateRoutineInstanceWrapper
                                            onClick={(e) => {
                                              handleReflectRoutine();
                                            }}
                                          >
                                            <Icons.UpdateRecurrence />
                                            <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>
                                              {language === 'ko' ? '이후 인스턴스에 변경 일정 반영하기' : 'Apply changes to future instances'}
                                              {/* Apply changes to future instances */}
                                            </Fonts.Body2>
                                            <IconButton
                                              className="apply-changes-btn"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setChangedRoutineInstanceId(null);
                                              }}
                                              sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px', opacity: 0 }}
                                            >
                                              <Icons.Close width={12} height={12} stroke={COLORS.gray300} />
                                            </IconButton>
                                          </UpdateRoutineInstanceWrapper>
                                        )}
                                      </SubtaskView>
                                    </div>
                                    {event.memo && event.memo.content.length > 0 && focusedTaskbox && focusedTaskbox.id === event.id && (
                                      <ShowingMemoWrapper>
                                        <Divider sx={{ margin: '4px 0px' }} />
                                        <Tooltip
                                          title={
                                            language === 'ko' ? '포커스모드에서 편집하기' : 'Edit in focus mode'
                                            // 'Edit in focus mode'
                                          }
                                          followCursor
                                        >
                                          <ShowingMemoTextWrapper
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                handleClickFocusMode(event);
                                              }
                                            }}
                                          >
                                            {event.memo?.content?.[0]?.content?.[0]?.text && <div>{event.memo.content[0].content[0].text}</div>}
                                            {event.memo?.content?.[1]?.content?.[0]?.text && <div>{event.memo.content[1].content[0].text}</div>}
                                          </ShowingMemoTextWrapper>
                                        </Tooltip>
                                      </ShowingMemoWrapper>
                                    )}
                                  </div>
                                </div>
                              )}
                            </TaskDragContainer>
                          )}
                        </Droppable>
                      ) : (
                        <TaskDragContainer
                          className="timeline-container"
                          key={event?.id}
                          data-scroll={`${event?.id}`}
                          event={event}
                          taskboxDone={event.done}
                          focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                          plan={plan}
                          hidden={draggingToTaskbox}
                          selectedTaskbox={selectedTaskbox}
                          multiSelected={multiSelected}
                          onClick={(e) => {
                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                          }}
                          style={{ position: 'relative' }}
                        >
                          {event && (
                            <div
                              className="flex gap-4 timeline-border"
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                              }}
                            >
                              <div
                                className="line"
                                onClick={(e) => {
                                  if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                }}
                              />
                              <div
                                className="flex-1"
                                onClick={(e) => {
                                  if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                }}
                                style={{ pointerEvents: 'all', width: 'calc(100% - 20px)' }}
                              >
                                <TaskboxPropertyWrapper>
                                  {(event.category || isSettingUnderbarCategory) && (
                                    <CategoryShowingWrapper
                                      data-category-taskbox-id={event.id}
                                      textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                      bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setCategoryAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                      {event.category && (
                                        <IconButton
                                          className="category-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickCategoryActions(null, 'SELECT');
                                            setIsSettingUnderbarCategory(false);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                            },
                                          }}
                                          style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category!.color)} />
                                        </IconButton>
                                      )}
                                    </CategoryShowingWrapper>
                                  )}
                                  {(event.deadline || isSettingUnderbarDeadline) && (
                                    <DeadlineShowingWrapper
                                      data-deadline-taskbox-id={event.id}
                                      date={event.deadline || ''}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setDeadlineAnchorEl(e.currentTarget);
                                      }}
                                      style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                                    >
                                      <Icons.Flag
                                        fill={
                                          !event.deadline
                                            ? COLORS.gray500
                                            : dayjs(event!.deadline).isToday()
                                            ? COLORS.brand1
                                            : dayjs(event!.deadline).isBefore(dayjs())
                                            ? COLORS.negative1
                                            : COLORS.gray600
                                        }
                                      />
                                      <span style={{ marginLeft: '2px' }}>
                                        {!event.deadline
                                          ? language === 'ko'
                                            ? '기한 설정'
                                            : 'Set deadline'
                                          : dayjs(event.deadline).isToday()
                                          ? language === 'ko'
                                            ? '오늘'
                                            : 'Today'
                                          : dayjs(event.deadline).isYesterday()
                                          ? language === 'ko'
                                            ? '어제'
                                            : 'Yesterday'
                                          : dayjs(event.deadline).isTomorrow()
                                          ? language === 'ko'
                                            ? '내일'
                                            : 'Tomorrow'
                                          : language === 'ko'
                                          ? dayjs(event.deadline).format('M월 D일 (dd)')
                                          : dayjs(event.deadline).format('dddd, MMM D')}
                                      </span>
                                      {event.deadline && (
                                        <IconButton
                                          className="deadline-detach-button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChangeDeadline(null);
                                            setIsSettingUnderbarDeadline(false);
                                          }}
                                          sx={{
                                            'width': '12px',
                                            'height': '12px',
                                            'borderRadius': '4px',
                                            'marginLeft': '4px',
                                            'padding': '0px',
                                            ':hover': {
                                              backgroundColor: hexToRGBA(
                                                dayjs(event!.deadline).isToday()
                                                  ? COLORS.brand1
                                                  : dayjs(event!.deadline).isBefore(dayjs())
                                                  ? COLORS.negative1
                                                  : COLORS.gray600,
                                                0.3,
                                              ),
                                            },
                                          }}
                                          style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                        >
                                          <Icons.Close
                                            width={8}
                                            height={8}
                                            stroke={
                                              dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600
                                            }
                                          />
                                        </IconButton>
                                      )}
                                    </DeadlineShowingWrapper>
                                  )}
                                  {/**태스크박스 시간 */}
                                  <div className="time-modal-container">
                                    {event.start?.datetime ? <Icons.Time stroke={COLORS.gray700} /> : <Icons.Today />}
                                    <Fonts.Blockquote
                                      className="text-gray700"
                                      onClick={(e) => {
                                        // if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                        if (!e.metaKey && !e.ctrlKey) {
                                          handleOpenSimpleTimeSelectionModal(e);
                                        }
                                      }}
                                      style={{ marginLeft: '4px' }}
                                    >
                                      {event.start?.datetime ? showingTime(event) : '오늘'}
                                    </Fonts.Blockquote>
                                    {!multiSelected ? (
                                      <Tooltip
                                        title={
                                          language === 'ko' ? '시간 해제하기' : 'Remove time'
                                          // 'Remove time'
                                        }
                                        disableInteractive
                                      >
                                        <IconButton
                                          className="unselect-time"
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                            }
                                          }}
                                          sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                        >
                                          <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <div style={{ width: '24px', height: '24px' }} />
                                    )}
                                  </div>
                                </TaskboxPropertyWrapper>
                                {event.project && event.project.title !== event.title && (
                                  <InstanceTitleWrapper
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        handleClickInstanceTitle(event);
                                        setIsLoadSubtaskFromProject(false);
                                      }
                                    }}
                                  >
                                    <Icons.Issue />
                                    <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                                    <Tooltip
                                      title={
                                        <div>
                                          <div style={{ fontWeight: 700 }}>
                                            {language === 'ko' ? '프로젝트와 연결 해지하기' : 'Unlink from project'}
                                            {/* Unlink from project */}
                                          </div>
                                          <div>
                                            {language === 'ko'
                                              ? '프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다'
                                              : 'Unlink from project and convert to a regular taskbox'}
                                            {/* Unlink from project and convert to a regular taskbox */}
                                          </div>
                                        </div>
                                      }
                                      disableInteractive
                                    >
                                      <IconButton
                                        className="unlink-project"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUnlinkProject(event);
                                        }}
                                        sx={{
                                          'width': '24px',
                                          'height': '24px',
                                          'borderRadius': '4px',
                                          'marginLeft': '4px',
                                          'padding': '4px',
                                          'display': 'none',
                                          ':hover': {
                                            backgroundColor: COLORS.gray100,
                                          },
                                        }}
                                      >
                                        <Icons.UnlinkProject />
                                      </IconButton>
                                    </Tooltip>
                                  </InstanceTitleWrapper>
                                )}
                                {event.routine && event.routine.title !== event.title && (
                                  <InstanceTitleWrapper
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        handleClickRoutineTitle(event);
                                      }
                                    }}
                                  >
                                    <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                    <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                                  </InstanceTitleWrapper>
                                )}
                                <div className="flex justify-between items-center relative timeline-header">
                                  {/* 타임박스 제목 */}
                                  <div
                                    className="taskbox-title-container"
                                    style={{
                                      width: `calc(100% - ${foldTaskboxFieldWidth}px)`,
                                    }}
                                  >
                                    {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                      <Checkbox
                                        checked={false}
                                        icon={
                                          event.focus ? (
                                            <Icons.FocusInprogressCheck />
                                          ) : (
                                            <Icons.InprogressCheck
                                              fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                            />
                                          )
                                        }
                                        sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        style={{ width: 24, height: 24, marginRight: 8 }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            if (event.type === 'TERM_TASK') {
                                              handleClickTaskboxCheck();
                                            } else {
                                              setSelectedTaskbox(event.id);
                                              setInprogressPopover(e.currentTarget);
                                            }
                                          }
                                        }}
                                      />
                                    ) : (
                                      <PriorityIssueCheckbox
                                        width={24}
                                        height={24}
                                        focus={event.focus}
                                        bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                        markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                        checked={!!event.done}
                                        // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                        // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                        }}
                                        style={{ marginRight: 8 }}
                                      />
                                    )}
                                    {isExploding && event.focus && (
                                      <ConfettiExplosion
                                        force={0.6}
                                        duration={2500}
                                        particleCount={80}
                                        width={1000}
                                        onComplete={() => setIsExploding(false)}
                                        style={{ position: 'absolute', top: 0, left: 0 }}
                                      />
                                    )}
                                    <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                      {event.project && event.project.title === event.title && (
                                        <InstanceIcon>
                                          <Icons.Issue />
                                        </InstanceIcon>
                                      )}
                                      {event.routine && event.routine.title === event.title && (
                                        <InstanceIcon>
                                          <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                        </InstanceIcon>
                                      )}
                                      {event.lockedIn && <LockInIcon event={event} />}
                                      <TaskboxInput
                                        ref={refTaskboxInput}
                                        className="taskbox-title"
                                        data-id={
                                          event.type === 'TERM_TASK'
                                            ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                            : `${event.id}`
                                        }
                                        onBlur={(e) => handleBlurTitle(e, event)}
                                        onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                        onInput={(e) => handleTaskboxInput(e)}
                                        placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                        contentEditable={multiSelected ? false : true}
                                        suppressContentEditableWarning={true}
                                        spellCheck={false}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            if (event.routine && event.routine.title === event.title) {
                                              handleClickRoutineTitle(event);
                                            } else if (event.project && event.project.title === event.title) {
                                              handleClickInstanceTitle(event);
                                              setIsLoadSubtaskFromProject(false);
                                            } else if (!event.project && !event.routine) {
                                              handleClickTaskbox(event);
                                              setGetCaret(window.getSelection()!.anchorOffset);
                                              if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                                setTaskboxDetailView(event);
                                              }
                                            }
                                          }
                                        }}
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {event.title}
                                      </TaskboxInput>
                                    </TaskboxInputWrapper>
                                    {event.issueId && (
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        onClick={() => handleClickIssueDetail(event.issueId!)}
                                        sx={{
                                          width: '100px',
                                          height: '24px',
                                          borderRadius: '8px',
                                          padding: '0px',
                                          color: `${COLORS.gray400}`,
                                          border: `1px solid ${COLORS.gray400}`,
                                        }}
                                      >
                                        <div className="timelineEvents-issue-detail">
                                          <IssueLinkage />
                                          <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                        </div>
                                      </Button>
                                    )}
                                  </div>
                                  <div data-foldShowingId={`${event.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    {event.memo && <Icons.TaskboxMemo />}
                                    {event.links!.length > 0 && (
                                      <div style={{ marginLeft: '4px' }}>
                                        <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                      </div>
                                    )}
                                    {(event.isRecurrence || event.routine) && (
                                      <div style={{ marginLeft: '4px' }}>
                                        <Icons.Recurrence />
                                      </div>
                                    )}
                                    {tasks.length > 0 && (
                                      <div className="show-subtask">
                                        <Icons.Subtask />
                                        <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                      </div>
                                    )}
                                  </div>
                                  {!multiSelected && (
                                    <TaskboxOptionsContainer
                                      className="taskbox-option"
                                      titleLength={taskboxTitleLength}
                                      style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                    >
                                      <TaskboxOptionsContent>
                                        <TaskboxOptionsIcon>
                                          <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '4px' }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  handleClickStartNow(event, true);
                                                }
                                              }}
                                            >
                                              <Icons.StartNowTaskboxAction />
                                            </IconButton>
                                          </Tooltip>
                                        </TaskboxOptionsIcon>
                                      </TaskboxOptionsContent>
                                      <TaskboxOptionsContent>
                                        <TaskboxOptionsIcon>
                                          {event.project && (
                                            <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                              <TaskboxDetachButtonWrapper>
                                                <IconButton
                                                  onClick={() => handleDetachTaskboxFromProject(event)}
                                                  sx={{
                                                    'width': '20px',
                                                    'height': '20px',
                                                    'padding': '0px',
                                                    'borderRadius': '2.5px',
                                                    'backgroundColor': COLORS.issue2,
                                                    ':hover': {
                                                      backgroundColor: COLORS.issue2,
                                                    },
                                                  }}
                                                >
                                                  <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                </IconButton>
                                              </TaskboxDetachButtonWrapper>
                                            </Tooltip>
                                          )}
                                          {event.routine && (
                                            <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                              <TaskboxDetachButtonWrapper>
                                                <IconButton
                                                  onClick={() => handleDetachTaskboxFromRoutine(event)}
                                                  sx={{
                                                    'width': '20px',
                                                    'height': '20px',
                                                    'padding': '0px',
                                                    'borderRadius': '2.5px',
                                                    'backgroundColor': COLORS.sub4,
                                                    ':hover': {
                                                      backgroundColor: COLORS.sub4,
                                                    },
                                                  }}
                                                >
                                                  <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                                </IconButton>
                                              </TaskboxDetachButtonWrapper>
                                            </Tooltip>
                                          )}
                                          {!event.project && !event.routine && (
                                            <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                                }}
                                              >
                                                <Icons.TaskboxConvertButton />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </TaskboxOptionsIcon>
                                      </TaskboxOptionsContent>
                                      <TaskboxOptionsContent>
                                        <TaskboxOptionsIcon>
                                          <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '4px' }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                              }}
                                            >
                                              <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                            </IconButton>
                                          </Tooltip>
                                        </TaskboxOptionsIcon>
                                      </TaskboxOptionsContent>
                                      <TaskboxOptionsContent>
                                        <TaskboxOptionsIcon>
                                          <Tooltip
                                            title={
                                              language === 'ko' ? '더보기' : 'More'
                                              // 'More'
                                            }
                                            disableInteractive
                                          >
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '4px' }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  setSelectedTaskbox(event.id!);
                                                  setTaskboxDetail(e.currentTarget);
                                                }
                                              }}
                                            >
                                              <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                            </IconButton>
                                          </Tooltip>
                                        </TaskboxOptionsIcon>
                                      </TaskboxOptionsContent>
                                    </TaskboxOptionsContainer>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </TaskDragContainer>
                      )}
                    </TaskboxDragContainer>
                  );
                }}
              </Draggable>
            ) : (
              <>
                {expanded || focusedTaskbox?.id === event?.id ? (
                  <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                    {(provided) => (
                      <TaskDragContainer
                        className="timeline-container"
                        key={event?.id}
                        data-scroll={`${event?.id}`}
                        event={event}
                        taskboxDone={event.done}
                        focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                        plan={plan}
                        hidden={draggingToTaskbox}
                        selectedTaskbox={selectedTaskbox}
                        multiSelected={multiSelected}
                        onClick={(e) => {
                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                        }}
                      >
                        {event && (
                          <div
                            className="flex gap-4 timeline-border"
                            onClick={(e) => {
                              if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                            }}
                          >
                            <div
                              className="line"
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                              }}
                            />
                            <div
                              className="flex-1"
                              onClick={(e) => {
                                if (e.currentTarget === e.target) handleClickTaskbox(event!);
                              }}
                              style={{ pointerEvents: 'all' }}
                            >
                              <TaskboxPropertyWrapper>
                                {(event.category || isSettingUnderbarCategory) && (
                                  <CategoryShowingWrapper
                                    data-category-taskbox-id={event.id}
                                    textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                    bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                    onClick={(e) => {
                                      setSelectedTaskbox(event.id);
                                      setCategoryAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                    {event.category && (
                                      <IconButton
                                        className="category-detach-button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleClickCategoryActions(null, 'SELECT');
                                          setIsSettingUnderbarCategory(false);
                                        }}
                                        sx={{
                                          'width': '12px',
                                          'height': '12px',
                                          'borderRadius': '4px',
                                          'marginLeft': '4px',
                                          'padding': '0px',
                                          ':hover': {
                                            backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                          },
                                        }}
                                        style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                      >
                                        <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category!.color)} />
                                      </IconButton>
                                    )}
                                  </CategoryShowingWrapper>
                                )}
                                {(event.deadline || isSettingUnderbarDeadline) && (
                                  <DeadlineShowingWrapper
                                    data-deadline-taskbox-id={event.id}
                                    date={event.deadline || ''}
                                    onClick={(e) => {
                                      setSelectedTaskbox(event.id);
                                      setDeadlineAnchorEl(e.currentTarget);
                                    }}
                                    style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                                  >
                                    <Icons.Flag
                                      fill={
                                        !event.deadline
                                          ? COLORS.gray500
                                          : dayjs(event!.deadline).isToday()
                                          ? COLORS.brand1
                                          : dayjs(event!.deadline).isBefore(dayjs())
                                          ? COLORS.negative1
                                          : COLORS.gray600
                                      }
                                    />
                                    <span style={{ marginLeft: '2px' }}>
                                      {!event.deadline
                                        ? language === 'ko'
                                          ? '기한 설정'
                                          : 'Set deadline'
                                        : dayjs(event.deadline).isToday()
                                        ? language === 'ko'
                                          ? '오늘'
                                          : 'Today'
                                        : dayjs(event.deadline).isYesterday()
                                        ? language === 'ko'
                                          ? '어제'
                                          : 'Yesterday'
                                        : dayjs(event.deadline).isTomorrow()
                                        ? language === 'ko'
                                          ? '내일'
                                          : 'Tomorrow'
                                        : language === 'ko'
                                        ? dayjs(event.deadline).format('M월 D일 (dd)')
                                        : dayjs(event.deadline).format('dddd, MMM D')}
                                    </span>
                                    {event.deadline && (
                                      <IconButton
                                        className="deadline-detach-button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleChangeDeadline(null);
                                          setIsSettingUnderbarDeadline(false);
                                        }}
                                        sx={{
                                          'width': '12px',
                                          'height': '12px',
                                          'borderRadius': '4px',
                                          'marginLeft': '4px',
                                          'padding': '0px',
                                          ':hover': {
                                            backgroundColor: hexToRGBA(
                                              dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600,
                                              0.3,
                                            ),
                                          },
                                        }}
                                        style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                      >
                                        <Icons.Close
                                          width={8}
                                          height={8}
                                          stroke={
                                            dayjs(event!.deadline).isToday()
                                              ? COLORS.brand1
                                              : dayjs(event!.deadline).isBefore(dayjs())
                                              ? COLORS.negative1
                                              : COLORS.gray600
                                          }
                                        />
                                      </IconButton>
                                    )}
                                  </DeadlineShowingWrapper>
                                )}
                                {/* 타임박스 시간 */}
                                <div className="time-modal-container">
                                  {event.start?.datetime ? <Icons.Time stroke={COLORS.gray700} /> : <Icons.Today />}
                                  <Fonts.Blockquote
                                    className="text-gray700"
                                    onClick={(e) => {
                                      // if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                      if (!e.metaKey && !e.ctrlKey) {
                                        handleOpenSimpleTimeSelectionModal(e);
                                      }
                                    }}
                                    style={{ marginLeft: '4px' }}
                                  >
                                    {event.start?.datetime ? showingTime(event) : '오늘'}
                                  </Fonts.Blockquote>
                                  {!multiSelected ? (
                                    <Tooltip
                                      title={
                                        language === 'ko' ? '시간 해제하기' : 'Remove time'
                                        // 'Remove time'
                                      }
                                      disableInteractive
                                    >
                                      <IconButton
                                        className="unselect-time"
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                          }
                                        }}
                                        sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                      >
                                        <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <div style={{ width: '24px', height: '24px' }} />
                                  )}
                                </div>
                              </TaskboxPropertyWrapper>
                              {event.project && event.project.title !== event.title && (
                                <InstanceTitleWrapper
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickInstanceTitle(event);
                                      setIsLoadSubtaskFromProject(false);
                                    }
                                  }}
                                >
                                  <Icons.Issue />
                                  <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                                  <Tooltip
                                    title={
                                      <div>
                                        <div style={{ fontWeight: 700 }}>
                                          {language === 'ko' ? '프로젝트와 연결 해지하기' : 'Unlink from project'}
                                          {/* Unlink from project */}
                                        </div>
                                        <div>
                                          {language === 'ko'
                                            ? '프로젝트와 연결을 해지하고 일반 태스크박스로 변경합니다'
                                            : 'Unlink from project and convert to a regular taskbox'}
                                          {/* Unlink from project and convert to a regular taskbox */}
                                        </div>
                                      </div>
                                    }
                                    disableInteractive
                                  >
                                    <IconButton
                                      className="unlink-project"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleUnlinkProject(event);
                                      }}
                                      sx={{
                                        'width': '24px',
                                        'height': '24px',
                                        'borderRadius': '4px',
                                        'marginLeft': '4px',
                                        'padding': '4px',
                                        'display': 'none',
                                        ':hover': {
                                          backgroundColor: COLORS.gray100,
                                        },
                                      }}
                                    >
                                      <Icons.UnlinkProject />
                                    </IconButton>
                                  </Tooltip>
                                </InstanceTitleWrapper>
                              )}
                              {event.routine && event.routine.title !== event.title && (
                                <InstanceTitleWrapper
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickRoutineTitle(event);
                                    }
                                  }}
                                >
                                  <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                  <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                                </InstanceTitleWrapper>
                              )}
                              <div className="flex justify-between items-start relative timeline-header">
                                {/* 타임박스 제목 */}
                                <div className="taskbox-title-container">
                                  {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                    <Checkbox
                                      checked={false}
                                      icon={
                                        event.focus ? (
                                          <Icons.FocusInprogressCheck />
                                        ) : (
                                          <Icons.InprogressCheck
                                            fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                          />
                                        )
                                      }
                                      sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                      style={{ width: 24, height: 24, marginRight: 8 }}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if (event.type === 'TERM_TASK') {
                                            handleClickTaskboxCheck();
                                          } else {
                                            setSelectedTaskbox(event.id);
                                            setInprogressPopover(e.currentTarget);
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <PriorityIssueCheckbox
                                      width={24}
                                      height={24}
                                      focus={event.focus}
                                      bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                      markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                      checked={!!event.done}
                                      // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                      // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                      }}
                                      style={{ marginRight: 8 }}
                                    />
                                  )}
                                  {isExploding && event.focus && (
                                    <ConfettiExplosion
                                      force={0.6}
                                      duration={2500}
                                      particleCount={80}
                                      width={1000}
                                      onComplete={() => setIsExploding(false)}
                                      style={{ position: 'absolute', top: 0, left: 0 }}
                                    />
                                  )}
                                  <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                    {event.project && event.project.title === event.title && (
                                      <InstanceIcon>
                                        <Icons.Issue />
                                      </InstanceIcon>
                                    )}
                                    {event.routine && event.routine.title === event.title && (
                                      <InstanceIcon>
                                        <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                      </InstanceIcon>
                                    )}
                                    {event.lockedIn && <LockInIcon event={event} />}
                                    <TaskboxInput
                                      ref={refTaskboxInput}
                                      className="taskbox-title"
                                      data-id={
                                        event.type === 'TERM_TASK'
                                          ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                          : `${event.id}`
                                      }
                                      onBlur={(e) => handleBlurTitle(e, event)}
                                      onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                      onInput={(e) => handleTaskboxInput(e)}
                                      placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                      contentEditable={multiSelected ? false : true}
                                      suppressContentEditableWarning={true}
                                      spellCheck={false}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if (event.routine && event.routine.title === event.title) {
                                            handleClickRoutineTitle(event);
                                          } else if (event.project && event.project.title === event.title) {
                                            handleClickInstanceTitle(event);
                                            setIsLoadSubtaskFromProject(false);
                                          } else if (!event.project && !event.routine) {
                                            handleClickTaskbox(event);
                                            setGetCaret(window.getSelection()!.anchorOffset);
                                            if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                              setTaskboxDetailView(event);
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      {event.title}
                                    </TaskboxInput>
                                  </TaskboxInputWrapper>
                                  {event.issueId && (
                                    <Button
                                      variant="outlined"
                                      color="inherit"
                                      onClick={() => {
                                        setClickedIssue(event.issueId!);
                                      }}
                                      sx={{
                                        width: '100px',
                                        height: '24px',
                                        borderRadius: '8px',
                                        padding: '0px',
                                        color: `${COLORS.gray400}`,
                                        border: `1px solid ${COLORS.gray400}`,
                                      }}
                                    >
                                      <div className="timelineEvents-issue-detail">
                                        <IssueLinkage />
                                        <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                      </div>
                                    </Button>
                                  )}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {event.memo && <Icons.TaskboxMemo />}
                                  {event.links!.length > 0 && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                    </div>
                                  )}
                                  {(event.isRecurrence || event.routine) && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Recurrence />
                                    </div>
                                  )}
                                  {tasks.length > 0 && (
                                    <div className="show-subtask">
                                      <Icons.Subtask />
                                      <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                    </div>
                                  )}
                                </div>
                                {!multiSelected && (
                                  <TaskboxOptionsContainer
                                    className="taskbox-option"
                                    titleLength={taskboxTitleLength}
                                    style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                  >
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                handleClickStartNow(event, true);
                                              }
                                            }}
                                          >
                                            <Icons.StartNowTaskboxAction />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        {event.project && (
                                          <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                            <TaskboxDetachButtonWrapper>
                                              <IconButton
                                                onClick={() => handleDetachTaskboxFromProject(event)}
                                                sx={{
                                                  'width': '20px',
                                                  'height': '20px',
                                                  'padding': '0px',
                                                  'borderRadius': '2.5px',
                                                  'backgroundColor': COLORS.issue2,
                                                  ':hover': {
                                                    backgroundColor: COLORS.issue2,
                                                  },
                                                }}
                                              >
                                                <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                              </IconButton>
                                            </TaskboxDetachButtonWrapper>
                                          </Tooltip>
                                        )}
                                        {event.routine && (
                                          <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                            <TaskboxDetachButtonWrapper>
                                              <IconButton
                                                onClick={() => handleDetachTaskboxFromRoutine(event)}
                                                sx={{
                                                  'width': '20px',
                                                  'height': '20px',
                                                  'padding': '0px',
                                                  'borderRadius': '2.5px',
                                                  'backgroundColor': COLORS.sub4,
                                                  ':hover': {
                                                    backgroundColor: COLORS.sub4,
                                                  },
                                                }}
                                              >
                                                <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                              </IconButton>
                                            </TaskboxDetachButtonWrapper>
                                          </Tooltip>
                                        )}
                                        {!event.project && !event.routine && (
                                          <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '4px' }}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                              }}
                                            >
                                              <Icons.TaskboxConvertButton />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                            }}
                                          >
                                            <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip
                                          title={
                                            language === 'ko' ? '더보기' : 'More'
                                            // 'More'
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                setSelectedTaskbox(event.id!);
                                                setTaskboxDetail(e.currentTarget);
                                              }
                                            }}
                                          >
                                            <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                  </TaskboxOptionsContainer>
                                )}
                              </div>
                              <div className={`${event.id} `} {...provided.droppableProps} ref={provided.innerRef}>
                                <SubtaskView>
                                  {event &&
                                    tasks?.map((task, index) => (
                                      <Draggable draggableId={task.id!} index={index} key={task.id}>
                                        {(provided) => (
                                          <EditableTextFieldWrapper
                                            {...provided.draggableProps}
                                            className="flex justify-between items-center relative"
                                            key={task.id}
                                            ref={provided.innerRef}
                                            draggable={true}
                                            hover={
                                              multiSelected || subtaskMultiSelected ? false : !(selectedEvent?.id === event.id && selectedTaskId === task.id)
                                            }
                                            selectedTaskId={multiSelected ? false : selectedTaskId === task.id ? true : false}
                                            multiSelected={subtaskMultiSelected && multiSelectSubtaskIds[0].subtaskIds.includes(task.id!)}
                                            onDragStart={(e) => {
                                              handleSubtaskDragStart(e, task);
                                            }}
                                            onDragEnd={(e) => {
                                              handleSubtaskDragEnd();
                                            }}
                                            onClick={(e) => {
                                              if (e.metaKey || e.ctrlKey) {
                                                handleMultiSelectSubtaskIds(task.id!, e);
                                              }
                                            }}
                                            style={{ ...provided.draggableProps.style }}
                                          >
                                            <div className="flex items-center box-border task-container">
                                              <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                              <SubTaskCheckbox
                                                focus={event.focus}
                                                multiSelect={multiSelected}
                                                bordercolor={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                                checked={!!task.done}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleClickSubtaskCheckbox(index);
                                                }}
                                              />
                                              <SubtaskField
                                                className="subtask flex items-center ml-2"
                                                ref={refTask}
                                                data-id={
                                                  event.type === 'TERM_TASK'
                                                    ? `${task.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                    : `${task.id}`
                                                }
                                                data-timeboxid={event.id}
                                                placeholder={
                                                  !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                    ? projectSubtasks[recommendTaskIndex].title === ''
                                                      ? language === 'ko'
                                                        ? '제목 없음'
                                                        : 'Untitled'
                                                      : projectSubtasks[recommendTaskIndex].title!
                                                    : 'Title'
                                                  // !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                  //   ? projectSubtasks[recommendTaskIndex].title === ''
                                                  //     ? 'Untitled'
                                                  //     : projectSubtasks[recommendTaskIndex].title!
                                                  //   : 'Title'
                                                }
                                                contentEditable={multiSelected ? false : true}
                                                suppressContentEditableWarning={true}
                                                spellCheck={false}
                                                taskboxDone={event.done}
                                                onBlur={(e) => handleBlurSubtask(e, index)}
                                                onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                                onInput={(e) => handleInputTask(e, task.id!)}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) {
                                                    handleClickTaskbox(event);
                                                    handleSelectedTaskId && handleSelectedTaskId(task.id!);
                                                  }
                                                }}
                                              >
                                                {task.content}
                                              </SubtaskField>
                                            </div>
                                            {(!isInstanceTyping &&
                                              selectedTaskId === task.id &&
                                              event.project &&
                                              projectSubtasks.length > 0 &&
                                              task.content === '') ||
                                            (isRemoveTextAndRecommend && selectedTaskId === task.id && event.project && projectSubtasks.length > 0) ? (
                                              !delayRecommendTask ? (
                                                <RecommendTaskWrapper>
                                                  {language === 'ko' ? (
                                                    <>
                                                      {projectSubtasks.length > 1 && (
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                          <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                        </div>
                                                      )}
                                                      <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                                      <span>으로 추가하기</span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {projectSubtasks.length > 1 && (
                                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                          <span style={{ marginRight: 4 }}>Navigate with</span>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                          <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                          <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                        </div>
                                                      )}
                                                      <span>Add with</span>
                                                      <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect>
                                                    </>
                                                  )}
                                                  {/* {projectSubtasks.length > 1 && (
                                                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                      <span style={{ marginRight: 4 }}>Navigate with</span>
                                                      <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                      <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                      <span>{`(${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                    </div>
                                                  )}
                                                  <span>Add with</span>
                                                  <RecommendButtonRect style={{ marginLeft: 4 }}>Tab</RecommendButtonRect> */}
                                                </RecommendTaskWrapper>
                                              ) : (
                                                <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                                              )
                                            ) : (
                                              <>
                                                {!multiSelected && !subtaskMultiSelected && (
                                                  <SubtaskAddsOnWrapper className="subtask-addson">
                                                    {task.category ? (
                                                      <CategoryShowingWrapper
                                                        className="detail task-detail"
                                                        textColor={getCategoryTextColor(task.category.color)}
                                                        bgColor={getCategoryBgColor(task.category.color)}
                                                        onClick={(e) => {
                                                          if (!e.metaKey && !e.ctrlKey) {
                                                            if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                            setSubtaskId(task.id);
                                                            setCategoryAnchorEl(e.currentTarget);
                                                          }
                                                        }}
                                                        style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                      >
                                                        {`# ${task.category.name}`}
                                                        <IconButton
                                                          className="category-detach-button"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleClickCategoryActions(null, 'SELECT', task.id);
                                                          }}
                                                          sx={{
                                                            'width': '12px',
                                                            'height': '12px',
                                                            'borderRadius': '4px',
                                                            'marginLeft': '4px',
                                                            'padding': '0px',
                                                            ':hover': {
                                                              backgroundColor: hexToRGBA(getCategoryTextColor(task.category!.color)!, 0.3),
                                                            },
                                                          }}
                                                          style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                        >
                                                          <Icons.Close width={8} height={8} stroke={getCategoryTextColor(task.category.color)} />
                                                        </IconButton>
                                                      </CategoryShowingWrapper>
                                                    ) : (
                                                      <IconButton
                                                        className="detail task-detail"
                                                        onClick={(e) => {
                                                          if (!e.metaKey && !e.ctrlKey) {
                                                            if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                            setSubtaskId(task.id);
                                                            setCategoryAnchorEl(e.currentTarget);
                                                          }
                                                        }}
                                                        sx={{
                                                          width: '20px',
                                                          height: '20px',
                                                          borderRadius: '4px',
                                                          padding: '0px',
                                                          marginRight: '4px',
                                                        }}
                                                        style={
                                                          categoryAnchorEl && subtaskId === task.id && !multiSelected
                                                            ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                            : {}
                                                        }
                                                      >
                                                        <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                                      </IconButton>
                                                    )}
                                                    {task.deadline ? (
                                                      <DeadlineShowingWrapper
                                                        className="detail task-detail"
                                                        date={task.deadline}
                                                        onClick={(e) => {
                                                          if (!e.metaKey && !e.ctrlKey) {
                                                            if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                            setSubtaskId(task.id);
                                                            setDeadlineAnchorEl(e.currentTarget);
                                                          }
                                                        }}
                                                        style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                      >
                                                        <Icons.Flag
                                                          fill={
                                                            dayjs(task!.deadline).isToday()
                                                              ? COLORS.brand1
                                                              : dayjs(task!.deadline).isBefore(dayjs())
                                                              ? COLORS.negative1
                                                              : COLORS.gray600
                                                          }
                                                        />
                                                        <span style={{ marginLeft: '2px' }}>
                                                          {dayjs(task.deadline).isToday()
                                                            ? language === 'ko'
                                                              ? '오늘'
                                                              : 'Today'
                                                            : dayjs(task.deadline).isYesterday()
                                                            ? language === 'ko'
                                                              ? '어제'
                                                              : 'Yesterday'
                                                            : dayjs(task.deadline).isTomorrow()
                                                            ? language === 'ko'
                                                              ? '내일'
                                                              : 'Tomorrow'
                                                            : language === 'ko'
                                                            ? dayjs(task.deadline).format('M월 D일 (dd)')
                                                            : dayjs(task.deadline).format('dddd, MMM D')}
                                                        </span>
                                                        {/* <span style={{ marginLeft: '2px' }}>
                                                          {dayjs(task.deadline).isToday()
                                                            ? 'Today'
                                                            : dayjs(task.deadline).isYesterday()
                                                            ? 'Yesterday'
                                                            : dayjs(task.deadline).isTomorrow()
                                                            ? 'Tomorrow'
                                                            : dayjs(task.deadline).format('dddd, MMM D')}
                                                        </span> */}
                                                        <IconButton
                                                          className="deadline-detach-button"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleChangeDeadline(null, task.id);
                                                          }}
                                                          sx={{
                                                            'width': '12px',
                                                            'height': '12px',
                                                            'borderRadius': '4px',
                                                            'marginLeft': '4px',
                                                            'padding': '0px',
                                                            ':hover': {
                                                              backgroundColor: hexToRGBA(
                                                                dayjs(task!.deadline).isToday()
                                                                  ? COLORS.brand1
                                                                  : dayjs(task!.deadline).isBefore(dayjs())
                                                                  ? COLORS.negative1
                                                                  : COLORS.gray600,
                                                                0.3,
                                                              ),
                                                            },
                                                          }}
                                                          style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                        >
                                                          <Icons.Close
                                                            width={8}
                                                            height={8}
                                                            stroke={
                                                              dayjs(task!.deadline).isToday()
                                                                ? COLORS.brand1
                                                                : dayjs(task!.deadline).isBefore(dayjs())
                                                                ? COLORS.negative1
                                                                : COLORS.gray600
                                                            }
                                                          />
                                                        </IconButton>
                                                      </DeadlineShowingWrapper>
                                                    ) : (
                                                      <IconButton
                                                        className="detail task-detail"
                                                        onClick={(e) => {
                                                          if (!e.metaKey && !e.ctrlKey) {
                                                            if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                            setSubtaskId(task.id);
                                                            setDeadlineAnchorEl(e.currentTarget);
                                                          }
                                                        }}
                                                        sx={{
                                                          width: '20px',
                                                          height: '20px',
                                                          borderRadius: '4px',
                                                          padding: '0px',
                                                          marginRight: '4px',
                                                        }}
                                                        style={
                                                          deadlineAnchorEl && subtaskId === task.id && !multiSelected
                                                            ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                            : {}
                                                        }
                                                      >
                                                        <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                                      </IconButton>
                                                    )}
                                                    {event.project ? (
                                                      <>
                                                        <Divider
                                                          className="detail task-detail"
                                                          orientation="vertical"
                                                          flexItem
                                                          style={{ margin: '0px 4px 0px 0px' }}
                                                        />
                                                        <Tooltip
                                                          title={
                                                            isNextInstance ? (
                                                              <div>
                                                                <div>
                                                                  {language === 'ko' ? '다음 인스턴스로 이동해요' : 'Move to next instance'}
                                                                  {/* Move to next instance */}
                                                                </div>
                                                                <div style={{ fontWeight: 700 }}>
                                                                  {language === 'ko'
                                                                    ? isNextInstance.start?.datetime
                                                                      ? dayjs(isNextInstance.start?.datetime).format('M월 D일 dddd A h:mm')
                                                                      : dayjs(isNextInstance.start?.date).format('M월 D일 dddd')
                                                                    : isNextInstance.start?.datetime
                                                                    ? dayjs(isNextInstance.start?.datetime).format('ddd, MMM D dddd A h:mm')
                                                                    : dayjs(isNextInstance.start?.date).format('ddd, MMM D')}
                                                                  {/* {isNextInstance.start?.datetime
                                                                    ? dayjs(isNextInstance.start?.datetime).format('dddd, MMM D dddd A h:mm')
                                                                    : dayjs(isNextInstance.start?.date).format('dddd, MMM D')} */}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {language === 'ko' ? '프로젝트의 미할당으로 이동해요' : 'Move to project unassigned'}
                                                                {/* Move to project unassigned */}
                                                              </div>
                                                            )
                                                          }
                                                          disableInteractive
                                                        >
                                                          <TaskIconWrapper
                                                            className="detail task-detail"
                                                            aria-label="later"
                                                            onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                          >
                                                            <Icons.ArrowRightLong width={16} height={16} stroke={COLORS.gray400} />
                                                          </TaskIconWrapper>
                                                        </Tooltip>
                                                      </>
                                                    ) : (
                                                      <TaskIconWrapper
                                                        className="detail task-detail"
                                                        aria-label="later"
                                                        onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                      >
                                                        <div className="later">
                                                          <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                                                        </div>
                                                      </TaskIconWrapper>
                                                    )}
                                                    <TaskIconWrapper
                                                      className="detail task-detail"
                                                      aria-label="delete"
                                                      onClick={() => handleClickSubtaskContextMenu(task.id!, 'DELETE')}
                                                      style={{ marginRight: event.project ? 0 : 4 }}
                                                    >
                                                      <div className="delete">
                                                        <Icons.Delete fill={COLORS.gray400} />
                                                      </div>
                                                    </TaskIconWrapper>
                                                    {!event.project && (
                                                      <div
                                                        className="detail task-detail"
                                                        style={subtaskDetail && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                        onClick={(e) => {
                                                          if (!e.metaKey && !e.ctrlKey) {
                                                            if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                            setSubtaskId(task.id);
                                                            setSubtaskDetail(e.currentTarget);
                                                          }
                                                        }}
                                                      >
                                                        <Icons.ColorMore />
                                                      </div>
                                                    )}
                                                  </SubtaskAddsOnWrapper>
                                                )}
                                              </>
                                            )}
                                            {/* 태스크 카테고리 */}
                                            {subtaskId === task.id && (
                                              <Popover
                                                open={Boolean(categoryAnchorEl)}
                                                anchorEl={categoryAnchorEl}
                                                anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                                }}
                                                onClose={() => {
                                                  setSelectedTaskbox(undefined);
                                                  setSubtaskId(undefined);
                                                  setCategoryAnchorEl(null);
                                                }}
                                                sx={{ marginTop: '4px' }}
                                              >
                                                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
                                              </Popover>
                                            )}
                                            {/* 태스크 기한 */}
                                            {subtaskId === task.id && (
                                              <Popover
                                                open={Boolean(deadlineAnchorEl)}
                                                anchorEl={deadlineAnchorEl}
                                                anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                                }}
                                                onClose={() => {
                                                  setSelectedTaskbox(undefined);
                                                  setSubtaskId(undefined);
                                                  setDeadlineAnchorEl(null);
                                                }}
                                                sx={{ marginTop: '4px' }}
                                              >
                                                <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
                                              </Popover>
                                            )}

                                            {subtaskId === task.id && (
                                              <SubtaskDetail
                                                taskId={task.id}
                                                detail={subtaskDetail}
                                                menus={['CONVERT_TO_TASKBOX']}
                                                onCloseDetail={handleCloseSubtaskDetail}
                                                onClickSubtaskContextMenu={handleClickSubtaskContextMenu}
                                              />
                                            )}
                                          </EditableTextFieldWrapper>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided!.placeholder!}
                                  <TaskboxAddOnWrapper multiSelected={multiSelected}>
                                    {event.project && projectSubtasks.length > 0 ? (
                                      <SubtaskWrapper
                                        data-timeboxid={event.id}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            if (subtaskMode === 'ADD') {
                                              handleClickTaskAdd(event);
                                            } else {
                                              handleClickInstanceTitle(event);
                                              setIsLoadSubtaskFromProject(true);
                                            }
                                          }
                                        }}
                                      >
                                        {subtaskMode === 'ADD' ? (
                                          <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
                                        ) : (
                                          <Icons.LoadToInstance width={12} height={12} fill={COLORS.gray500} />
                                        )}
                                        <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                          {subtaskMode === 'ADD'
                                            ? language === 'ko'
                                              ? '하위 업무 추가하기'
                                              : 'Add subtask'
                                            : language === 'ko'
                                            ? '하위 업무 가져오기'
                                            : 'Import subtask'}
                                          {/* Add subtask */}
                                        </Fonts.Badge>
                                        <IconButton
                                          onClick={(e) => handleClickSubtaskModeIconBtn(e)}
                                          sx={{
                                            marginLeft: '4px',
                                            padding: '0px',
                                          }}
                                        >
                                          <Icons.ArrowDownSmall />
                                        </IconButton>
                                      </SubtaskWrapper>
                                    ) : (
                                      <SubtaskWrapper
                                        data-timeboxid={event.id}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleClickTaskAdd(event);
                                          }
                                        }}
                                      >
                                        <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
                                        <Fonts.Badge style={{ color: COLORS.gray500, marginLeft: '4px' }}>
                                          {language === 'ko' ? '하위 업무 추가하기' : 'Add subtask'}
                                          {/* Add subtask */}
                                        </Fonts.Badge>
                                      </SubtaskWrapper>
                                    )}
                                    {!event.routine && (
                                      <AdditionalAddOnButtonWrapper className="additional-btn">
                                        <Divider orientation="vertical" flexItem sx={{ margin: '0px 6px' }} />
                                        {event && !event.category && (
                                          <Tooltip
                                            title={
                                              language === 'ko' ? '카테고리 설정하기' : 'Set category'
                                              // 'Set category'
                                            }
                                            disableInteractive
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleClickEmptyCategory(event.id!);
                                              }}
                                              sx={{
                                                'width': '24px',
                                                'height': '24px',
                                                'border': `1px solid ${COLORS.gray200}`,
                                                'borderRadius': '4px',
                                                'padding': '0px',
                                                'marginRight': '4px',
                                                ':hover': {
                                                  backgroundColor: COLORS.gray100,
                                                },
                                              }}
                                            >
                                              <Icons.Hashtag />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {event && !event.deadline && (
                                          <Tooltip
                                            title={
                                              language === 'ko' ? '기한 설정하기' : 'Set deadline'
                                              // 'Set deadline'
                                            }
                                            disableInteractive
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleClickEmptyDeadline(event.id!);
                                              }}
                                              sx={{
                                                'width': '24px',
                                                'height': '24px',
                                                'border': `1px solid ${COLORS.gray200}`,
                                                'borderRadius': '4px',
                                                'padding': '0px',
                                                'marginRight': '4px',
                                                ':hover': {
                                                  backgroundColor: COLORS.gray100,
                                                },
                                              }}
                                            >
                                              <Icons.Flag />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                      </AdditionalAddOnButtonWrapper>
                                    )}
                                  </TaskboxAddOnWrapper>
                                  {event.routine && changedRoutineInstanceId === event.id && (
                                    <UpdateRoutineInstanceWrapper
                                      onClick={(e) => {
                                        handleReflectRoutine();
                                      }}
                                    >
                                      <Icons.UpdateRecurrence fill={COLORS.gray900} />
                                      <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px' }}>
                                        {language === 'ko' ? '이후 인스턴스에 변경 일정 반영하기' : 'Apply changes to future instances'}
                                        {/* Apply changes to future instances */}
                                      </Fonts.Body2>
                                      <IconButton
                                        className="apply-changes-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setChangedRoutineInstanceId(null);
                                        }}
                                        sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px', opacity: 0 }}
                                      >
                                        <Icons.Close width={12} height={12} stroke={COLORS.gray300} />
                                      </IconButton>
                                    </UpdateRoutineInstanceWrapper>
                                  )}
                                </SubtaskView>
                              </div>
                              {event.memo && event.memo.content.length > 0 && focusedTaskbox && focusedTaskbox.id === event.id && (
                                <ShowingMemoWrapper>
                                  <Divider sx={{ margin: '4px 0px' }} />
                                  <Tooltip
                                    title={
                                      language === 'ko' ? '포커스모드에서 편집하기' : 'Edit in focus mode'
                                      // 'Edit in focus mode'
                                    }
                                    followCursor
                                  >
                                    <ShowingMemoTextWrapper
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          handleClickFocusMode(event);
                                        }
                                      }}
                                    >
                                      {event.memo?.content?.[0]?.content?.[0]?.text && <div>{event.memo.content[0].content[0].text}</div>}
                                      {event.memo?.content?.[1]?.content?.[0]?.text && <div>{event.memo.content[1].content[0].text}</div>}
                                    </ShowingMemoTextWrapper>
                                  </Tooltip>
                                </ShowingMemoWrapper>
                              )}
                            </div>
                          </div>
                        )}
                      </TaskDragContainer>
                    )}
                  </Droppable>
                ) : (
                  <TaskDragContainer
                    className="timeline-container"
                    key={event?.id}
                    data-scroll={`${event?.id}`}
                    event={event}
                    taskboxDone={event.done}
                    focusedTaskbox={matchCalendarAndBoardDate ? focusedTaskbox : undefined}
                    plan={plan}
                    hidden={draggingToTaskbox}
                    selectedTaskbox={selectedTaskbox}
                    multiSelected={multiSelected}
                    onClick={(e) => {
                      if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                    }}
                    style={{ position: 'relative' }}
                  >
                    {event && (
                      <div
                        className="flex gap-4 timeline-border"
                        onClick={(e) => {
                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                        }}
                      >
                        <div
                          className="line"
                          onClick={(e) => {
                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                          }}
                        />
                        <div
                          className="flex-1"
                          onClick={(e) => {
                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                          }}
                          style={{ pointerEvents: 'all', width: 'calc(100% - 20px)' }}
                        >
                          <TaskboxPropertyWrapper>
                            {(event.category || isSettingUnderbarCategory) && (
                              <CategoryShowingWrapper
                                data-category-taskbox-id={event.id}
                                textColor={event.category && event.category.color ? getCategoryTextColor(event.category!.color) : COLORS.gray500}
                                bgColor={event.category && event.category.color ? getCategoryBgColor(event.category!.color) : COLORS.white}
                                onClick={(e) => {
                                  setSelectedTaskbox(event.id);
                                  setCategoryAnchorEl(e.currentTarget);
                                }}
                              >
                                {event.category ? `# ${event.category.name}` : language === 'ko' ? '# 카테고리명' : '# Category'}
                                {event.category && (
                                  <IconButton
                                    className="category-detach-button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickCategoryActions(null, 'SELECT');
                                      setIsSettingUnderbarCategory(false);
                                    }}
                                    sx={{
                                      'width': '12px',
                                      'height': '12px',
                                      'borderRadius': '4px',
                                      'marginLeft': '4px',
                                      'padding': '0px',
                                      ':hover': {
                                        backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                      },
                                    }}
                                    style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                  >
                                    <Icons.Close width={8} height={8} stroke={getCategoryTextColor(event.category!.color)} />
                                  </IconButton>
                                )}
                              </CategoryShowingWrapper>
                            )}
                            {(event.deadline || isSettingUnderbarDeadline) && (
                              <DeadlineShowingWrapper
                                data-deadline-taskbox-id={event.id}
                                date={event.deadline || ''}
                                onClick={(e) => {
                                  setSelectedTaskbox(event.id);
                                  setDeadlineAnchorEl(e.currentTarget);
                                }}
                                style={!event.deadline ? { backgroundColor: COLORS.white } : {}}
                              >
                                <Icons.Flag
                                  fill={
                                    !event.deadline
                                      ? COLORS.gray500
                                      : dayjs(event!.deadline).isToday()
                                      ? COLORS.brand1
                                      : dayjs(event!.deadline).isBefore(dayjs())
                                      ? COLORS.negative1
                                      : COLORS.gray600
                                  }
                                />
                                <span style={{ marginLeft: '2px' }}>
                                  {!event.deadline
                                    ? language === 'ko'
                                      ? '기한 설정'
                                      : 'Set deadline'
                                    : dayjs(event.deadline).isToday()
                                    ? language === 'ko'
                                      ? '오늘'
                                      : 'Today'
                                    : dayjs(event.deadline).isYesterday()
                                    ? language === 'ko'
                                      ? '어제'
                                      : 'Yesterday'
                                    : dayjs(event.deadline).isTomorrow()
                                    ? language === 'ko'
                                      ? '내일'
                                      : 'Tomorrow'
                                    : language === 'ko'
                                    ? dayjs(event.deadline).format('M월 D일 (dd)')
                                    : dayjs(event.deadline).format('dddd, MMM D')}
                                </span>
                                {event.deadline && (
                                  <IconButton
                                    className="deadline-detach-button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleChangeDeadline(null);
                                      setIsSettingUnderbarDeadline(false);
                                    }}
                                    sx={{
                                      'width': '12px',
                                      'height': '12px',
                                      'borderRadius': '4px',
                                      'marginLeft': '4px',
                                      'padding': '0px',
                                      ':hover': {
                                        backgroundColor: hexToRGBA(
                                          dayjs(event!.deadline).isToday()
                                            ? COLORS.brand1
                                            : dayjs(event!.deadline).isBefore(dayjs())
                                            ? COLORS.negative1
                                            : COLORS.gray600,
                                          0.3,
                                        ),
                                      },
                                    }}
                                    style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                  >
                                    <Icons.Close
                                      width={8}
                                      height={8}
                                      stroke={
                                        dayjs(event!.deadline).isToday()
                                          ? COLORS.brand1
                                          : dayjs(event!.deadline).isBefore(dayjs())
                                          ? COLORS.negative1
                                          : COLORS.gray600
                                      }
                                    />
                                  </IconButton>
                                )}
                              </DeadlineShowingWrapper>
                            )}
                            {/**태스크박스 시간 */}
                            <div className="time-modal-container">
                              {event.start?.datetime ? <Icons.Time stroke={COLORS.gray700} /> : <Icons.Today />}
                              <Fonts.Blockquote
                                className="text-gray700"
                                onClick={(e) => {
                                  // if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                  if (!e.metaKey && !e.ctrlKey) {
                                    handleOpenSimpleTimeSelectionModal(e);
                                  }
                                }}
                                style={{ marginLeft: '4px' }}
                              >
                                {event.start?.datetime ? showingTime(event) : '오늘'}
                              </Fonts.Blockquote>
                              {!multiSelected ? (
                                <Tooltip
                                  title={
                                    language === 'ko' ? '시간 해제하기' : 'Remove time'
                                    // 'Remove time'
                                  }
                                  disableInteractive
                                >
                                  <IconButton
                                    className="unselect-time"
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                      }
                                    }}
                                    sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                  >
                                    <Icons.Close width={12} height={12} stroke={'#C8CDDB'} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <div style={{ width: '24px', height: '24px' }} />
                              )}
                            </div>
                          </TaskboxPropertyWrapper>
                          {event.project && event.project.title !== event.title && (
                            <InstanceTitleWrapper
                              onClick={(e) => {
                                if (!e.metaKey && !e.ctrlKey) {
                                  handleClickInstanceTitle(event);
                                  setIsLoadSubtaskFromProject(false);
                                }
                              }}
                            >
                              <Icons.Issue />
                              <InstanceTitle isProject>{event.project.title}</InstanceTitle>
                            </InstanceTitleWrapper>
                          )}
                          {event.routine && event.routine.title !== event.title && (
                            <InstanceTitleWrapper
                              onClick={(e) => {
                                if (!e.metaKey && !e.ctrlKey) {
                                  handleClickRoutineTitle(event);
                                }
                              }}
                            >
                              <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                              <InstanceTitle isRoutine>{event.routine.title}</InstanceTitle>
                            </InstanceTitleWrapper>
                          )}
                          <div className="flex justify-between items-center relative timeline-header">
                            {/* 타임박스 제목 */}
                            <div
                              className="taskbox-title-container"
                              style={{
                                width: `calc(100% - ${foldTaskboxFieldWidth}px)`,
                              }}
                            >
                              {tasks.filter((task) => task.done).length > 0 && !event.done ? (
                                <Checkbox
                                  checked={false}
                                  icon={
                                    event.focus ? (
                                      <Icons.FocusInprogressCheck />
                                    ) : (
                                      <Icons.InprogressCheck
                                        fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                                      />
                                    )
                                  }
                                  sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  style={{ width: 24, height: 24, marginRight: 8 }}
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      if (event.type === 'TERM_TASK') {
                                        handleClickTaskboxCheck();
                                      } else {
                                        setSelectedTaskbox(event.id);
                                        setInprogressPopover(e.currentTarget);
                                      }
                                    }
                                  }}
                                />
                              ) : (
                                <PriorityIssueCheckbox
                                  width={24}
                                  height={24}
                                  focus={event.focus}
                                  bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                  markercolor={event.project ? COLORS.issue4 : event.isRecurrence || event.routine ? COLORS.sub5 : COLORS.sub3}
                                  checked={!!event.done}
                                  // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                  // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                  }}
                                  style={{ marginRight: 8 }}
                                />
                              )}
                              {isExploding && event.focus && (
                                <ConfettiExplosion
                                  force={0.6}
                                  duration={2500}
                                  particleCount={80}
                                  width={1000}
                                  onComplete={() => setIsExploding(false)}
                                  style={{ position: 'absolute', top: 0, left: 0 }}
                                />
                              )}
                              <TaskboxInputWrapper isProject={event.project?.title === event.title} isRoutine={event.routine?.title === event.title}>
                                {event.project && event.project.title === event.title && (
                                  <InstanceIcon>
                                    <Icons.Issue />
                                  </InstanceIcon>
                                )}
                                {event.routine && event.routine.title === event.title && (
                                  <InstanceIcon>
                                    <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />
                                  </InstanceIcon>
                                )}
                                {event.lockedIn && <LockInIcon event={event} />}
                                <TaskboxInput
                                  ref={refTaskboxInput}
                                  className="taskbox-title"
                                  data-id={
                                    event.type === 'TERM_TASK'
                                      ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                      : `${event.id}`
                                  }
                                  onBlur={(e) => handleBlurTitle(e, event)}
                                  onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                  onInput={(e) => handleTaskboxInput(e)}
                                  placeholder={event.routine ? event.routine.title : event.project ? event.project.title : 'Title'}
                                  contentEditable={multiSelected ? false : true}
                                  suppressContentEditableWarning={true}
                                  spellCheck={false}
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      if (event.routine && event.routine.title === event.title) {
                                        handleClickRoutineTitle(event);
                                      } else if (event.project && event.project.title === event.title) {
                                        handleClickInstanceTitle(event);
                                        setIsLoadSubtaskFromProject(false);
                                      } else if (!event.project && !event.routine) {
                                        handleClickTaskbox(event);
                                        setGetCaret(window.getSelection()!.anchorOffset);
                                        if (!taskboxDetailView || event.id !== taskboxDetailView.id) {
                                          setTaskboxDetailView(event);
                                        }
                                      }
                                    }
                                  }}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {event.title}
                                </TaskboxInput>
                              </TaskboxInputWrapper>

                              {event.issueId && (
                                <Button
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => handleClickIssueDetail(event.issueId!)}
                                  sx={{
                                    width: '100px',
                                    height: '24px',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    color: `${COLORS.gray400}`,
                                    border: `1px solid ${COLORS.gray400}`,
                                  }}
                                >
                                  <div className="timelineEvents-issue-detail">
                                    <IssueLinkage />
                                    <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                  </div>
                                </Button>
                              )}
                            </div>
                            <div data-foldShowingId={`${event.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                              {event.memo && <Icons.TaskboxMemo />}
                              {event.links!.length > 0 && (
                                <div style={{ marginLeft: '4px' }}>
                                  <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                </div>
                              )}
                              {(event.isRecurrence || event.routine) && (
                                <div style={{ marginLeft: '4px' }}>
                                  <Icons.Recurrence />
                                </div>
                              )}
                              {tasks.length > 0 && (
                                <div className="show-subtask">
                                  <Icons.Subtask />
                                  <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                </div>
                              )}
                            </div>
                            {!multiSelected && (
                              <TaskboxOptionsContainer
                                className="taskbox-option"
                                titleLength={taskboxTitleLength}
                                style={selectedTaskbox ? { visibility: 'visible' } : {}}
                              >
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip title={language === 'ko' ? '지금 시작하기' : 'Start now'} disableInteractive>
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleClickStartNow(event, true);
                                          }
                                        }}
                                      >
                                        <Icons.StartNowTaskboxAction />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    {event.project && (
                                      <Tooltip title={language === 'ko' ? '프로젝트 연결 해제하기' : 'Detach from project'} disableInteractive>
                                        <TaskboxDetachButtonWrapper>
                                          <IconButton
                                            onClick={() => handleDetachTaskboxFromProject(event)}
                                            sx={{
                                              'width': '20px',
                                              'height': '20px',
                                              'padding': '0px',
                                              'borderRadius': '2.5px',
                                              'backgroundColor': COLORS.issue2,
                                              ':hover': {
                                                backgroundColor: COLORS.issue2,
                                              },
                                            }}
                                          >
                                            <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                          </IconButton>
                                        </TaskboxDetachButtonWrapper>
                                      </Tooltip>
                                    )}
                                    {event.routine && (
                                      <Tooltip title={language === 'ko' ? '루틴 연결 해제하기' : 'Detach from routine'} disableInteractive>
                                        <TaskboxDetachButtonWrapper>
                                          <IconButton
                                            onClick={() => handleDetachTaskboxFromRoutine(event)}
                                            sx={{
                                              'width': '20px',
                                              'height': '20px',
                                              'padding': '0px',
                                              'borderRadius': '2.5px',
                                              'backgroundColor': COLORS.sub4,
                                              ':hover': {
                                                backgroundColor: COLORS.sub4,
                                              },
                                            }}
                                          >
                                            <Icons.Close width={12} height={12} stroke={COLORS.white} />
                                          </IconButton>
                                        </TaskboxDetachButtonWrapper>
                                      </Tooltip>
                                    )}
                                    {!event.project && !event.routine && (
                                      <Tooltip title={language === 'ko' ? '전환하기' : 'Convert'} disableInteractive>
                                        <IconButton
                                          sx={{ borderRadius: '8px', padding: '4px' }}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) handleClickConvertIconBtn(e);
                                          }}
                                        >
                                          <Icons.TaskboxConvertButton />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip title={language === 'ko' ? '미루기' : 'postpone'} disableInteractive>
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) handleClickPostponeIconBtn(e);
                                        }}
                                      >
                                        <Icons.ArrowRightLong width={20} height={20} stroke={COLORS.gray600} />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip
                                      title={
                                        language === 'ko' ? '더보기' : 'More'
                                        // 'More'
                                      }
                                      disableInteractive
                                    >
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            setSelectedTaskbox(event.id!);
                                            setTaskboxDetail(e.currentTarget);
                                          }
                                        }}
                                      >
                                        <Icons.More width={20} height={20} fill={COLORS.gray600} />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                              </TaskboxOptionsContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </TaskDragContainer>
                )}
              </>
            )}
            {/** 간편 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(simpleTimeSelection)}
                anchorEl={simpleTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                disableEscapeKeyDown
              >
                <SimpleTimeSelectionModal
                  event={event}
                  autoSetting={isSettingUnderbarTime}
                  onClose={handleCloseSimpleTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                />
              </Popover>
            )}
            {/* 날짜 및 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(timeSelection)}
                anchorEl={timeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseTimeSelectionModal}
              >
                <TimeDateSelectionModal
                  event={event}
                  suppressMoveDate={event.type === 'TERM_TASK'}
                  onClose={handleCloseTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                  onUnlinkRoutine={handleUnlinkRoutine}
                />
              </Popover>
            )}
            {/* 태스크박스 상세 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <TimelineTaskboxDetail
                event={event}
                detail={taskboxDetail}
                focusedTaskbox={focusedTaskbox}
                suppressDivider={!!event.project}
                onCloseDetail={handleCloseTimelineDetail}
                onClickMenu={handleClickTaskboxDetailMenu}
              />
            )}
            {/**이어서 완료하기 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(inprogressPopover)}
                anchorEl={inprogressPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handleCloseInprogressPopover}
                sx={{ marginTop: '4px' }}
              >
                <InprogressTaskbox>
                  <InprogressTaskboxContent
                    onClick={() => {
                      handleClickTaskboxCheck();
                      handleCloseInprogressPopover();
                    }}
                  >
                    {event!.focus ? (
                      <Icons.FocusCheck width={16} height={16} />
                    ) : (
                      <Icons.TaskboxCheck
                        width={16}
                        height={16}
                        fill={event.project ? COLORS.issue2 : event!.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1}
                      />
                    )}
                    <span style={{ marginLeft: '8px' }}>
                      {language === 'ko' ? '모두 완료하기' : 'Complete all'}
                      {/* Complete all */}
                    </span>
                  </InprogressTaskboxContent>
                  {event.project ? (
                    <Tooltip
                      title={
                        <span>
                          {language === 'ko' ? '인스턴스를 완료하고 미완료 하위 업무를' : 'Complete the instance and send any incomplete'}
                          <br />
                          {language === 'ko' ? '다음 인스턴스나 프로젝트로 보냅니다.' : 'subtasks to the next instance or project.'}
                        </span>
                        // <span>
                        //   Complete the instance and send any incomplete
                        //   <br />
                        //   subtasks to the next instance or project.
                        // </span>
                      }
                      placement="right"
                    >
                      <InprogressTaskboxContent onClick={handleClickInstanceContinue}>
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '8px' }}>
                          {language === 'ko' ? '일부 완료하기' : 'Complete some'}
                          {/* Complete some */}
                        </span>
                        <Icons.QuestionInfo style={{ marginLeft: '4px' }} />
                      </InprogressTaskboxContent>
                    </Tooltip>
                  ) : (
                    <InprogressTaskboxContent
                      onClick={(e) => {
                        setContinueSettingPopover(e.currentTarget);
                      }}
                    >
                      {event.focus ? (
                        <Icons.ContinueFocusTaskbox />
                      ) : (
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence || event.routine ? COLORS.sub4 : COLORS.brand1} />
                      )}
                      <span style={{ marginLeft: '8px' }}>
                        {language === 'ko' ? '일부 완료하고 이어서하기' : 'Complete some and continue'}
                        {/* Complete some and continue */}
                      </span>
                    </InprogressTaskboxContent>
                  )}
                </InprogressTaskbox>
              </Popover>
            )}
            {/** 태스크박스 이어서하기 설정창 */}
            <TaskboxContinueDetail
              anchorEl={continueSettingPopover}
              event={event}
              onCloseContinue={handleCloseContinueSettingPopover}
              onChangeDate={handleChangeInprogress}
            />
            {/* 태스크박스 카테고리 */}
            <Popover
              open={Boolean(categoryAnchorEl)}
              anchorEl={categoryAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setSelectedTaskbox(undefined);
                setCategoryAnchorEl(null);
                if (isQuickCategory) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickCategory(false);
                }
                if (isSettingUnderbarCategory) setIsSettingUnderbarCategory(false);
              }}
              sx={{ marginTop: '4px' }}
            >
              <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
            </Popover>
            {/* 태스크박스 기한 */}
            <Popover
              open={Boolean(deadlineAnchorEl)}
              anchorEl={deadlineAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setSelectedTaskbox(undefined);
                setDeadlineAnchorEl(null);
                if (isQuickDeadline) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickDeadline(false);
                }
                if (isSettingUnderbarDeadline) setIsSettingUnderbarDeadline(false);
              }}
              sx={{ marginTop: '4px' }}
            >
              <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
            </Popover>
            {/* 태스크박스 프로적트 리스트 팝오버 */}
            <Popover
              open={Boolean(projectListAnchorEl)}
              anchorEl={projectListAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setProjectListAnchorEl(null);
                if (isQuickProject) {
                  setFocusElement(focusedTaskbox!.id!);
                  setIsQuickProject(false);
                }
              }}
              sx={{ marginTop: '4px' }}
            >
              <ProjectListPopover onClick={handleClickProjectList} />
            </Popover>
            {/* 태스크박스 멀티셀렉  */}
            {multiSelectIds[multiSelectIds.length - 1] === event!.id && (
              <Popper open={Boolean(multiSelectAnchorEl)} anchorEl={multiSelectAnchorEl} placement={'right-start'} ref={refPopperMenu} sx={{ zIndex: 1000 }}>
                <TaskboxMultiSelectPopover
                  date={dayjs(
                    events?.find((v) => v.id === multiSelectIds[0])?.start?.date || events?.find((v) => v.id === multiSelectIds[0])?.start?.datetime,
                  ).toDate()}
                  multiSelectCount={multiSelectIds.length}
                  openDeleteDialog={isOpenMultiSelectDeleteDialog}
                  onClickMultiSelectActions={onClickMultiSelectActions}
                  onOpenDeleteDialog={handleOpenMultiSelectDeleteDialog}
                  onCloseDeleteDialog={handleCloseMultiSelectDeleteDialog}
                />
              </Popper>
            )}
            {/** 하위업무 멀티셀렉 */}
            {multiSelectSubtaskIds.length > 0 && multiSelectSubtaskIds[0].taskboxId === event.id && (
              <Popper
                open={Boolean(multiSelectSubtaskAnchorEl)}
                anchorEl={multiSelectSubtaskAnchorEl}
                placement={'right-start'}
                ref={refPopperMenu}
                sx={{ zIndex: 1000 }}
              >
                <SubtaskMultiSelectPopover
                  menus={event.project ? ['MOVE_TO_AFTER', 'DELETE'] : ['CONVERT_TO_TASKBOX', 'MOVE_TO_AFTER', 'DELETE']}
                  subtaskIds={multiSelectSubtaskIds[0].subtaskIds}
                  onClickMultiSelectSubtaskAction={onClickMultiSelectSubtaskAction}
                />
              </Popper>
            )}
            {/** 태스크박스 컨텍스트 메뉴 */}
            <Popover
              open={Boolean(contextMenuPopover)}
              anchorEl={contextMenuPopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setContextMenuPopover(null);
                setTimeout(() => {
                  setContextTaskbox(null);
                }, 100);
              }}
            >
              <InboxContextMenu
                id={event.id!}
                type={contextTaskbox?.project ? 'instance' : 'taskbox'}
                menus={contextMenus}
                onClickMenu={handleClickContextMenu}
              />
            </Popover>
            {/** 하위업무 모드 선택 */}
            <Popover
              open={Boolean(subtaskModeAnchorEl)}
              anchorEl={subtaskModeAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => setSubtaskModeAnchorEl(null)}
            >
              <SubtaskModeMenu mode={subtaskMode} onChange={handleChangeSubtaskMode} />
            </Popover>
            {/** 태스크박스 프로젝트/루틴으로 전환 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(convertAnchorEl)}
                anchorEl={convertAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setConvertAnchorEl(null);
                  setSelectedTaskbox(undefined);
                }}
                sx={{ marginTop: '4px' }}
              >
                <TaskboxConvertMenu types={['PROJECT', 'ROUTINE']} onClick={handleClickTaskboxConvert} />
              </Popover>
            )}
            {/** 태스크박스 미루기  */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(taskboxPostponeAnchorEl)}
                anchorEl={taskboxPostponeAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setTaskboxPostponeAnchorEl(null);
                  setSelectedTaskbox(undefined);
                }}
                sx={{ marginTop: '4px' }}
              >
                <TaskboxPostponeDetail event={event} onChangeDate={handleChangeTaskboxDate} />
              </Popover>
            )}
            {/** 루틴 생성 창 */}
            <RoutineCreationView open={Boolean(convertToRoutineTaskbox)} event={convertToRoutineTaskbox} onClose={handleCloseCreateRoutine} />
            {/** 포커스 모드 dialog */}
            {focusModeTaskbox && event!.id === focusModeTaskbox!.id && (
              <FocusModeDialog
                open={Boolean(focusModeTaskbox) && !hideFocusMode}
                taskbox={focusModeTaskbox}
                templateList={templateList}
                hide={hideFocusMode}
                categoryList={categoryList}
                onFetch={handleFetchFocusMode}
                onClose={handleCloseFocusMode}
                onHide={handleHideFocusMode}
                onDelete={handleDeleteFocusMode}
                onCreateTemplate={handleCreateTemplate}
                onPutTemplate={handlePutTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onChangeInprogress={handleChangeFocusModeInprogress}
                onChangeTimer={onChangeTimer}
                onClickCategoryActions={handleClickCategoryActions}
                onChangeDeadline={handleChangeDeadline}
                onClickInstanceContinue={handleClickInstanceContinue}
                onChangeInstanceSubtask={handleChangeFocusModeInstanceSubtask}
                onPushProjectRecommendTask={handlePushProjectRecommendTask}
                onDeleteProject={handleDeleteProject}
              />
            )}
            {event!.id === focusedTaskbox?.id && deleteWithKeydown && (
              <ConfirmDeleteDialog open={deleteWithKeydown} onClose={() => setDeleteWithKeydown(false)} onDelete={handleDeleteWithKeydown} />
            )}
            {(event!.id === focusedTaskbox?.id || event!.id === selectedTaskbox) && (
              <UpdateRecurrenceDialog open={openRecurrenceUpdate} onClose={handleCloseRecurrenceUpdateDialog} onUpdateRecurrence={handleRemoveRecurrenceTime} />
            )}
            {(event!.id === focusedTaskbox?.id || event!.id === selectedTaskbox || event.id === contextTaskbox?.id) && deleteRecurrenceWithKeydown && (
              <RemoveRecurrenceDialog
                open={deleteRecurrenceWithKeydown}
                onClose={() => setDeleteRecurrenceWithKeydown(false)}
                onRemoveRecurrence={handleDeleteRecurrenceWithKeydown}
              />
            )}
            {!!clickedIssue && plan && (
              <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                <IssueDetailView
                  issueId={event!.issueId!}
                  selectable={true}
                  priority={plan.length > 0 ? plan[0].priority : undefined}
                  // sectionTitle={sectionTitle ? sectionTitle : '섹션 없음'}
                  // timelineView={timelineView}
                  // tasks={selectedTasks}
                  onClickCancel={handleClickCancelIssue}
                  onClickSave={handleClickSaveIssue}
                />
              </Dialog>
            )}
            {/** 자주 하는 업무 다이어로그 */}
            {event!.id! === selectedTaskbox && (
              <TemplateDialog
                open={openTemplateDialog}
                templateList={templateList}
                categoryList={categoryList}
                onClose={handleCloseTemplateDialog}
                onPut={handlePutTemplate}
                onDelete={handleDeleteTemplate}
                onAdd={handleTemplateToTaskbox}
                onClickCategory={handleClickCategoryActions}
              />
            )}
            <TaskDropContainer height={taskboxHeight} onDrop={() => handleDragDropToTaskbox(event!.id!)} hidden={!draggingToTaskbox} />
          </div>
        )}
      </>
    );
  },
);

const TaskboxDragContainer = styled.div<{ multiSelected?: boolean }>`
  position: relative;
  :hover {
    .taskbox-drag-handle {
      opacity: 0.4;
    }
  }
`;

const TaskDragContainer = styled.div<{
  event?: OutTaskboxDetailResponse;
  taskboxDone?: boolean;
  focusedTaskbox?: OutTaskboxDetailResponse;
  plan?: OutPlanWeekly[];
  selectedTaskbox?: string;
  multiSelected?: boolean;
}>`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${COLORS.white};
  pointer-events: all;
  margin-bottom: 8px;

  &:hover {
    ${(props) =>
      !(props.focusedTaskbox?.id === props.event?.id) &&
      css`
        border: 1px solid ${COLORS.gray300};
        box-shadow: 1px 1px 20px 1px ${COLORS.gray200};
      `}

    .taskbox-option {
      visibility: visible;
    }

    .additional-btn {
      display: flex;
      align-items: center;
    }

    ${(props) =>
      props.multiSelected &&
      css`
        .additional-btn {
          display: none;
        }
      `}
  }

  ${(props) =>
    props.taskboxDone &&
    css`
      border: 1px solid ${COLORS.gray200};
      background-color: ${COLORS.white};
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    props.event?.done &&
    css`
      border: 1px solid ${COLORS.gray300};
      border-radius: 8px;
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    props.event?.focus &&
    css`
      border: 1px solid transparent;
      background-image: linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
      border-radius: 8px;
      background-origin: border-box;
      background-clip: padding-box, border-box;
    `}

  ${(props) =>
    (props.event?.isRecurrence || props.event?.routine) &&
    !props.event?.focus &&
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.sub4};
    `}

    ${(props) =>
    props.event?.project &&
    !props.event?.focus &&
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.issue2};
    `}

  ${(props) =>
    props.multiSelected &&
    css`
      cursor: pointer;
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.gray300};
    `}

  .timeline-border {
    ${(props) =>
      props.taskboxDone &&
      css`
        opacity: 0.4;
      `}
  }

  .line {
    width: 4px;
    background-color: ${COLORS.brand1};
    border-radius: 4px;

    ${(props) =>
      (props.event?.isRecurrence || props.event?.routine) &&
      css`
        background-color: ${COLORS.sub4};
      `}

    ${(props) =>
      props.event?.project &&
      css`
        background-color: ${COLORS.issue2};
      `}

    ${(props) =>
      props.event?.focus &&
      css`
        background: var(--Gradation, linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%));
      `}
  }

  .show-subtask {
    margin-left: 4px;
    display: flex;
    align-items: center;
  }

  .timelineEvents-background {
    padding-right: 20px;
  }

  .time-modal-container {
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 3px 4px;

    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
      .unselect-time {
        display: block;
      }
    }
  }

  .unselect-time {
    display: none;
  }

  .timeline-header {
    padding: 4px 2px;
  }

  .taskbox-title-container {
    display: flex;
    width: 100%;
  }

  .timelineEvents-issue-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timelineEvents-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .taskbox-title {
    width: 100%;
    /* ${(props) =>
      props.taskboxDone === true &&
      css`
        color: ${COLORS.gray500};
      `} */

    ${(props) =>
      props.taskboxDone === true &&
      css`
        text-decoration: line-through;
      `}
  }

  .remove-timelineEvents {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }

  .add-task {
    margin-top: 2px;
    margin-left: 4px;
    color: ${COLORS.gray500};
    /* ${(props) =>
      props.taskboxDone === true &&
      css`
        color: ${COLORS.gray400};
      `} */
  }
`;

const TaskboxInputWrapper = styled.div<{ isProject?: boolean; isRoutine?: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  word-break: break-all;

  :hover {
    ${(props) => props.isProject && `color: ${COLORS.issue1};`}
    ${(props) => props.isRoutine && `color: ${COLORS.sub4};`}
  }
`;

const LockInIcon = styled.div<{ event?: OutTaskboxDetailResponse }>`
  margin-right: 4px;
  margin-top: 4px;
  width: 16px;
  height: 16px;

  ${(props) =>
    !props.event?.done &&
    css`
      background-image: url('${require('assets/images/Star.png')}');
      background-position: center;
      background-size: cover;
    `}

  ${(props) =>
    props.event?.done &&
    css`
      background-image: url('${require('assets/images/LockInDone.png')}');
      background-position: center;
      background-size: cover;
      opacity: 50%;
    `}
`;

const InstanceIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 4px;
`;

const TaskboxInput = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-right: 8px;

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }
`;

const SubtaskView = styled.div`
  width: 100%;
  margin-top: 2px;

  .event-background {
    padding-right: 20px;
  }

  .detail {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }

  .event-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .remove-event {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }
`;

const SubtaskField = styled.div<{ taskboxDone?: boolean }>`
  max-width: 480px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray900};

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      color: ${COLORS.gray500};
      content: attr(placeholder);
    }
  }

  ${(props) =>
    props.taskboxDone &&
    css`
      color: ${COLORS.gray500};
      text-decoration: line-through;
    `}
`;

const EditableTextFieldWrapper = styled.div<{ selectedTaskId?: boolean; hover?: boolean; multiSelected?: boolean }>`
  width: 100%;
  position: relative;
  word-break: break-all;
  border-radius: 8px;
  padding: 3px 0px 3px 5px;
  border: 1px solid transparent;
  margin-bottom: 4px;

  ${(props) =>
    props.selectedTaskId &&
    css`
      border: 1px solid ${COLORS.gray200};
    `}

  ${(props) =>
    props.multiSelected &&
    css`
      background-color: ${COLORS.sub3};
    `}

  .task-container {
    width: 100%;
    flex: 1;
  }

  .subtask {
    width: 100%;
  }

  :hover {
    ${(props) =>
      props.hover &&
      css`
        background-color: ${COLORS.gray100};
      `}
    .drag-handle {
      opacity: 0.4;
    }
    .subtask-addson {
      background-color: ${COLORS.white};
    }
    .detail {
      opacity: 1;
    }
  }
`;

const SubtaskAddsOnWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 2px;
  position: absolute;
  right: 2px;
`;

const TaskboxDragHandleIcon = styled.span<{ taskboxHeight?: number }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 + 1}px;
  left: -21px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const TaskboxDragToCalendarIcon = styled.span<{ taskboxHeight?: number }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 - 22}px;
  left: -21px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=arrow_back');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const DragHandleIcon = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  left: -16px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const TaskboxOptionsContainer = styled.div<{ titleLength: number }>`
  position: absolute;
  display: flex;
  border-radius: 8px;
  padding: 1px;
  visibility: hidden;
  z-index: 2;
  background-color: ${COLORS.white};
  /* left: ${(props) => props.titleLength}px; */
  right: 0px;
  top: 1px;
`;

const TaskboxOptionsContent = styled.div`
  position: relative;
`;

const TaskboxOptionsIcon = styled.div`
  :hover ~ .taskbox-tooltip {
    visibility: visible;
  }
`;

// const TaskboxOptionsTooltip = styled.div`
//   position: absolute;
//   font-size: 12px;
//   color: ${COLORS.white};
//   background-color: ${COLORS.gray900};
//   padding: 7px 12px;
//   border-radius: 4px;
//   right: -13px;
//   top: 30px;
//   text-align: center;
//   visibility: hidden;
// `;

const TaskboxAddOnWrapper = styled.div<{ multiSelected?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding-left: 1px;

  &:hover {
    .additional-btn {
      display: flex;
      align-items: center;
    }

    ${(props) =>
      props.multiSelected &&
      css`
        .additional-btn {
          display: none;
        }
      `}
  }
`;

const TaskboxDetachButtonWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  cursor: 'pointer';

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const SubtaskWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.gray200};
  border-radius: 4px;
  padding: 6px;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const AdditionalAddOnWrapper = styled.div`
  display: none;
`;

const AdditionalAddOnButtonWrapper = styled.div`
  display: none;
`;

const AdditionalAddOnButton = styled.div<{ template?: boolean }>`
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${COLORS.gray200};
  border-radius: 4px;
  padding: 6px;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const UpdateRoutineInstanceWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 4px 6px;
  cursor: pointer;

  :hover {
    border: 1px solid ${COLORS.gray200};

    .apply-changes-btn {
      opacity: 1;
    }
  }
`;

const TaskDropContainer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${COLORS.sub5};
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  margin-bottom: 8px;
`;

const InprogressTaskbox = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const InprogressTaskboxContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const TaskIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 4px;
  padding: 2px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);

    .later {
      filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
    }
    .delete {
      filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
    }
  }
`;

const ShowingMemoWrapper = styled.div``;

const ShowingMemoTextWrapper = styled.div`
  border-radius: 8px;
  color: ${COLORS.gray700};
  cursor: pointer;
  font-size: 12px;
  padding: 2px 8px;

  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const TaskboxPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const InstanceTitleWrapper = styled.div`
  width: fit-content;
  height: 24px;
  display: flex;
  align-items: center;
  :hover {
    .unlink-project {
      display: block;
    }
  }
`;

const InstanceTitle = styled.div<{ isProject?: boolean; isRoutine?: boolean }>`
  color: ${COLORS.gray700};
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  margin-left: 10px;
  :hover {
    ${(props) => props.isProject && `color: ${COLORS.issue1};`}
    ${(props) => props.isRoutine && `color: ${COLORS.sub4};`}
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.date && dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : props.date && dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200};
  border-radius: 4px;
  color: ${(props) =>
    props.date && dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : props.date && dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const DateRangeShowingWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 12px;
`;

const RecommendTaskWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  padding: 0px 4px;
`;

const RecommendButtonRect = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  background-color: ${COLORS.gray100};
  border-radius: 2px;
  font-size: 8px;
  padding: 0px 4px;
`;
