import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'locales/en.json';
import ko from 'locales/ko.json';

// 국가 코드와 json 파일 매핑
const resources = {
  ko: { translation: ko },
  en: { translation: en },
};

export const initialize = (lng = 'en'): void => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: lng, // 기본 언어 설정
      fallbackLng: false, // 기본 언어로 대체되지 않게 설정
      debug: process.env.NODE_ENV === 'development', // 디버그 모드 활성화
      keySeparator: false, // 키 분리자 비활성화
      nsSeparator: false, // 네임스페이스 분리자 비활성화
      returnEmptyString: false, // 빈 문자열 반환하지 않음
      interpolation: {
        prefix: '%{',
        suffix: '}',
        escapeValue: false, // react already safes from xss
      },
      parseMissingKeyHandler(key, defaultValue) {
        console.warn('Missing key:', key, defaultValue);
        const keySeparator = '~~';
        const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;
        return value;
      },
    });
};
