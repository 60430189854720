import styled from '@emotion/styled';
import { Dialog, TextField, Button } from '@mui/material';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { css } from '@emotion/react';

interface VoteProps {
  open?: boolean;
  onClose?: () => void;
  onClick?: (score: number, description: string) => void;
}

export const Vote = ({ open = false, onClose = () => {}, onClick = () => {} }: VoteProps) => {
  const [low, setLow] = useState(false);
  const [high, setHigh] = useState(false);
  const [voteText, setVoteText] = useState('');

  const handleChangeLow = () => {
    setLow(!low);
    if (high) setHigh(!high);
  };
  const handleChangeHigh = () => {
    setHigh(!high);
    if (low) setLow(!low);
  };
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoteText(e.target.value);
  };

  const handleClick = () => {
    const score = low ? 1 : 2;
    const description = voteText ? voteText : '';
    onClick(score, description);
  };

  const handleClose = async () => {
    onClose();
    await new Promise((resolve) => setTimeout(resolve, 100));

    setLow(false);
    setHigh(false);
    setVoteText('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <VoteContainer>
        <VoteHeader>
          <div style={{ marginRight: '10px' }}>🤔</div>
          얼마나 필요하신가요?
        </VoteHeader>
        <VoteSelect select={low} onClick={handleChangeLow}>
          <PriorityIssueCheckbox width={20} height={20} bordercolor={COLORS.brand1} checked={low} onClick={handleChangeLow} style={{ marginTop: '2px' }} />
          ⭐️ 기대돼요!
        </VoteSelect>
        <VoteSelect select={high} onClick={handleChangeHigh}>
          <PriorityIssueCheckbox width={20} height={20} bordercolor={COLORS.brand1} checked={high} onClick={handleChangeHigh} style={{ marginTop: '2px' }} />
          💖 꼭 필요해요. 빨리 오픈해주세요!
        </VoteSelect>
        <TextField
          multiline
          rows={4}
          placeholder="추가로 남기고 싶은 이야기가 있다면 남겨주세요."
          value={voteText}
          onChange={handleChangeText}
          sx={{ width: '100%', fontSize: '13px' }}
        />
        <VoteButtonWrapper>
          <Button onClick={handleClose}>취소하기</Button>
          <Button variant="contained" disabled={!low && !high} onClick={handleClick} sx={{ marginLeft: '24px' }}>
            전달하기
          </Button>
        </VoteButtonWrapper>
      </VoteContainer>
    </Dialog>
  );
};

const VoteContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VoteHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 32px;
`;

const VoteSelect = styled.div<{ select?: boolean }>`
  width: 100%;
  padding: 12px;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    ${(props) =>
      !props.select &&
      css`
        background-color: ${COLORS.gray200};
      `}
  }

  ${(props) =>
    props.select &&
    css`
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.brand1};
    `};
`;

const VoteButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;

  .MuiButton-root {
    width: 192px;
    padding: 14px 16px;
    border-radius: 8px;
  }
`;
