import TextField from '@mui/material/TextField';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, AutocompleteChangeReason, Avatar, Box, Button, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Alarm, Close, People, Map as MapIcon } from 'components/Icons';
import { useEffect, useState } from 'react';
import { getMembersV1UsersMembersGet, getUserMeV1UsersMeGet } from 'queries';
import { OutUserDetail } from 'queries/model';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DATE_FORMAT_7, DATE_FORMAT_3, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import dayjs from 'lib/dayjs';
import toast from 'react-hot-toast';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import styled from '@emotion/styled';

const UserChip = styled.div<{ guest: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  background-color: ${(props) => (props.guest ? '#FFF6DF' : '#E2ECFF')};
`;

export interface EventCreationModalProps extends DialogProps {
  currentDate?: Date;
  eventId?: string;
  eventTitle?: string;
  eventStartTime?: string;
  eventEndTime?: string;
  eventLocation?: string;
  eventParticipants?: any[];
  onClose?: () => void;
  // onClickSave?: (value: AppEventModelOutEvent) => void;
  // onClickSave?: (value: Partial<Pick<AppEventModelOutEvent, 'eventId' | 'title' | 'startTime' | 'endTime' | 'participants' | 'location'>>) => void;
  onClickDelete?: (id: string) => void;
}

const EventCreationModal = ({
  currentDate,
  eventId,
  eventTitle,
  eventStartTime,
  eventEndTime,
  eventLocation,
  eventParticipants,
  open,
  onClose,
  // onClickSave,
  onClickDelete,
  ...props
}: EventCreationModalProps) => {
  const [user, setUser] = useState<OutUserDetail | null>(null);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState<Date>(currentDate || new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [members, setMembers] = useState<any[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState(new Map<string, any>());
  const [location, setLocation] = useState('');
  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs(currentDate)
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(DATE_FORMAT_7),
    ),
  ];

  useEffect(() => {
    if (open) initialize();
  }, [open]);

  const initialize = async () => {
    const [userData, membersData] = await Promise.all([getUserMeV1UsersMeGet(), getMembersV1UsersMembersGet()]);
    setUser(userData);
    // setMembers(membersData.map(({ id = '', name = '', email = '', profileImage = '' }) => ({ id, name, email, profileImage } as OutEventUser)));
    setTitle(eventTitle || '');
    setStartTime(dayjs(eventStartTime || currentDate).format(DATE_FORMAT_7));
    setEndTime(dayjs(eventEndTime || currentDate).format(DATE_FORMAT_7));
    // setSelectedParticipants(new Map((eventParticipants || []).map((item) => [item.id ? item.id : item.email, item])));
    setLocation(eventLocation || '');
  };

  const handleMemberDelete = (value: any) => {
    if (!value) return;

    selectedParticipants.delete(value);
    setSelectedParticipants(new Map([...selectedParticipants]));
  };

  const handleClickSave = () => {
    if (!`${title}`.trim()) {
      toast.error('회의 이름을 입력해주세요.');
      return;
    }

    if (+dayjs(startTime) >= +dayjs(endTime)) {
      toast.error('회의 시간을 올바르게 입력해주세요.');
      return;
    }

    // onClickSave && onClickSave({ eventId, title, startTime, endTime, participants: [...selectedParticipants].map(([_, value]) => value), location });
  };

  const handleClickDelete = () => {
    onClickDelete && onClickDelete(eventId!);
  };

  const handleChangeMember = (event: React.SyntheticEvent<Element, Event>, value: string | any | null, reason: AutocompleteChangeReason) => {
    // const me = members.find((item) => item.id === user?.id);
    // if (!me) return;

    if (reason === 'createOption') {
      if (typeof value === 'string' && validateEmail(value)) {
        const member = members.find((item) => item.email === value);
        if (member) return;

        // selectedParticipants.set(me.id!, me);
        selectedParticipants.set(value, { email: value } as any);
      }

      setSelectedParticipants(new Map([...selectedParticipants]));
    }

    // if (reason === 'selectOption') {
    //   if (typeof value === 'object') {
    //     const member = members.find((item) => item.id === value?.id);
    //     if (member) {
    //       selectedParticipants.set(me.id!, me);
    //       selectedParticipants.set(member.id!, member);
    //     }
    //   }

    //   setSelectedParticipants(new Map([...selectedParticipants]));
    // }
  };

  const validateEmail = (value = '') => RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm').test(value);

  return (
    <Dialog open={open} {...props}>
      <DialogTitle sx={{ mt: 1 }} variant="h5" fontWeight={'bold'}>
        {eventId ? '회의를 수정합니다' : '새로운 회의를 생성합니다'}
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 12, top: 20 }}>
          <Close width={24} height={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" fontWeight={'bold'}>
            새로운 회의 이름
          </Typography>
          <TextField fullWidth placeholder="새로운 회의 이름을 입력해주세요." sx={{ mt: 1 }} value={title} onChange={(e) => setTitle(e.target.value)} />
        </Box>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: 3 }}>
          <Alarm />
          <DesktopDatePicker
            disableMaskedInput
            inputFormat={DATE_FORMAT_3}
            value={date}
            mask=""
            onChange={(v) => setDate(v!)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                fullWidth
                variant="standard"
                inputProps={{
                  ...params?.inputProps,
                  readOnly: true,
                }}
                style={{ width: 160, marginRight: 12, marginLeft: 18, borderBottom: 'none' }}
              />
            )}
          />
          <Select
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            style={{ width: 136 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 372,
                },
              },
            }}
          >
            {!selectableTime.includes(dayjs(eventStartTime).format(DATE_FORMAT_7)) && (
              <MenuItem disabled value={dayjs(eventStartTime).format(DATE_FORMAT_7)}>
                <span>{dayjs(eventStartTime).format(TIME_FORMAT_2)}</span>
              </MenuItem>
            )}
            {selectableTime.map((v, idx) => (
              <MenuItem key={idx} value={v}>
                <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
              </MenuItem>
            ))}
          </Select>
          <span style={{ margin: '0 4px' }}>-</span>
          <Select
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            style={{ width: 136 }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 372,
                },
              },
            }}
          >
            {!selectableTime.includes(dayjs(eventEndTime).format(DATE_FORMAT_7)) && (
              <MenuItem disabled value={dayjs(eventEndTime).format(DATE_FORMAT_7)}>
                <span>{dayjs(eventEndTime).format(TIME_FORMAT_2)}</span>
              </MenuItem>
            )}
            {selectableTime.map((v, idx) => (
              <MenuItem key={idx} value={v}>
                <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
              </MenuItem>
            ))}
            <MenuItem value={dayjs(currentDate).endOf('day').format(DATE_FORMAT_7)}>
              <span>{dayjs(currentDate).endOf('day').format(TIME_FORMAT_2)}</span>
            </MenuItem>
          </Select>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} sx={{ mt: 3 }}>
          <People />
          <Autocomplete
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            // blurOnSelect
            autoHighlight
            value={null}
            options={members}
            // options={members.filter((v) => v.id !== user?.id)}
            renderInput={(params) => <TextField {...params} placeholder="이름 또는 이메일로 참석자를 추가해주세요." />}
            onChange={handleChangeMember}
            style={{ marginLeft: 18 }}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 372,
                },
              },
            }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
            renderOption={(props, option) => (
              <></>
              // <Box key={option.id} component="li" {...props} sx={{ display: 'flex' }}>
              //   <Avatar alt={option.name} src={option.profileImage} sx={{ width: 24, height: 24, marginRight: 1 }} />
              //   <span>{option.name}</span>
              // </Box>
            )}
            isOptionEqualToValue={(option, value) => option.email === value.email}
          />
        </Stack>
        <Stack spacing={1} sx={{ mt: 1, ml: 5 }}>
          <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
            {Array.from(selectedParticipants).map(([key, value]) => (
              <UserChip key={key} guest={!value.name}>
                <div style={{ position: 'relative', marginRight: 8 }}>
                  <Avatar alt={value.name || ''} src={value.profileImage || ''} sx={{ width: 16, height: 16 }} />
                  {value.id === user?.id && (
                    <span style={{ position: 'absolute', top: 8, right: -2 }}>
                      <Icons.OrganizerMarker width={8} height={8} />
                    </span>
                  )}
                </div>
                <span>{value.name || value.email}</span>
                {value.id === user?.id && <span style={{ marginLeft: 4, color: COLORS.gray500 }}>(주최자)</span>}
                <span style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleMemberDelete(key)}>
                  <Icons.Close width={12} height={12} stroke={COLORS.gray500} />
                </span>
              </UserChip>
            ))}
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} sx={{ mt: 1 }}>
          <MapIcon width={24} height={24} />
          <TextField
            fullWidth
            placeholder="회의 장소를 입력해주세요."
            sx={{ mt: 1 }}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            style={{ marginLeft: 18 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mx: 2 }}>
        {eventId && (
          <Button color="error" variant="contained" disableElevation onClick={handleClickDelete}>
            삭제하기
          </Button>
        )}
        <Button variant="contained" disableElevation onClick={handleClickSave}>
          {eventId ? '수정하기' : '저장하기'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventCreationModal;
